import React from 'react'
import { Container } from 'reactstrap'

import Cell from '@src/components/table/Cell'
import CollapsibleRow from '@src/components/table/CollapsibleRow'
import SelectableHeader from '@src/components/table/ContentHeader'
import SelectableRow from '@src/components/table/ContentRow'

export default class ContentTable extends React.PureComponent {

    public static Cell = Cell
    public static CollapsibleRow = CollapsibleRow
    public static Header = SelectableHeader
    public static Row = SelectableRow

    public render() {
        return (
            <Container fluid className="selectable-content__wrapper mb-3 table-responsive">
                <div className="selectable-content table mb-0">
                    {this.props.children}
                </div>
            </Container>
        )
    }
}
