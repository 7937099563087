export enum FeatureFlag {
    DocumentModule = 'document-module',
    Communications = 'communications',
    Costs = 'cost-module',
    Registers = 'registers'
}

export enum FeatureValue {
    Default = 'default',
    CostsDefault = 'default'
}

export type DocumentFlagValue = FeatureValue.Default
export type CommunicationFlagValue = FeatureValue.Default
export type CostFlagValue = FeatureValue.CostsDefault
export type RegisterFlagValue = FeatureValue.Default

export type Features = {
    [key in FeatureFlag]: FeatureValue
}

export function hasFeature(features: Features, flag: FeatureFlag.DocumentModule, value: DocumentFlagValue | DocumentFlagValue[]): boolean
export function hasFeature(features: Features, flag: FeatureFlag.Communications, value: CommunicationFlagValue | CommunicationFlagValue[]): boolean
export function hasFeature(features: Features, flag: FeatureFlag.Costs, value: CostFlagValue | CostFlagValue[]): boolean
export function hasFeature(features: Features, flag: FeatureFlag.Registers, value: RegisterFlagValue | RegisterFlagValue[]): boolean
export function hasFeature(features: Features, flag: FeatureFlag, value: FeatureValue | FeatureValue[]): boolean {
    return value instanceof Array ? value.some(v => features[flag] === v) : features[flag] === value
}
