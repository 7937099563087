import React from 'react'
import { FormFeedback } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import cx from 'classnames'

import Select, { IBaseProps as SelectProps } from '@src/components/common/Select'

export default class ValidatedSelect<TValue> extends React.PureComponent<Omit<SelectProps<TValue>, keyof { onChange, value }> & WrappedFieldProps> {
    public render() {
        const { input, meta: { touched, error }, creatable, ...selectProps } = this.props

        const showError = touched && !!error

        return (
            <>
                <Select
                    {...selectProps}
                    name={input.name}
                    creatable={creatable}
                    value={input.value}
                    onChange={input.onChange}
                    invalid={showError}
                />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && error}</FormFeedback>
            </>
        )
    }
}
