import React from 'react'

const SidebarList: React.FunctionComponent = ({ children }) => {
    return (
        <ul className="sidebar-group__list">
            {children}
        </ul>
    )
}

export default SidebarList
