import React from 'react'
import { Col, Row } from 'reactstrap'

import ActionBar from '@src/components/common/ActionBar'
import Link from '@src/components/common/Link'
import CostValue from '@src/components/costs/common/CostValue'
import * as Routes from '@src/logic/routing/routes'
import { formatCurrency } from '@src/logic/utils/Currency'
import { Commitment, CostsOverview } from '@src/types/costs'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
    commitment: Commitment
}

const CommitmentToolbar: React.FC<IProps> = ({ costsOverview, commitment, projectId }) => {
    return (
        <ActionBar className="pt-0 d-block">
            <Row>
                <Col className="pt-3" xs={6} md="auto">
                    <h5>Total Value</h5>
                    <CostValue value={commitment.currentValue} />
                </Col>
                {commitment.subCommitmentValue != null &&
                    <>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Subcommitment Value</h5>
                            <CostValue value={commitment.subCommitmentValue} />
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Adjusted Total</h5>
                            <CostValue value={commitment.currentValue + commitment.subCommitmentValue} />
                        </Col>
                    </>
                }
                <Col className="pt-3" xs={6} md="auto">
                    <h5>Total Certified</h5>
                    <CostValue value={commitment.currentCertified} />
                </Col>
                <Col className="pt-3" xs={6} md="auto">
                    <h5>Total Remaining</h5>
                    <CostValue value={commitment.currentValue - commitment.currentCertified} />
                </Col>
                <Col className="pt-3" xs={6} md="auto">
                    <h5>Total Paid</h5>
                    <CostValue value={commitment.currentPaid} />
                </Col>
                {commitment.parent != null &&
                <Col className="pt-3" xs={6} md="auto">
                    <h5>{costsOverview.commitmentDefinitions.find(cd => cd.code === commitment.parent.type).name}</h5>
                    <Link to={Routes.projectCostsCommitmentTypeDetail(projectId, commitment.parent.type, commitment.parent.id)}>{commitment.parent.name}</Link>
                </Col>}
            </Row>
        </ActionBar>
    )
}

export default React.memo(CommitmentToolbar)
