import React from 'react'
import { Tooltip } from 'reactstrap'

import { ApprovalStatus } from '@src/types/document'
import { Transmittal, TransmittalBrief, TransmittalStatus } from '@src/types/transmittal'

interface IProps {
    transmittal: Transmittal | TransmittalBrief
}

interface IStatus {
    className: string
    description: string
}

interface IState {
    tooltipOpen: boolean
}

export default class TransmittalStatusIndicator extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            tooltipOpen: false
        }
    }

    private calculateStatus = (): IStatus => {
        const { transmittal } = this.props
        switch (transmittal.status) {
            case TransmittalStatus.Draft:
                return { className: 'status-badge--warning', description: 'Draft' }
            case TransmittalStatus.Published:
                if (transmittal.approval.status === ApprovalStatus.Pending) return { className: 'status-badge--pre-success', description: 'Pending Approval' }
                if (transmittal.approval.status === ApprovalStatus.Denied) return { className: 'status-badge--dangeer', description: 'Rejected' }
                if (transmittal.approval.status === ApprovalStatus.Approved) return { className: 'status-badge--pre-success', description: 'Approved, Not Sent' }
                break
            case TransmittalStatus.Sent:
                return { className: 'status-badge--success', description: 'Sent' }
        }

        return { className: 'status-badge--default', description: 'Unknown' }
    }

    private toggle = () => {
        this.setState({ tooltipOpen: !this.state.tooltipOpen })
    }

    public render() {
        const { transmittal } = this.props
        const status = this.calculateStatus()

        return (
            <>
                <Tooltip className="text-center" delay={10} target={'tci-' + transmittal.id} isOpen={this.state.tooltipOpen} toggle={this.toggle}>{status.description}</Tooltip>
                <span id={'tci-' + transmittal.id} className={'status-badge ' + status.className} />
            </>
        )
    }
}
