// tslint:disable: jsx-no-lambda
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Redirect, Route, Switch } from 'react-router'
import { Card, Col, Container, Nav, NavLink, Row } from 'reactstrap'

import { reloadCompany as reloadSessionCompany } from '@src/actions/session'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import CompanyBanner from '@src/components/company/CompanyBanner'
import CompanyToolbar from '@src/components/company/CompanyToolbar'
import CompanyAccessSection from '@src/components/company/settings/CompanyAccessSection'
import GeneralSettingSection from '@src/components/company/settings/GeneralSettingsSection'
import { MembershipSection } from '@src/components/company/settings/MembershipSection'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

const CompanySettingsPage: React.FC<{}> = () => {

    const dispatch = useDispatch()
    const company = useSelector<RootState, Company>(s => s.session.company)

    React.useEffect(() => reloadCompany(), [])

    function reloadCompany() {
        dispatch(reloadSessionCompany())
    }

    return (
        <>
            <CompanyBanner company={company} />
            <CompanyToolbar company={company} />
                <Container className="mt-3" fluid>
                    <Row className="mb-5">
                        <Col md={3} className="mb-3">
                            <div className="sticky-top" style={{ top: '1rem' }}>
                                <Card body>
                                    <Nav vertical pills>
                                        <NavLink disabled>General</NavLink>
                                        <NavLink tag={Link} to={Routes.COMPANY_SETTINGS_GENERAL} active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_GENERAL, true)}><FA icon="cogs" />&nbsp;&nbsp;Company Info</NavLink>
                                        <NavLink disabled className="mt-3">Security</NavLink>
                                        <NavLink tag={Link} to={Routes.COMPANY_SETTINGS_ACCESS} active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_ACCESS, true)}><FA icon="lock" />&nbsp;&nbsp;Access</NavLink>
                                        <NavLink tag={Link} to={Routes.COMPANY_SETTINGS_MEMBERSHIP} active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_MEMBERSHIP, true)}><FA icon="users-crown"/>&nbsp;&nbsp;Membership Admin</NavLink>
                                        {/* <NavLink disabled className="mt-3">Subscription</NavLink>
                                        <NavLink tag={Link} to={Routes.COMPANY_SETTINGS_BILLING} active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_BILLING, true)}><FA icon="credit-card"/>&nbsp;&nbsp;Billing &amp; Licensing</NavLink>
                                        <NavLink tag={Link} to={Routes.COMPANY_SETTINGS_INVOICES} active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_INVOICES, true)}><FA icon="file-invoice-dollar" />&nbsp;&nbsp; Invoices</NavLink> */}
                                    </Nav>
                                </Card>
                            </div>
                        </Col>
                        <Col md={9}>
                            <Switch>
                                <Route path={Routes.COMPANY_SETTINGS_GENERAL} exact render={routeProps => <GeneralSettingSection company={company} reloadCompany={reloadCompany} />} />
                                <Route path={Routes.COMPANY_SETTINGS_ACCESS} exact render={routeProps => <CompanyAccessSection {...routeProps} company={company} />} />
                                <Route path={Routes.COMPANY_SETTINGS_MEMBERSHIP} exact component={MembershipSection} />
                                <Redirect to={Routes.PROJECT_SETTINGS_GENERAL} />
                            </Switch>
                        </Col>
                    </Row>
                </Container>
        </>
    )
}

export default CompanySettingsPage
