import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import BigNumber from 'bignumber.js'
import { Operation as PatchOperation, compare } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import CheckboxRadio from '@src/components/common/CheckboxRadio'
import FA from '@src/components/common/FontAwesomeIcon'
import { buildOptions } from '@src/components/common/Select'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CommitmentStatusModal from '@src/components/costs/settings/CommitmentStatusModal'
import EditCommitmentDefinitionForm, { IEditCommitmentDefinitionFormData } from '@src/components/costs/settings/EditCommitmentDefinitionForm'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import GenericTable from '@src/components/table/GenericTable'
import * as Forms from '@src/logic/forms/Forms'
import { CommitmentDefinitionPatch, CommitmentsListByType } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { reorder } from '@src/logic/utils/Collection'
import { Api } from '@src/types/api'
import { CommitmentDefinition, CommitmentReportColumnName, CommitmentStatusDefinition } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IConnectedState {
    projectId: string
    commitmentDefinition: CommitmentDefinition
    existingCommitmentDefintions: CommitmentDefinition[]
}

interface IConnectedDispatch {
    submitSaveSettings: () => void
    reloadCostsOverview: () => void
}

interface IState {
    hasCommitments: boolean
    isCreating: boolean
    statusToEdit?: CommitmentStatusDefinition
    statusToDelete?: CommitmentStatusDefinition
    statusesLocked: boolean
    updatedStatuses: CommitmentStatusDefinition[]
}

class CommitmentDefinitionSettingsSection extends React.PureComponent<IConnectedState & IConnectedDispatch, IState> {
    constructor(props) {
        super(props)

        this.state = {
            hasCommitments: null,
            isCreating: false,
            statusToEdit: null,
            statusToDelete: null,
            statusesLocked: true,
            updatedStatuses: null
        }
    }

    public componentDidMount() {
        this.checkHasCommitments()
    }

    private readonly checkHasCommitments = async () => {
        const response = await CommitmentsListByType(this.props.projectId, this.props.commitmentDefinition.code, undefined, undefined, 1, 1)
        this.setState({ hasCommitments: response.data.commitments.length > 0 })
    }

    private readonly editStatus = (status: CommitmentStatusDefinition) => {
        this.setState({ statusToEdit: status })
    }

    private readonly clearNewOrEditStatusModal = () => {
        this.setState({ statusToEdit: null, isCreating: false })
    }

    private readonly clearDeleteStatusModal = () => {
        this.setState({ statusToDelete: null })
    }

    private readonly markDeleteStatus = (status: CommitmentStatusDefinition) => {
        this.setState({ statusToDelete: status })
    }

    private readonly deleteStatus = async () => {
        const { statusToDelete: status } = this.state
        const index = this.props.commitmentDefinition.statusDefinitions.findIndex(s => s.code === status.code)

        try {
            await CommitmentDefinitionPatch(this.props.projectId, this.props.commitmentDefinition.code, [{
                op: 'test', path: `/statusDefinitions/${index}/code`, value: status.code
            }, {
                op: 'remove', path: `/statusDefinitions/${index}`
            }])
            NotificationService.info(`Removed status '${status.code}'`)
        } catch {
            NotificationService.error(`There was an error while trying to delete status '${status.code}'`)
        } finally {
            this.props.reloadCostsOverview()
            this.clearDeleteStatusModal()
        }
    }

    private readonly toggleLockStatuses = () => {
        this.setState({ statusesLocked: !this.state.statusesLocked })
    }

    private readonly markCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly handleSaveSettings = async (values: IEditCommitmentDefinitionFormData) => {
        const cd = this.props.commitmentDefinition
        const original: Api.Request.CommitmentDefinitionUpdate = {
            name: cd.name,
            defaultVariationAdditions: cd.defaultVariationAdditions,
            defaultVariationDeductions: cd.defaultVariationDeductions,
            defaultDocumentLinkCategories: cd.defaultDocumentLinkCategories,
            defaultPaymentDocumentLinkCategories: cd.defaultPaymentDocumentLinkCategories,
            commitmentNumberDefinition: {
                prefix: cd.commitmentNumberDefinition.prefix,
                significantPlaces: cd.commitmentNumberDefinition.significantPlaces
            },
            certificateNumberDefinition: {
                prefix: cd.certificateNumberDefinition.prefix,
                significantPlaces: cd.certificateNumberDefinition.significantPlaces
            },
            statusDefinitions: cd.statusDefinitions,
            hasInsurances: cd.hasInsurances
        }
        const update: Api.Request.CommitmentDefinitionUpdate = {
            name: values.name,
            defaultVariationAdditions: new BigNumber(values.defaultVariationAdditionsPercentage).dividedBy(100).toNumber(),
            defaultVariationDeductions: new BigNumber(values.defaultVariationDeductionsPercentage).dividedBy(100).toNumber(),
            defaultDocumentLinkCategories: values.defaultDocumentLinkCategories?.map(x => x.value) ?? [],
            defaultPaymentDocumentLinkCategories: values.defaultPaymentDocumentLinkCategories?.map(x => x.value) ?? [],
            commitmentNumberDefinition: values.commitmentNumberDefinition,
            certificateNumberDefinition: values.paymentNumberDefinition,
            statusDefinitions: cd.statusDefinitions,
            hasInsurances: cd.hasInsurances
        }
        const patch = compare(original, update)

        try {
            await CommitmentDefinitionPatch(this.props.projectId, this.props.commitmentDefinition.code, patch)
            NotificationService.info('Saved settings')
        } catch {
            NotificationService.error('There was an error while saving settings')
        } finally {
            this.props.reloadCostsOverview()
        }
    }

    private readonly onSubmitSuccess = () => {
        this.props.reloadCostsOverview()
        this.clearNewOrEditStatusModal()
    }

    private readonly getStatusDefinitionId = (status: CommitmentStatusDefinition) => {
        return status.code
    }

    private readonly handleStatusReorder = async (status: CommitmentStatusDefinition, result: DropResult) => {
        if (result.destination == null || result.source.index === result.destination.index) return

        const patch: PatchOperation = {
            op: 'move',
            from: `/statusDefinitions/${result.source.index}`,
            path: `/statusDefinitions/${result.destination.index}`
        }

        this.setState({ updatedStatuses: reorder(this.props.commitmentDefinition.statusDefinitions, result.source.index, result.destination.index) })

        try {
            await CommitmentDefinitionPatch(this.props.projectId, this.props.commitmentDefinition.code, [patch])
            await this.props.reloadCostsOverview()
        } catch (e) {
            NotificationService.error('An error occurred when attempting to reorder the status')
        } finally {
            this.setState({ updatedStatuses: null })
        }
    }

    public render() {
        const { commitmentDefinition, projectId } = this.props
        const { statusToEdit, statusToDelete, statusesLocked, isCreating, updatedStatuses, hasCommitments } = this.state

        return (
                <Container fluid>
                    <Row className="my-3">
                        <Col xl={6} className="mb-3">
                            <Card>
                                <CardHeader className="d-flex">
                                    <div className="flex-grow-1 flex-shrink-0"><FA icon="cog" /> Commitment Settings</div>
                                    <Button color="primary" onClick={this.props.submitSaveSettings}>Save</Button>
                                </CardHeader>
                                <CardBody>
                                    {hasCommitments != null && <EditCommitmentDefinitionForm
                                        form={Forms.CostsEditCommitmentDefintion}
                                        destroyOnUnmount
                                        typeInUse={hasCommitments}
                                        initialValues={{
                                            name: commitmentDefinition.name,
                                            defaultVariationAdditionsPercentage: new BigNumber(commitmentDefinition.defaultVariationAdditions || 0).times(100).toNumber(),
                                            defaultVariationDeductionsPercentage: new BigNumber(commitmentDefinition.defaultVariationDeductions || 0).times(100).toNumber(),
                                            defaultDocumentLinkCategories: buildOptions(commitmentDefinition.defaultDocumentLinkCategories),
                                            defaultPaymentDocumentLinkCategories: buildOptions(commitmentDefinition.defaultPaymentDocumentLinkCategories),
                                            commitmentNumberDefinition: {
                                                prefix: commitmentDefinition.commitmentNumberDefinition.prefix,
                                                significantPlaces: commitmentDefinition.commitmentNumberDefinition.significantPlaces
                                            },
                                            paymentNumberDefinition: {
                                                prefix: commitmentDefinition.certificateNumberDefinition.prefix,
                                                significantPlaces: commitmentDefinition.certificateNumberDefinition.significantPlaces
                                            },
                                            hasInsurances: commitmentDefinition.hasInsurances
                                        }}
                                        onSubmit={this.handleSaveSettings}
                                    />}
                                </CardBody>
                            </Card>
                        </Col>
                        <Col xl={6}>
                            <Card>
                                <CardHeader className="d-flex">
                                    <div className="flex-grow-1 flex-shrink-0">Statuses</div>
                                    <Button className="mr-2" color="dark" onClick={this.toggleLockStatuses}><FA icon={statusesLocked ? 'lock' : 'lock-open'} /></Button>
                                    <Button color="primary" onClick={this.markCreating}><FA icon="plus" /> Add Status</Button>
                                </CardHeader>
                                <GenericTable<CommitmentStatusDefinition, never>
                                    className="mb-0"
                                    data={updatedStatuses || commitmentDefinition.statusDefinitions}
                                    getRowId={this.getStatusDefinitionId}
                                    onReorder={(!statusesLocked && updatedStatuses == null) ? this.handleStatusReorder : null}
                                    headers={[
                                        {
                                            accessor: 'code',
                                            name: 'Status'
                                        },
                                        {
                                            name: 'Columns',
                                            overrideRenderer: defintion => defintion.columns.map(c => CommitmentReportColumnName[c]).join(', ')
                                        },
                                        {
                                            name: 'Allow Claim',
                                            overrideRenderer: definition =>
                                                <div className="d-flex justify-content-center">
                                                    <CheckboxRadio disabled checked={definition.allowClaim} />
                                                </div>
                                        },
                                        {
                                            name: 'Unlocked Budget',
                                            overrideRenderer: definition =>
                                                <div className="d-flex justify-content-center">
                                                    <CheckboxRadio disabled checked={definition.allowInUnlockedBudget} />
                                                </div>
                                        },
                                        {
                                            name: 'Require Other Party',
                                            overrideRenderer: definition =>
                                                <div className="d-flex justify-content-center">
                                                    <CheckboxRadio disabled checked={definition.requireOtherParty} />
                                                </div>
                                        },
                                        {
                                            name: 'Actions',
                                            overrideRenderer: (definition, rowIdx) => (
                                                <div className="justify-content-end text-right selectable-content__actions">
                                                    <TooltipLinkAction id={`edit-commitdef-${rowIdx}`} tooltip="Edit" data={definition} onClick={this.editStatus}><FA icon="pencil" /></TooltipLinkAction>
                                                    <TooltipLinkAction id={`remove-commitdef-${rowIdx}`} tooltip="Remove" data={definition} onClick={this.markDeleteStatus}><FA icon="trash" /></TooltipLinkAction>
                                                </div>
                                            )
                                        }
                                    ]}
                                />
                            </Card>
                        </Col>
                    </Row>
                    <CommitmentStatusModal
                        open={statusToEdit != null || isCreating}
                        statusToEdit={statusToEdit}
                        toggle={this.clearNewOrEditStatusModal}
                        projectId={projectId}
                        commitmentDefinition={commitmentDefinition}
                        existingCommitmentDefinitions={this.props.existingCommitmentDefintions}
                    />
                    <ConfirmationModal
                        open={statusToDelete != null}
                        toggle={this.clearDeleteStatusModal}
                        message={<span>Are you sure you want to remove the status <strong>{statusToDelete ? statusToDelete.code : ''}</strong>? This action <strong>cannot</strong> be undone.</span>}
                        header="Delete Status"
                        confirmAction="Yes, Delete Status"
                        onConfirm={this.deleteStatus}
                        danger
                    />
                </Container>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: {}): IConnectedDispatch {
    return {
        ...ownProps,
        submitSaveSettings: () => dispatch(submit(Forms.CostsEditCommitmentDefintion)),
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview())
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<{key: string}>): IConnectedState {
    const definitionKey = ownProps.match.params.key
    return {
        projectId: state.projects.active.id,
        commitmentDefinition: state.projects.activeCostsOverview.commitmentDefinitions.find(cd => cd.code === definitionKey),
        existingCommitmentDefintions: state.projects.activeCostsOverview.commitmentDefinitions.filter(cd => cd.code !== definitionKey)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentDefinitionSettingsSection)
