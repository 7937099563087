import React from 'react'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps  {
    currentSort: string
    field: string
    reveal?: boolean
    onClick?: (e: React.MouseEvent, field: string) => void
}

export default class Sort extends React.PureComponent<IProps> {

    private handleSortClick = (e: React.MouseEvent) => {
        this.props.onClick(e, this.props.field)
    }

    public render() {
        const { currentSort, field, onClick, reveal, ...otherProps } = this.props

        if (currentSort == null) {
            return null
        }

        const trimmedSort = currentSort.trim()
        const descending = trimmedSort.startsWith('-')

        if (trimmedSort.length < 2 && descending) {
            return null
        }

        const rawCurrentSort = descending ? trimmedSort.substring(1) : trimmedSort

        const sortComponent = <FA icon={descending ? 'sort-amount-down' : 'sort-amount-up'} onClick={onClick ? this.handleSortClick : null} {...otherProps} />

        return (
            <span
                className={cx('text-nowrap', 'd-inline-block', { 'text-muted': rawCurrentSort !== field && reveal, invisible: rawCurrentSort !== field && !reveal })}
                style={{ width: 0 }}
            >
                {sortComponent}
            </span>
        )
    }
}
