import React from 'react'
import { DropResult } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import cx from 'classnames'
import { compare, Operation as PatchOperation } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import BudgetSettingsForm, { IBudgetSettingsFormData } from '@src/components/costs/settings/BudgetSettingsForm'
import BudgetStatusModal from '@src/components/costs/settings/BudgetStatusModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import GenericTable from '@src/components/table/GenericTable'
import * as Forms from '@src/logic/forms/Forms'
import { BudgetItemsList, BudgetPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { reorder } from '@src/logic/utils/Collection'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { BudgetOverview, BudgetReportColumnName, BudgetStatusDefinition } from '@src/types/costs'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IConnectedState {
    projectId: string
    budget: BudgetOverview
    widgetRevisions: Revision[]
}

interface IConnectedDispatch {
    submitBudgetSettings: () => void
    reloadCostsOverview: () => void
}

interface IState {
    isCreating: 'item' | 'adjustment'
    updatedItemStatuses: BudgetStatusDefinition[]
    updatedAdjustmentStatuses: BudgetStatusDefinition[]
    totalBudgetSum: number
    statusToEdit: BudgetStatusDefinition
    statusToEditType: 'item' | 'adjustment'
    statusToDelete: BudgetStatusDefinition
    statusToDeleteType: 'item' | 'adjustment'
}

class BudgetSettingsSection extends React.PureComponent<IConnectedState & IConnectedDispatch, IState> {

    constructor(props) {
        super(props)

        this.state = {
            isCreating: null,
            updatedItemStatuses: null,
            updatedAdjustmentStatuses: null,
            totalBudgetSum: null,
            statusToDelete: null,
            statusToDeleteType: null,
            statusToEdit: null,
            statusToEditType: null
        }
    }

    public componentDidMount() {
        BudgetItemsList(this.props.projectId, undefined, undefined, 1, 1, undefined)
            .then(res => this.setState({ totalBudgetSum: res.data.aggregate.totalSum }))
    }

    private getStatusDefinitionId = (status: BudgetStatusDefinition) => {
        return status.code
    }

    private markItemToDelete = (status: BudgetStatusDefinition) => {
        this.setState({ statusToDelete: status, statusToDeleteType: 'item' })
    }

    private markAdjustmentToDelete = (status: BudgetStatusDefinition) => {
        this.setState({ statusToDelete: status, statusToDeleteType: 'adjustment' })
    }

    private clearStatusToDelete = () => {
        this.setState({ statusToDelete: null, statusToDeleteType: null })
    }

    private markItemToEdit = (status: BudgetStatusDefinition) => {
        this.setState({ statusToEdit: status, statusToEditType: 'item' })
    }

    private markAdjustmentToEdit = (status: BudgetStatusDefinition) => {
        this.setState({ statusToEdit: status, statusToEditType: 'adjustment' })
    }

    private markCreatingItem = () => {
        this.setState({ isCreating: 'item' })
    }

    private markCreatingAdjustment = () => {
        this.setState({ isCreating: 'adjustment' })
    }

    private clearNewOrEditStatus = () => {
        this.setState({ statusToEdit: null, statusToEditType: null, isCreating: null })
    }

    private handleItemReorder = (status: BudgetStatusDefinition, result: DropResult) => {
        return this.handleStatusReorder('item', status, result)
    }

    private handleAdjustmentReorder = (status: BudgetStatusDefinition, result: DropResult) => {
        return this.handleStatusReorder('adjustment', status, result)
    }

    private handleStatusReorder = async (type: 'item' | 'adjustment', status: BudgetStatusDefinition, result: DropResult) => {
        if (result.destination == null || result.source.index === result.destination.index) return

        const { itemStatusDefinitions, adjustmentStatusDefinitions } = this.props.budget
        const isItemStatus = type === 'item'
        const rootPatchPath = isItemStatus ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        const patch: PatchOperation = {
            op: 'move',
            from: `${rootPatchPath}/${result.source.index}`,
            path: `${rootPatchPath}/${result.destination.index}`
        }

        const statusCollection: any = isItemStatus ? 'updatedItemStatuses' : 'updatedAdjustmentStatuses'

        this.setState({ [statusCollection]: reorder(isItemStatus ? itemStatusDefinitions : adjustmentStatusDefinitions, result.source.index, result.destination.index) } as any)

        try {
            await BudgetPatch(this.props.projectId, [patch])
            await this.props.reloadCostsOverview()
        } catch (e) {
            NotificationService.error('An error occurred when attempting to reorder the status')
        } finally {
            this.setState({ [statusCollection]: null } as any)
        }
    }

    private deleteStatus = async () => {
        const { statusToDelete: status, statusToDeleteType: type } = this.state
        const { itemStatusDefinitions, adjustmentStatusDefinitions } = this.props.budget

        const isItemStatus = type === 'item'
        const index = isItemStatus ? itemStatusDefinitions.findIndex(s => s.code === status.code) : adjustmentStatusDefinitions.findIndex(s => s.code === status.code)
        const rootPatchPath = isItemStatus ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        const testOperation: PatchOperation = {
            op: 'test', path: `${rootPatchPath}/${index}/code`, value: status.code
        }
        const deleteOperation: PatchOperation = {
            op: 'remove',
            path: `${rootPatchPath}/${index}`
        }

        try {
            await BudgetPatch(this.props.projectId, [testOperation, deleteOperation])
            NotificationService.info(`Removed status '${status.code}'`)
        } catch {
            NotificationService.error(`There was an error while trying to delete status '${status.code}'`)
        } finally {
            this.props.reloadCostsOverview()
            this.clearStatusToDelete()
        }
    }

    private saveBudgetSettings = async (values: IBudgetSettingsFormData) => {
        const { budget } = this.props
        const original: BudgetOverview = {
            ...budget
        }
        const update: BudgetOverview = {
            ...budget,
            locked: values.locked,
            approvedBudget: values.approvedBudget,
            budgetApprovalDate: values.budgetApprovalDate,
            clientBudgetAdvice: values.clientBudgetAdvice != null ? values.clientBudgetAdvice : [],
            consultantBudgetAdvice: values.consultantBudgetAdvice != null ? values.consultantBudgetAdvice : []
        }

        const patch = compare(original, update)

        try {
            await BudgetPatch(this.props.projectId, patch)
        } catch {
            NotificationService.error('An error occurred while saving budget settings')
            return
        }
        NotificationService.info('Budget settings saved')
        this.props.reloadCostsOverview()
    }

    private toggleBudgetLock = async () => {
        try {
            await BudgetPatch(this.props.projectId, [{ op: 'replace', path: '/locked', value: !this.props.budget.locked }])
        } catch {
            NotificationService.error(`Unable to ${this.props.budget.locked ? 'unlock' : 'lock'} budget`)
            return
        }

        this.props.reloadCostsOverview()
    }

    public render() {
        const { budget, budget: { itemStatusDefinitions, adjustmentStatusDefinitions }, projectId } = this.props
        const { isCreating, updatedAdjustmentStatuses, updatedItemStatuses, totalBudgetSum, statusToDelete, statusToEdit, statusToEditType } = this.state
        const canLock = totalBudgetSum != null ? totalBudgetSum === budget.approvedBudget : true

        return (
            <Container fluid>
                <Row className="mt-3">
                    <Col>
                        <Card>
                            <CardHeader className="d-flex">
                                <div className="flex-grow-1"><FA icon="cog" /> Budget Settings</div>
                                <Button color="primary" className={cx({ 'd-none': budget.locked })} onClick={this.props.submitBudgetSettings}>Save</Button>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Button className="mr-2" onClick={this.toggleBudgetLock} disabled={!budget.locked && !canLock}>{budget.locked ? 'Unlock' : 'Lock'} Budget</Button>
                                            {!budget.locked && !canLock && <FormText color="warning"><FA icon="exclamation-triangle" /> The budget cannot be locked because the current budget value does not equal the approved budget</FormText>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <BudgetSettingsForm
                                    form={Forms.CostsBudgetSettings}
                                    locked={budget.locked}
                                    availableRevisions={this.props.widgetRevisions}
                                    onSubmit={this.saveBudgetSettings}
                                    initialValues={{
                                        approvedBudget: budget.approvedBudget,
                                        budgetApprovalDate: budget.budgetApprovalDate,
                                        clientBudgetAdvice: budget.clientBudgetAdvice,
                                        consultantBudgetAdvice: budget.consultantBudgetAdvice,
                                        locked: budget.locked
                                    }}
                                />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col className="mb-3">
                        <Card>
                            <CardHeader className="d-flex">
                                <div className="flex-grow-1">Budget Statuses</div>
                                <Button disabled={budget.locked} color="primary" onClick={this.markCreatingItem}><FA icon="plus" /> Add Status</Button>
                            </CardHeader>
                            <GenericTable<BudgetStatusDefinition, never>
                                className="mb-0"
                                data={updatedItemStatuses || itemStatusDefinitions}
                                getRowId={this.getStatusDefinitionId}
                                onReorder={budget.locked ? null : this.handleItemReorder}
                                headers={[
                                    {
                                        accessor: 'code',
                                        name: 'Status'
                                    },
                                    {
                                        name: 'Columns',
                                        overrideRenderer: defintion => defintion.columns.map(c => BudgetReportColumnName[c]).join(', ')
                                    },
                                    {
                                        name: 'Actions',
                                        headerWrapperClass: 'text-right',
                                        overrideRenderer: definition => (
                                            <div className="justify-content-end text-right selectable-content__actions">
                                                <TooltipLinkAction id={`edit-${definition.code}`} tooltip="Edit" data={definition} onClick={this.markItemToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                                <TooltipLinkAction id={`remove-${definition.code}`} tooltip="Remove" data={definition} onClick={this.markItemToDelete}><FA icon="trash" /></TooltipLinkAction>
                                            </div>
                                        )
                                    }
                                ]}
                            />
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <CardHeader className="d-flex">
                                <div className="flex-grow-1">Budget Adjustment Statuses</div>
                                <Button disabled={budget.locked} color="primary" onClick={this.markCreatingAdjustment}><FA icon="plus" /> Add Status</Button>
                            </CardHeader>
                            <GenericTable<BudgetStatusDefinition, never>
                                className="mb-0"
                                data={updatedAdjustmentStatuses || adjustmentStatusDefinitions}
                                getRowId={this.getStatusDefinitionId}
                                onReorder={budget.locked ? null : this.handleAdjustmentReorder}
                                headers={[
                                    {
                                        accessor: 'code',
                                        name: 'Status'
                                    },
                                    {
                                        name: 'Columns',
                                        overrideRenderer: defintion => defintion.columns.map(c => BudgetReportColumnName[c]).join(', ')
                                    },
                                    {
                                        name: 'Actions',
                                        headerWrapperClass: 'text-right',
                                        overrideRenderer: definition => (
                                            <div className="justify-content-end text-right selectable-content__actions">
                                                <TooltipLinkAction id={`edit-adjustment-${definition.code}`} tooltip="Edit" data={definition} onClick={this.markAdjustmentToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                                <TooltipLinkAction id={`edit-adjustment-${definition.code}`} tooltip="Edit" data={definition} onClick={this.markAdjustmentToDelete}><FA icon="trash" /></TooltipLinkAction>
                                            </div>
                                        )
                                    }
                                ]}
                            />
                        </Card>
                    </Col>
                </Row>
                <BudgetStatusModal
                    open={statusToEdit != null || isCreating != null}
                    statusToEdit={statusToEdit}
                    statusType={statusToEditType || isCreating}
                    toggle={this.clearNewOrEditStatus}
                    projectId={projectId}
                    budget={budget}
                />
                <ConfirmationModal
                    open={statusToDelete != null}
                    toggle={this.clearStatusToDelete}
                    message={<span>Are you sure you want to remove the status <strong>{statusToDelete ? statusToDelete.code : ''}</strong>? This action <strong>cannot</strong> be undone.</span>}
                    header="Delete Status"
                    confirmAction="Yes, Delete Status"
                    onConfirm={this.deleteStatus}
                    danger
                />
            </Container>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: {}): IConnectedDispatch {
    return {
        ...ownProps,
        submitBudgetSettings: () => dispatch(submit(Forms.CostsBudgetSettings)),
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview())
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        budget: state.projects.activeCostsOverview.budget,
        widgetRevisions: getActiveProjectWidgetState(state).revisions
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(BudgetSettingsSection)
