import React from 'react'
import { Input, Label } from 'reactstrap'

import cx from 'classnames'

interface IProps extends React.HTMLProps<HTMLDivElement> {
    idPrefix?: string
    name?: string
    onToggleOff?: (name?: string) => void
    onToggleNeutral?: (name?: string) => void
    onToggleOn?: (name?: string) => void
    state?: boolean
    disabled?: boolean
}

export default class TriToggle extends React.PureComponent<IProps> {

    private handleToggleOff = () => {
        if (!this.props.disabled) {
            this.props.onToggleOff?.(this.props.name)
        }
    }

    private handleToggleNeutral = () => {
        if (!this.props.disabled) {
            this.props.onToggleNeutral?.(this.props.name)
        }
    }

    private handleToggleOn = () => {
        if (!this.props.disabled) {
            this.props.onToggleOn?.(this.props.name)
        }
    }

    public render() {
        const { disabled, idPrefix = 'tri', state, onToggleOn, onToggleOff, onToggleNeutral, name, ...divProps } = this.props
        return (
            <div className={cx('tri-toggle', { 'tri-toggle--disabled': disabled })} {...divProps}>
                <Input
                    aria-hidden="true"
                    className="tri-toggle__control tri-toggle__control--no"
                    type="radio"
                    name="tri-toggle-no"
                    // tslint:disable-next-line: no-boolean-literal-compare
                    checked={state === false}
                    readOnly
                />
                <Label
                    id={`${idPrefix}-toggle-no`}
                    className="tri-toggle__label"
                    onClick={this.handleToggleOff}
                    role="button"
                />
                <Input
                    aria-hidden="true"
                    className="tri-toggle__control"
                    type="radio"
                    name="tri-toggle-neutral"
                    checked={state === null}
                    readOnly
                />
                <Label
                    id={`${idPrefix}-toggle-neutral`}
                    className="tri-toggle__label"
                    onClick={this.handleToggleNeutral}
                    role="button"
                />
                <Input
                    aria-hidden="true"
                    className="tri-toggle__control tri-toggle__control--yes"
                    type="radio"
                    name="tri-toggle-yes"
                    // tslint:disable-next-line: no-boolean-literal-compare
                    checked={state === true}
                    readOnly
                />
                <Label
                    id={`${idPrefix}-toggle-yes`}
                    className="tri-toggle__label"
                    onClick={this.handleToggleOn}
                    role="button"
                />
                {/* tslint:disable-next-line: no-boolean-literal-compare */}
                <span className={cx('tri-toggle__handle', { 'tri-toggle__handle--no': state === false, 'tri-toggle__handle--yes': state === true })}/>
            </div>
        )
    }
}
