import React from 'react'
import { useHistory } from 'react-router'
import { Button } from 'reactstrap'

import moment from 'moment'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import { NewCommitmentModal } from '@src/components/costs/commitments/CommitmentFormModal'
import CostValue from '@src/components/costs/common/CostValue'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection from '@src/components/search/SearchSection'
import useBoolean from '@src/hooks/useBoolean'
import useLocalSearch from '@src/hooks/useLocalSearch'
import * as Routes from '@src/logic/routing/routes'
import { Commitment, CommitmentBrief, CostsOverview } from '@src/types/costs'
import { Project } from '@src/types/project'

const SubcommitmentSearchProperties: IMappedSearchProperty<CommitmentBrief>[] = [
    {
        name: 'Item Number',
        path: commitment => commitment.commitmentNo,
        searchKey: 'item_no',
        type: PropertyType.Text
    },
    {
        name: 'Type',
        path: commitment => commitment.type,
        searchKey: 'type',
        type: PropertyType.Text
    },
    {
        name: 'Name',
        path: commitment => commitment.name,
        searchKey: 'other_party',
        type: PropertyType.Text
    },
    {
        name: 'Date',
        path: commitment => new Date(commitment.createdDate),
        searchKey: 'created',
        type: PropertyType.Date
    },
    {
        name: 'Other Party Ref',
        path: commitment => commitment.otherPartyReference,
        searchKey: 'other_party_ref',
        type: PropertyType.Text
    },
    {
        name: 'Value',
        path: commitment => commitment.currentValue,
        searchKey: 'value',
        type: PropertyType.Number
    },
    {
        name: 'Claimed',
        path: commitment => commitment.currentClaimed,
        searchKey: 'claimed',
        type: PropertyType.Number
    },
    {
        name: 'Certified',
        path: commitment => commitment.currentCertified,
        searchKey: 'certified',
        type: PropertyType.Number
    },
    {
        name: 'Paid',
        path: commitment => commitment.currentPaid,
        searchKey: 'paid',
        type: PropertyType.Number
    }
]

interface IProps {
    project: Project
    commitment: Commitment
    costsOverview: CostsOverview
    search: string
}

const SubcommitmentSection: React.FunctionComponent<IProps> = ({ project, commitment, costsOverview, search }) => {
    const history = useHistory()
    const creating = useBoolean(false)
    const handleSearch = useLocalSearch(commitment.subCommitments, SubcommitmentSearchProperties)

    const commitmentTypeNameMap = costsOverview.commitmentDefinitions.reduce((agg, curr) => ({ ...agg, [curr.code]: curr.name }), {})

    function navigateToCommitment(commitment: Commitment) {
        history.push(Routes.projectCostsCommitmentTypeDetail(project.id, commitment.type, commitment.id))
    }

    return (
        <SearchSection<CommitmentBrief, 'id'>
            defaultPerPage={50}
            onSearch={handleSearch}
            searchAssistantProperties={SubcommitmentSearchProperties}
            extraSearchBarElements={[
                {
                    element: _ => <Button onClick={creating.setTrue}><FA icon="plus" /> New Sub Commitment</Button>,
                    position: 'before'
                }
            ]}
            noItemsFoundMessage={(
                <div>
                    <div className="my-3"><FA size="3x" icon="badge-dollar" /></div>
                    <p className="lead">There are no sub commitments</p>
                    <p className="lead"><Button onClick={creating.setTrue}>Create sub commitment</Button></p>
                </div>
            )}
            headers={[
                {
                    name: 'Item',
                    accessor: 'commitmentNo',
                    sortKey: 'item_no',
                    sortable: true
                },
                {
                    name: 'Type',
                    overrideRenderer: sub => <Link to={Routes.projectCostsCommitmentType(project.id, sub.type)}>{commitmentTypeNameMap[sub.type]}</Link>,
                    sortKey: 'type',
                    sortable: true
                },
                {
                    name: 'Name',
                    overrideRenderer: sub => <Link to={Routes.projectCostsCommitmentTypeDetail(project.id, sub.type, sub.id)}>{sub.name}</Link>,
                    sortKey: 'name',
                    sortable: true
                },
                {
                    name: 'Date',
                    overrideRenderer: sub => moment(sub.createdDate).format('L'),
                    sortKey: 'created',
                    sortable: true
                },
                {
                    name: 'Supplier Ref',
                    accessor: 'otherPartyReference',
                    sortKey: 'other_party_reference',
                    sortable: true
                },
                {
                    name: 'Value',
                    overrideRenderer: sub => <CostValue value={sub.currentValue} />,
                    sortKey: 'value',
                    sortable: true
                },
                {
                    name: 'Claimed',
                    overrideRenderer: sub => <CostValue value={sub.currentClaimed} />,
                    sortKey: 'claimed',
                    sortable: true
                },
                {
                    name: 'Certified',
                    overrideRenderer: sub => <CostValue value={sub.currentCertified} />,
                    sortKey: 'certified',
                    sortable: true
                },
                {
                    name: 'Paid',
                    overrideRenderer: sub => <CostValue value={sub.currentPaid} />,
                    sortKey: 'paid',
                    sortable: true
                }
            ]}
        >
            <NewCommitmentModal
                isOpen={creating.value}
                toggle={creating.setFalse}
                project={project}
                costsOverview={costsOverview}
                parentId={commitment.id}
                onCommitmentCreated={navigateToCommitment}
            />
        </SearchSection>
    )
}

export default SubcommitmentSection
