import React from 'react'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

interface IProps {
    fullName: string
    shortName: string
    onClick?: () => void
    icon?: IPFontawesomeIcon
}

interface IState {
    open: boolean
}

export default class WidgetButton extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    public render() {
        return (
            <div className="file-select__drop">
                <a className="file-select__doc pointer" onClick={this.props.onClick}>
                    {this.props.icon && <FA icon={this.props.icon} />}
                    <span className="d-sm-none">&nbsp;{this.props.shortName}</span>
                    <span className="d-none d-sm-inline-block">&nbsp;{this.props.fullName}</span>
                </a>
            </div>
        )
    }
}
