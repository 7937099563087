import React from 'react'
import { Card, CardHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import MetadataDefinitionList from '@src/components/metadata/definitions/MetadataDefinitionList'
import NewDefinitionModal, { INewDefinitionModalFormData } from '@src/components/metadata/definitions/NewDefinitionModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import { ProjectMetadataAdd, ProjectMetadataRetire, ProjectMetadataUpdate, RevisionsList } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { BaseMetadataTypes, IMetadataDefinition, IUpdateMetadataDefinition, MetadataTypes } from '@src/types/metadata'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    company: Company
}

interface IState {
    metadataDefinitions: IMetadataDefinition[]
}

export default class MetadataSettingsSection extends React.PureComponent<IProps, IState> {
    private static readonly validMetadataTypes: MetadataTypes[] = [MetadataTypes.Bool, MetadataTypes.Numeric, MetadataTypes.Text, MetadataTypes.Date, MetadataTypes.Select]

    constructor(props) {
        super(props)

        this.state = {
            metadataDefinitions: null
        }
    }

    public componentDidMount() {
        this.loadMetadataDefinitions()
    }

    private loadMetadataDefinitions = async () => {
        const revisionsResponse = await RevisionsList(this.props.project.id, undefined, undefined, undefined, 0)
        this.setState({ metadataDefinitions: revisionsResponse.data.metadataDefinitions })
    }

    private addMetadataDefinition = async (values: INewDefinitionModalFormData): Promise<IMetadataDefinition> => {
        const createdDefinition = await ProjectMetadataAdd(this.props.project.id, {
            name: values.name,
            key: values.key,
            description: '',
            tags: [],
            isRequired: false,
            options: {},
            type: values.type as BaseMetadataTypes
        })

        this.loadMetadataDefinitions()
        return createdDefinition.data
    }

    private deleteMetadataDefinition = async (key: string): Promise<void> => {
        try {
            await ProjectMetadataRetire(this.props.project.id, key)
            NotificationService.info('Deleted metadata')
        } catch {
            NotificationService.error('Unable to delete metadata')
        }
        this.loadMetadataDefinitions()
    }

    private updateMetadataDefinition = async (key: string, update: IUpdateMetadataDefinition): Promise<void> => {
        try {
            await ProjectMetadataUpdate(this.props.project.id, key, update)
            NotificationService.info('Updated metadata')
        } catch {
            NotificationService.error('Unable to update metadata')
        }
        this.loadMetadataDefinitions()
    }

    public render() {
        const metadataDisabled = !this.props.project.owner || this.props.project.owner.id !== this.props.company.id

        return (
            <div>
                <Card className="mb-3">
                    <CardHeader className="d-flex align-items-center">
                        <FA icon="list-ul" />
                        <span className="ml-2">Metadata</span>
                        <ModalToggle
                            modal={NewDefinitionModal}
                            modalProps={{ validTypes: MetadataSettingsSection.validMetadataTypes, onSubmit: this.addMetadataDefinition, existingDefinitions: [] }}
                            wrapperProps={{ className: 'ml-auto', disabled: metadataDisabled, id: 'add-project-metadata' }}
                        >
                            Add Metadata
                        </ModalToggle>
                    </CardHeader>
                    {this.state.metadataDefinitions &&
                    <MetadataDefinitionList
                        disabled={metadataDisabled}
                        definitions={this.state.metadataDefinitions}
                        onUpdateDefinition={this.updateMetadataDefinition}
                        onDeleteDefinition={this.deleteMetadataDefinition}
                    />}
                </Card>
            </div>
        )
    }
}
