import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Input } from 'reactstrap'

import { CancelToken } from 'axios'

import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import UserStatusIndicator from '@src/components/company/UserStatusIndicator'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useUpdateEffect from '@src/hooks/useUpdateEffect'
import { CompanyUserDisable, CompanyUserEnable, CompanyUsersList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { safeHtmlId } from '@src/logic/utils/Strings'
import { mutedNotSet } from '@src/logic/utils/ValueHelper'
import { ClientErrorCode } from '@src/types/http'
import { UserInternal, UserStatus } from '@src/types/principal'

const UsersSection: React.FC<RouteComponentProps> = ({ location: { search } }) => {
    const [userStatus, setUserStatus] = React.useState('All')
    const searchSectionRef = React.useRef<SearchSectionType<UserInternal, never>>()

    useUpdateEffect(
        () => {
            if (searchSectionRef.current) searchSectionRef.current.doSearch()
        },
        [userStatus]
    )

    async function onSearch(filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken) {
        const userStatusFilter = getUserStatusFilter()
        const filterWithStatus = filter != null && filter !== '' ? `${userStatusFilter} AND ${filter}` : userStatusFilter
        const response = await CompanyUsersList(filterWithStatus, sort, page, perPage, { cancelToken })

        return {
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        }
    }

    function getUserStatusFilter(): string {
        switch (userStatus) {
            case 'Enabled':
                return 'status: Enabled'
            // For simplicity in understanding, display activated users (company assigned, yet to login) as invited
            case 'Invited':
                return 'status: Activated'
            case 'Disabled':
                return 'status: Disabled'
            case 'All':
            default:
                return 'status: Enabled,Disabled,Activated,Invited'
        }
    }

    function handleUserStatusChange(e: React.ChangeEvent<HTMLInputElement>) {
        setUserStatus(e.currentTarget.value)
    }

    async function approveUserAsync(user: UserInternal) {
        try {
            await CompanyUserEnable(user.id)
            NotificationService.info(`User ${user.status === UserStatus.Disabled ? 'enabled' : 'approved'}`)
        } catch (err) {
            const defaultError = `There was an error while trying to ${user.status === UserStatus.Disabled ? 'enable' : 'approve'} the user.`
            if (!isAxiosError(err)) {
                NotificationService.error(defaultError)
            } else {
                switch (err.response.status) {
                    case ClientErrorCode.Forbidden:
                        NotificationService.error(`You must be a company administrator to ${user.status === UserStatus.Disabled ? 'enable' : 'approve'} a user.`)
                        break
                    default:
                        NotificationService.error(defaultError)
                }
            }
        }
        if (searchSectionRef.current) searchSectionRef.current.doSearch()
    }

    async function rejectUserAsync(user: UserInternal) {
        try {
            await CompanyUserDisable(user.id)
            NotificationService.info(`User ${user.status === UserStatus.Enabled ? 'disabled' : 'rejected'}`)
        } catch (err) {
            const defaultError = `There was an error while trying to ${user.status === UserStatus.Enabled ? 'disable' : 'reject'} the user.`
            if (!isAxiosError(err)) {
                NotificationService.error(defaultError)
            } else {
                switch (err.response.status) {
                    case ClientErrorCode.BadRequest:
                        NotificationService.error(err.response.data?.message ?? 'Cannot disable user that is not enabled')
                        break
                    case ClientErrorCode.Forbidden:
                        NotificationService.error(`You must be a company administrator to ${user.status === UserStatus.Enabled ? 'disable' : 'reject'} a user.`)
                        break
                    default:
                        NotificationService.error(defaultError)
                }
            }
        }

        if (searchSectionRef.current) searchSectionRef.current.doSearch()
    }

    return (
        <SearchSection
            ref={searchSectionRef}
            headers={[
                {
                    name: 'Name',
                    sortKey: 'firstName',
                    sortable: true,
                    overrideRenderer: u => (
                        <span className="d-inline-flex align-items-center">
                            <UserAvatar size="xs" imageUrl={u.profilePictureLink} firstName={u.firstName} lastName={u.lastName} />
                            <span className="ml-2 d-inline-flex"><UserStatusIndicator id={safeHtmlId(u.id)} status={u.status} /> {u.firstName} {u.lastName}</span>
                        </span>
                    )
                },
                {
                    name: 'Role',
                    accessor: 'role',
                    sortKey: 'role',
                    sortable: true,
                    defaultFallback: mutedNotSet
                },
                {
                    name: 'Mobile',
                    accessor: 'mobile',
                    sortKey: 'mobile',
                    sortable: true,
                    defaultFallback: mutedNotSet
                },
                {
                    name: 'Phone',
                    accessor: 'phone',
                    sortKey: 'phone',
                    sortable: true,
                    defaultFallback: mutedNotSet
                },
                {
                    name: 'Email',
                    accessor: 'email',
                    sortKey: 'email',
                    sortable: true,
                    defaultFallback: mutedNotSet
                },
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    overrideRenderer: user => (
                        <div className="justify-content-end text-right selectable-content__actions">
                            {user.status === 'Enabled' && <TooltipLinkAction id={`disable-user-${safeHtmlId(user.id)}`} data={user} onClick={rejectUserAsync} tooltip="Disable"><FA icon="times" /></TooltipLinkAction>}
                            {user.status === 'Disabled' && <TooltipLinkAction id={`enable-user-${safeHtmlId(user.id)}`} data={user} onClick={approveUserAsync} tooltip="Enable"><FA icon="check" /></TooltipLinkAction>}
                            {user.status === 'Activated' &&
                                <div>
                                    <TooltipLinkAction id={`approve-user-${safeHtmlId(user.id)}`} data={user} onClick={approveUserAsync} tooltip="Approve">
                                        <FA icon="user-check" />
                                    </TooltipLinkAction>
                                    <TooltipLinkAction id={`disable-user-${safeHtmlId(user.id)}`} data={user} onClick={rejectUserAsync} tooltip="Reject">
                                        <FA icon="user-slash" />
                                    </TooltipLinkAction>
                                </div>
                            }
                        </div>
                    )
                }
            ]}
            extraSearchBarElements={[
                {
                    element: _ => (
                        // tslint:disable-next-line:jsx-no-lambda
                        <Input type="select" value={userStatus} onChange={handleUserStatusChange}>
                            <option>All</option>
                            <option>Enabled</option>
                            <option>Invited</option>
                            <option>Disabled</option>
                        </Input>
                    ),
                    position: 'before'
                }
            ]}
            onSearch={onSearch}
            searchAssistantProperties={[
                {
                    name: 'First name',
                    searchKey: 'first_name',
                    type: PropertyType.Text
                },
                {
                    name: 'Last name',
                    searchKey: 'last_name',
                    type: PropertyType.Text
                },
                {
                    name: 'Email',
                    searchKey: 'email',
                    type: PropertyType.Text
                },
                {
                    name: 'Role',
                    searchKey: 'role',
                    type: PropertyType.Text
                },
                {
                    name: 'Phone',
                    searchKey: 'phone',
                    type: PropertyType.Text
                },
                {
                    name: 'Mobile',
                    searchKey: 'mobile',
                    type: PropertyType.Text
                }
            ]}
        />
    )
}

export default UsersSection
