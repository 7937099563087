interface IAddress {
    label: string
    street: string
    city: string
    stateProvince: string
    postalCode: string
    countryRegion: string
}

/**
 * vCard formatter for formatting vCards in VCF format
 */
export class VCard {

    public formattedName: string
    public firstName: string
    public middleName: string
    public lastName: string
    public namePrefix: string
    public nameSuffix: string
    public gender: string
    public uid: string
    public birthday: Date
    public anniversary: Date
    public email: string | string[]
    public workEmail: string | string[]
    public otherEmail: string | string[]
    public logo: {
        url: string
        mediaType: string
        base64: string
    }
    public photo: {
        url: string
        mediaType: string
        base64: string
    }
    public cellPhone: string | string[]
    public workPhone: string | string[]
    public title: string
    public role: string
    public organization: string
    public url: string
    public workUrl: string
    public workAddress: IAddress

    constructor() {
        this.getFormattedString = this.getFormattedString.bind(this)
    }

    /**
     * Get formatted vCard in VCF format
     * @param  {object}     vCard object
     * @return {String}     Formatted vCard in VCF format
     */
    public getFormattedString() {

        let formattedVCardString = ''
        formattedVCardString += 'BEGIN:VCARD' + this.nl()
        formattedVCardString += 'VERSION:' + 2.1 + this.nl()

        const encodingPrefix = ';CHARSET=UTF-8'
        let formattedName = this.formattedName

        if (!formattedName) {
            formattedName = '';

            [this.firstName, this.middleName, this.lastName]
                .forEach((name) => {
                    if (name) {
                        if (formattedName) {
                            formattedName += ' '
                        }
                    }
                    formattedName += name
                })
        }

        formattedVCardString += 'FN' + encodingPrefix + ':' + this.encode(formattedName) + this.nl()
        formattedVCardString += 'N' + encodingPrefix + ':' +
            this.encode(this.lastName) + ';' +
            this.encode(this.firstName) + ';' +
            this.encode(this.middleName) + ';' +
            this.encode(this.namePrefix) + ';' +
            this.encode(this.nameSuffix) + this.nl()

        if (this.gender) {
            formattedVCardString += 'GENDER:' + this.encode(this.gender) + this.nl()
        }

        if (this.uid) {
            formattedVCardString += 'UID' + encodingPrefix + ':' + this.encode(this.uid) + this.nl()
        }

        if (this.birthday) {
            formattedVCardString += 'BDAY:' + this.formatDate(this.birthday) + this.nl()
        }

        if (this.anniversary) {
            formattedVCardString += 'ANNIVERSARY:' + this.formatDate(this.anniversary) + this.nl()
        }

        if (this.email) {
            if (!(this.email instanceof Array)) {
                this.email = [this.email]
            }
            this.email.forEach(address => formattedVCardString += 'EMAIL' + encodingPrefix + ';HOME;INTERNET:' + this.encode(address) + this.nl())
        }

        if (this.workEmail) {
            if (!(this.workEmail instanceof Array)) {
                this.workEmail = [this.workEmail]
            }
            this.workEmail.forEach(address => formattedVCardString += 'EMAIL' + encodingPrefix + ';WORK;INTERNET:' + this.encode(address) + this.nl())
        }

        if (this.otherEmail) {
            if (!(this.otherEmail instanceof Array)) {
                this.otherEmail = [this.otherEmail]
            }
            this.otherEmail.forEach(address => formattedVCardString += 'EMAIL' + encodingPrefix + ';OTHER;INTERNET:' + this.encode(address) + this.nl())
        }

        if (this.logo && this.logo.url) {
            formattedVCardString += this.getFormattedPhoto('LOGO', this.logo.url, this.logo.mediaType, this.logo.base64)
        }

        if (this.photo && this.photo.url) {
            formattedVCardString += this.getFormattedPhoto('PHOTO', this.photo.url, this.photo.mediaType, this.photo.base64)
        }

        if (this.cellPhone) {
            if (!(this.cellPhone instanceof Array)) {
                this.cellPhone = [this.cellPhone]
            }
            this.cellPhone.forEach(number => formattedVCardString += 'TEL;TYPE=CELL:' + this.encode(number) + this.nl())
        }

        if (this.workPhone) {
            if (!(this.workPhone instanceof Array)) {
                this.workPhone = [this.workPhone]
            }
            this.workPhone.forEach(number => formattedVCardString += 'TEL;TYPE=WORK,VOICE:' + this.encode(number) + this.nl())
        }

        if (this.workAddress) {
            formattedVCardString += this.getFormattedAddress(encodingPrefix, { details: this.workAddress, type: 'WORK' })
        }

        if (this.title) {
            formattedVCardString += 'TITLE' + encodingPrefix + ':' + this.encode(this.title) + this.nl()
        }

        if (this.role) {
            formattedVCardString += 'ROLE' + encodingPrefix + ':' + this.encode(this.role) + this.nl()
        }

        if (this.organization) {
            formattedVCardString += 'ORG' + encodingPrefix + ':' + this.encode(this.organization) + this.nl()
        }

        formattedVCardString += 'REV:' + (new Date()).toISOString() + this.nl()
        formattedVCardString += 'END:VCARD' + this.nl()
        return formattedVCardString
    }

    private encode(value) {
        if (value) {
            const strValue = typeof (value) !== 'string' ? '' + value : value
            return strValue.replace(/\n/g, '\\n').replace(/,/g, '\\,').replace(/;/g, '\\;')
        }
        return ''
    }

    private nl() {
        return '\r\n'
    }

    private getFormattedPhoto = (photoType, url, mediaType, base64) => {
        const params = base64 ? ';ENCODING=BASE64;' : ';'
        const formattedPhoto = photoType + params + mediaType + ':' + this.encode(url) + this.nl()
        return formattedPhoto
    }

    private getFormattedAddress = (encodingPrefix: string, address: { details: IAddress, type: string }) => {

        let formattedAddress = ''

        if (address.details.label ||
            address.details.street ||
            address.details.city ||
            address.details.stateProvince ||
            address.details.postalCode ||
            address.details.countryRegion) {

            if (address.details.label) {
                formattedAddress = 'LABEL' + encodingPrefix + ';TYPE=' + address.type + ':' + this.encode(address.details.label) + this.nl()
            }
            formattedAddress += 'ADR' + encodingPrefix + ';TYPE=' + address.type + ':;;' +
                this.encode(address.details.street) + ';' +
                this.encode(address.details.city) + ';' +
                this.encode(address.details.stateProvince) + ';' +
                this.encode(address.details.postalCode) + ';' +
                this.encode(address.details.countryRegion) + this.nl()

        }

        return formattedAddress
    }

    private formatDate(date: Date) {
        return date.getFullYear() + ('0' + (date.getMonth() + 1)).slice(-2) + ('0' + date.getDate()).slice(-2)
    }

}
