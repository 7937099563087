import { Action, handleActions } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { Features } from '@src/logic/features/features'
import { Company } from '@src/types/principal'
import { Session } from '@src/types/session'

const initialState: Session.State = {
    company: null,
    user: null,
    features: null,
    account: null,
    error: null
}

export default handleActions<Session.State, any>(
    {
        [Actions.SESSION_SET_PROFILE]: (state, action) => {
            return { ...state,  user: action.payload }
        },
        [Actions.SESSION_SET_COMPANY]: (state, action: Action<Company>) => {
            return { ...state, company: action.payload }
        },
        [Actions.SESSION_SET_FEATURES]: (state, action: Action<Features>) => {
            return { ...state, features: action.payload }
        },
        [Actions.SESSION_SET_ACCOUNT]: (state, action: Action<Session.AuthAccount>) => {
            return { ...state, account: action.payload }
        },
        [Actions.SESSION_CLEAR]: (state, action) => {
            return initialState
        },
        [Actions.SESSION_RAISE_ERROR]: (state, action) => {
            return { ...state, error: action.payload }
        }
    },
    initialState
)
