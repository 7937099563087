import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { DocumentAccessGet, DocumentAccessUpdate, RevisionAccessGet, RevisionAccessUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

interface IProps {
    revision: Revision
}

const DocumentAccessPage: React.FC<IProps> = ({ revision }) => {

    const company = useSelector<RootState, Company>(s => s.session.company)

    async function getRevisionAcl() {
        const response = await RevisionAccessGet(revision.id)
        return response.data
    }

    async function getDocumentAcl() {
        const response = await DocumentAccessGet(revision.documentId)
        return response.data
    }

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    async function saveRevisionAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await RevisionAccessUpdate(revision.id, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    async function saveDocumentAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await DocumentAccessUpdate(revision.documentId, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    return (
        <>
            <Card className="mb-3">
                <CardHeader><FA icon="lock" /> Revision Access Control</CardHeader>
                <CardBody>
                    <AccessControlTable getAcl={getRevisionAcl} commitUpdates={saveRevisionAccessUpdates} loadPrincipals={loadCompanyPrincipals} disabled={!revision.myAccess.isAdministrator} />
                </CardBody>
            </Card>
            <Card className="mb-3">
                <CardHeader><FA icon="lock" /> Document Access Control</CardHeader>
                <CardBody>
                    <AccessControlTable getAcl={getDocumentAcl} commitUpdates={saveDocumentAccessUpdates} loadPrincipals={loadCompanyPrincipals} />
                </CardBody>
            </Card>
        </>
    )
}

export default DocumentAccessPage
