// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CommunicationToolbar from '@src/components/communication/CommunicationToolbar'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { TransmittalOperations } from '@src/logic/auth/operations'
import { CommunicationAccessGet, CommunicationAccessUpdate, CommunicationGet } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import AccessPage from '@src/pages/common/AccessPage'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import CommunicationEditPage from '@src/pages/communication/CommunicationEditPage'
import { Api } from '@src/types/api'
import { Communication } from '@src/types/communication'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IConnectedState {
    project: Project
    company: Company
}

interface IState {
    communication: Communication
    communicationLoadError: ClientErrorCode
}

class CommunicationPages extends React.PureComponent<RouteComponentProps<Routes.IProjectCommunicationParams> & IConnectedState, IState> {

    constructor(props) {
        super(props)

        this.state = {
            communication: null,
            communicationLoadError: null
        }
    }

    public componentDidMount() {
        this.loadCommunication()
    }

    private loadCommunication = async (communication?: Communication) => {
        if (communication) {
            this.setState({ communication })
            return
        }

        try {
            const response = await CommunicationGet(this.props.match.params.communicationId)
            this.setState({ communication: response.data, communicationLoadError: null })
        } catch (err) {
            if (isAxiosError(err)) {
                if (!err.response) {
                    throw err
                }

                switch (err.response.status) {
                    case 403:
                        this.setState({ communicationLoadError: ClientErrorCode.Forbidden })
                    case 404:
                        this.setState({ communicationLoadError: ClientErrorCode.NotFound })
                    default:
                        throw err
                }
            }
        }
    }

    private getBannerTitle = () => {
        if (this.state.communicationLoadError) {
            switch (this.state.communicationLoadError) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Transmittal Not Found'
                default:
                    return ''
            }
        }

        return this.state.communication ? this.state.communication.name : <>Loading Communication <FA icon="spinner-third" spin /></>
    }

    public getCommunicationAcl = async (communication: Communication) => {
        return (await CommunicationAccessGet(communication.id)).data
    }

    public saveCommunicationAccessUpdates = async (communication: Communication, accessUpdate: Api.Request.AccessControlListUpdate) => {
        try {
            await CommunicationAccessUpdate(communication.id, accessUpdate)
            await this.loadCommunication()
        } catch {
            return
        }
    }

    public render() {
        const { project, company, match, location } = this.props
        const { communication } = this.state

        return (
            <CrumbRoute
                path=""
                linkPath={communication ? Routes.projectCommunication(project.id, communication.id) : match.url}
                title={communication?.name ?? ''}
            >
                <div>
                    <ProjectBanner
                        project={project}
                        overrideTitle={this.getBannerTitle()}
                    />
                    {communication && <CommunicationToolbar currentRoutePath={location.pathname} project={project} communication={communication} reloadCommunication={this.loadCommunication} />}
                    <div className="mb-3" />
                    <ClientErrorPage clientErrorCode={this.state.communicationLoadError} resourceName="communication" requiredOperation={TransmittalOperations.Read}>
                        {communication &&
                        <Switch>
                            <Route path={Routes.PROJECT_COMMUNICATION_EDIT} exact render={routeProps => <CommunicationEditPage {...routeProps} communication={communication} reloadCommunication={this.loadCommunication} />} />
                            <CrumbRoute
                                path={Routes.PROJECT_COMMUNICATION_ACCESS}
                                title="Access"
                                render={() =>
                                    <AccessPage
                                        name="Communication"
                                        entity={communication}
                                        company={company}
                                        getEntityAcl={this.getCommunicationAcl}
                                        saveAccessUpdate={this.saveCommunicationAccessUpdates}
                                    />
                                }
                            />
                            {/* <Route path={Routes.PROJECT_TRANSMITTAL_VIEW} render={routeProps => <CommunicationViewPage communication={communication} />} /> */}
                            <Redirect to={Routes.projectCommunicationEdit(project.id, communication.id)} />
                        </Switch>}
                    </ClientErrorPage>
                </div>
            </CrumbRoute>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<any>): RouteComponentProps<any> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        company: state.session.company
    }
}

export default connect(mapStateToProps)(CommunicationPages)
