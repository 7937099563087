interface IDeferredPromise<T> {
    promise: Promise<T>
    resolve: (value: T) => void
    reject: (error: Error) => void
}

function getWait(wait: number | (() => number)): number {
    return (typeof wait === 'function') ? wait() : wait
}

function defer<T>() {
    const deferred: IDeferredPromise<T> = {
        promise: undefined,
        resolve: undefined,
        reject: undefined
    }
    deferred.promise = new Promise((resolve, reject) => {
        deferred.resolve = resolve
        deferred.reject = reject
    })
    return deferred
}

interface IDebouncePromiseOptions {
    leading: boolean
    accumulate: boolean
    maxWait: number | (() => number)
}

export function debouncePromise<T extends (...args: any) => any>(fn: T, wait: number | (() => number) = 0, options: Partial<IDebouncePromiseOptions> = {}): (
    ...args: Parameters<T>
) => any {
    let lastCallAt: number
    let lastInvokedAt: number
    let deferred: IDeferredPromise<T>
    let timer: number
    let pendingArgs = []
    return function debounced(...args: Parameters<T>) {
        const currentTime = new Date().getTime()
        const timeSinceLastCall = currentTime - lastCallAt
        const timeSinceLastInvoke = currentTime - lastInvokedAt
        const currentWait = getWait(wait)

        const isCold = !lastCallAt || timeSinceLastCall > currentWait || (timeSinceLastCall < 0) || (options.maxWait && timeSinceLastInvoke >= getWait(options.maxWait))

        lastCallAt = currentTime

        if (isCold && options.leading) {
            lastInvokedAt = currentTime
            return options.accumulate
                ? Promise.resolve(fn.call(this, [args])).then(result => result[0])
                : Promise.resolve(fn.call(this, ...(args as any[])))
        }

        if (deferred) {
            clearTimeout(timer)
        } else {
            deferred = defer()
        }

        pendingArgs.push(args)
        timer = window.setTimeout(flush.bind(this), currentWait)

        if (options.accumulate) {
            const argsIndex = pendingArgs.length - 1
            return deferred.promise.then(results => results[argsIndex])
        }

        return deferred.promise
    }

    function flush() {
        const thisDeferred = deferred
        clearTimeout(timer)

        lastInvokedAt = new Date().getTime()
        Promise.resolve(
            options.accumulate
                ? fn.call(this, pendingArgs)
                : fn.apply(this, pendingArgs[pendingArgs.length - 1])
        )
            .then(thisDeferred.resolve, thisDeferred.reject)

        pendingArgs = []
        deferred = null
    }
}

interface IThrottlePromiseOptions {
    accumulate: boolean
}

export function throttlePromise<T extends (...args: any) => any>(fn: T, wait: number | (() => number) = 0, options: Partial<IThrottlePromiseOptions> = {}): (
    ...args: Parameters<T>
) => ReturnType<T> extends Promise<any>
? ReturnType<T>
: Promise<ReturnType<T>> {
    return debouncePromise(fn, wait, { ...options, maxWait: wait, leading: true })
}

export async function promiseAllCapped<T, TResult>(items: T[], task: (item: T) => Promise<TResult>, concurrency: number): Promise<TResult[]> {
    let i = 0
    let remaining = items.length
    const outcome: TResult[] = []
    let rejected: boolean = false
    let res: (value?: TResult[] | PromiseLike<TResult[]>) => void
    let rej: (reason?: any) => void

    const p: Promise<TResult[]> = new Promise<TResult[]>((resolve, reject) => {
        res = resolve
        rej = reject
    })

    async function runTask(itemIndex: number) {
        try {
            outcome[itemIndex] = await task(items[itemIndex])
            remaining--
            if (remaining <= 0) res(outcome)
            else if (i < items.length) runTask(i++)
        } catch (error) {
            if (rejected) return
            rejected = true
            rej(error)
        }
    }

    while (i < Math.min(items.length, concurrency)) {
        runTask(i++)
    }

    return await p
}
