import React from 'react'
import { Field, FormSpy } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'

interface IProps<TField, TSet> {
    field: string
    becomes: TField
    set: string
    to: TSet
    isEqual?: (value: TField, becomes: TField) => boolean
    clearTouched?: boolean
}

function WhenFieldChanges<TField, TSet>(props: IProps<TField, TSet>) {
    const { field, becomes, set, to, isEqual, clearTouched } = props

    function buildEqualsAction(onChange: (value: TSet) => void) {
        return (newValue: TField) => {
            const equal = isEqual ? isEqual(newValue, becomes) : newValue === becomes
            if (equal) onChange(to)
        }
    }

    return (
        <Field name={set} subscription={{}}>
            {(
                // No subscription. We only use Field to get to the change function
                { input: { onChange } }
            ) => (
                    <FormSpy subscription={{}}>
                        {({ form }) => (
                            <OnChange name={field}>
                                {(newValue: TField) => {
                                    const equal = isEqual ? isEqual(newValue, becomes) : newValue === becomes
                                    if (!equal) return
                                    if (clearTouched) form.resetFieldState(set)
                                    setTimeout(() => onChange(to))
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
        </Field>
    )
}

export default WhenFieldChanges
