import React from 'react'
import { connect } from 'react-redux'
import { Label, Progress } from 'reactstrap'
import { bindActionCreators, Dispatch } from 'redux'

import * as SandboxActions from '@src/actions/sandbox'
import FA from '@src/components/common/FontAwesomeIcon'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { Sandbox } from '@src/types/sandbox'

interface IProps {
    upload: Sandbox.Upload
}

interface IConnectedDispatch {
    actions: typeof SandboxActions
}

class SandboxUpload extends React.PureComponent<IProps & IConnectedDispatch> {

    private uploadCanPause(upload: Sandbox.Upload) {
        return (upload.state === 'running' || upload.state === 'pausing')
    }

    private uploadCanResume(upload: Sandbox.Upload) {
        return (upload.state === 'paused')
    }

    private pause = () => {
        if (this.uploadCanPause(this.props.upload)) {
            this.props.actions.pauseUploads([this.props.upload.id])
        }
    }

    private resume = () => {
        if (this.uploadCanResume(this.props.upload)) {
            this.props.actions.resumeUploads([this.props.upload.id])
        }
    }

    private delete = () => {
        this.props.actions.deleteUploads(this.props.upload.id)
    }

    public render() {
        const { upload } = this.props

        const progress = upload.state === 'pausing' ?
            (
                <Progress multi style={{ height: '0.5rem' }}>
                    <Progress bar value={upload.bytesUploaded / upload.bytesTotal * 100} />
                    <Progress bar color="warning" value={(upload.pausingAtByte - upload.bytesUploaded) / upload.bytesTotal * 100}/>
                </Progress>
            ) : <Progress style={{ height: '0.5rem' }} value={upload.bytesUploaded / upload.bytesTotal * 100} />

        return (
            <div className="document-sandbox__file">
                <div className="d-flex">
                    <Label className="flex-grow-1 text-truncate"><FA icon={contentTypeIcon(upload.contentType)} /> {upload.filename}</Label>
                    <span className="flex-shrink-1">
                        {upload.state === 'completed' ? null : this.uploadCanPause(this.props.upload) ? <FA icon="pause" className="pointer" onClick={this.pause} /> : <FA icon="play" className="pointer" onClick={this.resume} />}
                        <FA className="pointer" icon="trash" onClick={this.delete} />
                    </span>
                </div>
                <div className="document-sandbox__progress d-flex justify-content-between align-items-center">
                    {progress}
                    <div className="document-sandbox__icon">
                        {upload.state === 'completed' ?
                            <FA icon="check" className="text-success" />
                            : <FA icon="sync" className="text-tertiary" spin />
                        }
                    </div>
                </div>
            </div>
        )
    }
}

function mapStateToProps(state, ownProps) {
    return ownProps
}

function mapDispatchToProps(dispatch: Dispatch): IConnectedDispatch {
    return {
        actions: bindActionCreators(SandboxActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SandboxUpload)
