import React from 'react'

export interface IModalProps {
    isOpen: boolean
    toggle: () => void
    onClosed?: () => void
}

interface IModalHook {
    show: () => void
    hide: () => void
}

export default function useModal(initialValue?: boolean, options: { onClose?: () => void } = {}): IModalProps & IModalHook {
    const [open, setOpen] = React.useState<boolean>(initialValue)

    React.useEffect(
        () => {
            if (!open && options.onClose) options.onClose()
        },
        [open]
    )

    function toggle() {
        setOpen(!open)
    }

    function show() {
        setOpen(true)
    }

    function hide() {
        setOpen(false)
    }

    return { toggle, show, hide, onClosed: options.onClose, isOpen: open }
}
