// tslint:disable:jsx-no-lambda

import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, RouteComponentProps, Switch } from 'react-router'

import CompanyBanner from '@src/components/company/CompanyBanner'
import CompanyToolbar from '@src/components/company/CompanyToolbar'
import GroupsSection from '@src/components/company/GroupsSection'
import ProjectsSection from '@src/components/company/ProjectsSection'
import TemplatesSection from '@src/components/company/TemplatesSection'
import UsersSection from '@src/components/company/UsersSection'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

const CompanyDashboard: React.FC<RouteComponentProps> = ({ match }) => {

    const company = useSelector<RootState, Company>(s => s.session.company)

    return (
        <>
            <CompanyBanner company={company} />
            <CompanyToolbar company={company} />
            <Switch>
                <CrumbRoute title="Projects" path={Routes.COMPANY_PROJECTS} render={routeProps => <ProjectsSection {...routeProps} company={company} />} />
                <CrumbRoute title="Users" path={Routes.COMPANY_USERS} component={UsersSection} />
                <CrumbRoute title="Groups" path={Routes.COMPANY_GROUPS} component={GroupsSection} />
                <CrumbRoute title="Templates" path={Routes.COMPANY_TEMPLATES} component={TemplatesSection} />
                <Redirect from={match.path} to={Routes.COMPANY_PROJECTS} />
            </Switch>
        </>
    )
}

export default CompanyDashboard
