import React from 'react'
import { Col, ColProps } from 'reactstrap'

import cx from 'classnames'

import Sort from '@src/components/common/Sort'

interface ICellProps extends ColProps {
    links?: boolean
    actions?: boolean
}

interface IHeaderCellProps extends ColProps {
    header: boolean
    sortField?: string
    onSort?: (sortField: string) => void
    currentSort?: string
}

interface IState {
    revealSort: boolean
}

export default class Cell extends React.PureComponent<ICellProps | IHeaderCellProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            revealSort: false
        }
    }

    private handleSort = (e: React.MouseEvent<HTMLDivElement>) => {
        const { onClick, onSort, sortField } = this.props as IHeaderCellProps
        if (onSort) {
            onSort(sortField)
        }
        if (onClick) {
            onClick(e)
        }
    }

    private handleMouseEnter = (e) => {
        const { onMouseEnter, onSort } = this.props as IHeaderCellProps
        if (onSort) {
            this.setState({ revealSort: true })
        }
        if (onMouseEnter) {
            onMouseEnter(e)
        }
    }

    private handleMouseLeave = (e) => {
        const { onMouseLeave, onSort } = this.props as IHeaderCellProps
        if (onSort) {
            this.setState({ revealSort: false })
        }
        if (onMouseLeave) {
            onMouseLeave(e)
        }
    }

    public render() {
        const { className, header, links, actions, sortField, onSort, currentSort, ...rest } = this.props as any

        return (
            <Col
                className={cx(className, 'selectable-content__cell', { 'd-none d-lg-table-cell': header, 'selectable-content__links': links, 'selectable-content__actions': actions, pointer: onSort != null })}
                onClick={this.handleSort}
                onMouseEnter={this.handleMouseEnter}
                onMouseLeave={this.handleMouseLeave}
                {...rest}
            >
                    {this.props.children}
                    {header && sortField && <>&nbsp;<Sort onClick={this.handleSort} reveal={this.state.revealSort} field={sortField} currentSort={currentSort} /></>}
            </Col>
        )
    }
}
