import log from 'loglevel'

import { CompanyUsersList, GroupsList } from '@src/logic/http/Api'
import { AclEntry, UserAccess } from '@src/types/access'
import { Company } from '@src/types/principal'

export async function loadPrincipals(company: Company) {
    const principalEntries: AclEntry[] = []
    const usersPromise = CompanyUsersList(undefined, undefined, 1, 200)
    const groupsPromise = GroupsList(undefined, 1, 200)

    const results = await Promise.all([usersPromise, groupsPromise])

    principalEntries.push(...results[0].data.filter(x => x.status === 'Enabled').map<AclEntry>(u => ({
        id: u.id,
        name: u.firstName + ' ' + u.lastName,
        email: u.email,
        company: company.id,
        isAdministrator: undefined,
        denials: [],
        grants: [],
        authorised: [],
        type: 'user'
    })))

    principalEntries.push(...results[1].data.map<AclEntry>(g => ({
        id: g.id,
        name: g.name,
        email: undefined,
        company: company.id,
        isAdministrator: undefined,
        denials: [],
        grants: [],
        authorised: [],
        type: 'group'
    })))

    principalEntries.push({
        id: company.id,
        company: company.id,
        name: company.name,
        email: undefined,
        isAdministrator: undefined,
        denials: [],
        grants: [],
        authorised: [],
        type: 'company'
    })

    return principalEntries
}

export function isAuthorised(userAccess: UserAccess, ...operations: string[]): boolean {
    if (!operations.every(o => userAccess.validOperations.includes(o))) {
        log.error(`Valid operations does not contain one of the following operations: ${operations.join(', ')}`)
        return false
    }
    if (userAccess.isAdministrator) { return true }

    if (operations.every(o => userAccess.authorisedOperations.includes(o))) {
        return true
    }

    return false
}
