import React from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { Button, Col, Container, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import GroupCard from '@src/components/group/GroupCard'
import GroupMemberList from '@src/components/group/GroupMemberList'
import GroupModal from '@src/components/group/GroupModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import GenericSearchBarWithPagination from '@src/components/search/GenericSearchBarWithPaging'
import { ISearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import useBoolean from '@src/hooks/useBoolean'
import { GroupDelete, GroupsList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import { getSearchParamsFromQuery } from '@src/logic/search/SearchStateHelpers'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { RootState } from '@src/types/models'
import { Company, Group } from '@src/types/principal'

const GroupSearchProperties: ISearchProperty[] = [
    {
        name: 'Name',
        searchKey: 'name',
        type: PropertyType.Text
    },
    {
        name: 'Notes',
        searchKey: 'notes',
        type: PropertyType.Text
    }
]

const GroupsSection: React.FC = () => {
    const company = useSelector<RootState, Company>(s => s.session.company)
    const location = useLocation()

    const [search, setSearch] = React.useState<{
        searchFilter: string
        sort: string
        page: number
        perPage: number
    }>(getSearchParamsFromQuery(location.search, 1, 16))

    const [selectedGroup, setSelectedGroup] = React.useState<Group>()
    const isCreatingOrEditing = useBoolean(false)
    const isDeleting = useBoolean(false)
    const isViewing = useBoolean(false)

    const groupsAsync = useAsyncCancellable(
        (cancelToken, _page?, _perPage?) => GroupsList(search.searchFilter, search.page, search.perPage, { cancelToken }).then(r => ({ groups: r.data, totalNumberOfGroups: r.headers[Headers.PaginationTotalCount] })),
        [search.page, search.perPage],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    function handleFilterChange(searchFilter: string, triggerSearch: boolean) {
        setSearch(s => ({ ...s, searchFilter }))
        if (triggerSearch) groupsAsync.execute()
    }

    function clearSelectedGroup() {
        setSelectedGroup(undefined)
    }

    function handleSelectPage(page: number) {
        setSearch(s => ({ ...s, page }))
    }

    function handleSelectPerPage(perPage: number) {
        setSearch(s => ({ ...s, perPage }))
    }

    function setSelectedGroupToEdit(group: Group) {
        setSelectedGroup(group)
        isCreatingOrEditing.setTrue()
    }

    function setSelectedGroupToView(group: Group) {
        setSelectedGroup(group)
        isViewing.setTrue()
    }

    function setSelectedGroupToDelete(group: Group) {
        setSelectedGroup(group)
        isDeleting.setTrue()
    }

    async function deleteGroup() {
        await GroupDelete(selectedGroup.id)
        isDeleting.setFalse()
        groupsAsync.execute()
    }

    return (
        <>
            <GenericSearchBarWithPagination
                filter={search.searchFilter}
                onSelectPage={handleSelectPage}
                onSelectPerPage={handleSelectPerPage}
                onSearch={groupsAsync.execute}
                onFilterChange={handleFilterChange}
                perPage={search.perPage}
                currentPage={search.page}
                totalItems={groupsAsync.result && groupsAsync.result.totalNumberOfGroups}
                searchAssistantProperties={GroupSearchProperties}
                extraElements={[
                    {
                        position: 'before',
                        element: _ => (
                            <Button onClick={isCreatingOrEditing.setTrue}><FA icon="plus" /> New Group</Button>
                        )
                    }
                ]}
            />
            <Container fluid>
                <Row className="my-3">
                    {groupsAsync.result && groupsAsync.result.groups.map(group => (
                        <Col key={group.id} xs={12} md={4} xl={3} style={{ height: 180 }} className="my-2">
                            <GroupCard
                                isAdminGroup={company.adminGroup === group.id}
                                group={group}
                                onClickEdit={setSelectedGroupToEdit}
                                onClickMembers={setSelectedGroupToView}
                                onClickDelete={setSelectedGroupToDelete}
                            />
                        </Col>
                    ))}
                </Row>
                <GroupModal
                    open={isCreatingOrEditing.value}
                    toggle={isCreatingOrEditing.setFalse}
                    onClosed={clearSelectedGroup}
                    group={selectedGroup}
                    onSave={groupsAsync.execute}
                />
                <Modal isOpen={isViewing.value} toggle={isViewing.setFalse} onClosed={clearSelectedGroup}>
                    <ModalHeader toggle={isViewing.setFalse}>{selectedGroup?.name}</ModalHeader>
                    <ModalBody>
                        <h5>Description</h5>
                        <p>{selectedGroup?.notes || <em>{mutedValue('No description...')}</em>}</p>
                        {selectedGroup && <GroupMemberList group={selectedGroup} />}
                    </ModalBody>
                    <ModalFooter><Button color="primary" onClick={isViewing.setFalse}>Close</Button></ModalFooter>
                </Modal>
                <ConfirmationModal
                    danger
                    open={isDeleting.value}
                    header="Delete Group"
                    onClosed={clearSelectedGroup}
                    toggle={isDeleting.setFalse}
                    message={
                        <div>
                            <span>Are you sure you want to delete <strong>{selectedGroup?.name}</strong>?</span>
                            <p className="mt-1">
                                <small>
                                    <FA className="mr-1 text-danger" icon="exclamation-triangle" />
                                    <em>Deleting groups that are in use may cause permissions issues. It is recommended that you consider loss of access to certain project resources for users in this group.</em>
                                </small>
                            </p>
                        </div>
                    }
                    onConfirm={deleteGroup}
                    onReject={isDeleting.setFalse}
                    confirmAction="Yes, delete"
                />
            </Container>
        </>
    )
}

export default GroupsSection
