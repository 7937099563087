import React from 'react'
import { connect } from 'react-redux'
import { match as Match, Route, RouteComponentProps, RouteProps, generatePath, matchPath, withRouter } from 'react-router'
import { Dispatch, bindActionCreators } from 'redux'

import * as UUID from 'uuid'

import * as BreadcrumbActions from '@src/actions/breadcrumb'

interface IProps<T> extends RouteProps {
    title: string | ((match: Match<T>) => string)
    path?: string
    linkPath?: string
}

interface IConnectedDispatch {
    crumbActions: typeof BreadcrumbActions
}

class CrumbRoute<T> extends React.PureComponent<IProps<T> & RouteComponentProps & IConnectedDispatch> {
    private readonly id: string

    constructor(props: IProps<T> & RouteComponentProps & IConnectedDispatch) {
        super(props)
        this.id = UUID.v4()
        props.crumbActions.addCrumb({ id: this.id, title: this.getTitle(), path: this.getCrumbPath(props.linkPath) })
    }

    public componentDidUpdate(prevProps) {
        if (prevProps.title !== this.props.title || prevProps.path !== this.props.path) {
            this.props.crumbActions.updateCrumb({ id: this.id, title: this.getTitle(), path: this.getCrumbPath(this.props.linkPath) })
        }
    }

    public componentWillUnmount() {
        this.props.crumbActions.deleteCrumb(this.id)
    }

    private readonly getTitle = () => {
        const { location, title, path } = this.props
        const match = matchPath<T>(location.pathname, { path: path, exact: false, strict: false })
        if (title == null) return null
        if (typeof title === 'function') {
            return title(match)
        }
        return title
    }

    private readonly getCrumbPath = (overrideLink?: string) => {
        const { path } = this.props
        const match = this.getMatch()
        return overrideLink ? generatePath(overrideLink, match?.params ?? {}) : generatePath(path, match.params ?? {})
    }

    private readonly getMatch = () => {
        return matchPath<T>(this.props.location.pathname, { path: this.props.path, exact: false, strict: false })
    }

    public render() {
        const { title, path, linkPath, ...routeProps } = this.props
        return (
            <Route {...routeProps} />
        )
    }
}

function mapDispatchToProps<T = any>(dispatch: Dispatch, ownProps: IProps<T>): IConnectedDispatch {
    return {
        crumbActions: bindActionCreators(BreadcrumbActions, dispatch)
    }
}

export default connect(null, mapDispatchToProps)(withRouter(CrumbRoute))
