import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { ProjectAccessGet, ProjectAccessUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    company: Company
    project: Project
}

const ProjectAccessSection: React.FC<IProps> = ({ company, project }) => {

    async function getProjectAcl() {
        const response = await ProjectAccessGet(project.id)
        return response.data
    }

    async function saveProjectAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await ProjectAccessUpdate(project.id, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    return (
        <Card className="mb-3">
            <CardHeader><FA icon="lock" /> Project Access Control</CardHeader>
            <CardBody>
                <AccessControlTable getAcl={getProjectAcl} commitUpdates={saveProjectAccessUpdates} loadPrincipals={loadCompanyPrincipals} disabled={!project.myAccess.isAdministrator} />
            </CardBody>
        </Card>
    )
}

export default ProjectAccessSection
