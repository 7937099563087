import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'

interface IProps {
    projectId: string
    currentRoutePath: string
}

export default class CostsSettingsToolbar extends React.PureComponent<IProps> {
    public render() {
        const { projectId } = this.props
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_SETTINGS, true)} to={Routes.projectCostsSettings(projectId)} name="General" nameBrief="General" iconBrief="cog" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_SETTINGS_PHASES)} to={Routes.projectCostsSettingsPhases(projectId)} name="Phases" nameBrief="Phases" iconBrief="stream" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_SETTINGS_CODES)} to={Routes.projectCostsSettingsCodes(projectId)} name="Codes" nameBrief="Codes" iconBrief="list-ol" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITIONS, false)} to={Routes.projectCostsSettingsCommitmentDefinitions(projectId)} name="Commitment Types" nameBrief="Commit" iconBrief={'badge-dollar' as any} />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_SETTINGS_BUDGET)} to={Routes.projectCostsSettingsBudget(projectId)} name="Budget" nameBrief="Budget" iconBrief="chart-pie" />
                </Toolbar>
            </section>
        )
    }
}
