import React, { useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { Button, Card, CardBody, CardHeader, Col, Container, Row } from 'reactstrap'

import axios, { CancelToken } from 'axios'
import moment from 'moment'

import CollapsibleCard from '@src/components/card/CollapsibleCard'
import AvatarList from '@src/components/common/AvatarList'
import ClampLines from '@src/components/common/ClampLines'
import DetailList from '@src/components/common/DetailList'
import FA from '@src/components/common/FontAwesomeIcon'
import FroalaViewer from '@src/components/common/FroalaViewer'
import LinkWithTooltip from '@src/components/common/LinkWithTooltip'
import TrackingList from '@src/components/common/TrackingList'
import DocumentTableTitle from '@src/components/document/DocumentTableTitle'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import { PropertyType, metadataSearchProperty } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import GenericContentTable, { ITableHeader } from '@src/components/table/GenericContentTable'
import TransmittalToolbar from '@src/components/transmittal/TransmittalToolbar'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import useProjectWidget from '@src/hooks/useWidget'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { ProjectOperations } from '@src/logic/auth/operations'
import { TransmittalApprove, TransmittalAttachmentsDownloadLink, TransmittalCreate, TransmittalRevisionsList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { mutedNotSet, mutedValue, valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { ApprovalStatus, Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Transmittal, TransmittalAddress, TransmittalStatus } from '@src/types/transmittal'

interface IProps {
    transmittal: Transmittal
    reloadTransmittal: (transmittal?: Transmittal) => void
}

const TransmittalViewPage: React.FC<IProps> = ({ transmittal, reloadTransmittal }) => {
    const revisionsSectionRef = useRef<SearchSectionType<Revision, 'id'>>()
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const history = useHistory()
    const [metadataDefinitions, setMetadataDefinitions] = useState<IMetadataDefinition[]>([])
    const widget = useProjectWidget()

    const forwardAsync = useAsyncCancellable(
        cancelToken => TransmittalCreate(
            transmittal.projectId,
            {
                approvalRequestComments: '',
                bcc: transmittal.bcc?.map(x => x.id) ?? [],
                body: transmittal.body,
                cc: transmittal.cc.map(x => x.id),
                linkedRevisions: transmittal.revisions.map(x => x.revisionId),
                linkedDocumentGroups: [],
                reason: transmittal.reason,
                requestedApprover: transmittal.approval.requestedApprover.id,
                responseDate: transmittal.responseDate,
                responseType: transmittal.responseType,
                subject: transmittal.subject,
                tags: transmittal.tags,
                to: transmittal.to.map(x => x.id)
            },
            { cancelToken }
        )
        .then(x => x.data),
        [],
        {
            executeOnMount: false,
            executeOnUpdate: false,
            onSuccess: t => history.push(Routes.projectTransmittalEdit(t.projectId, t.id)),
            onError: err => axios.isCancel(err) ? null : NotificationService.error('Unable to forward transmittal.')
        }
    )

    const approveAndSendAsync = useAsyncCancellable(
        async (cancelToken) => {
            const sendingNotification = NotificationService.pendingActivityToast('Sending transmittal...')
            try {
                await TransmittalApprove(transmittal.id, { comments: '' }, { cancelToken })
                NotificationService.updateToast(sendingNotification, 'Transmittal sent', { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
            } catch {
                NotificationService.updateToast(sendingNotification, 'There was an issue while sending the transmittal', { type: 'error', autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
            }
            reloadTransmittal()
        },
        [],
        { executeOnMount: false, executeOnUpdate: false }
    )

    function renderRecipientLine(title: string, addresses: TransmittalAddress[]) {
        return (
            <div className="d-flex align-items-center">
                <div className="mr-2"><strong>{title}:</strong></div>
                <div className="flex-shrink-1">
                    <AvatarList maxToShow={4} avatarImageLinks={addresses.map(t => null)} />
                </div>
                <div>
                    {addresses.map((a, idx) => {
                        const read = transmittal.tracking.find(t => t.principal.id === a.id)
                        return (
                            <React.Fragment key={a.id}><LinkWithTooltip to="#" tooltip={a.email}><span aria-label={read ? 'Read' : 'Unread'} className={read ? 'text-success' : 'text-danger'}>{a.name || a.email}</span></LinkWithTooltip>{idx < addresses.length - 1 && ', '}</React.Fragment>
                        )
                    })}
                </div>
            </div>
        )
    }

    async function loadTransmittalRevisions(filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken): Promise<ISearchResult<Revision>> {
        const response = await TransmittalRevisionsList(transmittal.id, filter, sort, page, perPage, { cancelToken })
        setMetadataDefinitions(response.data.metadataDefinitions)
        return {
            items: response.data.revisions,
            totalItems: response.headers[Headers.PaginationTotalCount]
        }
    }

    function handleRevisionSelected(...revisions: Revision[]) {
        widget.actions.toggleRevisions({ entities: revisions, projectId: transmittal.projectId, snapTogether: true })
    }

    function downloadRevisions() {
        downloadURL(TransmittalAttachmentsDownloadLink(auth.getSessionToken(), transmittal.id))
    }

    return (
        <>
            <TransmittalToolbar transmittal={transmittal}>
                {transmittal.status === TransmittalStatus.Published &&
                transmittal.approval.status === ApprovalStatus.Pending &&
                (
                    <Button
                        color="primary"
                        onClick={approveAndSendAsync.execute}
                        disabled={!isAuthorised(project.myAccess, ProjectOperations.ApproveTransmittal) || approveAndSendAsync.loading}
                    >
                        Approve &amp; Send
                    </Button>
                )}
                {transmittal.status === TransmittalStatus.Sent && <Button onClick={forwardAsync.execute} disabled={forwardAsync.loading}><FA icon="share-square" className="mr-2" /><span>Forward</span></Button>}
            </TransmittalToolbar>
            <div className="mb-3" />
            <Container fluid>
                <Row>
                    <Col xs={12} md={6} className="mb-3">
                        <Card className="mb-3">
                            <CardHeader><FA icon="info-circle" /> Details</CardHeader>
                            <CardBody>
                                <DetailList
                                    entries={[
                                        { title: 'Reference #', data: transmittal.reference },
                                        { title: 'Subject', data: transmittal.subject },
                                        { title: 'Date Sent', data: transmittal.status === TransmittalStatus.Sent ? moment(transmittal.updatedDate).format('lll') : mutedValue('(not sent)') },
                                        { title: 'Reason', data: valueOrMutedFallback(transmittal.reason, 'none') },
                                        { title: 'Author', data: transmittal.createdBy.name }
                                    ]}
                                />
                            </CardBody>
                        </Card>
                        <Card>
                            <CardHeader><FA icon="users" /> Recipients</CardHeader>
                            <CardBody>
                                {renderRecipientLine('To', transmittal.to)}
                                <hr />
                                {renderRecipientLine('Cc', transmittal.cc)}
                                {transmittal.bcc != null && <>
                                    <hr />
                                    {renderRecipientLine('Bcc', transmittal.bcc)}
                                </>}
                            </CardBody>
                        </Card>
                    </Col>
                    <Col xs={12} md={6} className="mb-3">
                        <Card>
                            <CardHeader><FA icon="envelope" /> Body</CardHeader>
                            <CardBody>
                                <FroalaViewer content={transmittal.body} />
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <CollapsibleCard
                            noCardBody
                            disabled={transmittal.revisions.length < 1}
                            defaultOpen={transmittal.revisions.length > 0}
                            header={(
                                <span className="d-inline-flex align-items-center w-100">
                                    <span className="flex-grow-1"><FA icon="file-alt" /> Documents <span className="text-muted">({transmittal.revisions.length})</span></span>
                                    <span><Button disabled={transmittal.revisions.length < 1} color="primary" onClick={downloadRevisions}><FA icon="download" /> Download All</Button></span>
                                </span>
                            )}
                        >
                            <SearchSection
                                itemIdKey="id"
                                noContainerForTable
                                ref={revisionsSectionRef}
                                onSearch={loadTransmittalRevisions}
                                onItemsSelected={handleRevisionSelected}
                                defaultPerPage={20}
                                selectedItems={widget.revisions.map(x => x.id)}
                                headers={[
                                    {
                                        name: 'Type',
                                        overrideRenderer: r => <FA icon={fileTypeIcon(r.fileName || '')} />
                                    },
                                    {
                                        name: 'Name',
                                        overrideRenderer: r => <DocumentTableTitle revision={r} />
                                    },
                                    {
                                        name: 'Rev #',
                                        accessor: 'revNumber'
                                    },
                                    {
                                        name: 'Tags',
                                        accessor: 'tags'
                                    },
                                    {
                                        name: 'Author',
                                        accessor: 'author'
                                    },
                                    {
                                        name: 'Revision Date',
                                        accessor: 'revDate',
                                        overrideRenderer: r => r.revDate ? moment(r.revDate).format('lll') : mutedNotSet
                                    },
                                    {
                                        name: 'Uploaded',
                                        overrideRenderer: r => r.revDate ? moment(r.revDate).format('L') : mutedNotSet
                                    },
                                    ...(metadataDefinitions.map<ITableHeader<Revision>>(md => ({
                                        name: md.name,
                                        overrideRenderer: (r) => {
                                            const value = renderMetadata(r.metadata[md.key], md, r.projectId)
                                            return md.type === 'text' ? <ClampLines lines={2} text={typeof value === 'string' ? value : ''} /> : value
                                        }
                                    }))),
                                    {
                                        name: 'Actions',
                                        headerWrapperClass: 'text-right',
                                        noSmallHeader: true,
                                        overrideRenderer: r => (
                                            <div className="text-right">
                                                {/* {viewAction.visible && <TooltipLink id={`view-${r.id}`} disabled={viewAction.disabled != null ? viewAction.disabled : false} tooltip={viewAction.tooltip || RevisionTable.defaultProps.viewAction.tooltip} to={Routes.projectDocument(r.projectId, r.documentId, r.id)} className="selectable-content__icon order-lg-1"><FA icon="eye" /></TooltipLink>} */}
                                                {/* {downloadAction.visible && <TooltipLinkAction id={`download-${r.id}`} disabled={downloadAction.disabled != null ? downloadAction.disabled : false} tooltip={downloadAction.tooltip || RevisionTable.defaultProps.downloadAction.tooltip} data={r} onClick={downloadRevision}><FA icon="download" /></TooltipLinkAction>} */}
                                                {/* {deleteAction.visible && <TooltipLinkAction id={`delete-${r.id}`} disabled={deleteAction.disabled != null ? deleteAction.disabled : false} tooltip={deleteAction.tooltip || RevisionTable.defaultProps.deleteAction.tooltip} data={r} onClick={deleteAction.overrideAction}><FA icon={deleteAction.icon} /></TooltipLinkAction>} */}
                                            </div>
                                        )
                                    }
                                ]}
                                searchAssistantProperties={[
                                    {
                                        name: 'Name',
                                        searchKey: 'name',
                                        type: PropertyType.Text
                                    },
                                    {
                                        name: 'Rev #',
                                        searchKey: 'revision_number',
                                        type: PropertyType.Text
                                    },
                                    {
                                        name: 'Tags',
                                        searchKey: 'tags',
                                        type: PropertyType.Text
                                    },
                                    {
                                        name: 'Created At',
                                        searchKey: 'created',
                                        type: PropertyType.Date
                                    },
                                    {
                                        name: 'Revision Date',
                                        searchKey: 'revision_date',
                                        type: PropertyType.Date
                                    },
                                    {
                                        name: 'Author',
                                        searchKey: 'author',
                                        type: PropertyType.Text
                                    },
                                    ...metadataDefinitions.map(d => metadataSearchProperty(d))
                                ]}
                            />
                        </CollapsibleCard>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <CollapsibleCard
                            noCardBody
                            disabled={transmittal.documentGroups.length < 1}
                            header={(
                                <span className="d-inline-flex align-items-center w-100">
                                    <span className="flex-grow-1"><FA icon="file-archive" /> Document Groups <span className="text-muted">({transmittal.documentGroups.length})</span></span>
                                </span>
                            )}
                        >
                            <div className="mt-2">
                                <GenericContentTable
                                    data={[]}
                                    headers={[]}
                                />
                            </div>
                        </CollapsibleCard>
                    </Col>
                </Row>
                {transmittal.status === TransmittalStatus.Sent &&
                    <Row className="mb-3">
                        <Col md={6}>
                            <TrackingList header="Transmittal History" trackingEntries={transmittal.tracking} />
                        </Col>
                    </Row>}
            </Container>
        </>
    )
}

export default React.memo(TransmittalViewPage)
