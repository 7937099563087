import React from 'react'
import { Card, Container } from 'reactstrap'

import { AclEntry } from '@src/types/access'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
}

interface IState {
    projectAccess: AclEntry[]
}

export default class SettingsSection extends React.Component<IProps, IState> {

    public componentDidMount() {
        //
    }

    public render() {
        return (
            <Container fluid>
                <Card />
            </Container>
        )
    }
}
