import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Form as BootstrapForm, FormGroup, Label } from 'reactstrap'

import { compare } from 'fast-json-patch'

import { reloadUserProfile } from '@src/actions/session'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { UserProfilePatch } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'

export interface IEditProfileFormData {
    firstName: string
    lastName: string,
    role: string,
    mobile: string
}

const EditProfileForm: React.FC = () => {

    const dispatch = useDispatch()
    const user = useSelector<RootState, Session.User>(s => s.session.user)

    async function updateProfile(values: IEditProfileFormData) {
        const original: Api.Request.UserSelfUpdate = {
            firstName: user.firstName,
            lastName: user.lastName,
            mobile: user.mobile,
            role: user.role,
            phone: null,
            favouriteProjects: null,
            title: null
        }
        const update: Api.Request.UserSelfUpdate = {
            firstName: values.firstName,
            lastName: values.lastName,
            mobile: values.mobile,
            role: values.role,
            phone: null,
            favouriteProjects: null,
            title: null
        }

        const patch = compare(original, update)

        if (patch.length === 0) {
            NotificationService.info('Profile updated')
            return
        }

        try {
            await UserProfilePatch(patch)
            NotificationService.info('Profile updated')
            dispatch(reloadUserProfile())
        } catch (error) {
            if (isAxiosError(error) && error.response?.status === 400) {
                return buildFormErrorsFromModelState(values, error.response.data)
            }

            NotificationService.error('Error while updating profile')
        }
    }

    return (
        <Form
            onSubmit={updateProfile}
            initialValues={{
                firstName: user.firstName,
                lastName: user.lastName,
                role: user.role,
                mobile: user.mobile
            }}
            subscription={{ submitting: true }}
        >
            {({ handleSubmit, submitting }) => (
                <BootstrapForm>
                    <FormGroup>
                        <Label for="firstName">First Name</Label>
                        <Field id="firstName" name="firstName" component={ValidatedInput} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="lastName">Last Name</Label>
                        <Field id="lastName" name="lastName" component={ValidatedInput} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="role">Role</Label>
                        <Field id="role" name="role" component={ValidatedInput} />
                    </FormGroup>
                    <FormGroup>
                        <Label for="mobile">Mobile</Label>
                        <Field id="mobile" name="mobile" component={ValidatedInput} />
                    </FormGroup>
                    <Button color="success" onClick={handleSubmit} disabled={submitting}>Update profile</Button>
                </BootstrapForm>
            )}
        </Form>
    )
}

export default React.memo(EditProfileForm)
