import React from 'react'

import moment from 'moment'
import numeral from 'numeral'

import LinksPopover from '@src/components/common/LinksPopover'
import * as Routes from '@src/logic/routing/routes'
import { mutedValue, valueOrMutedFallback } from '@src/logic/utils/ValueHelper'
import { DocumentLink } from '@src/types/document'
import { EmailLink } from '@src/types/email'
import { AutoNumberDefinition, BoolDefinition, CompanyEntityProps, CompanyLinksDefinition, CompanyPropertyDefinition, DateDefinition, DocumentEntityProps, DocumentLinksDefinition, DocumentPropertyDefinition, EmailEntityProps, EmailLinksDefinition, EmailPropertyDefinition, IMetadataDefinition, MetadataTypes, NumericDefinition, SelectDefinition, TextDefinition, TransmittalEntityProps, TransmittalLinksDefinition, TransmittalPropertyDefinition, UserEntityProps, UserLinksDefinition, UserPropertyDefinition } from '@src/types/metadata'
import { PrincipalBrief } from '@src/types/principal'
import { TransmittalLink } from '@src/types/transmittal'

export function renderMetadata(value: any, definition: IMetadataDefinition, projectId: string) {
    switch (definition.type) {
        case MetadataTypes.AutoNumber:
            return renderAutoNumber(value, definition as AutoNumberDefinition)
        case MetadataTypes.Bool:
            return renderBoolean(value, definition as BoolDefinition)
        case MetadataTypes.Text:
            return renderText(value, definition as TextDefinition)
        case MetadataTypes.Numeric:
            return renderNumeric(value, definition as NumericDefinition)
        case MetadataTypes.Date:
            return renderDate(value, definition as DateDefinition)
        case MetadataTypes.Select:
            return renderSelect(value, definition as SelectDefinition)
        case MetadataTypes.DocumentLinks:
            return renderDocumentLinks(value, definition as DocumentLinksDefinition, projectId)
        case MetadataTypes.DocumentProperty:
            return renderDocumentProperty(value, definition as DocumentPropertyDefinition)
        case MetadataTypes.TransmittalLinks:
            return renderTransmittalLinks(value, definition as TransmittalLinksDefinition, projectId)
        case MetadataTypes.TransmittalProperty:
            return renderTransmittalProperty(value, definition as TransmittalPropertyDefinition)
        case MetadataTypes.CompanyLinks:
            return renderCompanyLinks(value, definition as CompanyLinksDefinition)
        case MetadataTypes.CompanyProperty:
            return renderCompanyProperty(value, definition as CompanyPropertyDefinition)
        case MetadataTypes.UserLinks:
            return renderUserLinks(value, definition as UserLinksDefinition)
        case MetadataTypes.UserProperty:
            return renderUserProperty(value, definition as UserPropertyDefinition)
        case MetadataTypes.EmailLinks:
            return renderEmailLinks(value, definition as EmailLinksDefinition, projectId)
        case MetadataTypes.EmailProperty:
            return renderEmailProperty(value, definition as EmailPropertyDefinition)
    }
}

export function renderAutoNumber(value: number, definition: AutoNumberDefinition) {
    return value
}

export function renderBoolean(value: boolean, defintion: BoolDefinition, defaultValue: string = '(not set)') {
    return value == null ? mutedValue(defaultValue) : value.toString()
}

export function renderText(value: string, defintion: TextDefinition, defaultValue: string = '(not set)') {
    return value == null ? mutedValue(defaultValue) : value
}

export function renderNumeric(value: number, defintion: NumericDefinition, defaultValue: string = '(not set)') {
    return value == null ? mutedValue(defaultValue) : numeral(value).format(defintion.options.format)
}

export function renderDate(value: Date, defintion: DateDefinition, defaultValue: string = '(not set)') {
    return value == null ? mutedValue(defaultValue) : moment(value).format(defintion.options.format)
}

export function renderSelect(value: string | string[], definition: SelectDefinition, defaultValue: string = '(none selected)') {
    if (value == null) { return mutedValue(defaultValue) }

    if (definition.options.isMultiselect && value instanceof Array) {
        return value && value.length > 0 ? value.join(', ') : mutedValue(defaultValue)
    }

    return valueOrMutedFallback(value instanceof Array ? value[0] : value, defaultValue)
}

export function renderDocumentLinks(value: DocumentLink[], definition: DocumentLinksDefinition, projectId: string, defaultValue: string = '(no links)') {
    if (value == null || value.length === 0) { return mutedValue(defaultValue) }

    return <LinksPopover title="Documents" links={value.map(dl => ({ name: dl.name, route: Routes.projectDocument(projectId, dl.documentId, dl.revisionId) }))} />
}

export function renderDocumentProperty(value, definition: DocumentPropertyDefinition, defaultValue: string = '(unknown)') {
    switch (definition.options.property) {
        case DocumentEntityProps.Author:
        case DocumentEntityProps.Description:
        case DocumentEntityProps.Name:
        case DocumentEntityProps.RevisionNo:
            return value == null ? mutedValue(defaultValue) : value
        case DocumentEntityProps.CreatedDate:
        case DocumentEntityProps.RevisionDate:
            return moment(value).format('L')
    }
}

export function renderTransmittalLinks(value: TransmittalLink[], definition: TransmittalLinksDefinition, projectId: string, defaultValue: string = '(no links)') {
    if (value == null || value.length === 0) { return mutedValue(defaultValue) }

    return <LinksPopover title="Transmittal" links={value.map(tl => ({ name: tl.subject, route: Routes.projectTransmittal(projectId, tl.id) }))} />
}

export function renderTransmittalProperty(value, definition: TransmittalPropertyDefinition, defaultValue: string = '(unknown)') {
    switch (definition.options.property) {
        case TransmittalEntityProps.CreatedDate:
        case TransmittalEntityProps.ResponseDate:
            return value == null ? mutedValue(defaultValue) : moment(value).toString()
        case TransmittalEntityProps.ReferenceNo:
        case TransmittalEntityProps.ResponseType:
        case TransmittalEntityProps.Status:
        case TransmittalEntityProps.Subject:
            return value == null ? mutedValue(defaultValue) : value
    }
}

export function renderCompanyLinks(value: PrincipalBrief[], definition: CompanyLinksDefinition, defaultValue: string = '(no links)') {
    if (value == null || value.length === 0) { return mutedValue(defaultValue) }

    return <LinksPopover title="Companies" links={value.map(cl => ({ name: cl.name, route: null }))} />
}

export function renderCompanyProperty(value, definition: CompanyPropertyDefinition, defaultValue: string = '(unknown)') {
    switch (definition.options.property) {
        case CompanyEntityProps.Name:
        case CompanyEntityProps.AbbreviatedName:
            return value == null ? mutedValue(defaultValue) : value
        case CompanyEntityProps.Disciplines:
            return value == null ? mutedValue(defaultValue) : (value as string[]).join(', ')
    }
}

export function renderUserLinks(value: PrincipalBrief[], definition: UserLinksDefinition, defaultValue: string = '(no links)') {
    if (value == null || value.length === 0) { return mutedValue(defaultValue) }

    return <LinksPopover title="Users" links={value.map(dl => ({ name: dl.name, route: null }))} />
}

export function renderUserProperty(value, definition: UserPropertyDefinition, defaultValue: string = '(unknown)') {
    switch (definition.options.property) {
        case UserEntityProps.Email:
        case UserEntityProps.Mobile:
        case UserEntityProps.Name:
        case UserEntityProps.Role:
            return value == null ? mutedValue(defaultValue) : value
    }
}

export function renderEmailLinks(value: EmailLink[], definition: EmailLinksDefinition, projectId: string, defaultValue: string = '(no links)') {
    if (value == null || value.length === 0) { return mutedValue(defaultValue) }

    return <LinksPopover title="Emails" links={value.map(el => ({ name: el.subject, route: Routes.projectEmail(projectId, el.id) }))} />
}

export function renderEmailProperty(value, definition: EmailPropertyDefinition, defaultValue: string = '(unknown)') {
    switch (definition.options.property) {
        case EmailEntityProps.Bcc:
        case EmailEntityProps.Cc:
        case EmailEntityProps.From:
        case EmailEntityProps.Subject:
        case EmailEntityProps.To:
            return value == null ? mutedValue(defaultValue) : value
        case EmailEntityProps.Date:
            return moment(value).format('L')
    }
}
