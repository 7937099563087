// tslint:disable:max-classes-per-file
// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Form, FormGroup, Label, Row } from 'reactstrap'
import { ConfigProps, Field, FieldArray, Fields, formValueSelector, InjectedFormProps, reduxForm, WrappedFieldArrayProps, WrappedFieldProps, WrappedFieldsProps } from 'redux-form'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { CommitmentDefinition, CommitmentReportColumn, CommitmentReportColumnName, CommitmentStatusDefinition } from '@src/types/costs'


export interface IStatusMapping {
    commitmentType: CommitmentDefinition
    status: CommitmentStatusDefinition
}

export interface ICommitmentStatusFormData {
    code: string
    columns: { label: string, value: CommitmentReportColumn }[]
    allowClaim: boolean
    unlockedBudget: boolean
    requireOtherParty: boolean
    mapping: IStatusMapping[]
}

interface IProps {
    editingExisting?: boolean
    existingCommitmentDefinitions: CommitmentDefinition[],
}

interface IConnectedState {
    availableCommitmentDefintions: CommitmentDefinition[]
}

interface IStatusMappingFieldProps {
    availableCommitmentDefintions: CommitmentDefinition[]
}

class StatusMappingField extends React.Component<IStatusMappingFieldProps & WrappedFieldArrayProps<IStatusMapping>> {

    private addMapping = () => {
        this.props.fields.push({ commitmentType: null, status: null })
    }

    private removeMapping = (index: number) => {
        this.props.fields.remove(index)
    }

    public onCommitmentTypeChange(resetStatus, onChange, newValue, prevValue) {
        if (newValue === prevValue) return

        onChange(newValue)
        resetStatus()
    }

    public renderMappingFieldPair = (fields: WrappedFieldsProps) => {
        const mappingFields = (fields.mapping as any) as any[]
        const commitTypeField = mappingFields[mappingFields.length - 1].commitmentType as WrappedFieldProps
        const commitTypeValue: CommitmentDefinition = commitTypeField.input.value
        const statusField = mappingFields[mappingFields.length - 1].status as WrappedFieldProps
        const statuses = commitTypeValue ? commitTypeValue.statusDefinitions : []
        const availableCommitmentDefintions: CommitmentDefinition[] = fields.availableCommitmentDefintions as any
        const { input } = commitTypeField
        const { onChange } = input
        input.onChange = value => this.onCommitmentTypeChange(() => statusField.input.onChange(null), onChange, value, commitTypeField.input.value)
        return (
            <Row className="my-3">
                <Col>
                    <ValidatedSelect {...commitTypeField} options={availableCommitmentDefintions} isClearable getOptionLabel={cd => cd.name} getOptionValue={cd => cd.code} />
                </Col>
                <Col className="d-flex">
                    <span className="d-inline-block flex-grow-1 pr-3">
                        <ValidatedSelect {...statusField} options={statuses} isClearable getOptionLabel={s => s.code} getOptionValue={s => s.code} />
                    </span>
                    <span className="d-inline-block align-self-center">
                        <FA className="pointer" icon="times" onClick={fields.removeMapping as any} />
                    </span>
                </Col>
            </Row>
        )
    }

    public render() {
        return (
            <FormGroup>
                <Label>Mapping</Label>
                <Row>
                    <Col>Commitment</Col>
                    <Col>Status</Col>
                </Row>
                {this.props.fields.map((field, idx) => (
                    <Fields key={idx} names={[`${field}.commitmentType`, `${field}.status`]} component={this.renderMappingFieldPair} availableCommitmentDefintions={this.props.availableCommitmentDefintions} removeMapping={() => this.removeMapping(idx)} />
                ))}
                <Button color="link" onClick={this.addMapping}><FA icon="plus" /> Add Mapping</Button>
            </FormGroup>
        )
    }
}

const reportColumnOptions = Object.keys(CommitmentReportColumnName).map<{ label: string, value: string }>(k => ({ label: CommitmentReportColumnName[k], value: k }))

class CommitmentStatusForm extends React.PureComponent<IProps & IConnectedState & InjectedFormProps<ICommitmentStatusFormData, IProps>> {
    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label>Code</Label>
                    <Field name="code" component={ValidatedInput} disabled={this.props.editingExisting} />
                </FormGroup>
                <FormGroup>
                    <Label>Columns</Label>
                    <Field name="columns" component={ValidatedSelect} isMulti options={reportColumnOptions} />
                </FormGroup>
                <FormGroup>
                    <Field name="allowClaim" component={ValidatedCheckboxRadio} label="Allow Claim" />
                </FormGroup>
                <FormGroup>
                    <Field name="unlockedBudget" component={ValidatedCheckboxRadio} label="Unlocked Budget" />
                </FormGroup>
                <FormGroup>
                    <Field name="requireOtherParty" component={ValidatedCheckboxRadio} label="Require Other Party" />
                </FormGroup>
                <FieldArray<IStatusMappingFieldProps> name="mapping" component={StatusMappingField} availableCommitmentDefintions={this.props.availableCommitmentDefintions} />
            </Form>
        )
    }
}

function mapStateToProps(state, ownProps: IProps & ConfigProps<ICommitmentStatusFormData, IProps>): IProps & ConfigProps<ICommitmentStatusFormData, IProps> & IConnectedState {
    const selector = formValueSelector(ownProps.form)
    const mappedCommitments: IStatusMapping[] = selector(state, 'mapping') || []
    return {
        ...ownProps,
        availableCommitmentDefintions: ownProps.existingCommitmentDefinitions.filter(cd => mappedCommitments.filter(ad => ad.commitmentType != null).every(ad => ad.commitmentType.code !== cd.code))
    }
}

export default connect(mapStateToProps)(reduxForm<ICommitmentStatusFormData, IProps>({})(CommitmentStatusForm))
