import React from 'react'

import ProgressTrackStage from '@src/components/common/ProgressTrackStage'

export interface IProgressStage<T = string> {
    label: string
    value: T
}

interface IProps<T> {
    activeStage: T
    stages: IProgressStage<T>[],
    onSelectStage: (stage: T) => void
}

export default class ProgressTrack<T = string> extends React.Component<IProps<T>> {

    public render() {
        const activeStage = (stage: IProgressStage<T>) => stage.value === this.props.activeStage
        const items = this.props.stages.map((stage, idx) => <ProgressTrackStage<T> key={idx} active={activeStage(stage)} label={stage.label} value={stage.value} onClick={this.props.onSelectStage} />)

        return (
            <ul className="progress-track mb-3">
                {items}
            </ul>
        )
    }
}
