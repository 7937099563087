export const reorder = <T>(list: T[], startIndex, endIndex) => {
    const result = [...list]
    const [removed] = result.splice(startIndex, 1)
    result.splice(endIndex, 0, removed)

    return result
}

export function groupBy<T, TKey extends keyof Pick<T, { [K in keyof T]: T[K] extends string ? K : never }[keyof T]>>(collection: T[], key: TKey): { [groupedKey: string]: T[] } {
    return collection.reduce<{ [groupKey: string]: T[] }>(
        (prev, curr) => {
            prev[curr[key] as any] = prev[curr[key] as any] || []
            prev[curr[key] as any].push(curr)
            return prev
        },
        {}
    )
}
