import React from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, FormText, InputGroup, InputGroupAddon, Label } from 'reactstrap'
import { ConfigProps, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'

import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { nonNegative, required } from '@src/logic/forms/validation'
import { Phase } from '@src/types/costs'
import { RootState } from '@src/types/models'

export interface IPhaseFormData {
    code: string
    name: string
    description: string
    approvedValue: number
    locked: boolean
}

interface IProps {
    existingPhases: Phase[]
    existing: boolean
}

interface IConnectedState {
    isLocked: boolean
}

class PhaseForm extends React.PureComponent<IProps & IConnectedState & InjectedFormProps<IPhaseFormData, IProps & IConnectedState>> {

    public static defaultProps: Partial<IProps> = {
        existing: false
    }

    private normalizeCode = (value: string, previousValue) => value ? value.toUpperCase() : value

    private uniquePhase = (value: string, allValues: IPhaseFormData, props: IProps & IConnectedState, name) => {
        if (props.existingPhases.length < 1) return

        return props.existingPhases.find(p => p.code === value) == null ? undefined : 'Must be unique'
    }

    public render() {
        const { existing } = this.props
        return (
            <Form>
                <FormGroup>
                    <Label>Code</Label>
                    <Field name="code" disabled={this.props.existing} component={ValidatedInput} validate={existing ? [] : [required, this.uniquePhase]} normalize={this.normalizeCode} />
                </FormGroup>
                <FormGroup>
                    <Label>Name</Label>
                    <Field name="name" component={ValidatedInput} validate={required} />
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} />
                </FormGroup>
                <FormGroup>
                    <Label>Approved Value</Label>
                    <InputGroup>
                        <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                        <Field name="approvedValue" component={ValidatedCurrencyInput} disabled={this.props.isLocked} validate={nonNegative} />
                    </InputGroup>
                </FormGroup>
                <FormGroup>
                    <Field name="locked" component={ValidatedCheckboxRadio} label="Locked" />
                    <FormText>When a phase is locked, budget items and commitments can no longer be assigned to it.</FormText>
                </FormGroup>
            </Form>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & ConfigProps<IPhaseFormData, IProps>): IProps & ConfigProps<IPhaseFormData, IProps> & IConnectedState {
    const selector = formValueSelector(ownProps.form)
    return {
        ...ownProps,
        isLocked: selector(state, 'locked')
    }
}

export default connect(mapStateToProps)(reduxForm<IPhaseFormData, IProps & IConnectedState>({})(PhaseForm))
