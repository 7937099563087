// tslint:disable:max-classes-per-file

import React from 'react'
import { Field as RFFField, FieldProps } from 'react-final-form'
import { connect } from 'react-redux'
import { FormFeedback, Input } from 'reactstrap'
import { Field, WrappedFieldProps } from 'redux-form'

import cx from 'classnames'

import DatePicker from '@src/components/common/DatePicker'
import Select, { buildOptions, IOption } from '@src/components/common/Select'
import { PrefixedField } from '@src/components/forms/FieldPrefix'
import { validate } from '@src/components/metadata/validator'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { CommitmentLink, PaymentClaimLink } from '@src/types/costs'
import { DocumentLink } from '@src/types/document'
import { EmailLink } from '@src/types/email'
import { AutoNumberDefinition, BoolDefinition, CommitmentLinksDefinition, CompanyLinksDefinition, DateDefinition, DocumentLinksDefinition, EmailLinksDefinition, IMetadataDefinition, IMetadataForm, MetadataTypes, NumericDefinition, PaymentClaimLinksDefinition, SelectDefinition, TextDefinition, TransmittalLinksDefinition, UserLinksDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { CompanyLink, UserLink } from '@src/types/principal'
import { TransmittalLink } from '@src/types/transmittal'

interface IBaseInputProps {
    value: any
    invalid?: boolean
    definition: IMetadataDefinition
    onChange: (key: string, value) => void
    onBlur?: (event?) => void
    size?: 'sm' | 'md' | 'lg'
}

interface IAutoNumberInputProps extends IBaseInputProps {
    value: number
    definition: AutoNumberDefinition
}

export class AutoNumberInput extends React.PureComponent<IAutoNumberInputProps> {

    public render() {
        const { value, size, definition } = this.props

        return (
            <Input
                bsSize={size !== 'md' ? size : undefined}
                value={value || definition.current}
                readOnly
                disabled
            />
        )
    }
}

interface IBooleanInputProps extends IBaseInputProps {
    value: boolean
    definition: BoolDefinition
    onChange: (key: string, value: boolean) => void
}

export class BooleanInput extends React.PureComponent<IBooleanInputProps> {

    private static readonly options = [
        { value: true, label: 'True' },
        { value: false, label: 'False' }
    ]

    private handleSelect = (option: IOption<boolean>) => {
        this.props.onChange(this.props.definition.key, option ? option.value : null)
    }

    public render() {
        const { value, size } = this.props

        return (
            <Select
                size={size}
                value={BooleanInput.options.find(x => x.value === value)}
                options={BooleanInput.options}
                onChange={this.handleSelect}
                invalid={this.props.invalid}
                isClearable={!this.props.definition.isRequired}
            />
        )
    }
}

interface INumericInputProps extends IBaseInputProps {
    value: number
    definition: NumericDefinition
    onChange: (key: string, value: number) => void
}

export class NumericInput extends React.PureComponent<INumericInputProps> {

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value === '' ? null : Number(e.target.value)
        this.props.onChange(this.props.definition.key, value)
    }

    private handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onBlur(this.props.value !== null ? this.props.value : null)
    }

    public render() {
        const { definition, value, invalid, size, onBlur } = this.props
        const enforceRange = definition.options.enforceRange
        return (
            <Input
                type="number"
                bsSize={size === 'md' ? null : size}
                name={definition.key}
                onChange={this.handleChange}
                value={value != null ? value : ''}
                min={enforceRange ? definition.options.minValue : undefined}
                max={enforceRange ? definition.options.maxValue : undefined}
                invalid={invalid}
                onBlur={this.handleBlur}
            />
        )
    }
}

interface ITextInputProps extends IBaseInputProps {
    value: string
    definition: TextDefinition
    onChange: (key: string, value: string) => void
}

export class TextInput extends React.PureComponent<ITextInputProps> {

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.onChange(this.props.definition.key, e.target.value)
    }

    public render() {
        const { definition, value, size, onBlur } = this.props

        return (
            <Input
                invalid={this.props.invalid}
                bsSize={size === 'md' ? null : size}
                name={definition.key}
                onChange={this.handleChange}
                value={value || ''}
                minLength={definition.options.minLength || undefined}
                maxLength={definition.options.maxLength || undefined}
                onBlur={onBlur}
            />
        )
    }
}

interface IDateInputProps extends IBaseInputProps {
    value: Date | string
    definition: DateDefinition
    onChange: (key: string, value: Date) => void
}

export class DateInput extends React.PureComponent<IDateInputProps> {

    private handleChange = (value: Date) => {
        this.props.onChange(this.props.definition.key, value)
    }

    private handleBlurChange = (e: React.FocusEvent<HTMLInputElement>) => {
        this.props.onChange(this.props.definition.key, e.currentTarget.valueAsDate)

        if (this.props.onBlur) this.props.onBlur(e)
    }

    public render() {
        const { definition, value, invalid, size } = this.props

        return (
            <DatePicker
                selected={value ? new Date(value) : null}
                onChange={this.handleChange}
                size={size === 'md' ? null : size}
                startDate={definition.options.enforceRange ? definition.options.minValue : undefined}
                endDate={definition.options.enforceRange ? definition.options.maxValue : undefined}
                invalid={invalid}
                onBlur={this.handleBlurChange}
            />
        )
    }
}

interface ISelectInputProps extends IBaseInputProps {
    value: string[]
    definition: SelectDefinition
    onChange: (key: string, value: string[]) => void
}

export class SelectInput extends React.PureComponent<ISelectInputProps> {

    private handleChange = (options: IOption<string> | IOption<string>[]) => {
        if (options == null) {
            this.props.onChange(this.props.definition.key, [])
        } else if (options instanceof Array) {
            this.props.onChange(this.props.definition.key, options.map(x => x.value))
        } else {
            this.props.onChange(this.props.definition.key, [options.value])
        }
    }

    public render() {
        const { definition, size } = this.props
        const value = this.props.value || []

        const normalizedValue = definition.options.isMultiselect ?
            (value ? buildOptions(value) : null)
            : (value.length === 0 ? null : ({ label: value[0], value: value[0] }))

        return (
            <Select
                size={size}
                creatable={!definition.options.enforceValues}
                isMulti={definition.options.isMultiselect}
                value={normalizedValue}
                options={buildOptions(definition.options.values)}
                onChange={this.handleChange}
                invalid={this.props.invalid}
            />
        )
    }
}

interface IDocumentLinksInputProps extends IBaseInputProps {
    value: DocumentLink[]
    definition: DocumentLinksDefinition
    options: DocumentLink[]
    onChange: (key: string, value: DocumentLink[]) => void
}

export class DocumentLinksInput extends React.PureComponent<IDocumentLinksInputProps> {

    private handleChange = (values: DocumentLink | DocumentLink[]) => {
        if (values == null) return []
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values)
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getOptionLabel(documentLink: DocumentLink) {
        return documentLink.name
    }

    private getOptionValue(documentLink: DocumentLink) {
        return documentLink.revisionId
    }

    public render() {
        const { definition, value, size, options } = this.props

        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable
            />
        )
    }
}

function mapStateToPropsDocumentLinksInput(state: RootState, ownProps: Omit<IDocumentLinksInputProps, keyof { options }>): IDocumentLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).revisions.map(r => ({ name: r.name, documentId: r.documentId, revisionId: r.id }))
    }
}

export const WidgetConnectedDocumentLinksInput = connect(mapStateToPropsDocumentLinksInput)(DocumentLinksInput)

interface ICompanyLinksInputProps extends IBaseInputProps {
    value: CompanyLink[]
    definition: CompanyLinksDefinition
    options: CompanyLink[]
    onChange: (key: string, value: CompanyLink[]) => void
}

export class CompanyLinksInput extends React.PureComponent<ICompanyLinksInputProps> {

    private handleChange = (values: CompanyLink | CompanyLink[]) => {
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values ? values : [])
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getOptionLabel(companyLink: CompanyLink) {
        return companyLink.name
    }

    private getOptionValue(companyLink: CompanyLink) {
        return companyLink.id
    }

    public render() {
        const { definition, value, size, options } = this.props
        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable
            />
        )
    }
}

function mapStateToPropsCompanyLinksInput(state: RootState, ownProps: Omit<ICompanyLinksInputProps, keyof { options }>): ICompanyLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).companies.map(c => ({ name: c.name, id: c.id }))
    }
}

export const WidgetConnectedCompanyLinksInput = connect(mapStateToPropsCompanyLinksInput)(CompanyLinksInput)

interface IUserLinksInputProps extends IBaseInputProps {
    value: UserLink[]
    definition: UserLinksDefinition
    options: UserLink[]
    onChange: (key: string, value: UserLink[]) => void
}

export class UserLinksInput extends React.PureComponent<IUserLinksInputProps> {

    private handleChange = (values: UserLink | UserLink[]) => {
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values ? values : [])
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getOptionLabel(userLink: UserLink) {
        return userLink.name
    }

    private getOptionValue(userLink: UserLink) {
        return userLink.id
    }

    public render() {
        const { definition, value, options, size } = this.props
        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable
            />
        )
    }
}

function mapStateToPropsUserLinksInput(state: RootState, ownProps: Omit<IUserLinksInputProps, keyof { options }>): IUserLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).users.map(c => ({ name: `${c.firstName} ${c.lastName}`, id: c.id }))
    }
}

export const WidgetConnectedUserLinksInput = connect(mapStateToPropsUserLinksInput)(UserLinksInput)

interface ITransmittalLinksInputProps extends IBaseInputProps {
    value: TransmittalLink[]
    definition: TransmittalLinksDefinition
    options: TransmittalLink[]
    onChange: (key: string, value: TransmittalLink[]) => void
}

export class TransmittalLinksInput extends React.PureComponent<ITransmittalLinksInputProps> {

    private handleChange = (values: TransmittalLink | TransmittalLink[]) => {
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values ? values : [])
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getTransmittalLinkLabel(link: TransmittalLink) {
        return link.subject
    }

    private getTransmittalLinkValue(link: TransmittalLink) {
        return link.id
    }

    public render() {
        const { definition, value, options, size } = this.props
        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                onChange={this.handleChange}
                getOptionLabel={this.getTransmittalLinkLabel}
                getOptionValue={this.getTransmittalLinkValue}
                invalid={this.props.invalid}
                isClearable
            />
        )
    }
}

function mapStateToPropsTransmittalLinksInput(state: RootState, ownProps: Omit<ITransmittalLinksInputProps, keyof { options }>): ITransmittalLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).transmittals.map(t => ({ subject: t.subject, id: t.id }))
    }
}

export const WidgetConnectedTransmittalLinksInput = connect(mapStateToPropsTransmittalLinksInput)(TransmittalLinksInput)

interface IEmailLinksInputProps extends IBaseInputProps {
    value: EmailLink[]
    definition: EmailLinksDefinition
    options: EmailLink[]
    onChange: (key: string, value: EmailLink[]) => void
}

export class EmailLinksInput extends React.PureComponent<IEmailLinksInputProps> {

    private handleChange = (values: EmailLink | EmailLink[]) => {
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values ? values : [])
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getEmailLinkLabel(link: EmailLink) {
        return link.subject
    }

    private getEmailLinkValue(link: EmailLink) {
        return link.id
    }

    public render() {
        const { definition, value, options, size } = this.props
        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getEmailLinkLabel}
                getOptionValue={this.getEmailLinkValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable
            />
        )
    }
}

function mapStateToPropsEmailLinksInput(state: RootState, ownProps: Omit<IEmailLinksInputProps, keyof { options }>): IEmailLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).emails.map(t => ({ subject: t.subject, sender: t.from, id: t.id }))
    }
}

export const WidgetConnectedEmailLinksInput = connect(mapStateToPropsEmailLinksInput)(EmailLinksInput)

interface ICommitmentLinksInputProps extends IBaseInputProps {
    value: CommitmentLink[]
    definition: CommitmentLinksDefinition
    options: CommitmentLink[]
    onChange: (key: string, value: CommitmentLink[]) => void
}

export class CommitmentLinksInput extends React.PureComponent<ICommitmentLinksInputProps> {

    private handleChange = (values: CommitmentLink | CommitmentLink[]) => {
        if (values == null) return []
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values)
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getOptionLabel(commitmentLink: CommitmentLink) {
        return commitmentLink.name
    }

    private getOptionValue(commitmentLink: CommitmentLink) {
        return commitmentLink.id
    }

    public render() {
        const { definition, value, size, options } = this.props

        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable={!definition.isRequired}
            />
        )
    }
}

function mapStateToPropsCommitmentLinksInput(state: RootState, ownProps: Omit<ICommitmentLinksInputProps, keyof { options }>): ICommitmentLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).commitments.map(r => ({ name: r.name, id: r.id, type: r.type }))
    }
}

export const WidgetConnectedCommitmentLinksInput = connect(mapStateToPropsCommitmentLinksInput)(CommitmentLinksInput)

interface IPaymentClaimLinksInputProps extends IBaseInputProps {
    value: PaymentClaimLink[]
    definition: PaymentClaimLinksDefinition
    options: PaymentClaimLink[]
    onChange: (key: string, value: PaymentClaimLink[]) => void
}

export class PaymentClaimLinksInput extends React.PureComponent<IPaymentClaimLinksInputProps> {

    private handleChange = (values: PaymentClaimLink | PaymentClaimLink[]) => {
        if (values == null) return []
        if (values instanceof Array) {
            this.props.onChange(this.props.definition.key, values)
        } else {
            this.props.onChange(this.props.definition.key, values ? [values] : [])
        }
    }

    private getOptionLabel(paymentClaimLink: PaymentClaimLink) {
        return paymentClaimLink.certificateNumber
    }

    private getOptionValue(paymentClaimLink: PaymentClaimLink) {
        return paymentClaimLink.id
    }

    public render() {
        const { definition, value, size, options } = this.props

        const selectValue = value && value.length ? (definition.options.limit === 1 ? value[0] : value) : null

        return (
            <Select
                size={size}
                isMulti={definition.options.limit > 1}
                isDisabled={definition.options.limit != null && definition.options.limit < 1}
                value={selectValue}
                options={options}
                getOptionLabel={this.getOptionLabel}
                getOptionValue={this.getOptionValue}
                onChange={this.handleChange}
                invalid={this.props.invalid}
                isClearable={!definition.isRequired}
            />
        )
    }
}

function mapStateToPropsPaymentClaimLinksInput(state: RootState, ownProps: Omit<IPaymentClaimLinksInputProps, keyof { options }>): IPaymentClaimLinksInputProps {
    return {
        ...ownProps,
        options: getActiveProjectWidgetState(state).paymentClaims.map(r => ({ certificateNumber: r.certificateNumber, id: r.id, commitmentId: r.commitment.id }))
    }
}

export const WidgetConnectedPaymentClaimLinksInput = connect(mapStateToPropsPaymentClaimLinksInput)(PaymentClaimLinksInput)

export class GenericInput extends React.PureComponent<IBaseInputProps> {
    public render() {
        const { definition, ...rest } = this.props
        switch (definition.type) {
            case MetadataTypes.AutoNumber:
                return <AutoNumberInput definition={definition as AutoNumberDefinition} {...rest} />
            case MetadataTypes.Bool:
                return <BooleanInput definition={definition as BoolDefinition} {...rest} />
            case MetadataTypes.Numeric:
                return <NumericInput definition={definition as NumericDefinition} {...rest} />
            case MetadataTypes.Text:
                return <TextInput definition={definition as TextDefinition} {...rest} />
            case MetadataTypes.Date:
                return <DateInput definition={definition as DateDefinition} {...rest} />
            case MetadataTypes.Select:
                return <SelectInput definition={definition as SelectDefinition} {...rest} />
            case MetadataTypes.DocumentLinks:
                return <WidgetConnectedDocumentLinksInput definition={definition as DocumentLinksDefinition} {...rest} />
            case MetadataTypes.TransmittalLinks:
                return <WidgetConnectedTransmittalLinksInput definition={definition as TransmittalLinksDefinition} {...rest} />
            case MetadataTypes.CompanyLinks:
                return <WidgetConnectedCompanyLinksInput definition={definition as CompanyLinksDefinition} {...rest} />
            case MetadataTypes.UserLinks:
                return <WidgetConnectedUserLinksInput definition={definition as UserLinksDefinition} {...rest} />
            case MetadataTypes.EmailLinks:
                return <WidgetConnectedEmailLinksInput definition={definition as EmailLinksDefinition} {...rest} />
            case MetadataTypes.CommitmentLinks:
                return <WidgetConnectedCommitmentLinksInput definition={definition as CommitmentLinksDefinition} {...rest} />
            case MetadataTypes.PaymentClaimLinks:
                return <WidgetConnectedPaymentClaimLinksInput definition={definition as PaymentClaimLinksDefinition} {...rest} />
        }
    }
}

interface IValidatedGenericInputProps {
    definition: IMetadataDefinition
    size?: 'sm' | 'md' | 'lg'
}

export class ValidatedGenericInput extends React.PureComponent<IValidatedGenericInputProps & WrappedFieldProps> {

    public handleChange = (key: string, value: any) => {
        this.props.input.onChange(value === undefined ? null : value)
    }

    public render() {
        const { input, meta: { touched, error, warning }, definition } = this.props
        const showError = touched && !!error

        return (
            <>
                <GenericInput value={input.value} definition={definition} onChange={this.handleChange} size={this.props.size} invalid={showError} onBlur={input.onBlur} />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && error}</FormFeedback>
            </>
        )
    }
}

export function reduxFormMetadataValidator(value: any, allValues: any, props: IMetadataForm, name: string) {
    const key = name.split('.').slice(-1)[0]
    const errors = validate(value, props.definitions.find(d => d.key === key)) || []
    return errors.length > 0 ? errors : undefined
}

export interface IRFMetadataInput {
    definition: IMetadataDefinition
    disableValidations?: boolean
    name?: string
    onChange?: (e, value, previousValue, name: string) => void
    size?: 'sm' | 'md' | 'lg'
}

export function finalFormInitialValue(definition: IMetadataDefinition, value: any) {
    switch (definition.type) {
        case MetadataTypes.Select:
        case MetadataTypes.DocumentLinks:
        case MetadataTypes.TransmittalLinks:
        case MetadataTypes.EmailLinks:
        case MetadataTypes.CompanyLinks:
        case MetadataTypes.UserLinks:
        case MetadataTypes.CommitmentLinks:
        case MetadataTypes.PaymentClaimLinks:
            return value ? value instanceof Array && value.length === 0 ? undefined : value : undefined
        default:
            return value
    }
}

export class RFMetadataInput extends React.PureComponent<IRFMetadataInput> {
    public render() {
        const { definition, disableValidations, name, size } = this.props
        return <Field name={name || definition.key} component={ValidatedGenericInput} definition={definition} size={size} validate={disableValidations ? undefined : reduxFormMetadataValidator} onChange={this.props.onChange} format={null} />
    }
}
