import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { ButtonGroup, Card, Container, Input, UncontrolledTooltip } from 'reactstrap'
import { Dispatch } from 'redux'

import Axios, { CancelTokenSource } from 'axios'
import { flatten } from 'lodash'

import { activeProject } from '@src/actions/project'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'
import GenericSearchBarWithPagination from '@src/components/search/GenericSearchBarWithPaging'
import { metadataSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import DocumentsTable from '@src/components/table/DocumentsTable'
import { DocumentGet, DocumentsList, ProjectMySettingsUpdate } from '@src/logic/http/Api'
import * as Query from '@src/logic/http/CommonQueryParamters'
import * as Headers from '@src/logic/http/headers'
import { getSearchParamsFromQuery, pushURLSearchFilter, pushURLWithParamUpdates } from '@src/logic/search/SearchStateHelpers'
import { invertOrChange } from '@src/logic/utils/SortHelper'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { DocumentSearchMode, IPDocument, RevisionField } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { Project } from '@src/types/project'

interface IProps extends RouteComponentProps {
    project: Project
}

interface IConnectedDispatch {
    reloadProject: (project: Project) => void
}

interface IState {
    page: number
    perPage: number
    totalDocuments: number
    searchFilter: string
    sort: string
    documents: IPDocument[]
    metadataDefinitions: IMetadataDefinition[]
    visibleMetadata: string[]
    visibleRevisionProps: RevisionField[]
    urlSearch: string
    fileTypes: string[]
    documentSearchMode: DocumentSearchMode
}

const revisionPropertyHeaderSection = 'Revision Property'
const metadataHeaderSection = 'Metadata'

const FileTypes = {
    pdf: {
        name: 'Pdf',
        ext: ['pdf']
    },
    word: {
        name: 'Word',
        ext: ['doc', 'docx']
    },
    excel: {
        name: 'Excel',
        ext: ['xlsx']
    },
    powerpoint: {
        name: 'PowerPoint',
        ext: ['ppt', 'pptx']
    },
    jpg: {
        name: 'JPEG',
        ext: ['jpeg', 'jpg']
    },
    dwg: {
        name: 'DWG',
        ext: ['dwg']
    }
}

class DocumentsSection extends React.PureComponent<IProps & IConnectedDispatch, IState> {
    private updateSettingsCancelTokenSource: CancelTokenSource

    constructor(props: IProps & IConnectedDispatch) {
        super(props)

        const urlParams = new URLSearchParams(this.props.location.search)
        const documentSearchMode: DocumentSearchMode = urlParams.get(Query.DocumentSearchMode) as DocumentSearchMode || DocumentSearchMode.LatestNonArchived
        const { sort, ...otherSearchParams } = getSearchParamsFromQuery(props.location.search)

        this.state = {
            ...otherSearchParams,
            documentSearchMode,
            sort: isNullOrEmpty(sort) ? '-revision_date' : sort,
            fileTypes: [],
            totalDocuments: null,
            documents: null,
            metadataDefinitions: [],
            visibleMetadata: props.project.mySettings.preferredMetadata,
            visibleRevisionProps: props.project.mySettings.preferredRevisionProperties,
            urlSearch: props.location.search,
        }
    }

    public static getDerivedStateFromProps(nextProps: IProps, prevState: IState) {
        if (nextProps.location.search !== prevState.urlSearch) {
            const urlParams = new URLSearchParams(nextProps.location.search)
            const documentSearchMode: DocumentSearchMode = urlParams.get(Query.DocumentSearchMode) as DocumentSearchMode || DocumentSearchMode.LatestNonArchived
            const { sort, ...otherSearchParams } = getSearchParamsFromQuery(nextProps.location.search)

            return {
                ...prevState,
                ...otherSearchParams,
                documentSearchMode,
                sort: isNullOrEmpty(sort) ? '-revision_date' : sort,
                urlSearch: nextProps.location.search
            }
        }

        return null
    }

    public componentDidMount() {
        this.doSearch()
    }

    public componentDidUpdate(prevProps: Readonly<IProps>, prevState: Readonly<IState>) {
        if (this.state.urlSearch !== prevState.urlSearch) {
            this.doSearch()
        }
    }

    private readonly doSearch = () => {
        const fileFilter = this.fileTypeFilter()
        const combinedFilter = fileFilter ? `${fileFilter} AND ${this.state.searchFilter}` : this.state.searchFilter
        DocumentsList(this.props.project.id, this.state.documentSearchMode, combinedFilter, this.state.sort, this.state.page, this.state.perPage)
            .then((response) => {
                this.setState({
                    documents: response.data.documents,
                    metadataDefinitions: response.data.metadataDefinitions,
                    totalDocuments: +response.headers[Headers.PaginationTotalCount]
                })
            })
    }

    private readonly fileTypeFilter = () => {
        if (this.state.fileTypes.length === 0) return ''

        return `file_extension: ${flatten(this.state.fileTypes.map(x => FileTypes[x] ? FileTypes[x].ext : [])).join(',')} `
    }

    private readonly handleSearch = () => {
        const { location, history } = this.props
        const { searchFilter, sort, perPage } = this.state
        let { page } = this.state

        const querySearchParams = getSearchParamsFromQuery(location.search)

        // If search filter or sort has change, we should reset pagination
        if (querySearchParams.searchFilter !== searchFilter || querySearchParams.sort !== sort) {
            page = 1
        }

        if (!pushURLSearchFilter(history, location.search, searchFilter, sort, page, perPage, 50)) {
            this.doSearch()
        }
    }

    private readonly handleFilterChange = (value: string, triggerSearch?: boolean) => {
        this.setState({ searchFilter: value }, triggerSearch ? this.handleSearch : undefined)
    }

    private readonly handleSelectPage = (value: number) => {
        this.setState({ page: value }, this.handleSearch)
    }

    private readonly handleSelectPerPage = (value: number) => {
        this.setState({ perPage: value }, this.handleSearch)
    }

    private readonly handleSort = (value: string) => {
        this.setState({ sort: invertOrChange(this.state.sort, value) }, this.handleSearch)
    }

    private readonly getMoreRevisions = (document: IPDocument) => {
        return DocumentGet(document.id, true)
            .then((response) => {
                const documents = [...this.state.documents]
                documents.splice(this.state.documents.findIndex(d => d.id === response.data.document.id), 1, response.data.document)
                this.setState({ documents })
            })
    }

    private readonly handleHeaderChecked = (e, option: IDropdownCheckOption) => {
        const { visibleMetadata, visibleRevisionProps } = this.state

        if (option.section === revisionPropertyHeaderSection) {
            if (visibleRevisionProps.includes(option.key as RevisionField)) {
                this.setState({ visibleRevisionProps: this.state.visibleRevisionProps.filter(h => h !== option.key) }, this.updateSettings)
            } else {
                this.setState({ visibleRevisionProps: [...this.state.visibleRevisionProps, option.key as RevisionField] }, this.updateSettings)
            }
        } else if (option.section === metadataHeaderSection) {
            if (visibleMetadata.includes(option.key)) {
                this.setState({ visibleMetadata: this.state.visibleMetadata.filter(h => h !== option.key) }, this.updateSettings)
            } else {
                this.setState({ visibleMetadata: [...this.state.visibleMetadata, option.key] }, this.updateSettings)
            }
        }
    }

    private readonly updateSettings = async () => {
        if (this.updateSettingsCancelTokenSource != null) {
            this.updateSettingsCancelTokenSource.cancel()
        }

        this.updateSettingsCancelTokenSource = Axios.CancelToken.source()

        try {
            await ProjectMySettingsUpdate(
                this.props.project.id,
                {
                    preferredMetadata: this.state.visibleMetadata,
                    preferredRevisionProperties: this.state.visibleRevisionProps
                },
                { cancelToken: this.updateSettingsCancelTokenSource.token })
            this.props.reloadProject({ ...this.props.project, mySettings: { ...this.props.project.mySettings, preferredMetadata: this.state.visibleMetadata, preferredRevisionProperties: this.state.visibleRevisionProps } })
        } catch {
            //
        }
    }

    private readonly handleFileTypeChange = (e, option: IDropdownCheckOption) => {
        const { fileTypes } = this.state

        if (fileTypes.includes(option.key)) {
            this.setState({ fileTypes: this.state.fileTypes.filter(f => f !== option.key) }, this.doSearch)
        } else {
            this.setState({ fileTypes: [...this.state.fileTypes, option.key] }, this.doSearch)
        }
    }

    private readonly handleDocumentSearchMode = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState(
            { documentSearchMode: e.currentTarget.value as DocumentSearchMode },
            () => pushURLWithParamUpdates(this.props.history, this.props.location, { [Query.DocumentSearchMode]: this.state.documentSearchMode }, this.state.documentSearchMode === DocumentSearchMode.LatestNonArchived ? [Query.DocumentSearchMode] : [])
        )
    }

    public render() {
        const { documents, metadataDefinitions, visibleMetadata, visibleRevisionProps, page, perPage, searchFilter, sort, totalDocuments, fileTypes } = this.state

        const table = documents != null ? (documents.length > 0 ? (
            <DocumentsTable
                documents={documents}
                metadataDefinitions={metadataDefinitions}
                visibleMetadata={visibleMetadata}
                visibleRevisionProps={visibleRevisionProps}
                sortField={sort}
                getMoreRevisions={this.getMoreRevisions}
                onSort={this.handleSort}
            />
        ) : (
                <Card className="text-center" body>
                    <div className="my-3"><FA size="3x" icon="file-times" /></div>
                    <p className="lead">No documents found with the current search criteria...</p>
                    <p>Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters.</p>
                </Card>
            )
        ) : null

        const headerOptions = [
            {
                section: revisionPropertyHeaderSection,
                label: 'Name',
                key: RevisionField.Name,
                checked: visibleRevisionProps.includes(RevisionField.Name)
            },
            {
                section: revisionPropertyHeaderSection,
                label: 'Rev #',
                key: RevisionField.RevisionNumber,
                checked: visibleRevisionProps.includes(RevisionField.RevisionNumber)
            },
            {
                section: revisionPropertyHeaderSection,
                label: 'Author',
                key: RevisionField.Author,
                checked: visibleRevisionProps.includes(RevisionField.Author)
            },
            {
                section: revisionPropertyHeaderSection,
                label: 'Tags',
                key: RevisionField.Tags,
                checked: visibleRevisionProps.includes(RevisionField.Tags)
            },
            {
                section: revisionPropertyHeaderSection,
                label: 'Revision Date',
                key: RevisionField.RevisionDate,
                checked: visibleRevisionProps.includes(RevisionField.RevisionDate)
            },
            {
                section: revisionPropertyHeaderSection,
                label: 'Uploaded',
                key: RevisionField.UploadedDate,
                checked: visibleRevisionProps.includes(RevisionField.UploadedDate)
            },
            ...metadataDefinitions.map(md => ({ section: metadataHeaderSection, label: md.name, key: md.key, checked: visibleMetadata.includes(md.key) }))
        ]

        return (
            <>
                <GenericSearchBarWithPagination
                    filter={searchFilter}
                    onSearch={this.handleSearch}
                    onFilterChange={this.handleFilterChange}
                    totalItems={totalDocuments}
                    perPage={perPage}
                    currentPage={page}
                    onSelectPage={this.handleSelectPage}
                    onSelectPerPage={this.handleSelectPerPage}
                    wrapperClassName="mb-3"
                    searchAssistantProperties={[
                        {
                            name: 'Name',
                            searchKey: 'name',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Rev #',
                            searchKey: 'revision_number',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Tags',
                            searchKey: 'tags',
                            type: PropertyType.Text
                        },
                        {
                            name: 'Created At',
                            searchKey: 'created',
                            type: PropertyType.Date
                        },
                        {
                            name: 'Revision Date',
                            searchKey: 'revision_date',
                            type: PropertyType.Date
                        },
                        {
                            name: 'Author',
                            searchKey: 'author',
                            type: PropertyType.Text
                        },
                        ...this.state.metadataDefinitions.map(d => metadataSearchProperty(d))
                    ]}
                    extraElements={[
                        {
                            element: _ => (
                                <ButtonGroup size="sm">
                                    <DropdownCheckOptions
                                        caret
                                        isSearchable
                                        title={<FA icon="filter" />}
                                        sectionHeaders={['Revision Property', 'Metadata']}
                                        options={headerOptions}
                                        toggleProps={{ color: 'default', outline: true, id: 'document-search-columns' }}
                                        onCheck={this.handleHeaderChecked}
                                    />
                                    <DropdownCheckOptions
                                        caret
                                        isSearchable
                                        title={<FA icon="file-alt" />}
                                        toggleProps={{ color: 'default', outline: true, id: 'document-search-filetype' }}
                                        onCheck={this.handleFileTypeChange}
                                        options={[...Object.keys(FileTypes).map<IDropdownCheckOption>(f => ({ key: f, label: FileTypes[f].name, checked: this.state.fileTypes.includes(f) }))]}
                                    />
                                    <UncontrolledTooltip target="document-search-columns" delay={{ show: 1000, hide: 0 }}>Columns</UncontrolledTooltip>
                                    <UncontrolledTooltip target="document-search-filetype" delay={{ show: 1000, hide: 0 }}>File type</UncontrolledTooltip>
                                </ButtonGroup>
                            ),
                            position: 'before'
                        },
                        // {
                        //     element: _ => (
                        //     ),
                        //     position: 'before'
                        // },
                        {
                            element: _ => (
                                <Input type="select" value={this.state.documentSearchMode} onChange={this.handleDocumentSearchMode}>
                                    <option value={DocumentSearchMode.Latest}>Latest</option>
                                    <option value={DocumentSearchMode.LatestNonArchived}>Latest (Non Archived)</option>
                                    <option value={DocumentSearchMode.LatestPublished}>Latest Published</option>
                                    <option value={DocumentSearchMode.Published}>Published</option>
                                    <option value={DocumentSearchMode.Archived}>Archived</option>
                                </Input>
                            ),
                            position: 'before'
                        }
                    ]}
                />
                <Container fluid>
                    {table}
                </Container>
            </>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        reloadProject: (project: Project) => dispatch(activeProject(project))
    }
}

export default connect(undefined, mapDispatchToProps)(DocumentsSection)
