import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { v4 } from 'uuid'

import Link from '@src/components/common/Link'

interface IProps {
    to: string
    tooltip?: string
}

export default class LinkWithTooltip extends React.PureComponent<IProps> {

    private readonly tooltipId = 'link-with-tooltip-' + v4()

    public render() {
        return (
            <span>
                <Link to={this.props.to} id={this.tooltipId}>{this.props.children}</Link>
                {this.props.tooltip && <UncontrolledTooltip target={this.tooltipId}>{this.props.tooltip}</UncontrolledTooltip>}
            </span>
        )
    }
}
