import React from 'react'
import ReactSelect from 'react-select'
import Async, { AsyncProps } from 'react-select/async'
import AsyncCreatable from 'react-select/async-creatable'
import { Props as SelectProps } from 'react-select/base'
import Creatable, { CreatableProps } from 'react-select/creatable'
import { GroupType, InputActionMeta } from 'react-select/src/types'

import cx from 'classnames'

import variables from 'public/styles/custom/global/_variables.scss'

type SelectSize = 'sm' | 'md' | 'lg'

export type InputActionChange = 'set-value' | 'input-change' | 'input-blur' | 'menu-close'

export interface IBaseProps<TValue> extends Omit<SelectProps<TValue>, keyof { onChange }> {
    async?: boolean
    creatable?: boolean
    value: any
    onChange: (value: TValue | TValue[]) => void
    onInputChange?: (value: string, action: InputActionChange) => void
    rawValues?: boolean // Provided values and onChange handler are Option type
    invalid?: boolean
    wrapperClass?: string
    size?: SelectSize
    innerRef?: React.RefObject<ReactSelect<TValue>>
}

export interface IAsyncProps<TValue> extends AsyncProps<TValue>, IBaseProps<TValue> {
    async: true
}

export interface ICreatableProps<TValue> extends CreatableProps<TValue>, IBaseProps<TValue> {
    creatable: true
}

export type IProps<TValue> = IBaseProps<TValue> | IAsyncProps<TValue> | ICreatableProps<TValue> | (IAsyncProps<TValue> & ICreatableProps<TValue>)

export interface IOption<TValue> {
    __isNew__?: boolean
    label: string
    value: TValue
    [key: string]: any
}

export type IGroupedOptions<TValue> = GroupType<TValue>[]

const sizeStyles: {
    [key in SelectSize]: {
        [component: string]: React.CSSProperties
    }
} = {
    sm: {
        control: {
            fontSize: variables.inputFontSizeSm,
            // height: variables.inputHeightSm,
            minHeight: 'unset',
            lineHeight: variables.inputLineHeightSm,
            borderRadius: variables.inputBorderRadiusSm
        },
        option: {
            fontSize: variables.inputFontSizeSm
        },
        placeholder: {
            fontSize: variables.fontSizeSm
        },
        dropdownIndicator: {
            padding: '0px 8px'
        },
        clearIndicator: {
            padding: '0px 8px'
        },
        valueContainer: {
            padding: '0px 8px'
        }
    },
    md: {
        control: {
            borderRadius: variables.inputBorderRadius
        },
        option: {
            fontSize: variables.fontBaseSize
        },
        placeholder: {
            fontSize: variables.fontSizeBase
        },
        dropdownIndicator: {},
        clearIndicator: {},
        valueContainer: {}
    },
    lg: {
        control: {
            fontSize: variables.inputFontSizeLg,
            height: variables.inputHeightLg,
            lineHeight: variables.inputLineHeightLg,
            borderRadius: variables.inputBorderRadiusLg
        },
        option: {
            fontSize: variables.inputFontSizeLg
        },
        placeholder: {
            fontSize: variables.fontSizeLg
        },
        dropdownIndicator: {},
        clearIndicator: {},
        valueContainer: {}
    }
}

export function buildOptions(values: string[]): IOption<string>[] {
    return values.map(x => ({ label: x, value: x }))
}

export default class Select<TValue> extends React.PureComponent<IProps<TValue>> {
    private readonly formatValues = () => {
        const { value, rawValues } = this.props
        if (value == null) {
            return null
        }

        if (!rawValues) {
            return value
        }

        if (this.props.isMulti) {
            if (this.props.value === '') return []
            return value instanceof Array ? value.map(x => ({ label: x, value: x })) : ({ value, label: value })
        }

        if (value instanceof Array) {
            // TODO: log warning here - array value provided to a non multi select
            return ({ value: value[0], label: value[0] })
        }

        return ({ value, label: value })
    }

    private readonly formatOptions = () => {
        const { options, rawValues } = this.props

        if (!rawValues) return options

        return options ? (options as TValue[]).map(o => ({ label: o, value: o })) : []
    }

    private readonly handleChange = (option: TValue | TValue[]) => {
        if (option == null) {
            this.props.onChange(null)
            return
        }

        if (option instanceof Array) {
            this.props.onChange(this.props.rawValues ? (option as any[]).map(o => o.value) : option)
        } else {
            this.props.onChange(this.props.rawValues ? (option as any).value : option)
        }
    }

    private swallowEvent(e: React.MouseEvent) {
        e.stopPropagation()
    }

    private readonly defaultFilter = (option: IOption<any>, rawInput: string) => {
        const words = rawInput.split(' ')
        return words.reduce(
          (acc, cur) => acc && option.label.toLowerCase().includes(cur.toLowerCase()),
          true
        )
    }

    private readonly handleInputChange = (value: string, meta: InputActionMeta) => {
        this.props.onInputChange?.(value, meta.action)
    }

    public render() {
        const { async, creatable, size, filterOption, innerRef, id, ...selectProps } = this.props

        const SelectTag: React.ReactType = !creatable ? (!async ? ReactSelect : Async) : (!async ? Creatable : AsyncCreatable)
        const selectSize = size || 'md'

        const filter = filterOption || this.defaultFilter

        return (
            <div onClick={this.swallowEvent} className={cx('react-select', this.props.wrapperClass)}>
                <SelectTag
                    {...selectProps}
                    {...(innerRef ? { ref: innerRef } : {})}
                    filterOption={filter}
                    options={this.formatOptions()}
                    value={this.formatValues()}
                    onChange={this.handleChange}
                    onInputChange={this.handleInputChange}
                    // components={animatedComponents}
                    classNamePrefix="react-select"
                    inputId={id}
                    styles={{
                        control: (base, state) => ({
                            ...base,
                            background: state.isDisabled ? variables.inputDisabledBg : (state.isFocused ? variables.inputFocusBg : variables.inputBg),
                            borderColor: state.isFocused ? variables.inputFocusBorderColor : variables.inputBorderColor,
                            boxShadow: state.isFocused ? variables.inputFocusBoxShadow : null,
                            borderWidth: variables.inputBorderWidth,
                            fontWeight: 400,
                            ...(sizeStyles[selectSize].control)
                            // fontSize: variables.inputFontSizeSm,
                            // height: variables.inputHeightSm,
                            // minHeight: 'unset'
                            // $select-text-color:                 $input-color;
                            // $select-link-hover-color:           $dropdown-link-hover-color;
                            // $select-input-hover-box-shadow:     $dropdown-box-shadow;
                        }),
                        input: (base, state) => ({
                            ...base
                            // height: variables.inputHeightInner,
                        }),
                        option: (base, state) => ({
                            ...base,
                            fontWeight: 400,
                            ...(sizeStyles[selectSize].option),
                            ...(state.isSelected && { backgroundColor: variables.inputFocusBorderColor })
                            // fontSize: variables.inputFontSizeSm,
                            // fontSize: variables.fontBaseSize
                            // $select-option-color:               lighten($select-text-color, 20%) !default;
                            // $select-option-bg:                  $select-input-bg !default;
                            // $select-option-focused-color:       $dropdown-link-active-color;
                            // $select-option-focused-bg:          $dropdown-link-active-bg; // pale blue
                            // $select-option-selected-color:      $select-text-color !default;
                            // $select-option-selected-bg:         #f5faff !default; // lightest blue
                            // $select-option-disabled-color:      $dropdown-link-disabled-color;
                        }),
                        dropdownIndicator: (base, state) => ({
                            ...base,
                            ...(sizeStyles[selectSize].dropdownIndicator)
                        }),
                        valueContainer: (base, state) => ({
                            ...base,
                            ...(sizeStyles[selectSize].valueContainer)
                        }),
                        clearIndicator: (base, state) => ({
                            ...base,
                            ...(sizeStyles[selectSize].clearIndicator)
                            // // clear "x" button
                            // $select-clear-size:                 ($select-input-height / 2) !default;
                            // $select-clear-color:                #999 !default;
                            // $select-clear-hover-color:          #D0021B !default; // red
                            // $select-clear-width:                ($select-input-internal-height / 2) !default;
                        }),
                        placeholder: (base, state) => ({
                            ...base,
                            color: variables.inputPlaceholderColor,
                            ...(sizeStyles[selectSize].placeholder)
                        }),
                        loadingIndicator: (base, state) => ({
                            ...base
                            // // loading indicator
                            // $select-loading-size:               $select-input-internal-height !default;
                            // $select-loading-color:              $select-text-color !default;
                            // $select-loading-color-bg:           $select-input-border-color !default;
                        })
                    }}
                    className={cx({ 'react-select-invalid': this.props.invalid })}
                />
            </div>
        )

        // //React-select
        // // control options
        // $select-input-bg:                   $input-bg;
        // $select-input-bg-disabled:          $input-disabled-bg;
        // $select-input-bg-focus:             $input-focus-bg;
        // $select-input-border-color:         $input-border-color;
        // $select-input-border-radius:        $input-border-radius;
        // $select-input-border-focus:         $input-focus-border-color;
        // $select-input-box-shadow-focus:     $input-focus-box-shadow;
        // $select-input-border-width:         $input-border-width;
        // $select-input-height:               $input-height;
        // $select-input-internal-height:      $input-height-inner;
        // $select-input-placeholder:          $input-placeholder-color;
        // $select-text-color:                 $input-color;
        // $select-link-hover-color:           $dropdown-link-hover-color;
        // $select-input-hover-box-shadow:     $dropdown-box-shadow;

        // $select-padding-vertical:           $input-btn-padding-y;
        // $select-padding-horizontal:         $input-btn-padding-x;

        // $select-noresults-color:            lighten($select-text-color, 40%) !default;
    }
}
