import React from 'react'
import { Field } from 'react-final-form'
import { Button, ButtonGroup, FormGroup, FormText, Label } from 'reactstrap'

import * as infopointLogo from 'public/img/infopoint_colour.svg'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import FieldWarning from '@src/components/forms/FieldWarning'
import WhenFieldChanges from '@src/components/forms/WhenFieldChanges'
import Wizard from '@src/components/forms/Wizard'
import WizardPage from '@src/components/forms/WizardPage'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import useBoolean from '@src/hooks/useBoolean'
import { auth } from '@src/logic/auth/AuthService'
import { isTokenExpired } from '@src/logic/auth/jwtHelper'
import { SharedDomains } from '@src/logic/company/domains'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { isValidABN, required } from '@src/logic/forms/validation'
import { ActivateAccountExisting, ActivateAccountNew, ActivateAccountSendActivateEmail, CompaniesList, CompanyMeGet, UserProfileGet } from '@src/logic/http/Api'

import { isAxiosError } from '@src/logic/http/helpers'
import { stopPropagation } from '@src/logic/utils/Events'
import { Company } from '@src/types/principal'

const validate = (values: ISignupFormData) => {
    const errors = {} as any

    if (!values.password) {
        errors.password = 'Required'
    } else {
        const upper = +(/[A-Z]/.test(values.password))
        const lower = +(/[a-z]/.test(values.password))
        const number = +(/\d/.test(values.password))
        const character = +(/\W/.test(values.password))

        if (upper + lower + number + character < 3) {
            errors.password = ['Requires 3 of uppercase, lowercase, number, and special character']
        } else if (values.password.length < 8) {
            errors.password = 'Needs to be at least 8 characters'
        }
    }

    if (!values.confirmPassword || values.password !== values.confirmPassword) {
        errors.confirmPassword = 'Passwords do not match'
    }

    return errors
}

interface ISignupFormProps {
    token: string
}

interface ISignupFormData {
    firstName: string
    lastName: string
    password: string
    confirmPassword: string
    company: {
        id: string
        abn: string
        name: string
    }
    autoApproveDomain: boolean
    terms: boolean
    isNewCompany: boolean
}

const SignupForm: React.FC<ISignupFormProps> = ({ token }) => {
    const [decodedToken] = React.useState(auth.getUserProfile(token))
    const [tokenExpired] = React.useState(isTokenExpired(token))

    const activationEmailSent = useBoolean(false)
    const [requireApprovalCompany, setRequireApprovalCompany] = React.useState<ISignupFormData['company']>()

    const company = useAsyncCancellable(async cancelToken => decodedToken.companyId ? (await CompanyMeGet({ cancelToken, accessToken: token })).data : Promise.resolve<Company>(undefined), [], { executeOnUpdate: false })
    const user = useAsyncCancellable(async cancelToken => (await UserProfileGet({ cancelToken, accessToken: token })).data, [], { executeOnUpdate: false })

    async function loadCompanies(filter: string) {
        const filterValue = filter.replace('\'', '\\\'')
        const result = await CompaniesList(`name:'${filterValue}' OR abn:'${filterValue}'`, undefined, 1, 50, { accessToken: token })

        const options = result.data

        return options
    }

    async function activateAccount(values: ISignupFormData) {
        if (values.isNewCompany) {
            try {
                await ActivateAccountNew(
                    {
                        firstName: values.firstName,
                        lastName: values.lastName,
                        password: values.password,
                        abn: values.company.abn,
                        companyName: values.company.name,
                        autoApproveDomains: values.autoApproveDomain ? [user.result.email.split('@')[1]] : []
                    },
                    token
                )
            } catch (err) {
                if (isAxiosError(err) && err.response && err.response.status === 400) {
                    const submitErrors = buildFormErrorsFromModelState(values, err.response.data)

                    if (err.response.data.abn) {
                        const error = err.response.data.abn instanceof Array ? err.response.data.abn[0] : err.response.data.abn
                        if (submitErrors.company) submitErrors.company.abn = error
                        else submitErrors.company = { id: undefined, name: undefined, abn: error }
                    }

                    return submitErrors
                }
            }
        } else {
            await ActivateAccountExisting(
                {
                    firstName: values.firstName,
                    lastName: values.lastName,
                    password: values.password,
                    companyId: company.result ? company.result.id : values.company.id
                },
                token
            )

            if (!company.result) setRequireApprovalCompany(values.company)
        }
    }

    async function resendActivationEmail() {
        await ActivateAccountSendActivateEmail(decodedToken.userId)
        activationEmailSent.setTrue()
    }

    function parseAbn(value: string) {
        return value.replace(/\D/g, '').trim()
    }

    function formatAbn(value: string) {
        return value ? `${value.substring(0, 2)} ${value.substring(2, 5)} ${value.substring(5, 8)} ${value.substring(8, 11)}`.trim() : ''
    }

    function validateTerms(value: boolean) {
        return value == null || !value ? 'You must accept the terms to activate your account.' : undefined
    }

    function warnSharedDomains(value: boolean) {
        const userDomain = user.result.email.split('@')[1]
        const isSharedDomain = SharedDomains.includes(userDomain)

        return isSharedDomain ? <span>It is strongly recommended that you do not auto approve shared domains like <strong>{userDomain}</strong></span> : null
    }

    return (
        <>
            <img className="mt-2 mb-2 mx-auto" width="200" src={infopointLogo} alt="InfoPoint Logo" />
            <h1 className="mb-3 mx-auto">Activate Your Account</h1>
            {!tokenExpired && (
            (user.loading || company.loading)
                ? <div className="d-flex flex-column align-items-center justify-content-center">
                    <div><FA icon="circle-notch" size="3x" className="text-center my-3" spin /></div>
                    <p>
                        Loading...
                    </p>
                </div>
                : <Wizard<ISignupFormData>
                    onSubmit={activateAccount}
                    initialValues={{
                        firstName: user.result.firstName,
                        lastName: user.result.lastName,
                        company: company.result ? {
                            id: company.result.id,
                            abn: company.result.abn,
                            name: company.result.name
                        } : undefined,
                        autoApproveDomain: !SharedDomains.includes(user.result.email.split('@')[1]),
                        password: '',
                        confirmPassword: '',
                        terms: false,
                        isNewCompany: false
                    }}
                >
                    <WizardPage name="Basic Info" validate={validate}>
                        <div>
                            <FormText className="mb-3">Finish activating your account by confirming your details below and setting your account password. You will also be required to join or register your company.</FormText>
                            <FormGroup>
                                <Label for="firstName">First Name</Label>
                                <Field id="firstName" name="firstName" component={ValidatedInput} validate={required} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="lastName">Last Name</Label>
                                <Field id="lastName" name="lastName" component={ValidatedInput} validate={required} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="password">Password</Label>
                                <Field id="password" name="password" component={ValidatedInput} type="password" />
                            </FormGroup>
                            <FormGroup className="mb-4">
                                <Label for="confirmPassword">Confirm Password</Label>
                                <Field id="confirmPassword" name="confirmPassword" component={ValidatedInput} type="password" />
                            </FormGroup>
                        </div>
                    </WizardPage>
                    <WizardPage name="Company" nextLabel="Activate" submit>
                        <>
                            {company.result && <p>You have been invited to join {company.result.name} (ABN {company.result.abn})</p>}
                            {!company.result && <Field name="isNewCompany">
                                {({ input: { value: isNewCompany, onChange } }) => (
                                    <>
                                        <FormGroup className="d-flex justify-content-center">
                                            <ButtonGroup>
                                                <Button color="tertiary" type="button" active={!isNewCompany} onClick={() => onChange(false)}>Join Existing</Button>
                                                <Button color="tertiary" type="button" active={isNewCompany} onClick={() => onChange(true)}>Create New</Button>
                                            </ButtonGroup>
                                        </FormGroup>
                                        {!isNewCompany ? (
                                            <FormGroup>
                                                <FormText className="mb-2">If your company is already on InfoPoint, use the search below to find them. You will need to be approved by company adminsitrators before being able to login.</FormText>
                                                <Label>Search for your company</Label>
                                                <Field
                                                    name="company"
                                                    component={ValidatedSelect}
                                                    async
                                                    cacheOptions
                                                    defaultOptions
                                                    loadOptions={loadCompanies}
                                                    isClearable
                                                    filterOption={_ => true}
                                                    getOptionLabel={c => c.name}
                                                    getOptionValue={c => c.id}
                                                    formatOptionLabel={c => <span>{c.name} <span className="text-muted">(ABN {formatAbn(c.abn)})</span></span>}
                                                    placeholder="Search for company by name or ABN"
                                                />
                                            </FormGroup>) : (
                                                <>
                                                    <FormText className="mb-2">Create a new company on InfoPoint. You'll be able to update more of your companies details later, as well as invite other members of your team.</FormText>
                                                    <FormGroup>
                                                        <Label for="company.abn">Company ABN</Label>
                                                        <Field id="company.abn" name="company.abn" component={ValidatedInput} validate={isValidABN} format={formatAbn} parse={parseAbn} maxLength={14} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Label for="company.name">Company Name</Label>
                                                        <Field id="company.name" name="company.name" component={ValidatedInput} validate={required} />
                                                    </FormGroup>
                                                    <FormGroup>
                                                        <Field name="autoApproveDomain">
                                                            {field => <ValidatedCheckboxRadio id="autoApproveDomain" {...field as any} label={`Automatically approve new users with ${user.result.email.split('@')[1]} email`} />}
                                                        </Field>
                                                        <FieldWarning name="autoApproveDomain" shouldWarn={warnSharedDomains} />
                                                        <FormText>If someone is invited to InfoPoint and requests to join your company, you will have to manually approve them. Anyone who is invited from <strong>{user.result.email.split('@')[1]}</strong> will be automatically approved to join your company.</FormText>
                                                    </FormGroup>
                                                </>
                                            )}
                                    </>
                                )}
                            </Field>}
                            <hr />
                            <FormGroup>
                                <Field name="terms" validate={validateTerms}>
                                    {(termProps) => {
                                        const showError = termProps.meta.touched && !!termProps.meta.error
                                        return (
                                            <>
                                                <ValidatedCheckboxRadio id="terms-and-conditions" {...termProps as any} label={<span>I have read and agree to the <a href="http://infopoint.com.au/terms-and-conditions/" target="_blank" rel="noopener noreferrer" onClick={stopPropagation}>Terms of Service</a></span>} />
                                                {showError && <span className="form-text text-danger">{termProps.meta.error}</span>}
                                            </>
                                        )
                                    }}
                                </Field>
                            </FormGroup>
                            <WhenFieldChanges
                                field="isNewCompany"
                                becomes={false}
                                set="company"
                                to={undefined}
                            />
                            <WhenFieldChanges
                                field="isNewCompany"
                                becomes={true}
                                set="company"
                                to={undefined}
                            />
                        </>
                    </WizardPage>
                    <WizardPage name="Welcome" hideNavigation>
                        <div className="d-flex flex-column align-items-center">
                            <h1>Welcome to InfoPoint!</h1>
                            <div className="my-3"><FA className="text-success" icon="check-circle" size="4x" /></div>
                            {requireApprovalCompany
                                ? <p>An admin from <strong>{requireApprovalCompany.name}</strong> will need to approve you before you can login. We've sent them notification to let them know!</p>
                                : <p>Your InfoPoint account has now been activated.</p>}
                            <Button tag="a" color="success" href="/">Great, take me to login</Button>
                        </div>
                    </WizardPage>
                </Wizard>
            )}
            {tokenExpired && !activationEmailSent.value &&
                <div>
                    <p>The account activation link you have used has expired. Use the button below to send a new activation link to your email address.</p>
                    <p className="text-center"><Button color="primary" onClick={resendActivationEmail}>Resend Activation Link</Button></p>
                </div>
            }
            {activationEmailSent.value &&
                <div>
                    <p>A new activation link has been sent to your email. Use that link to continue activating your account.</p>
                </div>
            }
        </>
    )
}

export default SignupForm
