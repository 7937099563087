import queryString from 'query-string'
import URI from 'urijs'

export const qualifyUrl = (url: string): string => new URI(process.env.INFOPOINT_SERVER + url).normalizePathname().toString()

export class QueryStringBuilder {
    private queryStringMap: object = {}

    constructor(search?: string) {
        if (search) {
            this.queryStringMap = queryString.parse(search)
        }
    }

    public set = <T = string | number | boolean>(key: string, value: T): QueryStringBuilder => {
        this.queryStringMap[key] = encodeURIComponent(value.toString())
        return this
    }

    public remove = (key: string): QueryStringBuilder => {
        delete this.queryStringMap[key]
        return this
    }

    public build = (): string => {
        return Object.keys(this.queryStringMap).reduce(
            (query, key, index) => {
                if (index === 0) { return encodeURIComponent(key) + '=' + this.queryStringMap[key] }
                return query + '&' +  encodeURIComponent(key) + '=' + this.queryStringMap[key]
            },
            '')
    }
}
