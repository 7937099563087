import React from 'react'
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { RevisionApprove, RevisionReject } from '@src/logic/http/Api'
import { Revision } from '@src/types/document'

interface IProps extends IModalPropsManager {
    revision: Revision
    onApproveOrReject: () => void
}

interface IState {
    comments: string
}

// TODO: Modal might break on display < 480px due to auth0 styling at breakpoint
export default class RevisionApprovalModal extends React.Component<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            comments: ''
        }
    }

    public handleCommentsChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ comments: e.target.value })
    }

    public approveRevision = () => {
        RevisionApprove(this.props.revision.id, this.state.comments)
            .then((res) => {
                this.props.onApproveOrReject()
                this.props.toggle()
            })
    }

    public rejectRevision = () => {
        RevisionReject(this.props.revision.id, this.state.comments)
            .then((res) => {
                this.props.onApproveOrReject()
                this.props.toggle()
            })
    }

    public render() {
        const { revision } = this.props
        return (
            <Modal wrapClassName="d-block" isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Review Revision</ModalHeader>
                <ModalBody>
                    <p>You may leave comments along with your decision to approve or reject. If you are not yet ready to commit, close the dialog in the top right corner.</p>
                    <p><strong>Request Comments:</strong></p>
                    <p>{revision.approval.requestComments}</p>
                    <p><strong>Response:</strong></p>
                    <Input type="textarea" className="mb-3" value={this.state.comments} onChange={this.handleCommentsChange} />
                </ModalBody>
                <ModalFooter>
                    <Button color="success" className="mr-2" onClick={this.approveRevision}>Approve</Button>
                    <Button color="danger" onClick={this.rejectRevision}>Reject</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
