import { isEqual } from 'lodash'
import React from 'react'

export function useDeepCompareMemoize<T>(value?: T) {
    const ref = React.useRef<T>()

    if (!isEqual(value, ref.current)) {
        ref.current = value
    }

    return ref.current
}
