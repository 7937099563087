export function toCapitalizedWords(name) {
    const words = name.match(/[A-Za-z][a-z]*/g)

    return words.map(capitalize).join(' ')
}

export function capitalize(word: string) {
    return word.charAt(0).toUpperCase() + word.substring(1)
}

export function humanize(text: string) {
    return capitalize(underscore(text).replace(/_id$/, '').replace(/_/g, ' ').trim())
}

export function underscore(text: string) {
    return text.trim().replace(/([a-z\d])([A-Z]+)/g, '$1_$2').replace(/([A-Z\d]+)([A-Z][a-z])/g, '$1_$2').replace(/[-\s]+/g, '_').toLowerCase()
}

export function slugify(text: string) {
    return text.toLowerCase().replace(/[^a-z0-9 _-]/g, '').replace(/\s+/g, '_').replace(/_+/g, '_')
}

const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export function validateEmail(email) {
    return emailRegex.test(String(email).toLowerCase())
}

export function toBoolean(value: string): boolean {
    if (value == null) return null

    const lower = value.toLowerCase()
    if (lower === 'true') return true
    if (lower === 'false') return false

    return null
}

export function isNullOrEmpty(value: string): boolean {
    return value == null || value.trim() === ''
}

export function regexEscape(value: string): string {
    return value.replace(/[|\\{}()[\]^$+*?.]/g, '\\$&')
}

export function toPercent(value: number): string {
    if (value == null || isNaN(value)) return '0%'
    return `${(value * 100).toFixed(2)}%`
}

export function safeHtmlId(value: string): string {
    return value.replace(/^[^a-z]+|[^\w:.-]+/gi, '')
}

export function isValidDomain(value: string): boolean {
    return /^((?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9])$/.test(value)
}
