import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import { Dispatch, bindActionCreators } from 'redux'

import { CancelToken } from 'axios'
import moment from 'moment'

import { loadProjectCostsOverview } from '@src/actions/project'
import * as WidgetActions from '@src/actions/widget'
import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CostValue from '@src/components/costs/common/CostValue'
import PaymentClaimModal from '@src/components/costs/payments/PaymentClaimModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { PaymentClaimDelete, PaymentClaimsList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { mutedNotSet, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { getProjectState } from '@src/reducers/widget'
import { AggregatePaymentClaimData, CostsOverview, PaymentClaim, PaymentClaimBrief, PaymentClaimStatus } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IState {
    isCreating: boolean
    isEditing: boolean
    aggregatePaymentData: AggregatePaymentClaimData
    paymentClaimToDelete?: PaymentClaimBrief
    paymentClaimToEdit?: PaymentClaimBrief
}

interface IConnectedState {
    projectId: string
    costsOverview: CostsOverview
    widgetPaymentClaimIds: string[]
}

interface IConnectedDispatch {
    reloadCostsOverview: () => void
    widgetActions: typeof WidgetActions
}

class PaymentSection extends React.PureComponent<RouteComponentProps & IConnectedState & IConnectedDispatch, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<PaymentClaimBrief, 'id'>>

    constructor(props: RouteComponentProps & IConnectedState & IConnectedDispatch) {
        super(props)

        this.searchSectionRef = React.createRef<SearchSectionType<PaymentClaimBrief, 'id'>>()

        this.state = {
            isCreating: false,
            isEditing: false,
            aggregatePaymentData: null,
            paymentClaimToDelete: null,
            paymentClaimToEdit: null
        }
    }

    private readonly loadPaymentClaims = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken): Promise<ISearchResult<PaymentClaimBrief>> => {
        const response = await PaymentClaimsList(this.props.projectId, filter, sort, page, perPage, { cancelToken })
        this.setState({ aggregatePaymentData: response.data.aggregatePaymentData })
        return {
            items: response.data.paymentClaims,
            totalItems: response.headers[Headers.PaginationTotalCount]
        }
    }

    private readonly handlePaymentClaimsSelected = (...paymentClaims: PaymentClaimBrief[]) => {
        const selectedIds = [...this.props.widgetPaymentClaimIds]
        this.props.widgetActions.addPaymentClaims({ projectId: this.props.projectId, entities: paymentClaims.filter(p => !selectedIds.includes(p.id)) })
        this.props.widgetActions.removePaymentClaims({ projectId: this.props.projectId, entityIds: paymentClaims.filter(c => selectedIds.includes(c.id)).map(c => c.id) })
    }

    private readonly setCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly clearCreating = () => {
        this.setState({ isCreating: false })
    }

    private readonly setPaymentClaimToEdit = (paymentClaim: PaymentClaimBrief) => {
        this.setState({ isEditing: true, paymentClaimToEdit: paymentClaim })
    }

    private readonly clearEditing = () => {
        this.setState({ isEditing: false })
    }

    private readonly clearPaymentClaimToEdit = () => {
        this.setState({ paymentClaimToEdit: null })
    }

    private readonly clearCreatingAndEditing = () => {
        this.setState({ isCreating: false, isEditing: false })
    }

    private readonly setPaymentClaimToDelete = (paymentClaim: PaymentClaimBrief) => {
        this.setState({ paymentClaimToDelete: paymentClaim })
    }

    private readonly clearPaymentClaimToDelete = () => {
        this.setState({ paymentClaimToDelete: null })
    }

    private readonly deletePaymentClaim = async () => {
        const { paymentClaimToDelete } = this.state
        this.clearPaymentClaimToDelete()

        try {
            await PaymentClaimDelete(this.props.projectId, paymentClaimToDelete.commitment.id, paymentClaimToDelete.id)
        } catch {
            NotificationService.error(<span>Failed to remove {paymentClaimToDelete.certificateNumber}</span>)
        }

        await this.searchSectionRef.current.doSearch()
    }

    private readonly handlePaymentCreatedOrUpdated = (paymentClaim: PaymentClaim, isNew: boolean) => {
        if (paymentClaim == null) {
            NotificationService.info('Payment claim updated')
            this.searchSectionRef.current.doSearch()
        } else {
            this.props.history.push(Routes.projectCostsPaymentDetail(this.props.projectId, paymentClaim.commitment.type, paymentClaim.commitment.id, paymentClaim.id))
        }
    }

    public render() {
        const { projectId, costsOverview, widgetPaymentClaimIds } = this.props
        const { isCreating, isEditing, paymentClaimToEdit, paymentClaimToDelete } = this.state

        return (
            <>
                <ActionBar className="pb-0 pt-0 d-block">
                    <Row>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Claimed</h5>
                            {this.state.aggregatePaymentData && <CostValue value={this.state.aggregatePaymentData.totalClaimed} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Certified</h5>
                            {this.state.aggregatePaymentData && <CostValue value={this.state.aggregatePaymentData.totalCertified} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Paid</h5>
                            {this.state.aggregatePaymentData && <CostValue value={this.state.aggregatePaymentData.totalPaid} />}
                        </Col>
                    </Row>
                </ActionBar>
                <SearchSection<PaymentClaimBrief, 'id'>
                    ref={this.searchSectionRef}
                    onItemsSelected={this.handlePaymentClaimsSelected}
                    selectedItems={widgetPaymentClaimIds}
                    defaultPerPage={50}
                    itemIdKey="id"
                    headers={[
                        {
                            name: 'Claim #',
                            sortKey: 'commitment_no',
                            sortable: true,
                            overrideRenderer: payment => <Link to={Routes.projectCostsPaymentDetail(projectId, payment.commitment.type, payment.commitment.id, payment.id)}>{payment.certificateNumber}</Link>
                        },
                        {
                            name: 'Supplier',
                            overrideRenderer: payment => valueOrMutedNotSet(payment.commitment.otherParty?.name)
                        },
                        {
                            name: 'Claim Date',
                            sortKey: 'claimDate',
                            sortable: true,
                            overrideRenderer: payment => payment.claimDate ? moment(payment.claimDate).format('L') : mutedNotSet
                        },
                        {
                            name: 'Invoice Date',
                            overrideRenderer: payment => payment.invoiceDate ? moment(payment.invoiceDate).format('L') : mutedNotSet
                        },
                        {
                            name: 'Claim Ref.',
                            overrideRenderer: payment => valueOrMutedNotSet(payment.claimReference)
                        },
                        {
                            name: 'Invoice Ref.',
                            overrideRenderer: payment => valueOrMutedNotSet(payment.invoiceReference)
                        },
                        {
                            name: 'Claimed',
                            overrideRenderer: commitment => <CostValue value={commitment.currentClaimed} />
                        },
                        {
                            name: 'Certified',
                            overrideRenderer: commitment => <CostValue value={commitment.currentCertified} />
                        },
                        {
                            name: 'Paid',
                            overrideRenderer: commitment => <CostValue value={commitment.currentPaid} />
                        },
                        {
                            name: 'Actions',
                            headerWrapperClass: 'text-right',
                            overrideRenderer: item => (
                                <div className="text-right">
                                    <TooltipLinkAction id={`edit-payment-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={this.setPaymentClaimToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                    <TooltipLinkAction id={`delete-payment-${item.id}`} tooltip="Remove" data={item} className="order-lg-1" onClick={this.setPaymentClaimToDelete} disabled={item.status !== PaymentClaimStatus.Entered || !isAuthorised(costsOverview.myAccess, Operations.Delete)}><FA icon="trash" /></TooltipLinkAction>
                                </div>
                            )
                        }
                    ]}
                    onSearch={this.loadPaymentClaims}
                    extraSearchBarElements={[
                        {
                            position: 'before',
                            element: doSearch => (
                                <Button onClick={this.setCreating}><FA icon="plus" /> Add</Button>
                            )
                        }
                    ]}
                >
                    <PaymentClaimModal
                        open={isCreating || isEditing}
                        projectId={projectId}
                        costsOverview={costsOverview}
                        toggle={this.clearCreatingAndEditing}
                        onItemCreatedOrUpdated={this.handlePaymentCreatedOrUpdated}
                        paymentClaimToEdit={paymentClaimToEdit}
                        onClosed={this.clearPaymentClaimToEdit}
                    />
                    <ConfirmationModal
                        danger
                        open={paymentClaimToDelete != null}
                        toggle={this.clearPaymentClaimToDelete}
                        header={'Remove commitment'}
                        message={<span>Are you sure you want to remove <strong>{paymentClaimToDelete?.certificateNumber}</strong>?</span>}
                        confirmAction="Remove"
                        rejectAction="Cancel"
                        onReject={this.clearPaymentClaimToDelete}
                        onConfirm={this.deletePaymentClaim}
                    />
                </SearchSection>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps): RouteComponentProps & IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        costsOverview: state.projects.activeCostsOverview,
        widgetPaymentClaimIds: getProjectState(state.widget, state.projects.active.id).paymentClaims.map(c => c.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps): IConnectedDispatch {
    return {
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview()),
        widgetActions: bindActionCreators(WidgetActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentSection)
