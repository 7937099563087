import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'

import { CancelToken } from 'axios'

import { reloadActiveProject } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedAsyncCreatableSelect from '@src/components/common/ValidatedAsyncCreatableSelect'
import ValidatedAsyncSelect from '@src/components/common/ValidatedAsyncSelect'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { IProjectCollaboratorSettingsFormData } from '@src/components/project/settings/ProjectCollaboratorSettingsForm'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { userBasicLabel, userBasicLabelFormat, userBasicValue } from '@src/logic/forms/SelectHelpers'
import { isOnOrAfter } from '@src/logic/forms/validation'
import { ProjectSettingsUpdate, ProjectsList, ProjectUsersList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { Project } from '@src/types/project'
import { RouteComponentProps } from 'react-router'

const CollaboratorSettingsSection: React.FC<RouteComponentProps> = ({ history }) => {

    const project = useSelector<RootState, Project>(s => s.projects.active!)
    const dispatch = useDispatch()

    const projectContactAsync = useAsyncCancellable((cancel, primaryContactId) => loadPrimaryContact(primaryContactId, cancel), [project.settings.primaryContact ? project.settings.primaryContact.id : undefined])

    const statusOptionsAsync = useAsyncCancellable((cancel, input: string) => loadStatusOptions(input, cancel), [undefined], { executeOnMount: false, executeOnUpdate: false })
    const categoryOptionsAsync = useAsyncCancellable((cancel, input: string) => loadCategoryOptions(input, cancel), [undefined], { executeOnMount: false, executeOnUpdate: false })
    const projectContactsAsync = useAsyncCancellable((cancel, input: string) => loadProjectUsers(input, cancel), [undefined], { executeOnMount: false, executeOnUpdate: false })

    async function loadStatusOptions(inputValue: string, cancelToken?: CancelToken): Promise<IOption<string>[]> {
        const response = await ProjectsList(isNullOrEmpty(inputValue) ? '' : `status: "${inputValue.replace('"', '\\"')}"`, undefined, undefined, undefined, { cancelToken })
        return [...new Set(response.data.filter(x => !isNullOrEmpty(x.settings.status)).map(x => x.settings.status))].map(status => ({ label: status, value: status }))
    }

    async function loadCategoryOptions(inputValue: string, cancelToken?: CancelToken): Promise<IOption<string>[]> {
        const response = await ProjectsList(isNullOrEmpty(inputValue) ? '' : `category: "${inputValue.replace('"', '\\"')}"`, undefined, undefined, undefined, { cancelToken })
        return [...new Set(response.data.filter(x => !isNullOrEmpty(x.settings.category)).map(x => x.settings.category))].map(category => ({ label: category, value: category }))
    }

    async function loadProjectUsers(inputValue: string, cancelToken: CancelToken): Promise<UserBasic[]> {
        const val = inputValue.replace('"', '\\"').replace(/\s/, ',')
        const response = await ProjectUsersList(project.id, isNullOrEmpty(inputValue) ? '' : `first_name: ${val} last_name: ${val}`, undefined, undefined, undefined, { cancelToken })
        return response.data
    }

    async function loadPrimaryContact(contactId: string | undefined, cancelToken: CancelToken): Promise<UserBasic | undefined> {
        if (contactId === undefined) return undefined
        const result = await ProjectUsersList(project.id, `id: "${contactId}"`, undefined, 1, 1, { cancelToken })

        return result.data.length > 0 ? result.data[0] : undefined
    }

    async function handleSaveSettings(values: IProjectCollaboratorSettingsFormData) {
        try {
            await ProjectSettingsUpdate(project.id, {
                category: values.category != null ? values.category.value : null,
                code: values.code,
                description: values.description,
                finishDate: values.finishDate,
                primaryContact: values.primaryContact != null ? values.primaryContact.id : null,
                projectValue: values.value,
                startDate: values.startDate,
                status: values.status != null ? values.status.value : null
            })
        } catch (err) {
            if (!isAxiosError(err)) return false

            if (!err.response) {
                NotificationService.error('There was an error connecting to InfoPoint. Please try again.')
                return true
            }

            switch (err.response.status) {
                case 400:
                    return buildFormErrorsFromModelState(values, err.response.data)
                case 403:
                    NotificationService.error('You do not have permission to make changes.')
                    return
                case 404:
                    NotificationService.error('Could not find project.')
                    history.push(Routes.DASHBOARD)
                    return
                default:
                    NotificationService.error('There was an error while saving.')
                    return
            }
        }

        dispatch(reloadActiveProject())
    }

    function startDateAfterFinish(value: Date, allValues: IProjectCollaboratorSettingsFormData) {
        return isOnOrAfter(value, allValues.startDate)
    }

    const authorised = isAuthorised(project.myAccess, ProjectOperations.Update)
    return (
        <Form
            onSubmit={handleSaveSettings}
            initialValues={{
                category: project.settings.category ? { label: project.settings.category, value: project.settings.category } : null,
                code: project.settings.code,
                description: project.settings.description,
                status: project.settings.status ? { label: project.settings.status, value: project.settings.status } : null,
                finishDate: new Date(project.settings.finishDate),
                startDate: new Date(project.settings.startDate),
                primaryContact: projectContactAsync.result,
                value: project.settings.projectValue
            }}
        >
            {({ handleSubmit }) => (
                <Card className="mb-3">
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="cogs" /> Collaborator Settings</span>
                        {authorised && <Button className="ml-auto" color="primary" onClick={handleSubmit}>Save</Button>}
                    </CardHeader>
                    <CardBody>
                        <FormText>The following settings are specific to your company. Changing these will not affect similar settings set by other collaborators.</FormText>
                        {!authorised && <FormText color="warning"><FA icon="exclamation-triangle" /> You need <strong>update</strong> permission on the project in order to change these settings.</FormText>}
                        <div className="mb-3" />
                        <FormGroup row>
                            <Col md={6}>
                                <Label>Project Code</Label>
                                <Field name="code" disabled={!authorised} component={ValidatedInput} />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label>Project Category</Label>
                                <Field name="category" isDisabled={!authorised} component={ValidatedAsyncCreatableSelect} isClearable defaultOptions loadOptions={categoryOptionsAsync.execute} />
                            </Col>
                        </FormGroup>
                        <Row>
                            <Col md={3}>
                                <FormGroup>
                                    <Label>Start Date</Label>
                                    <Field name="startDate" disabled={!authorised} component={ValidatedDatePicker} />
                                </FormGroup>
                            </Col>
                            <Col md={3}>
                                <FormGroup>
                                    <Label>Finish Date</Label>
                                    <Field name="finishDate" disabled={!authorised} component={ValidatedDatePicker} validate={startDateAfterFinish} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup row>
                            <Col md={6}>
                                <Label>Value</Label>
                                <InputGroup>
                                    <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                                    <Field name="value" disabled={!authorised} component={ValidatedCurrencyInput} />
                                </InputGroup>
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label>Status</Label>
                                <Field name="status" isDisabled={!authorised} component={ValidatedAsyncCreatableSelect} loadOptions={statusOptionsAsync.execute} isClearable defaultOptions />
                            </Col>
                        </FormGroup>
                        <FormGroup row>
                            <Col md={6}>
                                <Label>Primary Contact</Label>
                                <Field name="primaryContact" isDisabled={!authorised} component={ValidatedAsyncSelect} loadOptions={projectContactsAsync.execute} isClearable defaultOptions getOptionValue={userBasicValue} getOptionLabel={userBasicLabel} formatOptionLabel={userBasicLabelFormat} />
                            </Col>
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Field name="description" disabled={!authorised} component={ValidatedInput} type="textarea" />
                        </FormGroup>
                    </CardBody>
                </Card>
            )}
        </Form>
    )
}

export default CollaboratorSettingsSection
