import React from 'react'

export function mutedValue(value: string | number | boolean | Date) {
    return <span className="text-muted">{value}</span>
}


export function valueOrMutedFallback(value: any, fallback: string) {
    return value != null ? value : mutedValue(fallback)
}

export function valueOrMutedNotSet(value: any) {
    if (value == null) return mutedNotSet

    if (typeof value === 'string') {
        return value !== '' ? value : mutedNotSet
    }
}

export const mutedNotSet = mutedValue('(not set)')
