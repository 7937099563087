import React from 'react'
import { useSelector } from 'react-redux'
import { Card } from 'reactstrap'

import cx from 'classnames'

import { IconAvatar, UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import SidebarGroup from '@src/components/navigation/SidebarGroup'
import SidebarList from '@src/components/navigation/SidebarList'
import SidebarListItem from '@src/components/navigation/SidebarListItem'
import SidebarProjectNavigation from '@src/components/navigation/SidebarProjectNavigation'
import FeatureGate from '@src/containers/FeatureGate'
import useBoolean, { IBooleanHook } from '@src/hooks/useBoolean'
import { auth } from '@src/logic/auth/AuthService'
import { FeatureFlag, Features, FeatureValue } from '@src/logic/features/features'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'


interface IProps {
    collapsed: boolean,
    toggle: (open?: boolean) => void
}

const AppSidebar: React.FunctionComponent<IProps> = ({ collapsed, toggle }) => {
    const projectMenuCollapsed = useBoolean(true)
    const projectDashboardCollapsed = useBoolean(true)
    const costmoduleCollapsed = useBoolean(true)
    const companyName = useSelector<RootState, string>(state => state.session.company.name)
    const activeProjectId = useSelector<RootState, string>(state => state.projects.active ? state.projects.active.id : null)
    const costsOverview = useSelector<RootState, CostsOverview>(state => state.projects.activeCostsOverview)
    const features = useSelector<RootState, Features>(state => state.session.features)
    const currentUser = useSelector<RootState, Session.User>(state => state.session.user)

    function toggleSidebar() {
        projectDashboardCollapsed.setTrue()
        costmoduleCollapsed.setTrue()
        toggle()
    }

    function buildGroupToggle(sectionHook: IBooleanHook) {
        return () => {
            if (collapsed) {
                toggle()
            }
            sectionHook.toggle()
        }
    }

    return (
        <nav className={cx('sidebar', { 'sidebar--collapsed': collapsed, 'sidebar--open': !collapsed })}>
            <Link className="project sidebar__project" to={Routes.DASHBOARD}>
                <div className="project project__trigger">
                    <IconAvatar size="sm" icon="home" />
                    <span className="project__text">
                        <span className="project__title">Your Dashboard</span>
                    </span>
                </div>
            </Link>
            <SidebarProjectNavigation open={!projectMenuCollapsed.value} toggle={projectMenuCollapsed.toggle} />
            <div className="sidebar__inner">
                <Card className="d-md-none sidebar-group">
                    <h2 className="sidebar-group__heading" onClick={projectDashboardCollapsed.toggle}>
                        <a className="sidebar-group__trigger" role="button">
                            <span className="sidebar-group__icon mb-2">
                                <UserAvatar size="xs" imageUrl={currentUser.profilePictureLink} />
                            </span>
                            <span className="sidebar-group__title"> {`${currentUser.firstName} ${currentUser.lastName}`}</span>
                        </a>
                    </h2>
                </Card>
                {activeProjectId &&
                    <>
                        <SidebarGroup title="Project Dashboard" toggle={buildGroupToggle(projectDashboardCollapsed)} icon="tachometer" collapsed={collapsed || projectDashboardCollapsed.value}>
                            <SidebarList>
                                <SidebarListItem to={Routes.projectDocuments(activeProjectId)}>Documents</SidebarListItem>
                                <SidebarListItem to={Routes.projectTransmittals(activeProjectId)}>Transmittals</SidebarListItem>
                                <SidebarListItem to={Routes.projectEmails(activeProjectId)}>Emails</SidebarListItem>
                                <SidebarListItem to={Routes.projectCommunications(activeProjectId)}>Communications</SidebarListItem>
                                <SidebarListItem to={Routes.projectRegisters(activeProjectId)}>Registers</SidebarListItem>
                                <SidebarListItem to={Routes.projectUsers(activeProjectId)}>Users</SidebarListItem>
                            </SidebarList>
                        </SidebarGroup>
                        <FeatureGate features={features} flag={FeatureFlag.Costs} value={FeatureValue.CostsDefault}>
                            <SidebarGroup title="Cost Module" toggle={buildGroupToggle(costmoduleCollapsed)} icon="chart-line" collapsed={collapsed || costmoduleCollapsed.value}>
                                <SidebarList>
                                    <SidebarListItem to={Routes.projectCostsDashboard(activeProjectId)}>Dashboard</SidebarListItem>
                                    <SidebarListItem to={Routes.projectCostsBudget(activeProjectId)}>Budget</SidebarListItem>
                                    {costsOverview && costsOverview.commitmentDefinitions.map((cd) => {
                                        const route = Routes.projectCostsCommitmentType(activeProjectId, cd.code)
                                        return (<SidebarListItem key={cd.code} to={route}>{cd.name}</SidebarListItem>
                                        )
                                    })}
                                    <SidebarListItem to={Routes.projectCostsPayments(activeProjectId)}>Payment</SidebarListItem>
                                    <SidebarListItem to={Routes.projectCostsSettings(activeProjectId)}>Settings</SidebarListItem>
                                </SidebarList>
                            </SidebarGroup>
                        </FeatureGate>
                    </>}
                <Card className="d-md-none sidebar-group">
                    <h2 className="sidebar-group__heading">
                        <Link className="sidebar-group__trigger" to={Routes.SETTINGS_PROFILE}>
                            <span className="sidebar-group__icon"><FA icon="cog" /></span>
                            <span className="sidebar-group__title">Settings</span>
                        </Link>
                    </h2>
                </Card>
                <Card className="d-md-none sidebar-group">
                    <h2 className="sidebar-group__heading">
                        <Link className="sidebar-group__trigger" to={Routes.COMPANY}>
                            <span className="sidebar-group__icon"><FA icon="building" /></span>
                            <span className="sidebar-group__title">{companyName}</span>
                        </Link>
                    </h2>
                </Card>
                <Card className="d-md-none sidebar-group">
                    <h2 className="sidebar-group__heading" onClick={auth.logout}>
                        <a className="sidebar-group__trigger" role="button">
                            <span className="sidebar-group__icon">
                                <FA icon="sign-out" />
                            </span>
                            <span className="sidebar-group__title"> Logout</span>
                        </a>
                    </h2>
                </Card>
                <Link className="sidebar__link" to={Routes.COMPANY}>
                    <span className="sidebar-group__icon"><FA icon="building" /></span>
                </Link>
            </div>
            <div className="sidebar-footer">
                <Link className="sidebar-footer__link" to={Routes.COMPANY}>
                    <FA icon="building" />
                    <span> {companyName}</span>
                </Link>
                <a className="sidebar-footer__trigger" onClick={toggleSidebar}>
                    <FA icon="chevron-left" />
                </a>
            </div>
        </nav>
    )
}

export default AppSidebar
