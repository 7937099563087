export function invertOrChange(currentSearch: string, newSearchField: string): string {
    if (currentSearch == null || newSearchField == null) {
        return null
    }

    const trimmedCurrent = currentSearch.trim()
    const trimmedNew = newSearchField.trim()

    if (trimmedCurrent.startsWith('-')) {
        return trimmedNew
    }

    if (trimmedCurrent === trimmedNew) {
        return '-' + trimmedNew
    }

    return trimmedNew
}
