import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Button, Form as BootstrapForm, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { required } from '@src/logic/forms/validation'
import { RootState } from '@src/types/models'
import { PrincipalBrief, UserInternal } from '@src/types/principal'
import { useAsync } from 'react-async-hook'

interface IProps extends IModalPropsManager {
    loadApprovers: () => Promise<PrincipalBrief[]>
    onSubmit: <T>(values: IApprovalFormData) => Promise<T | void>
}

export interface IApprovalFormData {
    approver: UserInternal
    comments: string
}

const TransmittalApprovalModal: React.FC<IProps> = ({ open, toggle, onClosed, onSubmit, loadApprovers }) => {

    const currentUserId = useSelector<RootState, string>(s => s.session.user.id)

    const approversAsync = useAsync(
        async () => {
            if (!open) return Promise.resolve([])
            const approvers = await loadApprovers()
            const currentUserApproverIdx = approvers.findIndex(a => a.id === currentUserId)
            if (currentUserApproverIdx > -1) {
                const currentUser = approvers.splice(currentUserApproverIdx, 1)[0]
                return [currentUser, ...approvers]
            }
            return approvers
        },
        [open],
        { setLoading: s => ({ ...s, loading: true }), executeOnMount: false }
    )

    function formatApprovalLabel(option: PrincipalBrief) {
        if (option && option.id === currentUserId) return <strong>Self Approve</strong>

        return option.name
    }

    function getApproverLabel(approver: PrincipalBrief) {
        return approver.id === currentUserId ? 'Self Approve' : approver.name
    }

    function getApproverValue(approver: PrincipalBrief) {
        return approver.id
    }

    return (
        <Modal isOpen={open} toggle={toggle} onClosed={onClosed}>
            <Form<IApprovalFormData>
                onSubmit={onSubmit}
            >
            {form =>
                <>
                    <ModalHeader toggle={toggle}>Request Approval</ModalHeader>
                    <ModalBody>
                        <BootstrapForm>
                            <FormGroup>
                                <Label>Approver</Label>
                                <Field
                                    name="approver"
                                    component={ValidatedSelect}
                                    validate={required}
                                    isLoading={approversAsync.loading}
                                    defaultValue={approversAsync.result && approversAsync.result.length > 0 && approversAsync.result[0]}
                                    options={approversAsync.result}
                                    formatOptionLabel={formatApprovalLabel}
                                    getOptionLabel={getApproverLabel}
                                    getOptionValue={getApproverValue}
                                />
                            </FormGroup>
                            <FormGroup>
                                <Label>Comments</Label>
                                <Field name="comments" component={ValidatedInput} type="textarea" />
                            </FormGroup>
                        </BootstrapForm>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="default" onClick={toggle}>Cancel</Button>
                        <Field name="approver" subscription={{ value: true }}>
                            {({ input: { value: selectedApprover } }) => <Button onClick={form.handleSubmit}>{selectedApprover?.id === currentUserId ? 'Approve & Send' : 'Request Approval'}</Button>}
                        </Field>
                    </ModalFooter>
                </>
            }
            </Form>
        </Modal>
    )
}

export default React.memo(TransmittalApprovalModal)
