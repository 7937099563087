import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { ConfigProps, Field, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'

import { IOption } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import MetadataTileInput from '@src/components/metadata/definitions/MetadataDefinitionTileInput'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { isMetadataKeyForm, required } from '@src/logic/forms/validation'
import { slugify } from '@src/logic/utils/Strings'
import { IMetadataDefinition, MetadataPropertyToLinkType, MetadataTypes } from '@src/types/metadata'

export interface INewDefinitionModalProps extends IProps, ConfigProps<{}, IProps & IModalPropsManager> {}

interface IProps {
    existingDefinitions: IMetadataDefinition[]
    validTypes: MetadataTypes[]
}

interface IInternalProps {
    selectedType: IOption<MetadataTypes>
}


export interface INewDefinitionModalFormData {
    name: string
    key: string
    type: MetadataTypes
    parentKey?: IOption<string>
}

class NewDefinitionModal extends React.PureComponent<IProps & IInternalProps & IModalPropsManager & InjectedFormProps<INewDefinitionModalFormData, IProps & IInternalProps & IModalPropsManager>> {

    private handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.props.change('key', slugify(e.target.value))
    }

    private propertyTypeSelected = () => this.props.selectedType && Object.keys(MetadataPropertyToLinkType).includes(this.props.selectedType.value)
    private linkColumnsAsOptions = (): IOption<string>[] => this.props.existingDefinitions.filter(d => d.type === MetadataPropertyToLinkType[this.props.selectedType.value]).map(d => ({ label: d.name, value: d.key }))

    public render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Add metadata</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">Name</Label>
                        <Field id="name" name="name" component={ValidatedInput} onChange={this.handleNameChange} validate={[required]} />
                        <FormText>Name of the field.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Search Key</Label>
                        <Field name="key" component={ValidatedInput} validate={[required, isMetadataKeyForm]} />
                        <FormText>This will be used to identify this field when searching.</FormText>
                    </FormGroup>
                    <FormGroup>
                        <Label>Type</Label>
                        <Field name="type" component={MetadataTileInput} availableTypes={this.props.validTypes} validate={required} />
                    </FormGroup>
                    {this.propertyTypeSelected() &&
                        <FormGroup>
                            <Label>Parent</Label>
                            <Field name="parentKey" component={ValidatedSelect} validate={[required]} options={this.linkColumnsAsOptions()} />
                        </FormGroup>
                    }
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.handleSubmit}>Create</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

const selector = formValueSelector('addMetadataDefinition')
function mapStateToProps(state, ownProps: IProps & IModalPropsManager & Partial<ConfigProps<{}, IProps & IModalPropsManager>>): IProps & IInternalProps & IModalPropsManager & Partial<ConfigProps<{}, IProps & IInternalProps & IModalPropsManager>> {
    return {
        ...ownProps,
        selectedType: selector(state, 'type'),
        onSubmitSuccess: (result, dispatch, props) => {
            ownProps.toggle()
            if (ownProps.onSubmitSuccess) ownProps.onSubmitSuccess(result, dispatch, props)
        }
    }
}

export default connect(mapStateToProps)(reduxForm<INewDefinitionModalFormData, IProps & IInternalProps & IModalPropsManager>({ form: 'addMetadataDefinition', destroyOnUnmount: true, forceUnregisterOnUnmount: true })(NewDefinitionModal))
