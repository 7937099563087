import React from 'react'

import cx from 'classnames'

interface IProps<T, P extends React.HTMLAttributes<HTMLElement> = React.HTMLAttributes<HTMLDivElement>> {
    data: T
    onClick: (arg: T) => void
    tag?: React.ElementType<P>
}

export default class Action<T, P extends React.HTMLAttributes<HTMLElement> = React.HTMLAttributes<HTMLDivElement>> extends React.PureComponent<IProps<T, P> & Omit<P, 'onClick'>> {

    public handleClick = (e: React.MouseEvent) => {
        this.props.onClick(this.props.data)
    }

    public render() {
        const { tag, onClick, data, className, ...tagAttrs } = this.props

        const Tag: React.ElementType<any> = tag || 'div'

        return (
            <Tag role="button" onClick={this.handleClick} className={cx('pointer', className)} {...tagAttrs} />
        )
    }
}
