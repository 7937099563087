import React from 'react'
import { useSelector } from 'react-redux'
import { Breadcrumb } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import CrumbItem from '@src/components/navigation/Crumb'
import { Crumb, RootState } from '@src/types/models'

const AppBreadcrumb: React.FC = () => {

    const crumbs = useSelector<RootState, Crumb[]>(s => s.breadcrumb)?.map((crumb, idx, allCrumbs) => {
        const title = truncateIfLong(crumb.title)
        return (
            <CrumbItem key={idx}>
                {idx === allCrumbs.length - 1
                    ? title
                    : <Link to={crumb.path}>{title}</Link>
                }
            </CrumbItem>
        )
    })

    function truncateIfLong(title: string): string {
        return title.length > 30 ? `${title.substring(0, 30)}...` : title
    }

    return (
        <Breadcrumb aria-label="breadcrumb" listClassName="align-items-center">
            <CrumbItem first><Link to="/"><FA icon="home"/></Link></CrumbItem>
            {crumbs}
        </Breadcrumb>
    )
}

export default React.memo(AppBreadcrumb)
