import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { AnyAction, Dispatch } from 'redux'
import { submit } from 'redux-form'

import GroupForm, { IGroupFormData } from '@src/components/group/GroupForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { GroupCreate, GroupUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Group } from '@src/types/principal'

interface IProps extends IModalPropsManager {
    group: Group
    onSave?: () => void
}

interface IConnectedDispatch {
    submit: () => void
}

class GroupModal extends React.PureComponent<IProps & IConnectedDispatch> {

    private handleSave = async (values: IGroupFormData, dispatch: Dispatch<AnyAction>) => {
        if (this.props.group) {
            await GroupUpdate(this.props.group.id, {
                name: values.name,
                notes: values.description,
                memberIds: values.members ? values.members.map(x => x.id) : []
            })
        } else {
            await GroupCreate({
                name: values.name,
                notes: values.description,
                memberIds: values.members ? values.members.map(x => x.id) : []
            })
        }
    }

    private handleSubmitSuccess = async () => {
        if (this.props.group) {
            NotificationService.info('Group updated')
        } else {
            NotificationService.info('Group created')
        }

        this.props.onSave()
        this.props.toggle()
    }

    private getInitialValues = (): IGroupFormData => {
        const { group } = this.props
        return group ? {
            name: group.name,
            description: group.notes,
            members: group.members
        } : {
            name: '',
            description: '',
            members: []
        }
    }

    public render() {
        const { group, children, ...modalProps } = this.props
        return (
            <Modal isOpen={modalProps.open} onClosed={modalProps.onClosed} toggle={modalProps.toggle}>
                <ModalHeader toggle={modalProps.toggle}>{group ? group.name : 'New group'}</ModalHeader>
                <ModalBody>
                    <GroupForm
                        form={Forms.Group}
                        enableReinitialize
                        onSubmit={this.handleSave}
                        onSubmitSuccess={this.handleSubmitSuccess}
                        initialValues={this.getInitialValues()}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.submit}>Save</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps) {
    return {
        submit: () => dispatch(submit('group'))
    }
}

export default connect(undefined, mapDispatchToProps)(GroupModal)
