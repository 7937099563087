import React from 'react'
import { SizeMe } from 'react-sizeme'
import { Button, ButtonGroup, Card, CardTitle, Col, Container, InputGroup, InputGroupAddon, Row, UncontrolledTooltip } from 'reactstrap'

import { Bar } from '@nivo/bar'
import { Sunburst } from '@nivo/sunburst'
import axios, { CancelTokenSource } from 'axios'
import { BigNumber } from 'bignumber.js'

import ActionBar from '@src/components/common/ActionBar'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'
import Select from '@src/components/common/Select'
import CostValue from '@src/components/costs/common/CostValue'
import CostSnapshotTable from '@src/components/costs/dashboard/CostSnapshotTable'
import CreateSnapshotModal from '@src/components/costs/snapshots/CreateSnapshotModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import { auth } from '@src/logic/auth/AuthService'
import { costSnapshotLabel, costSnapshotValue } from '@src/logic/forms/SelectHelpers'
import { CostSnapshotCurrentGet, CostSnapshotDownloadLink, CostSnapshotGet, CostSnapshotsList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { CostReportSnapshot, CostReportSnapshotBrief, CostReportSnapshotRow, CostsOverview } from '@src/types/costs'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
}

interface IState {
    currentSnapshot: CostReportSnapshot
    activeSnapshot?: CostReportSnapshot
    aggregatedData: {
        currentBudget: BigNumber
        forecastFinalCosts: BigNumber
        forecastCostVariance: BigNumber
    }
    hiddenPhases: string[]
}

const ALL_PHASES_KEY = '__ALL_PHASES__'
const UNASSIGNED_PHASES_KEY = '__UNASSIGNED__'

export default class DashboardSection extends React.PureComponent<IProps, IState> {
    private getSnapshotCancelTokenSource: CancelTokenSource

    constructor(props) {
        super(props)

        this.state = {
            currentSnapshot: null,
            activeSnapshot: undefined,
            aggregatedData: null,
            hiddenPhases: []
        }
    }

    public componentDidMount() {
        this.loadCostSnapshot()
    }

    public componentWillUnmount() {
        if (this.getSnapshotCancelTokenSource) {
            this.getSnapshotCancelTokenSource.cancel()
        }
    }

    private readonly loadCostSnapshot = async () => {
        if (this.getSnapshotCancelTokenSource) {
            this.getSnapshotCancelTokenSource.cancel()
        }

        this.getSnapshotCancelTokenSource = axios.CancelToken.source()
        try {
            const response = await CostSnapshotCurrentGet(this.props.projectId, { cancelToken: this.getSnapshotCancelTokenSource.token })
            const agg = response.data.rows.reduce(
                (prev, curr) => ({
                    currentBudget: prev.currentBudget.plus(curr.currentBudget),
                    forecastFinalCosts: prev.forecastFinalCosts.plus(curr.forecastFinalCosts),
                    forecastCostVariance: prev.forecastCostVariance.plus(curr.forecastCostVariance)
                }),
                {
                    currentBudget: new BigNumber(0),
                    forecastFinalCosts: new BigNumber(0),
                    forecastCostVariance: new BigNumber(0)
                })
            this.setState({ currentSnapshot: response.data, aggregatedData: agg, ...(this.state.activeSnapshot == null ? { activeSnapshot: response.data } : { activeSnapshot: this.state.activeSnapshot }) })
        } catch (error) {
            //
        } finally {
            this.getSnapshotCancelTokenSource = null
        }
    }

    private readonly handleCheckPhase = (e, option: IDropdownCheckOption) => {
        if (option.key === ALL_PHASES_KEY) {
            if (this.state.hiddenPhases.length > 0) {
                this.setState({ hiddenPhases: [] })
            } else {
                this.setState({ hiddenPhases: [...this.props.costsOverview.phases.map(p => p.code), UNASSIGNED_PHASES_KEY] })
            }
        } else if (this.state.hiddenPhases.indexOf(option.key) > -1) {
            this.setState({ hiddenPhases: this.state.hiddenPhases.filter(p => p !== option.key) })
        } else {
            this.setState({ hiddenPhases: [...this.state.hiddenPhases, option.key] })
        }
    }

    private readonly searchSnapshots = async (query: string) => {
        const snapshots = (await CostSnapshotsList(this.props.projectId, `name: "${query.replace('"', '\"')}"`)).data
        return this.state.currentSnapshot ? [this.state.currentSnapshot, ...snapshots] : snapshots
    }

    private readonly handleChangeActiveSnapshot = async (snapshot: CostReportSnapshotBrief) => {
        this.setState({ activeSnapshot: snapshot ? (await CostSnapshotGet(this.props.projectId, snapshot.id)).data : undefined })
    }

    private readonly handleSnapshotDownload = () => {
        downloadURL(CostSnapshotDownloadLink(this.props.projectId, [this.state.activeSnapshot.id], auth.getSessionToken()))
    }

    public render() {
        const { costsOverview } = this.props
        const { currentSnapshot, activeSnapshot, aggregatedData: agg, hiddenPhases } = this.state

        const codeGroup = costsOverview.costCodes.reduce((prev, curr) => ({ ...prev, [curr.code]: curr.group }), {})
        const groupRows: { [group: string]: CostReportSnapshotRow[] } = costsOverview.costCodes.reduce((prev, curr) => ({ ...prev, [curr.group]: [] }), {})

        if (currentSnapshot) {
            for (const row of currentSnapshot.rows.filter(r => (r.phase == null && !hiddenPhases.includes(UNASSIGNED_PHASES_KEY)) || (r.phase != null && !hiddenPhases.includes(r.phase)))) {
                groupRows[codeGroup[row.costCode]].push(row)
            }
        }

        return (
            <>
                <ActionBar className="mb-3 pt-0 py-3 d-block">
                    <Row>
                        <Col xs={6} md="auto">
                            <h5>Current Budget</h5>
                            {currentSnapshot != null ? <CostValue value={agg.currentBudget.toNumber()} /> : <FA icon="spinner-third" spin />}
                        </Col>
                        <Col xs={6} md="auto">
                            <h5>Forecast Final Cost</h5>
                            {currentSnapshot != null ? <CostValue value={agg.forecastFinalCosts.toNumber()} /> : <FA icon="spinner-third" spin />}
                        </Col>
                        <Col xs={6} md="auto">
                            <h5>Variance</h5>
                            {currentSnapshot != null ? <CostValue value={agg.forecastCostVariance.toNumber()} errorNonZero /> : <FA icon="spinner-third" spin />}
                        </Col>
                        {/* <Col xs={3} className="ml-auto">
                            <InputGroup>
                                <Select<CostReportSnapshotBrief>
                                    async
                                    defaultOptions
                                    wrapperClass="flex-grow-1"
                                    value={this.state.activeSnapshot}
                                    loadOptions={this.searchSnapshots}
                                    onChange={this.handleChangeActiveSnapshot}
                                    getOptionLabel={costSnapshotLabel}
                                    getOptionValue={costSnapshotValue}
                                />
                                <InputGroupAddon addonType="append">
                                    <ModalToggle modal={CreateSnapshotModal} wrapperProps={{ id: 'dashboard-create-snapshot' }} modalProps={{ projectId: this.props.projectId }}><FA icon="layer-plus" /></ModalToggle>
                                    <UncontrolledTooltip target="dashboard-create-snapshot">Create snapshot</UncontrolledTooltip>
                                </InputGroupAddon>
                                <InputGroupAddon addonType="append">
                                    <Button id="dashboard-download-snapshot" disabled={!activeSnapshot} onClick={this.handleSnapshotDownload}><FA icon="download" /></Button>
                                    <UncontrolledTooltip target="dashboard-download-snapshot">Download snapshot</UncontrolledTooltip>
                                </InputGroupAddon>
                            </InputGroup>
                        </Col> */}
                        <Col className="ml-auto flex-shrink-1 flex-grow-0">
                            <ButtonGroup>
                                <DropdownCheckOptions
                                    id="dashboard-phases-options"
                                    caret
                                    right
                                    title={<FA icon="stream" />}
                                    options={this.props.costsOverview.phases.map(p => ({
                                        section: 'Phases',
                                        label: p.name,
                                        key: p.code,
                                        checked: !this.state.hiddenPhases.includes(p.code)
                                    })).concat(
                                        {
                                            section: 'Global',
                                            label: 'All Phases',
                                            key: ALL_PHASES_KEY,
                                            checked: this.state.hiddenPhases.length === 0
                                        },
                                        {
                                            section: 'Global',
                                            label: 'Unassigned',
                                            key: UNASSIGNED_PHASES_KEY,
                                            checked: !this.state.hiddenPhases.includes(UNASSIGNED_PHASES_KEY)
                                        })}
                                    onCheck={this.handleCheckPhase}
                                    sectionHeaders={['Global', 'Phases']}
                                />
                                <UncontrolledTooltip target="dashboard-phases-options">Filter phases</UncontrolledTooltip>
                            </ButtonGroup>
                        </Col>
                    </Row>
                </ActionBar>
                <Container fluid>
                    {this.state.currentSnapshot &&
                        <>
                            {/* TODO: Readd this in future when charts are finalized
                                <Row className="mb-3">
                                <Col lg={8}>
                                    <Card body>
                                        <CardTitle>Variance By Group</CardTitle>
                                        <SizeMe>
                                            {({ size }) => (
                                                <div className="pl-n2">
                                                    <Bar
                                                        height={200}
                                                        width={size.width}
                                                        margin={{
                                                            top: 20,
                                                            right: 80,
                                                            bottom: 40,
                                                            left: 80
                                                        }}
                                                        data={Object.keys(groupRows).map(g => ({
                                                            group: g,
                                                            variance: groupRows[g].reduce((total, r) => total + r.forecastCostVariance, 0)
                                                        }))}
                                                        indexBy="group"
                                                        keys={[
                                                            'variance',
                                                            'foo'
                                                        ]}
                                                        padding={0.4}
                                                        labelTextColor="inherit:darker(1.2)"
                                                        labelSkipWidth={16}
                                                        labelSkipHeight={16}
                                                        colorBy="index"
                                                        enableLabel={false}
                                                        enableGridX
                                                        enableGridY={false}
                                                        axisTop={{ tickSize: 0, tickPadding: 12, format: l => '' }}
                                                        axisBottom={{
                                                            legend: 'GROUPS',
                                                            legendPosition: 'middle' as any,
                                                            legendOffset: 50,
                                                            tickSize: 0,
                                                            tickPadding: 12
                                                        }}
                                                        axisRight={null}
                                                        markers={[
                                                            {
                                                                axis: 'y',
                                                                value: 0,
                                                                lineStyle: { strokeOpacity: 0 },
                                                            },
                                                            {
                                                                axis: 'y',
                                                                value: 0,
                                                                lineStyle: { stroke: '#f47560', strokeWidth: 1 },
                                                            }
                                                        ]}
                                                        axisLeft={{
                                                            format: v => formatCurrency(new BigNumber(v).abs()),
                                                            tickValues: 7
                                                        }}
                                                        tooltipFormat={formatCurrency}
                                                    />
                                                </div>
                                            )}
                                        </SizeMe>
                                    </Card>
                                </Col>
                                <Col lg={4}>
                                    <Card body>
                                        <CardTitle>Cost Code Breakdown</CardTitle>
                                        <SizeMe>
                                            {({ size }) => (
                                                <div>
                                                    <Sunburst
                                                        data={{
                                                            name: 'Cost Snapshot',
                                                            children: Object.keys(groupRows).map(g => ({
                                                                name: g,
                                                                children: groupRows[g].map(x => ({
                                                                    name: `${x.costCode} ${costsOverview.costCodes.find(cc => cc.code === x.costCode).name}`,
                                                                    value: groupRows[g].filter(y => y.costCode === x.costCode).map(row => row.forecastFinalCosts).reduce((total, finalCost) => total + finalCost)
                                                                }))
                                                            }))
                                                        }}
                                                        margin={{
                                                            top: 10,
                                                            right: 20,
                                                            bottom: 10,
                                                            left: 20
                                                        }}
                                                        identity="name"
                                                        value="value"
                                                        cornerRadius={2}
                                                        borderWidth={1}
                                                        borderColor="white"
                                                        colors="nivo"
                                                        colorBy="id"
                                                        childColor="inherit:darker(0.5)"
                                                        animate={true}
                                                        motionStiffness={90}
                                                        motionDamping={15}
                                                        isInteractive={true}
                                                        width={size.width}
                                                        height={200}
                                                    />
                                                </div>
                                            )}
                                        </SizeMe>
                                    </Card>
                                </Col>
                            </Row> */}
                            <Row className="mb-4">
                                <Col>
                                    <CostSnapshotTable
                                        costModule={costsOverview}
                                        groupRows={groupRows}
                                    />
                                </Col>
                            </Row>
                        </>
                    }
                </Container>
            </>
        )
    }
}
