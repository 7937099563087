import React from 'react'

export default function useUpdateEffect(effect: React.EffectCallback, dependencies: React.DependencyList) {
    const isInitialMount = React.useRef(true)
    React.useEffect(
        () => {
            if (isInitialMount.current) {
                isInitialMount.current = false
            } else {
                effect()
            }
        },
        dependencies)
}
