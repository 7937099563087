import { AsyncState, UseAsyncOptions, UseAsyncReturn, useAsync } from 'react-async-hook'

import axios, { CancelToken, CancelTokenSource } from 'axios'

export const InitialAsyncLoadingState: AsyncState<any> = {
    status: 'loading',
    loading: true,
    result: undefined,
    error: undefined
}

function useAsyncCancellable<T, Args extends any[] | [any]>(
    asyncFunction: (cancelToken: CancelToken, ...args: Args) => Promise<T>,
    params: Args,
    options?: UseAsyncOptions<T>
): UseAsyncReturn<T, Args> {
    let cancelTokenSource: CancelTokenSource

    // Wrap the original async function and enhance it with abortion login
    const asyncFunctionWrapper: (...args: Args) => Promise<T> = async (
        ...p: Args
    ) => {
        // Cancel previous async call
        if (cancelTokenSource) cancelTokenSource.cancel()

        // Create/store new abort controller for next async call
        const localCancelTokenSource = cancelTokenSource = axios.CancelToken.source()

        try {
            return await asyncFunction(localCancelTokenSource.token, ...p)
        } finally {
            // Unset abortController ref if response is already there,
            // as it's not needed anymore to try to abort it (would it be no-op?)
            if (localCancelTokenSource === cancelTokenSource) {
                cancelTokenSource = undefined
            }
        }
    }

    return useAsync(asyncFunctionWrapper, params, options)
}

export default useAsyncCancellable
