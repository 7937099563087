import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
    tag?: React.ReactType
}

interface IStatus {
    className: string
    description: string
}

export default class DocumentStatusIndicator extends React.PureComponent<IProps> {

    private calculateStatus = (): IStatus => {
        const { revision } = this.props
        if (revision.approval.status === 'pending') { return { className: 'status-badge--pre-warning', description: 'Pending Approval' } }
        if (revision.approval.status === 'denied') { return { className: 'status-badge--error', description: 'Rejected' } }
        if (revision.published === true) { return { className: 'status-badge--pre-success', description: 'Published' } }
        if (revision.approval.status === 'approved') { return { className: 'status-badge--success', description: 'Approved' } }

        return { className: 'status-badge--default', description: '' }
    }

    public render() {
        const { revision, tag } = this.props
        const status = this.calculateStatus()
        const Tag = tag || 'span'

        return (
            <>
                <UncontrolledTooltip className="text-center" delay={10} target={'dci-' + revision.id}>{status.description}</UncontrolledTooltip>
                <Tag id={'dci-' + revision.id} className={'status-badge ' + status.className} />
            </>
        )
    }
}
