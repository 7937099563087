import React from 'react'
import { Button } from 'reactstrap'

import Banner from '@src/components/banner/Banner'
import BannerPanel from '@src/components/banner/BannerPanel'
import ClampLines from '@src/components/common/ClampLines'
import { Company } from '@src/types/principal'

interface IProps {
    company: Company
    overrideTitle?: React.ReactChild
}

export default class CompanyBanner extends React.PureComponent<IProps> {
    public render() {
        const { company, overrideTitle } = this.props

        const bannerTitle = overrideTitle !== undefined ? overrideTitle : <ClampLines lines={2} text={company.name} />
        return (
            <Banner title={bannerTitle}>
                <BannerPanel>
                    {/* <UserAvatar sm />
                        <div>
                            <strong className="text-uppercase">Primary Contact</strong>
                            <p>[Contact Name]</p>
                            <p>[Contact Email]</p>
                            <p>[Contact Phone] [Contact Mobile]</p>
                        </div> */}
                    <Button color="primary" size="sm" disabled>Add Primary Contact</Button>
                </BannerPanel>
                <BannerPanel visibleSize="md">
                    <div className="mr-2 text-right">
                        <p><strong>Website</strong></p>
                        <p><strong>Phone</strong></p>
                        <p><strong>Address</strong></p>
                    </div>
                    <div>
                        <p>{company.website || '(not set)'}</p>
                        <p>{company.phone || '(not set)'}</p>
                        <p>{company.addresses[0] || '(not set)'}</p>
                    </div>
                </BannerPanel>
                <BannerPanel visibleSize="md">
                    <div className="mr-2 text-right">
                        <p><strong>Industry</strong></p>
                        <p><strong>Discipline</strong></p>
                        <p><strong>A.B.N</strong></p>
                        <p><strong>A.C.N</strong></p>
                    </div>
                    <div>
                        <p>{company.industry || '(not set)'}</p>
                        <p>{company.disciplines[0] || '(not set)'}</p>
                        <p>{company.abn || '(not set)'}</p>
                        <p>{company.acn || '(not set)'}</p>
                    </div>
                </BannerPanel>
            </Banner>
        )
    }
}
