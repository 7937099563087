import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Dispatch } from 'redux'
import { InjectedFormProps, SubmissionError, submit } from 'redux-form'

import { DebouncedFunc, debounce } from 'lodash'

import CommunicationForm, { ICommunicationFormData } from '@src/components/communication/CommunicationForm'
import * as Forms from '@src/logic/forms/Forms'
import { CommunicationUpdate, ProjectUsersList } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { Communication } from '@src/types/communication'
import { RootState } from '@src/types/models'

interface IProps {
    communication: Communication
    reloadCommunication: (communication: Communication) => void
}

interface IConnectedState {
    activeProjectId: string
}

class CommunicationEditPage extends React.PureComponent<RouteComponentProps & IProps & IConnectedState> {
    private readonly autoSaveCommunicationDebounced: DebouncedFunc<((values, dispatch, props, previousValues) => void)>

    constructor(props: RouteComponentProps & IProps & IConnectedState) {
        super(props)
        this.autoSaveCommunicationDebounced = debounce(this.autoSaveCommunication, 2000)
    }

    public componentWillUnmount() {
        this.autoSaveCommunicationDebounced.cancel()
    }

    private onCommunicationSave = async (values: ICommunicationFormData): Promise<Communication> => {
        const updatedCommunication: Communication = {
            ...this.props.communication,
            to: values.to,
            cc: values.cc,
            name: values.name,
            body: values.body,
            metadata: values.metadata
        }

        await CommunicationUpdate(this.props.communication.id, {
            name: updatedCommunication.name,
            description: updatedCommunication.description,
            tags: [],
            to: updatedCommunication.to.map(x => x.id || x.email),
            cc: updatedCommunication.cc.map(x => x.id || x.email),
            body: updatedCommunication.body,
            metadata: updatedCommunication.metadata
        })

        return updatedCommunication
    }

    private onCommunicationSaveSuccess = (result: Communication, dispatch) => {
        this.props.reloadCommunication(result)
    }

    private onCommunicationSaveFailed = (errors, dispatch, submitError: SubmissionError, props) => {
        NotificationService.error('There was an error while saving the communication.')
    }

    private getProjectUsers = async (nameFilter: string, limit: number) => {
        return (await ProjectUsersList(this.props.activeProjectId, `first_name: "${nameFilter}" last_name: "${nameFilter}"`, undefined, 1, limit)).data
    }

    private autoSaveCommunication = (values: ICommunicationFormData, dispatch: Dispatch, props: InjectedFormProps<ICommunicationFormData>, previousValues: ICommunicationFormData) => {
        if (!props.pristine && !isNullOrEmpty(values.body)) {
            dispatch(submit(Forms.EditTransmittal))
        }
    }

    public render() {
        const { communication } = this.props

        const initialValues: ICommunicationFormData = {
            name: communication.name,
            description: communication.description,
            to: communication.to,
            cc: communication.cc,
            body: communication.body,
            metadata: communication.metadata
        }

        return (
            <CommunicationForm
                destroyOnUnmount
                enableReinitialize
                keepDirtyOnReinitialize
                communication={communication}
                definitions={communication.metadataDefinitions}
                form={Forms.EditCommunication}
                initialValues={initialValues}
                getUsers={this.getProjectUsers}
                // onChange={this.autoSaveCommunicationDebounced}
                onSubmit={this.onCommunicationSave}
                onSubmitSuccess={this.onCommunicationSaveSuccess}
                onSubmitFail={this.onCommunicationSaveFailed}
            />
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        activeProjectId: state.projects.active.id,
    }
}

export default connect(mapStateToProps)(CommunicationEditPage)
