import React from 'react'
import { Field, Form } from 'react-final-form'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap'

import { uniqBy } from 'lodash'

import { reloadActiveProject } from '@src/actions/project'
import FA from '@src/components/common/FontAwesomeIcon'
import { IOption, InputActionChange } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { principalBriefLabel, principalBriefValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { ProjectUpdate, ProjectsList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import { FilterBuilder } from '@src/logic/iql/FilterBuilder'
import NotificationService from '@src/logic/notification/NotificationService'
import { RootState } from '@src/types/models'
import { PrincipalBrief } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IGeneralSettingsFormData {
    name: string
    description: string
    owner: PrincipalBrief
    client: PrincipalBrief
}

const GeneralSettingSection: React.FC = () => {
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const dispatch = useDispatch()

    const clientsAsync = useAsyncCancellable(
        async (cancelToken, input) => {
            const projectsResponse = await ProjectsList(FilterBuilder.for<any>().eq('client', input).build(), undefined, 1, 50, { cancelToken })
            return uniqBy(projectsResponse.data.map(x => x.client).filter(x => x != null), x => x.id)
        },
        [''],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    async function updateProject(values: IGeneralSettingsFormData) {
        try {
            await ProjectUpdate(project.id, {
                name: values.name,
                description: values.description,
                tags: project.tags || [],
                client: values.client?.id ?? values.client?.name,
                ownerId: values.owner ? values.owner.id : null
            })

            NotificationService.info('Project settings updated')
        } catch (err) {
            if (!isAxiosError(err)) {
                NotificationService.error('Failed to update project settings')
                return
            }

            switch (err.response.status) {
                case 400:
                    return buildFormErrorsFromModelState(values, err.response.data)
                case 403:
                    NotificationService.error('You do not have permission to update project settings')
                    return
                default:
                    NotificationService.error('Failed to update project settings')
                    return
            }
        }

        dispatch(reloadActiveProject())
    }

    function noCollaboratorFound(selectProps: { inputValue: string }) {
        return `No project collaborators found with name "${selectProps.inputValue}"`
    }

    function clientOptionLabel(option: PrincipalBrief | IOption<string>) {
        return '__isNew__' in option ? option.label : option?.name
    }

    function clientOptionValue(option: PrincipalBrief | IOption<string>) {
        return option?.id ?? option?.name
    }

    function formatClientCreateLabel(input: string) {
        return `Set project client "${input}"`
    }

    function getNewClientOption(input: string, label) {
        return { id: null, name: input, label: label, __isNew__: true }
    }

    function handleClientInputChange(value: string, action: InputActionChange) {
        if (action === 'input-change') {
            clientsAsync.execute(value)
        }
    }

    const authorised = isAuthorised(project.myAccess, ProjectOperations.Update)
    return (
        <Form<IGeneralSettingsFormData>
            onSubmit={updateProject}
            initialValues={{
                name: project.name,
                description: project.description,
                client: project.client,
                owner: project.owner
            }}
        >
            {({ handleSubmit }) => (
                <Card className="mb-3">
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="cogs" /> Project Settings</span>
                        {authorised && <Button className="ml-auto" color="primary" onClick={handleSubmit}>Save</Button>}
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Field name="name" component={ValidatedInput} validate={required} />
                                    <FormText>Name of the project.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Description</Label>
                                    <Field name="description" component={ValidatedInput} type="textarea" />
                                    <FormText>Common description for the project.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Code</Label>
                                    <Input disabled value={project.code} readOnly />
                                    <FormText>The original common project code provided for the project. This cannot be changed.</FormText>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input disabled type="email" value={project.email} readOnly />
                                    <FormText>Based off the common project code. This cannot be changed.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Owner</Label>
                                    <Field
                                        name="owner"
                                        component={ValidatedSelect}
                                        getOptionValue={principalBriefValue}
                                        getOptionLabel={principalBriefLabel}
                                        options={project.collaborators}
                                        noOptionsMessage={noCollaboratorFound}
                                    />
                                    <FormText>The collaborator who manages this project.</FormText>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Client</Label>
                                    <Field
                                        name="client"
                                        creatable
                                        allowCreateWhileLoading
                                        isLoading={clientsAsync.loading}
                                        isClearable
                                        component={ValidatedSelect}
                                        getOptionValue={clientOptionValue}
                                        getOptionLabel={clientOptionLabel}
                                        getNewOptionData={getNewClientOption}
                                        onInputChange={handleClientInputChange}
                                        formatCreateLabel={formatClientCreateLabel}
                                        options={[...project.collaborators, ...(clientsAsync.result ?? [])]}
                                        noOptionsMessage={noCollaboratorFound}
                                    />
                                    <FormText>The client identified for the project.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Form>
    )
}

export default GeneralSettingSection
