import { UserAccess } from '@src/types/access'
import { IpEntity } from '@src/types/common'
import { DocumentLink } from '@src/types/document'
import { PrincipalBrief } from '@src/types/principal'

export interface AggregateCommitmentData {
    totalValue: number
    totalPaid: number
    totalClaimed: number
    totalCertified: number
    numberOfCommitmentItems: number
    aggregateByCostCode: {
        [costCode: string]: {
            totalValue: number
            totalPaid: number
            totalClaimed: number
            totalCertified: number
        }
    }
}

export interface AggregatePaymentClaimData {
    totalPaid: number
    totalClaimed: number
    totalCertified: number
    numberOfPaymentItems: number
}

export interface CostEntityNumberDefinition {
    prefix: string
    significantPlaces: number
    currentNumber: number
}

export enum BudgetReportColumn {
    Unreported = 'unreported',
    Original = 'original',
    Adjustment = 'adjustment'
}

export const BudgetReportColumnName = {
    [BudgetReportColumn.Unreported]: 'Unreported',
    [BudgetReportColumn.Original]: 'Original',
    [BudgetReportColumn.Adjustment]: 'Adjustment'
}

export interface BudgetStatusDefinition {
    code: string
    columns: BudgetReportColumn[]
}

export interface BudgetOverview {
    approvedBudget: number
    budgetApprovalDate?: Date
    locked: boolean
    clientBudgetAdvice: DocumentLink[]
    consultantBudgetAdvice: DocumentLink[]
    itemStatusDefinitions: BudgetStatusDefinition[]
    adjustmentStatusDefinitions: BudgetStatusDefinition[]
}

export interface Phase {
    code: string
    name: string
    description: string
    approvedValue: number
    locked: boolean
}

export interface CashFlowDistribution {
    startPeriod: Date
    finishPeriod: Date
    distribution: number[]
}

export interface PhasedCashFlow {
    phase: string
    cashFlowDistribution: CashFlowDistribution
}

export interface ClientCostCode {
    group: string
    code: string
    name: string
}

export interface CostCodeBrief {
    group: string
    code: string
    name: string
    clientGroup?: string
    clientCode?: string
    clientName?: string
}

export interface CostCode {
    group: string
    code: string
    name: string
    clientCostCode: ClientCostCode
    cashFlowDistribution: CashFlowDistribution
    phasedCashFlow: PhasedCashFlow[]
}

export interface CostReportSnapshot {
    id: string
    createdBy: PrincipalBrief
    createdDate: Date
    updatedBy: PrincipalBrief
    updatedDate: Date
    name: string
    description: string
    period: Date
    tags: string[]
    rows: CostReportSnapshotRow[]
}

export interface CostReportSnapshotRow {
    costCode: string
    phase: string
    none: number
    originalBudget: number
    budgetAdjustments: number
    currentBudget: number
    committedCosts: number
    committedVariations: number
    committedTotalCosts: number
    forecastVariations: number
    forecastCosts: number
    forecastFinalCosts: number
    forecastCostVariance: number
    certified: number
    paid: number
    receipt: number
}

export interface CostReportSnapshotBrief {
    id: string
    name: string
    description: string
    period: Date
}

export enum CommitmentReportColumn {
    Unreported = 'unreported',
    CommittedCost = 'committedCost',
    CommittedVariation = 'committedVariation',
    ForecastCost = 'forecastCost',
    ForecastVariation = 'forecastVariation'
}

export const CommitmentReportColumnName = {
    [CommitmentReportColumn.Unreported]: 'Unreported',
    [CommitmentReportColumn.CommittedCost]: 'Committed Cost',
    [CommitmentReportColumn.CommittedVariation]: 'Committed Variation',
    [CommitmentReportColumn.ForecastCost]: 'Forecast Cost',
    [CommitmentReportColumn.ForecastVariation]: 'Forecast Variation'
}

export interface CommitmentStatusDefinition {
    code: string
    columns: CommitmentReportColumn[]
    allowInUnlockedBudget: boolean
    allowClaim: boolean
    requireOtherParty: boolean
    mapping: {
        [commitmentCode: string]: string
    }
}

export enum CommitmentDefinitionRole {
    Parent = 'parent',
    Child = 'child',
    Both = 'both'
}

export interface CommitmentDefinition {
    code: string
    name: string
    role: CommitmentDefinitionRole
    commitmentNumberDefinition: CostEntityNumberDefinition
    certificateNumberDefinition: CostEntityNumberDefinition
    statusDefinitions: CommitmentStatusDefinition[]
    defaultVariationAdditions?: number
    defaultVariationDeductions?: number
    defaultDocumentLinkCategories: string[]
    defaultPaymentDocumentLinkCategories: string[]
    hasInsurances: boolean
}

export interface CostsSettings {
    gstRate: number
}

export interface CostsOverview {
    settings: CostsSettings
    costCodes: CostCodeBrief[]
    phases: Phase[]
    budget: BudgetOverview
    commitmentDefinitions: CommitmentDefinition[]
    myAccess: UserAccess
}

export interface CostValue {
    value: number
    unit: string
    quantity: number
    rate: number
    notes: string
}

export interface StatusColumnValueMap<TColumn> {
    status: string
    column: TColumn
    value: CostValue
}

export interface IBudgetItem extends IpEntity {
    isAdjustment: boolean
    itemNo: number
    costCode: CostCodeBrief
    status: string
    phase: string
    date: Date
    values: StatusColumnValueMap<BudgetReportColumn>[]
    currentValue: number
}

export interface BudgetItem extends IBudgetItem {
    isAdjustment: false
}

export interface BudgetAdjustment extends IBudgetItem {
    isAdjustment: true
    clientApprovalNumber: string
    clientApprovalDocuments: DocumentLink[]
}

export enum PaymentClaimStatus {
    Entered = 'entered',
    Locked = 'locked',
    Generated = 'generated'
}

export interface PaymentClaimBrief {
    id: string
    createdBy: PrincipalBrief
    createdDate: Date
    updatedBy: PrincipalBrief
    updatedDate: Date
    commitment: CommitmentReference
    status: PaymentClaimStatus
    certificateNumber: string
    claimDate?: Date
    claimReference: string
    value: number
    invoiceDate?: Date
    invoiceReference: string
    currentClaimed: number
    currentCertified: number
    currentPaid: number
    notes: string
}

export interface PaymentClaim {
    id: string
    createdBy: PrincipalBrief
    createdDate: Date
    updatedBy: PrincipalBrief
    updatedDate: Date
    commitment: CommitmentReference
    status: PaymentClaimStatus
    certificateNumber: string
    invoiceDate?: Date
    invoiceReference: string
    claimDate?: Date
    claimReference: string
    value: number
    currentClaimed: number
    currentCertified: number
    currentPaid: number
    notes: string
    items: PaymentClaimItem[]
    documentLinks: {
        [key: string]: DocumentLink[]
    }
}

export interface PaymentClaimLink {
    id: string
    commitmentId: string
    certificateNumber: string
}

export interface PaymentClaimItem {
    commitmentId: string
    commitmentItem: CommitmentItemBrief
    value: number
    determinedValue: number
    claimed: number
    previouslyCertified: number
    certified: number
    paid: number
    gst: number
    period: Date
    reason: string
}

export interface CommitmentItem extends IpEntity {
    itemNo: number
    otherPartyReference: string
    costCode: CostCodeBrief
    phase: string
    status: string
    currentValue: number
    currentCertified: number
    currentPaid: number
    values: StatusColumnValueMap<CommitmentReportColumn>[]
}

export interface CommitmentItemWithParentRef extends CommitmentItem {
    parentCommitment: CommitmentReference
}

export interface CommitmentItemBrief extends IpEntity {
    itemNo: number
    name: string
    costCode: string
    phase: string
    status: string
    value: number
    otherPartyRef: string
}

export interface CommitmentBrief extends IpEntity {
    type: string
    commitmentNo: string
    name: string
    otherParty: PrincipalBrief
    otherPartyReference: string
    paymentClaimsCount: number
    commitmentItemsCount: number
    currentValue: number
    currentClaimed: number
    currentCertified: number
    currentPaid: number
}

export interface Commitment extends IpEntity {
    type: string
    commitmentNo: string
    notes: string
    date: Date
    otherParty: PrincipalBrief
    otherPartyReference: string
    variationAdditions?: number
    variationDeductions?: number
    commitmentItems: CommitmentItem[]
    paymentClaims: PaymentClaimBrief[]
    subCommitments?: CommitmentBrief[]
    documentLinks: {
        [key: string]: DocumentLink[]
    }
    parent?: CommitmentBrief
    currentValue: number
    currentClaimed: number
    currentCertified: number
    currentPaid: number
    insurances?: Insurance[]
    subCommitmentValue?: number
    subCommitmentClaimed?: number
    subCommitmentCertified?: number
    subCommitmentPaid?: number
}

export interface CommitmentReference {
    id: string
    type: string
    commitmentNo: string
    name: string
    otherParty: PrincipalBrief | null
}

export interface CommitmentLink {
    id: string
    type: string
    name: string
}

export interface Insurance {
    id: string
    otherParty: PrincipalBrief
    type: string
    policyNumber: string
    expiryDate: Date
    insurer: string
    annualOccurrenceValue: number
    annualAggregateValue: number
    description: string
}
