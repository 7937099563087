import React from 'react'
import { useSelector } from 'react-redux'
import { Card, Col, FormGroup, FormText, Input, Label, Row } from 'reactstrap'

import ChangePasswordForm from '@src/components/usersettings/ChangePasswordForm'
import { RootState } from '@src/types/models'

const AccountPage: React.FC = () => {

    const userEmail = useSelector<RootState, string>(s => s.session.user.email)

    return (
        <Card body>
            <h2 className="border-bottom pb-2 mb-3">Account Email</h2>
            <Row>
                <Col md={8}>
                    <FormGroup>
                        <Label>Email</Label>
                        <Input disabled readOnly value={userEmail} />
                        <FormText color="muted">You cannot currently change your email here. If you need to do so, please contact support at <a href="mailto:support@infopoint.com.au">support@infopoint.com.au</a>.</FormText>
                    </FormGroup>
                </Col>
            </Row>
            <h2 className="border-bottom pb-2 mb-3">Change Password</h2>
            <Row>
                <Col md={8}>
                    <ChangePasswordForm />
                </Col>
            </Row>
        </Card>
    )
}

export default React.memo(AccountPage)
