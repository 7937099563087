// tslint:disable:max-classes-per-file

import React from 'react'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { InviteUserForm as FormFields } from '@src/logic/forms/FormProperties'
import * as Forms from '@src/logic/forms/Forms'
import { required } from '@src/logic/forms/validation'
import { CompanyUserInvite } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'

const inviteUserSuccess = () => {
    NotificationService.info('Invited user')
}

const inviteUserFailed = () => {
    NotificationService.error('An error occured while inviting the user. Refresh and try again.')
}

const handleSubmit = (values): Promise<any> => {
    return CompanyUserInvite({
        email: values[FormFields.EMAIL],
        firstName: values[FormFields.FIRSTNAME],
        lastName: values[FormFields.LASTNAME],
        mobile: values[FormFields.MOBILE],
        phone: values[FormFields.PHONE],
        groupIds: values[FormFields.GROUPS]
    })
}


class InviteUserForm extends React.PureComponent<IModalPropsManager & InjectedFormProps<{}, IModalPropsManager>> {

    private handleSubmit = (submitHandler, props, valid, asyncValidate, fields) => {
        this.props.handleSubmit(submitHandler, props, valid, asyncValidate, fields)
        this.props.toggle()
    }

    public render() {
        const { submitting, pristine, invalid } = this.props

        return (
            <Modal wrapClassName="d-block" isOpen={this.props.open} toggle={this.props.toggle} >
                <ModalHeader>Invite User</ModalHeader>
                <ModalBody>
                    <Row>
                        <Col xs={12} className="mb-3">
                            <Label for="email">Email</Label>
                            <Field id="email" name={FormFields.EMAIL} component={ValidatedInput} validate={required} />
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label for="firstName">First Name</Label>
                            <Field id="firstName" name={FormFields.FIRSTNAME} component={ValidatedInput} />
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label for="lastName">Last Name</Label>
                            <Field id="lastName" name={FormFields.LASTNAME} component={ValidatedInput} />
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label for="mobile">Mobile</Label>
                            <Field id="mobile" name={FormFields.MOBILE} component={ValidatedInput} />
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label for="phone">Phone</Label>
                            <Field id="phone" name={FormFields.PHONE} component={ValidatedInput} />
                        </Col>
                        <Col xs={12} className="mb-3">
                            <Label for="role">Role</Label>
                            <Field id="role" name={FormFields.ROLE} component={ValidatedInput} />
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button disabled={submitting || pristine || invalid} color="primary" className="mr-2" onClick={this.props.handleSubmit}>Invite</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

export default reduxForm<{}, IModalPropsManager>({ form: Forms.InviteUser, onSubmit: handleSubmit, onSubmitSuccess: inviteUserSuccess, onSubmitFail: inviteUserFailed })(InviteUserForm)
