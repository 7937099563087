import React from 'react'
import { OnChange } from 'react-final-form-listeners'
import { FormText } from 'reactstrap'

interface IProps {
    name: string
    shouldWarn: (value, previous) => React.ReactElement
}

const FieldWarning: React.FC<IProps> = ({ name, shouldWarn }) => {
    const [warning, setWarning] = React.useState<React.ReactElement>()

    function handleChange(value, previous) {
        const result = shouldWarn(value, previous)
        setWarning(result)
    }

    return (
        <>
            <OnChange name={name}>
                {handleChange}
            </OnChange>
            {warning && <FormText color="warning">{warning}</FormText>}
        </>
    )
}

export default FieldWarning
