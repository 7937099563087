import React from 'react'
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, InputGroup, InputGroupAddon, InputGroupText, Row, UncontrolledButtonDropdown } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import FA from '@src/components/common/FontAwesomeIcon'
import { IGroupedOptions } from '@src/components/common/Select'
import ValidatedAsyncSelect from '@src/components/common/ValidatedAsyncSelect'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { budgetStatusLabel, budgetStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { groupBy } from '@src/logic/utils/Collection'
import { BudgetStatusDefinition, CostCode, CostsOverview, Phase } from '@src/types/costs'


interface IProps {
    className?: string
    projectId: string
    costsOverview: CostsOverview
    onAdvancedAdd: () => void
}

export interface IQuickAddBudgetItemFormData {
    name: string
    costCode: CostCode
    phase?: Phase
    status: BudgetStatusDefinition
    value: number
}

class QuickAddBudgetItem extends React.PureComponent<IProps & InjectedFormProps<IQuickAddBudgetItemFormData, IProps>> {

    private readonly firstFieldRef: React.RefObject<HTMLInputElement>

    constructor(props) {
        super(props)

        this.firstFieldRef = React.createRef()
    }

    private submitOnEnterPressed = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            e.preventDefault()
            this.handleSubmit(e)
        }
    }

    private handleSubmit = (e: React.SyntheticEvent) => {
        this.firstFieldRef.current.focus()
        this.props.handleSubmit(e)
    }

    private loadCostCodes = async (query: string): Promise<IGroupedOptions<CostCode>> => {
        const response = await CostCodesList(this.props.projectId, 1, 200)
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }

    public render() {
        return (
            <Row className={this.props.className}>
                <Col>
                    <Field name="name" component={ValidatedInput} placeholder="Name" validate={[required]} innerRef={this.firstFieldRef} bsSize="sm" />
                </Col>
                <Col>
                    <Field name="costCode" component={ValidatedAsyncSelect} placeholder="Cost Code" validate={required} defaultOptions={true} loadOptions={this.loadCostCodes} getOptionValue={costCodeValue} getOptionLabel={costCodeLabel} cacheOptions size="sm" />
                </Col>
                <Col>
                    <Field name="phase" component={ValidatedSelect} placeholder="Phase" options={this.props.costsOverview.phases} getOptionLabel={phaseLabel} getOptionValue={phaseValue} isClearable size="sm" isOptionDisabled={isPhaseLocked} />
                </Col>
                <Col>
                    <Field name="status" component={ValidatedSelect} placeholder="Status" options={!this.props.costsOverview.budget.locked ? this.props.costsOverview.budget.itemStatusDefinitions : this.props.costsOverview.budget.adjustmentStatusDefinitions} validate={[required]} getOptionLabel={budgetStatusLabel} getOptionValue={budgetStatusValue} isClearable size="sm" />
                </Col>
                <Col>
                    <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="dollar-sign" /></InputGroupText></InputGroupAddon>
                        <Field name="value" component={ValidatedCurrencyInput} validate={required} onKeyPress={this.submitOnEnterPressed} />
                    </InputGroup>
                </Col>
                <Col className="flex-grow-0">
                    <UncontrolledButtonDropdown size="sm">
                        <Button onClick={this.handleSubmit}><FA icon="plus" /> Add</Button>
                        <DropdownToggle caret />
                        <DropdownMenu>
                            <DropdownItem className="pointer" onClick={this.props.onAdvancedAdd}>Advanced Add</DropdownItem>
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </Col>
            </Row>
        )
    }
}

export default reduxForm<IQuickAddBudgetItemFormData, IProps>({ onSubmitSuccess: (result, dispatch, props) => props.reset() })(QuickAddBudgetItem)
