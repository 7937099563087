import { generatePath, IRouteParams } from '@src/logic/routing/RouteHelpers'

export enum ProjectAccessEntityType {
    None = '',
    Document = 'document',
    Revision = 'revision',
    Communication = 'communication',
    Transmittal = 'transmittal',
    InboundEmail = 'inbound_email',
    Register = 'register'
}

export const DASHBOARD = '/'
export const COMPANY = '/company'
export const COMPANY_GROUPS = COMPANY + '/groups'
export const COMPANY_USERS = COMPANY + '/users'
export const COMPANY_SETTINGS = `${COMPANY}/settings`
export const COMPANY_SETTINGS_ACCESS = `${COMPANY_SETTINGS}/access`
export const COMPANY_SETTINGS_BILLING = `${COMPANY_SETTINGS}/billing`
export const COMPANY_SETTINGS_GENERAL = COMPANY_SETTINGS
export const COMPANY_SETTINGS_INVOICES = `${COMPANY_SETTINGS}/invoices`
export const COMPANY_SETTINGS_MEMBERSHIP = `${COMPANY_SETTINGS}/membership`
export const COMPANY_PROJECTS = COMPANY + '/projects'
export const COMPANY_TEMPLATES = `${COMPANY}/templates`
export const COMPANY_TEMPLATE = `${COMPANY_TEMPLATES}/:templateId`
export interface ICompanyTemplateParams {
    templateId: string
}
export const companyTemplate = (templateId: string) => COMPANY_TEMPLATE.replace(':templateId', templateId)
export const COMPANY_TEMPLATE_ACCESS = `${COMPANY_TEMPLATE}/access`
export const companyTemplateAccess = (templateId: string) => COMPANY_TEMPLATE_ACCESS.replace(':templateId', templateId)
export const PROJECT = '/project/:id'
export interface IProjectParams extends IRouteParams {
    id: string
}
export const project = (id: string) => PROJECT.replace(':id', id)
export const PROJECT_CREATE = '/projects/create'
export const PROJECT_DOCUMENT = PROJECT + '/documents/:docId'
export const projectDocument = (projectId: string, docId: string, revId?: string) => PROJECT_DOCUMENT.replace(':id', projectId).replace(':docId', docId) + (revId ? `?rid=${revId}` : '')
export const PROJECT_DOCUMENT_ACCESS = PROJECT_DOCUMENT + '/access'
export const projectDocumentAccess = (projectId: string, docId: string, revId?: string) => PROJECT_DOCUMENT_ACCESS.replace(':id', projectId).replace(':docId', docId) + (revId ? `?rid=${revId}` : '')
export const PROJECT_DOCUMENT_DOWNLOAD = PROJECT_DOCUMENT + '/download'
export const PROJECT_DOCUMENT_EDIT = PROJECT_DOCUMENT + '/edit'
export const projectDocumentEdit = (projectId: string, docId: string, revId?: string) => PROJECT_DOCUMENT_EDIT.replace(':id', projectId).replace(':docId', docId) + (revId ? `?rid=${revId}` : '')
export const PROJECT_DOCUMENTS = PROJECT + '/documents'
export const projectDocuments = (projectId: string) => PROJECT_DOCUMENTS.replace(':id', projectId)
export const PROJECT_EMAILS = PROJECT + '/emails'
export interface IProjectEmailParams extends IProjectParams {
    emailId: string
}
export const projectEmails = (projectId: string) => PROJECT_EMAILS.replace(':id', projectId)
export const PROJECT_EMAIL = PROJECT_EMAILS + '/:emailId'
export const projectEmail = (projectId: string, emailId: string) => PROJECT_EMAIL.replace(':id', projectId).replace(':emailId', emailId)
export const PROJECT_EMAIL_ACCESS = PROJECT_EMAIL + '/access'
export const projectEmailAccess = (projectId: string, emailId: string) => PROJECT_EMAIL_ACCESS.replace(':id', projectId).replace(':emailId', emailId)
export const PROJECT_REGISTER = PROJECT + '/registers/:registerId'
export const projectRegister = (projectId: string, registerId: string) => PROJECT_REGISTER.replace(':id', projectId).replace(':registerId', registerId)
export const PROJECT_REGISTER_ACCESS = PROJECT_REGISTER + '/access'
export const projectRegisterAccess = (projectId: string, registerId: string) => PROJECT_REGISTER_ACCESS.replace(':id', projectId).replace(':registerId', registerId)
export const PROJECT_REGISTER_COLUMNS = PROJECT_REGISTER + '/columns'
export const projectRegisterColumns = (projectId: string, registerId: string) => PROJECT_REGISTER_COLUMNS.replace(':id', projectId).replace(':registerId', registerId)
export const PROJECT_REGISTER_SETTINGS = PROJECT_REGISTER + '/settings'
export const projectRegisterSettings = (projectId: string, registerId: string) => PROJECT_REGISTER_SETTINGS.replace(':id', projectId).replace(':registerId', registerId)
export const PROJECT_REGISTERS = PROJECT + '/registers'
export const projectRegisters = (projectId: string) => PROJECT_REGISTERS.replace(':id', projectId)
export const PROJECT_SETTINGS_GENERAL = PROJECT + '/settings'
export const projectSettingsGeneral = (projectId: string) => PROJECT_SETTINGS_GENERAL.replace(':id', projectId)
export const PROJECT_SETTINGS_COLLABORATOR = PROJECT_SETTINGS_GENERAL + '/collaborator'
export const projectSettingsCollaborator = (projectId: string) => PROJECT_SETTINGS_COLLABORATOR.replace(':id', projectId)
export const PROJECT_SETTINGS_ACCESS = PROJECT_SETTINGS_GENERAL + '/access'
export const projectSettingsAccess = (projectId: string) => PROJECT_SETTINGS_ACCESS.replace(':id', projectId)
export const PROJECT_SETTINGS_METADATA = PROJECT + '/settings/metadata'
export const projectSettingsMetadata = (projectId: string) => PROJECT_SETTINGS_METADATA.replace(':id', projectId)
export const PROJECT_SETTINGS_DEFAULT_ACCESS = PROJECT + '/settings/default_access/:accessEntityType'
export const projectSettingsDefaultAccess = (projectId: string, projectEntityType?: ProjectAccessEntityType) => PROJECT_SETTINGS_DEFAULT_ACCESS.replace(':id', projectId).replace(':accessEntityType', projectEntityType)
export const PROJECT_COMMUNICATION = PROJECT + '/communications/:communicationId'
export interface IProjectCommunicationParams extends IProjectParams {
    communicationId: string
}
export const projectCommunication = (projectId: string, communicationId: string) => PROJECT_COMMUNICATION.replace(':id', projectId).replace(':communicationId', communicationId)
export const PROJECT_COMMUNICATION_EDIT = PROJECT_COMMUNICATION + '/edit'
export const projectCommunicationEdit = (projectId: string, communicationId: string) => PROJECT_COMMUNICATION_EDIT.replace(':id', projectId).replace(':communicationId', communicationId)
export const PROJECT_COMMUNICATION_VIEW = PROJECT_COMMUNICATION + '/view'
export const projectCommunicationView = (projectId: string, communicationId: string) => PROJECT_COMMUNICATION_VIEW.replace(':id', projectId).replace(':communicationId', communicationId)
export const PROJECT_COMMUNICATION_ACCESS = PROJECT_COMMUNICATION + '/access'
export const projectCommunicationAccess = (projectId: string, communicationId: string) => PROJECT_COMMUNICATION_ACCESS.replace(':id', projectId).replace(':communicationId', communicationId)
export const PROJECT_COMMUNICATIONS = PROJECT + '/communications'
export const projectCommunications = (projectId: string) => PROJECT_COMMUNICATIONS.replace(':id', projectId)
export const PROJECT_TEMPLATE = PROJECT + '/templates/:templateId'
export const projectTemplate = (projectId: string, templateId: string) => PROJECT_TEMPLATE.replace(':id', projectId).replace(':templateId', templateId)
export const PROJECT_TEMPLATE_ACCESS = PROJECT_TEMPLATE + '/access'
export const projectTemplateAccess = (projectId: string, templateId: string) => PROJECT_TEMPLATE_ACCESS.replace(':id', projectId).replace(':templateId', templateId)
export const PROJECT_TEMPLATES = PROJECT + '/templates'
export const projectTemplates = (projectId: string) => PROJECT_TEMPLATES.replace(':id', projectId)
export const PROJECT_TRANSMITTAL = PROJECT + '/transmittals/:transmittalId'
export const projectTransmittal = (projectId: string, transmittalId: string) => PROJECT_TRANSMITTAL.replace(':id', projectId).replace(':transmittalId', transmittalId)
export const PROJECT_TRANSMITTAL_EDIT = PROJECT_TRANSMITTAL + '/edit'
export const projectTransmittalEdit = (projectId: string, transmittalId: string) => PROJECT_TRANSMITTAL_EDIT.replace(':id', projectId).replace(':transmittalId', transmittalId)
export const PROJECT_TRANSMITTAL_DOWNLOAD = PROJECT_TRANSMITTAL + '/download'
export const projectTransmittalDownload = (projectId: string, transmittalId: string) => PROJECT_TRANSMITTAL_DOWNLOAD.replace(':id', projectId).replace(':transmittalId', transmittalId)
export const PROJECT_TRANSMITTAL_VIEW = PROJECT_TRANSMITTAL + '/view'
export const projectTransmittalView = (projectId: string, transmittalId: string) => PROJECT_TRANSMITTAL_VIEW.replace(':id', projectId).replace(':transmittalId', transmittalId)
export const PROJECT_TRANSMITTAL_ACCESS = PROJECT + '/transmittals/:transmittalId/access'
export const projectTransmittalAccess = (projectId: string, transmittalId: string) => PROJECT_TRANSMITTAL_ACCESS.replace(':id', projectId).replace(':transmittalId', transmittalId)
export const PROJECT_TRANSMITTAL_NEW = PROJECT + '/transmittals/new'
export const projectTransmittalNew = (projectId: string) => PROJECT_TRANSMITTAL_NEW.replace(':id', projectId)
export const PROJECT_TRANSMITTALS = PROJECT + '/transmittals'
export const projectTransmittals = (projectId: string) => PROJECT_TRANSMITTALS.replace(':id', projectId)
export const PROJECT_USERS = PROJECT + '/users'
export const projectUsers = (projectId: string) => PROJECT_USERS.replace(':id', projectId)

export const PROJECT_COSTS = PROJECT + '/costs'
export const projectCosts = (projectId: string) => PROJECT_COSTS.replace(':id', projectId)
export const PROJECT_COSTS_BUDGET = PROJECT_COSTS + '/budget'
export const projectCostsBudget = (projectId: string) => PROJECT_COSTS_BUDGET.replace(':id', projectId)

export interface IProjectCostsCommitmentTypeParams extends IProjectParams {
    type: string
}
export const PROJECT_COSTS_COMMITMENT_TYPE = PROJECT_COSTS + '/commitments/:type'
export const projectCostsCommitmentType = (projectId: string, commitmentType: string) => PROJECT_COSTS_COMMITMENT_TYPE.replace(':id', projectId).replace(':type', commitmentType)

export interface IProjectCostsCommitmentTypeDetailParams extends IProjectCostsCommitmentTypeParams {
    commitmentId: string
}
export const PROJECT_COSTS_COMMITMENT_TYPE_DETAIL = PROJECT_COSTS + '/commitments/:type/:commitmentId'
export const projectCostsCommitmentTypeDetail = (projectId: string, commitmentType: string, commitmentId: string) =>
    generatePath<IProjectCostsCommitmentTypeDetailParams>(PROJECT_COSTS_COMMITMENT_TYPE_DETAIL, ({
        commitmentId,
        id: projectId,
        type: commitmentType
    }))
export const PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_INSURANCES = PROJECT_COSTS_COMMITMENT_TYPE_DETAIL + '/insurances'
export const projectCostsCommitmentTypeDetailInsurances = (projectId: string, commitmentType: string, commitmentId: string) =>
generatePath<IProjectCostsCommitmentTypeDetailParams>(PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_INSURANCES, ({
    commitmentId,
    id: projectId,
    type: commitmentType
}))
export const PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_SUBCOMMIT = PROJECT_COSTS_COMMITMENT_TYPE_DETAIL + '/subcommits'
export const projectCostsCommitmentTypeDetailSubcommit = (projectId: string, commitmentType: string, commitmentId: string) =>
generatePath<IProjectCostsCommitmentTypeDetailParams>(PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_SUBCOMMIT, ({
    commitmentId,
    id: projectId,
    type: commitmentType
}))
export const PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_PAYMENTS = PROJECT_COSTS_COMMITMENT_TYPE_DETAIL + '/payments'
export const projectCostsCommitmentTypeDetailPayments = (projectId: string, commitmentType: string, commitmentId: string) =>
generatePath<IProjectCostsCommitmentTypeDetailParams>(PROJECT_COSTS_COMMITMENT_TYPE_DETAIL_PAYMENTS, ({
    commitmentId,
    id: projectId,
    type: commitmentType
}))
export const PROJECT_COSTS_DASHBOARD = PROJECT_COSTS + '/dashboard'
export const projectCostsDashboard = (projectId: string) => PROJECT_COSTS_DASHBOARD.replace(':id', projectId)
export const PROJECT_COSTS_PAYMENTS = PROJECT_COSTS + '/payments'
export const projectCostsPayments = (projecId: string) => PROJECT_COSTS_PAYMENTS.replace(':id', projecId)
export interface IProjectCostsPaymentDetailParams extends IProjectCostsCommitmentTypeDetailParams {
    paymentClaimId: string
}
export const PROJECT_COSTS_PAYMENT_DETAIL = `${PROJECT_COSTS_COMMITMENT_TYPE_DETAIL}/payments/:paymentClaimId`
export const projectCostsPaymentDetail = (projectId: string, commitmentType: string, commitmentId: string, paymentClaimId: string) =>
    generatePath<IProjectCostsPaymentDetailParams>(PROJECT_COSTS_PAYMENT_DETAIL, ({
        commitmentId,
        paymentClaimId,
        id: projectId,
        type: commitmentType
    }))
export const PROJECT_COSTS_SETTINGS = PROJECT_COSTS + '/settings'
export const projectCostsSettings = (projectId: string) => PROJECT_COSTS_SETTINGS.replace(':id', projectId)
export const PROJECT_COSTS_SETTINGS_PHASES = PROJECT_COSTS_SETTINGS + '/phases'
export const projectCostsSettingsPhases = (projectId: string) => PROJECT_COSTS_SETTINGS_PHASES.replace(':id', projectId)
export const PROJECT_COSTS_SETTINGS_CODES = PROJECT_COSTS_SETTINGS + '/codes'
export const projectCostsSettingsCodes = (projectId: string) => PROJECT_COSTS_SETTINGS_CODES.replace(':id', projectId)
export const PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITIONS = PROJECT_COSTS_SETTINGS + '/commitment-definitions'
export const projectCostsSettingsCommitmentDefinitions = (projectId: string) => PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITIONS.replace(':id', projectId)
export const PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITION = PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITIONS + '/:key'
export const projectCostsSettingsCommitmentDefinition = (projectId: string, key: string) => PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITION.replace(':id', projectId).replace(':key', key)
export const PROJECT_COSTS_SETTINGS_BUDGET = PROJECT_COSTS_SETTINGS + '/budget'
export const projectCostsSettingsBudget = (projectId: string) => PROJECT_COSTS_SETTINGS_BUDGET.replace(':id', projectId)

export const SETTINGS = '/settings'
export const SETTINGS_PROFILE = SETTINGS + '/profile'
export const SETTINGS_ACCOUNT = SETTINGS + '/account'
