import React from 'react'
import { RouteComponentProps } from 'react-router'

import { CancelToken } from 'axios'
import moment from 'moment'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { EmailOperations } from '@src/logic/auth/operations'
import { EmailDownloadAttachmentsLink, EmailDownloadLink, EmailsList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Headers from '@src/logic/http/headers'
import * as Routes from '@src/logic/routing/routes'
import { EmailBrief } from '@src/types/email'
import { Project } from '@src/types/project'

interface IProps extends RouteComponentProps {
    project: Project
}

export default class EmailsSection extends React.PureComponent<IProps> {
    private readonly onSearch = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken) => {
        const response = await EmailsList(this.props.project.id, filter, sort, page, perPage, { cancelToken })
        return ({
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        })
    }

    private readonly downloadEmailAttachments = (email: EmailBrief) => {
        downloadURL(EmailDownloadAttachmentsLink(auth.getSessionToken(), email.id))
    }

    private readonly downloadEmail = (email: EmailBrief) => {
        downloadURL(EmailDownloadLink(auth.getSessionToken(), email.id))
    }

    public render() {
        const noItemsFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="file-times" /></div>
                <p className="lead">No emails found with the current search criteria...</p>
                <p>Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters.</p>
            </>
        )

        return (
            <SearchSection<EmailBrief, never>
                headers={[
                    {
                        name: 'Subject',
                        sortKey: 'subject',
                        sortable: true,
                        noSmallHeader: true,
                        overrideRenderer: email => (
                            <div className="selectable-content__title">
                                <Link to={Routes.projectEmail(this.props.project.id, email.id)}>{email.subject}</Link>
                            </div>
                        )
                    },
                    {
                        name: 'Sender',
                        sortable: true,
                        sortKey: 'from',
                        overrideRenderer: email => email.from.name
                    },
                    {
                        name: 'Sent',
                        sortable: true,
                        sortKey: 'received',
                        overrideRenderer: email => moment(email.received).format('L')
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        overrideRenderer: email => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLink id={`edit-${email.id}`} tooltip="View" to={Routes.projectEmail(this.props.project.id, email.id)} className="selectable-content__icon order-lg-1"><FA icon="eye" /></TooltipLink>
                                <TooltipLinkAction id={`download-${email.id}`} tooltip="Download Attachment" disabled={email.attachments.length === 0 || !isAuthorised(email.myAccess, EmailOperations.Download)} data={email} onClick={this.downloadEmailAttachments}><FA icon="download" /></TooltipLinkAction>
                                <TooltipLinkAction id={`export-${email.id}`} tooltip="Export as .eml" disabled={!isAuthorised(email.myAccess, EmailOperations.Download)} data={email} onClick={this.downloadEmail}><FA icon="file-export" /></TooltipLinkAction>
                            </div>
                        ),
                        headerWrapperClass: 'text-right'
                    }
                ]}
                onSearch={this.onSearch}
                noItemsFoundMessage={noItemsFoundMessage}
                searchAssistantProperties={[
                    {
                        name: 'Subject',
                        searchKey: 'subject',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Received',
                        searchKey: 'received',
                        type: PropertyType.Date
                    },
                    {
                        name: 'From',
                        searchKey: 'from',
                        type: PropertyType.Text
                    },
                    {
                        name: 'From Email',
                        searchKey: 'from_email',
                        type: PropertyType.Text
                    },
                    {
                        name: 'To',
                        searchKey: 'to',
                        type: PropertyType.Text
                    },
                    {
                        name: 'To Email',
                        searchKey: 'to_email',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Cc',
                        searchKey: 'cc',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Cc Email',
                        searchKey: 'cc_email',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Bcc',
                        searchKey: 'bcc',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Bcc Email',
                        searchKey: 'bcc_email',
                        type: PropertyType.Text
                    }
                ]}
            />
        )
    }
}
