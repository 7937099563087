// tslint:disable: max-classes-per-file

import React from 'react'

import { IconAvatar, UserAvatar } from '@src/components/common/Avatar'
import { UserBasic } from '@src/types/principal'

interface IProps {
    avatarImageLinks: string[]
    maxToShow?: number
}

export default class AvatarList extends React.PureComponent<IProps> {

    private renderAvatarLinks(links: string[]) {
        return links.map((link, idx) => (<UserAvatar className="card-list__avatar" tag="span" size="xs" key={idx} imageUrl={link} />))
    }

    public render() {
        const { maxToShow, avatarImageLinks } = this.props
        const avatars = maxToShow ? this.renderAvatarLinks(avatarImageLinks.slice(0, maxToShow - 1)).concat(maxToShow < avatarImageLinks.length ? <IconAvatar icon="plus" className="card-list__avatar" tag="span" size="xs" key="overflowCount">+ {avatarImageLinks.length - maxToShow}</IconAvatar> : []) : this.renderAvatarLinks(avatarImageLinks)

        return (
            <div className="card-list__avatars">
                {avatars}
            </div>
        )
    }
}

interface IUserAvatarListProps {
    users: UserBasic[]
    maxToShow?: number
}

export class UserAvatarList extends React.PureComponent<IUserAvatarListProps> {

    private renderUserAvatars(users: UserBasic[]) {
        return users.map((user, idx) => (<UserAvatar key={idx} className="card-list__avatar" tag="span" size="xs" imageUrl={user.profilePictureLink} firstName={user.firstName} lastName={user.lastName} />))
    }

    public render() {
        const { maxToShow, users } = this.props
        const avatars = maxToShow ? this.renderUserAvatars(users.slice(0, maxToShow - 1)).concat(maxToShow < users.length ? <IconAvatar icon="plus" className="card-list__avatar" tag="span" size="xs" key="overflowCount">+ {users.length - maxToShow}</IconAvatar> : []) : this.renderUserAvatars(users)

        return (
            <div className="card-list__avatars">
                {avatars}
            </div>
        )
    }
}
