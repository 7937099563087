import React from 'react'

import Clipboard from 'clipboard'

import FA from '@src/components/common/FontAwesomeIcon'
import LinkAction from '@src/components/common/LinkAction'
import Tooltip from 'reactstrap/lib/Tooltip'

interface IProps {
    id: string
    explicitCopy?: string
    options?: Clipboard.Options
    onSuccess?: (e: Clipboard.Event) => void
    onError?: (e: Clipboard.Event) => void
}

interface IState {
    tooltipOpen: boolean
}

export default class ClipboardButton<P = {}> extends React.PureComponent<IProps, IState> {

    private readonly clipboardRef: React.RefObject<HTMLAnchorElement>
    private clipboard: Clipboard

    constructor(props) {
        super(props)

        this.clipboardRef = React.createRef()

        this.state = {
            tooltipOpen: false
        }
    }

    public componentDidMount() {
        this.clipboard = new Clipboard(
            this.clipboardRef.current,
            {
                ...this.props.options,
                ...(this.props.explicitCopy ? { text: this.getExplicitText } : {})
            }
        )

        this.clipboard.on('success', this.handleSuccess)

        if (this.props.onError) {
            this.clipboard.on('error', this.props.onError)
        }
    }

    public componentWillUnmount() {
        if (this.clipboard) {
            this.clipboard.destroy()
        }
    }

    private handleSuccess = (e: Clipboard.Event) => {
        this.toggleOn()
        if (this.props.onSuccess) {
            this.props.onSuccess(e)
        }
    }

    private getExplicitText = () => {
        return this.props.explicitCopy
    }

    private toggleOn = () => {
        this.setState({ tooltipOpen: true }, () => window.setTimeout(this.toggleOff, 500))
    }

    private toggleOff = () => {
        this.setState({ tooltipOpen: false })
    }

    private doNothing() {
        //
    }

    public render() {
        const { id } = this.props
        const children = this.props.children || <FA icon="clipboard" />
        return (
            <LinkAction id={id} innerRef={this.clipboardRef} data={null} onClick={this.doNothing}>
                <Tooltip target={id} delay={{ show: 0, hide: 0 }} isOpen={this.state.tooltipOpen}>Copied</Tooltip>
                {children}
            </LinkAction>
        )
    }
}
