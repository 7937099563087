import React from 'react'
import { Card, CardBody, CardHeader, Container, UncontrolledTooltip } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import MetadataDefinitionList from '@src/components/metadata/definitions/MetadataDefinitionList'
import NewDefinitionModal, { INewDefinitionModalFormData } from '@src/components/metadata/definitions/NewDefinitionModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import { isAuthorised } from '@src/logic/auth/access'
import { RegisterOperations } from '@src/logic/auth/operations'
import { RegisterColumnAdd, RegisterColumnDelete, RegisterColumnUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService';
import { IMetadataDefinition, IUpdateMetadataDefinition, MetadataTypes } from '@src/types/metadata'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    registerOverview: RegisterOverview
    reloadOverview: () => void
}

export default class RegisterColumnsPage extends React.PureComponent<IProps> {

    // All Metadata types are valid for registers
    private static readonly validMetadataTypes: MetadataTypes[] = Object.keys(MetadataTypes).map(key => MetadataTypes[key])

    private addMetadataDefinition = async (values: INewDefinitionModalFormData): Promise<IMetadataDefinition> => {
        const createdDefinition = await RegisterColumnAdd(this.props.registerOverview.id, {
            name: values.name,
            key: values.key,
            type: values.type,
            ...(values.parentKey ? { parentKey: values.parentKey.value } : {})
        } as any)
        this.props.reloadOverview()

        return createdDefinition.data
    }

    private handleDeleteColumn = async (columnKey: string) => {
        try {
            await RegisterColumnDelete(this.props.registerOverview.id, columnKey)
            NotificationService.info('Deleted column')
        } catch {
            NotificationService.error('Unable to delete column')
        }
        this.props.reloadOverview()
    }

    private handleUpdateColumn = async (columnKey: string, update: IUpdateMetadataDefinition) => {
        try {
            await RegisterColumnUpdate(this.props.registerOverview.id, columnKey, update)
            NotificationService.info('Updated column')
        } catch {
            NotificationService.error('Unable to update column')
        }
        this.props.reloadOverview()
    }

    public render() {
        const authorisedCreateColumn = isAuthorised(this.props.registerOverview.myAccess, RegisterOperations.CreateMetaDefinition)

        return (
            <Container fluid className="mt-3 mb-5">
                <Card>
                    <CardHeader className="d-flex">
                        Metadata
                        {!authorisedCreateColumn && <UncontrolledTooltip target="register-create-column">No create column permission</UncontrolledTooltip>}
                        <span id="register-create-column" className="ml-auto">
                            <ModalToggle
                                modal={NewDefinitionModal}
                                modalProps={{ existingDefinitions: this.props.registerOverview.columnDefinitions, validTypes: RegisterColumnsPage.validMetadataTypes, onSubmit: this.addMetadataDefinition }}
                                wrapperProps={{ disabled: !authorisedCreateColumn, style: { pointerEvents: !authorisedCreateColumn ? 'none' : 'initial' } }}
                            >
                                <FA icon="plus-circle" /> Add Metadata
                            </ModalToggle>
                        </span>
                    </CardHeader>
                    {this.props.registerOverview.columnDefinitions.length ?
                        (<MetadataDefinitionList
                            definitions={this.props.registerOverview.columnDefinitions}
                            onDeleteDefinition={this.handleDeleteColumn}
                            onUpdateDefinition={this.handleUpdateColumn}
                        />) : <CardBody className="text-center lead">No existing metadata</CardBody>
                    }
                </Card>
            </Container>
        )
    }
}
