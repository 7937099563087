import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import BudgetSettingsSection from '@src/components/costs/settings/BudgetSettingsSection'
import CommitmentDefinitionSettingsSection from '@src/components/costs/settings/CommitmentDefinitionSettingsSection'
import CostCodeSettingsSection from '@src/components/costs/settings/CostCodeSettingsSection'
import CostsSettingsToolbar from '@src/components/costs/settings/CostsSettingsToolbar'
import EditCommitmentDefinitionSection from '@src/components/costs/settings/EditCommitmentDefinitionSection'
import GeneralSettingsSection from '@src/components/costs/settings/GeneralSettingsSection'
import PhaseSettingsSection from '@src/components/costs/settings/PhaseSettingsSection'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const CostsSettingsPage: React.FC<RouteComponentProps> = ({ location }) => {
    const project = useSelector<RootState, Project>(s => s.projects.active)

    return (
        <>
            <ProjectBanner overrideTitle="Cost Management Settings" project={project} />
            <CostsSettingsToolbar projectId={project.id} currentRoutePath={location.pathname} />
            <Switch>
                <Route exact path={Routes.PROJECT_COSTS_SETTINGS} component={GeneralSettingsSection} />
                <CrumbRoute title="Phases" path={Routes.PROJECT_COSTS_SETTINGS_PHASES} component={PhaseSettingsSection} />
                <CrumbRoute title="Cost Codes" path={Routes.PROJECT_COSTS_SETTINGS_CODES} component={CostCodeSettingsSection} />
                <CrumbRoute title="Commitment Types" path={Routes.PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITIONS}>
                    <Switch>
                        <CrumbRoute title="Edit Type" path={Routes.PROJECT_COSTS_SETTINGS_COMMITMENTDEFINITION} component={EditCommitmentDefinitionSection} />
                        <Route component={CommitmentDefinitionSettingsSection} />
                    </Switch>
                </CrumbRoute>
                <CrumbRoute title="Budget" path={Routes.PROJECT_COSTS_SETTINGS_BUDGET} component={BudgetSettingsSection} />
                <Redirect to={Routes.PROJECT_COSTS_SETTINGS} />
            </Switch>
        </>
    )
}

export default CostsSettingsPage
