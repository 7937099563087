import React from 'react'
import { FormFeedback } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import cx from 'classnames'

import DatePicker from '@src/components/common/DatePicker'

interface IProps {
    format?: string
    startDate?: Date
    endDate?: Date
    invalid?: boolean
    disabled?: boolean
    isClearable?: boolean
    placeholder?: string
}

export default class ValidatedDatePicker extends React.PureComponent<IProps & WrappedFieldProps> {

    public render() {
        const { input, meta: { touched, error, warning }, ...dateProps } = this.props

        const showError = touched && !!error

        return (
            <>
                <DatePicker {...dateProps} onChange={input.onChange} selected={input.value || undefined} onBlur={input.onBlur} invalid={showError} disabled={this.props.disabled} />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && error}</FormFeedback>
            </>
        )
    }
}
