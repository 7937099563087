import React from 'react'

import { auth } from '@src/logic/auth/AuthService'

export default class AuthCallback extends React.PureComponent {

    constructor(props) {
        super(props)
    }

    public componentDidMount() {
        auth.handleAuthentication()
    }

    public render() {
        return (
            <div />
        )
    }
}
