export const clamp = (value: number, min: number, max: number) => Math.min(Math.max(min, value), max)
export const randomInt = (min: number, max: number): number => Math.floor(Math.random() * (max - min + 1)) + min
export function enforceNumber(value) {
    if (value == null) return 0

    if (typeof value === 'number') return value

    if (typeof value === 'string') {
        const asNumber = Number(value)
        return asNumber || 0
    }

    return 0
}
