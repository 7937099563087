import React from 'react'

import { debounce } from 'lodash'

interface IProps {
    debounceResize: boolean
    debounceDelay?: number
}

interface IState {
    currentBreakpoint: Breakpoint
    screenWidth: number
}

export enum Breakpoint {
    xs = 'xs',
    sm = 'sm',
    md = 'md',
    lg = 'lg',
    xl = 'xl'
}

export const BreakpointValues = {
    [Breakpoint.xs]: 0,
    [Breakpoint.sm]: 576,
    [Breakpoint.md]: 768,
    [Breakpoint.lg]: 992,
    [Breakpoint.xl]: 1200
}

export interface IBreakpointContext {
    breakpoints: {}
    currentBreakpoint: Breakpoint
    screenWidth: number
}

export const BreakpointContext = React.createContext({
    currentBreakpoint: null
})

export default class Breakpoints extends React.PureComponent<IProps, IState> {
    public static defaultProps = {
        debounceDelay: 50
    }

    constructor(props) {
        super(props)

        this.state = {
            screenWidth: null,
            currentBreakpoint: null
        }
    }

    public componentDidMount() {
        if (typeof window !== 'undefined') {

            this.readWidth() // initial width calculation

            if (false) {
                window.addEventListener(
                    'resize',
                    debounce(this.readWidth, this.props.debounceDelay),
                )
            } else {
                window.addEventListener('resize', this.readWidth)
            }
            window.addEventListener('orientationchange', this.readWidth)
        }
    }

    public componentWillUnmount() {
        if (typeof window !== 'undefined') {
            if (this.props.debounceResize) {
                window.addEventListener(
                    'resize',
                    debounce(this.readWidth, this.props.debounceDelay),
                )
            } else {
                window.addEventListener('resize', this.readWidth)
            }
            window.removeEventListener('orientationchange', this.readWidth)
        }
    }

    private calculateCurrentBreakpoint = (screenWidth) => {
        let currentBreakpoint = null
        const breakpointKeys = Object.keys(BreakpointValues)
        const other = [...breakpointKeys]
            .reverse() // reverse array to put largest breakpoint first
            .map((breakpoint) => {
                const breakpointPixelValue = BreakpointValues[breakpoint]
                if (!currentBreakpoint && screenWidth >= breakpointPixelValue) {
                    currentBreakpoint = breakpoint
                }
            })
        // If currentBreakpoint is null here, screenWidth is below lowest breakpoint,
        // so it will still be set to equal lowest breakpoint instead of null
        if (currentBreakpoint === null) {
            currentBreakpoint = breakpointKeys[0]
        }

        return currentBreakpoint
    }

    private readWidth = (event?) => {
        const width = event
            ? event.target.innerWidth
                ? event.target.innerWidth
                : window.innerWidth
            : window.innerWidth
        const current = this.calculateCurrentBreakpoint(width)

        this.setState((state) => {
            if (state.currentBreakpoint === current) return null
            return {
                currentBreakpoint: current,
                screenWidth: null
            }
        })
    }

    private getContextValues = () => ({
        breakpoints: BreakpointValues,
        currentBreakpoint: this.state.currentBreakpoint,
        screenWidth: this.state.screenWidth
    })

    public render() {
        const { children } = this.props
        return (
            <BreakpointContext.Provider value={this.getContextValues()}>
                {children}
            </BreakpointContext.Provider>
        )
    }
}

export const withBreakpoints = <P extends {}>(Component: React.ComponentType<IBreakpointContext & P>) => (props: P) => (
    <BreakpointContext.Consumer>
        {(breakpoints: IBreakpointContext) => <Component {...breakpoints} {...props} />}
    </BreakpointContext.Consumer>
)
