import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Container } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { EmailAccessGet, EmailAccessUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Email } from '@src/types/email'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

interface IProps {
    email: Email
    reloadEmail: () => void
}

const EmailAccessPage: React.FC<IProps> = ({ email, reloadEmail }) => {

    const company = useSelector<RootState, Company>(s => s.session.company)

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    async function getEmailAcl() {
        const response = await EmailAccessGet(email.id)
        return response.data
    }

    async function saveEmailAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await EmailAccessUpdate(email.id, accessUpdate)
            reloadEmail()
            return true
        } catch {
            return false
        }
    }

    return (
        <Container fluid className="mt-3">
            <Card className="mb-3">
                <CardHeader><FA icon="lock" /> Email Access Control</CardHeader>
                <CardBody>
                    <AccessControlTable getAcl={getEmailAcl} commitUpdates={saveEmailAccessUpdates} loadPrincipals={loadCompanyPrincipals} disabled={!email.myAccess.isAdministrator} />
                </CardBody>
            </Card>
        </Container>
    )
}

export default EmailAccessPage
