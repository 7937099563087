import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Col, Container, Row } from 'reactstrap'

import Banner from '@src/components/banner/Banner'
import NewProjectForm, { INewProjectFormData } from '@src/components/project/new/NewProjectForm'
import * as Forms from '@src/logic/forms/Forms'
import { ProjectCreate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'

interface IProps {
    stuff?
}

export default class NewProjectPage extends React.PureComponent<RouteComponentProps & IProps> {

    private createProjectAndRedirect = async (values: INewProjectFormData) => {
        try {
            // Create the project
            const response = await ProjectCreate({
                name: values.name,
                code: values.code,
                description: values.description
            })

            // Go to new project page
            this.props.history.push(Routes.projectDocuments(response.data.id))
        } catch {
            NotificationService.error('There was an error creating the project')
        }
    }

    public render() {
        return (
            <>
                <Container>
                    <Row className="mt-5 mb-3 justify-content-center">
                        <Col lg={8} xs={12} className="justify-content-center">
                            <h1 className="mb-4">Create a new project</h1>
                            <NewProjectForm
                                form={Forms.NewProject}
                                onSubmit={this.createProjectAndRedirect}
                            />
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
