import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import { compare } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import BudgetStatusForm, { IBudgetStatusFormData } from '@src/components/costs/settings/BudgetStatusForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { BudgetPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { BudgetOverview, BudgetReportColumnName, BudgetStatusDefinition } from '@src/types/costs'

interface IProps extends IModalPropsManager {
    projectId: string
    statusToEdit?: BudgetStatusDefinition
    statusType: 'item' | 'adjustment'
    budget: BudgetOverview
}

interface IConnectedDispatch {
    submitForm: () => void
    reloadCostsOverview: () => void
}

class BudgetStatusModal extends React.Component<IProps & IConnectedDispatch> {

    public static defaultProps: Partial<IProps> = {
        statusType: 'item'
    }

    private handleSubmit = async (values: IBudgetStatusFormData, dispatch: Dispatch<any>) => {
        const { budget, statusToEdit, statusType } = this.props
        const patchRootPath = statusType === 'item' ? '/itemStatusDefinitions' : '/adjustmentStatusDefinitions'
        if (statusToEdit) {
            const index = statusType === 'item' ?
                budget.itemStatusDefinitions.findIndex(s => s.code === statusToEdit.code)
                : budget.adjustmentStatusDefinitions.findIndex(s => s.code === statusToEdit.code)
            if (index < 0) throw Error('Status to edit not found in budget')
            const original: BudgetStatusDefinition = {
                code: statusToEdit.code,
                columns: statusToEdit.columns
            }
            const update: BudgetStatusDefinition = {
                code: statusToEdit.code,
                columns: values.columns.map(c => c.value)
            }
            const patch = compare(original, update).map(op => ({ ...op, path: `${patchRootPath}/${index}${op.path}` }))
            await BudgetPatch(this.props.projectId, [
                { op: 'test', path: `${patchRootPath}/${index}/code`, value: statusToEdit.code },
                ...patch
            ])
        } else {
            const newStatusDefinition: BudgetStatusDefinition = {
                code: values.code,
                columns: values.columns.map(c => c.value)
            }
            await BudgetPatch(this.props.projectId, [
                { op: 'add', path: patchRootPath + '/-', value: newStatusDefinition }
            ])
        }
    }

    private onSubmitSuccess = (result, dispatch, props) => {
        if (this.props.statusToEdit) {
            NotificationService.info('Updated status')
        } else {
            NotificationService.info('Created status')
        }
        this.props.reloadCostsOverview()
        this.props.toggle()
    }

    public render() {
        const { open, statusToEdit, toggle } = this.props

        return (
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>{statusToEdit ? `Edit - ${statusToEdit.code}` : 'New Status'}</ModalHeader>
                <ModalBody>
                    <BudgetStatusForm
                        form={statusToEdit ? Forms.CostsEditBudgetStatus : Forms.CostsNewBudgetStatus}
                        destroyOnUnmount
                        editingExisting={statusToEdit != null}
                        initialValues={this.props.statusToEdit == null ? {
                            columns: []
                        } : {
                            code: statusToEdit.code,
                            columns: statusToEdit.columns.map(c => ({ label: BudgetReportColumnName[c], value: c })),
                        }}
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.onSubmitSuccess}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.submitForm}>{statusToEdit ? 'Save' : 'Create'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        submitForm: () => dispatch(submit(ownProps.statusToEdit ? Forms.CostsEditBudgetStatus : Forms.CostsNewBudgetStatus)),
        reloadCostsOverview: () => dispatch(loadProjectCostsOverview())
    }
}

export default connect(null, mapDispatchToProps)(BudgetStatusModal)
