import React from 'react'
import { useHistory } from 'react-router'
import { Button, Card, Col, Container, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import { auth } from '@src/logic/auth/AuthService'
import { TransmittalAttachmentsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Routes from '@src/logic/routing/routes'
import { Transmittal } from '@src/types/transmittal'

interface IProps {
    transmittal: Transmittal
}

const TransmittalDownloadPage: React.FC<IProps> = ({ transmittal }) => {

    const history = useHistory()
    if (transmittal.revisions == null || transmittal.revisions.length === 0) {
        history.replace(Routes.projectTransmittalView(transmittal.projectId, transmittal.id))
        return null
    }

    React.useEffect(() => downloadTransmittal(), [])

    function downloadTransmittal() {
        downloadURL(TransmittalAttachmentsDownloadLink(auth.getSessionToken(), transmittal.id))
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <Card body className="text-center">
                        <div className="mb-3">
                            <FA size="4x" icon="cloud-download" />
                        </div>
                        <h1>Downloading your document{transmittal.revisions.length > 1 ? 's' : ''} now.</h1>
                        <div className="mb-3">
                            <small>If your download doesn't start click the button below</small>
                        </div>
                        <div>
                            <Button color="primary" onClick={downloadTransmittal}>Download</Button>
                            <span> or <Link to={Routes.projectTransmittalView(transmittal.projectId, transmittal.id)}>back to transmittal</Link></span>
                        </div>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default React.memo(TransmittalDownloadPage)
