import React from 'react'
import { Route, Switch } from 'react-router'

import CrumbRoute from '@src/components/navigation/CrumbRoute'
import * as Routes from '@src/logic/routing/routes'
import TemplatePages from '@src/pages/communication/TemplatePages'
import CompanyDashboard from '@src/pages/company/CompanyDashboard'
import CompanySettingsPage from '@src/pages/company/CompanySettingsPage'

export default class CompanyPages extends React.PureComponent {

    public render() {
        return (
            <Switch>
                <CrumbRoute title="Settings" path={Routes.COMPANY_SETTINGS_GENERAL} component={CompanySettingsPage} />
                <CrumbRoute title="Communication Templates" path={Routes.COMPANY_TEMPLATE} component={TemplatePages} linkPath={Routes.COMPANY_TEMPLATES} />
                <Route component={CompanyDashboard} />
            </Switch>
        )
    }
}
