import React from 'react'
import { connect } from 'react-redux'
import { Alert, Card, CardBody, CardHeader, Col, ListGroup, ListGroupItem, Row, Table } from 'reactstrap'

import axios, { CancelTokenSource } from 'axios'
import moment from 'moment'
import numeral from 'numeral'

import ClipboardButton from '@src/components/common/ClipboardButton'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import Tags from '@src/components/common/Tags'
import TrackingList from '@src/components/common/TrackingList'
import DocumentPreview from '@src/components/document/DocumentPreview'
import RevisionApprovalModal from '@src/components/document/RevisionApprovalModal'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import ModalToggle from '@src/components/modal/ModalToggle'
import TransmittalStatusIndicator from '@src/components/transmittal/TransmittalStatusIndicator'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { ProjectOperations } from '@src/logic/auth/operations'
import { RevisionsDownloadLink, TransmittalsList } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { capitalize } from '@src/logic/utils/Strings'
import { mutedNotSet, mutedValue, valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'
import { TransmittalBrief } from '@src/types/transmittal'

interface IProps {
    revision: Revision
    metadataDefinitions: IMetadataDefinition[]
    reloadDocument: () => void
}

interface IConnectedState {
    project: Project
    user: Session.User
}

interface IState {
    linkedTransmittals: TransmittalBrief[]
}

class DocumentPreviewPage extends React.PureComponent<IProps & IConnectedState, IState> {

    private getTransmittalsCancelTokenSource: CancelTokenSource

    constructor(props) {
        super(props)

        this.state = {
            linkedTransmittals: null
        }
    }

    public componentDidMount() {
        this.loadLinkedTransmittals()
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.revision.id !== this.props.revision.id) {
            this.props.reloadDocument()
        }
    }

    private loadLinkedTransmittals = async () => {
        if (this.getTransmittalsCancelTokenSource) {
            this.getTransmittalsCancelTokenSource.cancel()
        }

        this.getTransmittalsCancelTokenSource = axios.CancelToken.source()
        try {
            const response = await TransmittalsList(this.props.revision.projectId, `revision_ids: "${this.props.revision.id}"`, undefined, 1, 200)
            this.setState({ linkedTransmittals: response.data })
        } catch (error) {
            //
        } finally {
            this.getTransmittalsCancelTokenSource = null
        }
    }

    private renderMetadata = () => {
        return this.props.metadataDefinitions ?
            this.props.metadataDefinitions.map(md => (
                    <ListGroupItem key={md.key} className="py-1">
                        {md.name}: {renderMetadata(this.props.revision.metadata[md.key], md, this.props.revision.projectId)}
                    </ListGroupItem>
                )
            ) : <ListGroupItem className="text-center">No metadata</ListGroupItem>
    }

    public render() {
        const { revision, project, user } = this.props
        const { linkedTransmittals } = this.state

        const transmittals = linkedTransmittals != null && linkedTransmittals.length > 0 ? (
                <Table>
                    <thead>
                        <tr>
                            <th>Subject</th>
                            <th>Status</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        {linkedTransmittals.map((t, idx) => (
                            <tr key={idx}>
                                <td>
                                    <div>
                                    <TransmittalStatusIndicator transmittal={t} />
                                    <Link to={Routes.projectTransmittal(t.projectId, t.id)}>{t.subject}</Link>
                                    </div>
                                </td>
                                <td>{capitalize(t.status)}</td>
                                <td>{moment(t.updatedDate).format('lll')}</td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
        ) : <ListGroup className="small" flush><ListGroupItem className="text-center">Revision has not been sent in any transmittals</ListGroupItem></ListGroup>

        const linkToRevision = window.location.origin + Routes.projectDocument(revision.projectId, revision.documentId, revision.id)
        const linkToLatestRevision = window.location.origin + Routes.projectDocument(revision.projectId, revision.documentId)

        return (
            <>
                {revision.approval.status === 'pending' && <Row>
                    <Col>
                        <Alert color="info" className="mb-3">
                            <h4><FA icon="exclamation-circle" /> Revision Requires Approval</h4>
                            <p>This revision has been uploaded but requires approval before being committed. Only users with document approval permissions on the project will be able to see this revision until it has been formally approved.</p>
                            {isAuthorised(project.myAccess, ProjectOperations.ApproveDocument) && <ModalToggle modal={RevisionApprovalModal} modalProps={{ revision, onApproveOrReject: this.props.reloadDocument }}>Review</ModalToggle>}
                        </Alert>
                    </Col>
                </Row>}
                {revision.archived && <Row>
                    <Col>
                        <Alert color="warning" className="mb-3">
                            <h4><FA icon="archive" /> Archived Revision</h4>
                            <p>This revision has been archived and its access may only be intended for historical purposes.</p>
                        </Alert>
                    </Col>
                </Row>}
                <Row>
                    <Col lg={8} className="mb-3">
                        <Card>
                            <CardHeader><FA icon="eye" /> Preview</CardHeader>
                            <CardBody className="p-0">
                                <DocumentPreview revisionId={revision.id} />
                            </CardBody>
                        </Card>
                    </Col>
                    <Col lg={4}>
                        <Card className="mb-3">
                            <CardHeader><FA icon="info-circle" /> Basic Info</CardHeader>
                            <ListGroup className="small" flush>
                                <ListGroupItem className="py-1"><strong>Name: </strong>{revision.name}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Revision #: </strong>{valueOrMutedNotSet(revision.revNumber)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Revision Date: </strong>{revision.revDate ? moment(revision.revDate).format('lll') : mutedNotSet}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Description: </strong>{valueOrMutedNotSet(revision.description)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Uploaded: </strong>{moment(revision.uploadDate).format('lll')}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Uploaded By: </strong>{revision.uploadedBy.name}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Author: </strong>{valueOrMutedNotSet(revision.author)}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>Tags: </strong>{revision.tags.length ? <Tags tags={revision.tags} /> : mutedValue('(none)')}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>File name: </strong>{revision.fileName}</ListGroupItem>
                                <ListGroupItem className="py-1"><strong>File size: </strong>{numeral(revision.fileSize).format('0.0 b')}</ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Link to this revision: </strong><ClipboardButton id="copy-revision-link" explicitCopy={linkToRevision} /><Link to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)}>{linkToRevision}</Link></ListGroupItem>
                                <ListGroupItem className="py-1 text-truncate"><strong>Link to latest revision: </strong><ClipboardButton id="copy-latest-revision-link" explicitCopy={linkToLatestRevision} /><Link to={Routes.projectDocument(revision.projectId, revision.documentId)}>{linkToLatestRevision}</Link></ListGroupItem>
                            </ListGroup>
                        </Card>
                        <Card className="mb-3">
                            <CardHeader><FA icon="list" /> Metadata</CardHeader>
                            <ListGroup className="small" flush>
                                {this.renderMetadata()}
                            </ListGroup>
                        </Card>
                        <Card className="mb-3">
                            <CardHeader>
                                <FA icon="envelope" />&nbsp;Transmittals&nbsp;
                                {linkedTransmittals != null && <span className="text-muted">({linkedTransmittals.length})</span>}
                            </CardHeader>
                            {transmittals}
                        </Card>
                        <TrackingList header="Revision Activity" trackingEntries={revision.tracking} />
                    </Col>
                </Row>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        user: state.session.user
    }
}

export default connect(mapStateToProps)(DocumentPreviewPage)
