import React from 'react'

import Croppr from 'croppr'

import { getImagePortion } from '@src/logic/utils/Canvas'

import 'croppr/dist/croppr.css'

interface IProps extends Croppr.CropprOptions, React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement> {
    onCrop: (dataUrl: string) => void
}

export default class Cropper extends React.PureComponent<IProps> {

    private readonly imgRef: React.RefObject<HTMLImageElement>
    private croppr?: Croppr

    constructor(props) {
        super(props)

        this.imgRef = React.createRef<HTMLImageElement>()
    }

    public componentDidMount() {
        const {
            aspectRatio,
            maxSize,
            minSize,
            startSize,
            onCropStart,
            onCropMove,
            onInitialize,
            returnMode
        } = this.props
        this.croppr = new Croppr(this.imgRef.current, {
            aspectRatio,
            maxSize,
            minSize,
            startSize,
            onCropStart,
            onCropMove,
            returnMode,
            onInitialize: (instance: Croppr) => this.onCrop(instance.getValue()),
            onCropEnd: this.onCrop
        })
    }

    public componentWillUnmount() {
        if (this.croppr) {
            this.croppr.destroy()
        }
    }

    private onCrop = (data: Croppr.CropValue) => {
        const { x, y, width, height } = data
        this.props.onCrop(getImagePortion(this.imgRef.current, width, height, x, y, 1))
    }

    public render() {
        const {
            aspectRatio,
            maxSize,
            minSize,
            startSize,
            onCrop,
            onCropStart,
            onCropMove,
            onCropEnd,
            onInitialize,
            returnMode,
            ...imgProps
        } = this.props

        return (
            <img {...imgProps} ref={this.imgRef} />
        )
    }
}
