import React from 'react'
import { BreadcrumbItem } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    first?: boolean
    active?: boolean
}

export default class Crumb extends React.PureComponent<IProps> {

    public render() {
        const crumb = <BreadcrumbItem>{this.props.children}</BreadcrumbItem>

        if (this.props.first) {
            return crumb
        }

        return (
            <>
                <div className="px-2 d-inline-block text-muted" style={{ fontSize: '80%' }}>
                    <FA icon="chevron-right" />
                </div>
                {crumb}
            </>
        )
    }
}
