import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import { Operation } from 'fast-json-patch'

import PaymentClaimForm, { IPaymentClaimFormData } from '@src/components/costs/payments/PaymentClaimForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { CommitmentsList, PaymentClaimCreate, PaymentClaimPatch } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Commitment, CommitmentBrief, CommitmentReference, CostsOverview, PaymentClaim, PaymentClaimBrief } from '@src/types/costs'

interface IProps extends IModalPropsManager {
    projectId: string
    costsOverview: CostsOverview
    commitment?: Commitment | CommitmentBrief | CommitmentReference
    paymentClaimToEdit?: PaymentClaimBrief
    onItemCreatedOrUpdated?: (paymentClaim: PaymentClaim, isNew: boolean) => void
}

interface IConnectedDispatch {
    submitForm: () => void
}

class PaymentClaimModal extends React.PureComponent<IProps & IConnectedDispatch> {

    private handleSubmit = async (values: IPaymentClaimFormData, dispatch: Dispatch<any>): Promise<PaymentClaim | null> => {
        const { paymentClaimToEdit } = this.props

        if (paymentClaimToEdit) {
            const update: Operation[] = [
                { op: 'add', path: '/claimDate', value: values.claimDate },
                { op: 'add', path: '/claimReference', value: values.claimReference },
                { op: 'add', path: '/value', value: values.value },
                { op: 'add', path: '/invoiceDate', value: values.invoiceDate },
                { op: 'add', path: '/invoiceReference', value: values.invoiceReference },
                { op: 'add', path: '/notes', value: values.notes }
            ]

            await PaymentClaimPatch(this.props.projectId, paymentClaimToEdit.commitment.id, paymentClaimToEdit.id, update)
            return null
        }

        const newPaymentClaim: Api.Request.PaymentClaimNew = {
            claimDate: values.claimDate,
            claimReference: values.claimReference,
            value: values.value,
            invoiceDate: values.invoiceDate,
            invoiceReference: values.invoiceReference,
            notes: values.notes
        }
        return (await PaymentClaimCreate(this.props.projectId, this.props.commitment ? this.props.commitment.id : values.commitment.id, newPaymentClaim)).data
    }

    private onSubmitSuccess = (result: PaymentClaim | null, dispatch, props) => {
        this.props.toggle()
        this.props.onItemCreatedOrUpdated(result, result != null)
    }

    private loadCommitments = async (query: string): Promise<Commitment[]> => {
        return (await CommitmentsList(this.props.projectId, `parent_id: null AND ${query.split(' ').map(x => `name: "${x.replace('"', '\"')}"`).join(' ')}`)).data.commitments
    }

    public render() {
        const { paymentClaimToEdit, commitment } = this.props
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} onClosed={this.props.onClosed}>
                <ModalHeader toggle={this.props.toggle}>{paymentClaimToEdit ? `Edit - ${paymentClaimToEdit.certificateNumber}` : 'New Payment Claim'}</ModalHeader>
                <ModalBody>
                    <PaymentClaimForm
                        form={paymentClaimToEdit != null ? Forms.CostsEditPaymentClaim : Forms.CostsNewPaymentClaim}
                        destroyOnUnmount
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.onSubmitSuccess}
                        costsOverview={this.props.costsOverview}
                        loadCommitments={(this.props.commitment == null && this.props.paymentClaimToEdit == null) ? this.loadCommitments : null}
                        {...(paymentClaimToEdit != null ? {
                            initialValues: {
                                commitment: paymentClaimToEdit.commitment,
                                claimDate: paymentClaimToEdit.claimDate,
                                claimReference: paymentClaimToEdit.claimReference,
                                invoiceDate: paymentClaimToEdit.invoiceDate,
                                invoiceReference: paymentClaimToEdit.invoiceReference,
                                notes: paymentClaimToEdit.notes,
                                value: paymentClaimToEdit.value
                            }
                        } : commitment ? {
                            initialValues: { commitment }
                        } : {})}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.submitForm}>{paymentClaimToEdit != null ? 'Save' : 'Create'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        submitForm: () => dispatch(submit(ownProps.paymentClaimToEdit ? Forms.CostsEditPaymentClaim : Forms.CostsNewPaymentClaim)),
    }
}

export default connect(null, mapDispatchToProps)(PaymentClaimModal)

