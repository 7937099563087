import React from 'react'
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import LinkAction from '@src/components/common/LinkAction'
import DocumentLinksModal from '@src/components/document/DocumentLinksModal'
import useModal from '@src/hooks/useModal'
import { PaymentClaimPatch } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { valueOrMutedNotSet } from '@src/logic/utils/ValueHelper'
import { CostsOverview, PaymentClaim } from '@src/types/costs'
import { Revision } from '@src/types/document'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
    payment: PaymentClaim
    reloadPayment: (payment?: PaymentClaim) => void
}

const PaymentInfoCard: React.FC<IProps> = ({ costsOverview, payment, projectId, reloadPayment }) => {
    const commitmentDefinition = costsOverview.commitmentDefinitions.find(x => x.code === payment.commitment.type)

    const [linksCategory, setLinksCategory] = React.useState<string>()
    const documentLinksModal = useModal(false, { onClose: () => setLinksCategory(undefined) })

    function showDocumentLinks(type: string) {
        setLinksCategory(type)
        documentLinksModal.show()
    }

    async function handleAddDocumentLink(revision: Revision) {
        try {
            await PaymentClaimPatch(projectId, payment.commitment.id, payment.id, [
                { op: 'add', path: `/documentLinks/${linksCategory}/-`, value: revision.id }
            ])
        } catch (e) {
            //
        }

        payment.documentLinks[linksCategory].push({ documentId: revision.documentId, name: revision.name, revisionId: revision.id })
        reloadPayment(payment)
    }

    async function handleRemoveDocumentLink(revision: Revision) {
        const revisionIndex = payment.documentLinks[linksCategory].findIndex(x => x.revisionId === revision.id)
        try {
            await PaymentClaimPatch(projectId, payment.commitment.id, payment.id, [
                { op: 'test', path: `/documentLinks/${linksCategory}/${revisionIndex}`, value: revision.id },
                { op: 'remove', path: `/documentLinks/${linksCategory}/${revisionIndex}` }
            ])
        } catch (e) {
            //
        }

        payment.documentLinks[linksCategory].splice(revisionIndex, 1)
        reloadPayment(payment)
    }

    return (
        <Card>
            <CardHeader><FA icon="info-circle" className="mr-2" />Payment Claim Info</CardHeader>
            <ListGroup className="small mb-2" flush>
                <ListGroupItem className="py-1">
                    <strong>Certificate Number: </strong>
                    {payment.certificateNumber}
                </ListGroupItem>
                <ListGroupItem className="py-1">
                    <strong>{commitmentDefinition.name}: </strong>
                    <Link to={Routes.projectCostsCommitmentTypeDetail(projectId, payment.commitment.type, payment.commitment.id)}>{payment.commitment.name}</Link>
                </ListGroupItem>
                <ListGroupItem className="py-1">
                    <strong>Invoice Date: </strong>
                    {valueOrMutedNotSet(payment.invoiceDate)}
                </ListGroupItem>
                <ListGroupItem className="py-1">
                    <strong>Invoice Reference: </strong>
                    {valueOrMutedNotSet(payment.invoiceReference)}
                </ListGroupItem>
                <ListGroupItem className="py-1">
                    <strong>Claim Date: </strong>
                    {valueOrMutedNotSet(payment.claimDate)}
                </ListGroupItem>
                <ListGroupItem className="py-1">
                    <strong>Claim Reference: </strong>
                    {valueOrMutedNotSet(payment.claimReference)}
                </ListGroupItem>
                {Object.entries(payment.documentLinks).map(([title, links]) =>
                    <ListGroupItem key={title} className="py-1">
                        <strong>{title}: </strong>
                        <LinkAction data={title} onClick={showDocumentLinks}>{links.length} {links.length === 1 ? 'document' : 'documents'}</LinkAction>
                    </ListGroupItem>
                )}
            </ListGroup>
            <DocumentLinksModal
                {...documentLinksModal}
                projectId={projectId}
                header={linksCategory}
                links={payment.documentLinks[linksCategory]}
                onLinkAdd={handleAddDocumentLink}
                onLinkRemove={handleRemoveDocumentLink}
            />
        </Card>
    )
}

export default PaymentInfoCard
