import React, { Suspense } from 'react'
import { WrappedFieldProps } from 'redux-form'

const FroalaEditor = React.lazy(() => import('@src/components/common/FroalaEditor'))

interface IProps {
    config: object
}

export default class FroalaEditorInput extends React.PureComponent<IProps & WrappedFieldProps> {
    public render() {
        const { config, input } = this.props

        return (
            <Suspense fallback={<div/>}>
                <FroalaEditor
                    tag="textarea"
                    config={config}
                    model={input.value}
                    onModelChange={input.onChange}
                />
            </Suspense>
        )
    }
}
