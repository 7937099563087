import { Dispatch } from 'redux'
import { Action, createAction } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { Features } from '@src/logic/features/features'
import { CompanyMeGet, FeaturesGet, UserProfileGet } from '@src/logic/http/Api'
import * as Keys from '@src/logic/storage/keys'
import { Company } from '@src/types/principal'
import { Session } from '@src/types/session'

const setAccount = createAction<Session.AuthAccount>(Actions.SESSION_SET_ACCOUNT)
const setUserProfile = createAction<Session.User>(Actions.SESSION_SET_PROFILE)
const setCompanyProfile = createAction<Company>(Actions.SESSION_SET_COMPANY)
const setUserFeatures = createAction<Features>(Actions.SESSION_SET_FEATURES)
const setSessionError = createAction<any>(Actions.SESSION_RAISE_ERROR)

export const reloadUserProfile = () => {
    return async (dispatch: Dispatch) => {
        const response = await UserProfileGet()
        localStorage.setItem(Keys.UserProfile, JSON.stringify(response.data))
        dispatch(setUserProfile(response.data))
    }
}

export const reloadCompany = () => {
    return async (dispatch: Dispatch) => {
        const response = await CompanyMeGet()
        localStorage.setItem(Keys.UserCompany, JSON.stringify(response.data))
        dispatch(setCompanyProfile(response.data))
    }
}

export const login = (account: Session.AuthAccount) => {
    return async (dispatch: Dispatch) => {
        dispatch(setAccount(account))

        let user = JSON.parse(localStorage.getItem(Keys.UserProfile)) as Session.User
        let company = JSON.parse(localStorage.getItem(Keys.UserCompany)) as Company
        let features = JSON.parse(localStorage.getItem(Keys.UserFeatures)) as Features

        if (!user || !company || !features) {
            const userPromise = UserProfileGet()
            const companyPromise = CompanyMeGet()
            const featuresPromise = FeaturesGet()

            try {
                const responses = await Promise.all([userPromise, companyPromise, featuresPromise])
                user = responses[0].data
                company = responses[1].data
                features = responses[2].data
            } catch (err) {
                dispatch(setSessionError(err))
                return
            }

            localStorage.setItem(Keys.UserProfile, JSON.stringify(user))
            localStorage.setItem(Keys.UserCompany, JSON.stringify(company))
            localStorage.setItem(Keys.UserFeatures, JSON.stringify(features))
        }

        dispatch(setUserProfile(user))
        dispatch(setUserFeatures(features))
        dispatch(setCompanyProfile(company))
    }
}

export const clear = (): Action<{}> => {
    localStorage.removeItem(Keys.UserProfile)
    localStorage.removeItem(Keys.UserCompany)
    localStorage.removeItem(Keys.UserFeatures)
    return { type: Actions.SESSION_CLEAR, payload: undefined }
}
