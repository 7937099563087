// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { Route, RouteComponentProps, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import RegisterToolbar from '@src/components/register/RegisterToolbar'
import { RegisterOperations } from '@src/logic/auth/operations'
import { RegisterOverviewGetById } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import * as Routes from '@src/logic/routing/routes'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import RegisterAccessPage from '@src/pages/register/RegisterAccessPage'
import RegisterColumnsPage from '@src/pages/register/RegisterColumnsPage'
import RegisterPage from '@src/pages/register/RegisterPage'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { RegisterOverview } from '@src/types/register'

interface IConnectedState {
    project: Project
}

interface IState {
    registerId: string
    registerOverview: RegisterOverview
    registerLoadError: ClientErrorCode
}

class RegisterPages extends React.PureComponent<RouteComponentProps<any> & IConnectedState, IState> {

    constructor(props) {
        super(props)

        this.state = {
            registerId: this.props.match.params.registerId,
            registerOverview: null,
            registerLoadError: null
        }
    }

    public componentDidMount() {
        this.loadRegister()
    }

    public loadRegister = async () => {
        try {
            const response = await RegisterOverviewGetById(this.state.registerId)
            this.setState({ registerOverview: response.data, registerLoadError: null })
        } catch (err) {
            if (isAxiosError(err)) {
                if (!err.response) {
                    throw err
                }

                switch (err.response.status) {
                    case 403:
                        this.setState({ registerLoadError: ClientErrorCode.Forbidden })
                    case 404:
                        this.setState({ registerLoadError: ClientErrorCode.NotFound })
                    default:
                        throw err
                }
            }
        }
    }

    private getBannerTitle = () => {
        if (this.state.registerLoadError) {
            switch (this.state.registerLoadError) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Register Not Found'
            }
        }

        return this.state.registerOverview ? this.state.registerOverview.name : <>Loading register <FA icon="spinner-third" spin /></>
    }

    public render() {
        const { project, match, location } = this.props
        const { registerOverview } = this.state

        return (
            <CrumbRoute path="" linkPath={registerOverview ? Routes.projectRegister(project.id, registerOverview.id) : match.url} title={registerOverview?.name ?? ''}>
                <>
                    <ProjectBanner project={project} overrideTitle={this.getBannerTitle()} />
                    <ClientErrorPage clientErrorCode={this.state.registerLoadError} resourceName="register" requiredOperation={RegisterOperations.Read}>
                        {registerOverview &&
                            <>
                                <RegisterToolbar currentRoutePath={location.pathname} register={registerOverview} />
                                <Switch>
                                    <CrumbRoute path={Routes.PROJECT_REGISTER_ACCESS} title="Access" render={() => <RegisterAccessPage register={registerOverview} reloadOverview={this.loadRegister} />}/>
                                    <CrumbRoute path={Routes.PROJECT_REGISTER_COLUMNS} title="Edit" render={() => <RegisterColumnsPage registerOverview={registerOverview} reloadOverview={this.loadRegister} />} />
                                    <Route render={routeProps => <RegisterPage {...routeProps} registerOverview={registerOverview} />} />
                                </Switch>
                            </>
                        }
                    </ClientErrorPage>
                </>
            </CrumbRoute>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<any>): RouteComponentProps<any> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active
    }
}

export default connect(mapStateToProps)(RegisterPages)
