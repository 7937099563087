import { combineReducers } from 'redux'
import { reducer as form } from 'redux-form'

import breadcrumb from '@src/reducers/breadcrumb'
import projects from '@src/reducers/project'
import sandbox from '@src/reducers/sandbox'
import session from '@src/reducers/session'
import widget from '@src/reducers/widget'
import { RootState } from '@src/types/models'

export default combineReducers<RootState>({
    breadcrumb,
    form,
    projects,
    sandbox,
    session,
    widget
})
