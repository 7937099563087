export const SharedDomains: string[] = [
    'gmail.com',
    'yahoo.com',
    'hotmail.com',
    'aol.com',
    'msn.com',
    'live.com',
    'ymail.com',
    'y7mail.com',
    'outlook.com',
    'googlemail.com',
    'bigpond.com',
    'facebook.com',
    'yahoo.com.au',
    'mail.com',
    'live.com.au',
    'optusnet.com.au',
    'bigpond.net.au',
    'protonmail.com',
    'protonmail.ch'
]
