// tslint:disable:jsx-no-lambda
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, Switch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import TemplatesSection from '@src/components/company/TemplatesSection'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import CommunicationsSection from '@src/components/project/CommunicationsSection'
import DocumentsSection from '@src/components/project/DocumentsSection'
import EmailsSection from '@src/components/project/EmailsSection'
import ProjectToolbar from '@src/components/project/ProjectToolbar'
import RegistersSection from '@src/components/project/RegistersSection'
import SettingsSection from '@src/components/project/SettingsSection'
import TransmittalsSection from '@src/components/project/TransmittalsSection'
import UsersSection from '@src/components/project/UsersSection'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IConnectedState {
    project: Project
}

class ProjectMainPage extends React.Component<RouteComponentProps & IConnectedState> {

    public render() {
        const { project, location } = this.props

        return (
            <>
                <ProjectBanner project={project} />
                <ProjectToolbar currentRoutePath={location.pathname} project={project} />
                <Switch>
                    <CrumbRoute title="Users" path={Routes.PROJECT_USERS} render={props => <UsersSection project={project} {...props} />}/>
                    <CrumbRoute title="Documents" path={Routes.PROJECT_DOCUMENTS} render={props => <DocumentsSection project={project} {...props} />}/>
                    <CrumbRoute title="Transmittals" path={Routes.PROJECT_TRANSMITTALS} render={props => <TransmittalsSection project={project} {...props} />} />
                    <CrumbRoute title="Emails" path={Routes.PROJECT_EMAILS} render={props => <EmailsSection project={project} {...props} />} />
                    <CrumbRoute title="Registers" path={Routes.PROJECT_REGISTERS} render={props => <RegistersSection project={project} {...props}/>}/>
                    <CrumbRoute title="Communications" path={Routes.PROJECT_COMMUNICATIONS} render={props => <CommunicationsSection project={project} {...props}/>}/>
                    <CrumbRoute title="Templates" path={Routes.PROJECT_TEMPLATES} render={props => <TemplatesSection project={project} {...props}/>}/>
                    <CrumbRoute path={Routes.PROJECT_SETTINGS_GENERAL} title="Settings">
                        <SettingsSection project={project} />
                    </CrumbRoute>
                    <Redirect from={Routes.project(project.id)} to={Routes.projectDocuments(project.id)} />
                </Switch>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps): RouteComponentProps & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active
    }
}

export default connect(mapStateToProps)(ProjectMainPage)
