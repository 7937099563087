import BigNumber from 'bignumber.js'

export function formatCurrency(value: number | BigNumber, includeCurrencySymbol: boolean = true) {
    if (typeof value === 'number') {
        return new BigNumber(value).toFormat(2, { prefix: includeCurrencySymbol ? '$' : '', decimalSeparator: '.', groupSeparator: ',', groupSize: 3 })
    }

    return (value as BigNumber).toFormat(2, { prefix: includeCurrencySymbol ? '$' : '', decimalSeparator: '.', groupSeparator: ',', groupSize: 3 })
}

export function removeAllNonPeriodDigit(value: string) {
    if (value == null || typeof value !== 'string') return value
    return value.replace(/[^0-9.-]+/g, '')
}
