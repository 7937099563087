import React from 'react'

import ForbiddenPage from '@src/pages/common/ForbiddenPage'
import NotFoundPage from '@src/pages/common/NotFoundPage'
import { ClientErrorCode } from '@src/types/http'

interface IProps {
    requiredOperation: string
    resourceName: string
    noSuggestOperationOnNotFound: boolean
    clientErrorCode: ClientErrorCode
}

export default class ClientErrorPage extends React.PureComponent<IProps> {

    public static defaultProps = {
        noSuggestOperationOnNotFound: null
    }

    public render() {
        const { requiredOperation, resourceName, noSuggestOperationOnNotFound, clientErrorCode, children } = this.props

        switch (clientErrorCode) {
            case undefined:
            case null:
                return <>{children}</>
            case 403:
                return <ForbiddenPage resourceName={resourceName} requiredOperation={requiredOperation} />
            case 404:
                return <NotFoundPage resourceName={resourceName} requiredOperation={noSuggestOperationOnNotFound ? null : requiredOperation} />
            default:
                throw new Error('No error page defined for status code')
        }
    }
}
