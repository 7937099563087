import React from 'react'
import { Badge, Card, CardBody, CardHeader, ListGroup, ListGroupItem } from 'reactstrap'

import cx from 'classnames'

import NotificationListItem from '@src/components/notifications/NotificationListItem'
import { Notification } from '@src/types/notification'

interface IProps {
    notifications: Notification[]
    totalUnread: number
    loading: boolean
    markAllAsRead: () => void
    markAsRead: (notificationId: string) => void
}

const NotificationsCard: React.FC<IProps> = ({ notifications, totalUnread, loading, markAllAsRead, markAsRead }) => {
    return (
        <Card style={{ minWidth: '460px', height: '80vh', minHeight: '280px', maxHeight: '800px' }}>
            <CardHeader className="d-flex px-3 py-2 align-items-center" style={{ zIndex: 2 }}>
                <div className="flex-grow-1">
                    <h4 className="mb-0" style={{ fontWeight: 400 }}>Notifications<small className={cx('text-warning ml-2', { 'd-none': totalUnread < 1 })}>{totalUnread}</small></h4>
                </div>
                {totalUnread > 0 && <Badge tag="a" href="#" color="light" className="text-muted" onClick={markAllAsRead}><small>Mark all as read</small></Badge>}
            </CardHeader>
            <CardBody className="p-0 overflow-auto bg-light">
                <ListGroup flush>
                    {loading
                        ? <ListGroupItem className="text-muted bg-light text-center">Loading...</ListGroupItem>
                        : (notifications?.length > 0
                            ? notifications.map((n, idx) => <NotificationListItem key={n.id} tabIndex={idx} notification={n} markAsRead={markAsRead} />)
                            : <ListGroupItem className="text-muted bg-light text-center">No notifications to show</ListGroupItem>
                        )}
                </ListGroup>
            </CardBody>
        </Card>
    )
}

export default NotificationsCard
