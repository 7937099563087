import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Container } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import TransmittalToolbar from '@src/components/transmittal/TransmittalToolbar'
import { loadPrincipals } from '@src/logic/auth/access'
import { TransmittalAccessGet, TransmittalAccessUpdate, } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Transmittal } from '@src/types/transmittal'

interface IProps {
    transmittal: Transmittal
    reloadTransmittal: () => void
}

const TransmittalAccessPage: React.FC<IProps> = ({ transmittal, reloadTransmittal }) => {

    const company = useSelector<RootState, Company>(s => s.session.company)

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    async function getRevisionAcl() {
        const response = await TransmittalAccessGet(transmittal.id)
        return response.data
    }

    async function saveTransmittalAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await TransmittalAccessUpdate(transmittal.id, accessUpdate)
            reloadTransmittal()
            return true
        } catch {
            return false
        }
    }

    return (
        <>
            <TransmittalToolbar transmittal={transmittal} />
            <Container fluid className="mt-3">
                <Card className="mb-3">
                    <CardHeader><FA icon="lock" /> Transmittal Access Control</CardHeader>
                    <CardBody>
                        <AccessControlTable getAcl={getRevisionAcl} commitUpdates={saveTransmittalAccessUpdates} loadPrincipals={loadCompanyPrincipals} disabled={!transmittal.myAccess.isAdministrator} />
                    </CardBody>
                </Card>
            </Container>
        </>
    )
}

export default TransmittalAccessPage
