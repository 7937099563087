import { generatePath as reactRouterGeneratePath, matchPath } from 'react-router'

import history from '@src/logic/routing/history'

export interface IRouteParams {
    [param: string]: string
}

export function generatePath<T extends IRouteParams>(pathTemplate: string, routeProps?: T) {
    return reactRouterGeneratePath(pathTemplate, routeProps)
}

export function pathMatchesCurrentRoute(path: string, exact: boolean = true): boolean {
    return !!matchPath(history.location.pathname, { path, exact })
}
