import { createAction } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { BudgetItem, Commitment, PaymentClaimBrief } from '@src/types/costs'
import { Revision } from '@src/types/document'
import { UserBasic } from '@src/types/principal'
import { WidgetState } from '@src/types/widget'

export interface IAddEntityPayload<T> {
    projectId: string
    entities: T[]
}

export interface IToggleEntityPayload<T> {
    projectId: string
    entities: T[]
    snapTogether: boolean
}

export interface IRemoveEntityPayload {
    projectId: string
    entityIds: string[]
}

export const rehydrateWidget = createAction<WidgetState>(Actions.WIDGET_REHYDRATE)

export const clearWidget = createAction<string>(Actions.WIDGET_CLEAR)

export const addRevisions = createAction<IAddEntityPayload<Revision>>(Actions.WIDGET_REVISIONS_ADD)
export const toggleRevisions = createAction<IToggleEntityPayload<Revision>>(Actions.WIDGET_REVISIONS_TOGGLE)
export const removeRevisions = createAction<IRemoveEntityPayload>(Actions.WIDGET_REVISIONS_REMOVE)
export const clearRevisions = createAction<string>(Actions.WIDGET_REVISIONS_CLEAR)

export const addUsers = createAction<IAddEntityPayload<UserBasic>>(Actions.WIDGET_USERS_ADD)
export const removeUsers = createAction<IRemoveEntityPayload>(Actions.WIDGET_USERS_REMOVE)
export const clearUsers = createAction<string>(Actions.WIDGET_USERS_CLEAR)

export const addBudgetItems = createAction<IAddEntityPayload<BudgetItem>>(Actions.WIDGET_BUDGET_ITEMS_ADD)
export const removeBudgetItems = createAction<IRemoveEntityPayload>(Actions.WIDGET_BUDGET_ITEMS_REMOVE)
export const clearBudgetItems = createAction<string>(Actions.WIDGET_BUDGET_ITEMS_CLEAR)

export const addCommitments = createAction<IAddEntityPayload<Commitment>>(Actions.WIDGET_COMMITMENTS_ADD)
export const removeCommitments = createAction<IRemoveEntityPayload>(Actions.WIDGET_COMMITMENTS_REMOVE)
export const clearCommitments = createAction<string>(Actions.WIDGET_COMMITMENTS_CLEAR)

export const addPaymentClaims = createAction<IAddEntityPayload<PaymentClaimBrief>>(Actions.WIDGET_PAYMENTCLAIMS_ADD)
export const removePaymentClaims = createAction<IRemoveEntityPayload>(Actions.WIDGET_PAYMENTCLAIMS_REMOVE)
export const clearPaymentClaims = createAction<string>(Actions.WIDGET_PAYMENTCLAIMS_CLEAR)
