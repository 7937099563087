type FilterValue = string | number | Date | null

enum FilterOperation {
    Eq = '',
    Gt = '>',
    Lt = '<'
}

type QueryMapKeys<T> = { [P in keyof T]: T[P] extends FilterValue ? P : never }[keyof T]

export class FilterBuilder<TPropMap> {

    private readonly filterCache: string[] = []

    private constructor() {
        this.eq = this.eq.bind(this)
        this.gt = this.gt.bind(this)
        this.lt = this.lt.bind(this)
        this.build = this.build.bind(this)
        this.populateCacheEntry = this.populateCacheEntry.bind(this)
    }

    public static for<T>(): FilterBuilder<T> {
        return new FilterBuilder<T>()
    }

    public eq<T extends QueryMapKeys<TPropMap>>(property: T, value: TPropMap[T] | TPropMap[T][]): FilterBuilder<TPropMap> {
        this.populateCacheEntry(property, FilterOperation.Eq, value)
        return this
    }

    public gt<T extends QueryMapKeys<TPropMap>>(property: T, value: TPropMap[T]): FilterBuilder<TPropMap> {
        this.populateCacheEntry(property, FilterOperation.Gt, value)
        return this
    }

    public lt<T extends QueryMapKeys<TPropMap>>(property: T, value: TPropMap[T]): FilterBuilder<TPropMap> {
        this.populateCacheEntry(property, FilterOperation.Lt, value)
        return this
    }

    public and(action: (builderContext: FilterBuilder<TPropMap>) => void) {
        const nestedBuilder = new FilterBuilder<TPropMap>()
        action(nestedBuilder)

        if (nestedBuilder.filterCache.length < 2) {
            this.filterCache.push(...nestedBuilder.filterCache)
        } else {
            this.filterCache.push(`(${nestedBuilder.buildInternal('AND')})`)
        }

        return this
    }

    public or(action: (builderContext: FilterBuilder<TPropMap>) => void) {
        const nestedBuilder = new FilterBuilder<TPropMap>()
        action(nestedBuilder)

        if (nestedBuilder.filterCache.length < 2) {
            this.filterCache.push(...nestedBuilder.filterCache)
        } else {
            this.filterCache.push(`(${nestedBuilder.build()})`)
        }

        return this
    }

    public build(): string {
        return this.buildInternal()
    }

    private buildInternal(operator: 'AND' | '' = '') {
        return this.filterCache.join(` ${operator} `)
    }

    private populateCacheEntry<T extends QueryMapKeys<TPropMap>>(property: T, op: FilterOperation, value: TPropMap[T] | TPropMap[T][]) {
        const prop = this.cleanAndValidateProperty(property as string)
        const cleanValue = value instanceof Array ? value.map(v => this.cleanValue(v)).join(',') : this.cleanValue(value)

        this.filterCache.push(`${prop}:${op}${cleanValue}`)
    }

    private cleanAndValidateProperty(property: string): string {
        const trimmedProp = property.trim()

        if (property === '' || property.match(/s+/)) {
            throw new Error('Invalid property')
        }

        return trimmedProp
    }

    private cleanValue(value: FilterValue): string {
        if (value == null) return 'null'

        if (typeof value === 'string') {
            if (value === '') return '""'

            if (value === 'null') return '"null"'

            if (value.match(/((?![a-zA-Z1-9]).)+/)) return `"${value.replace('"', '\"')}"`

            return value
        }

        return value.toString()
    }
}
