import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarButton from '@src/components/toolbar/ToolbarButton'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { auth } from '@src/logic/auth/AuthService'
import { CostReportExcelLink, CostReportPdfLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { CostsOverview } from '@src/types/costs'

interface IProps {
    projectId: string
    currentRoutePath: string
    costsOverview: CostsOverview
}

export default class CostsToolbar extends React.PureComponent<IProps> {

    private downloadExcelCostReport = () => {
        downloadURL(CostReportExcelLink(auth.getSessionToken(), this.props.projectId))
    }

    private downloadPdfCostReport = () => {
        downloadURL(CostReportPdfLink(auth.getSessionToken(), this.props.projectId))
    }

    public render() {
        const { projectId, costsOverview } = this.props
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_DASHBOARD)} to={Routes.projectCostsDashboard(projectId)} name="Dashboard" nameBrief="Dash" iconBrief="chart-line" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_BUDGET, true)} to={Routes.projectCostsBudget(projectId)} name="Budget" nameBrief="Budget" iconBrief="box-usd" />
                    {costsOverview.commitmentDefinitions.map((cd) => {
                        const route = Routes.projectCostsCommitmentType(projectId, cd.code)
                        return <ToolbarLink key={cd.code} active={pathMatchesCurrentRoute(route)} to={route} name={cd.name} nameBrief={cd.name} />
                    })}
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COSTS_PAYMENTS)} to={Routes.projectCostsPayments(projectId)} name="Payment" nameBrief="Payment" />
                    <div className="d-none d-md-flex ml-auto">
                        <ToolbarButton onClick={this.downloadExcelCostReport} name="Export" icon="share-square" iconBrief="share-square" tool />
                        <ToolbarButton onClick={this.downloadPdfCostReport} name="Report (Beta)" icon="file-chart-line" iconBrief="file-chart-line" tool />
                        <ToolbarLink to={Routes.projectCostsSettings(projectId)} tool name="Settings" icon="cog" iconBrief="cog" />
                    </div>
                </Toolbar>
            </section>
        )
    }
}
