export const SESSION_CLEAR = 'SESSION_CLEAR'
export const SESSION_RAISE_ERROR = 'SESSION_RAISE_ERROR'
export const SESSION_SET_COMPANY = 'SESSION_SET_COMPANY'
export const SESSION_SET_FEATURES = 'SESSION_SET_FEATURES'
export const SESSION_SET_ACCOUNT = 'SESSION_SET_ACCOUNT'
export const SESSION_SET_PROFILE = 'SESSION_SET_PROFILE'

export const CRUMB_ADD = 'CRUMB_ADD'
export const CRUMB_UPDATE = 'CRUMB_UPDATE'
export const CRUMB_DELETE = 'CRUMB_DELETE'

export const PROJECT_SET_ACTIVE = 'PROJECT_SET_ACTIVE'
export const PROJECT_SET_ACTIVE_CONTACT = 'PROJECT_SET_ACTIVE_CONTACT'
export const PROJECT_SET_ACTIVE_COSTSOVERVIEW = 'PROJECT_SET_ACTIVE_COSTSOVERVIEW'
export const PROJECT_SET_FAVOURITES = 'PROJECT_SET_FAVOURITES'

export const SANDBOX_TOGGLE = 'SANDBOX_TOGGLE'
export const SANDBOX_LOADING_UPLOADS = 'SANDBOX_LOADING_UPLOADS'
export const SANDBOX_CLEAR_COMPLETED_UPLOADS = 'SANDBOX_CLEAR_COMPLETED_UPLOADS'
export const SANDBOX_UPLOAD_CREATE = 'SANDBOX_UPLOAD_CREATE'
export const SANDBOX_UPLOAD_PROGRESS = 'SANDBOX_UPLOAD_PROGRESS'
export const SANDBOX_UPLOAD_WILL_PAUSE = 'SANDBOX_UPLOAD_WILL_PAUSE'
export const SANDBOX_UPLOAD_SET_STATE = 'SANDBOX_UPLOAD_SET_STATE'
export const SANDBOX_UPLOAD_REMOVE = 'SANDBOX_UPLOAD_REMOVE'
export const SANDBOX_REVISE_DOCUMENT = 'SANDBOX_REVISE_DOCUMENT'

export const WIDGET_REHYDRATE = 'WIDGET_REHYDRATE'
export const WIDGET_CLEAR = 'WIDGET_CLEAR'
export const WIDGET_REVISIONS_ADD = 'WIDGET_REVISIONS_ADD'
export const WIDGET_REVISIONS_TOGGLE = 'WIDGET_REVISIONS_TOGGLE'
export const WIDGET_REVISIONS_REMOVE = 'WIDGET_REVISIONS_REMOVE'
export const WIDGET_REVISIONS_CLEAR = 'WIDGET_REVISIONS_CLEAR'
export const WIDGET_USERS_ADD = 'WIDGET_USERS_ADD'
export const WIDGET_USERS_REMOVE = 'WIDGET_USERS_REMOVE'
export const WIDGET_USERS_CLEAR = 'WIDGET_USERS_CLEAR'
export const WIDGET_BUDGET_ITEMS_ADD = 'WIDGET_BUDGET_ITEMS_ADD'
export const WIDGET_BUDGET_ITEMS_REMOVE = 'WIDGET_BUDGET_ITEMS_REMOVE'
export const WIDGET_BUDGET_ITEMS_CLEAR = 'WIDGET_BUDGET_ITEMS_CLEAR'
export const WIDGET_COMMITMENTS_ADD = 'WIDGET_COMMITMENTS_ADD'
export const WIDGET_COMMITMENTS_REMOVE = 'WIDGET_COMMITMENTS_REMOVE'
export const WIDGET_COMMITMENTS_CLEAR = 'WIDGET_COMMITMENTS_CLEAR'
export const WIDGET_PAYMENTCLAIMS_ADD = 'WIDGET_PAYMENTCLAIMS_ADD'
export const WIDGET_PAYMENTCLAIMS_REMOVE = 'WIDGET_PAYMENTCLAIMS_REMOVE'
export const WIDGET_PAYMENTCLAIMS_CLEAR = 'WIDGET_PAYMENTCLAIMS_CLEAR'
