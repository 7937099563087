import React from 'react'
import { connect } from 'react-redux'
import { Card, CardHeader, ListGroup, ListGroupItem } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import DocumentTitleLink from '@src/components/document/DocumentTitleLink'
import { RevisionsListRecentlyViewed } from '@src/logic/http/Api'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IConnectedState {
    userId: string
}

interface IState {
    revisions: Revision[]
}

class RecentlyViewedDocumentsCard extends React.PureComponent<IConnectedState, IState> {

    constructor(props) {
        super(props)

        this.state = {
            revisions: null
        }
    }

    public componentDidMount() {
        RevisionsListRecentlyViewed()
            .then(response => this.setState({ revisions: response.data.revisions.slice(0, 9) }))
    }

    public render() {
        return (
            <Card>
                <CardHeader><FA icon="file-alt" /> Recent Documents</CardHeader>
                <ListGroup flush>
                    {this.state.revisions && this.state.revisions.map(r => (
                        <ListGroupItem key={r.id}>
                            <DocumentTitleLink revision={r} />
                        </ListGroupItem>
                    ))}
                    {!this.state.revisions && <ListGroupItem key="__loading">Loading recently viewed <FA icon="spinner-third" spin/></ListGroupItem>}
                </ListGroup>
            </Card>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        userId: state.session.user.id
    }
}

export default connect(mapStateToProps)(RecentlyViewedDocumentsCard)
