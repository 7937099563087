import React from 'react'

import Link from '@src/components/common/Link'

interface IProps {
    to: string
}

const SidebarListItem: React.FunctionComponent<IProps> = ({ to, children }) => {
    return (
        <li>
            <Link className="sidebar-group__list-item" to={to}>{children}</Link>
        </li>
    )
}

export default SidebarListItem
