import { BeaconCommands } from '@src/types/beacon'

import { Company } from '@src/types/principal'
import { Session } from '@src/types/session'

class HelpScoutService {

    constructor(uuid: string) {
        window.Beacon(BeaconCommands.init, uuid)
    }

    public open() {
        window.Beacon(BeaconCommands.open)
    }

    public close() {
        window.Beacon(BeaconCommands.open)
    }

    public toggle() {
        window.Beacon(BeaconCommands.toggle)
    }

    public search(query: string) {
        window.Beacon(BeaconCommands.search, query)
    }

    public suggest(...ids: string[]) {
        window.Beacon(BeaconCommands.suggest, ids)
    }

    public showArticle(id: string) {
        window.Beacon(BeaconCommands.article, id)
    }

    public identify(user: Session.User, company: Company) {
        window.Beacon(BeaconCommands.identify, {
            name: `${user.firstName} ${user.lastName}`,
            email: `${user.email}`,
            company: company.name,
            ...(user.profilePictureLink && { avatar: user.profilePictureLink }),
            'User Id': user.id,
            'Company Id': user.companyId,
        })
    }
}

export const HelpScoutServiceInstance = new HelpScoutService(process.env.HELPSCOUT_BEACON_ID)
