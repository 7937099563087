import React from 'react'
import { connect } from 'react-redux'
import { Form, FormGroup, Label, ListGroup, ListGroupItem, ListGroupItemHeading, ListGroupItemText } from 'reactstrap'
import { ConfigProps, Field, FieldArray, FieldArrayFieldsProps, FieldArrayMetaProps, formValueSelector, InjectedFormProps, reduxForm } from 'redux-form'

import axios, { CancelTokenSource } from 'axios'
import { DebouncedFunc, throttle } from 'lodash'

import Action from '@src/components/common/Action'
import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import Select, { InputActionChange } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { userBasicLabel, userBasicLabelFormat, userBasicValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CompanyUsersList } from '@src/logic/http/Api'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { RootState } from '@src/types/models'
import { UserInternal } from '@src/types/principal'

interface IConnectedState {
    members: UserInternal[]
}

interface IState {
    users: UserInternal[]
}

export interface IGroupFormData {
    name: string
    description: string
    members: UserInternal[]
}

class GroupForm extends React.PureComponent<IConnectedState & InjectedFormProps<IGroupFormData, IConnectedState>, IState> {
    private loadUsersCancelToken: CancelTokenSource
    private readonly throttledLoadUsers: DebouncedFunc<(filter: string) => void>

    constructor(props) {
        super(props)

        this.throttledLoadUsers = throttle(this.loadUsers, 500)

        this.state = {
            users: []
        }
    }

    public componentDidMount() {
        this.throttledLoadUsers('')
    }

    public componentWillUnmount() {
        this.throttledLoadUsers.cancel()
    }

    private removeMember = (index: number) => {
        this.props.array.remove('members', index)
    }

    private renderMembers = (data: { fields: FieldArrayFieldsProps<UserInternal>, meta: FieldArrayMetaProps }) => {
        return data.fields.map((member, idx, f) => {
            const user = f.get(idx)
            return (
                <ListGroupItem key={user.id}>
                    <div className="d-flex align-items-center">
                        <UserAvatar size="xs" imageUrl={user.profilePictureLink} firstName={user.firstName} lastName={user.lastName} />
                        <ListGroupItemText className="mb-0 ml-2">{user.firstName} {user.lastName} {mutedValue(user.email)}</ListGroupItemText>
                        <Action data={idx} onClick={this.removeMember} className="ml-auto">
                            <FA className="text-muted" icon="times" />
                        </Action>
                    </div>
                </ListGroupItem>
            )
        })
    }

    private loadUsers = async (input: string): Promise<void> => {
        if (this.loadUsersCancelToken) this.loadUsersCancelToken.cancel()
        try {
            this.loadUsersCancelToken = axios.CancelToken.source()
            const queryValue = input.split(/\s/).map(p => `"${p.replace('"', '\\"')}"`).join(',')
            const usersResponse = await CompanyUsersList(`first_name: ${queryValue} OR last_name: ${queryValue}`, 'first_name', 1, 100, { cancelToken: this.loadUsersCancelToken.token })
            this.loadUsersCancelToken = null
            this.setState({ users: usersResponse.data })
        } catch (err) {
            if (axios.isCancel(err)) return
            this.loadUsersCancelToken = null
        }
    }

    private filterUsers = (option: { label, value, data: UserInternal }, input) => {
        return !this.props.members.find(x => x.id === option.data.id)
    }

    private onChange = (user: UserInternal) => {
        this.props.array.push('members', user)
    }

    private onInputChange = (value: string, action: InputActionChange) => {
        if (action === 'input-change') {
            this.throttledLoadUsers(value)
        }
    }

    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Field id="name" name="name" component={ValidatedInput} validate={required} />
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" />
                </FormGroup>
                <ListGroup flush>
                    <ListGroupItemHeading>Members</ListGroupItemHeading>
                    <ListGroupItem>
                        <Select<UserInternal>
                            value={null}
                            isLoading={this.loadUsersCancelToken != null}
                            options={this.state.users.filter(u => !this.props.members.find(x => x.id === u.id))}
                            onInputChange={this.onInputChange}
                            onChange={this.onChange}
                            getOptionLabel={userBasicLabel}
                            getOptionValue={userBasicValue}
                            formatOptionLabel={userBasicLabelFormat}
                            filterOption={this.filterUsers}
                        />
                    </ListGroupItem>
                    <FieldArray name="members" component={this.renderMembers as any} />
                </ListGroup>
            </Form>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: ConfigProps<IGroupFormData>): IConnectedState {
    const members = formValueSelector(ownProps.form)(state, 'members')
    return {
        members
    }
}

export default connect(mapStateToProps)(reduxForm<IGroupFormData, IConnectedState>({})(GroupForm))
