import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Card, Col, Container, Row } from 'reactstrap'

import SignupForm from '@src/components/signup/SignupForm'
import history from '@src/logic/routing/history'

interface IState {
    token: string
}

export default class extends React.Component<RouteComponentProps, IState> {

    constructor(props) {
        super(props)

        const token = new URLSearchParams(this.props.location.search).get('token')
        if (!token) {
            history.push('/')
        }

        this.state = { token: decodeURIComponent(token) }
    }

    public render() {
        return (
                <div className="d-flex h-100 content__inner" style={{ background: 'linear-gradient(135deg, rgba(2,21,79,1) 0%, rgba(37,64,146,1) 100%)' }}>
                    <Container fluid>
                        <Row className="h-100 justify-content-center align-items-center">
                            <Col className="d-none d-md-block" md={2} xl={4} />
                            <Col className="h-75" xs={12} md={8} xl={4}>
                                <Card body className="mb-5">
                                    <SignupForm token={this.state.token} />
                                </Card>
                            </Col>
                            <Col className="d-none d-md-block" md={2} xl={4} />
                        </Row>
                    </Container>
                </div>
        )
    }
}
