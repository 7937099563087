import React from 'react'
import { DropdownItem } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

export default class LoadingProjectsDropdownItem extends React.PureComponent {
    public render() {
        return (
            <DropdownItem disabled header className="project__menu-item text-dark" tag="a" role="button">
                <span className="project__text">
                    <span className="">Loading projects... <FA icon="spinner-third" spin /></span>
                </span>
            </DropdownItem>
        )
    }
}
