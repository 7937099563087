import React from 'react'

import cx from 'classnames'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'

interface IProps extends React.HTMLAttributes<HTMLAnchorElement> {
    active?: boolean
    name: string
    disabled?: boolean
    icon?: IPFontawesomeIcon
    iconBrief?: IPFontawesomeIcon
    nameBrief?: string
    tool?: boolean
    to?: string
}

const ToolbarLink: React.FC<IProps> = ({ active, name, nameBrief, disabled, icon, iconBrief, to, tool, ...rest }) => {

    const largeIcon = icon ? <FA size="lg" icon={icon} className="d-none d-lg-inline" /> : null
    const smallIcon = iconBrief ? <FA size="lg" icon={iconBrief} className="d-lg-none" /> : null

    return (
        <Link disabled={disabled} to={to || '#'} className={cx('toolbar__link', { active, toolbar__tool: tool, 'text-black-50': disabled })} {...rest}>
            {smallIcon}
            <span className="d-none d-sm-inline">
                {largeIcon}{smallIcon}
                <span className={cx('d-none d-lg-inline')}> {name}</span>
                <span className={cx('d-lg-none')}> {nameBrief || name}</span>
            </span>
        </Link>
    )
}

export default ToolbarLink
