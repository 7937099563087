import React from 'react'
import { Card, Container } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    resourceName: string
    requiredOperation: string
}

export default class ForbiddenPage extends React.PureComponent<IProps> {
    public render() {
        const { requiredOperation, resourceName } = this.props

        return (
            <Container fluid>
                <Card body className="mt-3 text-center">
                    <div className=" mt-3 mb-3"><FA icon="ban" size="4x" /></div>
                    <div className="mb-3"><h4>No permission to access {resourceName}</h4></div>
                    <div className="mb-5">
                        <p>You do not have the <strong>{requiredOperation}</strong> permission on <strong>{resourceName}</strong> required to access it. Contact an administrator of the resource to get permission</p>
                    </div>
                </Card>
            </Container>
        )
    }
}
