import React from 'react'
import { useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Container } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { RegisterAccessGet, RegisterAccessUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    register: RegisterOverview
    reloadOverview: () => void
}

const RegisterAccessPage: React.FC<IProps> = ({ register, reloadOverview }) => {

    const company = useSelector<RootState, Company>(s => s.session.company)

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    async function getRegisterAcl() {
        const response = await RegisterAccessGet(register.id)
        return response.data
    }

    async function saveRegisterAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await RegisterAccessUpdate(register.id, accessUpdate)
            reloadOverview()
            return true
        } catch {
            return false
        }
    }

    return (
        <Container fluid className="mt-3">
            <Card className="mb-3">
                <CardHeader><FA icon="lock" /> Register Access Control</CardHeader>
                <CardBody>
                    <AccessControlTable getAcl={getRegisterAcl} commitUpdates={saveRegisterAccessUpdates} loadPrincipals={loadCompanyPrincipals} disabled={!register.myAccess.isAdministrator} />
                </CardBody>
            </Card>
        </Container>
    )
}

export default RegisterAccessPage
