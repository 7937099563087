import React from 'react'

import BigNumber from 'bignumber.js'
import cx from 'classnames'

import { formatCurrency } from '@src/logic/utils/Currency'

interface IProps {
    className: string
    value: number
    errorNonZero: boolean
}

export default class CostValue extends React.PureComponent<IProps> {
    public static defaultProps: IProps = {
        className: null,
        value: 0,
        errorNonZero: false
    }

    public render() {
        const { errorNonZero, value } = this.props
        return (
            <div className={cx('d-flex', { 'text-danger': (errorNonZero && value !== 0) || value < 0 }, this.props.className)}>
                <span style={{ visibility: value < 0 ? 'initial' : 'hidden' }}>(</span><span>$</span><span className="ml-auto">{formatCurrency(new BigNumber(value).abs(), false)}</span><span style={{ visibility: value < 0 ? 'initial' : 'hidden' }}>)</span>
            </div>
        )
    }
}
