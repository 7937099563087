
import { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

const iconClass = (type: FileType): IPFontawesomeIcon => {
    switch (type) {
        case FileType.Archive:
            return 'file-archive'
        case FileType.Audio:
            return 'file-audio'
        case FileType.Code:
            return 'file-code'
        case FileType.Excel:
            return 'file-excel'
        case FileType.File:
        case FileType.Unknown:
            return 'file'
        case FileType.Image:
            return 'file-image'
        case FileType.Pdf:
            return 'file-pdf'
        case FileType.PowerPoint:
            return 'file-powerpoint'
        case FileType.Text:
            return 'file-alt'
        case FileType.Video:
            return 'file-video'
        case FileType.Word:
            return 'file-word'
    }
}

export const contentTypeIcon = (contentType) => {
    return iconClass(fileType(contentType))
}

export const fileTypeIcon = (fileName: string) => {
    return iconClass(fileTypeFromFileName(fileName))
}

export enum FileType {
    Archive,
    Audio,
    Code,
    Excel,
    File,
    Image,
    Pdf,
    PowerPoint,
    Text,
    Video,
    Word,
    Unknown
}

export const fileType = (contentType: string): FileType => {
    function getFileType(type) {
        switch (type) {
            case 'image':
                return FileType.Image
            case 'audio':
                return FileType.Audio
            case 'video':
                return FileType.Video
            // Documents
            case 'application/pdf':
                return FileType.Pdf
            case 'application/msword':
            case 'application/vnd.ms-word':
            case 'application/vnd.oasis.opendocument.text':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml':
            case 'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
                return FileType.Word
            case 'application/vnd.ms-excel':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml':
            case 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet':
            case 'application/vnd.oasis.opendocument.spreadsheet':
                return FileType.Excel
            case 'application/vnd.ms-powerpoint':
            case 'application/vnd.openxmlformats-officedocument.presentationml':
            case 'application/vnd.openxmlformats-officedocument.presentationml.presentation':
            case 'application/vnd.oasis.opendocument.presentation':
                return FileType.PowerPoint
            case 'text/plain':
                return FileType.Text
            case 'text/html':
                return FileType.Code
            case 'application/json':
                return FileType.Code
            // Archives
            case 'application/gzip':
            case 'application/zip':
                return FileType.Archive
        }
    }
    const baseMediaType = contentType.split('/', 2)[0]
    return getFileType(baseMediaType) || getFileType(contentType) || FileType.Unknown
}

export const fileTypeFromFileName = (fileName: string): FileType => {
    return getFileType(fileName.split('.').slice(-1)[0])

    function getFileType(type) {
        switch (type) {
            case 'bmp':
            case 'raw':
            case 'cr2':
            case 'nef':
            case 'orf':
            case 'sr2':
            case 'jpg':
            case 'jpeg':
            case 'png':
            case 'gif':
            case 'tif':
            case 'tiff':
            case 'svg':
                return FileType.Image
            case 'aac':
            case 'aiff':
            case 'm4a':
            case 'mp3':
            case 'ogg':
            case 'oga':
            case 'mogg':
            case 'wav':
            case 'wma':
                return FileType.Audio
            case 'webm':
            case 'flv':
            case 'avi':
            case 'mp4':
            case 'm4v':
            case 'mpg':
            case 'mpeg':
            case 'mpg2':
                return FileType.Video
            case 'pdf':
                return FileType.Pdf
            case 'doc':
            case 'docx':
            case 'docm':
            case 'dotx':
            case 'dotm':
            case 'docb':
                return FileType.Word
            case 'xls':
            case 'xlt':
            case 'xlm':
            case 'xlsx':
            case 'xlsm':
            case 'xltx':
            case 'xltm':
                return FileType.Excel
            case 'ppt':
            case 'pot':
            case 'pps':
            case 'pptx':
            case 'pptm':
            case 'potx':
            case 'potm':
            case 'ppsx':
            case 'ppsm':
            case 'sldx':
            case 'sldm':
                return FileType.PowerPoint
            case 'txt':
            case 'text':
            case 'rtf':
            case 'md':
                return FileType.Text
            case 'html':
            case 'json':
            case 'css':
            case 'js':
                return FileType.Code
            case 'zip':
            case 'gz':
            case 'rar':
            case 'tar':
                return FileType.Archive
            default:
                return FileType.File
        }
    }
}
