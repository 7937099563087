import React from 'react'
import { connect } from 'react-redux'
import { Badge, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import AnnounceKit from 'announcekit-react'

import { UserAvatar } from '@src/components/common/Avatar'
import Link from '@src/components/common/Link'
import { auth } from '@src/logic/auth/AuthService'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'

interface IConnectedState {
    user: Session.User
    account: Session.AuthAccount
}

interface IState {
    open: boolean
    notificationCount: number
}

class UserMenu extends React.PureComponent<IConnectedState, IState> {
    constructor(props) {
        super(props)

        this.state = {
            open: false,
            notificationCount: 0
        }
    }

    private readonly toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    private readonly handleAnnounceKitUnread = ({ unread }) => {
        this.setState({ notificationCount: unread })
    }

    public render() {
        const { user } = this.props
        return (
            <Dropdown className="header__dropdown h-100 d-flex" isOpen={this.state.open} toggle={this.toggle} inNavbar>
                <DropdownToggle className="px-1 py-0 my-auto" caret color="link" role="button">
                    <span className="header__notifications d-inline-block mr-0">
                        <UserAvatar size="xs" imageUrl={user.profilePictureLink} />
                        {this.state.notificationCount > 0 && <Badge pill color="warning">{this.state.notificationCount}</Badge>}
                    </span>
                </DropdownToggle>
                <DropdownMenu className="dropdown-menu--alt" right>
                    <DropdownItem header>{user.firstName + ' ' + user.lastName}</DropdownItem>
                    <DropdownItem tag={Link} to={Routes.SETTINGS_PROFILE}>Settings</DropdownItem>
                    <DropdownItem onClick={auth.logout}>Logout</DropdownItem>
                    <DropdownItem header>Help</DropdownItem>
                    {process.env.ANNOUNCEKIT_WIDGET_URL != null && <DropdownItem id="ak-trigger">
                        <span className="mr-2">What's new</span>
                        <AnnounceKit
                            catchClick="#ak-trigger"
                            widget={process.env.ANNOUNCEKIT_WIDGET_URL}
                            onWidgetUnread={this.handleAnnounceKitUnread}
                            user={{
                                id: user.id,
                                user_email: user.email,
                                user_name: `${user.firstName} ${user.lastName}`,
                                company_id: user.companyId
                            }}
                        />
                    </DropdownItem>}
                    <DropdownItem tag="a" href="https://docs.infopoint.com.au" target="_blank">Docs</DropdownItem>
                </DropdownMenu>
            </Dropdown>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        user: state.session.user,
        account: state.session.account
    }
}

export default connect(mapStateToProps)(UserMenu)
