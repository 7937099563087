import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import Link, { IProps as LinkProps } from '@src/components/common/Link'

interface IProps extends LinkProps {
    id: string,
    tooltip: React.ReactChild
}

export default class TooltipLink extends React.PureComponent<IProps> {

    public render() {
        const { id, tooltip, children, ...linkProps } = this.props

        return (
            <Link id={id} {...linkProps}>{children}<UncontrolledTooltip target={id} delay={{ show: 250, hide: 0 }} boundariesElement="window" flip={false}>{tooltip}</UncontrolledTooltip></Link>
        )
    }
}
