import React from 'react'
import { Col, Collapse, Label, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { FieldPrefix, PrefixedField } from '@src/components/forms/FieldPrefix'
import { finalFormInitialValue, ValidatedGenericInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { buildValidator } from '@src/components/metadata/validator'
import useBoolean from '@src/hooks/useBoolean'
import { required } from '@src/logic/forms/validation'
import { IMetadataDefinition } from '@src/types/metadata'

interface IProps {
    metadataDefinitions: IMetadataDefinition[]
    title?: string | React.ReactElement
    initialValues?: Partial<{
        name: string
        revNumber: string
        revDate: Date
        author: string
        tags?: IOption<string>[]
        description: string
        metadata: {
            [key: string]: any
        }
    }>
    noValidate?: boolean
}

export const DocumentUpload: React.FC<IProps> = ({ title, initialValues: propsInitialValues, metadataDefinitions, noValidate }) => {
    const isOpen = useBoolean(true)
    const initialValues = propsInitialValues || {}

    function renderMetadataInputs() {
        return metadataDefinitions.map((md, idx) => (
            <Col xs={12} md={4} lg={2} key={md.key} className="mb-3">
                <Label>{md.name} <MetadataTooltip id={`meta-tooltip-${md.key}`} definition={md} placement="right" /></Label>
                <PrefixedField name={md.key} component={ValidatedGenericInput} definition={md} validate={!noValidate && buildValidator(md)} size="sm" initialValue={initialValues.metadata ? finalFormInitialValue(md, initialValues.metadata[md.key]) : undefined} />
            </Col>
        ))
    }

    return (
        <>
            {title && <h5 className="border-bottom pb-2 mb-2 pointer" onClick={isOpen.toggle}>
                {title}
                <FA className="ml-2" icon={isOpen.value ? 'chevron-up' : 'chevron-down'} role="button" />
            </h5>}
            <Collapse isOpen={isOpen.value}>
                <Row>
                    <Col xs={12} md={4} lg={2} className="mb-3">
                        <Label>Document Name</Label>
                        <PrefixedField name="name" component={ValidatedInput} validate={!noValidate && required} bsSize="sm" initialValue={initialValues.name} />
                    </Col>
                    <Col xs={12} md={4} lg={2} className="mb-3">
                        <Label className="text-nowrap">Revision #</Label>
                        <PrefixedField name="version" component={ValidatedInput} bsSize="sm" defaultValue={initialValues.revNumber} />
                    </Col>
                    <Col xs={12} md={4} lg={2} className="mb-3">
                        <Label>Revision Date</Label>
                        <PrefixedField name="date" component={ValidatedDatePicker} size="sm" defaultValue={initialValues.revDate} />
                    </Col>
                    <Col xs={12} md={4} lg={2} className="mb-3">
                        <Label>Author</Label>
                        <PrefixedField name="author" component={ValidatedInput} bsSize="sm" defaultValue={initialValues.author} />
                    </Col>
                    <Col xs={12} md={4} lg={2} className="mb-3">
                        <Label>Tags</Label>
                        <PrefixedField name="tags" isMulti creatable component={ValidatedSelect} size="sm" initialValue={initialValues.tags}  />
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} md={8} lg={12} className="mb-3">
                        <Label>Description</Label>
                        <PrefixedField name="description" type="textarea" component={ValidatedInput} bsSize="sm" defaultValue={initialValues.description} />
                    </Col>
                </Row>
                <Row>
                    <FieldPrefix prefix="metadata">
                        {renderMetadataInputs()}
                    </FieldPrefix>
                </Row>
            </Collapse>
        </>
    )
}
