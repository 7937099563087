import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'
import { bindActionCreators } from 'redux'

import * as ProjectActions from '@src/actions/project'
import MiniBanner from '@src/components/banner/MiniBanner'
import ButtonLink from '@src/components/common/ButtonLink'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { ProjectOperations } from '@src/logic/auth/operations'
import { FeatureFlag, FeatureValue, Features, hasFeature } from '@src/logic/features/features'
import { ProjectUsersList, ProjectsGet } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import NotFoundPage from '@src/pages/common/NotFoundPage'
import CommunicationPages from '@src/pages/communication/CommunicationPages'
import TemplatePages from '@src/pages/communication/TemplatePages'
import CostsPages from '@src/pages/costs/CostsPages'
import EmailPages from '@src/pages/email/EmailPages'
import DocumentPages from '@src/pages/project/DocumentPages'
import ProjectMainPage from '@src/pages/project/ProjectMainPage'
import ProjectSettingsPage from '@src/pages/project/ProjectSettingsPage'
import RegisterPages from '@src/pages/register/RegisterPages'
import TransmittalPages from '@src/pages/transmittal/TransmittalPages'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const ProjectPages: React.FC = () => {
    const dispatch = useDispatch()
    const projectActions = bindActionCreators(ProjectActions, dispatch)
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const features = useSelector<RootState, Features>(s => s.session.features)
    const match = useRouteMatch<Routes.IProjectParams>()

    const projectAsync = useAsyncCancellable(
        async (cancelToken, id) => {
            if (project?.id === id) {
                return
            }
            const p = await ProjectsGet(id, { cancelToken }).then(res => res.data)
            projectActions.activeProject(p)
            const primaryContactUser = p.settings?.primaryContact ? (await ProjectUsersList(p.id, `id: "${p.settings.primaryContact.id}"`, undefined, 1, 1)).data[0] : null
            projectActions.activeProjectContact(primaryContactUser)
            if (hasFeature(features, FeatureFlag.Costs, FeatureValue.CostsDefault)) {
                projectActions.loadProjectCostsOverview()
            }
        },
        [match.params.id]
    )

    if (projectAsync.loading) return null

    if (projectAsync.error) {
        return (
            <CrumbRoute path="" title="Project Not Found">
                <MiniBanner />
                <NotFoundPage requiredOperation={ProjectOperations.Read} resourceName="project">
                    <div className="mt-4"><ButtonLink to={Routes.COMPANY_PROJECTS}>Company Projects</ButtonLink></div>
                </NotFoundPage>
            </CrumbRoute>
        )
    }

    return (
        <CrumbRoute path="" title={project.name} linkPath={Routes.project(project.id)}>
            <Switch>
                <CrumbRoute path={Routes.PROJECT_DOCUMENT} title="Documents" component={DocumentPages} linkPath={Routes.projectDocuments(project.id)} />
                <CrumbRoute path={Routes.PROJECT_TRANSMITTAL} title="Transmittals" component={TransmittalPages} linkPath={Routes.projectTransmittals(project.id)} />
                <CrumbRoute path={Routes.PROJECT_EMAIL} title="Emails" component={EmailPages} linkPath={Routes.projectEmails(project.id)} />
                <CrumbRoute path={Routes.PROJECT_REGISTER} title="Registers" component={RegisterPages} linkPath={Routes.projectRegisters(project.id)} />
                <CrumbRoute path={Routes.PROJECT_COMMUNICATION} title="Communications" component={CommunicationPages} linkPath={Routes.projectCommunications(project.id)} />
                <CrumbRoute path={Routes.PROJECT_TEMPLATE} title="Templates" render={routeProps => <TemplatePages project={project} {...routeProps} />} linkPath={Routes.projectTemplates(project.id)} />
                <CrumbRoute path={Routes.PROJECT_SETTINGS_GENERAL} title="Settings" component={ProjectSettingsPage} />
                {hasFeature(features, FeatureFlag.Costs, FeatureValue.CostsDefault) && <CrumbRoute path={Routes.PROJECT_COSTS} title="Costs" linkPath={Routes.projectCosts(project.id)} component={CostsPages} />}
                <Route component={ProjectMainPage} />
            </Switch>
        </CrumbRoute>
    )
}

export default ProjectPages
