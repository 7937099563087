import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import ToggleButton from '@src/components/common/ToggleButton'

interface IProps {
    label?: string
}

export default class RFToggleButton extends React.PureComponent<IProps & WrappedFieldProps> {

    private handleToggle = () => {
        this.props.input.onChange(!!!this.props.input.value)
    }

    public render() {
        return (
            <ToggleButton on={this.props.input.value} onToggle={this.handleToggle} label={this.props.label} />
        )
    }
}
