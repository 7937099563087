import * as React from 'react'
import { Field, Form, FormRenderProps } from 'react-final-form'
import { OnChange } from 'react-final-form-listeners'
import { Form as BootstrapForm, Button, Col, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { CancelToken } from 'axios'
import BigNumber from 'bignumber.js'
import { flow, sortBy, uniqBy } from 'lodash/fp'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption, InputActionChange } from '@src/components/common/Select'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import localSearch from '@src/components/search/LocalSearch'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import useConstant from '@src/hooks/useConstant'
import { IModalProps } from '@src/hooks/useModal'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { commitmentLabel, commitmentTypeLabel, commitmentTypeValue, commitmentValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CommitmentCreate, CommitmentUpdate, CommitmentsList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import { FilterBuilder } from '@src/logic/iql/FilterBuilder'
import NotificationService from '@src/logic/notification/NotificationService'
import { groupBy } from '@src/logic/utils/Collection'
import { throttlePromise } from '@src/logic/utils/Promise'
import { Api } from '@src/types/api'
import { Commitment, CommitmentDefinition, CommitmentDefinitionRole, CommitmentReference, CostsOverview } from '@src/types/costs'
import { CollaboratorBasic, Project } from '@src/types/project'

export interface ICommitmentFormData {
    type?: CommitmentDefinition
    parent?: Commitment | CommitmentReference
    name: string
    description: string
    date: Date
    tags: string[]
    otherParty: IOption<string>
    otherPartyReference: string
    variationAdditionsPercent?: number
    variationDeductionsPercent?: number
}

interface IBaseProps extends IModalProps {
    /** Project commitment exists in */
    project: Project
    /** Costs overview for project */
    costsOverview: CostsOverview
}

interface ICommonProps extends IBaseProps {
    /** Id of parent to create a subcommitment for */
    parentId?: string
    /** Commitment to edit */
    commitment?: Commitment
    /** Force the type a new commitment can be */
    commitmentDefinition?: CommitmentDefinition
}

interface INewCommitmentProps extends IBaseProps, Pick<ICommonProps, keyof { parentId, commitmentDefinition }> {
    onCommitmentCreated?: (commitment: Commitment) => void
}

interface IEditCommitmentProps extends IBaseProps, Pick<ICommonProps, 'commitment'> {
    commitment: Commitment
    onCommitmentUpdated: () => void
}

interface CommitmentQueryMap {
    other_party: string
    type: string
    parent_id: string
    name: string
}

const collaboratorPropertyMap: IMappedSearchProperty<CollaboratorBasic>[] = [
    { name: 'Name', searchKey: 'other_party', path: i => i.name, type: PropertyType.Text }
]

async function otherPartySearch(cancelToken: CancelToken, input: string, project: Project) {
    const filter = FilterBuilder.for<CommitmentQueryMap>().eq('other_party', input).build()
    return await CommitmentsList(project.id, filter, undefined, 1, 200, { cancelToken })
        .then(res => {
            const collaborators = localSearch(project.collaborators, collaboratorPropertyMap, filter, '', 1, project.collaborators.length)
            const options = res.data.commitments
                .filter(x => x.otherParty != null)
                .map(x => ({ label: x.otherParty.name, value: x.otherParty.id || x.otherParty.name }))
                .concat(collaborators.map(x => ({ label: x.name, value: x.id })))
            return flow(
                uniqBy<{ label: string, value: string }>('value'),
                sortBy(x => x.label.indexOf(input))
            )(options)
        })
}

async function commitmentSearch(cancelToken: CancelToken, input: string, projectId: string, commitmentDefinitions: CommitmentDefinition[], supplier?: string) {
    return await CommitmentsList(
        projectId,
        FilterBuilder.for<CommitmentQueryMap>()
            .and(ctx =>
                ctx.eq('type', commitmentDefinitions.filter(d => d.role !== CommitmentDefinitionRole.Child).map(cd => cd.code))
                    .eq('parent_id', null)
                    .eq('other_party', supplier ?? '')
                    .eq('name', input)
            )
            .build(),
        undefined,
        1,
        50,
        { cancelToken })
    .then((res) => {
        const groups = groupBy(res.data.commitments, 'type')
        return Object.keys(groups).map(g => ({
            label: commitmentDefinitions.find(d => d.code === g).name,
            options: groups[g]
        }))
    })
}

interface InnerFormProps {
    submitLabel: string
}

const InnerForm: React.FC<InnerFormProps & ICommonProps & FormRenderProps<ICommitmentFormData>> = ({ commitment, commitmentDefinition, costsOverview, form, handleSubmit, parentId, project, submitLabel, toggle, values }) => {
    const childCommitmentTypes = React.useMemo(() => costsOverview.commitmentDefinitions.filter(x => x.role !== CommitmentDefinitionRole.Parent), [costsOverview.commitmentDefinitions])

    const throttledOtherPartySearch = useConstant(() => throttlePromise(otherPartySearch, 200))
    const throttledCommitmentSearch = useConstant(() => throttlePromise(commitmentSearch, 200))

    const otherPartyAsync = useAsyncCancellable(
        throttledOtherPartySearch,
        ['', project],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    const parentCommitmentsAsync = useAsyncCancellable(
        throttledCommitmentSearch,
        ['', project.id, costsOverview.commitmentDefinitions, values.otherParty?.label],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    function showParentInput(activeCommitmentDefinition: CommitmentDefinition) {
        return activeCommitmentDefinition && (parentId == null && activeCommitmentDefinition.role !== CommitmentDefinitionRole.Parent)
    }

    function handleSupplierChange(input: string, action: InputActionChange) {
        if (action === 'input-change') {
            otherPartyAsync.execute(input, project)
        }
    }

    function handleParentInputChange(input: string, action: InputActionChange) {
        if (action === 'input-change') {
            parentCommitmentsAsync.execute(input, project.id, costsOverview.commitmentDefinitions, values.otherParty?.value)
        }
    }

    const title = commitment
        ? `Edit ${commitment.name}`
        : `New ${commitmentDefinition?.name ?? 'sub commitment'}`

    return (
        <>
            <ModalHeader id="form-header" toggle={toggle}>{title}</ModalHeader>
            <ModalBody>
                <BootstrapForm aria-labelledby="form-header">
                    {!commitment?.parent && <Row>
                        <Col>
                            <FormGroup>
                                <Label for="supplier">Supplier</Label>
                                <Field
                                    id="supplier"
                                    name="otherParty"
                                    inputId="supplier"
                                    component={ValidatedSelect}
                                    creatable
                                    isLoading={otherPartyAsync.loading}
                                    options={otherPartyAsync.result || []}
                                    onInputChange={handleSupplierChange}
                                    validate={required}
                                />
                            </FormGroup>
                        </Col>
                    </Row>}
                    {(commitmentDefinition == null || showParentInput(values.type)) && <Row>
                        {commitmentDefinition == null && <Col>
                            <FormGroup>
                                <Label for="type">Type</Label>
                                <Field
                                    id="type"
                                    name="type"
                                    component={ValidatedSelect}
                                    validate={required}
                                    options={childCommitmentTypes}
                                    getOptionLabel={commitmentTypeLabel}
                                    getOptionValue={commitmentTypeValue}
                                />
                            </FormGroup>
                        </Col>}
                        {showParentInput(values.type) && <Col>
                            <FormGroup>
                                <Label for="parent">Parent</Label>
                                <Field
                                    id="parent"
                                    name="parent"
                                    component={ValidatedSelect}
                                    validate={required}
                                    onInputChange={handleParentInputChange}
                                    options={parentCommitmentsAsync.result || []}
                                    isLoading={parentCommitmentsAsync.loading}
                                    getOptionValue={commitmentValue}
                                    getOptionLabel={commitmentLabel}
                                    isDisabled={commitment != null}
                                />
                                <OnChange name="otherParty">
                                    {(val?: IOption<string>) => {
                                        if (values.parent?.otherParty && (values.parent.otherParty.id || values.parent.otherParty.name) !== val?.value) {
                                            form.change('parent', undefined)
                                        }
                                    }}
                                </OnChange>
                            </FormGroup>
                        </Col>}
                    </Row>}
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="name">Name</Label>
                                <Field id="name" name="name" component={ValidatedInput} validate={required} />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="date">Date</Label>
                                <Field id="date" name="date" component={ValidatedDatePicker} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="supplierReference">Supplier Reference</Label>
                                <Field id="supplierReference" name="otherPartyReference" component={ValidatedInput} />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <FormGroup>
                                <Label for="additions">Additions</Label>
                                <InputGroup size="sm">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="percentage" /></InputGroupText></InputGroupAddon>
                                    <Field id="additions" name="variationAdditionsPercent" component={ValidatedInput} type="number" initialValue={(commitment?.variationAdditions ?? values.type?.defaultVariationAdditions ?? 0) * 100} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label for="deductions">Deductions</Label>
                                <InputGroup size="sm">
                                    <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="percentage" /></InputGroupText></InputGroupAddon>
                                    <Field id="deductions" name="variationDeductionsPercent" component={ValidatedInput} type="number" initialValue={(commitment?.variationDeductions ?? values.type?.defaultVariationDeductions ?? 0) * 100} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            <FormGroup>
                                <Label for="description">Description</Label>
                                <Field id="description" name="description" component={ValidatedInput} type="textarea" />
                            </FormGroup>
                        </Col>
                    </Row>
                </BootstrapForm>
            </ModalBody>
            <ModalFooter>
                <Button onClick={handleSubmit}>{submitLabel}</Button>
            </ModalFooter>
        </>
    )
}

export const EditCommitmentModal: React.FC<IEditCommitmentProps> = (props) => {
    async function handleUpdate(values: ICommitmentFormData) {
        const updatedCommitment: Api.Request.CommitmentUpdate = {
            name: values.name,
            description: values.description,
            date: values.date,
            otherParty: values.otherParty?.value,
            otherPartyReference: values.otherPartyReference,
            variationAdditions: new BigNumber(values.variationAdditionsPercent).dividedBy(100).dp(4).toNumber(),
            variationDeductions: new BigNumber(values.variationDeductionsPercent).dividedBy(100).dp(4).toNumber(),
            documentLinks: props.commitment.documentLinks,
            tags: props.commitment.tags,
            notes: props.commitment.notes
        }
        try {
            await CommitmentUpdate(props.project.id, props.commitment.id, updatedCommitment)
            props.onCommitmentUpdated?.()
            props.toggle()
        } catch (err) {
            if (isAxiosError(err)) {
                switch (err.response?.status) {
                    case 400:
                        return buildFormErrorsFromModelState(values, err.response.data as Api.Response.ModelError<Api.Request.CommitmentUpdate>)
                    case 403:
                        NotificationService.error(`Cannot update ${values.type.name.toLocaleLowerCase()}, you do not have permission.`)
                        break
                    default:
                        break
                }
            }
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <Form<ICommitmentFormData>
                onSubmit={handleUpdate}
                keepDirtyOnReinitialize
                initialValues={{
                    parent: props.commitment.parent,
                    type: props.costsOverview.commitmentDefinitions.find(x => x.code === props.commitment.type),
                    date: props.commitment.date,
                    description: props.commitment.description,
                    name: props.commitment.name,
                    otherParty: props.commitment.otherParty ? { label: props.commitment.otherParty.name, value: props.commitment.otherParty.id || props.commitment.otherParty.name } : null,
                    otherPartyReference: props.commitment.otherPartyReference,
                    tags: props.commitment.tags ?? []
                }}
            >
                {form => <InnerForm {...form} {...props} submitLabel="Save" />}
            </Form>
        </Modal>
    )
}

export const NewCommitmentModal: React.FC<INewCommitmentProps> = (props) => {
    async function handleCreate(values: ICommitmentFormData): Promise<void | Partial<ICommitmentFormData>> {
        const canHaveParent = props.commitmentDefinition ? props.commitmentDefinition.role !== CommitmentDefinitionRole.Parent : props.costsOverview.commitmentDefinitions.find(x => x.code === values.type.code).role !== CommitmentDefinitionRole.Parent
        const newCommitment: Api.Request.CommitmentNew = {
            parentId: !canHaveParent ? null : (props.parentId || (values.parent ? values.parent.id : null)),
            type: values.type?.code,
            name: values.name,
            description: values.description,
            date: values.date,
            otherParty: values.otherParty?.value,
            otherPartyReference: values.otherPartyReference,
            variationAdditions: new BigNumber(values.variationAdditionsPercent).dividedBy(100).dp(4).toNumber(),
            variationDeductions: new BigNumber(values.variationDeductionsPercent).dividedBy(100).dp(4).toNumber(),
            tags: [],
            notes: '',
            documentLinks: {}
        }

        try {
            const res = await CommitmentCreate(props.project.id, newCommitment)
            props.onCommitmentCreated?.(res.data)
            props.toggle()
        } catch (err) {
            if (isAxiosError(err)) {
                switch (err.response?.status) {
                    case 400:
                        return buildFormErrorsFromModelState(values, err.response.data as Api.Response.ModelError<Api.Request.CommitmentNew>)
                    case 403:
                        NotificationService.error(`Cannot create ${values.type.name.toLocaleLowerCase()}, you do not have permission.`)
                        break
                    default:
                        break
                }
            }
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <Form<ICommitmentFormData>
                onSubmit={handleCreate}
                initialValues={{
                    type: props.commitmentDefinition,
                    date: new Date(),
                    description: '',
                    name: '',
                    otherParty: null,
                    otherPartyReference: null,
                    tags: []
                }}
            >
                {formProps => <InnerForm {...formProps} {...props} submitLabel="Create" />}
            </Form>
        </Modal>
    )
}
