import React from 'react'
import { Card, Collapse } from 'reactstrap'

import cx from 'classnames'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

interface IProps {
    toggle: () => void
    collapsed: boolean
    title: string
    icon: IPFontawesomeIcon
    className?: string
}

const SidebarGroup: React.FunctionComponent<IProps> = ({ icon, toggle, title, className, collapsed, children }) => {
    return (
        <Card className={cx('sidebar-group', className)}>
            <h2 className="sidebar-group__heading" onClick={toggle}>
                <a className="sidebar-group__trigger collapsed" role="button">
                    <span className="sidebar-group__icon"><FA icon={icon} /></span>
                    <span className="sidebar-group__title">{title}</span>
                </a>
            </h2>
            <Collapse className="sidebar-group__collapse" isOpen={!collapsed}>
                {children}
            </Collapse>
        </Card>
    )
}

export default SidebarGroup
