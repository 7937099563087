import React from 'react'
import { Card, Container } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    resourceName: string
    requiredOperation: string
}

export default class NotFoundPage extends React.PureComponent<IProps> {

    public static defaultProps: Pick<IProps, 'requiredOperation'> = {
        requiredOperation: null
    }

    public render() {
        const { resourceName, requiredOperation } = this.props

        return (
            <Container fluid>
                <Card body className="mt-3 text-center">
                    <div className="mt-3 mb-3"><FA icon="map-marker-question" size="4x" /></div>
                    <div className="mb-3"><h4>Could not find the {resourceName} you were looking for...</h4></div>
                    <div className="mb-5">
                        <p className="mb-1">We searched for the {resourceName} you asked for, but couldn't find anything. Are you sure it exists?</p>
                        {requiredOperation && <small>If you are sure something should be here, you might be missing the <strong>{requiredOperation}</strong> permission. Contact an administrator of the {resourceName} to give this permission.</small>}
                        {this.props.children}
                    </div>
                </Card>
            </Container>
        )
    }
}
