import React from 'react'
import { RouteComponentProps } from 'react-router'

import moment from 'moment'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import Tags from '@src/components/common/Tags'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import NewRegisterModal from '@src/components/register/NewRegisterModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations, RegisterOperations } from '@src/logic/auth/operations'
import { RegisterDelete, RegistersList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { Project } from '@src/types/project'
import { RegisterOverview } from '@src/types/register'

interface IProps extends RouteComponentProps {
    project: Project
}

interface IState {
    registerToDelete: RegisterOverview
}

export default class RegistersSection extends React.PureComponent<IProps, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<RegisterOverview, never>>

    constructor(props) {
        super(props)

        this.searchSectionRef = React.createRef<SearchSectionType<RegisterOverview, never>>()

        this.state = {
            registerToDelete: null
        }
    }

    private readonly onSearch = async (filter: string, sort: string, page: number, perPage: number) => {
        const response = await RegistersList(this.props.project.id, filter, sort, page, perPage)
        return ({
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        })
    }

    private readonly navigateToNewRegister = (newRegisterOverview: RegisterOverview) => {
        this.props.history.push({ pathname: Routes.projectRegister(newRegisterOverview.projectId, newRegisterOverview.id) })
    }

    private readonly deleteRegister = async () => {
        const { registerToDelete } = this.state
        try {
            await RegisterDelete(registerToDelete.id)
            NotificationService.info('Deleted register')
        } catch (e) {
            if (isAxiosError(e)) {
                if (e.response) {
                    switch (e.response.status) {
                        case 403:
                            NotificationService.error('You do not have permission to delete the register.')
                            break
                        case 404:
                            NotificationService.error('Could not find the register to delete.')
                            break
                        default:
                            NotificationService.error('An error occurred while deleting the register.')
                            break
                    }
                } else {
                    NotificationService.error('Encountered network connection issues while deleting the register.')
                }
            } else {
                NotificationService.error('An error occurred while deleting the register.')
            }
        }

        this.clearRegisterToDelete()
        this.searchSectionRef.current.doSearch()
    }

    private readonly markRegisterToDelete = (register: RegisterOverview) => {
        this.setState({ registerToDelete: register })
    }

    private readonly clearRegisterToDelete = () => {
        this.setState({ registerToDelete: null })
    }

    public render() {
        const { registerToDelete } = this.state
        const noItemsFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="file-times" /></div>
                <p className="lead">No registers found with the current search criteria...</p>
                <p>Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters.</p>
            </>
        )

        return (
            <SearchSection<RegisterOverview, never>
                ref={this.searchSectionRef}
                headers={[
                    {
                        name: 'Name',
                        sortKey: 'name',
                        sortable: true,
                        overrideRenderer: register => <Link to={Routes.projectRegister(this.props.project.id, register.id)}>{register.name}</Link>
                    },
                    {
                        name: 'Description',
                        accessor: 'description',
                        sortKey: 'description',
                        sortable: true
                    },
                    {
                        name: 'Tags',
                        sortKey: 'tags',
                        sortable: true,
                        overrideRenderer: register => <Tags tags={register.tags} />
                    },
                    {
                        name: 'Created',
                        sortKey: 'created_date',
                        sortable: true,
                        overrideRenderer: register => moment(register.createdDate).format('L')
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        headerWrapperClass: 'text-right',
                        overrideRenderer: r => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLinkAction id={'delete-' + r.id} tooltip="Delete" disabled={!isAuthorised(r.myAccess, RegisterOperations.Delete)} data={r} onClick={this.markRegisterToDelete}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                        )
                    }
                ]}
                extraSearchBarElements={[
                    {
                        element: onSearch => <ModalToggle modal={NewRegisterModal} modalProps={{ projectId: this.props.project.id, onSubmitSuccess: this.navigateToNewRegister }} wrapperProps={{ disabled: !isAuthorised(this.props.project.myAccess, ProjectOperations.CreateRegister) }}>New Register</ModalToggle>,
                        position: 'before'
                    }
                ]}
                onSearch={this.onSearch}
                noItemsFoundMessage={noItemsFoundMessage}
                searchAssistantProperties={[
                    {
                        name: 'Name',
                        searchKey: 'name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Created',
                        searchKey: 'created',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Updated',
                        searchKey: 'updated',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Description',
                        searchKey: 'description',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Tags',
                        searchKey: 'tags',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Name',
                        searchKey: 'name',
                        type: PropertyType.Text
                    }
                ]}
            >
                <ConfirmationModal
                    danger
                    open={registerToDelete != null}
                    header="Delete register"
                    message={<span>Are you sure you want to delete <strong>{registerToDelete?.name}</strong>?</span>}
                    confirmAction="Delete"
                    onConfirm={this.deleteRegister}
                    toggle={this.clearRegisterToDelete}
                    onReject={this.clearRegisterToDelete}
                />
            </SearchSection>
        )
    }
}
