import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'public/styles/app.scss'

import 'public/js/fontawesome/fontawesome.js'

// Require Editor JS files.
// tslint:disable:ordered-imports
// import 'froala-editor/js/froala_editor.pkgd.min.js'
// import 'froala-editor/css/froala_style.min.css'
// import 'froala-editor/css/froala_editor.pkgd.min.css'

import * as Sentry from '@sentry/browser'
import log from 'loglevel'
import moment from 'moment'

import { buildIconLibrary } from '@src/components/common/FontAwesomeIcon'
import AppFacade from '@src/containers/AppFacade'
import { buildFroalaIconLibrary } from '@src/logic/froala/fontawesome'
import { configureStore } from '@src/store'

Sentry.init({ dsn: process.env.SENTRY_DSN })

buildIconLibrary()
buildFroalaIconLibrary()
moment.locale('en-au')

log.setDefaultLevel(process.env.LOGGER_LEVEL as log.LogLevelDesc || 'error')

const store = configureStore()

const rootElement = document.getElementById('root')

ReactDOM.render(<Provider store={store}><AppFacade /></Provider>, rootElement)
