import React from 'react'
import { Field, Form, FormProps } from 'react-final-form'
import { Button, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'

import { buildOptions, IOption } from '@src/components/common/Select'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { composeValidators, required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { CostCodeBrief } from '@src/types/costs'

export interface ICostCodeFormData {
    group: IOption<string>
    code: string
    name: string
    clientGroup: string
    clientCode: string
    clientName: string
}

interface IProps extends IModalPropsManager {
    projectId: string
    existingCode?: CostCodeBrief
    onSubmit: FormProps['onSubmit']
}

const CostCodeForm: React.FC<IProps & FormProps<ICostCodeFormData>> = ({ projectId, existingCode, open, toggle, onClosed, onSubmit }) => {

    const costCodes = useAsyncCancellable(async (cancelToken, pid) => (await CostCodesList(pid, 1, 200, { cancelToken })).data, [projectId])

    function normalizeCode(code: IOption<string>) { return code ? { ...code, value: code.value.toUpperCase() } : code }

    function uniqueCodeValidate(value: string, allValues: ICostCodeFormData) {
        if (costCodes.result == null) return

        if (existingCode && existingCode.code === value) return undefined

        const code = costCodes.result.find(c => c.code === value && c.group === allValues.group.value)

        return code == null ? undefined : `Code needs to be unique - already used by '${code.code} ${code.name}'`
    }

    return (
        <Form
            initialValues={existingCode ? {
                group: { label: existingCode.group, value: existingCode.group },
                code: existingCode.code,
                name: existingCode.name,
                clientGroup: existingCode.clientGroup,
                clientCode: existingCode.clientCode,
                clientName: existingCode.clientName
            } : undefined}
            onSubmit={onSubmit}
        >
            {({ handleSubmit }) =>
                <Modal size="lg" isOpen={open} toggle={toggle} onClosed={onClosed}>
                    <ModalHeader toggle={toggle}>{existingCode ? 'Edit Cost Code' : 'New Cost Code'}</ModalHeader>
                    <ModalBody>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Group</Label>
                                    <Field
                                        name="group"
                                        component={ValidatedSelect}
                                        creatable
                                        validate={required}
                                        normalize={normalizeCode}
                                        isLoading={costCodes.loading}
                                        options={costCodes.result && buildOptions([...new Set(costCodes.result.map(c => c.group))])}
                                    />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Client Group</Label>
                                    <Field name="clientGroup" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Code</Label>
                                    <Field name="code" component={ValidatedInput} disabled={existingCode} validate={composeValidators(required, uniqueCodeValidate)} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Client Code</Label>
                                    <Field name="clientCode" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Field name="name" component={ValidatedInput} validate={required} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Client Name</Label>
                                    <Field name="clientName" component={ValidatedInput} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={handleSubmit}>{existingCode ? 'Save' : 'Create'}</Button>
                    </ModalFooter>
                </Modal>
            }
        </Form>
    )
}

export default CostCodeForm
