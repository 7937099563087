import React from 'react'
import { Popover, PopoverBody } from 'reactstrap'

import { v4 } from 'uuid'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import LinkAction from '@src/components/common/LinkAction'

interface ILink {
    name: string
    route: string
}

interface IProps {
    title: string
    links: ILink[]
}

interface IState {
    open: boolean
}

export default class LinksPopover extends React.PureComponent<IProps, IState> {

    private readonly popoverId = 'doclink-popover-' + v4()

    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    private toggle = () => { this.setState({ open: !this.state.open }) }

    public render() {
        return (
            <LinkAction data={null} className="px-0" id={this.popoverId} onClick={this.toggle}>
                <span style={{ whiteSpace: 'nowrap' }}><FA icon="link" />&nbsp;{this.props.title}</span>
                <Popover isOpen={this.state.open} toggle={this.toggle} target={this.popoverId}>
                    <PopoverBody>
                        {this.props.links.map(((l, idx) => (<li key={idx}><Link to={l.route}>{l.name}</Link></li>)))}
                    </PopoverBody>
                </Popover>
            </LinkAction>
        )
    }
}
