import React from 'react'
import { Table } from 'reactstrap'

import { BigNumber } from 'bignumber.js'
import { groupBy } from 'lodash'

import Link from '@src/components/common/Link'
import CostValue from '@src/components/costs/common/CostValue'
import { CostReportSnapshotRow, CostsOverview } from '@src/types/costs'

interface IProps {
    costModule: CostsOverview
    groupRows: { [group: string]: CostReportSnapshotRow[] }
}

interface ITotal {
    originalBudget: number
    budgetAdjustments: number
    currentBudget: number
    committedCosts: number
    committedVariations: number
    committedTotalCosts: number
    forecastVariations: number
    forecastCosts: number
    forecastFinalCosts: number
    forecastCostVariance: number
    certified: number
}

const emptyTotal = (): ITotal => ({
    originalBudget: 0,
    budgetAdjustments: 0,
    currentBudget: 0,
    committedCosts: 0,
    committedVariations: 0,
    committedTotalCosts: 0,
    forecastVariations: 0,
    forecastCosts: 0,
    forecastFinalCosts: 0,
    forecastCostVariance: 0,
    certified: 0
})

export default class CostSnapshotTable extends React.PureComponent<IProps> {
    private readonly accumlateRows = (rows: ITotal[], accumulator: ITotal): ITotal => {
        return rows.reduce<ITotal>(
            (total, row) => {
                total.originalBudget += row.originalBudget
                total.budgetAdjustments += row.budgetAdjustments
                total.currentBudget += row.currentBudget
                total.committedCosts += row.committedCosts
                total.committedVariations += row.committedVariations
                total.committedTotalCosts += row.committedTotalCosts
                total.forecastVariations += row.forecastVariations
                total.forecastCosts += row.forecastCosts
                total.forecastFinalCosts += row.forecastFinalCosts
                total.forecastCostVariance += row.forecastCostVariance
                total.certified += row.certified
                return total
            },
            accumulator
        )
    }

    private readonly renderCostCodeGroup = (group: string, rows: CostReportSnapshotRow[], grandTotal: ITotal) => {
        const groupedPhases = groupBy<CostReportSnapshotRow>(rows, r => r.costCode)

        const subtotal = emptyTotal()
        const renderedRows = [(
            <tr key={`${group}`}>
                <td colSpan={15} style={{ background: '#D5D5D5' }}><strong>{group}</strong></td>
            </tr>
        )].concat(Object.keys(groupedPhases).map(x => groupedPhases[x]).map((codeRows, idx) => {
            const codeValues: ITotal = emptyTotal()
            this.accumlateRows(codeRows, codeValues)
            this.accumlateRows([codeValues], subtotal)
            return (
                <tr key={`${group}-${codeRows[0].costCode}-${idx}`}>
                    <td>{codeRows[0].costCode}</td>
                    <td>{this.props.costModule.costCodes.find(c => c.code === codeRows[0].costCode).name}</td>
                    <td><CostValue value={codeValues.originalBudget} /></td>
                    <td><CostValue value={codeValues.budgetAdjustments} /></td>
                    <td><CostValue value={codeValues.currentBudget} /></td>
                    <td><CostValue value={codeValues.committedCosts} /></td>
                    <td><CostValue value={codeValues.committedVariations} /></td>
                    <td><CostValue value={codeValues.committedTotalCosts} /></td>
                    <td><CostValue value={codeValues.forecastVariations} /></td>
                    <td><CostValue value={codeValues.forecastCosts} /></td>
                    <td><CostValue value={codeValues.forecastFinalCosts} /></td>
                    <td><CostValue value={codeValues.forecastCostVariance} /></td>
                    <td><CostValue value={codeValues.certified} /></td>
                    <td><CostValue value={new BigNumber(codeValues.forecastFinalCosts).minus(codeValues.certified).toNumber()} /></td>
                </tr>
            )
        })).concat((
            <tr key={`${group}-subtotal`} className="text-light" style={{ background: '#A1A7AF' }}>
                <td>SUB-TOTAL</td>
                <td/>
                <td><CostValue value={subtotal.originalBudget} /></td>
                <td><CostValue value={subtotal.budgetAdjustments} /></td>
                <td><CostValue value={subtotal.currentBudget} /></td>
                <td><CostValue value={subtotal.committedCosts} /></td>
                <td><CostValue value={subtotal.committedVariations} /></td>
                <td><CostValue value={subtotal.committedTotalCosts} /></td>
                <td><CostValue value={subtotal.forecastVariations} /></td>
                <td><CostValue value={subtotal.forecastCosts} /></td>
                <td><CostValue value={subtotal.forecastFinalCosts} /></td>
                <td><CostValue value={subtotal.forecastCostVariance} /></td>
                <td><CostValue value={subtotal.certified} /></td>
                <td><CostValue value={new BigNumber(subtotal.forecastFinalCosts).minus(subtotal.certified).toNumber()} /></td>
            </tr>
        ))

        this.accumlateRows([subtotal], grandTotal)

        return renderedRows
    }

    private readonly renderRows = (grandTotal: ITotal) => {
        return Object.keys(this.props.groupRows).sort().map(g => this.renderCostCodeGroup(g, this.props.groupRows[g], grandTotal))
    }

    public render() {
        const grandTotal: ITotal = emptyTotal()

        return (
            <Table bordered responsive hover size="sm" style={{ fontSize: '0.8125rem' }}>
                <thead className="thead-light text-center">
                    <tr>
                        <th colSpan={2} />
                        <th colSpan={3}>Budget</th>
                        <th colSpan={3}>Committed</th>
                        <th colSpan={4}>Forecast</th>
                        <th colSpan={1}>Payment</th>
                        <th colSpan={1} />
                    </tr>
                    <tr>
                        <th colSpan={2} />
                        <th>A</th>
                        <th>B</th>
                        <th>C=A+B</th>
                        <th>D</th>
                        <th>E</th>
                        <th>F=E+D</th>
                        <th>G</th>
                        <th>H</th>
                        <th>I=F+G+H</th>
                        <th>J=C-I</th>
                        <th>K</th>
                        <th>L=I-K</th>
                    </tr>
                    <tr>
                        <th>Cost Code</th>
                        <th>Cost Code Name</th>
                        <th>Original</th>
                        <th>Adjustment</th>
                        <th>Current</th>
                        <th>Committed</th>
                        <th>Variations</th>
                        <th>Total</th>
                        <th>Variations</th>
                        <th>Costs</th>
                        <th>Final Cost</th>
                        <th>Variance</th>
                        <th>Certified</th>
                        <th>Remaining Cashflow</th>
                    </tr>
                </thead>
                <tbody>
                    {this.renderRows(grandTotal)}
                </tbody>
                <tfoot>
                    <tr className="text-light font-weight-bold" style={{ background: '#71777F' }}>
                        <td>TOTAL</td>
                        <td/>
                        <td><CostValue value={grandTotal.originalBudget} /></td>
                        <td><CostValue value={grandTotal.budgetAdjustments} /></td>
                        <td><CostValue value={grandTotal.currentBudget} /></td>
                        <td><CostValue value={grandTotal.committedCosts} /></td>
                        <td><CostValue value={grandTotal.committedVariations} /></td>
                        <td><CostValue value={grandTotal.committedTotalCosts} /></td>
                        <td><CostValue value={grandTotal.forecastVariations} /></td>
                        <td><CostValue value={grandTotal.forecastCosts} /></td>
                        <td><CostValue value={grandTotal.forecastFinalCosts} /></td>
                        <td><CostValue value={grandTotal.forecastCostVariance} /></td>
                        <td><CostValue value={grandTotal.certified} /></td>
                        <td><CostValue value={new BigNumber(grandTotal.forecastFinalCosts).minus(grandTotal.certified).toNumber()} /></td>
                    </tr>
                </tfoot>
            </Table>
        )
    }
}
