import React from 'react'

import cx from 'classnames'

export interface IStage<T> {
    active: boolean
    label: string
    value: T
    onClick: (value: T) => void
}

export default class ProgressTrackStage<T = string> extends React.PureComponent<IStage<T>> {

    private onClick = () => {
        this.props.onClick(this.props.value)
    }

    public render() {
        return (
            <li className={cx('progress-track__item', { 'progress-track__item--active': this.props.active })}>
                <span onClick={this.onClick} className="progress-track__icon" />
                <a onClick={this.onClick}>{this.props.label}</a>
            </li>
        )
    }
}
