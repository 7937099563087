import React from 'react'
import { Col, Row } from 'reactstrap'

interface IBaseProps {
    title?: string
}

interface ISelectableRowProps extends IBaseProps {
    onSelectAll: () => void
    allRowsSelected: boolean
}

export default class ContentHeader extends React.PureComponent<IBaseProps | ISelectableRowProps> {

    public render() {
        const { allRowsSelected, onSelectAll } = this.props as ISelectableRowProps

        return (
            <Row className="selectable-content__header">
                {onSelectAll &&
                    <>
                        <Col key="header-checkbox" lg={1} className="selectable-content__select selectable-content__cell d-none d-lg-table-cell justify-content-end align-items-baseline" style={{ width: 50 }}>
                            <div className="selectable-content__checkbox mx-lg-auto" onClick={onSelectAll}>
                                <input readOnly checked={allRowsSelected} type="checkbox" data-select="all"/>
                                <label />
                            </div>
                        </Col>
                        <Col key="header-checkbox-collapsed" lg={1} className="selectable-content__select selectable-content__cell d-flex d-lg-none justify-content-end align-items-baseline">
                            <div className="flex-grow-1">
                                {this.props.title}
                            </div>
                            <div>
                                Select all
                            </div>
                            <div className="selectable-content__checkbox mx-lg-auto" onClick={onSelectAll}>
                                <input readOnly checked={allRowsSelected} type="checkbox" data-select="all"/>
                                <label />
                            </div>
                        </Col>
                    </>
                }
                {!onSelectAll &&
                    <Col key="header-checkbox-collapsed" lg={1} className="selectable-content__select selectable-content__cell d-flex d-lg-none align-items-baseline">
                        <div className="flex-grow-1">
                            {this.props.title}
                        </div>
                    </Col>
                }
                {this.props.children}
            </Row>
        )
    }
}
