import React from 'react'
import { ButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Input, Label } from 'reactstrap'

import { isNullOrEmpty } from '@src/logic/utils/Strings'

export interface IDropdownCheckOption {
    section?: string
    label: string
    key: string
    checked: boolean
}

interface IProps<TP = any> {
    id?: string
    isSearchable: boolean
    sectionHeaders?: string[]
    options: IDropdownCheckOption[]
    onCheck: (e: React.SyntheticEvent, option: IDropdownCheckOption) => void
    title: React.ReactChild
    caret: boolean
    tag?: React.ReactType<TP>
    toggleProps?: TP
    right?: boolean
}

interface IState {
    open: boolean
    searchValue: string
    filteredOptions: IDropdownCheckOption[]
}

export default class DropdownCheckOptions extends React.PureComponent<IProps, IState> {

    public static defaultProps: Partial<IProps> = {
        sectionHeaders: [],
        caret: false
    }

    constructor(props: IProps) {
        super(props)

        this.state = {
            open: false,
            searchValue: '',
            filteredOptions: props.options
        }
    }

    public componentDidUpdate(prevProps) {
        if (this.props.options !== prevProps.options) {
            this.updateFilteredOptions()
        }
    }

    private toggle = () => this.setState({ open: !this.state.open })

    private handleCheckParent = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation()
        this.props.onCheck(e, this.props.options.find(o => o.key === e.currentTarget.name))
    }

    private handleCheck = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.stopPropagation()
        this.props.onCheck(e, this.props.options.find(o => o.key === e.currentTarget.name))
    }

    private dontPropagate = (e: React.MouseEvent<HTMLElement>) => {
        e.stopPropagation()
        e.nativeEvent.stopImmediatePropagation()
    }

    private handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ searchValue: e.currentTarget.value }, this.updateFilteredOptions)
    }

    private updateFilteredOptions = () => {
        const filteredOptions = this.props.options
            .map(x => ({ option: x, index: x.label.toLowerCase().indexOf(this.state.searchValue) }))
            .filter(o => o.index > -1).sort((a, b) => a.index - b.index)
            .map(x => x.option)

        this.setState({ filteredOptions })
    }

    private renderOption = (option: IDropdownCheckOption) => {
        return (
            <DropdownItem key={option.key} name={option.key} className="no-outline" toggle={false} onClick={this.handleCheckParent}>
                <FormGroup check>
                    <Label size="sm" check>
                        <Input name={option.key} onChange={this.handleCheck} onClick={this.dontPropagate} readOnly checked={option.checked} type="checkbox" />
                        {option.label}
                    </Label>
                </FormGroup>
            </DropdownItem>
        )
    }

    private renderSectionHeader = (section: string) => <DropdownItem header>{section}</DropdownItem>

    private renderSection = (section: string) => {
        return (
            <React.Fragment key={'__checkoptions-header-' + section}>
                {this.renderSectionHeader(section)}
                {this.state.filteredOptions.filter(x => x.section === section).map(this.renderOption)}
            </React.Fragment>
        )
    }

    public render() {
        const { caret, right, tag, title, toggleProps } = this.props
        const { filteredOptions, open, searchValue } = this.state

        const sections = this.props.sectionHeaders.filter(s => filteredOptions.find(x => x.section === s)).map(this.renderSection)
        const nullSectionOptions = filteredOptions.filter(o => o.section == null)

        return (
            <ButtonDropdown id={this.props.id} direction="down" inNavbar isOpen={open} toggle={this.toggle}>
                <DropdownToggle tag={tag} {...toggleProps} className="w-100" caret={caret}>{title}</DropdownToggle>
                <DropdownMenu onClick={this.dontPropagate} right={right}>
                    {this.props.isSearchable && <DropdownItem className="py-2" toggle={false}>
                        <Input bsSize="sm" value={searchValue} onChange={this.handleSearchChange} placeholder="Filter..." />
                    </DropdownItem>}
                    {sections.length > 0 && sections}
                    {sections.length > 0 && nullSectionOptions.length > 0 && this.renderSectionHeader('Other')}
                    {nullSectionOptions.length > 0 && nullSectionOptions.map(this.renderOption)}
                    {this.props.isSearchable && !isNullOrEmpty(searchValue) && filteredOptions.length === 0 && <DropdownItem header className="text-center"><em>No options</em></DropdownItem>}
                </DropdownMenu>
            </ButtonDropdown>
        )
    }
}
