import { Action, handleActions } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { Sandbox } from '@src/types/sandbox'

const initialState: Sandbox.State = {
    open: false,
    loadingUploads: false,
    uploads: [],
    toRevise: [],
    project: undefined
}

export default handleActions<Sandbox.State, any>(
    {
        [Actions.SANDBOX_TOGGLE] : (state, action: Action<boolean>) => {
            return {
                ...state,
                open: action.payload == null ? !state.open : action.payload
            }
        },
        [Actions.SANDBOX_LOADING_UPLOADS]: (state, action: Action<boolean>) => {
            return {
                ...state,
                loadingUploads: action.payload
            }
        },
        [Actions.SANDBOX_CLEAR_COMPLETED_UPLOADS]: (state, action) => {
            return {
                ...state,
                uploads: state.uploads.filter(u => u.state !== 'completed')
            }
        },
        [Actions.SANDBOX_UPLOAD_CREATE]: (state, action: Action<Sandbox.Upload>) => {
            if (state.uploads.find(u => u.id === action.payload.id)) {
                return state
            }

            return { ...state, uploads: [...state.uploads, action.payload] }
        },
        [Actions.SANDBOX_UPLOAD_PROGRESS]: (state, action: Action<Sandbox.Actions.ReportUploadProgress>) => {
            const idx = state.uploads.findIndex(u => u.id === action.payload.id)

            if (idx === -1) {
                return state
            }

            const newUploads = [...state.uploads]
            newUploads.splice(idx, 1, { ...newUploads[idx], bytesUploaded: action.payload.bytesCompleted })
            return {
                ...state,
                uploads: newUploads
            }
        },
        [Actions.SANDBOX_UPLOAD_WILL_PAUSE]: (state, action: Action<Sandbox.Actions.UploadPause>) => {
            const idx = state.uploads.findIndex(u => u.id === action.payload.id)

            if (idx === -1) {
                return state
            }

            const newUploads = [...state.uploads]
            newUploads.splice(idx, 1, { ...newUploads[idx], state: 'pausing', pausingAtByte: action.payload.pauseAtByte })
            return {
                ...state,
                uploads: newUploads
            }
        },
        [Actions.SANDBOX_UPLOAD_SET_STATE]: (state, action: Action<Sandbox.Actions.UploadState>) => {
            const idx = state.uploads.findIndex(u => u.id === action.payload.id)

            if (idx === -1) {
                return state
            }

            const newUploads = [...state.uploads]
            newUploads.splice(idx, 1, { ...newUploads[idx], state: action.payload.state })
            return {
                ...state,
                uploads: newUploads
            }
        },
        [Actions.SANDBOX_UPLOAD_REMOVE]: (state, action: Action<string[]>) => {
            return {
                ...state,
                uploads: state.uploads.filter(u => !action.payload.includes(u.id))
            }
        },
        [Actions.SANDBOX_REVISE_DOCUMENT]: (state, action: Action<Sandbox.Actions.ReviseDocument>) => {
            return {
                ...state,
                toRevise: action.payload.revisions.filter(x => x.projectId === action.payload.project.id),
                project: action.payload.project
            }
        }
    },
    initialState
)
