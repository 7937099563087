import React from 'react'

import { CommunicationFlagValue, CostFlagValue, DocumentFlagValue, FeatureFlag, Features, FeatureValue, hasFeature, RegisterFlagValue } from '@src/logic/features/features'

interface IBaseProps<TFlag extends FeatureFlag, TValue extends FeatureValue> {
    features: Features
    flag: TFlag
    value: TValue | TValue[]
}

type Props = IBaseProps<FeatureFlag.DocumentModule, DocumentFlagValue> |
             IBaseProps<FeatureFlag.Communications, CommunicationFlagValue> |
             IBaseProps<FeatureFlag.Costs, CostFlagValue> |
             IBaseProps<FeatureFlag.Registers, RegisterFlagValue>


const FeatureGate: React.FunctionComponent<Props> = ({ features, flag, value, children }) => {
    return hasFeature(features, flag as any, value) ? children as React.ReactElement : null
}

export default FeatureGate
