import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Router, Switch } from 'react-router'

import App from '@src/containers/App'
import AuthCallback from '@src/containers/AuthCallback'
import InitializingApp from '@src/containers/InitializingApp'
import LoadingApp from '@src/containers/LoadingApp'
import SessionError from '@src/containers/SessionError'
import { UnauthenticatedArea } from '@src/containers/UnauthenticatedArea'
import { auth } from '@src/logic/auth/AuthService'
import history from '@src/logic/routing/history'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'

const AppFacade: React.FC = () => {

    const session = useSelector<RootState, Session.State>(s => s.session)
    const [initialSessionLoaded, setInitialSessionLoaded] = React.useState(location.pathname === '/authcallback')

    React.useEffect(
        () => {
            if (location.pathname !== '/authcallback') {
                auth.loadSession().then(() => setInitialSessionLoaded(true))
            }
        },
        []
    )

    let child: React.ComponentType = InitializingApp

    if (!initialSessionLoaded) {
        child = InitializingApp
    } else if (!session.account) {
        child = UnauthenticatedArea
    } else if (session.error) {
        child = SessionError
    } else if (!session.user || !session.company || !session.features) {
        child = LoadingApp
    } else if (session.user && session.company && !session.error) {
        child = App
    }

    return (
        <Router history={history}>
            <Switch>
                <Route path="/authcallback" component={AuthCallback} />
                <Route component={child} />
            </Switch>
        </Router>
    )
}

export default AppFacade
