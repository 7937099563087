import React from 'react'
import { Card, CardText, CardTitle, UncontrolledTooltip } from 'reactstrap'

import cx from 'classnames'

import Action from '@src/components/common/Action'
import { UserAvatarList } from '@src/components/common/AvatarList'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import { isAuthorised } from '@src/logic/auth/access'
import { GroupOperations } from '@src/logic/auth/operations'
import { isNullOrEmpty } from '@src/logic/utils/Strings'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { Group } from '@src/types/principal'

interface IProps {
    isAdminGroup?: boolean
    group: Group
    onClickEdit?: (group: Group) => void
    onClickMembers?: (group: Group) => void
    onClickDelete?: (group: Group) => void
}

interface IState {
    hovered: boolean
}

export default class GroupCard extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)
        this.state = {
            hovered: false
        }
    }

    private handleClickEdit = () => {
        if (this.props.onClickEdit) this.props.onClickEdit(this.props.group)
    }

    private handleClickDelete = () => {
        if (this.props.onClickDelete) this.props.onClickDelete(this.props.group)
    }

    private handleClickMembers = () => {
        if (this.props.onClickMembers) this.props.onClickMembers(this.props.group)
    }

    private handleMouseEnter = () => {
        this.setState({ hovered: true })
    }

    private handleMouseLeave = () => {
        this.setState({ hovered: false })
    }

    public render() {
        const { group, isAdminGroup } = this.props
        const formattedGroupId = group.id.replace('|', '_')
        const canEdit = isAuthorised(group.myAccess, GroupOperations.Update)
        const canDelete = isAuthorised(group.myAccess, GroupOperations.Delete)

        return (
            <Card body style={{ transition: 'box-shadow .2s', height: '100%' }} className={cx('d-flex', { shadow: this.state.hovered })} onMouseEnter={this.handleMouseEnter} onMouseLeave={this.handleMouseLeave}>
                <CardTitle className="d-flex">
                    <Action data={null} onClick={this.handleClickMembers} className="text-truncate">
                        {isAdminGroup && <>
                            <FA id={`group-isadmin-${formattedGroupId}`} className="mr-1 text-warning" icon="star" />
                            <UncontrolledTooltip target={`group-isadmin-${formattedGroupId}`}>Company admin group</UncontrolledTooltip>
                        </>}
                        {group.name}
                    </Action>
                    <span className="ml-auto">
                        {canEdit && <Action tag="span" data={null} onClick={this.handleClickEdit}>
                            <FA id={`group-edit-${formattedGroupId}`} icon="pencil"/>
                            <UncontrolledTooltip target={`group-edit-${formattedGroupId}`}>Edit</UncontrolledTooltip>
                        </Action>}
                        {canDelete && !isAdminGroup && <Action<HTMLSpanElement, React.HTMLAttributes<HTMLSpanElement>> tag="span" className="ml-2" data={null} onClick={this.handleClickDelete}>
                            <FA id={`group-delete-${formattedGroupId}`} icon="trash"/>
                            <UncontrolledTooltip target={`group-delete-${formattedGroupId}`}>Delete</UncontrolledTooltip>
                        </Action>}
                    </span>
                </CardTitle>
                <CardText className="flex-grow-1 flex-shrink-0">{isNullOrEmpty(group.notes) ? <em>{mutedValue('No description...')}</em> : <ClampLines lines={3} text={group.notes} />}</CardText>
                <div className="d-flex">
                    <UserAvatarList maxToShow={5} users={group.members} />
                    <Action tag="span" data={group} onClick={this.handleClickMembers} className="ml-auto text-muted flex-shrink-0">{group.members.length} {group.members.length === 1 ? 'Member' : 'Members'}</Action>
                </div>
            </Card>
        )
    }
}
