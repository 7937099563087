import React from 'react'
import { Field, useField, useForm } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Col, Collapse, Container, FormGroup, Label, Row } from 'reactstrap'

import AccessControlTableInput from '@src/components/access/AccessControlTableInput'
import RFToggleButton from '@src/components/common/RFToggleButton'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import * as Operations from '@src/logic/auth/operations'
import { aclHasAdmin, required } from '@src/logic/forms/validation'
import { GroupsList, ProjectAccessGet, ProjectSettingsDefaultAccessGet, ProjectUsersList } from '@src/logic/http/Api'
import { AccessControlList, AclEntry, DefaultPermissions } from '@src/types/access'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'

interface IAccessDefaultsState {
    defaultDocumentAcl: AccessControlList
    defaultDocumentCreatorPermissions: DefaultPermissions
    defaultRevisionAcl: AccessControlList
    defaultRevisionCreatorPermissions: DefaultPermissions
}

const ReviewStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {

    const [approvers, setApprovers] = React.useState<AclEntry[]>(undefined)
    const [accessDefaults, setAccessDefaults] = React.useState<IAccessDefaultsState>({
        defaultDocumentAcl: null,
        defaultDocumentCreatorPermissions: null,
        defaultRevisionAcl: null,
        defaultRevisionCreatorPermissions: null
    })
    const currentUser = useSelector<RootState, Session.User>(s => s.session.user)
    const userCompany = useSelector<RootState, Company>(s => s.session.company)
    const project = useField<Project>('project').input.value
    const overridePermissions = useField<boolean>('overridePermissions').input.value
    const form = useForm()

    React.useEffect(
        () => {
            ProjectAccessGet(project.id, true)
                .then(response => setApprovers(response.data.acl.filter(x => x.type === 'user' && x.authorised.includes(Operations.ApproveDocument))))

            ProjectSettingsDefaultAccessGet(project.id).then((response) => {
                setAccessDefaults({
                    defaultDocumentAcl: response.data.defaultDocumentAcl,
                    defaultDocumentCreatorPermissions: response.data.defaultDocumentCreatorPermissions,
                    defaultRevisionAcl: response.data.defaultRevisionAcl,
                    defaultRevisionCreatorPermissions: response.data.defaultRevisionCreatorPermissions
                })
            })
        },
        []
    )

    React.useEffect(
        () => {
            if (accessDefaults.defaultRevisionAcl != null) {
                loadRevisionPermissionDefaults()
                loadDocumentPermissionDefaults()
            }
        },
        [accessDefaults]
    )

    function loadDocumentPermissionDefaults() {
        const entries = [...accessDefaults.defaultDocumentAcl.acl]
        const userEntryIdx = entries.findIndex(e => e.id === currentUser.id)
        if (userEntryIdx > -1) {
            entries[userEntryIdx].grants.push(...accessDefaults.defaultDocumentCreatorPermissions.grants.filter(g => !entries[userEntryIdx].grants.includes(g)))
            entries[userEntryIdx].denials.push(...accessDefaults.defaultDocumentCreatorPermissions.denials.filter(d => !entries[userEntryIdx].denials.includes(d)))
            entries[userEntryIdx].isAdministrator = accessDefaults.defaultDocumentCreatorPermissions.isAdministrator
        } else {
            entries.push({
                grants: accessDefaults.defaultDocumentCreatorPermissions.grants,
                denials: accessDefaults.defaultDocumentCreatorPermissions.denials,
                isAdministrator: accessDefaults.defaultDocumentCreatorPermissions.isAdministrator,
                type: 'user',
                company: currentUser.companyId,
                id: currentUser.id,
                email: currentUser.email,
                name: currentUser.firstName + ' ' + currentUser.lastName,
                authorised: []
            })
        }
        form.change('documentAccessControl', entries)
        form.mutators.setFieldTouched('documentAccessControl', false)
    }

    function loadRevisionPermissionDefaults() {
        const entries = [...accessDefaults.defaultRevisionAcl.acl]
        const userEntryIdx = entries.findIndex(e => e.id === currentUser.id)
        if (userEntryIdx > -1) {
            entries[userEntryIdx].grants.push(...accessDefaults.defaultRevisionCreatorPermissions.grants.filter(g => !entries[userEntryIdx].grants.includes(g)))
            entries[userEntryIdx].denials.push(...accessDefaults.defaultRevisionCreatorPermissions.denials.filter(d => !entries[userEntryIdx].denials.includes(d)))
            entries[userEntryIdx].isAdministrator = accessDefaults.defaultRevisionCreatorPermissions.isAdministrator
        } else {
            entries.push({
                grants: accessDefaults.defaultRevisionCreatorPermissions.grants,
                denials: accessDefaults.defaultRevisionCreatorPermissions.denials,
                isAdministrator: accessDefaults.defaultRevisionCreatorPermissions.isAdministrator,
                type: 'user',
                company: currentUser.companyId,
                id: currentUser.id,
                email: currentUser.email,
                name: currentUser.firstName + ' ' + currentUser.lastName,
                authorised: []
            })
        }
        form.change('revisionAccessControl', entries)
        form.mutators.setFieldTouched('revisionAccessControl', false)
    }

    async function loadPrincipals() {
        const principalEntries: AclEntry[] = []
        const usersPromise = ProjectUsersList(project.id, undefined, undefined, 1, 200)
        const groupsPromise = GroupsList(undefined, 1, 200)

        const results = await Promise.all([usersPromise, groupsPromise])

        principalEntries.push(...results[0].data.filter(x => x.status === 'Enabled').map<AclEntry>(u => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`,
            email: u.email,
            company: currentUser.companyId,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'user'
        })))

        principalEntries.push(...results[1].data.map<AclEntry>(g => ({
            id: g.id,
            name: g.name,
            email: undefined,
            company: currentUser.companyId,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'group'
        })))

        principalEntries.push({
            id: userCompany.id,
            company: userCompany.id,
            name: userCompany.name,
            email: undefined,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'company'
        })

        return principalEntries
    }

    function approversSorted() {
        if (approvers == null) return []

        const index = approvers.findIndex(x => x.id === currentUser.id)

        return index < 0 ? approvers : [approvers[index], ...approvers.slice(0, index), ...approvers.slice(index + 1)]
    }

    function getApproverLabel(approver: AclEntry) {
        return approver.id === currentUser.id ? 'Self Approve' : `${approver.name}`
    }

    function getApproverValue(approver: AclEntry) {
        return approver.id
    }

    function formatApproverLabel(approver: AclEntry) {
        return approver.id === currentUser.id ? 'Self Approve' : <span>{approver.name} <small className="text-muted">({approver.email})</small></span>
    }

    return (
        <Container fluid>
            <Row>
                <Col>
                    <h1>Send for review</h1>
                </Col>
            </Row>
            <Row className="align-items-center">
                <Col xs={12} md={9} lg={5}>
                    <FormGroup>
                        <Label>Who will review your files?</Label>
                        <Field
                            name="approver"
                            component={ValidatedSelect}
                            validate={required}
                            isLoading={approvers == null}
                            options={approversSorted()}
                            getOptionLabel={getApproverLabel}
                            getOptionValue={getApproverValue}
                            formatOptionLabel={formatApproverLabel}
                        />
                    </FormGroup>
                </Col>
            </Row>
            <Row className="mb-3">
                <Col xs={12} md={9} lg={5}>
                    <FormGroup>
                        <Label>Comments (optional)</Label>
                        <Field name="approvalComments" type="textarea" component={ValidatedInput} />
                    </FormGroup>
                </Col>
            </Row>
            {project.myAccess.isAdministrator &&
                <Row className="mb-3">
                    <Col>
                        <Field name="overridePermissions" component={RFToggleButton} defaultValue={false} label="Override Permissions" />
                        {form.getRegisteredFields().includes('overridePermissions') && <Collapse isOpen={form.getFieldState('overridePermissions').value}>
                            {!true &&
                                <>
                                    <h4>Document Access</h4>
                                    <Field
                                        name="documentAccessControl"
                                        component={AccessControlTableInput}
                                        validOperations={accessDefaults.defaultDocumentAcl ? accessDefaults.defaultDocumentAcl.validOperations : []}
                                        loadPrincipals={loadPrincipals}
                                        reset={loadDocumentPermissionDefaults}
                                        validate={aclHasAdmin}
                                    />
                                </>}
                            <h4>Revision Access</h4>
                            <Field
                                name="revisionAccessControl"
                                component={AccessControlTableInput}
                                validOperations={accessDefaults.defaultRevisionAcl ? accessDefaults.defaultRevisionAcl.validOperations : []}
                                loadPrincipals={loadPrincipals}
                                reset={loadRevisionPermissionDefaults}
                                validate={aclHasAdmin}
                            />
                        </Collapse>}
                    </Col>
                </Row>}
        </Container>
    )
}

export default ReviewStage
