import React from 'react'

import cx from 'classnames'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

interface IProps {
    id?: string
    active?: boolean
    name: string
    onClick: () => void
    icon?: IPFontawesomeIcon
    iconBrief?: IPFontawesomeIcon
    nameBrief?: string
    tool?: boolean
    disabled?: boolean
}

export default class ToolbarButton extends React.PureComponent<IProps> {

    private buildIcon(icon: IPFontawesomeIcon, className?: string) {
        return icon ? <FA size="lg" icon={icon} className={className} />
        : null
    }

    public render() {
        const icon = this.buildIcon(this.props.icon, 'd-none d-lg-inline')
        const iconBrief = this.buildIcon(this.props.iconBrief, 'd-lg-none')

        const nameBrief = this.props.nameBrief || this.props.name

        return (
            <div role="button" className={cx('toolbar__link', { pointer: !this.props.disabled, 'text-muted': this.props.disabled, active: this.props.active, toolbar__tool: this.props.tool })} onClick={this.props.onClick}>
                {iconBrief}
                <span id={this.props.id} className="d-none d-sm-inline">
                    {icon}
                    <span className="d-none d-lg-inline"> {this.props.name}</span>
                    <span className="d-lg-none"> {nameBrief}</span>
                </span>
            </div>
        )
    }
}
