import React from 'react'

import cx from 'classnames'

interface IProps {
    entries: {
        title: string
        data: React.ReactChild
        titleClassName?: string
        dataClassName?: string
    }[]
    titleClassName: string
    dataClassName: string
}

export default class DetailList extends React.PureComponent<IProps> {

    public static defaultProps: Partial<IProps> = {
        titleClassName: '',
        dataClassName: ''
    }

    private renderPairs = () => {
        return this.props.entries.map((e, idx) => (
            <React.Fragment key={idx}>
                <dt className={cx('mr-2', this.props.titleClassName, e.titleClassName)} style={{ gridColumnStart: 1 }}><strong>{e.title}</strong></dt>
                <dd className={cx('mb-0', this.props.dataClassName, e.dataClassName)} style={{ gridColumnStart: 2 }}>{e.data}</dd>
            </React.Fragment>
        ))
    }

    public render() {
        return (
            <dl className="mb-0" style={{ display: 'grid', gridTemplateColumns: 'max-content auto' }}>
                {this.renderPairs()}
            </dl>
        )
    }
}
