import React from 'react'

import cx from 'classnames'

interface IProps extends React.ImgHTMLAttributes<HTMLImageElement> {
    responsive?: boolean
    thumbnail?: boolean
    rounded?: boolean
}

export default class Image extends React.PureComponent<IProps> {
    public render() {
        const { responsive, rounded, thumbnail, className, alt, ...rest } = this.props

        return (
            <img className={cx({ rounded, 'img-fluid': responsive, 'img-thumbnail': thumbnail }, className)} alt={alt} {...rest} />
        )
    }
}
