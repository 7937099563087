import React from 'react'
import { Card } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

export default class extends React.PureComponent {
    public render() {
        return (
            <Card body>
                <div className="text-center"><span className="lead">Loading...</span><FA icon="spinner-third" spin /></div>
            </Card>
        )
    }
}
