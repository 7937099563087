import React from 'react'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'

interface IProps {
    objectName: string
    icon?: IPFontawesomeIcon
}

const NoSearchResults: React.FC<IProps> = ({ icon, objectName }) => {
    return (
        <>
            <div className="my-3"><FA size="3x" icon={icon || 'file-times'} /></div>
            <p className="lead">No {objectName} found with the current search criteria...</p>
            <p>Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters.</p>
        </>
    )
}

export default NoSearchResults
