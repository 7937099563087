import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { Field, Form, FormProps } from 'react-final-form'

export interface IGeneralSettingsFormData {
    gstRate: number
}

const GeneralSettingsForm: React.FC<FormProps<IGeneralSettingsFormData>> = ({ ...formProps }) => {
    return (
        <Form<IGeneralSettingsFormData>
            {...formProps}
        >
            {({ handleSubmit }) => (
                <Card className="my-3">
                    <CardHeader className="d-flex align-items-center">
                        <div className="flex-grow-1"><FA icon="cog" /> General Settings</div>
                        <Button color="primary" onClick={handleSubmit}>Save</Button>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col md={4}>
                                <FormGroup>
                                    <Label>GST Rate</Label>
                                    <InputGroup>
                                        <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="percentage" /></InputGroupText></InputGroupAddon>
                                        <Field name="gstRate" component={ValidatedInput} type="number" />
                                    </InputGroup>
                                    <FormText>Changing the GST rate will only affect new or updated commitments and payments.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Form>
    )
}

export default GeneralSettingsForm
