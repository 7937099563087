// tslint:disable: max-classes-per-file
import initHelpHero from 'helphero'

import { Session } from '@src/types/session'

// Hack to get non-exported type out of library
type HelpHero = ReturnType<typeof initHelpHero>
type initHelpHeroFunc = typeof initHelpHero

interface IHelpHeroService {
    identify(user: Session.User): void
}

class FakeHelpHeroService implements IHelpHeroService {
    // tslint:disable-next-line: no-empty
    public identify(user: Session.User) {
    }
}

class HelpHeroService implements IHelpHeroService {

    private readonly helpHero: HelpHero

    constructor(appId: string) {
        // Library default exports aren't correct
        this.helpHero = (initHelpHero as unknown as initHelpHeroFunc)(appId)
    }

    public identify(user: Session.User) {
        this.helpHero.identify(user.id)
    }
}

export const HelpHeroServiceInstance = ['localhost', '127.0.0.1'].includes(location.hostname) ? new FakeHelpHeroService() : new HelpHeroService(process.env.HELPHERO_APP_ID)
