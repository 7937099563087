import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import RevisionEditForm from '@src/components/document/RevisionEditForm'
import * as FormNames from '@src/logic/forms/Forms'
import NotificationService from '@src/logic/notification/NotificationService'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'

interface IProps {
    revision: Revision
    metadataDefinitions: IMetadataDefinition[]
    reloadDocument: () => void
}

export default class DocumentEditPage extends React.Component<IProps> {

    public handledDocumentUpdated = () => {
        NotificationService.info('Revision updated sucessfully')
        this.props.reloadDocument()
    }

    public handleDocumentUpdateFailed = (errors) => {
        if (errors == null) {
            NotificationService.error('An error occurred while trying to update the revision. Changes were not saved.')
        }
    }

    public render() {
        return (
            <Card>
                <CardHeader><FA icon="pencil" /> Edit Revision</CardHeader>
                <CardBody>
                    <RevisionEditForm
                        revision={this.props.revision}
                        definitions={this.props.metadataDefinitions}
                        form={FormNames.EditRevision}
                        enableReinitialize={true}
                        destroyOnUnmount={true}
                        onSubmitSuccess={this.handledDocumentUpdated}
                        onSubmitFail={this.handleDocumentUpdateFailed}
                    />
                </CardBody>
            </Card>
        )
    }
}
