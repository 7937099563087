import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios'
import log from 'loglevel'

import * as Errors from '@src/logic/storage/gcs/Errors'

const MIN_CHUNK_SIZE = 262144 // 256 KB
const MAX_CHUNK_SIZE = 33554432 // 32 MB
const RECOMMENDED_CHUNK_SIZE = MIN_CHUNK_SIZE * 8 // 2 MB

export default class GoogleCloudUpload {

    public readonly id: string
    public readonly file: File
    public readonly url: string
    public onProgress: (id: string, bytesCompleted: number) => void
    public onComplete: (id: string) => void

    private position: number = 0
    private chunkSize: number
    private paused: boolean
    private finished: boolean
    private currentChunk: Blob
    private currentChunkCancelToken: CancelTokenSource
    private pauseCallback: Promise<void>
    private unpausePromise: Promise<void>
    private unpauseTrigger: () => void

    constructor(id: string, url: string, file: File) {
        this.id = id
        this.url = url
        this.file = file
        this.chunkSize = RECOMMENDED_CHUNK_SIZE

        if (!this.id || !this.url || !this.file) {
            throw new Errors.MissingOptionsError()
        }

        log.debug('Creating new upload instance:')
        log.debug(` - Url: ${this.url}`)
        log.debug(` - Id: ${this.id}`)
        log.debug(` - File size: ${this.file.size}`)
        log.debug(` - Chunk size: ${this.chunkSize}`)
    }

    public async start() {
        const validateStatus = status => (status >= 200 && status < 300) || status === 308

        const uploadNextChunk = async () => {
            const total = this.file.size
            const end = Math.min(this.position + this.chunkSize, this.file.size) - 1

            const headers = {
                'Content-Type': this.file.type,
                'Content-Range': `bytes ${this.position}-${end}/${total}`
            }

            const onUpProgress = (reqProgress: ProgressEvent) => {
                if (this.onProgress) {
                    this.onProgress(this.id, this.position + reqProgress.loaded)
                }
            }

            this.currentChunk = this.file.slice(this.position, end + 1)
            this.currentChunkCancelToken = axios.CancelToken.source()
            const res = await this.safePut(this.url, this.currentChunk, { headers, validateStatus, cancelToken: this.currentChunkCancelToken.token, onUploadProgress: onUpProgress })

            checkResponseStatus(res, this, [200, 201, 308])
        }

        const increaseChunkSize = () => {
            if (this.chunkSize < MAX_CHUNK_SIZE) {
                this.chunkSize *= 2
            }
        }

        if (this.finished) {
            throw new Errors.UploadAlreadyFinishedError()
        }

        while (this.position < this.file.size - 1) {
            if (this.isPaused) {
                if (this.pauseCallback) {
                    Promise.resolve(this.pauseCallback).then(() => this.pauseCallback = null)
                }
                // report progress before waiting
                this.onProgress(this.id, this.position)
                await this.unpausePromise
            }
            try {
                await uploadNextChunk()
                this.position += this.chunkSize
                increaseChunkSize()
            } catch {
                //
            }
        }

        this.finished = true
        if (this.onComplete) {
            this.onComplete(this.id)
        }
    }

    public pause(onFinishCurrentChunk?: (upload: string) => void, force: boolean = false): number {
        if (!this.paused) {
            this.paused = true
            this.unpausePromise = new Promise(resolve => this.unpauseTrigger = resolve)

            if (force) {
                this.currentChunkCancelToken.cancel()

                if (onFinishCurrentChunk) {
                    onFinishCurrentChunk(this.id)
                }
                return this.position
            }


            if (onFinishCurrentChunk) {
                this.pauseCallback = new Promise(res => onFinishCurrentChunk(this.id))
            }

            return this.position + this.currentChunk.size
        }
    }

    public unpause() {
        if (this.paused) {
            this.paused = false
            this.unpauseTrigger()
            log.debug('Upload unpaused')
        }
    }

    public isPaused = () => this.paused

    private async safePut(url, body, opts: AxiosRequestConfig): Promise<any> {
        try {
            return await axios.put(url, body, opts)
        } catch (e) {
            return e
        }
    }
}

function checkResponseStatus(res, upload: GoogleCloudUpload, allowed: number[] = []) {
    const { status } = res
    if (allowed.indexOf(status) > -1) {
        return true
    }

    switch (status) {
        case 308:
            throw new Errors.UploadIncompleteError()

        case 201:
        case 200:
            throw new Errors.FileAlreadyUploadedError(upload.id, upload.url)

        case 404:
            throw new Errors.UrlNotFoundError(upload.url)

        case 500:
        case 502:
        case 503:
        case 504:
            throw new Errors.UploadFailedError(status)

        default:
            throw new Errors.UnknownResponseError(res)
    }
}
