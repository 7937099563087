import React from 'react'
import { Col, Form, FormGroup, FormText, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import { Field, FormSection, InjectedFormProps, reduxForm } from 'redux-form'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { required } from '@src/logic/forms/validation'

interface IProps {
    typeInUse: boolean
}

export interface IEditCommitmentDefinitionFormData {
    name: string
    commitmentNumberDefinition: {
        prefix: string
        significantPlaces: number
    }
    paymentNumberDefinition: {
        prefix: string
        significantPlaces: number
    }
    defaultVariationAdditionsPercentage: number
    defaultVariationDeductionsPercentage: number
    defaultDocumentLinkCategories: IOption<string>[]
    defaultPaymentDocumentLinkCategories: IOption<string>[]
    hasInsurances: boolean
}

class EditCommitmentDefinitionForm extends React.PureComponent<IProps & InjectedFormProps<IEditCommitmentDefinitionFormData, IProps>> {
    private readonly normalizeCode = (value: string) => value ? value.toUpperCase() : value

    public render() {
        const { typeInUse } = this.props
        return (
            <Form>
                {typeInUse && <FormText className="mb-2"><span className="text-warning"><FA icon="exclamation-triangle" /> This commitment type is in use and cannot be modified.</span></FormText>}
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Name</Label>
                            <Field name="name" component={ValidatedInput} validate={required} disabled={typeInUse} />
                        </FormGroup>
                    </Col>
                </Row>
                <h5 className="pb-2 border-bottom">Item Numbering</h5>
                <FormSection name="commitmentNumberDefinition" component={Row as any}>
                    <Col>
                        <FormGroup>
                            <Label>Item Prefix</Label>
                            <Field name="prefix" component={ValidatedInput} validate={required} normalize={this.normalizeCode} disabled={typeInUse} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Item Significant Places</Label>
                            <Field name="significantPlaces" component={ValidatedInput} validate={required} type="number" min={2} max={10} disabled={typeInUse} />
                        </FormGroup>
                    </Col>
                </FormSection>
                <h5 className="pb-2 border-bottom">Payment Numbering</h5>
                <FormSection name="paymentNumberDefinition" component={Row as any}>
                    <Col>
                        <FormGroup>
                            <Label>Payment Claim Prefix</Label>
                            <Field name="prefix" component={ValidatedInput} validate={required} normalize={this.normalizeCode} disabled={typeInUse} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Payment Claim Significant Places</Label>
                            <Field name="significantPlaces" component={ValidatedInput} validate={required} type="number" min={2} max={10} disabled={typeInUse} />
                        </FormGroup>
                    </Col>
                </FormSection>
                <h5 className="pb-2 border-bottom">Other settings</h5>
                <Row>
                    <Col>
                        <FormGroup>
                            <Field name="hasInsurances" component={ValidatedCheckboxRadio} disabled={typeInUse} label="Has Insurances" />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Additions</Label>
                            <InputGroup>
                                <Field name="defaultVariationAdditionsPercentage" component={ValidatedInput} type="number" disabled={typeInUse} />
                                <InputGroupAddon addonType="append">%</InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Deductions</Label>
                            <InputGroup>
                                <Field name="defaultVariationDeductionsPercentage" component={ValidatedInput} type="number" disabled={typeInUse} />
                                <InputGroupAddon addonType="append">%</InputGroupAddon>
                            </InputGroup>
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Document Link Categories</Label>
                            <Field name="defaultDocumentLinkCategories" component={ValidatedSelect} isMulti creatable placeholder="Create category..." isDisabled={typeInUse} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Payment Document Link Categories</Label>
                            <Field name="defaultPaymentDocumentLinkCategories" component={ValidatedSelect} isMulti creatable placeholder="Create category..." isDisabled={typeInUse} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default reduxForm<IEditCommitmentDefinitionFormData, IProps>({})(EditCommitmentDefinitionForm)
