import { Action, handleActions } from 'redux-actions'

import * as Actions from '@src/actions/Actions'
import { Crumb } from '@src/types/models'

const initialState: Crumb[] = []

export default handleActions<Crumb[], any>(
    {
        [Actions.CRUMB_ADD]: (state, action: Action<Crumb>) => {
            return [...state, action.payload]
        },
        [Actions.CRUMB_UPDATE]: (state, action: Action<Crumb>) => {
            return state.map(crumb => crumb.id === action.payload.id ? action.payload : crumb)
        },
        [Actions.CRUMB_DELETE]: (state, action: Action<string>) => {
            return state.filter(crumb => crumb.id !== action.payload)
        }
    },
    initialState
)
