import React from 'react'
import { Card, CardBody, CardHeader, Container } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { AccessControlList, UserAccess } from '@src/types/access'
import { Api } from '@src/types/api'
import { Company } from '@src/types/principal'

interface IProps<T extends { myAccess: UserAccess }> {
    entity: T
    name: string
    company: Company
    getEntityAcl: (entity: T) => Promise<AccessControlList>
    saveAccessUpdate: (entity: T, accessUpdate: Api.Request.AccessControlListUpdate) => Promise<void>
}

export default class AccessPage<T extends { myAccess: UserAccess }> extends React.PureComponent<IProps<T>> {

    public loadPrincipals = () => {
        return loadPrincipals(this.props.company)
    }

    public getAcl = () => {
        return this.props.getEntityAcl(this.props.entity)
    }

    public saveAccessUpdate = async (accessUpdate: Api.Request.AccessControlListUpdate) => {
        try {
            await this.props.saveAccessUpdate(this.props.entity, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    public render() {
        return (
            <Container fluid className="mt-3">
                <Card className="mb-3">
                    <CardHeader><FA icon="lock" /> {this.props.name} Access Control</CardHeader>
                    <CardBody>
                        <AccessControlTable getAcl={this.getAcl} commitUpdates={this.saveAccessUpdate} loadPrincipals={this.loadPrincipals} disabled={!this.props.entity.myAccess.isAdministrator} />
                    </CardBody>
                </Card>
            </Container>
        )
    }
}
