import React from 'react'
import { Button, Col, Row, Table } from 'reactstrap'

import ACRow from '@src/components/access/AccessControlTableRow'
import FA from '@src/components/common/FontAwesomeIcon'
import Select, { IGroupedOptions, IOption } from '@src/components/common/Select'
import NotificationService from '@src/logic/notification/NotificationService'
import { toCapitalizedWords } from '@src/logic/utils/Strings'
import { AccessControlList, AclEntry } from '@src/types/access'
import { Api } from '@src/types/api'

interface IProps {
    getAcl: () => Promise<AccessControlList>
    commitUpdates: (update: Api.Request.AccessControlListUpdate) => Promise<boolean>
    loadPrincipals?: () => Promise<AclEntry[]>
    disabled?: boolean
}

interface IState {
    availablePrincipals: AclEntry[]
    validOperations: string[]
    originalEntries: AclEntry[]
    updatedEntries: AclEntry[]
    dirty: boolean
}

export function calculateDiff(before: AclEntry[], after: AclEntry[]): Api.Request.AccessControlListUpdate {
    const aclUpdate: Api.Request.AccessControlListUpdate = {
        grant: {},
        revoke: {},
        deny: {},
        undeny: {},
        addAdmin: [],
        removeAdmin: []
    }

    after.forEach((update) => {
        const original = before.find(x => x.id === update.id)

        if (original == null) {
            if (update.grants.length) { aclUpdate.grant[update.id] = [...update.grants] }
            if (update.denials.length) { aclUpdate.deny[update.id] = [...update.denials] }
            if (update.isAdministrator) { aclUpdate.addAdmin.push(update.id) }
            return
        }

        const toGrant = update.grants.filter(x => !original.grants.includes(x))
        const toRevoke = original.grants.filter(x => !update.grants.includes(x))
        const toDeny = update.denials.filter(x => !original.denials.includes(x))
        const toUndeny = original.denials.filter(x => !update.denials.includes(x))

        if (toGrant.length) {
            aclUpdate.grant[update.id] = toGrant
        }

        if (toRevoke.length) {
            aclUpdate.revoke[update.id] = toRevoke
        }

        if (toDeny.length) {
            aclUpdate.deny[update.id] = toDeny
        }

        if (toUndeny.length) {
            aclUpdate.undeny[update.id] = toUndeny
        }

        if (update.isAdministrator && !original.isAdministrator) {
            aclUpdate.addAdmin.push(update.id)
        } else if (!update.isAdministrator && original.isAdministrator) {
            aclUpdate.removeAdmin.push(update.id)
        }
    })

    return aclUpdate
}

export default class AccessControlTable extends React.Component<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            availablePrincipals: null,
            validOperations: null,
            originalEntries: null,
            updatedEntries: null,
            dirty: false
        }
    }

    public componentDidMount() {
        this.refreshAclAndTable()
    }

    private readonly refreshAclAndTable = () => {
        this.props.getAcl()
            .then(res => this.setState({ validOperations: res.validOperations, originalEntries: res.acl, updatedEntries: res.acl.sort(this.sortPrincipals), dirty: false }))
        this.props.loadPrincipals().then(res => this.setState({ availablePrincipals: res }))
    }

    private readonly resetChanges = () => {
        this.setState({
            updatedEntries: [...this.state.originalEntries],
            dirty: false
        })
    }

    private readonly calculateAclUpdateAndCommit = () => {
        const aclUpdate = calculateDiff(this.state.originalEntries, this.state.updatedEntries)
        const notification = NotificationService.info('Updating permissions for revision...', { autoClose: false })
        this.props.commitUpdates(aclUpdate)
            .then((success) => {
                if (success) {
                    NotificationService.updateToast(notification, 'Successfully updated permissions', { autoClose: 3000 })
                } else {
                    NotificationService.updateToast(notification, 'There was an issue while trying to save permissions.', { autoClose: 3000, type: 'error' })
                }
                this.refreshAclAndTable()
            })
            .catch(() => NotificationService.updateToast(notification, 'There was an issue while trying to save permissions.', { autoClose: 3000, type: 'error' }))
    }

    private readonly getPrincipalEntry = (id: string): AclEntry => this.state.updatedEntries.find(x => x.id === id)

    private readonly getPrincipalIndex = (id: string): number => this.state.updatedEntries.findIndex(x => x.id === id)

    private readonly renderPermissionsHeader = () => {
        const permissionCells = this.state.validOperations.map(o => <th key={o} scope="col">{toCapitalizedWords(o)}</th>)

        return (
            <thead>
                <tr>
                    <th style={{ width: 350 }} />
                    <th scope="col" className="permission-table__apply-all">Apply All</th>
                    {permissionCells}
                </tr>
            </thead>
        )
    }

    private readonly handleToggleDeny = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x)), ...operations],
            grants: [...principal.grants.filter(x => !operations.includes(x))]
        }

        const updatedEntries = [...this.state.updatedEntries]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.setState({ updatedEntries, dirty: true })
    }

    private readonly handleToggleNeutral = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x))],
            grants: [...principal.grants.filter(x => !operations.includes(x))]
        }

        const updatedEntries = [...this.state.updatedEntries]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.setState({ updatedEntries, dirty: true })
    }

    private readonly handleToggleGrant = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x))],
            grants: [...principal.grants.filter(x => !operations.includes(x)), ...operations]
        }

        const updatedEntries = [...this.state.updatedEntries]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.setState({ updatedEntries, dirty: true })
    }

    private readonly handleToggleAdministrator = (principalId: string) => {
        const principal = this.getPrincipalEntry(principalId)
        const updatedPrincipal: AclEntry = {
            ...principal,
            isAdministrator: !principal.isAdministrator
        }

        const updatedEntries = [...this.state.updatedEntries]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.setState({ updatedEntries, dirty: true })
    }

    private sortPrincipals(a: AclEntry, b: AclEntry) {
        const aValue = (a.name + (a.email ? a.email : a.id)).toLowerCase()
        const bValue = (b.name + (b.email ? b.email : b.id)).toLowerCase()

        if (aValue < bValue) { return -1 }
        if (aValue > bValue) { return 1 }

        return 0
    }

    private renderEntriesSection(sectionName: string, entries: AclEntry[], handleAdminChanges: boolean) {
        return (
            <>
                <tr key={sectionName} className="permission-table__group-heading">
                    <th scope="row">{sectionName}</th>
                    <th colSpan={this.state.validOperations.length + 1} />
                </tr>
                {entries.map((e, idx) =>
                    <ACRow
                        key={e.id}
                        principal={e}
                        validOperations={this.state.validOperations}
                        onDeny={this.handleToggleDeny}
                        onRevokeOrUndeny={this.handleToggleNeutral}
                        onGrant={this.handleToggleGrant}
                        onToggleAdmin={handleAdminChanges ? this.handleToggleAdministrator : null}
                        disabled={this.props.disabled}
                    />
                )}
            </>
        )
    }

    private readonly formatOptions = (): IGroupedOptions<IOption<string>> => {
        const { availablePrincipals, updatedEntries } = this.state

        if (availablePrincipals === null) { return [] }

        const principalsNotOnAcl = availablePrincipals.filter(p => !updatedEntries.find(x => x.id === p.id))

        const optionGroups: IGroupedOptions<IOption<string>> = []
        const companies: AclEntry[] = []; const groups: AclEntry[] = []; const users: AclEntry[] = []
        principalsNotOnAcl.forEach((p) => {
            switch (p.type) {
                case 'company':
                    companies.push(p)
                    break
                case 'group':
                    groups.push(p)
                    break
                case 'user':
                    users.push(p)
                    break
            }
        })

        if (companies.length > 0) {
            optionGroups.push({ label: 'Company', options: companies.map<IOption<string>>(c => ({ label: c.name, value: c.id, principal: c })) })
        }

        if (groups.length > 0) {
            optionGroups.push({ label: 'Groups', options: groups.map<IOption<string>>(g => ({ label: g.name, value: g.id, principal: g })) })
        }

        if (users.length > 0) {
            optionGroups.push({ label: 'Users', options: users.map<IOption<string>>(u => ({ label: u.name, value: u.id, principal: u })) })
        }

        return optionGroups
    }

    private readonly handleAddPrincipal = (option: IOption<string> | IOption<string>[]) => {
        const updatedEntries = [...this.state.updatedEntries, option instanceof Array ? option.map(o => o.principal) : option.principal].sort(this.sortPrincipals)
        this.setState({ updatedEntries })
    }

    public render() {
        const { disabled } = this.props
        const { availablePrincipals, updatedEntries, dirty } = this.state

        if (updatedEntries == null) {
            return null
        }

        return (
            <div>
                {!disabled && <Row className="mb-3">
                    <Col className="mb-2 mb-md-auto" xs={12} md={8} lg={6}>
                        <Select
                            isLoading={availablePrincipals === undefined}
                            onChange={this.handleAddPrincipal}
                            options={this.formatOptions()}
                            value={undefined}
                            placeholder="Add user, groups, or your company to the ACL"
                        />
                    </Col>
                    <Col xs={12} md={4} lg={6}>
                        <div className="float-right">
                            <Button disabled={!dirty} className="mr-2" color="info" onClick={this.resetChanges}><FA icon="trash" /> <span className="d-none d-md-inline">Discard<span className="d-none d-lg-inline"> Changes</span></span></Button>
                            <Button onClick={this.calculateAclUpdateAndCommit} disabled={!dirty} color="tertiary"><FA icon="save" /> <span className="d-none d-md-inline">Save<span className="d-none d-lg-inline"> Changes</span></span></Button>
                        </div>
                    </Col>
                </Row>}
                <Table className="permission-table" responsive bordered>
                    {this.renderPermissionsHeader()}
                    <tbody>
                        {this.renderEntriesSection('Companies', updatedEntries.filter(x => x.type === 'company'), false)}
                        {this.renderEntriesSection('Groups', updatedEntries.filter(x => x.type === 'group'), true)}
                        {this.renderEntriesSection('Users', updatedEntries.filter(x => x.type === 'user'), true)}
                    </tbody>
                </Table>
            </div>
        )
    }
}
