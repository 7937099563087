import React from 'react'
import { FormFeedback, Input } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import BigNumber from 'bignumber.js'

import { formatCurrency, removeAllNonPeriodDigit } from '@src/logic/utils/Currency'

interface IState {
    value: string
}

export default class ValidatedCurrencyInput extends React.PureComponent<WrappedFieldProps, IState> {

    private readonly inputRef: React.RefObject<HTMLInputElement>

    constructor(props: WrappedFieldProps) {
        super(props)

        this.inputRef = React.createRef()

        this.state = {
            value: this.formattedFormValue()
        }
    }

    public componentDidUpdate(prevProps: WrappedFieldProps) {
        if (document.activeElement.id !== this.props.input.name && this.props.input.value !== prevProps.input.value) {
            this.setState({ value: this.formattedFormValue() })
        }
    }

    private formattedFormValue = () => {
        const value = new BigNumber(removeAllNonPeriodDigit(this.props.input.value))
        return formatCurrency(value.isNaN() ? new BigNumber(0) : value, false)
    }

    private handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value != null ? new BigNumber(removeAllNonPeriodDigit(e.currentTarget.value)).decimalPlaces(2).toNumber() : 0
        this.setState({ value: formatCurrency(!isNaN(value) ? value : 0, false) }, () => this.props.input.onBlur(!isNaN(value) ? value : 0))
    }

    private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value != null ? new BigNumber(removeAllNonPeriodDigit(e.currentTarget.value)).decimalPlaces(2).toNumber() : 0
        this.setState({ value: e.currentTarget.value }, () => this.props.input.onChange(!isNaN(value) ? value : 0))
    }

    public render() {
        const { input, meta: { touched, error, active }, ...htmlInputProps } = this.props
        const showError = touched && !!error

        return (
            <>
                <Input id={input.name} className="text-right" {...htmlInputProps} {...input} innerRef={this.inputRef} onBlur={this.handleBlur} onChange={this.handleChange} invalid={showError} value={document.activeElement.id === input.name ? this.state.value : this.formattedFormValue()} />
                <FormFeedback>{showError && error}</FormFeedback>
            </>
        )
    }
}
