import React from 'react'
import { FormatOptionLabelMeta } from 'react-select/base'
import { UncontrolledTooltip } from 'reactstrap'

import cx from 'classnames'

import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import { Template, TemplateBrief } from '@src/types/communication'
import { BudgetStatusDefinition, Commitment, CommitmentBrief, CommitmentDefinition, CommitmentItem, CommitmentReference, CommitmentStatusDefinition, CostCode, CostCodeBrief, CostReportSnapshot, Phase } from '@src/types/costs'
import { DocumentLink, Revision } from '@src/types/document'
import { Group, PrincipalBrief, UserBasic } from '@src/types/principal'
import { CollaboratorBasic, Project } from '@src/types/project'
import { TransmittalAddress } from '@src/types/transmittal'

export function trueFilter() { return true }

type SimpleValue = string | number

export function formatSimpleValue(value?: SimpleValue | SimpleValue[]) {
    if (Array.isArray(value)) {
        return value.map(x => ({ value: x, label: x }))
    }
    return value != null ? { value, label: value } : null
}

export function parseOptionToSimpleValue<T>(option?: IOption<T>): T;
export function parseOptionToSimpleValue<T>(option?: IOption<T>[]): T[];
export function parseOptionToSimpleValue<T>(option?: IOption<T> | IOption<T>[]): T | T[] {
    if (Array.isArray(option)) {
        return option.map(x => x.value)
    }
    return option?.value
}

export function budgetStatusLabel(status: BudgetStatusDefinition) {
    return status.code
}

export function budgetStatusValue(status: BudgetStatusDefinition & { isAdjustment: boolean }) {
    return status.isAdjustment + status.code
}

export function commitmentLabel(commitment: CommitmentBrief | Commitment | CommitmentReference) {
    return commitment.name
}

export function commitmentValue(commitment: CommitmentBrief | Commitment | CommitmentReference) {
    return commitment.id
}

export function commitmentItemLabel(commitmentItem: CommitmentItem) {
    return commitmentItem.name
}

export function commitmentItemValue(commitmentItem: CommitmentItem) {
    return commitmentItem.id
}

export function commitmentStatusLabel(status: CommitmentStatusDefinition) {
    return status.code
}

export function commitmentStatusValue(status: CommitmentStatusDefinition) {
    return status.code
}

export function commitmentTypeLabel(type: CommitmentDefinition) {
    return type.name
}

export function commitmentTypeValue(type: CommitmentDefinition) {
    return type.code
}

export function costCodeLabel(costCode: CostCode | CostCodeBrief) {
    return `${costCode.code} ${costCode.name}`
}

export function costCodeValue(costCode: CostCode | CostCodeBrief) {
    return costCode.code
}

export function costSnapshotLabel(costSnapshot: CostReportSnapshot) {
    return costSnapshot.name
}

export function costSnapshotValue(costSnapshot: CostReportSnapshot) {
    return costSnapshot.id
}

export function documentLinkLabel(documentLink: DocumentLink) {
    return documentLink.name
}

export function documentLinkValue(documentLink: DocumentLink) {
    return documentLink.revisionId
}

export function groupLabel(group: Group) {
    return group.name
}

export function groupValue(group: Group) {
    return group.id
}

export function formatGroup(group: Group, labelMeta: FormatOptionLabelMeta<Group>) {
    const formattedGroupId = group.id.replace('|', '_')
    return <span>{group.adminGroup && <><FA id={`select-admingroup-${formattedGroupId}`} className="text-warning" icon="star"/><UncontrolledTooltip target={`select-admingroup-${formattedGroupId}`}>Company admin group</UncontrolledTooltip></>} {group.name}</span>
}

export function phaseLabel(phase: Phase) {
    return phase.name
}

export function phaseValue(phase: Phase) {
    return phase.code
}

export function isPhaseLocked(phase: Phase) {
    return phase.locked
}

export function revisionLabel(revision: Revision) {
    return revision.name
}

export function revisionValue(revision: Revision) {
    return revision.id
}

export function templateLabel(template: Template | TemplateBrief) {
    return template.name
}

export function templateValue(template: Template | TemplateBrief) {
    return template.id
}

export function principalBriefValue(principal: PrincipalBrief) {
    return principal.id
}

export function principalBriefLabel(principal: PrincipalBrief) {
    return principal.name
}

export function userBasicValue(user: UserBasic) {
    return user.id
}

export function userBasicLabel(user: UserBasic) {
    return `${user.firstName} ${user.lastName}`
}

export function userBasicLabelFormat(user: UserBasic, labelMeta: FormatOptionLabelMeta<UserBasic>) {
    return (
        <span className="py-2">
            <UserAvatar size="xs" imageUrl={user.profilePictureLink} firstName={user.firstName} lastName={user.lastName} />&nbsp;
            {user.firstName} {user.lastName} <small className={cx({ 'text-muted': labelMeta.context === 'value' || ((labelMeta.selectValue as []).length === 0 || (labelMeta.selectValue as UserBasic[])[0].id !== user.id) })}>({user.email})</small>
        </span>
    )
}

export function collaboratorLabel(collaborator: CollaboratorBasic) {
    return collaborator.name
}

export function collaboratorValue(collaborator: CollaboratorBasic) {
    return collaborator.id
}

export function transmittalAddressLabel(user: TransmittalAddress) {
    return user.name ? user.name : user.email
}

export function transmittalAddressValue(user: TransmittalAddress) {
    return user.id != null ? user.id : user.email
}

export function projectLabel(project: Project) {
    return project.name
}

export function projectValue(project: Project) {
    return project.id
}
