import React from 'react'
import { Pagination as BootstrapPagination, PaginationItem, PaginationLink } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    currentPage: number
    perPage: number
    totalCount: number
    pageRangeDisplayed?: number
    marginPagesCount?: number
    onSelectPage: (page: number) => void
}

export default class Pagination extends React.PureComponent<IProps> {

    public static defaultProps: Partial<IProps> = {
        pageRangeDisplayed: 3,
        marginPagesCount: 1
    }

    private previousDisabled = () => this.props.currentPage === 1
    private nextDisabled = () => this.props.currentPage === this.totalPages()

    private previousPage = () => {
        if (this.previousDisabled()) { return }
        this.props.onSelectPage(this.props.currentPage - 1)
    }

    private nextPage = () => {
        if (this.nextDisabled()) { return }
        this.props.onSelectPage(this.props.currentPage + 1)
    }

    private totalPages = () => Math.max(1, Math.ceil(this.props.totalCount / this.props.perPage))

    private renderPageNumbers = () => {
        const { currentPage, pageRangeDisplayed, perPage, totalCount, marginPagesCount } = this.props
        const totalPages = this.totalPages()
        const items = []

        if (totalPages <= pageRangeDisplayed) {

            for (let index = 1; index < totalPages + 1; index++) {
                items.push(<Page key={index} active={currentPage === index} value={index} onClick={this.props.onSelectPage}/>)
            }

        } else {

            let leftSide = Math.ceil(pageRangeDisplayed / 2)
            let rightSide = pageRangeDisplayed - leftSide

            if (currentPage > totalPages - pageRangeDisplayed / 2) {
                rightSide = totalPages - currentPage
                leftSide = pageRangeDisplayed - rightSide
            } else if (currentPage < pageRangeDisplayed / 2) {
                leftSide = currentPage
                rightSide = pageRangeDisplayed - leftSide
            }

            let index: number
            let page: number

            for (index = 0; index < totalPages; index++) {

                page = index + 1

                if (page <= marginPagesCount && page < currentPage) {
                    items.push(<Page key={index} className={page === marginPagesCount ? 'page-item--start' : ''} active={currentPage === page} value={page} onClick={this.props.onSelectPage}/>)
                    continue
                }

                if (page > totalPages - marginPagesCount && page > currentPage) {
                    items.push(<Page key={index} className={index === totalPages - marginPagesCount ? 'page-item--end' : ''} active={currentPage === page} value={page} onClick={this.props.onSelectPage}/>)
                    continue
                }

                if ((page >= currentPage - leftSide) && (page <= currentPage + rightSide + 1)) {
                    items.push(<Page key={index} active={currentPage === page} value={page} onClick={this.props.onSelectPage}/>)
                    continue
                }
            }
        }

        return items
    }

    public render() {
        return (
            <BootstrapPagination listClassName="page-navigation__pagination">
                <PaginationItem disabled={this.previousDisabled()} onClick={this.previousPage}>
                    <PaginationLink><FA icon="chevron-left" /></PaginationLink>
                </PaginationItem>
                {this.renderPageNumbers()}
                <PaginationItem disabled={this.nextDisabled()} onClick={this.nextPage}>
                    <PaginationLink><FA icon="chevron-right" /></PaginationLink>
                </PaginationItem>
            </BootstrapPagination>
        )
    }
}

interface IPageProps {
    value: number
    active: boolean
    onClick: (page: number) => void
    className?: string
}

// tslint:disable-next-line:max-classes-per-file
class Page extends React.PureComponent<IPageProps> {

    private onClick = () => this.props.onClick(this.props.value)

    public render() {
        return (
            <PaginationItem className={this.props.className} active={this.props.active} onClick={this.onClick}>
                <PaginationLink>{this.props.value}</PaginationLink>
            </PaginationItem>
        )
    }
}
