import React from 'react'
import { Form, FormProps } from 'react-final-form'
import { Button, Col, Container, Form as BootstrapForm, Row } from 'reactstrap'

import { FormApi } from 'final-form'

import FA from '@src/components/common/FontAwesomeIcon'
import ProgressTrack from '@src/components/common/ProgressTrack'
import { IWizardPageProps } from '@src/components/forms/WizardPage'

interface IProps<T extends {}> extends FormProps<T> {
    style?: React.CSSProperties
    className?: string
    children?: React.ReactElement<IWizardPageProps<T>>[]
    onPageChange?: (page: number) => void
    fluid?: boolean
}

export default function Wizard<T>(props: IProps<T>) {

    const [page, setPage] = React.useState(0)
    const [values, setValues] = React.useState<T>(props.initialValues || {} as any)

    const activePage = React.Children.toArray(props.children)[page] as React.ReactElement<IWizardPageProps<T>>
    const isLastPage = page === React.Children.toArray(props.children).length - 1

    function handlePageChange(newPage: number) {
        setPage(newPage)
        if (props.onPageChange) props.onPageChange(newPage)
    }

    function handleProgressTrack(values: T) {
        return (toPage: number) => {
            if (toPage < page) {
                handlePageChange(toPage)
            } else {
                let travelTo = page
                while (travelTo < toPage) {
                    if (props.children[travelTo].props.validate(values)) {
                        travelTo++
                    } else {
                        break
                    }
                }

                handlePageChange(travelTo)
            }
        }
    }

    function next(updatedValues: T) {
        setValues(updatedValues)
        handlePageChange(Math.min(page + 1, props.children.length - 1))
    }

    function previous() {
        handlePageChange(Math.max(page - 1, 0))
    }

    function validate(pageValues: T) {
        return activePage.props.validate ? activePage.props.validate(pageValues) : undefined
    }

    async function handleSubmit(formValues: T, form: FormApi<T>) {
        if (!activePage.props.submit && !isLastPage) {
            return next(formValues)
        }

        const submitResult = props.onSubmit(formValues, form)

        if (submitResult instanceof Promise) {
            const res = await submitResult
            if (res == null || Object.keys(res).length === 0) {
                next(formValues)
                return res
            }

            return res
        }

        return submitResult == null || (submitResult instanceof Object && Object.keys(submitResult).length === 0) ?
            next(formValues) :
            submitResult
    }

    const { initialValues, validate: handleValidate, onSubmit, children, className, style, fluid, ...rest } = props
    return (
        <Form<T>
            initialValues={values}
            validate={validate}
            onSubmit={handleSubmit}
            {...rest}
        >
            {({ handleSubmit: formHandleSubmit, submitting, values: formValues }) => (
                <Container fluid={fluid} tag={BootstrapForm} onSubmit={formHandleSubmit} className={className} style={style}>
                    <Row>
                        <Col>
                            <ProgressTrack
                                stages={React.Children.toArray(props.children).map((c, idx) => ({ value: idx, label: (c as React.ReactElement<IWizardPageProps<T>>).props.name }))}
                                activeStage={page}
                                onSelectStage={handleProgressTrack(formValues)}
                            />
                            <hr />
                        </Col>
                    </Row>
                    <Row className="mb-3">
                        <Col>
                            {activePage}
                        </Col>
                    </Row>
                    {!activePage.props.hideNavigation && <Row className="mb-5">
                        <Col>
                            {page > 0 && <Button color="tertiary" type="button" onClick={previous}><FA icon="chevron-left" className="mr-2" />{activePage.props.previousLabel || 'Previous'}</Button>}
                        </Col>
                        <Col className="ml-auto justify-content-end d-flex align-items-end">
                            <Button color={activePage.props.submit || isLastPage ? 'success' : 'tertiary'} disabled={submitting} onClick={formHandleSubmit} type={'button'}>
                                {activePage.props.nextLabel || (activePage.props.submit || isLastPage ? 'Submit' : 'Next')}<FA className="ml-2" icon={activePage.props.submit || isLastPage ? 'check-circle' : 'chevron-right'} />
                            </Button>
                        </Col>
                    </Row>}
                </Container>
            )}
        </Form>
    )
}
