// tslint:disable:max-classes-per-file

export class DifferentChunkError extends Error {
    private chunkIndex: number
    private originalChecksum: string
    private newChecksum: string

    constructor(chunkIndex, originalChecksum, newChecksum) {
        super(`Chunk at index '${chunkIndex}' is different to original`)
        this.chunkIndex = chunkIndex
        this.originalChecksum = originalChecksum
        this.newChecksum = newChecksum
    }
}

export class FileAlreadyUploadedError extends Error {
    constructor(id, url) {
        super(`File '${id}' has already been uploaded to unique url '${url}'`)
    }
}

export class UrlNotFoundError extends Error {
    constructor(url) {
        super(`Upload URL '${url}' has either expired or is invalid`)
    }
}

export class UploadFailedError extends Error {
    constructor(status) {
        super(`HTTP status ${status} received from GCS, consider retrying`)
    }
}

export class UnknownResponseError extends Error {
    private res: any

    constructor(res) {
        super('Unknown response received from GCS')
        this.res = res
    }
}

export class MissingOptionsError extends Error {
    constructor() {
        super('Missing options for Upload')
    }
}

export class UploadIncompleteError extends Error {
    constructor() {
        super('Upload is not complete')
    }
}

export class InvalidChunkSizeError extends Error {
    constructor(chunkSize) {
        super(`Invalid chunk size ${chunkSize}, must be a multiple of 262144`)
    }
}

export class UploadAlreadyFinishedError extends Error {
    constructor() {
        super('Upload instance has already finished')
    }
}
