import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Badge, Button, Input, InputGroup, InputGroupAddon, InputGroupText, UncontrolledTooltip } from 'reactstrap'
import { bindActionCreators, Dispatch } from 'redux'

import { CancelToken } from 'axios'

import * as WidgetActions from '@src/actions/widget'
import { UserAvatar } from '@src/components/common/Avatar'
import FA from '@src/components/common/FontAwesomeIcon'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import { ProjectOperations } from '@src/logic/auth/operations'
import { ProjectInvite, ProjectUsersList } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import { mutedNotSet, mutedValue } from '@src/logic/utils/ValueHelper'
import { getProjectState } from '@src/reducers/widget'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IConnectedState {
    widgetUsersIds: string[]
}

interface IConnectedDispatch {
    widgetActions: typeof WidgetActions
}

interface IProps extends RouteComponentProps {
    project: Project
}

interface IState {
    inviteUserEmail: string
}

class UsersSection extends React.PureComponent<IProps & IConnectedState & IConnectedDispatch, IState> {
    private readonly userSearchSectionRef: React.RefObject<SearchSectionType<UserBasic, never>>

    constructor(props) {
        super(props)

        this.userSearchSectionRef = React.createRef()

        this.state = {
            inviteUserEmail: ''
        }
    }

    private readonly searchUsers = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken) => {
        const response = await ProjectUsersList(this.props.project.id, filter, sort, page, perPage, { cancelToken })
        return ({ items: response.data, totalItems: response.headers[Headers.PaginationTotalCount] })
    }

    private readonly inviteUser = async () => {
        const email = this.state.inviteUserEmail
        try {
            await ProjectInvite(this.props.project.id, email)
            NotificationService.info(`Sent invitation to ${email}`)
            this.setState({ inviteUserEmail: '' })
        } catch (err) {
            NotificationService.error(`There was an issue sending an invite to ${email}`)
        }

        this.userSearchSectionRef.current.doSearch()
    }

    private readonly handleInviteEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ inviteUserEmail: e.target.value })
    }

    private readonly handleUsersSelected = (...users: UserBasic[]) => {
        const selectedIds = [...this.props.widgetUsersIds]
        this.props.widgetActions.addUsers({ projectId: this.props.project.id, entities: users.filter(u => !selectedIds.includes(u.id)) })
        this.props.widgetActions.removeUsers({ projectId: this.props.project.id, entityIds: users.filter(u => selectedIds.includes(u.id)).map(u => u.id) })
    }

    private readonly handleEnterQuickInvite = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') this.inviteUser()
    }

    public render() {
        const { project } = this.props

        const canInvite = isAuthorised(project.myAccess, ProjectOperations.Update)

        const quickInvite = (
            <InputGroup>
                <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="user-plus" /></InputGroupText></InputGroupAddon>
                <Input disabled={!canInvite} onKeyDown={this.handleEnterQuickInvite} placeholder="example@example.com" value={this.state.inviteUserEmail} onChange={this.handleInviteEmailChange} />
                <InputGroupAddon addonType="append" id="invite-to-project" tag="div"><Button style={{ pointerEvents: !canInvite ? 'none' : 'initial' }} onClick={this.inviteUser} disabled={!canInvite}>Quick Invite</Button></InputGroupAddon>
                {!canInvite && <UncontrolledTooltip target="invite-to-project">Requires update permission on project</UncontrolledTooltip>}
            </InputGroup>
        )

        return (
            <SearchSection<UserBasic, 'id'>
                ref={this.userSearchSectionRef}
                headers={[
                    {
                        name: 'Name',
                        overrideRenderer: u => (
                            <span>
                                <UserAvatar size="xs" imageUrl={u.profilePictureLink} firstName={u.firstName} lastName={u.lastName} />
                                <span className="ml-2">{`${u.firstName || ''} ${u.lastName || ''}`.trim() || mutedValue('(not set)')}</span>
                            </span>
                        )
                    },
                    {
                        name: 'Company',
                        sortKey: 'company',
                        sortable: true,
                        overrideRenderer: u => u.company ? u.company.name : mutedValue('Not Registered')
                    },
                    {
                        name: 'Role',
                        accessor: 'role',
                        sortKey: 'role',
                        sortable: true,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Email',
                        accessor: 'email',
                        sortKey: 'email',
                        sortable: true,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Mobile',
                        accessor: 'mobile',
                        sortKey: 'mobile',
                        sortable: true,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Phone',
                        accessor: 'phone',
                        sortKey: 'phone',
                        sortable: true,
                        defaultFallback: mutedNotSet
                    },
                    {
                        name: 'Status',
                        sortKey: 'status',
                        sortable: true,
                        overrideRenderer: user => <Badge pill>{user.status}</Badge>
                    }
                ]}
                extraSearchBarElements={[{
                    element: onSearch => quickInvite,
                    position: 'after'
                }]}
                onSearch={this.searchUsers}
                itemIdKey="id"
                selectedItems={this.props.widgetUsersIds}
                onItemsSelected={this.handleUsersSelected}
                searchAssistantProperties={[
                    {
                        name: 'First name',
                        searchKey: 'first_name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Last name',
                        searchKey: 'last_name',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Email',
                        searchKey: 'email',
                        type: PropertyType.Text
                    }
                ]}
            />
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        widgetUsersIds: getProjectState(state.widget, ownProps.project.id).users.map(u => u.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        widgetActions: bindActionCreators(WidgetActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UsersSection)
