import React from 'react'
import { useField, useForm } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps } from 'react-final-form-arrays'
import { OnChange } from 'react-final-form-listeners'
import { Card, Col, Container, Row } from 'reactstrap'

import axios from 'axios'

import FA from '@src/components/common/FontAwesomeIcon'
import LoadingCard from '@src/components/common/LoadingCard'
import { buildOptions } from '@src/components/common/Select'
import ToggleButton from '@src/components/common/ToggleButton'
import { FieldPrefix } from '@src/components/forms/FieldPrefix'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxDocument, IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import { DocumentUpload } from '@src/components/sandbox/DocumentUpload'
import useBoolean from '@src/hooks/useBoolean'
import { DocumentsList } from '@src/logic/http/Api'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { IMetadataDefinition } from '@src/types/metadata'
import { Sandbox } from '@src/types/sandbox'

const MetadataStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {

    const showOptionalMetadata = useBoolean(true)
    const form = useForm<IDocumentSandboxWizardForm>()
    const metadataDefinitionsField = useField<IMetadataDefinition[]>('metadataDefinitions')
    const metadataDefinitions = metadataDefinitionsField.input.value && !showOptionalMetadata.value ? metadataDefinitionsField.input.value.filter(x => x.isRequired) : metadataDefinitionsField.input.value

    React.useEffect(
        () => {
            const cts = axios.CancelToken.source()
            DocumentsList(form.getState().values.project.id, undefined, undefined, undefined, 1, 0, { cancelToken: cts.token })
                .then((response) => {
                    form.change('metadataDefinitions', response.data.metadataDefinitions)
                })
            return cts.cancel
        },
        []
    )

    function getInitialDocumentName(document: IDocumentSandboxDocument, upload: Sandbox.Upload) {
        if (document.existingRevision) return document.existingRevision.name
        const fileNameParts = upload.filename.split('.')
        return fileNameParts.length > 1 ? fileNameParts.slice(0, -1).join('.') : fileNameParts[0]
    }

    function buildGlobalChange(field: string, documents: IDocumentSandboxDocument[]): (value, oldValue) => void {
        return (value) => {
            form.batch(() => {
                documents.forEach((d, didx) =>
                    d.uploads.forEach((u, uidx) => {
                        form.change(`documents[${didx}].uploads[${uidx}].data.${field}`, value)
                    })
                )
            })
        }
    }

    function renderUploads(arrayProps: FieldArrayRenderProps<IDocumentSandboxDocument, any>) {
        let numberOfUploads = 0
        const uploads = arrayProps.fields.map((documentField, idx) => {
            const document = arrayProps.fields.value[idx]
            numberOfUploads += document.uploads.length
            return document.uploads.length > 0 ? (
                <FieldPrefix prefix={documentField} key={document.id}>
                    <Card body className="mb-3">
                        <h4 className="border-bottom mb-3 pb-2">{document.existingRevision ? document.existingRevision.name : 'New Document'}</h4>
                        <FieldArray name="uploads" subscription={{}} value={document.uploads}>
                            {() => document.uploads.map((upload, uidx) => (
                                <FieldPrefix key={upload.id} prefix={`uploads[${uidx}].data`}>
                                    <DocumentUpload
                                        title={
                                            <span>
                                                <FA className="mr-1" icon={contentTypeIcon(document.uploads[uidx].contentType)} />
                                                {document.existingRevision ? document.existingRevision.name : document.uploads[uidx].filename}
                                                <em className="text-muted">&nbsp;(Rev. {uidx + 1})</em>
                                            </span>
                                        }
                                        metadataDefinitions={metadataDefinitions}
                                        initialValues={document.existingRevision ? {
                                            author: document.existingRevision.author,
                                            description: document.existingRevision.description,
                                            metadata: document.existingRevision.metadata,
                                            name: document.existingRevision.name,
                                            revDate: document.existingRevision.revDate,
                                            revNumber: document.existingRevision.revNumber,
                                            tags: React.useMemo(() => buildOptions(document.existingRevision.tags), [document.existingRevision.tags])
                                        } : {
                                            name: getInitialDocumentName(document, upload)
                                        }}
                                    />
                                </FieldPrefix>
                            ))}
                        </FieldArray>
                    </Card>
                </FieldPrefix>
            ) : undefined
        }).filter(x => x !== undefined)

        const globalCard = (
            <FieldPrefix prefix="global">
                <Card body className="mb-3">
                    <h4 className="border-bottom mb-3 pb-2 d-flex align-items-center">Global <em className="ml-1 text-muted">(set metadata for all uploads)</em></h4>
                    <DocumentUpload noValidate metadataDefinitions={metadataDefinitions} />
                </Card>
                <OnChange name="global.name">
                    {buildGlobalChange('name', arrayProps.fields.value)}
                </OnChange>
                <OnChange name="global.version">
                    {buildGlobalChange('version', arrayProps.fields.value)}
                </OnChange>
                <OnChange name="global.date">
                    {buildGlobalChange('date', arrayProps.fields.value)}
                </OnChange>
                <OnChange name="global.author">
                    {buildGlobalChange('author', arrayProps.fields.value)}
                </OnChange>
                <OnChange name="global.tags">
                    {buildGlobalChange('tags', arrayProps.fields.value)}
                </OnChange>
                {metadataDefinitions.map(md => (
                    <OnChange key={md.key} name={`global.metadata.${md.key}`}>
                        {buildGlobalChange(`metadata.${md.key}`, arrayProps.fields.value)}
                    </OnChange>
                ))}
            </FieldPrefix>
        )

        return numberOfUploads > 1 ? [globalCard, ...uploads] : uploads
    }

    return (
        <Container fluid className="mh-100 d-flex flex-column">
            <h4 className="mb-3">Set the metadata for your files</h4>
            <p>Use the forms below to set the metadata for your uploaded files. Have fields that are the same? Set these in the global row and the data will be copied across all of your documents.</p>
            <ToggleButton on={showOptionalMetadata.value} onToggle={showOptionalMetadata.toggle} label="Show optional metadata fields" />
            <Row className="mb-3">
                <Col>
                    {metadataDefinitions ? <FieldArray<IDocumentSandboxDocument> name="documents" render={renderUploads} /> : <LoadingCard />}
                </Col>
            </Row>
        </Container>
    )
}

export default MetadataStage
