// tslint:disable:variable-name
import { ParserRuleContext } from 'antlr4ts/ParserRuleContext'
import { AbstractParseTreeVisitor, ErrorNode, TerminalNode } from 'antlr4ts/tree'

import * as IQLFilterParser from '@src/components/search/IQL/IQLFilterParser'
import { IQLFilterVisitor } from '@src/components/search/IQL/IQLFilterVisitor'

export default class SerializeVisitor extends AbstractParseTreeVisitor<ParserRuleContext[]> implements IQLFilterVisitor<ParserRuleContext[]> {
    protected defaultResult(): any[] {
        return []
    }

    public visitNOT = (ctx: IQLFilterParser.NOTContext): ParserRuleContext[] => {
        return [ctx, ...this.visit(ctx.expr())]
    }

    public visitExprAND = (ctx: IQLFilterParser.ExprANDContext): ParserRuleContext[] => {
        return [...this.visit(ctx.expr(0)), ctx, ...this.visit(ctx.expr(1))]
    }

    public visitExprOR = (ctx: IQLFilterParser.ExprORContext): ParserRuleContext[] => {
        return [...this.visit(ctx.expr(0)), ctx, ...this.visit(ctx.expr(1))]
    }

    public visitText = (ctx: IQLFilterParser.TextContext): ParserRuleContext[] => {
        return [ctx]
    }

    public visitTextOrFilter = (ctx: IQLFilterParser.TextOrFilterContext) => {
        return this.visitChildren(ctx)
    }

    public visitEq = (ctx: IQLFilterParser.EqContext): ParserRuleContext[] => {
        return this.visitKeyOpValue(ctx)
    }

    public visitGt = (ctx: IQLFilterParser.GtContext): ParserRuleContext[] => {
        return this.visitKeyOpValue(ctx)
    }

    public visitLt = (ctx: IQLFilterParser.LtContext): ParserRuleContext[] => {
        return this.visitKeyOpValue(ctx)
    }

    public visitKeyOpValue = (ctx: IQLFilterParser.KeyOpValueContext): ParserRuleContext[] => {
        const leftCtx = ctx.tryGetChild(0, IQLFilterParser.Property_pathContext)
        const left = leftCtx ? this.visit(leftCtx) : []
        const rightCtx = ctx.tryGetChild(0, IQLFilterParser.ValueContext)
        const right = rightCtx ? this.visit(rightCtx) : []
        return left.concat(ctx).concat(right)
    }

    public visitProperty_path = (ctx: IQLFilterParser.Property_pathContext): ParserRuleContext[] => {
        return [ctx]
    }

    public visitValue = (ctx: IQLFilterParser.ValueContext): ParserRuleContext[] => {
        return [ctx]
    }

    public visitTerminal = (node: TerminalNode) => {
        return []
    }

    public visitErrorNode = (node: ErrorNode) => {
        return []
    }

    public visitIgnored?: (ctx: IQLFilterParser.IgnoredContext) => any[]
}
