import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, FormText, Label, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import FieldWarning from '@src/components/forms/FieldWarning'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { SharedDomains } from '@src/logic/company/domains'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { formatGroup, formatSimpleValue, groupLabel, groupValue, parseOptionToSimpleValue, userBasicLabel, userBasicLabelFormat, userBasicValue } from '@src/logic/forms/SelectHelpers'
import { CompanyMeSettingsGet, CompanyMeSettingsUpdate, CompanyUsersList, GroupsList } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { isValidDomain } from '@src/logic/utils/Strings'
import { Group, UserInternal } from '@src/types/principal'

interface ICompanyMembershipFormData {
    autoApproveDomains: string[]
    newUserApprovers: UserInternal[]
    newUserDefaultGroupsMembership: Group[]
}

export const MembershipSection: React.FC = () => {
    const asyncSettings = useAsyncCancellable(cancelToken => CompanyMeSettingsGet({ cancelToken }), [], { executeOnUpdate: false, setLoading: state => ({ ...state, loading: true }) })

    async function loadCompanyUsers(input: string): Promise<UserInternal[]> {
        const response = await CompanyUsersList(input, undefined, 1, 50)
        return response.data
    }

    async function loadGroupOptions(input: string): Promise<Group[]> {
        const response = await GroupsList(input, 1, 50)
        return response.data
    }

    async function updateCompanyMembershipSettings(values: ICompanyMembershipFormData) {
        try {
            await CompanyMeSettingsUpdate({
                autoApproveDomains: values.autoApproveDomains,
                newUserApprovers: values.newUserApprovers.map(u => u.id),
                newUserDefaultGroupsMembership: values.newUserDefaultGroupsMembership.map(u => u.id)
            })
        } catch (err) {
            if (isAxiosError(err) && err.response && err.response.status < 500) {
                switch (err.response.status) {
                    case 400:
                        return buildFormErrorsFromModelState(values, err.response.data)
                    case 403:
                        NotificationService.error('No permission to update membership settings')
                }
            } else {
                NotificationService.error('Unable to update membership settings')
            }
            return
        }

        NotificationService.info('Membership settings updated')
        await asyncSettings.execute()
    }

    function validateUsers(value: UserInternal[]) {
        if (value == null || value.length === 0) return 'Atleast one approver required'
    }

    function parseDomains(domains: IOption<string>[]) {
        if (domains == null) return []
        return [...new Set(parseOptionToSimpleValue(domains).map(x => x.toLowerCase()))]
    }

    function formatNewDomainLabel(input: string) {
        return `Add domain "${input}"`
    }

    function validateDomains(domains: string[]): string {
        if (domains == null) return

        if (!domains.every(d => isValidDomain(d))) {
            return 'Domain not valid format.'
        }
    }

    function warnSharedDomains(value: string[]) {
        const firstSharedDomain = value.find(domain => SharedDomains.includes(domain))

        return firstSharedDomain ? <span>It is strongly recommended that you do not auto approve shared domains like <strong>{firstSharedDomain}</strong></span> : null
    }

    return (
        <Form<ICompanyMembershipFormData>
            onSubmit={updateCompanyMembershipSettings}
            initialValues={asyncSettings.result ? {
                autoApproveDomains: asyncSettings.result.data.autoApproveDomains,
                newUserApprovers: asyncSettings.result.data.newUserApprovers,
                newUserDefaultGroupsMembership: asyncSettings.result.data.newUserDefaultGroupsMembership
            } : undefined}
        >
            {({ handleSubmit, submitting, pristine }) => (
                <Card>
                    <CardHeader className="d-flex align-items-center">
                        <span><FA icon="users-crown" /> Membership Admin</span>
                        <Button color="primary" className="ml-auto" onClick={handleSubmit} >
                            <FA icon={submitting ? 'spinner-third' : !pristine ? 'save' : 'check-circle'} spin={submitting} />
                            <span className="d-none d-md-inline-block">&nbsp;{submitting ? 'Saving...' : !pristine ? 'Save' : 'Saved'}</span>
                        </Button>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label for="autoApproveDomains">Automatically Approve Domains</Label>
                                    <Field
                                        id="autoApproveDomains"
                                        name="autoApproveDomains"
                                        component={ValidatedSelect}
                                        isMulti
                                        creatable
                                        format={formatSimpleValue}
                                        parse={parseDomains}
                                        validate={validateDomains}
                                        formatCreateLabel={formatNewDomainLabel}
                                    />
                                    <FieldWarning name="autoApproveDomains" shouldWarn={warnSharedDomains} />
                                    <FormText>Domains to automatically approve new accounts for.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>New User Approvers</Label>
                                    <Field
                                        name="newUserApprovers"
                                        validate={validateUsers}
                                        component={ValidatedSelect}
                                        async
                                        defaultOptions
                                        cacheOptions
                                        isMulti
                                        loadOptions={loadCompanyUsers}
                                        getOptionLabel={userBasicLabel}
                                        getOptionValue={userBasicValue}
                                        formatOptionLabel={userBasicLabelFormat}
                                    />
                                    <FormText>Users who will be notified and can approve new signups to company.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup>
                                    <Label>Default Groups</Label>
                                    <Field
                                        name="newUserDefaultGroupsMembership"
                                        component={ValidatedSelect}
                                        async
                                        defaultOptions
                                        cacheOptions
                                        isMulti
                                        loadOptions={loadGroupOptions}
                                        getOptionLabel={groupLabel}
                                        getOptionValue={groupValue}
                                        formatOptionLabel={formatGroup}
                                    />
                                    <FormText>Groups that new users will automatically be added to on signup.</FormText>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            )}
        </Form>
    )
}
