import React from 'react'
import { Col, Form, FormGroup, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { documentLinkLabel, documentLinkValue } from '@src/logic/forms/SelectHelpers'
import { isNonNullNumber } from '@src/logic/forms/validation'
import { DocumentLink, Revision } from '@src/types/document'

interface IProps {
    availableRevisions: Revision[]
    locked: boolean
}

export interface IBudgetSettingsFormData {
    approvedBudget: number
    budgetApprovalDate?: Date
    locked: boolean
    clientBudgetAdvice: DocumentLink[]
    consultantBudgetAdvice: DocumentLink[]
}

class BudgetSettingsForm extends React.Component<IProps & InjectedFormProps<IBudgetSettingsFormData, IProps>> {

    private revisionOptions = (): DocumentLink[] => {
        return this.props.availableRevisions.map<DocumentLink>(r => ({
            revisionId: r.id,
            documentId: r.documentId,
            name: r.name
        }))
    }

    private noOptionsMessage = (data: { inputValue: string }) => {
        return data.inputValue === '' ? 'No documents in widget' : 'No document in widget match'
    }

    public render() {
        const revisionOptions = this.revisionOptions()
        return (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Approved Budget</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend">$</InputGroupAddon>
                                <Field name="approvedBudget" component={ValidatedCurrencyInput} validate={isNonNullNumber} disabled={this.props.locked} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Approval Date</Label>
                            <Field name="budgetApprovalDate" component={ValidatedDatePicker} disabled={this.props.locked} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Client Budget Advice</Label>
                            <Field
                                name="clientBudgetAdvice"
                                component={ValidatedSelect}
                                options={revisionOptions}
                                placeholder="Select documents..."
                                isMulti
                                getOptionLabel={documentLinkLabel}
                                getOptionValue={documentLinkValue}
                                isDisabled={this.props.locked}
                                noOptionsMessage={this.noOptionsMessage}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label>Consultant Budget Advice</Label>
                            <Field
                                name="consultantBudgetAdvice"
                                component={ValidatedSelect}
                                options={revisionOptions}
                                placeholder="Select documents..."
                                isMulti
                                getOptionLabel={documentLinkLabel}
                                getOptionValue={documentLinkValue}
                                isDisabled={this.props.locked}
                                noOptionsMessage={this.noOptionsMessage}
                            />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default reduxForm<IBudgetSettingsFormData, IProps>({})(BudgetSettingsForm)
