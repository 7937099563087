import React from 'react'

export default class Toolbar extends React.Component {

    public render() {
        return (
            <div className="toolbar">
                <div className="toolbar__nav d-flex flex-row">
                    {this.props.children}
                </div>
            </div>
        )
    }
}
