import React from 'react'

import FA from '@src/components/common/FontAwesomeIcon'

export default class InitializingApp extends React.PureComponent {

    public render() {
        return (
            <section className="h-100 d-flex flex-column align-items-center justify-content-center">
                <div>
                    <FA icon="spinner-third" spin size="2x" />
                </div>
            </section>
        )
    }
}
