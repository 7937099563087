import React, { useEffect } from 'react'
import { useAsync } from 'react-async'
import { useDispatch, useSelector } from 'react-redux'
import { Badge, Button, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown } from 'reactstrap'

import cx from 'classnames'

import * as infopointLogo from 'public/img/infopoint_white.svg'

import * as infopointLogoSmall from 'public/img/infopoint_white_small.svg'

import { toggleSandbox } from '@src/actions/sandbox'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import ProjectMenu from '@src/components/header/ProjectMenu'
import UserMenu from '@src/components/header/UserMenu'
import NotificationsCard from '@src/components/notifications/NotificationsCard'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { HelpScoutServiceInstance } from '@src/logic/support/HelpScoutService'
import { RootState } from '@src/types/models'
import { Notification } from '@src/types/notification'
import { ProjectsState } from '@src/types/project'

interface IProps {
    sidebarCollapsed: boolean
    toggleSidebar: (open?: boolean) => void
}

async function loadNotifications() {
    const result = await NotificationService.listNotifications()
    return { unread: result.unread, notifications: result.notifications }
}

const AppHeader: React.FC<IProps> = ({ sidebarCollapsed, toggleSidebar }) => {
    const projects = useSelector<RootState, ProjectsState>(s => s.projects)
    const dispatch = useDispatch()

    const notificationsAsync = useAsync(loadNotifications)

    useEffect(
        () => NotificationService.onReceiveNotification(handleReceiveNotification),
        []
    )

    function handleReceiveNotification(notification: Notification) {
        if (!notificationsAsync.isResolved) {
            notificationsAsync.reload()
        } else {
            notificationsAsync.setData({ unread: notificationsAsync.data.unread + 1, notifications: [notification, ...notificationsAsync.data.notifications] })
        }
    }

    function handleToggleSidebar() {
        toggleSidebar(!sidebarCollapsed)
    }

    function showHelpScout() {
        HelpScoutServiceInstance.toggle()
    }

    function openSandbox() {
        dispatch(toggleSandbox(true))
    }

    async function markAllNotificationsAsRead() {
        await NotificationService.markAllNotificationsAsRead()
        if (!notificationsAsync.isResolved) {
            return notificationsAsync.reload()
        } else {
            notificationsAsync.setData({ unread: 0, notifications: notificationsAsync.data.notifications.map(n => ({ ...n, read: true })) })
        }
    }

    async function markNotificationAsRead(notificationId: string) {
        await NotificationService.markNotificationAsRead(notificationId)
        if (!notificationsAsync.isResolved) {
            return notificationsAsync.reload()
        } else {
            notificationsAsync.setData({ unread: notificationsAsync.data.unread - 1, notifications: notificationsAsync.data.notifications.map(n => n.id === notificationId ? ({ ...n, read: true }) : n) })
        }
    }

    return (
        <header className="header">
            <ProjectMenu active={projects.active} projects={projects.favourites || []} />
            <Link to={Routes.DASHBOARD} className="header__logo">
                <img className="d-none d-md-inline-block" src={infopointLogo} alt="InfoPoint Logo" />
                <img className="d-md-none" src={infopointLogoSmall} alt="InfoPoint Logo" />
            </Link>
            <Button className="d-none d-md-inline-block" color="success" onClick={openSandbox}><FA icon="upload" /> Upload documents</Button>
            {/* <SimpleSearch className="header__search flex-grow-1 flex-md-grow-0" /> */}
            <UncontrolledButtonDropdown>
                <DropdownToggle className="header__notifications mr-0" color="link">
                    <FA icon="bell" />
                    {notificationsAsync.data?.unread > 0 && <Badge pill color="warning">{notificationsAsync.data.unread}</Badge>}
                </DropdownToggle>
                <DropdownMenu right className="shadow">
                    <NotificationsCard
                        totalUnread={notificationsAsync.data?.unread}
                        notifications={notificationsAsync.data?.notifications}
                        loading={notificationsAsync.isLoading}
                        markAllAsRead={markAllNotificationsAsRead}
                        markAsRead={markNotificationAsRead}
                    />
                </DropdownMenu>
            </UncontrolledButtonDropdown>
            <Button onClick={showHelpScout} className="header__notifications mr-0" color="link"><FA icon="question-circle" /></Button>
            <UserMenu />
            <a
                className={cx('header__trigger', { 'header__trigger--active': !sidebarCollapsed })}
                onClick={handleToggleSidebar}
                role="button"
            >
                <span className="header__burger" />
            </a>
        </header>
    )
}

export default AppHeader
