import { DocumentLink } from '@src/types/document'
import { EmailLink } from '@src/types/email'
import { BoolDefinition, CompanyLinksDefinition, DateDefinition, DocumentLinksDefinition, EmailLinksDefinition, IMetadataDefinition, NumericDefinition, SelectDefinition, TextDefinition, TransmittalLinksDefinition, UserLinksDefinition } from '@src/types/metadata'
import { CompanyLink, UserLink } from '@src/types/principal'
import { TransmittalLink } from '@src/types/transmittal'

export const buildValidator = (definition: IMetadataDefinition) => (value: any): string => validate(value, definition)

export function validate(value: any, definition: IMetadataDefinition): string {
    switch (definition.type) {
        case 'bool':
            return validateBoolean(value, definition as BoolDefinition)
        case 'numeric':
            return validateNumeric(value, definition as NumericDefinition)
        case 'text':
            return validateText(value, definition as TextDefinition)
        case 'date':
            return validateDate(value, definition as DateDefinition)
        case 'select':
            return validateSelect(value, definition as SelectDefinition)
        case 'documentLinks':
            return validateDocumentLinks(value, definition as DocumentLinksDefinition)
        case 'transmittalLinks':
            return validateTransmittalLinks(value, definition as TransmittalLinksDefinition)
        case 'emailLinks':
            return validateEmailLinks(value, definition as EmailLinksDefinition)
        case 'companyLinks':
            return validateCompanyLinks(value, definition as CompanyLinksDefinition)
        case 'userLinks':
            return validateUserLinks(value, definition as UserLinksDefinition)
        default:
            return undefined
    }
}

export function validateBoolean(value: boolean, definition: BoolDefinition): string {
    if (definition.isRequired && value == null) {
        return 'Required'
    }

    return undefined
}

export function validateNumeric(value: number, definition: NumericDefinition): string {
    if (value == null) {
        return definition.isRequired ? 'Required' : undefined
    }

    if (definition.options.maxValue < value) {
        if (definition.options.enforceRange) {
            return 'Too large'
        }
        // return 'Larger than suggested range'
    }

    if (definition.options.minValue > value) {
        if (definition.options.enforceRange) {
            return 'Too small'
        }
        // return 'Smaller than suggested range'
    }

    return undefined
}

export function validateText(value: string, definition: TextDefinition): string {
    if (definition.isRequired && (value == null || value === '')) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.maxLength < value.length) {
        return 'Too large'
    }

    if (definition.options.minLength > value.length) {
        return 'Too small'
    }

    return undefined
}

export function validateDate(value: Date, definition: DateDefinition): string {
    if (definition.isRequired && value == null) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.maxValue < value) {
        if (definition.options.enforceRange) {
            return 'Too small'
        }
        // return 'Smaller than suggested range'
    }

    if (definition.options.minValue > value) {
        if (definition.options.enforceRange) {
            return 'Too small'
        }
        // return 'Smaller than suggested range'
    }

    return undefined
}

export function validateSelect(value: string[], definition: SelectDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.values.length > 0 && value.filter(x => !definition.options.values.includes(x)).length > 0) {
        if (definition.options.enforceValues) {
            return 'Option is not valid'
        }
        // return 'Option is not in suggested options'
    }

    if (!definition.options.isMultiselect && value.length > 1) {
        return 'Only one option permitted'
    }

    return undefined
}

export function validateDocumentLinks(value: DocumentLink[], definition: DocumentLinksDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.limit && definition.options.limit < value.length) {
        return `Too many documents. Maximum '${definition.options.limit}' allowed.`
    }

    return undefined
}

export function validateTransmittalLinks(value: TransmittalLink[], definition: TransmittalLinksDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.limit && definition.options.limit < value.length) {
        return `Too many transmittals. Maximum '${definition.options.limit}' allowed.`
    }

    return undefined
}

export function validateEmailLinks(value: EmailLink[], definition: EmailLinksDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.limit && definition.options.limit < value.length) {
        return `Too many emails. Maximum '${definition.options.limit}' allowed.`
    }

    return undefined
}

export function validateCompanyLinks(value: CompanyLink[], definition: CompanyLinksDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.limit && definition.options.limit < value.length) {
        return `Too many companies. Maximum '${definition.options.limit}' allowed.`
    }

    return undefined
}

export function validateUserLinks(value: UserLink[], definition: UserLinksDefinition): string {
    if (definition.isRequired && (value == null || value.length === 0)) {
        return 'Required'
    }

    if (value == null) return undefined

    if (definition.options.limit && definition.options.limit < value.length) {
        return `Too many users. Maximum '${definition.options.limit}' allowed.`
    }

    return undefined
}
