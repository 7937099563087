import React from 'react'
import { Form, FormGroup, Label } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import ValidatedInput from '@src/components/common/ValidatedInput'
import { required } from '@src/logic/forms/validation'

export interface INewTemplateFormData {
    name: string
    key: string
}

class NewTemplateForm extends React.PureComponent<InjectedFormProps<INewTemplateFormData>> {
    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label>Name</Label>
                    <Field name="name" component={ValidatedInput} validate={required} />
                </FormGroup>
                <FormGroup>
                    <Label>Code</Label>
                    <Field name="key" component={ValidatedInput} validate={required} />
                </FormGroup>
            </Form>
        )
    }
}

export default reduxForm<INewTemplateFormData>({})(NewTemplateForm)
