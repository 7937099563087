import React from 'react'

import cx from 'classnames'

interface IProps {
    page: number
    perPage: number
    totalCount: number
    onChange: (perPage: number) => void
    options?: number[]
    className?: string
}

export default class PaginationCompliment extends React.PureComponent<IProps> {

    private static readonly DEFAULT_OPTIONS = [20, 50, 100]

    private renderOptions = () => {
        const valueOptions = this.props.options == null || this.props.options.length === 0 ? PaginationCompliment.DEFAULT_OPTIONS : this.props.options

        if (valueOptions.indexOf(this.props.perPage) === -1) {
            valueOptions.push(this.props.perPage)
        }

        return valueOptions.sort((a, b) => a - b).map((o, idx) => <option key={idx} value={o}>{o}</option>)
    }

    public handlePerPageChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
        this.props.onChange(+e.target.value)
    }

    public render() {
        const { options, page, perPage, totalCount } = this.props
        const startRange = !totalCount ? 0 : (Math.max(page - 1, 0) * perPage + 1)
        const endRange = Math.min(page * perPage, totalCount)

        return (
            <div className={cx('listings page-navigation__listings', this.props.className)}>
                <select className="listings__control custom-select" value={perPage} onChange={this.handlePerPageChange}>
                    {this.renderOptions()}
                </select>
                <span className="listings__desc">Showing <strong>{startRange}-{endRange}</strong> of {totalCount || 0}</span>
            </div>
        )
    }
}
