import { UserAccess } from '@src/types/access'

export interface Address {
    description: string
    lines: string[]
    city: string
    state: string
    postCode: string
}

export interface Company {
    id: string
    name: string
    description: string
    abbreviatedName: string
    abn: string
    acn: string
    addresses: Address[]
    phone: string
    website: string
    industry: string
    disciplines: string[]
    adminGroup: string
    logoLink?: string
    myAccess: UserAccess
}

export interface CompanySettings {
    autoApproveDomains: string[]
    newUserApprovers: UserInternal[]
    newUserDefaultGroupsMembership: Group[]
}

export interface CompanyPublic {
    id: string
    name: string
    description: string
    abbreviatedName: string
    abn: string
    acn: string
    addresses: any[]
    phone: string
    website: string
    industry: string
    disciplines: string[]
    logoLink?: string
}

export interface CompanyLink {
    id: string
    name: string
}

export interface Group {
    id: string
    name: string
    notes: string
    members: UserBasic[]
    adminGroup: boolean
    myAccess: UserAccess
}

export interface PrincipalBrief {
    id: string
    name: string
}

export type PrincipalType = 'user' | 'group' | 'company'

export interface UserBasic {
    id: string
    firstName: string
    lastName: string
    email: string
    mobile: string
    phone: string
    role: string
    status: UserStatus
    profilePictureLink: string
    company: PrincipalBrief
}

export interface UserInternal {
    id: string
    firstName: string
    lastName: string
    email: string
    mobile: string
    phone: string
    role: string
    status: UserStatus
    profilePictureLink: string
}

export enum UserStatus {
    Invited = 'Invited',
    Activated = 'Activated',
    Enabled = 'Enabled',
    Disabled = 'Disabled'
}

export interface UserLink {
    id: string
    name: string
}
