import React from 'react'

export interface IWizardPageProps<T> {
    children?: React.ReactChild | React.ReactChildren
    validate?: (values: T) => {}
    name: string
    nextLabel?: string
    previousLabel?: string
    submit?: boolean
    hideNavigation?: boolean
}

function WizardPage<T>(props: IWizardPageProps<T>): React.ReactElement {
    return props.children as any
}

export default WizardPage
