import React from 'react'
import { Row } from 'reactstrap'

import cx from 'classnames'

import Cell from '@src/components/table/Cell'

interface ISelectableRowProps<TRow> {
    rowData: TRow
    onSelect: (rowData: TRow) => void
    isSelected: (rowData: TRow) => boolean
}

export default class SelectableContentRow<TRow> extends React.PureComponent<{} | ISelectableRowProps<TRow>> {

    private onSelect = () => {
        const selectableProps = this.props as ISelectableRowProps<TRow>
        if (selectableProps.onSelect) {
            selectableProps.onSelect(selectableProps.rowData)
        }
    }

    public render() {
        const { isSelected, onSelect, rowData } = this.props as ISelectableRowProps<TRow>
        const selected = isSelected ? isSelected(rowData) : null

        return (
            <Row className={cx('selectable-content__row', { 'selectable-content__row--selected': selected })}>
                {onSelect &&
                    <Cell key="row-checkbox" xs={3} lg={1} className="selectable-content__select d-flex d-lg-table-cell order-2 order-lg-1 justify-content-end align-items-baseline">
                        <div className="selectable-content__checkbox mx-lg-auto" onClick={this.onSelect}>
                            <input name="row-check" checked={selected} type="checkbox" readOnly />
                            <label/>
                        </div>
                    </Cell>
                }
                {this.props.children}
            </Row>
        )
    }
}
