// tslint:disable:jsx-no-lambda

import React from 'react'
import { connect } from 'react-redux'
import { PopoverBody, PopoverHeader, UncontrolledPopover } from 'reactstrap'
import { bindActionCreators, Dispatch } from 'redux'

import cx from 'classnames'
import moment from 'moment'

import { setDocumentToRevise, toggleSandbox } from '@src/actions/sandbox'
import * as WidgetActions from '@src/actions/widget'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import Tags from '@src/components/common/Tags'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import DocumentStatusIndicator from '@src/components/document/DocumentStatusIndicator'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import ContentTable from '@src/components/table/ContentTable'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import * as Operations from '@src/logic/auth/operations'
import { DocumentCheckin, DocumentCheckout, RevisionsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { IPDocument, Revision, RevisionField } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Session } from '@src/types/session'

interface IProps {
    metadataDefinitions: IMetadataDefinition[]
    documents: IPDocument[]
    sortField: string
    visibleRevisionProps: RevisionField[]
    visibleMetadata: string[]
    getMoreRevisions: (document: IPDocument) => Promise<void>
    onSort: (field: string) => void
}

interface IConnectedState {
    user: Session.User
    activeProject: Project
    selectedRevisionIds: string[]
}

interface IConnectedDispatch {
    widgetActions: typeof WidgetActions
    sandboxReviseDocument: (document: IPDocument, project: Project, metadataDefinitions: IMetadataDefinition[]) => void
    openSandbox: () => void
}

interface IState {
    showConfirmDownload: boolean
    revisionToDownload: Revision
    expandedDocuments: string[]
}

class DocumentsTable extends React.Component<IProps & IConnectedState & IConnectedDispatch, IState> {

    constructor(props) {
        super(props)

        this.state = {
            showConfirmDownload: false,
            revisionToDownload: null,
            expandedDocuments: []
        }
    }

    private renderMetadataCell = (definition: IMetadataDefinition, data) => {
        const { visibleMetadata } = this.props
        const value = renderMetadata(data, definition, this.props.activeProject.id)

        return visibleMetadata.includes(definition.key) ? (
            <ContentTable.Cell key={definition.key} xs={4} lg className={cx('order-4', { 'text-muted': value === undefined })}>
                <strong className="d-lg-none">{definition.name}: </strong>
                {definition.type === 'text' ? <ClampLines lines={2} text={typeof value === 'string' ? value : ''} /> : value}
            </ContentTable.Cell>
         ) : null
    }

    private areRevisionsSelected = (...rows: Revision[]) => {
        return rows.every(row => this.props.selectedRevisionIds.includes(row.id))
    }

    private allRevisions = (includeExpanded: boolean = true): Revision[] => {
        return [].concat(...this.props.documents.map((doc) => {
            if (includeExpanded && this.state.expandedDocuments.includes(doc.id)) {
                return doc.revisions
            }

            return [doc.revisions[0]]
        }))
    }

    private allRevisionsSelected = () => {
        return this.areRevisionsSelected(...this.allRevisions())
    }

    private onSelectRow = (row: Revision) => {
        if (this.areRevisionsSelected(row)) {
            this.props.widgetActions.removeRevisions({ projectId: this.props.activeProject.id, entityIds: [row.id] })
        } else {
            this.props.widgetActions.addRevisions({ projectId: this.props.activeProject.id, entities: [row] })
        }
    }

    private onSelectAll = () => {
        if (!this.allRevisionsSelected()) {
            this.props.widgetActions.addRevisions({ projectId: this.props.activeProject.id, entities: this.allRevisions() })
        } else {
            this.props.widgetActions.removeRevisions({ projectId: this.props.activeProject.id, entityIds: this.allRevisions().map(r => r.id) })
        }
    }

    private renderHeader = () => {
        const { metadataDefinitions, sortField, visibleRevisionProps, visibleMetadata } = this.props
        const metadataHeaders = metadataDefinitions.map(md => (
            visibleMetadata.includes(md.key) ? (
                <ContentTable.Cell key={md.key} lg header sortField={md.key} onSort={this.props.onSort} currentSort={sortField} className="d-none">
                {md.name}
                </ContentTable.Cell>
            ) : null
        ))

        return (
            <ContentTable.Header key="table-header" onSelectAll={this.onSelectAll} allRowsSelected={this.allRevisionsSelected()}>
                <ContentTable.Cell key="links" lg header links className="d-none">
                    Links
                </ContentTable.Cell>
                <ContentTable.Cell key="type" lg={2} header className="d-none">
                    Type
                </ContentTable.Cell>
                {visibleRevisionProps.includes(RevisionField.Name) && <ContentTable.Cell key="name" lg={2} header className="d-none" sortField="name" onSort={this.props.onSort} currentSort={sortField} data-tour={TourTags.DocumentTableColumnName}>
                    Name
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.RevisionNumber) && <ContentTable.Cell key="revnumber" lg header className="d-none">
                    Rev&nbsp;#
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.Tags) && <ContentTable.Cell key="tags" lg header className="d-none" sortField="tags" onSort={this.props.onSort} currentSort={sortField}>
                    Tags
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.Author) && <ContentTable.Cell key="author" lg header className="d-none" sortField="author" onSort={this.props.onSort} currentSort={sortField}>
                    Author
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.RevisionDate) && <ContentTable.Cell key="revdate" lg header className="d-none" sortField="revision_date" onSort={this.props.onSort} currentSort={sortField}>
                    Revision Date
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.UploadedDate) && <ContentTable.Cell key="uploaded" lg header className="d-none" sortField="uploaded" onSort={this.props.onSort} currentSort={sortField}>
                    Uploaded
                </ContentTable.Cell>}
                {metadataHeaders}
                <ContentTable.Cell key="actions" lg={2} header className="text-right">
                    Actions
                </ContentTable.Cell>
            </ContentTable.Header>
        )
    }

    private renderRows = () => {
        if (this.props.documents.length === 0) {
            return null
        }

        const rows = []

        this.props.documents.map((document) => {
            rows.push(this.rowRenderer(document.revisions[0], true, document))

            if (document.revisionCount > 1) {
                rows.push((
                    <ContentTable.CollapsibleRow key={`${document.id}-earlier-revisions`} isOpen={this.state.expandedDocuments.includes(document.id)}>
                        {document.revisions.slice(1).map(revision => this.rowRenderer(revision, false, document))}
                    </ContentTable.CollapsibleRow>)
                )
            }
        })

        return rows
    }

    private downloadRevision = (revision: Revision) => {
        if (this.props.documents.find(d => d.id === revision.documentId).checkedOutBy == null || (this.state.revisionToDownload && this.state.revisionToDownload.id === revision.id)) {
            downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
        } else {
            this.setState({ showConfirmDownload: true, revisionToDownload: revision })
        }
    }

    private expandDocument = (document: IPDocument) => {
        if (this.state.expandedDocuments.includes(document.id)) {
            this.setState({ expandedDocuments: this.state.expandedDocuments.filter(id => id !== document.id) })
        } else {
            // Check if we have retrieved the extra revisions
            if (document.revisionCount > document.revisions.length) {
                this.props.getMoreRevisions(document).then(() => this.setState({ expandedDocuments: this.state.expandedDocuments.concat(document.id) }))
            } else {
                this.setState({ expandedDocuments: this.state.expandedDocuments.concat(document.id) })
            }
        }
    }

    private handleCheckout = async (document: IPDocument) => {
        if (document.checkedOutBy == null) {
            await DocumentCheckout(document.id)
            NotificationService.info('Checked out document')
            this.downloadRevision(document.revisions[0])
        } else {
            await DocumentCheckin(document.id)
            NotificationService.info('Checked in document')
        }

        await this.props.getMoreRevisions(document)
    }

    private isCheckoutDisabled = (document: IPDocument) => {
        if (!isAuthorised(document.myAccess, Operations.Update)) {
            return true
        }

        if (document.checkedOutBy == null) {
            return false
        }

        if (document.checkedOutBy.id !== this.props.user.id && !document.myAccess.isAdministrator) {
            return true
        }

        return false
    }

    private checkoutTooltip = (document: IPDocument) => {
        if (document.checkedOutBy == null) {
            return 'Check Out'
        }

        if (document.checkedOutBy.id === this.props.user.id) {
            return 'Check In'
        }

        if (document.myAccess.isAdministrator) {
            return `Remove check out by ${document.checkedOutBy.name}`
        }

        return `Checked out by ${document.checkedOutBy.name}`
    }

    private downloadConfirmDownload = () => {
        this.downloadRevision(this.state.revisionToDownload)
        this.setState({ showConfirmDownload: false })
    }

    private reviseDocument = (document: IPDocument) => {
        this.props.sandboxReviseDocument(document, this.props.activeProject, this.props.metadataDefinitions)
        this.props.openSandbox()
    }

    private clearConfirmDownload = () => {
        this.setState({ showConfirmDownload: false })
    }

    private clearRevisionToDownload = () => {
        this.setState({ revisionToDownload: null })
    }

    private confirmedDownloadCheckedOutBy = () => {
        if (this.state.revisionToDownload == null) return null
        const doc = this.props.documents.find(d => d.id === this.state.revisionToDownload.documentId)
        return doc != null && doc.checkedOutBy != null ? doc.checkedOutBy.name : null
    }

    private rowRenderer = (revision: Revision, headRevision: boolean, containerDocument: IPDocument) => {
        const { visibleMetadata, visibleRevisionProps } = this.props
        const metadataCells = this.props.metadataDefinitions.filter(m => visibleMetadata.includes(m.key)).map(definition => this.renderMetadataCell(definition, revision.metadata[definition.key]))

        const expandAction = headRevision && containerDocument.revisionCount > 1 ? (
            <TooltipLinkAction id={`expand-revisions-${revision.id}`} tooltip="Earlier revisions" className="order-lg-0" onClick={this.expandDocument} data={containerDocument} data-tour={TourTags.DocumentTableRowExpand}><FA icon={this.state.expandedDocuments.includes(containerDocument.id) ? 'minus-square' : 'plus-square'} /></TooltipLinkAction>
        ) : null

        return (
            <ContentTable.Row key={revision.id} onSelect={this.onSelectRow} rowData={revision} isSelected={this.areRevisionsSelected}>
                <ContentTable.Cell xs={4} lg={2} links className="order-11 order-lg-2 text-center align-items-center">
                    <a id={`revision-${revision.id}-tlinks`} href="#" className={cx('mr-0', 'text-center', 'selectable-content__icon', { 'selectable-content__icon--red': revision.links.transmittals.length > 0 })}><FA icon="inbox" /></a>
                    {revision.links.transmittals.length > 0 && <UncontrolledPopover target={`revision-${revision.id}-tlinks`} trigger="hover">
                        <PopoverHeader>Linked in Transmittals</PopoverHeader>
                        <PopoverBody>
                            <ul className="pl-3">
                                {revision.links.transmittals.map(t => <li key={t.id}><Link to={Routes.projectTransmittal(revision.projectId, t.id)}>{t.subject}</Link></li>)}
                            </ul>
                        </PopoverBody>
                    </UncontrolledPopover>}
                </ContentTable.Cell>
                <ContentTable.Cell className="d-none d-lg-table-cell order-1 text-center">
                    <FA icon={fileTypeIcon(revision.fileName || '')} />
                </ContentTable.Cell>
                {visibleRevisionProps.includes(RevisionField.Name) && <ContentTable.Cell xs={9} lg={2} className="order-1 order-lg-3">
                    <DocumentStatusIndicator revision={revision} />
                    <div className="selectable-content__title">
                        <Link to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)}>{revision.name}</Link>
                    </div>
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.RevisionNumber) && <ContentTable.Cell xs={4} lg className="order-4">
                    <strong className="d-lg-none">Rev #: </strong>{revision.revNumber}
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.Tags) && <ContentTable.Cell xs={4} lg className="order-4" style={{ wordBreak: 'break-all' }}>
                    <strong className="d-lg-none">Tags: </strong><Tags tags={revision.tags} />
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.Author) && <ContentTable.Cell xs={4} lg className="order-4">
                    <strong className="d-lg-none">Author: </strong>{revision.author}
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.RevisionDate) && <ContentTable.Cell xs={4} lg className="order-4">
                    <strong className="d-lg-none">Revision Date: </strong>{moment(revision.revDate).format('L')}
                </ContentTable.Cell>}
                {visibleRevisionProps.includes(RevisionField.UploadedDate) && <ContentTable.Cell xs={4} lg className="order-4">
                    <strong className="d-lg-none">Uploaded: </strong>{moment(revision.uploadDate).format('L')}
                </ContentTable.Cell>}
                {metadataCells}
                <div className="w-100 d-lg-none order-10"/>
                <ContentTable.Cell xs={8} lg={2} actions className="order-12 d-flex d-lg-table-cell justify-content-end align-items-baseline text-right">
                    {expandAction}
                    <TooltipLink id={`view-${revision.id}`} tooltip="View" to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)} className="selectable-content__icon order-lg-1" data-tour={TourTags.DocumentTableRowView}><FA icon="eye" /></TooltipLink>
                    <TooltipLink id={`edit-${revision.id}`} tooltip="Edit" to={Routes.projectDocumentEdit(revision.projectId, revision.documentId, revision.id)} className="selectable-content__icon order-lg-1" data-tour={TourTags.DocumentTableRowEdit}><FA icon="pencil" /></TooltipLink>
                    {headRevision && <TooltipLinkAction id={`revise-${revision.id}`} tooltip="Revise" data={containerDocument} className="order-lg-1" onClick={this.reviseDocument} disabled={!isAuthorised(containerDocument.myAccess, Operations.Revise) || containerDocument.checkedOutBy != null} data-tour={TourTags.DocumentTableRowRevise}><FA icon="edit" /></TooltipLinkAction>}
                    {headRevision && <TooltipLinkAction id={`checkout-${revision.id}`} tooltip={this.checkoutTooltip(containerDocument)} data={containerDocument} onClick={this.handleCheckout} disabled={this.isCheckoutDisabled(containerDocument)} data-tour={TourTags.DocumentTableRowCheckout}><FA icon={containerDocument.checkedOutBy != null ? 'lock' : 'lock-open'} /></TooltipLinkAction>}
                    <TooltipLinkAction id={`download-${revision.id}`} tooltip="Download" data={revision} onClick={this.downloadRevision} data-tour={TourTags.DocumentTableRowDownload}><FA icon="download" /></TooltipLinkAction>
                </ContentTable.Cell>
            </ContentTable.Row>
        )
    }

    public render() {
        const { revisionToDownload, showConfirmDownload } = this.state
        return (
            <ContentTable>
                {this.renderHeader()}
                {this.renderRows()}
                <ConfirmationModal
                    open={showConfirmDownload}
                    header="Downloading Checked Out Document"
                    message={<span>This document has been checked out by {<strong>{this.confirmedDownloadCheckedOutBy()}</strong>}, and they may be making changes to it. Are you sure you want to download?</span>}
                    confirmAction="Download"
                    onConfirm={this.downloadConfirmDownload}
                    onReject={this.clearConfirmDownload}
                    onClosed={this.clearRevisionToDownload}
                    toggle={this.clearConfirmDownload}
                />
            </ContentTable>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IProps & IConnectedState {
    return {
        ...ownProps,
        user: state.session.user,
        activeProject: state.projects.active,
        selectedRevisionIds: getActiveProjectWidgetState(state).revisions.map(x => x.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch): IConnectedDispatch {
    return {
        widgetActions: bindActionCreators(WidgetActions, dispatch),
        sandboxReviseDocument: (document: IPDocument, project: Project, metadataDefinitions: IMetadataDefinition[]) => dispatch(setDocumentToRevise({
            project,
            revisions: [document.revisions[0]]
        })),
        openSandbox: () => dispatch(toggleSandbox(true))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentsTable)
