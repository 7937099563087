import React from 'react'
import { Label, Progress } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { Sandbox } from '@src/types/sandbox'

interface IProps {
    upload: Sandbox.Upload
    onPause?: (upload: Sandbox.Upload) => void
    onResume?: (upload: Sandbox.Upload) => void
    onCancel?: (upload: Sandbox.Upload) => void
}

const SmartUpload: React.FunctionComponent<IProps> = ({ upload, onPause, onResume, onCancel }) => {

    function uploadCanPause() {
        return (upload.state === 'running' || upload.state === 'pausing')
    }

    function toggle() {
        if (uploadCanPause()) {
            onPause(upload)
        } else {
            onResume(upload)
        }
    }

    function handleCancel() {
        onCancel(upload)
    }

    const progress = upload.state === 'pausing' ?
            (
                <Progress multi style={{ height: '0.5rem' }}>
                    <Progress bar value={upload.bytesUploaded / upload.bytesTotal * 100} />
                    <Progress bar color="warning" value={(upload.pausingAtByte - upload.bytesUploaded) / upload.bytesTotal * 100}/>
                </Progress>
            ) : <Progress style={{ height: '0.5rem' }} value={upload.bytesUploaded / upload.bytesTotal * 100} />

    return (
            <div className="document-sandbox__file">
                <div className="d-flex">
                    <Label className="flex-grow-1 text-truncate"><FA icon={contentTypeIcon(upload.contentType)} /> {upload.filename}</Label>
                    <span className="flex-shrink-1">
                        {upload.state === 'completed' || !onPause || !onResume ? null : <FA icon={uploadCanPause() ? 'pause' : 'play'} className="pointer" onClick={toggle} />}
                        {onCancel && <FA className="pointer" icon="trash" onClick={handleCancel} />}
                    </span>
                </div>
                <div className="document-sandbox__progress d-flex justify-content-between align-items-center">
                    {progress}
                    <div className="document-sandbox__icon">
                        {upload.state === 'completed' ?
                            <FA icon="check" className="text-success" />
                            : <FA icon="sync" className="text-tertiary" spin />
                        }
                    </div>
                </div>
            </div>
    )
}

export default SmartUpload
