import { useDispatch, useSelector } from 'react-redux'

import { bindActionCreators } from 'redux'

import * as WidgetActions from '@src/actions/widget'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { RootState } from '@src/types/models'
import { IProjectWidgetState } from '@src/types/widget'

export default function useProjectWidget() {
    const dispatch = useDispatch()
    const widgetData = useSelector<RootState, IProjectWidgetState>(s => getActiveProjectWidgetState(s))

    return {
        ...widgetData,
        actions: bindActionCreators(WidgetActions, dispatch)
    }
}
