import React from 'react'

import cx from 'classnames'

import Checkbox from '@src/components/common/CheckboxRadio'
import TriToggle from '@src/components/common/TriToggleButton'
import { AclEntry } from '@src/types/access'

interface IProps {
    principal: AclEntry
    validOperations: string[]
    onDeny: (principalId: string, ...operations: string[]) => void
    onRevokeOrUndeny: (principalId: string, ...operations: string[]) => void
    onGrant: (principalId: string, ...operations: string[]) => void
    onToggleAdmin?: (principalId: string) => void
    disabled?: boolean
}

export default class extends React.PureComponent<IProps> {

    constructor(props) {
        super(props)

        this.state = {
            applyAll: undefined
        }
    }

    private handleDenyAll = () => {
        this.props.onDeny(this.props.principal.id, ...this.props.validOperations)
        this.setState({ applyAll: false })
    }

    private handleRevokeOrUndenyAll = () => {
        this.props.onRevokeOrUndeny(this.props.principal.id, ...this.props.validOperations)
        this.setState({ applyAll: undefined })
    }

    private handleGrantAll = () => {
        this.props.onGrant(this.props.principal.id, ...this.props.validOperations)
        this.setState({ applyAll: true })
    }

    private handleToggleDeny = (operation: string) => {
        this.props.onDeny(this.props.principal.id, operation)
        this.setState({ applyAll: undefined })
    }

    private handleToggleNeutral = (operation: string) => {
        this.props.onRevokeOrUndeny(this.props.principal.id, operation)
        this.setState({ applyAll: undefined })
    }

    private handleToggleGrant = (operation: string) => {
        this.props.onGrant(this.props.principal.id, operation)
        this.setState({ applyAll: undefined })
    }

    private getOperationState = (operation: string): boolean => {
        if (this.props.principal.denials.includes(operation)) {
            return false
        }

        if (this.props.principal.grants.includes(operation)) {
            return true
        }

        return null
    }

    private getApplyAllState = () => {
        const { principal, validOperations } = this.props
        if (principal.denials.length === validOperations.length) {
            return false
        }

        if (principal.grants.length === validOperations.length) {
            return true
        }

        return undefined
    }

    private toggleAdministrator = () => {
        this.props.onToggleAdmin(this.props.principal.id)
    }

    public render() {
        const { principal, validOperations, disabled } = this.props
        const principalName = principal.type === 'user' ? <>{principal.name} <small className="text-muted">({principal.email || 'no email'})</small></> : principal.name

        const adminCheckbox = principal.type === 'company' ? null : <div><Checkbox labelClass={disabled && 'text-dark'} onClick={this.toggleAdministrator} checked={principal.isAdministrator} label="Admin" disabled={disabled} /></div>

        return (
            <tr className={cx({ 'table-secondary': principal.isAdministrator })}>
                <th scope="row">{principalName}{adminCheckbox}</th>
                <td className="permission-table__apply-all">
                    <TriToggle idPrefix="apply-all" disabled={principal.isAdministrator || disabled} state={this.getApplyAllState()} onToggleOff={this.handleDenyAll} onToggleNeutral={this.handleRevokeOrUndenyAll} onToggleOn={this.handleGrantAll} />
                </td>
                {validOperations.map((op) => {
                    return (
                        <td key={op}>
                            <TriToggle idPrefix={op} aria-label={`Toggle ${op}`} name={op} disabled={principal.isAdministrator || disabled} state={this.getOperationState(op)} onToggleOff={this.handleToggleDeny} onToggleOn={this.handleToggleGrant} onToggleNeutral={this.handleToggleNeutral} />
                        </td>
                    )
                })}
            </tr>
        )
    }
}
