import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Container } from 'reactstrap'

import cx from 'classnames'
import arrayMutators from 'final-form-arrays'
import setFieldTouched from 'final-form-set-field-touched'

import { IReviseDocumentIdAndRequest, reviseDocuments, setDocumentToRevise, submitNewDocumentRequests, toggleSandbox } from '@src/actions/sandbox'
import FA from '@src/components/common/FontAwesomeIcon'
import { IOption } from '@src/components/common/Select'
import Wizard from '@src/components/forms/Wizard'
import FileSelectStage from '@src/components/sandbox/FileSelectStage'
import MetadataStage from '@src/components/sandbox/MetadataStage'
import ReviewStage from '@src/components/sandbox/ReviewStage'
import SelectProjectStage from '@src/components/sandbox/SelectProjectStage'
import UploadStage from '@src/components/sandbox/UploadStage'
import { required } from '@src/logic/forms/validation'
import { AclEntry } from '@src/types/access'
import { Api } from '@src/types/api'
import { Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Sandbox } from '@src/types/sandbox'

export interface IDocumentSandboxDocument {
    id: string
    existingRevision?: Revision,
    uploads: (Sandbox.Upload & {
        data?: {
            name: string,
            version: string,
            date: Date,
            author: string,
            tags: IOption<string>[],
            description: string
            metadata: {
                [key: string]: any
            }
        }
    })[]
}

export interface IDocumentSandboxWizardForm {
    project: Project
    metadataDefinitions: IMetadataDefinition[]
    documents: IDocumentSandboxDocument[]
    approver: AclEntry
    approvalComments: string
    documentAccessControl: AclEntry[]
    revisionAccessControl: AclEntry[]
    overridePermissions: boolean
}

function buildOverridePermissions(entries: AclEntry[]) {
    return entries.reduce(
        (permissions, entry) => {
            permissions.grants[entry.id] = entry.grants
            permissions.denials[entry.id] = entry.denials
            if (entry.isAdministrator) permissions.administrators.push(entry.id)
            return permissions
        },
        {
            grants: {},
            denials: {},
            administrators: []
        })
}

function buildNewRevisions(uploads: IDocumentSandboxDocument['uploads'], metadataDefinitions: IMetadataDefinition[]) {
    return uploads.map(upload => ({
        author: upload.data.author,
        date: upload.data.date,
        description: upload.data.description,
        fileUuid: upload.id,
        metadata: metadataDefinitions.reduce(
            (meta, d) => ({
                ...meta,
                [d.key]: upload.data.metadata[d.key]
            }),
            {}),
        name: upload.data.name,
        tags: upload.data.tags && upload.data.tags.map(x => x.value),
        version: upload.data.version
    }))
}

const DocumentSandbox: React.FC = () => {
    const open = useSelector<RootState, boolean>(state => state.sandbox.open)
    const toRevise = useSelector<RootState, Revision[]>(state => state.sandbox.toRevise)

    const dispatch = useDispatch()
    const [page, setPage] = React.useState(0)
    const projectStageHidden = toRevise.length > 0

    React.useEffect(
        () => {
            if (!open) setPage(0)
        },
        [open, setPage]
    )

    function submitForm(values: IDocumentSandboxWizardForm) {
        const requests = values.documents.filter(d => d.uploads.length !== 0).reduce<{ newDocuments: Api.Request.NewDocument[], newRevisions: IReviseDocumentIdAndRequest[] }>(
            (agg, doc) => {
                if (doc.existingRevision) {
                    agg.newRevisions.push({
                        documentId: doc.existingRevision.documentId,
                        request: {
                            approvalRequestComments: values.approvalComments,
                            requestedApprover: values.approver.id,
                            overrideRevisionPermissions: values.overridePermissions && values.revisionAccessControl != null ? buildOverridePermissions(values.revisionAccessControl) : null,
                            revisions: buildNewRevisions(doc.uploads, values.metadataDefinitions)
                        }
                    })
                } else {
                    agg.newDocuments.push({
                        approvalRequestComments: values.approvalComments,
                        requestedApprover: values.approver.id,
                        overrideDocumentPermissions: values.overridePermissions && values.documentAccessControl != null ? buildOverridePermissions(values.documentAccessControl) : null,
                        overrideRevisionPermissions: values.overridePermissions && values.revisionAccessControl != null ? buildOverridePermissions(values.revisionAccessControl) : null,
                        revisions: buildNewRevisions(doc.uploads, values.metadataDefinitions)
                    })
                }

                return agg
            },
            { newDocuments: [], newRevisions: [] })

        if (requests.newDocuments.length > 0) {
            dispatch(submitNewDocumentRequests(values.project.id, requests.newDocuments))
        }

        if (requests.newRevisions.length > 0) {
            dispatch(reviseDocuments(requests.newRevisions))
        }
        handleClose()
    }

    function handleClose() {
        dispatch(setDocumentToRevise({ project: undefined, revisions: [] }))
        dispatch(toggleSandbox(false))
    }

    function validateProjectStage(values: IDocumentSandboxWizardForm) {
        const errors: { [key in keyof IDocumentSandboxWizardForm]?: string } = {}
        errors.project = required(values.project)
        return errors
    }

    function validateFileStage(values: IDocumentSandboxWizardForm) {
        const errors: { [key in keyof IDocumentSandboxWizardForm]?: string } = {}
        errors.documents = !!!values.documents || values.documents.length === 0 || values.documents.every(d => !d.uploads || d.uploads.length === 0) ? 'Uploads need to be assigned to new documents or revisions' : undefined
        return errors
    }

    return open ? (
        <Alert color="info" className={cx('alert-dismissible document-sandbox d-flex flex-column', { full: projectStageHidden ? page > 0 : page > 1 })}>
            <Button color="link" className="close" onClick={handleClose}>
                <span className="align-top">Close</span>
                <FA icon="times" style={{ fontSize: '1rem', marginLeft: '0.25rem' }} />
            </Button>
            <Container tag="h2" className="my-3">Document sandbox</Container>
            <Wizard<IDocumentSandboxWizardForm>
                fluid
                className="px-0 d-flex flex-column"
                mutators={{
                    ...arrayMutators,
                    setFieldTouched
                }}
                onSubmit={submitForm}
                onPageChange={setPage}
            >
                <UploadStage name="Upload" nextLabel={projectStageHidden ? 'Organise files' : 'Select project'} />
                {!projectStageHidden ? <SelectProjectStage name="Project" validate={validateProjectStage} /> : undefined}
                <FileSelectStage name="Files" validate={validateFileStage} />
                <MetadataStage name="Metadata" />
                <ReviewStage name="Review" />
            </Wizard>
        </Alert>
    ) : null
}

export default DocumentSandbox
