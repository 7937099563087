import React from 'react'
import { Collapse } from 'reactstrap'

import cx from 'classnames'

import ActionBar from '@src/components/common/ActionBar'
import PaginationWithCompliment from '@src/components/common/PaginationWithCompliment'
import SearchBar from '@src/components/common/SearchBar'
import { ISearchProperty } from '@src/components/search/SearchAssistant'

export interface IExtraElement {
    element: (onSearch: () => void) => React.ReactElement<any>
    position: 'before' | 'after'
}

interface IProps {
    perPage: number
    currentPage: number
    totalItems: number
    filter: string
    placeholder?: string
    searchAssistantProperties?: ISearchProperty[]
    onFilterChange: (filter: string, triggerSearch?: boolean) => void
    onSelectPage: (page: number) => void
    onSelectPerPage: (perPage: number) => void
    onSearch: () => void
    searching?: boolean
    extraElements?: IExtraElement[]
    wrapperClassName?: string
}

interface IState {
    actionBarOpen: boolean
}

export default class GenericSearchBarWithPagination extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            actionBarOpen: false
        }
    }

    private onSearch = () => {
        this.props.onSearch()
    }

    private toggleActionBar = () => {
        this.setState({ actionBarOpen: !this.state.actionBarOpen })
    }

    public render() {
        const { currentPage, perPage, totalItems, onSelectPage, onSelectPerPage, placeholder, searchAssistantProperties, searching, extraElements, wrapperClassName } = this.props

        const pagination = (
            <PaginationWithCompliment
                className="d-flex"
                page={currentPage}
                perPage={perPage}
                totalCount={totalItems}
                onPageChange={onSelectPage}
                onPerPageChange={onSelectPerPage}
            />
        )

        const beforeElements = extraElements ? extraElements.filter(x => x.position === 'before').map(x => x.element) : []
        const afterElements = extraElements ? extraElements.filter(x => x.position === 'after').map(x => x.element) : []

        return (
            <ActionBar className={wrapperClassName}>
                <ActionBar.Search>
                    {beforeElements.map((el, idx) => <div key={idx} className={cx('d-none d-xl-flex mr-xl-2 action-bar__input flex-shrink-0', { 'ml-2': idx !== 0 })}>{el(this.onSearch)}</div>)}
                    <SearchBar
                        searchAssistant={searchAssistantProperties != null && searchAssistantProperties.length > 0}
                        availableProperties={searchAssistantProperties}
                        onChange={this.props.onFilterChange}
                        value={this.props.filter}
                        onSubmit={this.onSearch}
                        placeholder={placeholder || 'Search...'}
                        submitting={searching}
                    />
                    {afterElements.map((el, idx) => <div key={idx} className={cx('d-none d-xl-flex ml-xl-2 action-bar__input flex-shrink-0', { 'ml-2': idx !== 0 })}>{el(this.onSearch)}</div>)}
                    <div className="d-none d-xl-flex ml-xl-2 flex-shrink-0">
                        {pagination}
                    </div>
                    <ActionBar.Trigger open={this.state.actionBarOpen} onToggle={this.toggleActionBar} />
                </ActionBar.Search>
                <Collapse className="d-xl-none" isOpen={this.state.actionBarOpen}>
                    {beforeElements.concat(afterElements).map((el, idx) => <div key={idx} className={cx('mt-3 action-bar__input d-inline-flex', { 'ml-2': idx !== 0 })}>{el(this.onSearch)}</div>)}
                    {pagination}
                </Collapse>
            </ActionBar>
        )
    }
}
