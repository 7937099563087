export const ApproveDocument = 'approveDocument'
export const ApproveTransmittal = 'approveTransmittal'
export const Create = 'create'
export const CreateCommunication = 'createCommunication'
export const CreateCostModule = 'createCostModule'
export const CreateDocument = 'createDocument'
export const CreateLayout = 'createLayout'
export const CreateMetaDefinition = 'createMetaDef'
export const CreateProject = 'createProject'
export const CreateRegister = 'createRegister'
export const CreateTemplate = 'createTemplate'
export const CreateTransmittal = 'createTransmittal'
export const Delete = 'delete'
export const DeleteBudget = 'deleteBudget'
export const DeleteCostCode = 'deleteCostCode'
export const DeleteCostSettings = 'deleteCostSettings'
export const DeletePhase = 'deletePhase'
export const Download = 'download'
export const Invite = 'invite'
export const MaintainUsers = 'maintainUsers'
export const MaintainMembership = 'maintainMembership'
export const Read = 'read'
export const RetireMetaDefinition = 'retireMetaDef'
export const Revise = 'revise'
export const Update = 'update'
export const UpdateMetaDefinition = 'updateMetaDef'
export const Write = 'write'
export const WriteBudget = 'writeBudget'
export const WriteCostCode = 'writeCostCode'
export const WriteCostSettings = 'writeCostSettings'
export const WritePhase = 'writePhase'

export const CompanyOperations = {
    Invite,
    Update,
    CreateProject,
    CreateTemplate,
    CreateLayout,
    CreateCostModule,
    MaintainUsers
}

export const EmailOperations = {
    Read,
    Download
}

export const GroupOperations = {
    Read,
    Update,
    Delete,
    MaintainMembership
}

export const ProjectOperations = {
    Create,
    Read,
    Update,
    Delete,
    ApproveDocument,
    ApproveTransmittal,
    CreateDocument,
    CreateRegister,
    CreateTransmittal,
    CreateCommunication,
    CreateMetaDefinition,
    RetireMetaDefinition,
    UpdateMetaDefinition
}

export const RegisterOperations = {
    Create,
    Read,
    Update,
    Delete,
    CreateMetaDefinition,
    RetireMetaDefinition,
    UpdateMetaDefinition
}

export const RevisionOperations = {
    Read,
    Update,
    Delete,
    Download
}

export const RevisionOperationsArray = Object.keys(RevisionOperations).map(o => RevisionOperations[o])

export const TemplateOperations = {
    Read,
    Update,
    Delete
}

export const TransmittalOperations = {
    Read,
    Update,
    Delete
}

export const CostOperations = {
    Read,
    Write,
    Delete,
    WritePhase,
    DeletePhase,
    WriteCostCode,
    DeleteCostCode,
    WriteCostSettings,
    DeleteCostSettings,
    WriteBudget,
    DeleteBudget
}
