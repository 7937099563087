import React from 'react'

import cx from 'classnames'

import Pagination from '@src/components/common/Pagination'
import PaginationCompliment from '@src/components/common/PaginationCompliment'

interface IProps {
    page: number
    perPage: number
    totalCount: number
    onPerPageChange: (value: number) => void
    onPageChange: (value: number) => void
    pageRangeDisplayed?: number
    marginPagesCount?: number
    perPageOptions?: number[]
    className?: string
}

export default class PaginationWithCompliment extends React.PureComponent<IProps> {
    public render() {
        const { className, page, perPage, totalCount, onPageChange, onPerPageChange, pageRangeDisplayed, marginPagesCount } = this.props
        return (
            <nav className={cx('page-navigation', className)} aria-label="Page navigation">
                <PaginationCompliment className="mr-2" page={page} perPage={perPage} totalCount={totalCount} onChange={onPerPageChange} />
                <Pagination currentPage={page} perPage={perPage} totalCount={totalCount} pageRangeDisplayed={pageRangeDisplayed} marginPagesCount={marginPagesCount} onSelectPage={onPageChange} />
            </nav>
        )
    }
}
