import React from 'react'
import { Button } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { Template } from '@src/types/communication'

interface IProps {
    template: Template
}

const TemplateToolbar: React.FC<IProps> = ({ template }) => {

    const projectTemplate = template.projectId != null
    const onEditRoute = template.projectId ? pathMatchesCurrentRoute(Routes.PROJECT_TEMPLATE, true) : pathMatchesCurrentRoute(Routes.COMPANY_TEMPLATE, true)

    return (
        <section className="banner">
            <Toolbar>
                <ToolbarLink active={onEditRoute} to={projectTemplate ? Routes.projectTemplate(template.projectId, template.id) : Routes.companyTemplate(template.id)} name="Edit" nameBrief="Edit" iconBrief="pencil" />
                <ToolbarLink active={projectTemplate ? pathMatchesCurrentRoute(Routes.PROJECT_TEMPLATE_ACCESS) : pathMatchesCurrentRoute(Routes.COMPANY_TEMPLATE_ACCESS)} to={projectTemplate ? Routes.projectTemplateAccess(template.projectId, template.id) : Routes.companyTemplateAccess(template.id)} name="Access" nameBrief="Access" iconBrief="lock" />
                <div className="ml-auto d-none d-md-flex align-items-center">
                    <Button onClick={null} className="mr-2">
                        <FA icon="file-pdf" />
                        <span className="d-none d-md-inline-block">&nbsp;Preview</span>
                    </Button>
                    {/* <Button color="primary" onClick={saveCommunication}>
                            <FA icon={isSavingChanges ? 'spinner-third' : hasUnsavedChanges ? 'save' : 'check-circle'} spin={isSavingChanges} />
                            <span className="d-none d-md-inline-block">&nbsp;{isSavingChanges ? 'Saving...' : hasUnsavedChanges ? 'Save' : 'Saved'}</span>
                        </Button> */}
                </div>
            </Toolbar>
        </section>
    )
}

export default TemplateToolbar
