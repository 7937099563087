import React, { Suspense } from 'react'

const FroalaEditor = React.lazy(() => import('@src/components/common/FroalaEditor'))

interface IProps {
    content: string
    config?: object
}

const FroalaViewer: React.FC<IProps> = ({ content, config }) => {
    function editorOff(e, editor) {
        editor.edit.off()
        editor.toolbar.hide()
    }

    return (
        <Suspense fallback={<div/>}>
            <FroalaEditor
                tag="textarea"
                model={content}
                config={{
                    key: process.env.FROALA_LICENSE_KEY,
                    iconsTemplate: 'font_awesome_5l',
                    heightMin: 400,
                    editorClass: 'fr-forceselectable',
                    events: {
                        'froalaEditor.initialized': editorOff
                    },
                    charCounterCount: false,
                    placeholderText: '',
                    ...config
                }}
            />
        </Suspense>
    )
}

export default React.memo(FroalaViewer)
