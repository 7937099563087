import { ANTLRInputStream, CommonTokenStream } from 'antlr4ts'
import { ParserRuleContext } from 'antlr4ts/ParserRuleContext'

import { IQLFilterLexer } from '@src/components/search/IQL/IQLFilterLexer'
import { EqContext, ExprContext, ExprORContext, GtContext, IQLFilterParser, LtContext, NOTContext, TextContext } from '@src/components/search/IQL/IQLFilterParser'
import SerializeVisitor from '@src/components/search/IQL/SerializeVisitor'

export interface IMatch {
    value: string
    currentSegment: string
    prefix: string,
    index: number
}

interface IOptions {
    limit?: number
}

export class SuggestionProvider {

    private static readonly unaryOperatorsCompletions: string[] = ['NOT']
    private static readonly binaryOperatorsCompletions: string[] = ['AND', 'OR']

    public suggestCompletions(query: string, fields: string[] = [], options?: IOptions) {
        const possibleMatches: string[] = []
        const fieldCompletions = fields.map(f => f + ':')
        const inputStream = new ANTLRInputStream(query)
        const lexer = new IQLFilterLexer(inputStream)
        const tokenStream = new CommonTokenStream(lexer)
        const parser = new IQLFilterParser(tokenStream)
        parser.removeErrorListeners()
        const ast = parser.filter()
        const visitor = new SerializeVisitor()
        const serializedAst: ParserRuleContext[] = visitor.visit(ast)

        const currentAST = serializedAst[serializedAst.length - 1]
        const previousAST = serializedAst[serializedAst.length - 2]

        let currentSegment = ''

        if (serializedAst.length === 0) {
            if (query.length === 0) {
                possibleMatches.push(...fieldCompletions)
                possibleMatches.push(...SuggestionProvider.unaryOperatorsCompletions)
            }
        } else {
            if (query[query.length - 1] === currentAST.text[currentAST.text.length - 1] && (currentAST instanceof ExprContext || currentAST instanceof TextContext)) {
                possibleMatches.push(...fieldCompletions)

                const offerOperatorsCompletion = !(previousAST instanceof EqContext || previousAST instanceof GtContext || previousAST instanceof LtContext || previousAST instanceof NOTContext)
                if (offerOperatorsCompletion) {
                    const offerBinaryOperatorsCompletion = (serializedAst.length > 1) && (!(previousAST instanceof ExprContext) || (previousAST instanceof ExprORContext && previousAST.OR().length === 0))

                    if (offerBinaryOperatorsCompletion) {
                        possibleMatches.push(...SuggestionProvider.binaryOperatorsCompletions)
                    }
                    possibleMatches.push(...SuggestionProvider.unaryOperatorsCompletions)
                }
            }

            currentSegment = tokenStream.getTextFromRange(currentAST._start, currentAST._stop)
        }

        const prefix = query.slice(0, -currentSegment.length)
        const matches = this.filterCompletionMatches(prefix, currentSegment, possibleMatches, options && options.limit)

        return matches
    }

    private filterCompletionMatches(prefix: string, currentSegment: string, possibleMatches: string[], limit?: number): IMatch[] {
        const matches: IMatch[] = []
        possibleMatches.forEach((possibleMatch) => {
            if (limit && matches.length === limit) return
            const matchPosition = possibleMatch.toLowerCase().indexOf(currentSegment.toLowerCase())
            if (matchPosition > -1 && currentSegment !== possibleMatch) {
                const match: IMatch = {
                    currentSegment,
                    prefix,
                    value: (possibleMatch).trim(),
                    index: matchPosition
                }
                matches.push(match)
            }
        })

        matches.sort((a, b) => {
            if (a.index === b.index) return 0
            if (a.index > b.index) return 1
            return -1
        })

        return matches
    }
}
