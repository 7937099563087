import React from 'react'
import { Field, Form } from 'react-final-form'
import { Button, FormGroup, FormText, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import axios, { CancelTokenSource } from 'axios'
import { throttle } from 'lodash'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { templateLabel, templateValue } from '@src/logic/forms/SelectHelpers'; import { required } from '@src/logic/forms/validation'
import { CommunicationCreate, TemplatesList } from '@src/logic/http/Api'
import { Communication, TemplateBrief } from '@src/types/communication'

interface IProps extends IModalPropsManager {
    projectId: string
    onCommunicationCreated: (communication: Communication) => void
}

interface INewCommunicationFormData {
    name: string
    template: TemplateBrief
}

export default class NewCommunicationModal extends React.PureComponent<IProps> {

    private loadTemplatesCancelToken: CancelTokenSource
    private readonly throttledLoadTemplates

    constructor(props) {
        super(props)

        this.throttledLoadTemplates = throttle(this.loadTemplates, 300)
    }

    private doSubmit = async (values: INewCommunicationFormData) => {
        const communicationResponse = await CommunicationCreate(this.props.projectId, {
            name: values.name,
            description: '',
            templateId: values.template.id,
            to: [],
            cc: [],
            metadata: {},
            metadataDefinitions: null,
            tags: []
        })

        this.props.onCommunicationCreated(communicationResponse.data)
        this.props.toggle()
    }

    private loadTemplates = async (value: string): Promise<TemplateBrief[]> => {
        if (this.loadTemplatesCancelToken) {
            this.loadTemplatesCancelToken.cancel()
        }

        this.loadTemplatesCancelToken = axios.CancelToken.source()
        try {
            const templatesResponse = await TemplatesList(`project: ${this.props.projectId},null AND name: "${value.replace('"', '\"')}"`, undefined, 1, 100)
            this.loadTemplatesCancelToken = null
            return templatesResponse.data
        } catch (e) {
            //
        }
    }

    public componentWillUnmount() {
        if (this.loadTemplatesCancelToken) {
            this.loadTemplatesCancelToken.cancel()
        }
    }

    public render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle} onClosed={this.props.onClosed}>
                <Form onSubmit={this.doSubmit}>
                    {({ handleSubmit, submitting }) => (
                        <>
                            <ModalHeader toggle={this.props.toggle}>New Communication</ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label for="name">Name</Label>
                                    <Field id="name" name="name" component={ValidatedInput} validate={required} />
                                </FormGroup>
                                <FormGroup>
                                    <Label for="template">Template</Label>
                                    <Field
                                        id="template"
                                        name="template"
                                        validate={required}
                                        component={ValidatedSelect}
                                        async
                                        defaultOptions
                                        loadOptions={this.throttledLoadTemplates}
                                        getOptionLabel={templateLabel}
                                        getOptionValue={templateValue}
                                    />
                                    <Field<TemplateBrief> name="template" render={({ input: { value } }) => value && <FormText className="user-text">{value.description}</FormText>} />
                                </FormGroup>
                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={handleSubmit} disabled={submitting}>Create</Button>
                            </ModalFooter>
                        </>
                    )}
                </Form>
            </Modal>
        )
    }
}
