import React from 'react'
import ReactDatePicker from 'react-datepicker'
import ReactDOM from 'react-dom'
import { Button, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'

export interface IDatePickerProps {
    selected: Date
    onChange: (value: Date) => void
    onBlur?: (event) => void
    format?: string
    startDate?: Date
    endDate?: Date
    invalid?: boolean
    disabled?: boolean
    isClearable?: boolean
    placeholder?: string
    size?: 'sm' | 'lg'
}

const CalendarContainer = ({ children }) => children ? (
    ReactDOM.createPortal(
        React.cloneElement(children, {
            className: 'react-datepicker-popper'
        }),
        document.body)
) : null

export default class DatePicker extends React.PureComponent<IDatePickerProps> {

    private handleClear = () => {
        this.props.onChange('' as any)
    }

    private getFormat = () => this.props.format || 'dd/MM/yyyy'

    public render() {
        const selectedDate = this.props.selected ? new Date(this.props.selected) : null
        const startDate = this.props.startDate ? new Date(this.props.startDate) : null
        const endDate = this.props.endDate ? new Date(this.props.endDate) : null
        return (
            <ReactDatePicker
                todayButton="Today"
                popperContainer={CalendarContainer}
                disabled={this.props.disabled}
                dateFormat={this.getFormat()}
                startDate={startDate}
                endDate={endDate}
                customInput={<DatePickerInput size={this.props.size} invalid={this.props.invalid} onClear={this.handleClear} placeholderText={this.props.placeholder} disabled={this.props.disabled} />}
                selected={selectedDate}
                onChange={this.props.onChange}
                onBlur={this.props.onBlur}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
            />
        )
    }
}

interface IDatePickerInputProps {
    value?: string
    onClick?: () => void
    invalid?: boolean
    disabled?: boolean
    onClear?: () => void
    placeholderText?: string
    size?: 'sm' | 'lg'
}

// tslint:disable-next-line:max-classes-per-file
class DatePickerInput extends React.PureComponent<IDatePickerInputProps> {

    private handleClick = () => {
        if (!this.props.disabled) this.props.onClick()
    }

    public render() {
        return (
            <InputGroup size={this.props.size}>
                <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="calendar" /></InputGroupText></InputGroupAddon>
                <Input value={this.props.value} onClick={this.handleClick} placeholder={this.props.placeholderText} disabled={this.props.disabled} readOnly invalid={this.props.invalid} />
                {this.props.onClear && <InputGroupAddon addonType="append" style={{ zIndex: 0 }}><Button color="primary" onClick={this.props.onClear} disabled={this.props.disabled}><FA icon="times" /></Button></InputGroupAddon>}
            </InputGroup>
        )
    }
}
