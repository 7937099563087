import React from 'react'
import { DropdownItem, Input, InputProps } from 'reactstrap'

import cx from 'classnames'

import { IMatch, SuggestionProvider } from '@src/components/search/SuggestionProvider'
import { setNativeValue } from '@src/logic/utils/dom'

interface IProps extends InputProps {
    fields: string[]
    value: string
    limit?: number
}

interface IState {
    activeOptionIndex: number
    suggestions: IMatch[]
    focused: boolean
}

export default class TypeaheadInput extends React.PureComponent<IProps, IState> {

    public static readonly defaultProps: Partial<IProps> = {
        fields: [],
        limit: 4
    }

    private readonly suggestionProvider: SuggestionProvider
    private readonly inputRef: React.RefObject<HTMLInputElement>
    private readonly dropMenuRef: React.RefObject<HTMLDivElement>

    constructor(props) {
        super(props)

        this.suggestionProvider = new SuggestionProvider()
        this.inputRef = React.createRef<HTMLInputElement>()
        this.dropMenuRef = React.createRef<HTMLDivElement>()

        this.state = {
            activeOptionIndex: null,
            suggestions: [],
            focused: false
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if (this.props.fields.length === 0) {
            if (prevProps.fields.length > 0) {
                this.setState({ suggestions: [], activeOptionIndex: null })
            }
        } else if (prevProps.value !== this.props.value || prevProps.fields !== this.props.fields) {
            const suggestions = this.suggestionProvider.suggestCompletions(this.props.value, this.props.fields, { limit: this.props.limit })

            this.setState({ suggestions, activeOptionIndex: suggestions.length > 0 ? 0 : null })
        }
    }

    private getSuggestionText = (suggestion: IMatch) => {
        return (suggestion.prefix + suggestion.value).trim()
    }

    private handleInputKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        const { suggestions, activeOptionIndex } = this.state
        switch (e.key) {
            case 'Tab':
                if (suggestions.length < 1) break
                e.preventDefault()
                const activeSuggestion = suggestions[Math.min(activeOptionIndex, suggestions.length - 1)]
                this.populateValue(this.getSuggestionText(activeSuggestion))
                break
            case 'ArrowDown':
                if (!this.dropMenuRef || !this.dropMenuRef.current) break
                e.preventDefault()
                this.moveSelectedDown()
                break
            case 'ArrowUp':
                if (!this.dropMenuRef || !this.dropMenuRef.current) break
                e.preventDefault()
                this.moveSelectedUp()
                break
            case 'Escape':
                this.setState({ focused: false })
                break
            default:
                if (this.props.onKeyDown) this.props.onKeyDown(e)
                break
        }
    }

    private populateValue = (value: string) => {
        setNativeValue(this.inputRef.current, value)
    }

    private moveSelectedDown = () => {
        const { activeOptionIndex, suggestions } = this.state

        if (suggestions == null || suggestions.length === 0 || activeOptionIndex === suggestions.length - 1) {
            this.setState({ activeOptionIndex: null })
        } else if (activeOptionIndex == null) {
            this.setState({ activeOptionIndex: 0 })
        } else {
            this.setState({ activeOptionIndex: activeOptionIndex + 1 })
        }
    }

    private moveSelectedUp = () => {
        const { activeOptionIndex, suggestions } = this.state

        if (suggestions == null || suggestions.length === 0 || activeOptionIndex === 0) {
            this.setState({ activeOptionIndex: null })
        } else if (activeOptionIndex == null) {
            this.setState({ activeOptionIndex: suggestions.length })
        } else {
            this.setState({ activeOptionIndex: activeOptionIndex - 1 })
        }
    }

    private setFocused = () => {
        this.setState({ focused: true })
    }

    private clearFocus = (e: React.FocusEvent) => {
        const relatedTarget = this.targetAsHTMLElement(e.relatedTarget)
        if (e.relatedTarget != null && relatedTarget.parentElement === this.dropMenuRef.current && relatedTarget.parentElement.lastElementChild !== relatedTarget) {
            this.populateValue((e.relatedTarget as HTMLSpanElement).textContent)
            this.inputRef.current.focus()
        } else {
            this.setState({ focused: false })
        }
    }

    private targetAsHTMLElement(element: EventTarget) {
        return element as HTMLElement
    }

    private getActiveSuggestionText = (): string => {
        const { suggestions, focused, activeOptionIndex } = this.state
        let activeSuggestion: IMatch

        if (suggestions.length > 0 && focused) {
            activeSuggestion = activeOptionIndex == null ? suggestions[0] : suggestions[activeOptionIndex]
            if (activeSuggestion == null || activeSuggestion.value.indexOf(activeSuggestion.currentSegment) !== 0) {
                activeSuggestion = null
            }
        }

        return activeSuggestion ? activeSuggestion.prefix + activeSuggestion.value : ''
    }

    public render() {
        const { suggestions, focused, activeOptionIndex } = this.state
        const formattedSuggestions =
            suggestions.map((s, idx) => (
                <React.Fragment key={idx}>
                    {s.prefix && <strong>{s.prefix} </strong>}
                    {s.value.split(s.currentSegment).map((x, sidx, arr) =>
                        <React.Fragment key={sidx}>{sidx === arr.length - 1 ? x : <>{x}<strong>{s.currentSegment}</strong></>}</React.Fragment>
                    )}
                </React.Fragment>)
            )

        return (
            <>
                <div style={{ position: 'relative' }} className="typeahead">
                    <Input {...this.props} placeholder={focused ? '' : this.props.placeholder} onFocus={this.setFocused} onBlur={this.clearFocus} onKeyDown={this.handleInputKeyDown} innerRef={this.inputRef} />
                    <input
                        aria-hidden
                        readOnly
                        className="form-control typeahead-hint"
                        tabIndex={-1}
                        value={this.getActiveSuggestionText()}
                    />
                </div>
                <div className={cx('dropdown-menu w-100 mt-1', { show: formattedSuggestions.length > 0 && focused })} ref={this.dropMenuRef}>
                    {formattedSuggestions.map((s, idx) => <DropdownItem tag="small" active={activeOptionIndex === idx} key={idx} className="py-0 pointer">{s}</DropdownItem>)}
                    <DropdownItem disabled tag="small" className="py-0 text-right"><span className="kbd">Tab</span> to complete,<span className="kbd">&#8679;</span><span className="kbd">&#8681;</span> to select</DropdownItem>
                </div>
            </>
        )
    }
}
