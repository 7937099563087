import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { Button, Col, Row, Table } from 'reactstrap'

import ACRow from '@src/components/access/AccessControlTableRow'
import FA from '@src/components/common/FontAwesomeIcon'
import Select, { IGroupedOptions, IOption } from '@src/components/common/Select'
import { toCapitalizedWords } from '@src/logic/utils/Strings'
import { AclEntry } from '@src/types/access'

interface IProps {
    loadPrincipals?: () => Promise<AclEntry[]>
    reset: () => void
    validOperations: string[]
}

interface IState {
    availablePrincipals: AclEntry[]
}

export default class AccessControlTableInput extends React.Component<IProps & FieldRenderProps<AclEntry[], HTMLElement>, IState> {

    constructor(props) {
        super(props)

        this.state = {
            availablePrincipals: null
        }
    }

    public componentDidMount() {
        this.props.loadPrincipals().then(res => this.setState({ availablePrincipals: res }))
    }

    private getPrincipalEntry = (id: string): AclEntry => this.props.input.value.find(x => x.id === id)

    private getPrincipalIndex = (id: string): number => this.props.input.value.findIndex(x => x.id === id)

    private renderPermissionsHeader = () => {
        const permissionCells = this.props.validOperations.map(o => <th key={o} scope="col">{toCapitalizedWords(o)}</th>)

        return (
            <thead>
                <tr>
                    <th style={{ width: 350 }} />
                    <th scope="col" className="permission-table__apply-all">Apply All</th>
                    {permissionCells}
                </tr>
            </thead>
        )
    }

    private handleToggleDeny = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x)), ...operations],
            grants: [...principal.grants.filter(x => !operations.includes(x))]
        }

        const updatedEntries = [...this.props.input.value]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.props.input.onChange(updatedEntries)
    }

    private handleToggleNeutral = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const isDenied = principal.denials.every(x => operations.includes(x))
        const isGranted = principal.grants.every(x => operations.includes(x))

        if (!isDenied && !isGranted) {
            return
        }

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x))],
            grants: [...principal.grants.filter(x => !operations.includes(x))]
        }

        const updatedEntries = [...this.props.input.value]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.props.input.onChange(updatedEntries)
    }

    private handleToggleGrant = (principalId: string, ...operations: string[]) => {
        const principal = this.getPrincipalEntry(principalId)

        const updatedPrincipal: AclEntry = {
            ...principal,
            denials: [...principal.denials.filter(x => !operations.includes(x))],
            grants: [...principal.grants.filter(x => !operations.includes(x)), ...operations]
        }

        const updatedEntries = [...this.props.input.value]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.props.input.onChange(updatedEntries)
    }

    private handleToggleAdministrator = (principalId: string) => {
        const principal = this.getPrincipalEntry(principalId)
        const updatedPrincipal: AclEntry = {
            ...principal,
            isAdministrator: !principal.isAdministrator
        }

        const updatedEntries = [...this.props.input.value]
        updatedEntries.splice(this.getPrincipalIndex(updatedPrincipal.id), 1, updatedPrincipal)

        this.props.input.onChange(updatedEntries)
    }

    private sortPrincipals(a: AclEntry, b: AclEntry) {
        const aValue = (a.name + (a.email ? a.email : a.id)).toLowerCase()
        const bValue = (b.name + (b.email ? b.email : b.id)).toLowerCase()

        if (aValue < bValue) { return -1 }
        if (aValue > bValue) { return 1 }

        return 0
    }

    private renderEntriesSection(sectionName: string, entries: AclEntry[], handleAdminChanges: boolean) {

        return (
            <>
                <tr key={sectionName} className="permission-table__group-heading">
                    <th scope="row">{sectionName}</th>
                    <th colSpan={this.props.validOperations.length + 1} />
                </tr>
                {entries.map((e, idx) =>
                    <ACRow
                        key={e.id}
                        principal={e}
                        validOperations={this.props.validOperations}
                        onDeny={this.handleToggleDeny}
                        onRevokeOrUndeny={this.handleToggleNeutral}
                        onGrant={this.handleToggleGrant}
                        onToggleAdmin={handleAdminChanges ? this.handleToggleAdministrator : undefined}
                    />
                )}
            </>
        )
    }

    private formatOptions = (): IGroupedOptions<IOption<string>> => {
        let { input: { value: entries } } = this.props
        entries = entries || []
        const { availablePrincipals } = this.state

        if (availablePrincipals === null) { return [] }

        const principalsNotOnAcl = availablePrincipals.filter(p => !entries.find(x => x.id === p.id))

        const optionGroups: IGroupedOptions<IOption<string>> = []
        const companies: AclEntry[] = [], groups: AclEntry[] = [], users: AclEntry[] = []
        principalsNotOnAcl.forEach((p) => {
            switch (p.type) {
                case 'company':
                    companies.push(p)
                    break
                case 'group':
                    groups.push(p)
                    break
                case 'user':
                    users.push(p)
                    break
            }
        })

        if (companies.length > 0) {
            optionGroups.push({ label: 'Company', options: companies.map<IOption<string>>(c => ({ label: c.name, value: c.id, principal: c })) })
        }

        if (groups.length > 0) {
            optionGroups.push({ label: 'Groups', options: groups.map<IOption<string>>(g => ({ label: g.name, value: g.id, principal: g })) })
        }

        if (users.length > 0) {
            optionGroups.push({ label: 'Users', options: users.map<IOption<string>>(u => ({ label: u.name, value: u.id, principal: u })) })
        }

        return optionGroups
    }

    private optionRenderer = (option: IOption<string>) => {
        if (option.disabled) {
            return <strong>{option.label}</strong>
        }

        if (option.principal.type === 'user') {
            return <span>{option.label} <small className="text-muted">({option.principal.email || 'no email'})</small></span>
        }

        return <span>{option.label}</span>
    }

    private handleAddPrincipal = (option: IOption<string> | IOption<string>[]) => {
        const updatedEntries = [...this.props.input.value, option instanceof Array ? option.map(o => o.principal) : option.principal].sort(this.sortPrincipals)
        this.props.input.onChange(updatedEntries)
    }

    public render() {
        const { input: { value }, meta: { touched, error } } = this.props
        const { availablePrincipals } = this.state
        const showError = touched && !!error

        const aclEntries = value || []

        return (
            <div>
                <span className="text-danger">{showError && error}</span>
                <Row className="mb-3">
                    <Col className="mb-2 mb-md-auto" xs={12} md={8} lg={6}>
                        <Select
                            aria-label="Add principals to ACL"
                            isLoading={availablePrincipals === undefined}
                            onChange={this.handleAddPrincipal}
                            options={this.formatOptions()}
                            value={undefined}
                            placeholder="Add user, groups, or your company to the ACL"
                        />
                    </Col>
                    <Col xs={12} md={4} lg={6} className="d-flex">
                        <div className="ml-auto">
                            {this.props.reset && <Button disabled={!this.props.meta.touched} className="mr-2" color="info" onClick={this.props.reset}><FA icon="undo" /> <span className="d-none d-md-inline">Reset</span></Button>}
                        </div>
                    </Col>
                </Row>
                <Table className="permission-table" responsive bordered>
                    {this.renderPermissionsHeader()}
                    <tbody>
                        {this.renderEntriesSection('Companies', aclEntries.filter(x => x.type === 'company'), false)}
                        {this.renderEntriesSection('Groups', aclEntries.filter(x => x.type === 'group'), true)}
                        {this.renderEntriesSection('Users', aclEntries.filter(x => x.type === 'user'), true)}
                    </tbody>
                </Table>
            </div>
        )
    }
}
