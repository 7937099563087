import React from 'react'
import { AnyAction, Dispatch } from 'redux'
import { createAction } from 'redux-actions'
import { ThunkAction, ThunkDispatch } from 'redux-thunk'

import log from 'loglevel'

import * as Actions from '@src/actions/Actions'
import FA from '@src/components/common/FontAwesomeIcon'
import { DocumentCreate, DocumentRevise, SandboxDeleteFile, SandboxListFiles, SandboxSignUpload } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Manager } from '@src/logic/storage/gcs/GCSUploadManager'

import { promiseAllCapped } from '@src/logic/utils/Promise'
import { Api } from '@src/types/api'
import { RootState } from '@src/types/models'
import { Sandbox } from '@src/types/sandbox'

export const toggleSandbox = createAction<boolean>(Actions.SANDBOX_TOGGLE)
const clearCompletedUploads = createAction(Actions.SANDBOX_CLEAR_COMPLETED_UPLOADS)
const registerUpload = createAction<Sandbox.Upload>(Actions.SANDBOX_UPLOAD_CREATE)
const loadingUploads = createAction<boolean>(Actions.SANDBOX_LOADING_UPLOADS)
const uploadWillPause = createAction<Sandbox.Actions.UploadPause>(Actions.SANDBOX_UPLOAD_WILL_PAUSE)
export const setUploadState = createAction<Sandbox.Actions.UploadState>(Actions.SANDBOX_UPLOAD_SET_STATE)
const removeUploads = createAction<string[]>(Actions.SANDBOX_UPLOAD_REMOVE)
export const setDocumentToRevise = createAction<Sandbox.Actions.ReviseDocument>(Actions.SANDBOX_REVISE_DOCUMENT)
export const reportUploadProgress = createAction<Sandbox.Actions.ReportUploadProgress>(Actions.SANDBOX_UPLOAD_PROGRESS)

export const newUploads = (files: File[]) => {
    return async (dispatch: Dispatch) => {
        const uploadFile = async (file: File): Promise<Sandbox.Upload> => {
            const sandboxSignReq = {
                fileName: encodeURI(file.name),
                contentType: file.type || 'text/plain; charset=us-ascii',
                length: file.size
            }
            const signedUrlResponse = (await SandboxSignUpload(sandboxSignReq)).data

            const googleUpload = await Manager.newSignedUpload(signedUrlResponse.name, signedUrlResponse.url, file, (id, bytesCompleted) => dispatch(reportUploadProgress({ id, bytesCompleted })), id => dispatch(setUploadState({ id, state: 'completed' })))

            return {
                id: googleUpload.id,
                contentType: sandboxSignReq.contentType,
                filename: file.name,
                bytesUploaded: 0,
                bytesTotal: sandboxSignReq.length,
                pausingAtByte: 0,
                state: 'running'
            }
        }

        const uploads = await Promise.all(files.map(uploadFile))

        uploads.forEach((upload) => {
            dispatch(registerUpload(upload))
            Manager.startUpload(upload.id)
        })
    }
}

export const pauseUploads = (uploadIds: string[]) => {
    return (dispatch: Dispatch) => {
        uploadIds.forEach((id) => {
            const pauseAtByte = Manager.pauseUpload(id, uId => dispatch(setUploadState({ id: uId, state: 'paused' })))
            dispatch(uploadWillPause({ id, pauseAtByte }))
        })
    }
}

export const resumeUploads = (uploadIds: string[]) => {
    return (dispatch: Dispatch) => {
        uploadIds.forEach((id) => {
            if (Manager.resumeUpload(id)) {
                dispatch(setUploadState({ id, state: 'running' }))
            }
        })
    }
}

export const deleteUploads = (...ids: string[]) => {
    return async (dispatch: Dispatch) => {
        const completedIds = ids.filter(Manager.cancelUpload)
        dispatch(removeUploads(completedIds))

        if (completedIds.length === ids.length) {
            return
        }

        const remoteDeleteIds = ids.filter(id => !completedIds.includes(id))
        await Promise.all(remoteDeleteIds.map(id => SandboxDeleteFile(id)))
        dispatch(removeUploads(remoteDeleteIds))
    }
}

export const listFiles = (): ThunkAction<Promise<void>, RootState, null, AnyAction> => {
    return async (dispatch: Dispatch) => {
        dispatch(loadingUploads(true))

        try {
            const sandboxFiles = await SandboxListFiles()
            dispatch(clearCompletedUploads())

            sandboxFiles.data.forEach(file => dispatch(registerUpload({
                bytesTotal: file.size,
                bytesUploaded: file.size,
                contentType: file.contentType,
                filename: file.metadata.filename,
                id: file.uuid,
                state: 'completed',
                pausingAtByte: file.size
            })))
        } catch (e) {
            //
        }

        dispatch(loadingUploads(false))
    }
}

export interface IReviseDocumentIdAndRequest {
    documentId: string
    request: Api.Request.ReviseDocument
}

export const reviseDocuments = (toRevise: IReviseDocumentIdAndRequest[]) => {
    return async (dispatch: Dispatch) => {
        try {
            await promiseAllCapped(toRevise, x => DocumentRevise(x.documentId, x.request), 5)
            NotificationService.info('Successfully revised document')
            dispatch(removeUploads(toRevise.reduce<string[]>((ids, doc) => ids.concat(doc.request.revisions.map(r => r.fileUuid)), [])))
        } catch (err) {
            dispatch(removeUploads(Array.from<Api.Request.NewRevision>([]).concat(...toRevise.map(r => r.request.revisions)).map(rev => rev.fileUuid)))
            NotificationService.error('An error occured while revising the document. Changes were not saved.')
        }
    }
}

export const submitNewDocumentRequests = (projectId: string, newDocuments: Api.Request.NewDocument[]) => {
    return async (dispatch: ThunkDispatch<RootState, null, AnyAction>) => {
        const notificationId = NotificationService.info(<div><span>Creating documents...  </span><FA icon="spinner-third" spin /></div>, { autoClose: false })

        try {
            await promiseAllCapped(newDocuments, doc => DocumentCreate(projectId, doc), 5)
            NotificationService.updateToast(notificationId, `Successfully created ${newDocuments.length} documents`, { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })

            dispatch(removeUploads(newDocuments.reduce<string[]>((ids, doc) => doc.revisions.map(r => r.fileUuid), [])))
        } catch (err) {
            log.error('Failed to create new documents', { projectId, newDocuments, response: err.response })
            NotificationService.updateToast(notificationId, 'Failed to create new documents. Refresh and try again.', { type: 'error' })
        } finally {
            dispatch(listFiles())
        }
    }
}
