import React from 'react'
import { FormSpy } from 'react-final-form'

import { debounce } from 'lodash'

import useConstant from '@src/hooks/useConstant'
import useUpdateEffect from '@src/hooks/useUpdateEffect'

interface AutoSaveProps {
    wait: number
    save: () => void
}

const AutoSave: React.FC<any & AutoSaveProps> = ({ values, dirty, save, wait, submitting }) => {
    const debouncedSave = useConstant(() => debounce(save, wait))

    useUpdateEffect(
        () => {
            if ((!submitting && dirty)) {
                debouncedSave()
            }
        },
        [values])

    return null
}

export default (props: AutoSaveProps) => <FormSpy {...props} subscription={{ values: true, dirty: true, submitting: true }} component={AutoSave} />
