import React from 'react'

import cx from 'classnames'

interface IProps extends React.HTMLProps<HTMLDivElement> {
    on: boolean
    label?: string
    onToggle: () => void
}

export default class ToggleButton extends React.PureComponent<IProps> {

    public render() {
        const { on, label, onToggle, className, ...divProps } = this.props
        return (
            <div className={cx('toggle', className)}>
                <input className="toggle__control" type="checkbox" checked={on} readOnly />
                <label className="toggle__label" onClick={onToggle}>
                    {label}
                </label>
            </div>
        )
    }
}
