import React from 'react'
import { Button, Card, Col, Container, Row } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { auth } from '@src/logic/auth/AuthService'
import { RevisionsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
}

export default class DownloadRevisionPage extends React.PureComponent<IProps> {

    constructor(props) {
        super(props)
    }

    public componentDidMount() {
        this.downloadRevision()
    }

    private downloadRevision = () => {
        const { revision } = this.props
        downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
    }

    public render() {
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Card body className="text-center">
                            <div className="mb-3">
                                <FA size="4x" icon="cloud-download" />
                            </div>
                            <h1>Downloading your document now.</h1>
                            <div className="mb-3">
                                <small>If your download doesn't start click the button below</small>
                            </div>
                            <div>
                                <Button color="primary" onClick={this.downloadRevision}>Download</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}
