// Project Toolbar
export const ProjectToolbarDocuments = 'project-toolbar_documents'
export const ProjectToolbarTransmittals = 'project-toolbar_transmittals'
export const ProjectToolbarEmails = 'project-toolbar_emails'
export const ProjectToolbarRegisters = 'project-toolbar_registers'
export const ProjectToolbarCommunications = 'project-toolbar_communications'
export const ProjectToolbarTemplates = 'project-toolbar_templates'
export const ProjectToolbarUsers = 'project-toolbar_users'
export const ProjectToolbarSettings = 'project-toolbar_settings'

// Document Toolbar
export const DocumentToolbarPreview = 'document-toolbar_preview'
export const DocumentToolbarEdit = 'document-toolbar_edit'
export const DocumentToolbarAccess = 'document-toolbar_access'
export const DocumentToolbarWidget = 'document-toolbar_widget'
export const DocumentToolbarDownload = 'document-toolbar_download'
export const DocumentToolbarDelete = 'document-toolbar_delete'
export const DocumentToolbarRevise = 'document-toolbar_revise'

// Company Toolbar
export const CompanyToolbarProjects = 'company-toolbar_projects'
export const CompanyToolbarGroups = 'company-toolbar_groups'
export const CompanyToolbarUsers = 'company-toolbar_users'
export const CompanyToolbarTemplates = 'company-toolbar_templates'

// Transmittal Toolbar
export const TransmittalToolbarView = 'transmittal-toolbar_view'
export const TransmittalToolbarAccess = 'transmittal-toolbar_access'

// Document Table
export const DocumentTableColumnName = 'document-table-col_name'
export const DocumentTableRowSelect = 'document-table-row_select'
export const DocumentTableRowExpand = 'document-table-row_view'
export const DocumentTableRowView = 'document-table-row_view'
export const DocumentTableRowEdit = 'document-table-row_edit'
export const DocumentTableRowRevise = 'document-table-row_revise'
export const DocumentTableRowCheckout = 'document-table-row_checkout'
export const DocumentTableRowDownload = 'document-table-row_download'

// Transmittal Table
export const TransmittalTableRowActions = 'transmittal-table-row_actions'
export const TransmittalTableRowView = 'transmittal-table-row_view'
export const TransmittalTableRowDelete = 'transmittal-table-row_delete'
export const TransmittalTableRowDownload = 'transmittal-table-row_download'

// Project Settings Menu
export const ProjectSettingsMenuGeneral = 'project-settings-menu-general'
export const ProjectSettingsMenuMetadata = 'project-settings-menu-metadata'
export const ProjectSettingsMenuAccess = 'project-settings-menu-access'
export const ProjectSettingsMenuDefaultAccess = 'project-settings-menu-default-access'
export const ProjectSettingsMenuDefaultAccessDocuments = 'project-settings-menu-default-access-documents'
export const ProjectSettingsMenuDefaultAccessRevisions = 'project-settings-menu-default-access-revisions'
export const ProjectSettingsMenuDefaultAccessCommunications = 'project-settings-menu-default-access-communications'
export const ProjectSettingsMenuDefaultAccessTransmittals = 'project-settings-menu-default-access-transmittals'
export const ProjectSettingsMenuDefaultAccessInboundEmails = 'project-settings-menu-default-access-inbound-emails'
export const ProjectSettingsMenuDefaultAccessRegisters = 'project-settings-menu-default-access-registers'
