import React from 'react'

import cx from 'classnames'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import Image from '@src/components/common/Image'

interface IProps {
    url: string
    className?: string
    fallbackIcon?: IPFontawesomeIcon
}

export default class ProfilePicture extends React.PureComponent<IProps> {
    public render() {
        const { className, url, fallbackIcon } = this.props

        return url
            ? <Image src={url} width="200" height="200" responsive rounded className={className} />
            : (
                <div className={cx('text-center d-flex', className)} style={{ width: 200, height: 200 }}>
                    <FA icon={fallbackIcon ?? 'user'} className="flex-grow-1 align-self-center" size="7x" />
                </div>
            )
    }
}
