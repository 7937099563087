import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFeedback, Input, InputProps } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

export default class ValidatedInput<TValue extends string | number | string[]> extends React.PureComponent<InputProps & (WrappedFieldProps | FieldRenderProps<TValue>)> {
    public render() {
        const { input, meta: { touched, error, ...otherMeta }, type, ...htmlInputProps } = this.props
        const submitError = (otherMeta as FieldRenderProps<any, HTMLInputElement>['meta']).submitError
        const showError = touched && (!!error || (!!submitError && !(otherMeta as any).dirtySinceLastSubmit))

        return (
            <>
                <Input {...htmlInputProps} {...input} {...(type && { type })} invalid={showError} {...showError ? { 'aria-invalid': true } : {}} />
                <FormFeedback>{showError && (error || submitError)}</FormFeedback>
            </>
        )
    }
}
