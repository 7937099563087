// tslint:disable:jsx-no-lambda

import React from 'react'
import { connect, useSelector } from 'react-redux'
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router'
import { Card, Col, Container, Nav, NavItem, NavLink, Row, UncontrolledCollapse } from 'reactstrap'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import ProjectToolbar from '@src/components/project/ProjectToolbar'
import CollaboratorSettingsSection from '@src/components/project/settings/CollaboratorSettingsSection'
import DefaultAccessSection from '@src/components/project/settings/DefaultAccessSection'
import GeneralSettingSection from '@src/components/project/settings/GeneralSettingsSection'
import MetadataSettingsSection from '@src/components/project/settings/MetadataSettingsSection'
import ProjectAccessSection from '@src/components/project/settings/ProjectAccessSection'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IConnectedState {
    company: Company
    project: Project
}

const ProjectSettingsPage: React.FC<RouteComponentProps> = ({ location: { pathname } }) => {

    const { company, project } = useSelector<RootState, IConnectedState>(state => ({ company: state.session.company, project: state.projects.active }))

    return (
        <>
            <ProjectBanner project={project} />
            <ProjectToolbar currentRoutePath={pathname} project={project} />
            <Container className="mt-3" fluid>
                <Row className="mb-5">
                    <Col md={3}>
                        <div className="sticky-top" style={{ top: '1rem' }}>
                            <Card body>
                                <Nav vertical pills>
                                    <NavLink disabled>Common</NavLink>
                                    <NavLink tag={Link} to={Routes.projectSettingsGeneral(project.id)} active={pathMatchesCurrentRoute(Routes.PROJECT_SETTINGS_GENERAL, true)} data-tour={TourTags.ProjectSettingsMenuGeneral}><FA icon="cogs" />&nbsp;&nbsp;General</NavLink>
                                    <NavLink tag={Link} to={Routes.projectSettingsMetadata(project.id)} active={pathMatchesCurrentRoute(Routes.PROJECT_SETTINGS_METADATA, true)} data-tour={TourTags.ProjectSettingsMenuMetadata}><FA icon="list" />&nbsp;&nbsp;Metadata</NavLink>
                                    <NavLink disabled>Collaborator</NavLink>
                                    <NavLink tag={Link} to={Routes.projectSettingsCollaborator(project.id)} active={pathMatchesCurrentRoute(Routes.PROJECT_SETTINGS_DEFAULT_ACCESS)}><FA icon="users-cog" /> General</NavLink>
                                    <NavLink tag={Link} to={Routes.projectSettingsAccess(project.id)} active={pathMatchesCurrentRoute(Routes.PROJECT_SETTINGS_ACCESS, true)} data-tour={TourTags.ProjectSettingsMenuAccess}><FA icon="lock" />&nbsp;&nbsp;Project Access</NavLink>
                                    <NavLink id="access-settings-tab" href="#" data-tour={TourTags.ProjectSettingsMenuDefaultAccess}><FA icon="lock-alt" />&nbsp;&nbsp;Default Access</NavLink>
                                    <UncontrolledCollapse toggler="access-settings-tab" {...(pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.None), false) ? ({ isOpen: true }) : ({}))}>
                                        <Nav className="pl-3" vertical pills>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Document)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Document), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessDocuments}>
                                                <small>Documents</small>
                                            </NavLink>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Revision)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Revision), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessRevisions}>
                                                <small>Revisions</small>
                                            </NavLink>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Communication)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Communication), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessCommunications}>
                                                <small>Communications</small>
                                            </NavLink>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Transmittal)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Transmittal), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessTransmittals}>
                                                <small>Transmittals</small>
                                            </NavLink>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.InboundEmail)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.InboundEmail), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessInboundEmails}>
                                                <small>Inbound Emails</small>
                                            </NavLink>
                                            <NavLink tag={Link} to={Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Register)} active={pathMatchesCurrentRoute(Routes.projectSettingsDefaultAccess(project.id, Routes.ProjectAccessEntityType.Register), true)} data-tour={TourTags.ProjectSettingsMenuDefaultAccessRegisters}>
                                                <small>Registers</small>
                                            </NavLink>
                                        </Nav>
                                    </UncontrolledCollapse>
                                </Nav>
                            </Card>
                        </div>
                    </Col>
                    <Col md={9}>
                        <Switch>
                            <Route path={Routes.PROJECT_SETTINGS_GENERAL} exact render={routeProps => <GeneralSettingSection />} />
                            <Route path={Routes.PROJECT_SETTINGS_COLLABORATOR} exact render={routeProps => <CollaboratorSettingsSection {...routeProps} />} />
                            <Route path={Routes.PROJECT_SETTINGS_ACCESS} exact render={routeProps => <ProjectAccessSection {...routeProps} project={project} company={company} />} />
                            <Route path={Routes.PROJECT_SETTINGS_METADATA} exact render={routeProps => <MetadataSettingsSection {...routeProps} company={company} project={project} />} />
                            <Route path={Routes.PROJECT_SETTINGS_DEFAULT_ACCESS} component={routeProps => <DefaultAccessSection {...routeProps} company={company} project={project} />} />
                            <Redirect to={Routes.PROJECT_SETTINGS_GENERAL} />
                        </Switch>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ProjectSettingsPage
