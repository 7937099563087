import React from 'react'
import { Dropdown, DropdownMenu, DropdownToggle } from 'reactstrap'

import { IconAvatar } from '@src/components/common/Avatar'
import HomeDropdownItem from '@src/components/navigation/HomeDropdownItem'
import ProjectDropdownItem from '@src/components/navigation/ProjectDropdownItem'
import { Project } from '@src/types/project'

interface IProps {
    active?: Project
    projects: Project[]
}

interface IState {
    open: boolean
}

export default class ProjectMenu extends React.PureComponent<IProps, IState> {
    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    private readonly toggle = () => {
        this.setState({
            open: !this.state.open
        })
    }

    public renderProjectItems = () => {
        const { active, projects } = this.props
        const projectItems = projects.filter(x => active ? x.id !== active.id : true).map(p => (<ProjectDropdownItem key={p.id} project={p} />))

        return active ? [(
            <HomeDropdownItem key="__home" />
        ), ...projectItems] : projectItems
    }

    public renderSelectedItem = () => {
        return this.props.active ? (
            <DropdownToggle tag="a" className="project__trigger" role="button">
                <div className="avatar avatar--xs">{this.props.active.settings?.code || this.props.active.code}</div>
            </DropdownToggle>
        ) : (
            <DropdownToggle tag="a" className="project__trigger" role="button">
                <IconAvatar size="xs" icon="home" />
            </DropdownToggle>
        )
    }

    public render() {
        return (
            <Dropdown className="project header__project" isOpen={this.state.open} toggle={this.toggle}>
                {this.renderSelectedItem()}
                <DropdownMenu className="project__menu">
                    {this.renderProjectItems()}
                </DropdownMenu>
            </Dropdown>
        )
    }
}
