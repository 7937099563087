import React from 'react'
import { Collapse, CollapseProps } from 'reactstrap'

export default class CollapsibleRow extends React.PureComponent<CollapseProps> {

    public render() {
        return (
            <Collapse {...this.props as any} className="selectable-content__row--collapsed">
                {this.props.children}
            </Collapse>
        )
    }
}
