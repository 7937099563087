import React from 'react'
import { Tooltip } from 'reactstrap'

import Popper from 'popper.js'

import FA from '@src/components/common/FontAwesomeIcon'
import { metaAttributes } from '@src/components/metadata/attributes'
import { IMetadataDefinition } from '@src/types/metadata'

interface IProps {
    id: string
    definition: IMetadataDefinition
    container?: string | HTMLElement
    delay?: number | {show: number, hide: number}
    className?: string
    innerClassName?: string
    autohide?: boolean
    placement?: Popper.Placement
    modifiers?: Popper.Modifiers
}

interface IState {
    open: boolean
}

export default class MetadataTooltip extends React.Component<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            open: false
        }
    }

    private toggle = () => {
        this.setState({ open: !this.state.open })
    }

    private renderRequirements = () => {
        const { definition } = this.props

        const attributes = metaAttributes(definition)

        return attributes.length === 0 ? 'No requirements' : (
            <div>
                <p className="text-left mb-0">Has attributes:</p>
                <ul className="mx-2 pl-3">
                    {attributes.map((a, idx) => <li key={idx} className="text-left">{a}</li>)}
                </ul>
            </div>
        )
    }

    public render() {
        const { id, definition, ...tooltipProps } = this.props
        return (
            <>
                <FA id={id} icon="question-circle" />
                <Tooltip target={id} isOpen={this.state.open} toggle={this.toggle} {...tooltipProps}>
                    {this.renderRequirements()}
                </Tooltip>
            </>
        )
    }
}

