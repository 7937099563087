import React from 'react'
import { ListGroup, ListGroupItem, ListGroupItemText } from 'reactstrap'

import { UserAvatar } from '@src/components/common/Avatar'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { Group } from '@src/types/principal'

interface IProps {
    group: Group
}

export default class GroupMemberList extends React.PureComponent<IProps> {
    public render() {
        return (
            <ListGroup flush>
                {this.props.group.members.map(member => (
                    <ListGroupItem key={member.id}>
                        <div className="d-flex align-items-center">
                            <UserAvatar size="xs" imageUrl={member.profilePictureLink} firstName={member.firstName} lastName={member.lastName} />
                            <ListGroupItemText className="mb-0 ml-2">{member.firstName} {member.lastName} {mutedValue(`(${member.email})`)}</ListGroupItemText>
                        </div>
                    </ListGroupItem>
                ))}
            </ListGroup>
        )
    }
}
