// tslint:disable:max-classes-per-file
import React from 'react'
import { connect } from 'react-redux'
import { Col, FormGroup, FormText, Label, Row } from 'reactstrap'
import { ConfigProps, Field, FormSection, InjectedFormProps, reduxForm } from 'redux-form'

import { IOption } from '@src/components/common/Select'
import ValidatedCheckboxRadio from '@src/components/common/ValidatedCheckboxRadio'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import DefinitionTemplate from '@src/components/metadata/definitions/DefinitionTemplate'
import { AutoNumberDefinition, BoolDefinition, CommitmentLinksDefinition, CompanyEntityProps, CompanyLinksDefinition, CompanyPropertyDefinition, DateDefinition, DocumentEntityProps, DocumentLinksDefinition, DocumentPropertyDefinition, EmailEntityProps, EmailLinksDefinition, EmailPropertyDefinition, IAutoNumberOptions, ICommitmentLinksOptions, ICompanyLinkOptions, IDateOptions, IDocumentLinkOptions, IDocumentPropertyOptions, IEmailLinkOptions, IEntityPropertyOptions, IMetadataDefinition, INumericOptions, IPaymentClaimLinksOptions, ISelectOptions, ITextOptions, ITransmittalLinkOptions, IUpdateMetadataDefinition, IUserLinkOptions, MetadataTypeDisplayMap, MetadataTypes, NumericDefinition, PaymentClaimLinksDefinition, SelectDefinition, TextDefinition, TransmittalEntityProps, TransmittalLinksDefinition, TransmittalPropertyDefinition, UserEntityProps, UserLinksDefinition, UserPropertyDefinition } from '@src/types/metadata'
import { RootState } from '@src/types/models'

export const renderMetadataDefinition = (props: ICommonProps<IMetadataDefinition, any>) =>  {
    switch (props.definition.type) {
        case MetadataTypes.AutoNumber:
            return <WrappedAutoNumberMetadataDefinition {...props as any} />
        case MetadataTypes.Bool:
            return <WrappedBoolMetadataDefinition {...props as any} />
        case MetadataTypes.Numeric:
            return <WrappedNumericMetadataDefinition {...props as any} />
        case MetadataTypes.Text:
            return <WrappedTextMetadataDefinition {...props as any} />
        case MetadataTypes.Date:
            return <WrappedDateMetadataDefinition {...props as any} />
        case MetadataTypes.Select:
            return <WrappedSelectMetadataDefinition {...props as any} />
        case MetadataTypes.DocumentLinks:
            return <WrappedDocumentLinksMetadataDefinition {...props as any} />
        case MetadataTypes.DocumentProperty:
            return <WrappedDocumentPropertyMetadataDefinition {...props as any} />
        case MetadataTypes.TransmittalLinks:
            return <WrappedTransmittalLinksMetadataDefinition {...props as any} />
        case MetadataTypes.TransmittalProperty:
            return <WrappedTransmittalPropertyMetadataDefinition {...props as any} />
        case MetadataTypes.CompanyLinks:
            return <WrappedCompanyLinksMetadataDefinition {...props as any} />
        case MetadataTypes.CompanyProperty:
            return <WrappedCompanyPropertyMetadataDefinition {...props as any} />
        case MetadataTypes.UserLinks:
            return <WrappedUserLinksMetadataDefinition {...props as any} />
        case MetadataTypes.UserProperty:
            return <WrappedUserPropertyMetadataDefinition {...props as any} />
        case MetadataTypes.EmailLinks:
            return <WrappedEmailLinksMetadataDefinition {...props as any} />
        case MetadataTypes.EmailProperty:
            return <WrappedEmailPropertyMetadataDefinition {...props as any} />
        case MetadataTypes.CommitmentLinks:
            return <WrappedCommitmentLinksMetadataDefinition {...props as any} />
        case MetadataTypes.PaymentClaimLinks:
            return <WrappedPaymentClaimLinksMetadataDefinition {...props as any} />
        default:
            return null
    }
}

function mapStateToProps<TProps extends ICommonProps<IMetadataDefinition<any>, any>>(state: RootState, ownProps: TProps): TProps & Partial<ConfigProps<any, TProps>> {
    return {
        ...ownProps,
        form: ownProps.definition.key
    }
}

export interface ICommonFieldText {
    name: string
    key: string
    description: string
}

function wrapInForm<TDef extends IMetadataDefinition<any>, TFormData extends IMetadataDefinitionFormData<any>, TProps extends ICommonProps<TDef, any> & Partial<ConfigProps<TFormData, TProps>>>(component: React.ComponentClass<TProps & InjectedFormProps<TFormData, TProps>>) {
    return connect(mapStateToProps, undefined, undefined)(reduxForm<TFormData, TProps>({})(component) as any)
}

interface ICommonSettingsProps {
    fieldLabels?: ICommonFieldText
    fieldDescriptions?: ICommonFieldText
    disabled?: boolean
}

class CommonMetadataSettings extends React.PureComponent<ICommonSettingsProps> {

    public render() {
        const { fieldLabels: labels, fieldDescriptions: descriptions, disabled } = this.props

        return (
            <>
                <legend className="col-form-label">General</legend>
                <FormGroup>
                    <Label>{labels ? labels.name : 'Name'}</Label>
                    <Field name="name" component={ValidatedInput} disabled={disabled} />
                    <FormText>{descriptions ? descriptions.name : 'Name of the field.'}</FormText>
                </FormGroup>
                <FormGroup>
                    <Label>{labels ? labels.key : 'Search Key'}</Label>
                    <Field name="key" component={ValidatedInput} disabled={disabled} />
                    <FormText>{descriptions ? descriptions.key : 'This will be used to identify this field when searching.'}</FormText>
                </FormGroup>
                <FormGroup>
                    <Label>{labels ? labels.description : 'Description'}</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" disabled={disabled} />
                    <FormText>{descriptions ? descriptions.description : 'A short description which indicates how the field should be used.'}</FormText>
                </FormGroup>
            </>
        )
    }
}

export interface IMetadataDefinitionFormData<TOps = {}> {
    name: string
    key: string
    description: string
    isRequired: boolean
    options?: TOps
}

interface ICommonProps<T extends IMetadataDefinition<TOptions>, TOptions> {
    definition: T
    onRetire?: (key: string) => void
    onUpdate?: <TUpdate extends IUpdateMetadataDefinition<TOptions>>(key: string, update: TUpdate) => Promise<void>
    fieldLabels?: ICommonFieldText
    fieldDescriptions?: ICommonFieldText
    disabled?: boolean
}

abstract class MetadataDefinitionComponent<TDef extends IMetadataDefinition<any>, TOptions, TFormOpts> extends React.PureComponent<ICommonProps<TDef, TOptions> & InjectedFormProps<IMetadataDefinitionFormData<TFormOpts>, ICommonProps<TDef, TOptions>>> {

    constructor(props) {
        super(props)
        props.initialize(this.initialValues(props.definition))
    }

    public abstract initialValues(definition: TDef): IMetadataDefinitionFormData<TFormOpts>
}

function commonInitialValues(definition: IMetadataDefinition): Omit<IMetadataDefinitionFormData, keyof { options }> {
    return {
        key: definition.key,
        name: definition.name,
        description: definition.description,
        isRequired: definition.isRequired
    }
}

export class BoolMetadataDefinition extends MetadataDefinitionComponent<BoolDefinition, {}, {}> {

    public initialValues(definition: BoolDefinition): IMetadataDefinitionFormData<{}> {
        return {
            ...commonInitialValues(definition),
            options: {}
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData, dispatch, props: ICommonProps<BoolDefinition, {}>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {}
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="check-circle" tooltip="Check (True/False)" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                        </Label>
                    </FormGroup>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface INumericOpts {
    enforceRange: boolean
    minValue: number
    maxValue: number
    format: string
}

export class NumericMetadataDefinition extends MetadataDefinitionComponent<NumericDefinition, INumericOptions, INumericOpts> {

    public initialValues(definition: NumericDefinition): IMetadataDefinitionFormData<INumericOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                enforceRange: definition.options.enforceRange,
                minValue: definition.options.minValue,
                maxValue: definition.options.maxValue,
                format: definition.options.format
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<INumericOpts>, dispatch, props: ICommonProps<NumericDefinition, INumericOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                enforceRange: values.options.enforceRange,
                minValue: values.options.minValue,
                maxValue: values.options.maxValue,
                format: values.options.format
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="superscript" tooltip="Numeric" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup check>
                            <Label check>
                                <Field name="enforceRange" component={ValidatedInput} type="checkbox" disabled={disabled} />
                                Enforce Range
                                </Label>
                        </FormGroup>
                        <Row className="mt-3">
                            <Col>
                                <FormGroup>
                                    <Label>Minimum Value</Label>
                                    <Field name="minValue" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Maximum Value</Label>
                                    <Field name="maxValue" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Format</Label>
                            <Field name="format" component={ValidatedInput} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface ITextOpts {
    minLength: number
    maxLength: number
}

export class TextMetadataDefinition extends MetadataDefinitionComponent<TextDefinition, ITextOpts, ITextOptions> {

    public initialValues(definition: TextDefinition): IMetadataDefinitionFormData<ITextOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                minLength: definition.options.minLength,
                maxLength: definition.options.maxLength
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ITextOpts>, dispatch, props: ICommonProps<TextDefinition, ITextOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                minLength: values.options.minLength,
                maxLength: values.options.maxLength,
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="font" tooltip="Text" name={definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <Row className="mt-3">
                            <Col>
                                <FormGroup>
                                    <Label>Minimum Length</Label>
                                    <Field name="minLength" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Maximum Length</Label>
                                    <Field name="maxLength" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const commonDateOptions: IOption<string>[] = [
    {
        label: 'DD/MM/YYYY',
        value: 'DD/MM/YYYY'
    },
    {
        label: 'YYYY-MM-DDTHH:mm:ssZ',
        value: 'YYYY-MM-DDTHH:mm:ssZ'
    }

]

interface IDateOpts {
    enforceRange: boolean
    minValue?: Date
    maxValue?: Date
    format: IOption<string>
}

export class DateMetadataDefinition extends MetadataDefinitionComponent<DateDefinition, IDateOptions, IDateOpts> {

    public initialValues(definition: DateDefinition): IMetadataDefinitionFormData<IDateOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                enforceRange: definition.options.enforceRange,
                minValue: definition.options.minValue,
                maxValue: definition.options.maxValue,
                format: { label: definition.options.format, value: definition.options.format }
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IDateOpts>, dispatch, props: ICommonProps<DateDefinition, IDateOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                enforceRange: values.options.enforceRange,
                minValue: values.options.minValue,
                maxValue: values.options.maxValue,
                format: values.options.format.value
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="calendar" tooltip="Date" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup check>
                            <Label check>
                                <Field name="enforceRange" component={ValidatedInput} type="checkbox" disabled={disabled} />
                                Enforce Range
                                </Label>
                        </FormGroup>
                        <Row className="mt-3">
                            <Col>
                                <FormGroup>
                                    <Label>Minimum Date</Label>
                                    <Field name="minValue" component={ValidatedDatePicker} disabled={disabled} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Maximum Date</Label>
                                    <Field name="maxValue" component={ValidatedDatePicker} disabled={disabled} />
                                </FormGroup>
                            </Col>
                        </Row>
                        <FormGroup>
                            <Label>Format</Label>
                            <Field name="format" component={ValidatedSelect} creatable options={commonDateOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface ISelectOpts {
    enforceValues: boolean
    isMultiselect: boolean,
    values: IOption<string>[]
}

export class SelectMetadataDefinition extends MetadataDefinitionComponent<SelectDefinition, ISelectOptions, ISelectOpts> {

    public initialValues(definition: SelectDefinition): IMetadataDefinitionFormData<ISelectOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                enforceValues: definition.options.enforceValues,
                isMultiselect: definition.options.isMultiselect,
                values: definition.options.values.map(v => ({ label: v, value: v }))
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ISelectOpts>, dispatch, props: ICommonProps<SelectDefinition, ISelectOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                enforceValues: values.options.enforceValues,
                isMultiselect: values.options.isMultiselect,
                values: values.options.values.map(x => x.value)
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="list" tooltip="Option list" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup check>
                            <Label check>
                                <Field name="enforceValues" component={ValidatedInput} type="checkbox" disabled={disabled} />
                                Enforce Options
                                </Label>
                        </FormGroup>
                        <FormGroup check>
                            <Label check>
                                <Field name="isMultiselect" component={ValidatedInput} type="checkbox" disabled={disabled} />
                                Multi Select
                                </Label>
                        </FormGroup>
                        <FormGroup>
                            <Label>Options</Label>
                            <Field name="values" component={ValidatedSelect} isMulti creatable isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface IDocumentLinkOpts {
    limit?: number
}

export class DocumentLinksMetadataDefinition extends MetadataDefinitionComponent<DocumentLinksDefinition, IDocumentLinkOptions, IDocumentLinkOpts> {

    public initialValues(definition: DocumentLinksDefinition): IMetadataDefinitionFormData<IDocumentLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IDocumentLinkOpts>, dispatch, props: ICommonProps<DocumentLinksDefinition, IDocumentLinkOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit,
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="file-alt" tooltip="Document Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const DocumentPropertySelectOptions: IOption<DocumentEntityProps>[] = [
    {
        label: 'Author',
        value: DocumentEntityProps.Author
    },
    {
        label: 'Created By',
        value: DocumentEntityProps.CreatedBy
    },
    {
        label: 'Created Date',
        value: DocumentEntityProps.CreatedDate
    },
    {
        label: 'Description',
        value: DocumentEntityProps.Description
    },
    {
        label: 'Metadata',
        value: DocumentEntityProps.MetaData
    },
    {
        label: 'Name',
        value: DocumentEntityProps.Name
    },
    {
        label: 'Published',
        value: DocumentEntityProps.Published
    },
    {
        label: 'Revision Date',
        value: DocumentEntityProps.RevisionDate
    },
    {
        label: 'Revision Number',
        value: DocumentEntityProps.RevisionNo
    }
]

interface IDocumentPropertyOpts {
    property: typeof DocumentPropertySelectOptions[0]
}

export class DocumentPropertyMetadataDefinition extends MetadataDefinitionComponent<DocumentPropertyDefinition, IDocumentPropertyOptions, IDocumentPropertyOpts> {
    public initialValues(definition: DocumentPropertyDefinition): IMetadataDefinitionFormData<IDocumentPropertyOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                property: DocumentPropertySelectOptions.find(x => x.value === definition.options.property)
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IDocumentPropertyOpts>, dispatch, props: ICommonProps<DocumentPropertyDefinition, IDocumentPropertyOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            parentKey: props.definition.parentKey,
            options: {
                property: values.options.property.value,
                parentMetadataKey: props.definition.options.parentMetadataKey,
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="file-plus" tooltip="Document Property" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Label>Property</Label>
                            <Field name="property" component={ValidatedSelect} options={DocumentPropertySelectOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface ITransmittalLinkOpts {
    limit?: number
}

export class TransmittalLinksMetadataDefinition extends MetadataDefinitionComponent<TransmittalLinksDefinition, ITransmittalLinkOptions, ITransmittalLinkOpts> {

    public initialValues(definition: TransmittalLinksDefinition): IMetadataDefinitionFormData<ITransmittalLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ITransmittalLinkOpts>, dispatch, props: ICommonProps<TransmittalLinksDefinition, ITransmittalLinkOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="envelope" tooltip="Transmittal Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const TransmittalPropertySelectOptions: IOption<TransmittalEntityProps>[] = [
    {
        label: 'Created Date',
        value: TransmittalEntityProps.CreatedDate
    },
    {
        label: 'Reference Number',
        value: TransmittalEntityProps.ReferenceNo
    },
    {
        label: 'Response Date',
        value: TransmittalEntityProps.ResponseDate
    },
    {
        label: 'Response Type',
        value: TransmittalEntityProps.ResponseType
    },
    {
        label: 'Status',
        value: TransmittalEntityProps.Status
    },
    {
        label: 'Subject',
        value: TransmittalEntityProps.Subject
    }
]

interface ITransmittalPropertyOpts {
    property: typeof TransmittalPropertySelectOptions[0]
}

export class TransmittalPropertyMetadataDefinition extends MetadataDefinitionComponent<TransmittalPropertyDefinition, IEntityPropertyOptions<TransmittalEntityProps>, ITransmittalPropertyOpts> {

    public initialValues(definition: TransmittalPropertyDefinition): IMetadataDefinitionFormData<ITransmittalPropertyOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                property: TransmittalPropertySelectOptions.find(x => x.value === definition.options.property)
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ITransmittalPropertyOpts>, dispatch, props: ICommonProps<TransmittalPropertyDefinition, IEntityPropertyOptions<TransmittalEntityProps>>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            parentKey: props.definition.parentKey,
            options: {
                property: values.options.property.value
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="envelope-open-text" tooltip="Transmittal Property" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Label>Property</Label>
                            <Field name="property" component={ValidatedSelect} options={TransmittalPropertySelectOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface ICompanyLinkOpts {
    limit?: number
}

export class CompanyLinksMetadataDefinition extends MetadataDefinitionComponent<CompanyLinksDefinition, ICompanyLinkOptions, ICompanyLinkOpts> {

    public initialValues(definition: CompanyLinksDefinition): IMetadataDefinitionFormData<ICompanyLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ICompanyLinkOpts>, dispatch, props: ICommonProps<CompanyLinksDefinition, ICompanyLinkOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="building" tooltip="Company Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const CompanyPropertySelectOptions: IOption<CompanyEntityProps>[] = [
    {
        label: 'Abbreviated Name',
        value: CompanyEntityProps.AbbreviatedName
    },
    {
        label: 'Name',
        value: CompanyEntityProps.Name
    },
    {
        label: 'Disciplines',
        value: CompanyEntityProps.Disciplines
    }
]

interface ICompanyPropertyOpts {
    property: typeof CompanyPropertySelectOptions[0]
}

export class CompanyPropertyMetadataDefinition extends MetadataDefinitionComponent<CompanyPropertyDefinition, IEntityPropertyOptions<CompanyEntityProps>, ICompanyPropertyOpts> {

    public initialValues(definition: CompanyPropertyDefinition): IMetadataDefinitionFormData<ICompanyPropertyOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                property: CompanyPropertySelectOptions.find(x => x.value === definition.options.property)
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ICompanyPropertyOpts>, dispatch, props: ICommonProps<CompanyPropertyDefinition, IEntityPropertyOptions<CompanyEntityProps>>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            parentKey: props.definition.parentKey,
            options: {
                property: values.options.property.value
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="building" tooltip="Company Property" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Label>Property</Label>
                            <Field name="property" component={ValidatedSelect} options={CompanyPropertySelectOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface IUserLinkOpts {
    limit?: number
}

export class UserLinksMetadataDefinition extends MetadataDefinitionComponent<UserLinksDefinition, IUserLinkOptions, IUserLinkOpts> {
    public initialValues(definition: UserLinksDefinition): IMetadataDefinitionFormData<IUserLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IUserLinkOpts>, dispatch, props: ICommonProps<UserLinksDefinition, IUserLinkOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="user" tooltip="User Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const UserPropertySelectOptions: IOption<UserEntityProps>[] = [
    {
        label: 'Email',
        value: UserEntityProps.Email
    },
    {
        label: 'Mobile',
        value: UserEntityProps.Mobile
    },
    {
        label: 'Name',
        value: UserEntityProps.Name
    },
    {
        label: 'Role',
        value: UserEntityProps.Role
    }
]

interface IUserPropertyOpts {
    property: typeof UserPropertySelectOptions[0]
}

export class UserPropertyMetadataDefinition extends MetadataDefinitionComponent<UserPropertyDefinition, IEntityPropertyOptions<UserEntityProps>, IUserPropertyOpts> {

    public initialValues(definition: UserPropertyDefinition): IMetadataDefinitionFormData<IUserPropertyOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                property: UserPropertySelectOptions.find(x => x.value === definition.options.property)
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IUserPropertyOpts>, dispatch, props: ICommonProps<UserPropertyDefinition, IEntityPropertyOptions<UserEntityProps>>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            parentKey: props.definition.parentKey,
            options: {
                property: values.options.property.value
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate
                icon="user-cog"
                tooltip="User Property"
                name={this.props.definition.name}
                metadataKey={definition.key}
                onRetire={this.props.onRetire}
                onUpdate={this.props.handleSubmit(this.submitHandler)}
                disabled={disabled}
            >
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Label>Property</Label>
                            <Field name="property" component={ValidatedSelect} options={UserPropertySelectOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface IEmailLinkOpts {
    limit?: number
}

export class EmailLinksMetadataDefinition extends MetadataDefinitionComponent<EmailLinksDefinition, IEmailLinkOptions, IEmailLinkOpts> {

    public initialValues(definition: EmailLinksDefinition): IMetadataDefinitionFormData<IEmailLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IEmailLinkOpts>, dispatch, props: ICommonProps<EmailLinksDefinition, IEmailLinkOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="at" tooltip="Email Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const EmailPropertySelectOptions: IOption<EmailEntityProps>[] = [
    {
        label: 'Bcc',
        value: EmailEntityProps.Bcc
    },
    {
        label: 'Cc',
        value: EmailEntityProps.Cc
    },
    {
        label: 'Date',
        value: EmailEntityProps.Date
    },
    {
        label: 'From',
        value: EmailEntityProps.From
    },
    {
        label: 'Subject',
        value: EmailEntityProps.Subject
    },
    {
        label: 'To',
        value: EmailEntityProps.To
    }
]

interface IEmailPropertyOpts {
    property: typeof EmailPropertySelectOptions[0]
}

export class EmailPropertyMetadataDefinition extends MetadataDefinitionComponent<EmailPropertyDefinition, IEntityPropertyOptions<EmailEntityProps>, IEmailPropertyOpts> {

    public initialValues(definition: EmailPropertyDefinition): IMetadataDefinitionFormData<IEmailPropertyOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                property: EmailPropertySelectOptions.find(x => x.value === definition.options.property)
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IEmailPropertyOpts>, dispatch, props: ICommonProps<EmailPropertyDefinition, IEntityPropertyOptions<EmailEntityProps>>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            parentKey: props.definition.parentKey,
            options: {
                property: values.options.property.value
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="mail-bulk" tooltip="Email Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Label>Property</Label>
                            <Field name="property" component={ValidatedSelect} options={EmailPropertySelectOptions} isDisabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface IAutoNumberOpts {
    displayPrefix: boolean
    minValue: number
    maxValue: number
}

export class AutoNumberMetadataDefinition extends MetadataDefinitionComponent<AutoNumberDefinition, IAutoNumberOptions, IAutoNumberOpts> {

    public initialValues(definition: AutoNumberDefinition): IMetadataDefinitionFormData<IAutoNumberOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                displayPrefix: definition.options.displayPrefix,
                minValue: definition.options.minValue,
                maxValue: definition.options.maxValue
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IAutoNumberOpts>, dispatch, props: ICommonProps<AutoNumberDefinition, IAutoNumberOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                displayPrefix: values.options.displayPrefix,
                minValue: values.options.minValue,
                maxValue: values.options.maxValue
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="sort-numeric-down" tooltip="Auto Number" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormSection name="options">
                        <FormGroup>
                            <Field name="displayPrefix" component={ValidatedCheckboxRadio} label="Display prefix" disabled={disabled} />
                        </FormGroup>
                        <Row className="mt-3">
                            <Col>
                                <FormGroup>
                                    <Label>Minimum Value</Label>
                                    <Field name="minValue" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Maximum Value</Label>
                                    <Field name="maxValue" component={ValidatedInput} type="number" disabled={disabled} />
                                </FormGroup>
                            </Col>
                        </Row>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface ICommitmentLinkOpts {
    limit?: number
}

export class CommitmentLinksMetadataDefinition extends MetadataDefinitionComponent<CommitmentLinksDefinition, ICommitmentLinksOptions, ICommitmentLinkOpts> {

    public initialValues(definition: CommitmentLinksDefinition): IMetadataDefinitionFormData<ICommitmentLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<ICommitmentLinkOpts>, dispatch, props: ICommonProps<CommitmentLinksDefinition, ICommitmentLinkOpts>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        return (
            <DefinitionTemplate icon="at" tooltip="Email Link" name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

interface IPaymentClaimLinkOpts {
    limit?: number
}

export class PaymentClaimLinksMetadataDefinition extends MetadataDefinitionComponent<PaymentClaimLinksDefinition, IPaymentClaimLinkOpts, IPaymentClaimLinkOpts> {

    public initialValues(definition: PaymentClaimLinksDefinition): IMetadataDefinitionFormData<IPaymentClaimLinkOpts> {
        return {
            ...commonInitialValues(definition),
            options: {
                limit: definition.options.limit
            }
        }
    }

    private submitHandler(values: IMetadataDefinitionFormData<IPaymentClaimLinkOpts>, dispatch, props: ICommonProps<PaymentClaimLinksDefinition, IPaymentClaimLinksOptions>) {
        return props.onUpdate(props.definition.key, {
            name: values.name,
            type: props.definition.type,
            key: values.key,
            description: values.description,
            isRequired: values.isRequired,
            options: {
                limit: values.options.limit
            }
        })
    }

    public render() {
        const { definition, fieldLabels, fieldDescriptions, disabled } = this.props
        const { icon, tooltip } = MetadataTypeDisplayMap[MetadataTypes.PaymentClaimLinks]

        return (
            <DefinitionTemplate icon={icon} tooltip={tooltip} name={this.props.definition.name} metadataKey={definition.key} onRetire={this.props.onRetire} onUpdate={this.props.handleSubmit(this.submitHandler)} disabled={disabled}>
                <Col xs={12} sm={6}>
                    <CommonMetadataSettings fieldLabels={fieldLabels} fieldDescriptions={fieldDescriptions} disabled={disabled} />
                </Col>
                <Col xs={12} sm={6}>
                    <legend className="col-form-label mb-3">Options</legend>
                    <FormGroup check>
                        <Label check>
                            <Field name="isRequired" component={ValidatedInput} type="checkbox" disabled={disabled} />
                            Required
                            </Label>
                    </FormGroup>
                    <FormSection name="options">
                        <FormGroup className="mt-2">
                            <Label>Limit</Label>
                            <Field name="limit" component={ValidatedInput} type="number" min={0} disabled={disabled} />
                        </FormGroup>
                    </FormSection>
                </Col>
            </DefinitionTemplate>
        )
    }
}

const WrappedAutoNumberMetadataDefinition = wrapInForm(AutoNumberMetadataDefinition)
const WrappedBoolMetadataDefinition = wrapInForm(BoolMetadataDefinition)
const WrappedNumericMetadataDefinition = wrapInForm(NumericMetadataDefinition)
const WrappedTextMetadataDefinition = wrapInForm(TextMetadataDefinition)
const WrappedDateMetadataDefinition = wrapInForm(DateMetadataDefinition)
const WrappedSelectMetadataDefinition = wrapInForm(SelectMetadataDefinition)
const WrappedDocumentLinksMetadataDefinition = wrapInForm(DocumentLinksMetadataDefinition)
const WrappedDocumentPropertyMetadataDefinition = wrapInForm(DocumentPropertyMetadataDefinition)
const WrappedTransmittalLinksMetadataDefinition = wrapInForm(TransmittalLinksMetadataDefinition)
const WrappedTransmittalPropertyMetadataDefinition = wrapInForm(TransmittalPropertyMetadataDefinition)
const WrappedCompanyLinksMetadataDefinition = wrapInForm(CompanyLinksMetadataDefinition)
const WrappedCompanyPropertyMetadataDefinition = wrapInForm(CompanyPropertyMetadataDefinition)
const WrappedUserLinksMetadataDefinition = wrapInForm(UserLinksMetadataDefinition)
const WrappedUserPropertyMetadataDefinition = wrapInForm(UserPropertyMetadataDefinition)
const WrappedEmailLinksMetadataDefinition = wrapInForm(EmailLinksMetadataDefinition)
const WrappedEmailPropertyMetadataDefinition = wrapInForm(EmailPropertyMetadataDefinition)
const WrappedCommitmentLinksMetadataDefinition = wrapInForm(CommitmentLinksMetadataDefinition)
const WrappedPaymentClaimLinksMetadataDefinition = wrapInForm(PaymentClaimLinksMetadataDefinition)
