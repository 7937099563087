import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, Col, Row } from 'reactstrap'
import { Dispatch, bindActionCreators } from 'redux'

import { CancelToken } from 'axios'
import moment from 'moment'

import { loadProjectCostsOverview } from '@src/actions/project'
import * as WidgetActions from '@src/actions/widget'
import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { EditCommitmentModal, NewCommitmentModal } from '@src/components/costs/commitments/CommitmentFormModal'
import CostValue from '@src/components/costs/common/CostValue'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { CommitmentDelete, CommitmentsListByType } from '@src/logic/http/Api'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { mutedNotSet, mutedValue } from '@src/logic/utils/ValueHelper'
import { getProjectState } from '@src/reducers/widget'
import { AggregateCommitmentData, Commitment, CommitmentDefinition, CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IState {
    isCreating: boolean
    isEditing: boolean
    aggregateCommitmentData: AggregateCommitmentData
    commitmentDefinition: CommitmentDefinition
    commitmentToDelete?: Commitment
    commitmentToEdit?: Commitment
}

interface IConnectedState {
    project: Project
    costsOverview: CostsOverview
    widgetCommitmentsIds: string[]
}

interface IConnectedDispatch {
    reloadCostsOverview: () => void
    widgetActions: typeof WidgetActions
}

const commitmentSearchProperties: IMappedSearchProperty<Commitment>[] = [
    {
        name: 'Name',
        searchKey: 'name',
        path: c => c.name,
        type: PropertyType.Text
    },
    {
        name: 'Commitment Number',
        searchKey: 'commitment_number',
        path: c => c.commitmentNo,
        type: PropertyType.Text
    },
    {
        name: 'Other Party',
        searchKey: 'other_party',
        path: c => c.otherParty.name,
        type: PropertyType.Text
    },
    {
        name: 'Description',
        searchKey: 'description',
        path: c => c.description,
        type: PropertyType.Text
    }
]

class CommitmentSection extends React.PureComponent<RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<Commitment, 'id'>>

    constructor(props: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch) {
        super(props)

        const commitmentDefinition = CommitmentSection.getCommitmentDefinitionOrRedirect(props)

        this.searchSectionRef = React.createRef<SearchSectionType<Commitment, 'id'>>()

        this.state = {
            commitmentDefinition,
            isCreating: false,
            isEditing: false,
            aggregateCommitmentData: null,
            commitmentToDelete: null,
            commitmentToEdit: null
        }
    }

    public static getDerivedStateFromProps(props, state) {
        const commitmentDefinition = CommitmentSection.getCommitmentDefinitionOrRedirect(props)

        return {
            ...state,
            commitmentDefinition
        }
    }

    public static getCommitmentDefinitionOrRedirect(props: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState & IConnectedDispatch) {
        const commitmentDefinition = props.costsOverview.commitmentDefinitions.find(x => x.code === props.match.params.type)

        if (commitmentDefinition == null) {
            props.history.push({
                pathname: Routes.projectCostsDashboard(props.project.id)
            })
        }

        return commitmentDefinition
    }

    public componentDidUpdate(prevProps, prevState: IState) {
        if (this.state.commitmentDefinition.code !== prevState.commitmentDefinition.code) {
            this.searchSectionRef.current.doSearch()
        }
    }

    private readonly loadCommitments = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken): Promise<ISearchResult<Commitment>> => {
        const response = await CommitmentsListByType(this.props.project.id, this.state.commitmentDefinition.code, filter, sort, page, perPage, { cancelToken })
        this.setState({ aggregateCommitmentData: response.data.aggregateCommitmentData })
        return {
            items: response.data.commitments,
            totalItems: response.headers[Headers.PaginationTotalCount]
        }
    }

    private readonly setCommitmentToDelete = (item: Commitment) => {
        this.setState({ commitmentToDelete: item })
    }

    private readonly clearCommitmentToDelete = () => {
        this.setState({ commitmentToDelete: null })
    }

    private readonly deleteCommitment = async () => {
        const { commitmentToDelete } = this.state
        this.clearCommitmentToDelete()

        try {
            await CommitmentDelete(this.props.project.id, commitmentToDelete.id)
        } catch {
            NotificationService.error(<span>Failed to remove {commitmentToDelete.name}</span>)
        }

        await this.searchSectionRef.current.doSearch()
    }

    private readonly setCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly clearCreating = () => {
        this.setState({ isCreating: false })
    }

    private readonly handleCommitmentsSelected = (...commitments: Commitment[]) => {
        const selectedIds = [...this.props.widgetCommitmentsIds]
        this.props.widgetActions.addCommitments({ projectId: this.props.project.id, entities: commitments.filter(c => !selectedIds.includes(c.id)) })
        this.props.widgetActions.removeCommitments({ projectId: this.props.project.id, entityIds: commitments.filter(c => selectedIds.includes(c.id)).map(c => c.id) })
    }

    private readonly setCommitmentToEdit = (item: Commitment) => {
        this.setState({ isEditing: true, commitmentToEdit: item })
    }

    private readonly clearEditing = () => {
        this.setState({ isEditing: false })
    }

    private readonly clearCommitmentToEdit = () => {
        this.setState({ commitmentToEdit: null })
    }

    private readonly clearFlags = () => {
        this.setState({ isCreating: false, isEditing: false })
    }

    private readonly navigateToCommitment = (commitment: Commitment) => {
        this.props.history.push(Routes.projectCostsCommitmentTypeDetail(this.props.project.id, commitment.type, commitment.id))
    }

    public render() {
        const { project, costsOverview, widgetCommitmentsIds } = this.props
        const { commitmentDefinition, commitmentToDelete, isCreating, isEditing, commitmentToEdit } = this.state

        return (
            <>
                <ActionBar className="pb-0 pt-0 d-block">
                    <Row>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Value</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalValue} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Claimed</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalClaimed} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Certified</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalCertified} />}
                        </Col>
                        <Col className="pt-3" xs={6} md="auto">
                            <h5>Total Paid</h5>
                            {this.state.aggregateCommitmentData && <CostValue value={this.state.aggregateCommitmentData.totalPaid} />}
                        </Col>
                    </Row>
                </ActionBar>
                <SearchSection<Commitment, 'id'>
                    ref={this.searchSectionRef}
                    onItemsSelected={this.handleCommitmentsSelected}
                    selectedItems={widgetCommitmentsIds}
                    itemIdKey="id"
                    searchAssistantProperties={commitmentSearchProperties}
                    headers={[
                        {
                            name: 'Commitment',
                            accessor: 'commitmentNo',
                            sortKey: 'commitment_no',
                            sortable: true
                        },
                        {
                            name: 'Name',
                            sortKey: 'name',
                            sortable: true,
                            overrideRenderer: commitment => <Link to={Routes.projectCostsCommitmentTypeDetail(project.id, commitment.type, commitment.id)}>{commitment.name}</Link>
                        },
                        {
                            name: 'Date',
                            overrideRenderer: commitment => commitment.date != null ? moment(commitment.date).format('d MMMM YYYY') : mutedNotSet
                        },
                        {
                            name: 'Other Party',
                            overrideRenderer: commitment => commitment.otherParty ? commitment.otherParty.name : mutedValue('(none)')
                        },
                        {
                            name: 'Value',
                            overrideRenderer: commitment => <CostValue value={commitment.currentValue} />
                        },
                        {
                            name: 'Claimed',
                            overrideRenderer: commitment => <CostValue value={commitment.currentClaimed} />
                        },
                        {
                            name: 'Certified',
                            overrideRenderer: commitment => <CostValue value={commitment.currentCertified} />
                        },
                        {
                            name: 'Paid',
                            overrideRenderer: commitment => <CostValue value={commitment.currentPaid} />
                        },
                        {
                            name: 'Actions',
                            headerWrapperClass: 'text-right',
                            overrideRenderer: item => (
                                <div className="text-right">
                                    <TooltipLinkAction id={`edit-commitment-${item.id}`} tooltip="Edit" data={item} className="order-lg-1" onClick={this.setCommitmentToEdit}><FA icon="pencil" /></TooltipLinkAction>
                                    <TooltipLinkAction id={`delete-commitment-${item.id}`} tooltip="Remove" data={item} className="order-lg-1" onClick={this.setCommitmentToDelete} disabled={!isAuthorised(costsOverview.myAccess, Operations.Delete)}><FA icon="trash" /></TooltipLinkAction>
                                </div>
                            )
                        }
                    ]}
                    onSearch={this.loadCommitments}
                    extraSearchBarElements={[
                        {
                            position: 'before',
                            element: doSearch => (
                                <Button onClick={this.setCreating}><FA icon="plus" /> Add</Button>
                            )
                        }
                    ]}
                >
                    <NewCommitmentModal
                        isOpen={isCreating}
                        project={project}
                        costsOverview={costsOverview}
                        commitmentDefinition={commitmentDefinition}
                        toggle={this.clearFlags}
                        onClosed={this.clearCommitmentToEdit}
                        onCommitmentCreated={this.navigateToCommitment}
                    />
                    {commitmentToEdit && <EditCommitmentModal
                        isOpen={isEditing}
                        project={project}
                        costsOverview={costsOverview}
                        commitment={commitmentToEdit}
                        toggle={this.clearFlags}
                        onClosed={this.clearCommitmentToEdit}
                        onCommitmentUpdated={this.searchSectionRef.current?.doSearch}
                    />}
                    <ConfirmationModal
                        danger
                        open={commitmentToDelete != null}
                        toggle={this.clearCommitmentToDelete}
                        header={'Remove commitment'}
                        message={<span>Are you sure you want to remove <strong>{commitmentToDelete?.name}</strong>?</span>}
                        confirmAction="Remove"
                        rejectAction="Cancel"
                        onReject={this.clearCommitmentToDelete}
                        onConfirm={this.deleteCommitment}
                    />
                </SearchSection>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams>): RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams> & IConnectedState {
    return {
        ...ownProps,
        project: state.projects.active,
        costsOverview: state.projects.activeCostsOverview,
        widgetCommitmentsIds: getProjectState(state.widget, state.projects.active.id).commitments.map(c => c.id)
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: RouteComponentProps<Routes.IProjectCostsCommitmentTypeParams>): IConnectedDispatch {
    return {
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview()),
        widgetActions: bindActionCreators(WidgetActions, dispatch)
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentSection)
