import React from 'react'

import InviteUserForm from '@src/components/company/InviteUserForm'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarButton from '@src/components/toolbar/ToolbarButton'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import useBoolean from '@src/hooks/useBoolean'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { Company } from '@src/types/principal'

const CompanyToolbar: React.FC<{ company: Company }> = ({ company }) => {

    const inviteModalToggle = useBoolean(false)

    return (
        <section className="banner">
            <Toolbar>
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.COMPANY_PROJECTS)} to={Routes.COMPANY_PROJECTS} name="Projects" nameBrief="Projects" iconBrief="project-diagram" data-tour={TourTags.CompanyToolbarProjects} />
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.COMPANY_GROUPS)} to={Routes.COMPANY_GROUPS} name="Groups" nameBrief="Groups" iconBrief="users" data-tour={TourTags.CompanyToolbarGroups} />
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.COMPANY_USERS)} to={Routes.COMPANY_USERS} name="Users" nameBrief="Users" iconBrief="user" data-tour={TourTags.CompanyToolbarUsers} />
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.COMPANY_TEMPLATES)} to={Routes.COMPANY_TEMPLATES} name="Templates" nameBrief="Templates" iconBrief="file-plus" data-tour={TourTags.CompanyToolbarTemplates} />
                <div className="ml-auto d-none d-md-flex">
                    {company.myAccess.isAdministrator && <ToolbarLink active={pathMatchesCurrentRoute(Routes.COMPANY_SETTINGS_GENERAL)} tool to={Routes.COMPANY_SETTINGS_GENERAL} name="Settings" icon="cog" iconBrief="cog" />}
                    <ToolbarButton tool name="Invite" icon="user-plus" iconBrief="user-plus" onClick={inviteModalToggle.setTrue} />
                </div>
            </Toolbar>
            <InviteUserForm open={inviteModalToggle.value} toggle={inviteModalToggle.setFalse} />
        </section>
    )
}

export default CompanyToolbar
