// @ts-nocheck
/* eslint-disable */

import { ATN } from 'antlr4ts/atn/ATN';
import { ATNDeserializer } from 'antlr4ts/atn/ATNDeserializer';
import { LexerATNSimulator } from 'antlr4ts/atn/LexerATNSimulator';
import { CharStream } from 'antlr4ts/CharStream';
import { Lexer } from 'antlr4ts/Lexer';
import { Vocabulary } from 'antlr4ts/Vocabulary';
import { VocabularyImpl } from 'antlr4ts/VocabularyImpl';

import * as Utils from 'antlr4ts/misc/Utils';

export class IQLFilterLexer extends Lexer {
	public static readonly T__0 = 1
	public static readonly T__1 = 2
	public static readonly T__2 = 3
	public static readonly T__3 = 4
	public static readonly T__4 = 5
	public static readonly T__5 = 6
	public static readonly T__6 = 7
	public static readonly T__7 = 8
	public static readonly AND = 9
	public static readonly OR = 10
	public static readonly NOT = 11
	public static readonly BOOLEAN = 12
	public static readonly ISODATE = 13
	public static readonly NULL = 14
	public static readonly NUMBER = 15
	public static readonly WORD = 16
	public static readonly STOPWORD = 17
	public static readonly QUOTE = 18
	public static readonly WS = 19
	public static readonly UNKNOWN = 20

	// tslint:disable:no-trailing-whitespace
	public static readonly channelNames: string[] = [
		'DEFAULT_TOKEN_CHANNEL', 'HIDDEN',
	]

	// tslint:disable:no-trailing-whitespace
	public static readonly modeNames: string[] = [
		'DEFAULT_MODE',
	]

	public static readonly ruleNames: string[] = [
		'T__0', 'T__1', 'T__2', 'T__3', 'T__4', 'T__5', 'T__6', 'T__7', 'AND',
		'OR', 'NOT', 'BOOLEAN', 'ISODATE', 'NULL', 'NUMBER', 'WORD', 'STOPWORD',
		'QUOTE', 'WS', 'UNKNOWN', 'Alpha', 'AlphaNum', 'Digit', 'Int', 'True',
		'False', 'Null', 'Undefined', 'SingleQuote', 'DoubleQuote', 'Year', 'DayMonth',
		'FullDate', 'TimePart', 'TimeFrac', 'TimeOffset', 'FullTime',
	]

	private static readonly _LITERAL_NAMES: Array<string | undefined> = [
		undefined, '\'(\'', '\')\'', '\'.\'', '\':\'', '\'>\'', '\'<\'', '\',\'', '\'_\'',
	]
	private static readonly _SYMBOLIC_NAMES: Array<string | undefined> = [
		undefined, undefined, undefined, undefined, undefined, undefined, undefined,
		undefined, undefined, 'AND', 'OR', 'NOT', 'BOOLEAN', 'ISODATE', 'NULL',
		'NUMBER', 'WORD', 'STOPWORD', 'QUOTE', 'WS', 'UNKNOWN',
	]
	public static readonly VOCABULARY: Vocabulary = new VocabularyImpl(IQLFilterLexer._LITERAL_NAMES, IQLFilterLexer._SYMBOLIC_NAMES, [])



	public get vocabulary(): Vocabulary {
		return IQLFilterLexer.VOCABULARY
	}
	// tslint:enable:no-trailing-whitespace


	constructor(input: CharStream) {
		super(input)
		this._interp = new LexerATNSimulator(IQLFilterLexer._ATN, this)
	}


	public get grammarFileName(): string { return 'IQLFilter.g4'; }


	public get ruleNames(): string[] { return IQLFilterLexer.ruleNames }


	public get serializedATN(): string { return IQLFilterLexer._serializedATN }


	public get channelNames(): string[] { return IQLFilterLexer.channelNames }


	public get modeNames(): string[] { return IQLFilterLexer.modeNames }

	// tslint:disable:quotemark
	public static readonly _serializedATN: string =
		"\x03\uC91D\uCABA\u058D\uAFBA\u4F53\u0607\uEA8B\uC241\x02\x16\u0102\b\x01" +
		"\x04\x02\t\x02\x04\x03\t\x03\x04\x04\t\x04\x04\x05\t\x05\x04\x06\t\x06" +
		"\x04\x07\t\x07\x04\b\t\b\x04\t\t\t\x04\n\t\n\x04\v\t\v\x04\f\t\f\x04\r" +
		"\t\r\x04\x0E\t\x0E\x04\x0F\t\x0F\x04\x10\t\x10\x04\x11\t\x11\x04\x12\t" +
		"\x12\x04\x13\t\x13\x04\x14\t\x14\x04\x15\t\x15\x04\x16\t\x16\x04\x17\t" +
		"\x17\x04\x18\t\x18\x04\x19\t\x19\x04\x1A\t\x1A\x04\x1B\t\x1B\x04\x1C\t" +
		"\x1C\x04\x1D\t\x1D\x04\x1E\t\x1E\x04\x1F\t\x1F\x04 \t \x04!\t!\x04\"\t" +
		"\"\x04#\t#\x04$\t$\x04%\t%\x04&\t&\x03\x02\x03\x02\x03\x03\x03\x03\x03" +
		"\x04\x03\x04\x03\x05\x03\x05\x03\x06\x03\x06\x03\x07\x03\x07\x03\b\x03" +
		"\b\x03\t\x03\t\x03\n\x03\n\x03\n\x03\n\x05\nb\n\n\x03\v\x03\v\x03\v\x03" +
		"\f\x03\f\x03\f\x03\f\x05\fk\n\f\x03\r\x03\r\x05\ro\n\r\x03\x0E\x03\x0E" +
		"\x03\x0E\x03\x0E\x03\x0F\x03\x0F\x05\x0Fw\n\x0F\x03\x10\x05\x10z\n\x10" +
		"\x03\x10\x03\x10\x03\x10\x05\x10\x7F\n\x10\x03\x11\x06\x11\x82\n\x11\r" +
		"\x11\x0E\x11\x83\x03\x12\x06\x12\x87\n\x12\r\x12\x0E\x12\x88\x03\x12\x03" +
		"\x12\x03\x13\x03\x13\x05\x13\x8F\n\x13\x03\x14\x06\x14\x92\n\x14\r\x14" +
		"\x0E\x14\x93\x03\x14\x03\x14\x03\x15\x03\x15\x03\x15\x03\x15\x03\x16\x03" +
		"\x16\x03\x17\x03\x17\x05\x17\xA0\n\x17\x03\x18\x03\x18\x03\x19\x06\x19" +
		"\xA5\n\x19\r\x19\x0E\x19\xA6\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03\x1A\x03" +
		"\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1B\x03\x1C\x03\x1C\x03\x1C\x03" +
		"\x1C\x03\x1C\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03\x1D\x03" +
		"\x1D\x03\x1D\x03\x1D\x03\x1E\x03\x1E\x03\x1E\x03\x1E\x07\x1E\xC7\n\x1E" +
		"\f\x1E\x0E\x1E\xCA\v\x1E\x03\x1E\x03\x1E\x03\x1F\x03\x1F\x03\x1F\x03\x1F" +
		"\x07\x1F\xD2\n\x1F\f\x1F\x0E\x1F\xD5\v\x1F\x03\x1F\x03\x1F\x03 \x03 \x03" +
		" \x03 \x03 \x03!\x03!\x03!\x03\"\x03\"\x03\"\x03\"\x03\"\x03\"\x03#\x03" +
		"#\x03#\x03$\x03$\x07$\xEC\n$\f$\x0E$\xEF\v$\x03%\x03%\x03%\x03%\x03%\x03" +
		"%\x05%\xF7\n%\x03&\x03&\x03&\x03&\x03&\x03&\x05&\xFF\n&\x03&\x03&\x02" +
		"\x02\x02\'\x03\x02\x03\x05\x02\x04\x07\x02\x05\t\x02\x06\v\x02\x07\r\x02" +
		"\b\x0F\x02\t\x11\x02\n\x13\x02\v\x15\x02\f\x17\x02\r\x19\x02\x0E\x1B\x02" +
		"\x0F\x1D\x02\x10\x1F\x02\x11!\x02\x12#\x02\x13%\x02\x14\'\x02\x15)\x02" +
		"\x16+\x02\x02-\x02\x02/\x02\x021\x02\x023\x02\x025\x02\x027\x02\x029\x02" +
		"\x02;\x02\x02=\x02\x02?\x02\x02A\x02\x02C\x02\x02E\x02\x02G\x02\x02I\x02" +
		"\x02K\x02\x02\x03\x02\x14\x04\x02CCcc\x04\x02PPpp\x04\x02FFff\x04\x02" +
		"QQqq\x04\x02TTtt\x04\x02VVvv\x05\x02\v\f\x0F\x0F\"\"\x04\x02C\\c|\x03" +
		"\x022;\x04\x02WWww\x04\x02GGgg\x04\x02HHhh\x04\x02NNnn\x04\x02UUuu\x04" +
		"\x02KKkk\x03\x02))\x03\x02$$\x04\x02--//\x02\u0103\x02\x03\x03\x02\x02" +
		"\x02\x02\x05\x03\x02\x02\x02\x02\x07\x03\x02\x02\x02\x02\t\x03\x02\x02" +
		"\x02\x02\v\x03\x02\x02\x02\x02\r\x03\x02\x02\x02\x02\x0F\x03\x02\x02\x02" +
		"\x02\x11\x03\x02\x02\x02\x02\x13\x03\x02\x02\x02\x02\x15\x03\x02\x02\x02" +
		"\x02\x17\x03\x02\x02\x02\x02\x19\x03\x02\x02\x02\x02\x1B\x03\x02\x02\x02" +
		"\x02\x1D\x03\x02\x02\x02\x02\x1F\x03\x02\x02\x02\x02!\x03\x02\x02\x02" +
		"\x02#\x03\x02\x02\x02\x02%\x03\x02\x02\x02\x02\'\x03\x02\x02\x02\x02)" +
		"\x03\x02\x02\x02\x03M\x03\x02\x02\x02\x05O\x03\x02\x02\x02\x07Q\x03\x02" +
		"\x02\x02\tS\x03\x02\x02\x02\vU\x03\x02\x02\x02\rW\x03\x02\x02\x02\x0F" +
		"Y\x03\x02\x02\x02\x11[\x03\x02\x02\x02\x13a\x03\x02\x02\x02\x15c\x03\x02" +
		"\x02\x02\x17j\x03\x02\x02\x02\x19n\x03\x02\x02\x02\x1Bp\x03\x02\x02\x02" +
		"\x1Dv\x03\x02\x02\x02\x1Fy\x03\x02\x02\x02!\x81\x03\x02\x02\x02#\x86\x03" +
		"\x02\x02\x02%\x8E\x03\x02\x02\x02\'\x91\x03\x02\x02\x02)\x97\x03\x02\x02" +
		"\x02+\x9B\x03\x02\x02\x02-\x9F\x03\x02\x02\x02/\xA1\x03\x02\x02\x021\xA4" +
		"\x03\x02\x02\x023\xA8\x03\x02\x02\x025\xAD\x03\x02\x02\x027\xB3\x03\x02" +
		"\x02\x029\xB8\x03\x02\x02\x02;\xC2\x03\x02\x02\x02=\xCD\x03\x02\x02\x02" +
		"?\xD8\x03\x02\x02\x02A\xDD\x03\x02\x02\x02C\xE0\x03\x02\x02\x02E\xE6\x03" +
		"\x02\x02\x02G\xE9\x03\x02\x02\x02I\xF6\x03\x02\x02\x02K\xF8\x03\x02\x02" +
		"\x02MN\x07*\x02\x02N\x04\x03\x02\x02\x02OP\x07+\x02\x02P\x06\x03\x02\x02" +
		"\x02QR\x070\x02\x02R\b\x03\x02\x02\x02ST\x07<\x02\x02T\n\x03\x02\x02\x02" +
		"UV\x07@\x02\x02V\f\x03\x02\x02\x02WX\x07>\x02\x02X\x0E\x03\x02\x02\x02" +
		"YZ\x07.\x02\x02Z\x10\x03\x02\x02\x02[\\\x07a\x02\x02\\\x12\x03\x02\x02" +
		"\x02]^\t\x02\x02\x02^_\t\x03\x02\x02_b\t\x04\x02\x02`b\x07(\x02\x02a]" +
		"\x03\x02\x02\x02a`\x03\x02\x02\x02b\x14\x03\x02\x02\x02cd\t\x05\x02\x02" +
		"de\t\x06\x02\x02e\x16\x03\x02\x02\x02fg\t\x03\x02\x02gh\t\x05\x02\x02" +
		"hk\t\x07\x02\x02ik\x07#\x02\x02jf\x03\x02\x02\x02ji\x03\x02\x02\x02k\x18" +
		"\x03\x02\x02\x02lo\x053\x1A\x02mo\x055\x1B\x02nl\x03\x02\x02\x02nm\x03" +
		"\x02\x02\x02o\x1A\x03\x02\x02\x02pq\x05C\"\x02qr\x07V\x02\x02rs\x05K&" +
		"\x02s\x1C\x03\x02\x02\x02tw\x057\x1C\x02uw\x059\x1D\x02vt\x03\x02\x02" +
		"\x02vu\x03\x02\x02\x02w\x1E\x03\x02\x02\x02xz\x07/\x02\x02yx\x03\x02\x02" +
		"\x02yz\x03\x02\x02\x02z{\x03\x02\x02\x02{~\x051\x19\x02|}\x070\x02\x02" +
		"}\x7F\x051\x19\x02~|\x03\x02\x02\x02~\x7F\x03\x02\x02\x02\x7F \x03\x02" +
		"\x02\x02\x80\x82\x05-\x17\x02\x81\x80\x03\x02\x02\x02\x82\x83\x03\x02" +
		"\x02\x02\x83\x81\x03\x02\x02\x02\x83\x84\x03\x02\x02\x02\x84\"\x03\x02" +
		"\x02\x02\x85\x87\x07/\x02\x02\x86\x85\x03\x02\x02\x02\x87\x88\x03\x02" +
		"\x02\x02\x88\x86\x03\x02\x02\x02\x88\x89\x03\x02\x02\x02\x89\x8A\x03\x02" +
		"\x02\x02\x8A\x8B\x05!\x11\x02\x8B$\x03\x02\x02\x02\x8C\x8F\x05;\x1E\x02" +
		"\x8D\x8F\x05=\x1F\x02\x8E\x8C\x03\x02\x02\x02\x8E\x8D\x03\x02\x02\x02" +
		"\x8F&\x03\x02\x02\x02\x90\x92\t\b\x02\x02\x91\x90\x03\x02\x02\x02\x92" +
		"\x93\x03\x02\x02\x02\x93\x91\x03\x02\x02\x02\x93\x94\x03\x02\x02\x02\x94" +
		"\x95\x03\x02\x02\x02\x95\x96\b\x14\x02\x02\x96(\x03\x02\x02\x02\x97\x98" +
		"\v\x02\x02\x02\x98\x99\x03\x02\x02\x02\x99\x9A\b\x15\x03\x02\x9A*\x03" +
		"\x02\x02\x02\x9B\x9C\t\t\x02\x02\x9C,\x03\x02\x02\x02\x9D\xA0\x05+\x16" +
		"\x02\x9E\xA0\x05/\x18\x02\x9F\x9D\x03\x02\x02\x02\x9F\x9E\x03\x02\x02" +
		"\x02\xA0.\x03\x02\x02\x02\xA1\xA2\t\n\x02\x02\xA20\x03\x02\x02\x02\xA3" +
		"\xA5\x05/\x18\x02\xA4\xA3\x03\x02\x02\x02\xA5\xA6\x03\x02\x02\x02\xA6" +
		"\xA4\x03\x02\x02\x02\xA6\xA7\x03\x02\x02\x02\xA72\x03\x02\x02\x02\xA8" +
		"\xA9\t\x07\x02\x02\xA9\xAA\t\x06\x02\x02\xAA\xAB\t\v\x02\x02\xAB\xAC\t" +
		"\f\x02\x02\xAC4\x03\x02\x02\x02\xAD\xAE\t\r\x02\x02\xAE\xAF\t\x02\x02" +
		"\x02\xAF\xB0\t\x0E\x02\x02\xB0\xB1\t\x0F\x02\x02\xB1\xB2\t\f\x02\x02\xB2" +
		"6\x03\x02\x02\x02\xB3\xB4\t\x03\x02\x02\xB4\xB5\t\v\x02\x02\xB5\xB6\t" +
		"\x0E\x02\x02\xB6\xB7\t\x0E\x02\x02\xB78\x03\x02\x02\x02\xB8\xB9\t\v\x02" +
		"\x02\xB9\xBA\t\x03\x02\x02\xBA\xBB\t\x04\x02\x02\xBB\xBC\t\f\x02\x02\xBC" +
		"\xBD\t\r\x02\x02\xBD\xBE\t\x10\x02\x02\xBE\xBF\t\x03\x02\x02\xBF\xC0\t" +
		"\f\x02\x02\xC0\xC1\t\x04\x02\x02\xC1:\x03\x02\x02\x02\xC2\xC8\x07)\x02" +
		"\x02\xC3\xC7\n\x11\x02\x02\xC4\xC5\x07^\x02\x02\xC5\xC7\x07)\x02\x02\xC6" +
		"\xC3\x03\x02\x02\x02\xC6\xC4\x03\x02\x02\x02\xC7\xCA\x03\x02\x02\x02\xC8" +
		"\xC6\x03\x02\x02\x02\xC8\xC9\x03\x02\x02\x02\xC9\xCB\x03\x02\x02\x02\xCA" +
		"\xC8\x03\x02\x02\x02\xCB\xCC\x07)\x02\x02\xCC<\x03\x02\x02\x02\xCD\xD3" +
		"\x07$\x02\x02\xCE\xD2\n\x12\x02\x02\xCF\xD0\x07^\x02\x02\xD0\xD2\x07$" +
		"\x02\x02\xD1\xCE\x03\x02\x02\x02\xD1\xCF\x03\x02\x02\x02\xD2\xD5\x03\x02" +
		"\x02\x02\xD3\xD1\x03\x02\x02\x02\xD3\xD4\x03\x02\x02\x02\xD4\xD6\x03\x02" +
		"\x02\x02\xD5\xD3\x03\x02\x02\x02\xD6\xD7\x07$\x02\x02\xD7>\x03\x02\x02" +
		"\x02\xD8\xD9\x05/\x18\x02\xD9\xDA\x05/\x18\x02\xDA\xDB\x05/\x18\x02\xDB" +
		"\xDC\x05/\x18\x02\xDC@\x03\x02\x02\x02\xDD\xDE\x05/\x18\x02\xDE\xDF\x05" +
		"/\x18\x02\xDFB\x03\x02\x02\x02\xE0\xE1\x05? \x02\xE1\xE2\x07/\x02\x02" +
		"\xE2\xE3\x05A!\x02\xE3\xE4\x07/\x02\x02\xE4\xE5\x05A!\x02\xE5D\x03\x02" +
		"\x02\x02\xE6\xE7\x05/\x18\x02\xE7\xE8\x05/\x18\x02\xE8F\x03\x02\x02\x02" +
		"\xE9\xED\x070\x02\x02\xEA\xEC\x05/\x18\x02\xEB\xEA\x03\x02\x02\x02\xEC" +
		"\xEF\x03\x02\x02\x02\xED\xEB\x03\x02\x02\x02\xED\xEE\x03\x02\x02\x02\xEE" +
		"H\x03\x02\x02\x02\xEF\xED\x03\x02\x02\x02\xF0\xF7\x07\\\x02\x02\xF1\xF2" +
		"\t\x13\x02\x02\xF2\xF3\x05E#\x02\xF3\xF4\x07<\x02\x02\xF4\xF5\x05E#\x02" +
		"\xF5\xF7\x03\x02\x02\x02\xF6\xF0\x03\x02\x02\x02\xF6\xF1\x03\x02\x02\x02" +
		"\xF7J\x03\x02\x02\x02\xF8\xF9\x05E#\x02\xF9\xFA\x07<\x02\x02\xFA\xFB\x05" +
		"E#\x02\xFB\xFC\x07<\x02\x02\xFC\xFE\x05E#\x02\xFD\xFF\x05G$\x02\xFE\xFD" +
		"\x03\x02\x02\x02\xFE\xFF\x03\x02\x02\x02\xFF\u0100\x03\x02\x02\x02\u0100" +
		"\u0101\x05I%\x02\u0101L\x03\x02\x02\x02\x16\x02ajnvy~\x83\x88\x8E\x93" +
		"\x9F\xA6\xC6\xC8\xD1\xD3\xED\xF6\xFE\x04\b\x02\x02\x02\x03\x02"
	// tslint:enable:quotemark
	public static __ATN: ATN
	public static get _ATN(): ATN {
		if (!IQLFilterLexer.__ATN) {
			IQLFilterLexer.__ATN = new ATNDeserializer().deserialize(Utils.toCharArray(IQLFilterLexer._serializedATN))
		}

		return IQLFilterLexer.__ATN
	}

}

