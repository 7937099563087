import React from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import { Dispatch } from 'redux'
import { FormAction, isDirty, isSubmitting, submit } from 'redux-form'

import FA from '@src/components/common/FontAwesomeIcon'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { auth } from '@src/logic/auth/AuthService'
import * as Forms from '@src/logic/forms/Forms'
import { CommunicationPreviewPdfLink } from '@src/logic/http/Api'
import { downloadInNewTab } from '@src/logic/http/Download'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { Communication } from '@src/types/communication'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    communication: Communication
    currentRoutePath: string
    reloadCommunication: () => void
}

interface IConnectedState {
    projectId: string
    userId: string
    hasUnsavedChanges: boolean
    isSavingChanges: boolean
}

interface IConnectedDispatch {
    saveCommunication: () => FormAction
}

class CommunicationToolbar extends React.PureComponent<IProps & IConnectedState & IConnectedDispatch> {

    private handlePreviewClick = () => {
        downloadInNewTab(CommunicationPreviewPdfLink(auth.getSessionToken(), this.props.communication.id))
    }

    public render() {
        const { project, communication, hasUnsavedChanges, isSavingChanges, userId } = this.props
        const onEditRoute = pathMatchesCurrentRoute(Routes.PROJECT_COMMUNICATION_EDIT)

        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={onEditRoute} to={Routes.projectCommunication(project.id, communication.id)} name="Edit" nameBrief="Edit" iconBrief="pencil" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_COMMUNICATION_ACCESS)} to={Routes.projectCommunicationAccess(project.id, communication.id)} name="Access" nameBrief="Access" iconBrief="lock" />
                    <div className="ml-auto d-none d-md-flex align-items-center">
                        <Button onClick={this.handlePreviewClick} className="mr-2">
                            <FA icon="file-pdf" />
                            <span className="d-none d-md-inline-block">&nbsp;Preview</span>
                        </Button>
                        <Button color="primary" onClick={this.props.saveCommunication}>
                            <FA icon={isSavingChanges ? 'spinner-third' : hasUnsavedChanges ? 'save' : 'check-circle'} spin={isSavingChanges} />
                            <span className="d-none d-md-inline-block">&nbsp;{isSavingChanges ? 'Saving...' : hasUnsavedChanges ? 'Save' : 'Saved'}</span>
                        </Button>
                    </div>
                </Toolbar>
            </section>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps): IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        userId: state.session.user.id,
        hasUnsavedChanges: isDirty(Forms.EditCommunication)(state),
        isSavingChanges: isSubmitting(Forms.EditCommunication)(state),
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        saveCommunication: () => dispatch(submit(Forms.EditCommunication)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommunicationToolbar)
