import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarButton from '@src/components/toolbar/ToolbarButton'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { auth } from '@src/logic/auth/AuthService'
import { EmailDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { Email } from '@src/types/email'

interface IProps {
    email: Email
    currentRoutePath: string
}

export default class EmailToolbar extends React.PureComponent<IProps> {

    private downloadEmail = () => {
        downloadURL(EmailDownloadLink(auth.getSessionToken(), this.props.email.id))
    }

    public render() {
        const { email } = this.props
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_EMAIL)} to={Routes.projectEmail(email.projectId, email.id)} name="View" nameBrief="View" iconBrief="eye" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_EMAIL_ACCESS)} to={Routes.projectEmailAccess(email.projectId, email.id)} name="Access" nameBrief="Access" iconBrief="lock" />
                    <div className="ml-auto d-none d-md-flex">
                        <ToolbarButton tool onClick={this.downloadEmail} name="Export" icon="file-export" iconBrief="file-export" />
                    </div>
                </Toolbar>
            </section>
        )
    }
}
