// tslint:disable:max-classes-per-file

import React from 'react'

import cx from 'classnames'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import useBoolean from '@src/hooks/useBoolean'
import { capitalize } from '@src/logic/utils/Strings'

export interface IBaseProps {
    className?: string
    size?: 'md' | 'sm' | 'xs'
    tag?: React.ReactType
}

export interface IImageProps extends IBaseProps {
    imageUrl: string
    children?: never
}

export interface INameProps extends IBaseProps {
    firstName: string
    lastName: string
    children?: never
}

export interface IIconProps extends IBaseProps {
    icon: IPFontawesomeIcon
}

export class IconAvatar extends React.PureComponent<IIconProps> {
    public render() {
        const { children, className, icon, tag, size } = this.props

        const Tag = tag || 'div'

        return (
            <Tag className={cx(className, 'avatar', { 'avatar--sm': size === 'sm', 'avatar--xs': size === 'xs' })}>
                {!children && <FA className="avatar__icon align-middle" icon={icon} />}
                {children}
            </Tag>
        )
    }
}

export const UserAvatar: React.FC<IImageProps | INameProps> = (props) => {
    const imageLoaded = useBoolean(false)

    function hasNameProps(p: IImageProps | INameProps): p is INameProps {
        const nameProps = props as INameProps
        if (nameProps.firstName && nameProps.lastName) return true
        return false
    }

    function hasImageProps(p: IImageProps | INameProps): p is IImageProps {
        const nameProps = props as IImageProps
        if (nameProps.imageUrl) return true
        return false
    }

    let firstName: string, lastName: string, imageUrl: string
    let validName: boolean = false
    if (hasNameProps(props)) {
        firstName = props.firstName
        lastName = props.lastName
        validName = firstName.length > 0 && lastName.length > 0
    }

    if (hasImageProps(props)) {
        imageUrl = props.imageUrl
    }

    const { className, tag, size } = props
    const Tag = tag || 'div'

    const fallback = validName ? capitalize(`${firstName[0]}${lastName[0]}`) : <FA className="avatar__icon align-middle" icon="user" />

    return (
        <Tag className={cx(className, 'avatar', { 'avatar--sm': size === 'sm', 'avatar--xs': size === 'xs' })}>
            {imageUrl &&
                <>
                    <img className={cx('avatar__img', { 'd-none': !imageLoaded.value })} src={imageUrl} alt="" onLoad={imageLoaded.setTrue} />
                    {!imageLoaded.value && fallback}
                </>}
            {!imageUrl && fallback}
        </Tag>
    )
}
