import localSearch from '@src/components/search/LocalSearch'
import { IMappedSearchProperty } from '@src/components/search/SearchAssistant'
import { ISearchResult } from '@src/components/search/SearchSection'

export default function useLocalSearch<T extends object>(items: T[], searchProperties: IMappedSearchProperty<T>[]) {
    return (filter: string, sort: string, page: number, perPage: number): Promise<ISearchResult<T>> =>
        new Promise((resolve) => {
            const result = localSearch(items, searchProperties, filter, sort, page, perPage)
            resolve({
                items: result,
                totalItems: items.length
            })
        })
}
