import React from 'react'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'

export interface IProps {
    id?: string
    label?: React.ReactChild
    labelClass?: string
    checked: boolean
    onClick?: () => void
    disabled?: boolean
}

export default class CheckboxRadio extends React.PureComponent<IProps> {
    private readonly handleClick = () => {
        if (!this.props.disabled && this.props.onClick) {
            this.props.onClick()
        }
    }

    public render() {
        return (
            <div className="checkbox-radio">
                <input className="checkbox-radio__control" type="checkbox" checked={!!this.props.checked} disabled={this.props.disabled} readOnly />
                <span className={cx('checkbox-radio__label', this.props.labelClass)}>
                    <span className="checkbox-radio__box" role="checkbox" aria-labelledby={this.props.id} aria-checked={!!this.props.checked} tabIndex={0} onClick={this.handleClick} onKeyDown={this.handleClick}/>
                    <label id={this.props.id}>{this.props.label}</label>
                    <span className="checkbox-radio__check">
                        <FA size="lg" icon="check" />
                    </span>
                </span>
            </div>
        )
    }
}
