import React from 'react'
import Dropzone, { DropzoneRef } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'reactstrap'

import { listFiles, newUploads } from '@src/actions/sandbox'
import FA from '@src/components/common/FontAwesomeIcon'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import SandboxUpload from '@src/components/sandbox/SandboxUpload'
import { RootState } from '@src/types/models'
import { Sandbox } from '@src/types/sandbox'

const UploadStage: React.FunctionComponent<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {

    const dropzoneRef = React.useRef<DropzoneRef>()
    const sandbox = useSelector<RootState, Sandbox.State>(s => s.sandbox)
    const dispatch = useDispatch()

    React.useEffect(
        () => {
            dispatch(listFiles())
        },
        []
    )

    function onDrop(files: File[]) {
        dispatch(newUploads(files))
    }

    function preventPropagation(e: React.MouseEvent) {
        e.stopPropagation()
    }

    function triggerDropzone(e: React.MouseEvent) {
        e.stopPropagation()
        dropzoneRef.current.open()
    }

    const uploadsComponents = sandbox.uploads.map((upload, idx) => <SandboxUpload key={idx} upload={upload} />)
    const innerDropzone = sandbox.uploads.length ? <div onClick={preventPropagation}>{uploadsComponents}</div> : (
        <div className="text-center" onClick={preventPropagation}>
            <FA icon="cloud-upload" size="3x" />
            <h2 className="my-3">Drag and Drop</h2>
            <p>or</p>
            <Button block color="success" onClick={triggerDropzone}>Select Files</Button>
        </div>
    )

    return (
        <>
            <h4 className="mb-3">Upload files to the sandbox</h4>
            <p>Welcome to your document sandbox. This area will hold all of your uploads until you commit them into InfoPoint.</p>
            {sandbox.loadingUploads && <p>Loading files <FA spin icon="spinner-third" /></p>}
            <Dropzone ref={dropzoneRef} onDrop={onDrop} >
                {({ getRootProps, getInputProps }) => <div data-cy="sandbox-upload" className="document-sandbox__filebox" onClick={preventPropagation} {...getRootProps()}>
                    {innerDropzone}
                    <input {...getInputProps()}  />
                </div>}
            </Dropzone>
        </>
    )
}

export default UploadStage
