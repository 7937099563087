import { Store } from 'redux'
import { Action, handleActions,  } from 'redux-actions'

import { debounce, forOwn } from 'lodash'

import * as Actions from '@src/actions/Actions'
import { IAddEntityPayload, IRemoveEntityPayload, IToggleEntityPayload, rehydrateWidget } from '@src/actions/widget'
import * as Keys from '@src/logic/storage/keys'
import { BudgetItem, Commitment, PaymentClaimBrief } from '@src/types/costs'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { IProjectWidgetState, WidgetState } from '@src/types/widget'

const initialProjectWidgetState: IProjectWidgetState = {
    companies: [],
    revisions: [],
    transmittals: [],
    users: [],
    emails: [],
    budgetItems: [],
    commitments: [],
    paymentClaims: []
}
const initialState: WidgetState = {}

export function getActiveProjectWidgetState(state: RootState) {
    return state.projects.active ? getProjectState(state.widget, state.projects.active.id) : initialProjectWidgetState
}

export function getProjectState(state: WidgetState, projectId: string): IProjectWidgetState {
    return state.hasOwnProperty(projectId) ? state[projectId] : { ...initialProjectWidgetState }
}

function updateProjectState(widgetState: WidgetState, projectId: string, projectState: IProjectWidgetState): WidgetState {
    return { ...widgetState, [projectId]: { ...projectState } }
}

export function widgetPersistAndSync(store: Store<RootState>) {
    window.removeEventListener('storage', handleStorageEvent)
    window.addEventListener('storage', handleStorageEvent, false)
    let prevState = store.getState()
    const persist = debounce(persistStateToLocalStorage, 500)
    parseAndRehydrateWidget(localStorage.getItem(Keys.WidgetState))

    store.subscribe(() => {
        const nextState = store.getState()

        if (prevState.widget !== nextState.widget) {
            persist(nextState.widget)
        }

        prevState = nextState
    })

    function parseAndRehydrateWidget(widgetState: string) {
        store.dispatch(rehydrateWidget(JSON.parse(widgetState)))
    }

    function handleStorageEvent(e: StorageEvent) {
        if (e.key && e.key.indexOf(Keys.WidgetState) === 0) {
            if (e.oldValue !== e.newValue) {
                parseAndRehydrateWidget(e.newValue)
            }
        }
    }

    function persistStateToLocalStorage(nextState: WidgetState) {
        localStorage.setItem(Keys.WidgetState, JSON.stringify(nextState))
    }
}


export default handleActions<WidgetState, any>(
    {
        [Actions.WIDGET_REHYDRATE]: (state, action: Action<WidgetState>) => {
            return { ...state, ...(forOwn(action.payload, (value, key) => ({ [key]: { ...initialProjectWidgetState, ...value } }))) }
        },
        [Actions.WIDGET_CLEAR]: (state, action: Action<string>) => {
            return updateProjectState(state, action.payload, initialProjectWidgetState)
        },
        [Actions.WIDGET_REVISIONS_ADD]: (state, action: Action<IAddEntityPayload<Revision>>) => {
            const { projectId, entities: revisions } = action.payload
            const projectState = getProjectState(state, projectId)
            const revisionsToAdd = projectState.revisions.concat(
                revisions.filter(newRev => !projectState.revisions.find(r => r.id === newRev.id)))
            return updateProjectState(state, projectId, { ...projectState, revisions: revisionsToAdd })
        },
        [Actions.WIDGET_REVISIONS_TOGGLE]: (state, action: Action<IToggleEntityPayload<Revision>>) => {
            const { projectId, entities: revisions, snapTogether } = action.payload
            const projectState = getProjectState(state, projectId)
            const selectedRevisions = projectState.revisions.map(x => x.id)
            let newRevisions = projectState.revisions
            if (snapTogether) {
                if (!revisions.every(r => selectedRevisions.includes(r.id))) {
                    newRevisions = newRevisions.concat(revisions.filter(newRev => !selectedRevisions.includes(newRev.id)))
                } else {
                    const revisionIds = revisions.map(x => x.id)
                    newRevisions = newRevisions.filter(r => !revisionIds.includes(r.id))
                }
            } else {
                newRevisions = newRevisions.concat(
                    revisions.filter(newRev => !selectedRevisions.includes(newRev.id)))
            }

            return updateProjectState(state, projectId, { ...projectState, revisions: newRevisions })
        },
        [Actions.WIDGET_REVISIONS_REMOVE]: (state, action: Action<IRemoveEntityPayload>) => {
            const { projectId, entityIds: revisionIds } = action.payload
            const projectState = getProjectState(state, projectId)
            return updateProjectState(state, action.payload.projectId, { ...projectState, revisions: projectState.revisions.filter(r => !revisionIds.includes(r.id)) })
        },
        [Actions.WIDGET_REVISIONS_CLEAR]: (state, action: Action<string>) => {
            const projectState = getProjectState(state, action.payload)
            return updateProjectState(state, action.payload, { ...projectState, revisions: [] })
        },
        [Actions.WIDGET_USERS_ADD]: (state, action: Action<IAddEntityPayload<UserBasic>>) => {
            const { projectId, entities: users } = action.payload
            const projectState = getProjectState(state, projectId)
            const usersToAdd = projectState.users.concat(
                users.filter(user => !projectState.users.find(r => r.id === user.id)))
            return updateProjectState(state, projectId, { ...projectState, users: usersToAdd })
        },
        [Actions.WIDGET_USERS_REMOVE]: (state, action: Action<IRemoveEntityPayload>) => {
            const { projectId, entityIds: userIds } = action.payload
            const projectState = getProjectState(state, projectId)
            return updateProjectState(state, projectId, { ...projectState, users: projectState.users.filter(u => !userIds.includes(u.id)) })
        },
        [Actions.WIDGET_USERS_CLEAR]: (state, action: Action<string>) => {
            const projectState = getProjectState(state, action.payload)
            return updateProjectState(state, action.payload, { ...projectState, users: [] })
        },
        [Actions.WIDGET_BUDGET_ITEMS_ADD]: (state, action: Action<IAddEntityPayload<BudgetItem>>) => {
            const { projectId, entities: budgetItems } = action.payload
            const projectState = getProjectState(state, projectId)
            const budgetItemsToAdd = projectState.budgetItems.concat(
                budgetItems.filter(item => !projectState.budgetItems.find(r => r.id === item.id)))
            return updateProjectState(state, projectId, { ...projectState, budgetItems: budgetItemsToAdd })
        },
        [Actions.WIDGET_BUDGET_ITEMS_REMOVE]: (state, action: Action<IRemoveEntityPayload>) => {
            const { projectId, entityIds: budgetItemIds } = action.payload
            const projectState = getProjectState(state, projectId)
            return updateProjectState(state, projectId, { ...projectState, budgetItems: projectState.budgetItems.filter(bi => !budgetItemIds.includes(bi.id)) })
        },
        [Actions.WIDGET_BUDGET_ITEMS_CLEAR]: (state, action: Action<string>) => {
            const projectState = getProjectState(state, action.payload)
            return updateProjectState(state, action.payload, { ...projectState, budgetItems: [] })
        },
        [Actions.WIDGET_COMMITMENTS_ADD]: (state, action: Action<IAddEntityPayload<Commitment>>) => {
            const { projectId, entities: commitments } = action.payload
            const projectState = getProjectState(state, projectId)
            const commitmentsToAdd = projectState.commitments.concat(
                commitments.filter(commitment => !projectState.commitments.find(c => c.id === commitment.id)))
            return updateProjectState(state, projectId, { ...projectState, commitments: commitmentsToAdd })
        },
        [Actions.WIDGET_COMMITMENTS_REMOVE]: (state, action: Action<IRemoveEntityPayload>) => {
            const { projectId, entityIds: commitmentIds } = action.payload
            const projectState = getProjectState(state, projectId)
            return updateProjectState(state, projectId, { ...projectState, commitments: projectState.commitments.filter(c => !commitmentIds.includes(c.id)) })
        },
        [Actions.WIDGET_COMMITMENTS_CLEAR]: (state, action: Action<string>) => {
            const projectState = getProjectState(state, action.payload)
            return updateProjectState(state, action.payload, { ...projectState, commitments: [] })
        },
        [Actions.WIDGET_PAYMENTCLAIMS_ADD]: (state, action: Action<IAddEntityPayload<PaymentClaimBrief>>) => {
            const { projectId, entities: paymentClaims } = action.payload
            const projectState = getProjectState(state, projectId)
            const paymentClaimsToAdd = projectState.paymentClaims.concat(
                paymentClaims.filter(paymentClaim => !projectState.paymentClaims.find(c => c.id === paymentClaim.id)))
            return updateProjectState(state, projectId, { ...projectState, paymentClaims: paymentClaimsToAdd })
        },
        [Actions.WIDGET_PAYMENTCLAIMS_REMOVE]: (state, action: Action<IRemoveEntityPayload>) => {
            const { projectId, entityIds: paymentClaimIds } = action.payload
            const projectState = getProjectState(state, projectId)
            return updateProjectState(state, projectId, { ...projectState, paymentClaims: projectState.paymentClaims.filter(p => !paymentClaimIds.includes(p.id)) })
        },
        [Actions.WIDGET_PAYMENTCLAIMS_CLEAR]: (state, action: Action<string>) => {
            const projectState = getProjectState(state, action.payload)
            return updateProjectState(state, action.payload, { ...projectState, paymentClaims: [] })
        }
    },
    initialState
)
