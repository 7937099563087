import React from 'react'

import cx from 'classnames'

import Link from '@src/components/common/Link'

export interface IProps<TData> extends Omit<React.HTMLAttributes<HTMLAnchorElement>, 'onClick'> {
    id?: string
    data: TData
    onClick: (data: TData) => void
    className?: string
    href?: string
    disabled?: boolean
    innerRef?: React.RefObject<HTMLElement>
}

function LinkAction<TData>(props: React.PropsWithChildren<IProps<TData>>) {

    function handleClick(e: React.MouseEvent<HTMLElement>) {
        if (props.href == null) {
            e.preventDefault()
        }
        props.onClick(props.data)
    }

    const { className, disabled, href, id, innerRef, children, ...rest } = props
    return (
        <Link {...rest} innerRef={innerRef} id={id} to={href} tag={href == null ? 'span' : 'a'} onClick={handleClick} className={cx('selectable-content__icon', className, { disabled })} role="button">
            {children}
        </Link>
    )
}

export default LinkAction
