import React from 'react'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { IModalPropsManager } from '@src/components/modal/ModalProps'

interface IProps {
    message: React.ReactChild
    header?: React.ReactChild
    confirmAction?: string
    rejectAction?: string
    onConfirm: () => void
    onReject?: () => void
    danger?: boolean
}

export default class ConfirmationModal extends React.PureComponent<IProps & IModalPropsManager> {

    public render() {
        const { children, message, header, confirmAction, danger, rejectAction, onConfirm, onReject, open, toggle } = this.props
        return (
            <Modal isOpen={open} toggle={toggle}>
                {header && <ModalHeader toggle={toggle}>{header}</ModalHeader>}
                <ModalBody>
                    {message}
                    {children}
                </ModalBody>
                <ModalFooter>
                    {onReject && <Button color="default" onClick={onReject}>{rejectAction || 'Cancel'}</Button>}
                    <Button color={danger && 'danger'} onClick={onConfirm}>{confirmAction || 'Confirm'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}
