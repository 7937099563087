import React from 'react'

import useConstant from '@src/hooks/useConstant'

interface IHookConfig {
    initialPage: number
    initialPerPage: number
    initialTotalItems: number
}

interface IPaginationHookData {
    page: number
    perPage: number
    totalPages: number
    setTotalItems: (count: number) => void
    setPage: (page: number) => void
    setPerPage: (perPage: number) => void
    nextPage: () => void
    previousPage: () => void
    firstPage: () => void
    lastPage: () => void
}

const defaultConfig: IHookConfig = {
    initialPage: 1,
    initialPerPage: 50,
    initialTotalItems: 0
}

export function usePagination(config?: Partial<IHookConfig>): IPaginationHookData {
    const constantConfig = useConstant(() => ({ ...defaultConfig, ...(config ?? {}) }))

    const [page, setPage] = React.useState(constantConfig.initialPage)
    const [perPage, setPerPageInner] = React.useState(constantConfig.initialPerPage)
    const [totalItems, setTotalItems] = React.useState(constantConfig.initialTotalItems)

    const totalPages = Math.max(1, Math.ceil(totalItems / perPage))

    function setPerPage(count: number) {
        if (count < 1) return
        setPerPageInner(count)
        setPage(1)
    }

    function nextPage() {
        if (page < totalPages) setPage(page + 1)
    }

    function previousPage() {
        if (page > 1) setPage(page - 1)
    }

    function firstPage() {
        setPage(1)
    }

    function lastPage() {
        setPage(totalPages)
    }

    return {
        page,
        perPage,
        totalPages,
        setTotalItems,
        setPage,
        setPerPage,
        nextPage,
        previousPage,
        firstPage,
        lastPage
    }
}
