import React from 'react'

import cx from 'classnames'

import Breadcrumb from '@src/components/navigation/Breadcrumb'

interface IProps {
    wrapperClassName?: string
}

export default class MiniBanner extends React.PureComponent<IProps> {

    public render() {
        return (
            <section className={cx('banner', this.props.wrapperClassName)}>
                <div className="banner__inner">
                    <div className="banner__panel banner__panel--lead">
                        <Breadcrumb/>
                    </div>
                    {this.props.children}
                </div>
            </section>
        )
    }
}
