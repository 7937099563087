import React from 'react'
import { DragDropContext, Draggable, DropResult, Droppable } from 'react-beautiful-dnd'
import { Field, useForm } from 'react-final-form'
import { FieldArray, FieldArrayRenderProps, useFieldArray } from 'react-final-form-arrays'
import { useSelector } from 'react-redux'
import { Button, ButtonGroup, Card, CardBody, CardHeader, Col, Container, FormFeedback, ListGroupItem, Row, UncontrolledTooltip } from 'reactstrap'

import { flatMap } from 'lodash'
import * as uuid from 'uuid'

import FA from '@src/components/common/FontAwesomeIcon'
import { IWizardPageProps } from '@src/components/forms/WizardPage'
import { IDocumentSandboxDocument, IDocumentSandboxWizardForm } from '@src/components/sandbox/DocumentSandbox'
import { contentTypeIcon } from '@src/logic/utils/FileFormats'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'
import { Sandbox } from '@src/types/sandbox'

const UploadDraggable: React.FC<{ upload: Sandbox.Upload, index: number, asNewDocument?: () => void }> = ({ upload, index, asNewDocument }) => {
    return (
        <Draggable draggableId={upload.id} key={upload.id} index={index}>
            {(provided, snapshot) => (
                <li
                    key={upload.id}
                    ref={provided.innerRef}
                    className="list-group-item border rounded-sm shadow-sm my-1"
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                >
                    <div className="d-flex align-items-center">
                        <FA icon={contentTypeIcon(upload.contentType)} className="mr-2" /> {upload.filename}
                        {asNewDocument &&
                            <Button id={`sandbox-create-doc-${upload.id}`} onClick={asNewDocument} type="button" size="sm" color="quaternary" className="ml-auto">
                                <FA icon="plus" />
                                <UncontrolledTooltip target={`sandbox-create-doc-${upload.id}`}>New Document</UncontrolledTooltip>
                            </Button>
                        }
                    </div>
                    <div>{}</div>
                </li>
            )}
        </Draggable>
    )
}

const FileSelectStage: React.FC<IWizardPageProps<IDocumentSandboxWizardForm>> = () => {
    const uploads = useSelector<RootState, Sandbox.Upload[]>(state => state.sandbox.uploads)
    const toRevise = useSelector<RootState, Revision[]>(state => state.sandbox.toRevise)
    const sandboxProject = useSelector<RootState, Project>(s => s.sandbox.project)
    const documents = useFieldArray('documents', {})
    const form = useForm<IDocumentSandboxWizardForm>()

    React.useEffect(
        () => {
            if (sandboxProject && sandboxProject !== form.getState().values.project) {
                toRevise.forEach(r => documents.fields.push(buildDocument(r)))
                form.change('project', sandboxProject)
            }
        },
        [toRevise, sandboxProject]
    )

    function allocatedUploads(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']): Sandbox.Upload[] {
        return flatMap(fields.value, d => d.uploads)
    }

    function unallocatedUploads(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']) {
        return uploads.filter(u => allocatedUploads(fields).find(au => au.id === u.id) == null)
    }

    function handleDragToFiles(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields'], result: DropResult) {
        if (result.source.droppableId === 'sandbox-files') {
            return
        }

        const id = result.source.droppableId.split('sandbox-documents-')[1]
        const idx = fields.value.findIndex(x => x.id === id)
        fields.update(idx, { ...fields.value[idx], uploads: fields.value[idx].uploads.filter(x => x.id !== result.draggableId) })
    }

    function handleDragToRevisions(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields'], result: DropResult) {
        const documentId = result.destination.droppableId.split('sandbox-documents-')[1]
        const idx = fields.value.findIndex(x => x.id === documentId)

        if (result.destination.droppableId === result.source.droppableId) {
            form.mutators.move(`documents[${idx}].uploads`, result.source.index, result.destination.index)
        } else {
            form.mutators.insert(`documents[${idx}].uploads`, result.destination.index, uploads.find(x => x.id === result.draggableId))

            if (result.source.droppableId.startsWith('sandbox-documents-')) {
                const removeFromId = result.source.droppableId.split('sandbox-documents-')[1]
                const removeFromIdx = fields.value.findIndex(x => x.id === removeFromId)
                form.mutators.remove(`documents[${removeFromIdx}].uploads`, result.source.index)
            }
        }
    }

    function makeHandleDragEnd(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']): (result: DropResult) => void {
        return (result: DropResult) => {
            if (result.destination == null) return

            if (result.destination.droppableId === 'sandbox-files') {
                return handleDragToFiles(fields, result)
            }

            if (result.destination.droppableId.startsWith('sandbox-documents')) {
                return handleDragToRevisions(fields, result)
            }
        }
    }

    function buildDocument(revision?: Revision, ...uploadsForDocument: Sandbox.Upload[]): IDocumentSandboxDocument {
        return { id: uuid.v4(), existingRevision: revision, uploads: uploadsForDocument }
    }

    function remainingUploadsToNewDocuments(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']) {
        return () => unallocatedUploads(fields).map(u => fields.push(buildDocument(undefined, u)))
    }

    function remainingUploadsAsNewDocument(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields']) {
        return () => fields.push(buildDocument(undefined, ...unallocatedUploads(fields)))
    }

    function removeDocument(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields'], document: IDocumentSandboxDocument) {
        return () => fields.remove(fields.value.findIndex(x => x.id === document.id))
    }

    function uploadToNewDocument(fields: FieldArrayRenderProps<IDocumentSandboxDocument, HTMLElement>['fields'], upload: Sandbox.Upload) {
        return () => {
            const emptyDocIdx = fields.value ? fields.value.findIndex(v => v.uploads.length === 0) : -1

            if (emptyDocIdx > -1) {
                fields.update(emptyDocIdx, { ...fields.value[emptyDocIdx], uploads: [upload] })
            } else {
                fields.push(buildDocument(undefined, upload))
            }
        }
    }

    return (
        <Container fluid className="h-100">
            <h4 className="mb-3">Organise your files into new documents and revisions</h4>
            <FieldArray<IDocumentSandboxDocument> name="documents">
                {({ fields }) => {
                    const unallocated = unallocatedUploads(fields)
                    return (
                        <>
                            <Row className="mb-3">
                                <Col>
                                    <Field name="documents">
                                        {({ meta: { error, dirty, touched } }) => touched && typeof error === 'string' && <FormFeedback className="d-block">{error}</FormFeedback>}
                                    </Field>
                                </Col>
                            </Row>
                            <Row className="mb-3 overflow-auto">
                                <DragDropContext onDragEnd={makeHandleDragEnd(fields)}>
                                    <Col md={6}>
                                        <Card>
                                            <CardHeader className="d-flex">
                                                <span>Sandbox Files</span>
                                                <span className="ml-auto">
                                                    <ButtonGroup>
                                                        <Button id="sandbox-uploads-to-document-each" disabled={unallocated.length === 0} onClick={remainingUploadsToNewDocuments(fields)}>
                                                            <FA icon="file" />
                                                            <UncontrolledTooltip target="sandbox-uploads-to-document-each">Each upload to new document</UncontrolledTooltip>
                                                        </Button>
                                                        <Button id="sandbox-uploads-to-single-document" disabled={unallocated.length === 0} onClick={remainingUploadsAsNewDocument(fields)}>
                                                            <FA icon="copy" />
                                                            <UncontrolledTooltip target="sandbox-uploads-to-single-document">All uploads as revisions</UncontrolledTooltip>
                                                        </Button>
                                                    </ButtonGroup>
                                                </span>
                                            </CardHeader>
                                            <CardBody>
                                                <Droppable droppableId="sandbox-files">
                                                    {(provided, snapshot) =>
                                                        <ul ref={provided.innerRef} {...provided.droppableProps} className="list-group list-group-flush">
                                                            {unallocated.map((upload, idx) => <UploadDraggable key={upload.id} upload={upload} index={idx} asNewDocument={uploadToNewDocument(fields, upload)}/>)}
                                                            <ListGroupItem style={{ zIndex: -1 }} className="border-0" />{provided.placeholder}
                                                        </ul>
                                                    }
                                                </Droppable>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                    <Col md={6}>
                                        {fields.value && fields.value.map((doc, idx) => (
                                            <Card className="mb-3" key={doc.id}>
                                                <CardHeader>
                                                    <div className="d-flex">
                                                        <span>{doc.existingRevision ? <span>{doc.existingRevision.name} <span className="text-muted">({doc.existingRevision.fileName})</span></span> : 'New Document'}</span>
                                                        <span className="ml-auto"><FA role="button" className="pointer" icon="times" onClick={removeDocument(fields, doc)} /></span>
                                                    </div>
                                                </CardHeader>
                                                <CardBody>
                                                    <Droppable droppableId={`sandbox-documents-${doc.id}`} >
                                                        {(docProvided, docSnapshot) =>
                                                            <ul ref={docProvided.innerRef} {...docProvided.droppableProps} className="list-group list-group-flush border-0">
                                                                {doc.uploads.map((upload, uidx) => <UploadDraggable key={upload.id} upload={upload} index={uidx} />)}
                                                                <ListGroupItem style={{ zIndex: -1 }} className="border-0" />{docProvided.placeholder}
                                                            </ul>
                                                        }
                                                    </Droppable>
                                                </CardBody>
                                            </Card>
                                        ))}
                                    </Col>
                                </DragDropContext>
                            </Row>
                        </>
                    )
                }}
            </FieldArray>
        </Container>
    )
}

export default FileSelectStage
