import React from 'react'
import { Button, Card, CardBody, CardHeader, Col, Container, ListGroup, ListGroupItem, Row } from 'reactstrap'

import AvatarList from '@src/components/common/AvatarList'
import FA from '@src/components/common/FontAwesomeIcon'
import FroalaViewer from '@src/components/common/FroalaViewer'
import LinkWithTooltip from '@src/components/common/LinkWithTooltip'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { EmailOperations } from '@src/logic/auth/operations'
import { EmailDownloadAttachmentsLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { Email, EmailAttachment, EmailUser } from '@src/types/email'

const RecipientLine: React.FC<{ title: string, recipients: EmailUser[] }> = React.memo(({ title, recipients }) => {
    return (
        <div className="d-flex align-items-center">
            <div className="mr-2"><strong>{title}:</strong></div>
            <div className="flex-shrink-1">
                <AvatarList maxToShow={4} avatarImageLinks={recipients.map(t => null)} />
            </div>
            <div>
                {recipients.map((a, idx) => <React.Fragment key={idx}><LinkWithTooltip to="#" tooltip={a.email}>{a.name || a.email}</LinkWithTooltip>{idx < recipients.length - 1 && ', '}</React.Fragment>)}
            </div>
        </div>
    )
})

const Attachment: React.FC<{ emailId: string, attachment: EmailAttachment, disabled: boolean }> = React.memo(({ attachment, emailId, disabled }) => {

    function downloadAttachment() {
        downloadURL(EmailDownloadAttachmentsLink(auth.getSessionToken(), emailId, attachment.contentId))
    }

    return (
        <ListGroupItem className="d-flex align-items-center" key={attachment.storageObjectName}>
            <span className="flex-grow-1"><FA icon={fileTypeIcon(attachment.fileName)} /> {attachment.fileName}</span>
            <Button color="link" onClick={downloadAttachment} disabled={disabled} aria-label="Download attachment"><FA icon="download" /></Button>
        </ListGroupItem>
    )
})

interface IProps {
    email: Email
}

const EmailPage: React.FC<IProps> = ({ email }) => {

    const nonContentAttachments = email.attachments.filter(a => a.contentId == null)
    const disableDownload = nonContentAttachments.length === 0 || !isAuthorised(email.myAccess, EmailOperations.Download)

    function downloadAllAttachments() {
        downloadURL(EmailDownloadAttachmentsLink(auth.getSessionToken(), email.id))
    }

    return (
        <Container fluid>
            <Row>
                <Col xs={12} md={6} className="mb-3">
                    <Card className="mb-3">
                        <CardHeader><FA icon="info-circle" /> Details</CardHeader>
                        <CardBody>
                            <Row>
                                <Col xs={2}><strong>Subject</strong></Col>
                                <Col>{email.subject}</Col>
                            </Row>
                            <Row>
                                <Col xs={2}><strong>Author</strong></Col>
                                <Col>{email.from.name}</Col>
                            </Row>
                        </CardBody>
                    </Card>
                    <Card className="mb-3">
                        <CardHeader><FA icon="users" /> Recipients</CardHeader>
                        <CardBody>
                            <RecipientLine title="To" recipients={email.to} />
                            <hr />
                            <RecipientLine title="Cc" recipients={email.cc} />
                            <hr />
                            <RecipientLine title="Bcc" recipients={email.bcc} />
                        </CardBody>
                    </Card>
                    <Card>
                        <CardHeader className="d-inline-flex align-items-center">
                            <span className="flex-grow-1"><FA icon="paperclip" /> Attachments</span>
                            <Button
                                color="primary"
                                onClick={downloadAllAttachments}
                                disabled={disableDownload}
                            >
                                <FA icon="download" /> Download All
                            </Button>
                        </CardHeader>
                        <ListGroup flush>
                            {nonContentAttachments.length === 0 && <ListGroupItem className="text-center">No attachments</ListGroupItem>}
                            {nonContentAttachments.map((attachment, idx) => <Attachment key={idx} emailId={email.id} attachment={attachment} disabled={disableDownload} />)}
                        </ListGroup>
                    </Card>
                </Col>
                <Col xs={12} md={6} className="mb-3">
                    <Card>
                        <CardHeader><FA icon="envelope" /> Body</CardHeader>
                        <CardBody>
                            <FroalaViewer content={email.htmlBody || email.textBody} />
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </Container>
    )
}

export default EmailPage
