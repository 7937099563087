import React from 'react'
import { Button, Form, FormGroup, Label } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import ButtonLink from '@src/components/common/ButtonLink'
import Link from '@src/components/common/Link'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { required } from '@src/logic/forms/validation'
import * as Routes from '@src/logic/routing/routes'

export interface INewProjectFormData {
    name: string
    code: string
    description: string
}

class NewProjectForm extends React.PureComponent<InjectedFormProps<INewProjectFormData>> {
    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label for="name">Name</Label>
                    <Field id="name" name="name" size="lg" component={ValidatedInput} validate={required} />
                </FormGroup>
                <FormGroup>
                    <Label for="code">Code (optional)</Label>
                    <Field id="code" name="code" component={ValidatedInput} />
                </FormGroup>
                <FormGroup>
                    <Label>Description (optional)</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" />
                </FormGroup>
                <div className="d-flex">
                    <Button onClick={this.props.handleSubmit}>Create Project</Button>
                    <ButtonLink className="ml-auto" color="link" to={Routes.COMPANY}>or go back</ButtonLink>
                </div>
            </Form>
        )
    }
}

export default reduxForm({})(NewProjectForm)
