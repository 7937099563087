import React from 'react'
import { DropdownItem } from 'reactstrap'

import { IconAvatar } from '@src/components/common/Avatar'
import history from '@src/logic/routing/history'
import * as Routes from '@src/logic/routing/routes'

export default class HomeDropdownItem extends React.PureComponent {

    private navigateHome() {
        history.push(Routes.DASHBOARD)
    }

    public render() {
        return (
            <DropdownItem key={'__home'} className="project__menu-item pointer" tag="a" role="button" onClick={this.navigateHome}>
                <IconAvatar size="sm" icon="home" />
                <span className="project__text">
                    <span className="project__title">Home</span>
                    <span className="project__desc">Your Personal Dashboard</span>
                </span>
            </DropdownItem>
        )
    }
}
