import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, Input } from 'reactstrap'

import { CancelToken } from 'axios'
import moment from 'moment'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import { buildOptions } from '@src/components/common/Select'
import Tags from '@src/components/common/Tags'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import TransmittalStatusIndicator from '@src/components/transmittal/TransmittalStatusIndicator'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { ProjectOperations } from '@src/logic/auth/operations'
import { RevisionsDownloadLink, TransmittalDelete, TransmittalsList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Headers from '@src/logic/http/headers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { capitalize, isNullOrEmpty } from '@src/logic/utils/Strings'
import { mutedValue } from '@src/logic/utils/ValueHelper'
import { Project } from '@src/types/project'
import { TransmittalBrief, TransmittalStatus } from '@src/types/transmittal'

interface IProps {
    project: Project
}

interface IState {
    statusApproval: string
    showConfirmDelete: boolean
    transmittalToDelete: TransmittalBrief
}

export default class TransmittalsSection extends React.PureComponent<IProps & RouteComponentProps, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<TransmittalBrief, never>>

    constructor(props) {
        super(props)

        this.searchSectionRef = React.createRef()

        this.state = {
            statusApproval: 'All',
            showConfirmDelete: false,
            transmittalToDelete: null
        }
    }

    private readonly onSearch = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken) => {
        const filterWithStatus = filter != null && filter !== '' ? `${this.getStatusApprovalFilter()} AND ${filter}` : this.getStatusApprovalFilter()
        const response = await TransmittalsList(this.props.project.id, filterWithStatus, sort, page, perPage, { cancelToken })
        return ({
            items: response.data,
            totalItems: +response.headers[Headers.PaginationTotalCount]
        })
    }

    private readonly handleStatusApprovalChange = (e: React.ChangeEvent<HTMLInputElement>, triggerSearch: () => void) => this.setState({ statusApproval: e.currentTarget.value }, triggerSearch)

    private readonly getStatusApprovalFilter = (): string => {
        switch (this.state.statusApproval) {
            case 'Draft':
                return 'status: Draft'
            case 'Pending Approval':
                return 'status: Published AND approval: Pending'
            case 'Sent':
                return 'status: Sent'
            case 'Rejected':
                return 'approval_status: Denied'
            case 'All':
            default:
                return ''
        }
    }

    private readonly newTransmittal = () => {
        this.props.history.push(Routes.projectTransmittalNew(this.props.project.id))
    }

    private readonly deleteTransmittal = async () => {
        await TransmittalDelete(this.state.transmittalToDelete.id)
        NotificationService.info('Transmittal deleted')
        this.clearConfirmDelete()
        this.searchSectionRef.current.doSearch()
    }

    private readonly downloadRevisions = (transmittal: TransmittalBrief) => {
        downloadURL(RevisionsDownloadLink(auth.getSessionToken(), this.props.project.id, ...transmittal.revisions.map(r => r.revisionId)))
    }

    private readonly setTransmittalToDelete = (transmittal: TransmittalBrief) => {
        this.setState({ transmittalToDelete: transmittal, showConfirmDelete: true })
    }

    private readonly clearConfirmDelete = () => {
        this.setState({ showConfirmDelete: false })
    }

    private readonly clearTransmittalToDelete = () => {
        this.setState({ transmittalToDelete: null })
    }

    public render() {
        const { transmittalToDelete, showConfirmDelete } = this.state

        const noItemsFoundMessage = (
            <>
                <div className="my-3"><FA size="3x" icon="file-times" /></div>
                <p className="lead">No transmittals found with the current search criteria...</p>
                <p>Ensure that your search is valid - make sure you didn't miss any speech marks or parentheses. Alternatively, try fewer filters.</p>
            </>
        )

        return (
            <SearchSection<TransmittalBrief, never>
                ref={this.searchSectionRef}
                headers={[
                    {
                        name: 'Reference #',
                        sortKey: 'reference',
                        accessor: 'reference',
                        sortable: true,
                        overrideRenderer: transmittal => isNullOrEmpty(transmittal.reference) ? mutedValue('(unpublished)') : <Link to={Routes.projectTransmittal(transmittal.projectId, transmittal.id)}>{transmittal.reference}</Link>
                    },
                    {
                        name: 'Subject',
                        sortKey: 'subject',
                        sortable: true,
                        noSmallHeader: true,
                        overrideRenderer: transmittal => (
                            <div className="selectable-content__title">
                                <TransmittalStatusIndicator transmittal={transmittal} />
                                <Link to={Routes.projectTransmittal(transmittal.projectId, transmittal.id)}>{isNullOrEmpty(transmittal.subject) ? '(No Subject)' : transmittal.subject}</Link>
                            </div>
                        )
                    },
                    {
                        name: 'Sender',
                        sortable: true,
                        sortKey: 'updated_by',
                        overrideRenderer: t => t.createdBy.name
                    },
                    {
                        name: 'Status',
                        sortable: true,
                        overrideRenderer: t => capitalize(t.status)
                    },
                    {
                        name: 'Tags',
                        sortKey: 'tags',
                        sortable: true,
                        overrideRenderer: t => <Tags tags={t.tags} />
                    },
                    {
                        name: 'Last Update',
                        sortable: true,
                        sortKey: 'updated_at',
                        overrideRenderer: t => moment(t.updatedDate).format('L')
                    },
                    {
                        name: 'Sent',
                        overrideRenderer: t => t.status === TransmittalStatus.Sent ? moment(t.updatedDate).format('lll') : mutedValue('(not sent)')
                    },
                    {
                        name: 'Actions',
                        noSmallHeader: true,
                        overrideRenderer: t => (
                            <div className="justify-content-end text-right selectable-content__actions" data-tour={TourTags.TransmittalTableRowActions}>
                                {t.status !== TransmittalStatus.Draft && <TooltipLink id={`view-${t.id}`} tooltip="View" to={Routes.projectTransmittalView(t.projectId, t.id)} className="selectable-content__icon order-lg-1" data-tour={TourTags.TransmittalTableRowView}><FA icon="eye" /></TooltipLink>}
                                {t.status === TransmittalStatus.Draft && <TooltipLink id={`edit-${t.id}`} tooltip="Edit" to={Routes.projectTransmittalView(t.projectId, t.id)} className="selectable-content__icon order-lg-1"><FA icon="pencil" /></TooltipLink>}
                                <TooltipLinkAction id={`delete-${t.id}`} tooltip="Delete" disabled={t.status === TransmittalStatus.Sent} data={t} onClick={this.setTransmittalToDelete} data-tour={TourTags.TransmittalTableRowDelete}><FA icon="trash" /></TooltipLinkAction>
                                <TooltipLinkAction id={`download-${t.id}`} tooltip="Download Attachments" data={t} onClick={this.downloadRevisions} data-tour={TourTags.TransmittalTableRowDownload}><FA icon="download" /></TooltipLinkAction>
                            </div>
                        ),
                        headerWrapperClass: 'text-right'
                    }
                ]}
                onSearch={this.onSearch}
                searchAssistantProperties={[
                    {
                        name: 'Reference',
                        searchKey: 'reference',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Reference Number',
                        searchKey: 'reference_number',
                        type: PropertyType.Number
                    },
                    {
                        name: 'Subject',
                        searchKey: 'subject',
                        type: PropertyType.Text
                    },
                    {
                        name: 'Created',
                        searchKey: 'created',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Updated',
                        searchKey: 'updated',
                        type: PropertyType.Date
                    },
                    {
                        name: 'Status',
                        searchKey: 'status',
                        type: PropertyType.Select,
                        selectOptions: buildOptions(['Draft', 'Published', 'Sent'])
                    }
                ]}
                extraSearchBarElements={[{
                    element: onSearch => <Button disabled={!isAuthorised(this.props.project.myAccess, ProjectOperations.CreateTransmittal)} onClick={this.newTransmittal}>New Transmittal</Button>,
                    position: 'before'
                },
                {
                    element: onSearch => (
                        // tslint:disable-next-line:jsx-no-lambda
                        <Input type="select" value={this.state.statusApproval} onChange={e => this.handleStatusApprovalChange(e, onSearch)}>
                            <option>All</option>
                            <option>Draft</option>
                            <option>Pending Approval</option>
                            <option>Sent</option>
                            <option>Rejected</option>
                        </Input>
                    ),
                    position: 'before'
                }]}
                noItemsFoundMessage={noItemsFoundMessage}
            >
                <ConfirmationModal
                    danger
                    header={`Delete Transmittal`}
                    message={<span>Are you sure you want to delete <strong>{transmittalToDelete && (isNullOrEmpty(transmittalToDelete.subject) ? '(No Subject)' : transmittalToDelete.subject)}</strong></span>}
                    confirmAction="Yes, delete"
                    open={showConfirmDelete}
                    toggle={this.clearConfirmDelete}
                    onConfirm={this.deleteTransmittal}
                    onClosed={this.clearTransmittalToDelete}
                />
            </SearchSection>
        )
    }
}
