// tslint:disable:jsx-no-lambda
import React from 'react'
import { useSelector } from 'react-redux'
import { Route, Switch, useRouteMatch } from 'react-router'

import ProjectBanner from '@src/components/banner/ProjectBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import EmailToolbar from '@src/components/email/EmailToolbar'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import { EmailOperations } from '@src/logic/auth/operations'
import { EmailGetById } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import ClientErrorPage from '@src/pages/common/ClientErrorPage'
import EmailAccessPage from '@src/pages/email/EmailAccessPage'
import EmailPage from '@src/pages/email/EmailPage'
import { Email } from '@src/types/email'
import { ClientErrorCode } from '@src/types/http'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

const EmailPages: React.FC = () => {
    const match = useRouteMatch<Routes.IProjectEmailParams>()
    const project = useSelector<RootState, Project>(s => s.projects.active)
    const emailAsync = useAsyncCancellable(
        (cancelToken, _emailId?) => EmailGetById(match.params.emailId, { cancelToken }).then(res => res.data),
        [match.params.emailId]
    )

    function getBannerTitle() {
        if (emailAsync.error) {
            if (!isAxiosError(emailAsync.error)) {
                NotificationService.error('An error occurred while loading the email')
                return 'Error loading email'
            }

            switch (emailAsync.error.response.status) {
                case ClientErrorCode.Forbidden:
                    return 'Permission Issue'
                case ClientErrorCode.NotFound:
                    return 'Email Not Found'
                default:
                    return 'Error loading email'
            }
        }

        return emailAsync.loading ? <>Loading email <FA icon="spinner-third" spin /></> : emailAsync.result.subject
    }

    const email: Email | undefined = emailAsync.result
    const clientError = emailAsync.error && isAxiosError(emailAsync.error) ? emailAsync.error.response.status : undefined

    return (
        <CrumbRoute path="" linkPath={email ? Routes.projectEmail(project.id, email.id) : match.url} title={email?.subject ?? ''}>
            <>
                <ProjectBanner project={project} overrideTitle={getBannerTitle()} />
                <ClientErrorPage clientErrorCode={clientError} resourceName="email" requiredOperation={EmailOperations.Read}>
                    {email &&
                        <>
                            <EmailToolbar currentRoutePath={location.pathname} email={email} />
                            <div className="mb-3" />
                            <Switch>
                                <CrumbRoute path={Routes.PROJECT_EMAIL_ACCESS} title="Access">
                                    <EmailAccessPage email={email} reloadEmail={emailAsync.execute} />
                                </CrumbRoute>
                                <Route>
                                    <EmailPage email={email} />
                                </Route>
                            </Switch>
                        </>
                    }
                </ClientErrorPage>
            </>
        </CrumbRoute>
    )
}

export default React.memo(EmailPages)
