import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Label, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { ConfigProps, Field, FormSection, InjectedFormProps, reduxForm } from 'redux-form'

import { buildOptions, IOption } from '@src/components/common/Select'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { RFMetadataInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { required } from '@src/logic/forms/validation'
import { RevisionUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Revision } from '@src/types/document'
import { IMetadataForm } from '@src/types/metadata'
import { RootState } from '@src/types/models'

interface IProps extends IMetadataForm {
    revision: Revision
}

export interface IRevisionEditFormData {
    name: string
    revNumber: string
    revDate: Date
    author: string
    tags: IOption<string>[]
    description: string
    metadata: {
        [key: string]: any
    }
}

class RevisionEditForm extends React.PureComponent<IProps & InjectedFormProps<IRevisionEditFormData, IProps>> {

    private renderMetadataFields = () => {
        return this.props.definitions.map(d => (
            <Col key={d.key} xs={12} md={6} lg={3} className="mb-3">
                <Label>{d.name} <MetadataTooltip id={'meta-tooltip-' + d.key} definition={d} placement="right" /></Label>
                <RFMetadataInput definition={d} />
            </Col>
        ))
    }

    public render() {
        const { definitions, pristine, error, submitting, invalid } = this.props
        return (
            <>
                <h5>Common Info</h5>
                <Row>
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Label>Revision Name</Label>
                        <Field name="name" component={ValidatedInput} validate={required} />
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Label>Revision #</Label>
                        <Field name="revNumber" component={ValidatedInput} />
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Label>Revision Date</Label>
                        <Field name="revDate" component={ValidatedDatePicker} />
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Label>Author</Label>
                        <Field name="author" component={ValidatedInput} />
                    </Col>
                    <Col xs={12} md={6} lg={9} className="mb-3">
                        <Label>Description</Label>
                        <Field name="description" component={ValidatedInput} type="textarea" />
                    </Col>
                    <Col xs={12} md={6} lg={3} className="mb-3">
                        <Label>Tags</Label>
                        <Field name="tags" isMulti creatable component={ValidatedSelect} />
                    </Col>
                </Row>
                {definitions.length > 0 &&
                    <FormSection name="metadata" component={Row as any}>
                        <Col xs={12}>
                            <h5>Metadata</h5>
                        </Col>
                        {this.renderMetadataFields()}
                    </FormSection>
                }
                <Button disabled={submitting || pristine} onClick={this.props.handleSubmit} className="mr-2">Save Changes</Button>
                <Button color="warning" disabled={pristine || submitting} onClick={this.props.reset}>Reset</Button>
            </>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & ConfigProps<IRevisionEditFormData, IProps>): IProps & ConfigProps<IRevisionEditFormData, IProps> {
    const { revision, definitions } = ownProps
    const initialValues: IRevisionEditFormData = {
        name: revision.name,
        revNumber: revision.revNumber,
        revDate: revision.revDate,
        author: revision.author,
        tags: buildOptions(revision.tags),
        description: revision.description,
        metadata: definitions.map(d => d.key).reduce((prev, definitionKey) => ({ ...prev, [definitionKey]: revision.metadata[definitionKey] }), {})
    }

    return {
        ...ownProps,
        initialValues
    }
}

function commitRevisionUpdates(values: IRevisionEditFormData, dispatch: Dispatch, props: IProps) {
    const update: Api.Request.RevisionUpdate = {
        author: values.author,
        description: values.description,
        metadata: props.definitions.reduce(
            (map, d) => {
                map[d.key] = values.metadata[d.key]
                return map
            },
            {}),
        name: values.name,
        published: props.revision.published,
        archived: props.revision.archived,
        revisionDate: values.revDate,
        revisionNumber: values.revNumber,
        tags: values.tags ? values.tags.map(x => x.value) : []
    }
    return RevisionUpdate(props.revision.id, update)
}

export default connect(mapStateToProps)(reduxForm<IRevisionEditFormData, IProps>({ onSubmit: commitRevisionUpdates })(RevisionEditForm))
