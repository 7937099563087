import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import { UserStatus } from '@src/types/principal'

interface IProps {
    id: string
    status: UserStatus
}

interface IFormattedStatus {
    class: string
    tooltip: string
}

const UserStatusIndicator: React.FC<IProps> = ({ id, status }) => {
    function formatStatus(): IFormattedStatus {
        switch (status) {
            case UserStatus.Invited:
                return { class: 'status-badge--warning', tooltip: 'Invited' }
            case UserStatus.Activated:
                return { class: 'status-badge--pre-success', tooltip: 'Activated (requires approval)' }
            case UserStatus.Enabled:
                return { class: 'status-badge--success', tooltip: 'Enabled' }
            case UserStatus.Disabled:
                return { class: 'status-badge--dangeer', tooltip: 'Disabled' }
            default:
                return { class: 'status-badge--default', tooltip: 'Unknown' }
        }
    }

    const formattedStatus = formatStatus()

    return (
        <>
            <UncontrolledTooltip className="text-center" target={`usi-${id}`}>{formattedStatus.tooltip}</UncontrolledTooltip>
            <span id={`usi-${id}`} className={`status-badge ${formattedStatus.class}`} aria-label={status} />
        </>
    )
}

export default UserStatusIndicator
