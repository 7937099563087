import React from 'react'

import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    register: RegisterOverview
    currentRoutePath: string
}

export default class RegisterToolbar extends React.PureComponent<IProps> {

    public render() {
        const { register } = this.props
        return (
            <section className="banner">
                <Toolbar>
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER)} to={Routes.projectRegister(register.projectId, register.id)} name="Search" nameBrief="Search" iconBrief="search" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER_COLUMNS)} to={Routes.projectRegisterColumns(register.projectId, register.id)} name="Columns" nameBrief="Cols" iconBrief="table" />
                    <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER_ACCESS)} to={Routes.projectRegisterAccess(register.projectId, register.id)} name="Access" nameBrief="Access" iconBrief="lock" />
                    <div className="ml-auto d-none d-md-flex">
                        <ToolbarLink disabled tool name="Export" icon="download" iconBrief="download" />
                        <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_REGISTER_SETTINGS)} to={Routes.projectRegisterSettings(register.projectId, register.id)} tool name="Settings" icon="cog" iconBrief="cog" />
                    </div>
                </Toolbar>
            </section>
        )
    }
}
