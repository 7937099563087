import React from 'react'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import FroalaEditorInput from '@src/components/common/FroalaEditorInput'

interface IProps {
    getEditorContent: () => string
}

export interface ITemplateLayoutEditFormData {
    layout: string
}

class TemplateLayoutEditForm extends React.PureComponent<InjectedFormProps<ITemplateLayoutEditFormData>> {
    private editor

    public componentDidUpdate() {
        if (this.editor) this.editor.button.bulkRefresh()
    }

    private readonly penEditor = (ed) => {
        this.editor = ed
        ed.button.bulkRefresh()
    }

    public render() {
        return (
            <Field
                component={FroalaEditorInput}
                name="layout"
                config={{
                    events: {
                        initialized: this.penEditor
                    },
                    htmlUntouched: true,
                    entities: '&lsquo;&rsquo;',
                    htmlRemoveTags: ['script'],
                    iframe: true,
                    iframeStyle: 'body{overflow: auto; height: 800px;}',
                    toolbarSticky: false
                }}
            />
        )
    }
}

export default reduxForm<ITemplateLayoutEditFormData>({})(TemplateLayoutEditForm)
