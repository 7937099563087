import React from 'react'
import { useHistory } from 'react-router'
import { Button, ButtonProps } from 'reactstrap'

interface IProps extends ButtonProps {
    onClick?: (event: React.MouseEvent) => void
    target?: string
    replace?: boolean
    to: string
}

const isModifiedEvent = (event: React.MouseEvent<any>) =>
    !!(event.metaKey || event.altKey || event.ctrlKey || event.shiftKey)

const ButtonLink: React.FC<IProps> = ({ children, onClick, replace, target, to, ...props }) => {
    const history = useHistory()

    function handleClick(event: React.MouseEvent) {
        onClick?.(event)

        if (
            !event.defaultPrevented && // onClick prevented default
            event.button === 0 && // ignore everything but left clicks
            !target && // let browser handle "target=_blank" etc.
            !isModifiedEvent(event) // ignore clicks with modifier keys
        ) {
            event.preventDefault()

            if (replace) {
                history.replace(to)
            } else {
                history.push(to)
            }
        }
    }

    return (
        <Button {...props} onClick={handleClick}>
            {children}
        </Button>
    )
}

export default ButtonLink
