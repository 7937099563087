import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Card, CardBody, CardHeader } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { CompanyUsersList, GroupsList, ProjectAccessEntityType, ProjectSettingsDefaultAccessGet, ProjectSettingsDefaultAccessUpdate } from '@src/logic/http/Api'
import { projectSettingsDefaultAccess } from '@src/logic/routing/routes'
import { humanize, toCapitalizedWords } from '@src/logic/utils/Strings'
import { AccessControlList, AclEntry } from '@src/types/access'
import { Api } from '@src/types/api'
import { Company } from '@src/types/principal'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    company: Company
}

interface IState {
    projectAccessEntityType: ProjectAccessEntityType
}

interface IDefaultAccessRouteParams {
    accessEntityType: string
}

export default class DefaultAccessSection extends React.PureComponent<RouteComponentProps<IDefaultAccessRouteParams> & IProps, IState> {

    constructor(props) {
        super(props)

        const paramTypeAsLower = this.props.match.params.accessEntityType.toLowerCase()
        const entityType = Object.keys(ProjectAccessEntityType).find(x => ProjectAccessEntityType[x].toLowerCase() === paramTypeAsLower)

        if (entityType == null) {
            this.props.history.replace(projectSettingsDefaultAccess(this.props.project.id, ProjectAccessEntityType.Document))
        }

        this.state = {
            projectAccessEntityType: entityType == null ? null : ProjectAccessEntityType[entityType]
        }
    }

    private getDefaultEntityAcl = async (): Promise<AccessControlList> => {
        const projectSettings = await ProjectSettingsDefaultAccessGet(this.props.project.id)

        switch (this.state.projectAccessEntityType) {
            case ProjectAccessEntityType.Document:
                return projectSettings.data.defaultDocumentAcl
            case ProjectAccessEntityType.Revision:
                return projectSettings.data.defaultRevisionAcl
            case ProjectAccessEntityType.Communication:
                return projectSettings.data.defaultCommunicationAcl
            case ProjectAccessEntityType.Transmittal:
                return projectSettings.data.defaultTransmittalAcl
            case ProjectAccessEntityType.InboundEmail:
                return projectSettings.data.defaultInboundEmailAcl
            case ProjectAccessEntityType.Register:
                return projectSettings.data.defaultRegisterAcl
            default:
                return null
        }
    }

    private loadPrincipals = async () => {
        const principalEntries: AclEntry[] = []
        const usersPromise = CompanyUsersList(undefined, undefined, 1, 200)
        const groupsPromise = GroupsList(undefined, 1, 200)
        const { company } = this.props

        const results = await Promise.all([usersPromise, groupsPromise])

        principalEntries.push(...results[0].data.filter(x => x.status === 'Enabled').map<AclEntry>(u => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`,
            email: u.email,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'user'
        })))

        principalEntries.push(...results[1].data.map<AclEntry>(g => ({
            id: g.id,
            name: g.name,
            email: undefined,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'group'
        })))

        principalEntries.push({
            id: company.id,
            company: company.id,
            name: company.name,
            email: undefined,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'company'
        })

        return principalEntries
    }

    private saveDefaultAccessUpdates = async (accessUpdate: Api.Request.AccessControlListUpdate) => {
        try {
            await ProjectSettingsDefaultAccessUpdate(this.props.project.id, this.state.projectAccessEntityType, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    public render() {
        return (
            <div>
                {this.state.projectAccessEntityType != null &&
                <Card>
                    <CardHeader>
                        <FA icon="lock-alt" />
                        <span> {toCapitalizedWords(humanize(this.state.projectAccessEntityType))} Default Access Control</span>
                    </CardHeader>
                    <CardBody>
                        <AccessControlTable
                            getAcl={this.getDefaultEntityAcl}
                            loadPrincipals={this.loadPrincipals}
                            commitUpdates={this.saveDefaultAccessUpdates}
                        />
                    </CardBody>
                </Card>
                }
            </div>
        )
    }
}
