import React from 'react'
import { Button, Form, FormGroup, FormText, Input, InputGroup, InputGroupAddon, InputGroupText } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { AccountPasswordReset } from '@src/logic/http/Api'

interface IProps {
    onCancel: () => void
}

interface IState {
    email: string
    submitting: boolean
    resetSuccess: boolean
}

export default class ResetPasswordForm extends React.PureComponent<IProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            email: '',
            submitting: false,
            resetSuccess: false
        }
    }

    private handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ email: e.currentTarget.value })
    }

    private handleResetPassword = async (e: React.FormEvent) => {
        e.preventDefault()
        this.setState({ submitting: true })
        try {
            await AccountPasswordReset(this.state.email)
            this.setState({ resetSuccess: true })
        } catch (e) {
            // TODO: handle error in resetting password
        } finally {
            this.setState({ submitting: false })
        }
    }

    private handleBackToLogin = (e: React.MouseEvent) => {
        e.preventDefault()
        this.props.onCancel()
    }

    public render() {
        const { email, submitting, resetSuccess } = this.state
        return (
            <Form onSubmit={this.handleResetPassword}>
                {!submitting && !resetSuccess && <div className="my-3 text-center text-muted">Enter your account email address below and we will send you a password reset email.</div>}
                {!submitting && !resetSuccess && <FormGroup className="mb-3">
                    <InputGroup>
                        <InputGroupAddon addonType="prepend"><InputGroupText><FA icon="at" /></InputGroupText></InputGroupAddon>
                        <Input onChange={this.handleEmailChange} value={email} placeholder="your@example.com" type="email" required />
                    </InputGroup>
                </FormGroup>}
                {submitting &&
                <div className="mb-3 text-center">
                    <div className="mb-2"><FA icon="spinner-third" size="3x" spin /></div>
                    <div>Sending reset email...</div>
                </div>}
                {resetSuccess ? (
                        <div className="mb-3 text-center">
                            <div className="text-success mb-2"><FA icon="check-circle" size="3x" /></div>
                            <div>Password reset email has been sent to <strong>{email}</strong>.</div>
                        </div>
                    ) : (
                        <>
                            <div className="mb-5 pointer text-center"><a href="#" className="text-reset" role="button" onClick={this.handleBackToLogin}>Take me back to log in</a></div>
                            <Button disabled={submitting} block size="lg" color="primary" className="text-uppercase p-3 font-weight-bold" type="submit" style={{ fontSize: '90%', letterSpacing: '.08rem' }}>Reset Password <FA className="ml-1" icon="chevron-right" /></Button>
                        </>
                    )
                }
            </Form>
        )
    }
}
