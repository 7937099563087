import decode from 'jwt-decode'

export function decodeToken<T>(token) {
    return decode<T>(token)
}

export function getTokenExpirationDate(token: string): Date {
    const decoded = decode<{ exp: number }>(token)
    if (!decoded.exp) {
        return null
    }

    const date = new Date(0) // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp)
    return date
}

export function isTokenExpired(token: string): boolean {
    const date = getTokenExpirationDate(token)
    if (date === null) { return false }
    const offsetSeconds = 0
    return !(date.valueOf() > (new Date().valueOf() + (offsetSeconds * 1000)))
}

export function isValidToken(token: string): boolean {
    try {
        decode(token)
        return true
    } catch (ex) {
        return false
    }
}
