import React from 'react'
import Dropzone from 'react-dropzone'
import { Button, FormFeedback } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'
import SmartUpload from '@src/components/common/SmartUpload'
import { SandboxDeleteFile, SandboxSignUpload } from '@src/logic/http/Api'
import { Manager } from '@src/logic/storage/gcs/GCSUploadManager'
import { Sandbox } from '@src/types/sandbox'

interface IProps extends WrappedFieldProps {
}

export default class ValidatedDropzoneSandboxInput extends React.PureComponent<IProps> {

    private onDrop = async (files: File[]) => {
        const sandboxSignReq = {
            fileName: encodeURI(files[0].name),
            contentType: files[0].type || 'text/plain; charset=us-ascii',
            length: files[0].size
        }
        const signedUrlResponse = (await SandboxSignUpload(sandboxSignReq)).data

        const googleUpload = await Manager.newSignedUpload(signedUrlResponse.name, signedUrlResponse.url, files[0], this.handleUploadUpdate, this.handleUploadCompleted)

        const upload: Sandbox.Upload = {
            id: googleUpload.id,
            bytesTotal: googleUpload.file.size,
            bytesUploaded: 0,
            contentType: googleUpload.file.type,
            filename: googleUpload.file.name,
            pausingAtByte: 0,
            state: 'running'
        }

        this.props.input.onChange(upload)
        Manager.startUpload(googleUpload.id)
    }

    private handleUploadUpdate = (id: string, bytesCompleted: number) => {
        this.props.input.onChange({ ...this.props.input.value, bytesUploaded: bytesCompleted })
    }

    private handleUploadCompleted = (id: string) => {
        this.props.input.onChange({ ...this.props.input.value, state: 'completed' })
    }

    private handleRemove = () => {
        Manager.cancelUpload(this.props.input.value.id)
        this.props.input.onChange(undefined)
        SandboxDeleteFile(this.props.input.value.id)
    }

    public componentWillUnmount() {
        if (this.props.input.value) {
            Manager.cancelUpload(this.props.input.value.id)
        }
    }

    public render() {
        const { value: upload } = this.props.input
        const showError = this.props.meta.touched && !!this.props.meta.error
        return (
            <Dropzone onDrop={this.onDrop} multiple={false}>
                {({ getRootProps, getInputProps }) =>
                    <>
                    <div className={cx('document-sandbox__filebox', { 'border-danger': showError })} onClick={null} {...getRootProps()}>
                        {upload ? <SmartUpload upload={upload} onCancel={this.handleRemove} /> : (
                            <div className="text-center">
                                <FA icon="cloud-upload" size="3x" />
                                <h2 className="my-3">Drag and Drop</h2>
                                <p>or</p>
                                <Button block color="success" onClick={null}>Select File</Button>
                            </div>
                        )}
                        <input {...getInputProps()}/>
                    </div>
                    {showError && <FormFeedback className="text-danger d-block">{this.props.meta.error}</FormFeedback>}
                    </>}
            </Dropzone>
        )
    }
}
