import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import { loadProjectCostsOverview } from '@src/actions/project'
import ActionBar from '@src/components/common/ActionBar'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import NewCommitmentDefinitionForm, { INewCommitmentDefinitionFormData } from '@src/components/costs/settings/NewCommitmentDefinitionForm'
import GenericContentTable from '@src/components/table/GenericContentTable'
import { CostsNewCommitmentDefinition } from '@src/logic/forms/Forms'
import { CommitmentDefinitionCreate, CommitmentDefinitionDelete } from '@src/logic/http/Api'
import * as Routes from '@src/logic/routing/routes'
import { CommitmentDefinition } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IConnectedState {
    projectId: string
    commitmentDefinitions: CommitmentDefinition[]
}

interface IConnectedDispatch {
    submitCreateNewCommitmentDefinition: () => void
    reloadCostsOverview: () => void
}

interface IState {
    isCreating: boolean
}

class CommitmentDefinitionSettingsSection extends React.PureComponent<IConnectedState & IConnectedDispatch, IState> {
    constructor(props) {
        super(props)

        this.state = {
            isCreating: false
        }
    }

    private readonly deleteCommitmentDefinition = async (commitmentDefinition: CommitmentDefinition) => {
        await CommitmentDefinitionDelete(this.props.projectId, commitmentDefinition.code)
        this.props.reloadCostsOverview()
    }

    private readonly markCreating = () => {
        this.setState({ isCreating: true })
    }

    private readonly clearCreating = () => {
        this.setState({ isCreating: false })
    }

    private readonly doSubmit = () => {
        this.props.submitCreateNewCommitmentDefinition()
    }

    private readonly handleSubmit = async (values: INewCommitmentDefinitionFormData) => {
        return await CommitmentDefinitionCreate(this.props.projectId, {
            code: values.code,
            name: values.name,
            commitmentNumberDefinition: values.commitmentNumberDefinition,
            statusDefinitions: {},
            defaultVariationAdditions: values.defaultVariationAdditionsPercentage / 100,
            defaultVariationDeductions: values.defaultVariationDeductionsPercentage / 100,
            defaultDocumentLinkCategories: values.defaultDocumentLinkCategories?.map(x => x.value) ?? [],
            defaultPaymentDocumentLinkCategories: []
        })
    }

    private readonly onSubmitSuccess = () => {
        this.props.reloadCostsOverview()
        this.clearCreating()
    }

    public render() {
        const { commitmentDefinitions } = this.props
        const { isCreating } = this.state

        const definitionsExistBody = (
            <GenericContentTable
                data={commitmentDefinitions}
                headers={[
                    {
                        name: 'Code',
                        accessor: 'code'
                    },
                    {
                        name: 'Name',
                        accessor: 'name'
                    },
                    {
                        name: 'Additions',
                        overrideRenderer: cd => ((cd.defaultVariationAdditions || 0) * 100).toFixed(3) + '%'
                    },
                    {
                        name: 'Deductions',
                        overrideRenderer: cd => ((cd.defaultVariationDeductions || 0) * 100).toFixed(3) + '%'
                    },
                    {
                        name: 'Current Number',
                        overrideRenderer: cd => cd.commitmentNumberDefinition.currentNumber
                    },
                    {
                        name: 'Current Certificate',
                        overrideRenderer: cd => 'TODO'
                    },
                    {
                        name: 'Actions',
                        headerWrapperClass: 'text-right',
                        noSmallHeader: true,
                        overrideRenderer: (commitmentType, rowIdx) => (
                            <div className="justify-content-end text-right selectable-content__actions">
                                <TooltipLink id={`edit-commitdef-${rowIdx}`} tooltip="Edit" to={Routes.projectCostsSettingsCommitmentDefinition(this.props.projectId, commitmentType.code)}><FA icon="pencil" /></TooltipLink>
                                <TooltipLinkAction id={`remove-commitdef-${rowIdx}`} tooltip="Remove" data={commitmentType} onClick={this.deleteCommitmentDefinition}><FA icon="trash" /></TooltipLinkAction>
                            </div>
                        )
                    }
                ]}
            />
        )

        const noDefinitionsBody = (
            <Card body className="text-center mt-3">
                <div className="my-3"><FA size="3x" icon={'badge-dollar' as any} /></div>
                <p className="lead"><Button onClick={this.markCreating}>Create Commitment Type</Button></p>
                <p>There are no commitment types for this project.</p>
            </Card>
        )

        return (
            <>
                {commitmentDefinitions.length > 0 &&
                <ActionBar className="mb-3">
                    <Button onClick={this.markCreating}><FA icon="plus-circle" /> Create Type</Button>
                </ActionBar>
                }
                <Container fluid>
                    {commitmentDefinitions.length > 0 ? definitionsExistBody : noDefinitionsBody}
                    <Modal isOpen={isCreating} toggle={this.clearCreating}>
                        <ModalHeader toggle={this.clearCreating}>New Commitment Type</ModalHeader>
                        <ModalBody>
                            <NewCommitmentDefinitionForm
                                form={CostsNewCommitmentDefinition}
                                enableReinitialize={true}
                                keepDirtyOnReinitialize={false}
                                onSubmit={this.handleSubmit}
                                onSubmitSuccess={this.onSubmitSuccess}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.doSubmit}>Create</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: {}): IConnectedDispatch {
    return {
        ...ownProps,
        submitCreateNewCommitmentDefinition: () => dispatch(submit(CostsNewCommitmentDefinition)),
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview())
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        commitmentDefinitions: state.projects.activeCostsOverview.commitmentDefinitions
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(CommitmentDefinitionSettingsSection)
