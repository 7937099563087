import React from 'react'
import { Card, Col, Container, Row } from 'reactstrap'

import LoginForm from '@src/components/auth/LoginForm'
import ResetPasswordForm from '@src/components/auth/ResetPasswordForm'

import * as infopointLogo from 'public/img/infopoint_colour.svg'

interface IState {
    showPasswordReset: boolean
}

export default class LoginPage extends React.PureComponent<{}, IState> {

    constructor(props) {
        super(props)

        this.state = {
            showPasswordReset: false
        }
    }

    private handleForgotPassword = () => {
        this.setState({ showPasswordReset: true })
    }

    private handleBackToLogin = () => {
        this.setState({ showPasswordReset: false })
    }

    public render() {
        const { showPasswordReset } = this.state
        return (
            <div className="flex-grow-1 d-flex" style={{ background: 'linear-gradient(135deg, rgba(2,21,79,1) 0%, rgba(37,64,146,1) 100%)' }}>
                <Container className="my-auto">
                    <Row>
                        <Col sm={9} md={7} lg={5} className="mx-auto">
                            <Card body className="my-5" style={{ boxShadow: '0 0.5rem 1rem 0 rgba(0, 0, 0, 0.1)' }}>
                                <div className="d-flex">
                                    <img className="mb-2 mx-auto" style={{ height: 60, width: 'auto' }} src={infopointLogo} />
                                </div>
                                <hr />
                                {!showPasswordReset && <LoginForm onForgotPassword={this.handleForgotPassword} />}
                                {showPasswordReset && <ResetPasswordForm onCancel={this.handleBackToLogin} />}
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        )
    }
}
