import React from 'react'
import { useSelector } from 'react-redux'
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap'

import { IconAvatar } from '@src/components/common/Avatar'
import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import LoadingProjectsDropdownItem from '@src/components/navigation/LoadingProjectsDropdownItem'
import ProjectDropdownItem from '@src/components/navigation/ProjectDropdownItem'
import * as Routes from '@src/logic/routing/routes'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IProps {
    toggle: () => void
    open: boolean
}

const SidebarProjectNavigation: React.FunctionComponent<IProps> = ({ open, toggle }) => {
    const activeProject = useSelector<RootState, Project>(state => state.projects.active)
    const favouriteProjects = useSelector<RootState, Project[]>(state => state.projects.favourites)

    const toggleProps: {
        icon: IPFontawesomeIcon
        fallback: React.ReactNode
        title: string
        description: string
    } = activeProject ? {
        icon: 'project-diagram',
        fallback: activeProject.settings.code || activeProject.code,
        title: activeProject.name,
        description: activeProject.description
    } : {
        icon: 'star',
        fallback: null,
        title: 'Favourite Projects',
        description: ''
    }

    function renderFavouriteProjects() {
        if (!favouriteProjects) return [<LoadingProjectsDropdownItem key="__loading" />]

        if (favouriteProjects.length === 0) {
            return [(
                <DropdownItem key="__no_favourites" className="project__menu-item pointer" tag={Link} role="button" to={Routes.COMPANY_PROJECTS}>
                    <span className="project__text">
                        <span className="project__title text-center">No favourite projects</span>
                    </span>
                </DropdownItem>
            )]
        }

        return favouriteProjects
                .filter(x => !activeProject || x.id !== activeProject.id)
                .map(p => (<ProjectDropdownItem key={p.id} project={p} />))
    }

    return (
        <Dropdown direction="right" className="project sidebar__project" isOpen={open} toggle={toggle}>
            <DropdownToggle className="project__trigger pointer" tag="a" role="button">
                <IconAvatar size="sm" icon={toggleProps.icon}>{toggleProps.fallback}</IconAvatar>
                <span className="project__text">
                    <span className="project__title">{toggleProps.title}</span>
                    <span className="project__desc">{toggleProps.description}</span>
                </span>
            </DropdownToggle>
            <DropdownMenu
                positionFixed
                modifiers={{ preventOverflow: { priority: ['bottom'] } }}
                className="project__menu dropdown-menu--alt"
            >
                {activeProject && <ProjectDropdownItem key="__active" project={activeProject} active />}
                {renderFavouriteProjects()}
                <DropdownItem header className="pt-0 pb-4">
                    <Link tag="small" className="float-right text-muted" to={Routes.COMPANY_PROJECTS} onClick={toggle}><FA icon="star" /> Add or Remove Favourites</Link>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>
    )
}

export default SidebarProjectNavigation
