import React from 'react'
import { Col, Form, FormGroup, Label, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { required } from '@src/logic/forms/validation'
import { BudgetReportColumn, BudgetReportColumnName } from '@src/types/costs'

interface IProps {
    editingExisting?: boolean
}

export interface IBudgetStatusFormData {
    code: string
    columns: { label: string, value: BudgetReportColumn }[]
}

const reportColumnOptions = Object.keys(BudgetReportColumnName).map<{ label: string, value: string }>(k => ({ label: BudgetReportColumnName[k], value: k }))

class BudgetStatusForm extends React.PureComponent<IProps & InjectedFormProps<IBudgetStatusFormData>> {

    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label>Code</Label>
                    <Field name="code" component={ValidatedInput} validate={required} disabled={this.props.editingExisting} />
                </FormGroup>
                <FormGroup>
                    <Label>Columns</Label>
                    <Field name="columns" component={ValidatedSelect} isMulti options={reportColumnOptions} />
                </FormGroup>
            </Form>
        )
    }
}

export default reduxForm<IBudgetStatusFormData, IProps>({})(BudgetStatusForm)
