import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'

import LinkAction, { IProps as LinkActionProps } from '@src/components/common/LinkAction'

interface IProps<TData> extends LinkActionProps<TData> {
    id: string,
    tooltip: React.ReactChild
}

export default class TooltipLinkAction<TData> extends React.PureComponent<IProps<TData>> {

    public render() {
        const { id, tooltip, children, ...linkProps } = this.props

        return (
            <LinkAction id={id} {...linkProps}>{children}<UncontrolledTooltip target={id} delay={{ show: 250, hide: 0 }} boundariesElement="window" flip={false}>{tooltip}</UncontrolledTooltip></LinkAction>
        )
    }
}
