import React from 'react'
import { Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'

import { groupBy } from 'lodash'

import { IGroupedOptions } from '@src/components/common/Select'
import ValidatedAsyncSelect from '@src/components/common/ValidatedAsyncSelect'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import { commitmentLabel, commitmentValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { Commitment, CommitmentReference, CostsOverview } from '@src/types/costs'

interface IProps {
    costsOverview: CostsOverview
    loadCommitments?: (query: string) => Promise<CommitmentReference[]>
}

export interface IPaymentClaimFormData {
    commitment: CommitmentReference
    value: number
    notes: string
    invoiceDate: Date
    invoiceReference: string
    claimDate: Date
    claimReference: string
}

class PaymentClaimForm extends React.PureComponent<IProps & InjectedFormProps<IPaymentClaimFormData, IProps>> {

    private loadOptions = async (query: string): Promise<IGroupedOptions<CommitmentReference>> => {
        if (this.props.loadCommitments == null) return Promise.resolve([])

        const commitments = await this.props.loadCommitments(query)

        const grouped = groupBy<CommitmentReference>(commitments, c => c.type)
        return Object.keys(grouped).map(g => ({
            label: `${g} - ${this.props.costsOverview.commitmentDefinitions.find(cd => cd.code === g).name}`,
            options: grouped[g]
        }))
    }

    public render() {
        const { loadCommitments } = this.props
        return (
            <Form>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Commitment</Label>
                            <Field name="commitment" isDisabled={loadCommitments == null} validate={required} component={ValidatedAsyncSelect} defaultOptions={true} loadOptions={this.loadOptions} getOptionLabel={commitmentLabel} getOptionValue={commitmentValue} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Invoice Date</Label>
                            <Field name="invoiceDate" component={ValidatedDatePicker} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Invoice Reference</Label>
                            <Field name="invoiceReference" component={ValidatedInput} />
                        </FormGroup>
                    </Col>
                    <Col>
                        <FormGroup>
                            <Label>Claim Date</Label>
                            <Field name="claimDate" component={ValidatedDatePicker} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Claim Reference</Label>
                            <Field name="claimReference" component={ValidatedInput} />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <FormGroup>
                            <Label>Value</Label>
                            <InputGroup>
                                <InputGroupAddon addonType="prepend"><InputGroupText>$</InputGroupText></InputGroupAddon>
                                <Field name="value" component={ValidatedCurrencyInput} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup>
                            <Label>Notes</Label>
                            <Field name="notes" type="textarea" component={ValidatedInput} />
                        </FormGroup>
                    </Col>
                </Row>
            </Form>
        )
    }
}

export default reduxForm<IPaymentClaimFormData, IProps>({})(PaymentClaimForm)
