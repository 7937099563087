import React from 'react'
import { FieldRenderProps } from 'react-final-form'
import { FormFeedback } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import cx from 'classnames'

import Select, { IAsyncProps } from '@src/components/common/Select'

export default class ValidatedAsyncSelect<TValue> extends React.PureComponent<Omit<IAsyncProps<TValue>, keyof { onChange, async, value }> & FieldRenderProps<TValue, HTMLElement>> {

    public render() {
        const { input, meta: { touched, error, submitError, dirtySinceLastSubmit }, creatable, ...selectProps } = this.props

        const showError = touched && (!!error || (!!submitError))
        return (
            <>
                <Select<TValue>
                    {...selectProps}
                    async
                    value={input.value}
                    onChange={input.onChange}
                    invalid={showError}
                />
                <FormFeedback className={cx({ 'd-block': showError })}>{showError && (error || submitError)}</FormFeedback>
            </>
        )
    }
}
