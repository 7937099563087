import React from 'react'
import { connect } from 'react-redux'
import { Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Row, Table } from 'reactstrap'
import { ConfigProps, Field, FieldArray, InjectedFormProps, reduxForm, WrappedFieldArrayProps } from 'redux-form'

import { IGroupedOptions } from '@src/components/common/Select'
import ValidatedAsyncSelect from '@src/components/common/ValidatedAsyncSelect'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { budgetStatusLabel, budgetStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue, revisionLabel, revisionValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { groupBy } from '@src/logic/utils/Collection'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { BudgetAdjustment, BudgetItem, BudgetReportColumn, BudgetStatusDefinition, CostCodeBrief, CostsOverview, Phase } from '@src/types/costs'
import { DocumentLink, Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IProps {
    isAdjustment: boolean
    budgetItemToEdit?: BudgetItem | BudgetAdjustment
    projectId: string
    costsOverview: CostsOverview
}

interface IConnectedState {
    availableApprovalDocuments: DocumentLink[]
}

export interface IBudgetItemFormData {
    name: string
    description: string
    costCode: CostCodeBrief
    phase: Phase
    status: BudgetStatusDefinition
    date: Date
    statusColumnValues: {
        status: string
        unreported: number
        original: number
        adjustment: number
    }[]
    clientApprovalNumber: string
    clientApprovalDocuments: DocumentLink[]
}

class BudgetItemForm extends React.PureComponent<IProps & IConnectedState & InjectedFormProps<IBudgetItemFormData, IProps>> {

    private loadCostCodes = async (query: string): Promise<IGroupedOptions<CostCodeBrief>> => {
        const response = await CostCodesList(this.props.projectId, 1, 200)
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }

    private renderValueInput(name: string) {
        return (
            <InputGroup size="sm">
                <InputGroupAddon addonType="prepend" size="sm"><InputGroupText>$</InputGroupText></InputGroupAddon>
                <Field name={name} component={ValidatedCurrencyInput} bsSize="sm" />
            </InputGroup>
        )
    }

    private renderStatusColumnValue = (member: string, index: number, statuses: BudgetStatusDefinition[]) => (
        <tr key={index}>
            <td>{statuses[index].code}</td>
            <td>{statuses[index].columns.includes(BudgetReportColumn.Unreported) ? this.renderValueInput(`${member}.unreported`) : <span>N/A</span>}</td>
            <td>{statuses[index].columns.includes(BudgetReportColumn.Original) ? this.renderValueInput(`${member}.original`) : <span>N/A</span>}</td>
            <td>{statuses[index].columns.includes(BudgetReportColumn.Adjustment) ? this.renderValueInput(`${member}.adjustment`) : <span>N/A</span>}</td>
        </tr>
    )
    private renderStatusMembers = ({ fields }: WrappedFieldArrayProps<string>) => {
        const statuses = this.props.isAdjustment ? this.props.costsOverview.budget.adjustmentStatusDefinitions : this.props.costsOverview.budget.itemStatusDefinitions

        return (
            <tbody>
                {fields.map((f, idx) => this.renderStatusColumnValue(f, idx, statuses))}
            </tbody>
        )
    }

    public render() {
        return (
            <Form>
                <Row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Name</Label>
                            <Field name="name" component={ValidatedInput} placeholder="Name" validate={required} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Cost Code</Label>
                            <Field name="costCode" component={ValidatedAsyncSelect} placeholder="Cost Code" validate={required} defaultOptions={true} loadOptions={this.loadCostCodes} getOptionValue={costCodeLabel} getOptionLabel={costCodeValue} cacheOptions />
                        </FormGroup>
                        <FormGroup>
                            <Label>Phase</Label>
                            <Field name="phase" component={ValidatedSelect} placeholder="Phase" options={this.props.costsOverview.phases} getOptionLabel={phaseLabel} getOptionValue={phaseValue} isClearable isOptionDisabled={isPhaseLocked} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Current Status</Label>
                            <Field name="status" component={ValidatedSelect} placeholder="Status" options={this.props.isAdjustment ? this.props.costsOverview.budget.adjustmentStatusDefinitions : this.props.costsOverview.budget.itemStatusDefinitions} validate={required} getOptionLabel={budgetStatusLabel} getOptionValue={budgetStatusValue} isClearable />
                        </FormGroup>
                        <FormGroup>
                            <Label>Date</Label>
                            <Field name="date" component={ValidatedDatePicker} validate={this.props.isAdjustment ? required : null} />
                        </FormGroup>
                        {this.props.isAdjustment &&
                            <>
                                <FormGroup>
                                    <Label>Client Approval Number</Label>
                                    <Field name="clientApprovalNumber" component={ValidatedInput} validate={required} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>Client Approval Documents</Label>
                                    <Field name="clientApprovalDocuments" component={ValidatedSelect} validate={required} options={this.props.availableApprovalDocuments} getOptionLabel={revisionLabel} getOptionValue={revisionValue} isMulti />
                                </FormGroup>
                            </>
                        }
                    </Col>
                </Row>
                <FormGroup>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" />
                </FormGroup>
                <Table>
                    <thead>
                        <tr>
                            <th>Status</th>
                            <th>Unreported</th>
                            <th>Original</th>
                            <th>Adjustment</th>
                        </tr>
                    </thead>
                    <FieldArray name="statusColumnValues" component={this.renderStatusMembers as any} />
                </Table>
            </Form>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & Omit<Partial<ConfigProps<IBudgetItemFormData, IProps>>, 'initialValues'>): Partial<ConfigProps<IBudgetItemFormData, IProps>> & IProps & IConnectedState {
    const { budgetItemToEdit, costsOverview } = ownProps

    let initialValues: Partial<IBudgetItemFormData>
    if (budgetItemToEdit == null) {
        initialValues = {
            date: new Date(),
            statusColumnValues: (ownProps.isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions).map(sd => ({
                status: sd.code,
                unreported: 0,
                original: 0,
                adjustment: 0
            }))
        }
    } else {
        const groupedStatusColumnValues = groupBy(budgetItemToEdit.values, 'status')
        initialValues = {
            costCode: budgetItemToEdit.costCode,
            description: budgetItemToEdit.description,
            name: budgetItemToEdit.name,
            phase: costsOverview.phases.find(p => p.code === budgetItemToEdit.phase),
            date: budgetItemToEdit.date,
            status: costsOverview.budget.itemStatusDefinitions.find(s => s.code === budgetItemToEdit.status),
            statusColumnValues: Object.keys(groupedStatusColumnValues).map((status) => {
                const unreported = groupedStatusColumnValues[status].find(x => x.column === BudgetReportColumn.Unreported)
                const original = groupedStatusColumnValues[status].find(x => x.column === BudgetReportColumn.Original)
                const adjustment = groupedStatusColumnValues[status].find(x => x.column === BudgetReportColumn.Adjustment)
                return {
                    status,
                    unreported: unreported ? unreported.value.value : 0,
                    original: original ? original.value.value : 0,
                    adjustment: adjustment ? adjustment.value.value : 0
                }
            }),
            ...(budgetItemToEdit.isAdjustment ? {
                clientApprovalNumber: budgetItemToEdit.clientApprovalNumber,
                clientApprovalDocuments: budgetItemToEdit.clientApprovalDocuments
            } : {})
        }
    }

    return {
        ...ownProps,
        initialValues,
        availableApprovalDocuments: getActiveProjectWidgetState(state).revisions.map<DocumentLink>(r => ({
            documentId: r.documentId,
            name: r.name,
            revisionId: r.id
        }))
    }
}

export default connect(mapStateToProps)(reduxForm<IBudgetItemFormData, IProps>({})(BudgetItemForm))
