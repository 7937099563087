import React from 'react'
import { Redirect, RouteComponentProps, Switch } from 'react-router'
import { Card, CardTitle, Col, Container, Nav, NavLink, Row } from 'reactstrap'

import MiniBanner from '@src/components/banner/MiniBanner'
import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import CrumbRoute from '@src/components/navigation/CrumbRoute'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import AccountPage from '@src/pages/settings/AccountPage'
import UserProfilePage from '@src/pages/settings/UserProfilePage'

interface IState {
    menuCollapsed: boolean
}

export default class SettingsPages extends React.PureComponent<RouteComponentProps, IState> {

    constructor(props) {
        super(props)

        this.state = {
            menuCollapsed: true
        }
    }

    private toggleMenu = () => {
        this.setState({ menuCollapsed: !this.state.menuCollapsed })
    }

    public render() {
        const menu = (
            <>
                {/* <ListGroupItem tag={Link} to={Routes.SETTINGS_PROFILE} action active={pathMatchesCurrentRoute(Routes.SETTINGS_PROFILE)}><FA icon="user" /> Profile</ListGroupItem> */}
                <NavLink tag={Link} to={Routes.SETTINGS_PROFILE} active={pathMatchesCurrentRoute(Routes.SETTINGS_PROFILE)}><FA icon="user" /> General</NavLink>
                {/* <ListGroupItem tag={Link} to={Routes.SETTINGS_ACCOUNT} action active={pathMatchesCurrentRoute(Routes.SETTINGS_ACCOUNT)}><FA icon="key" /> Account</ListGroupItem> */}
                <NavLink tag={Link} to={Routes.SETTINGS_ACCOUNT} active={pathMatchesCurrentRoute(Routes.SETTINGS_ACCOUNT)}><FA icon="key" /> Account</NavLink>
            </>
        )

        return (
            <>
                <MiniBanner />
                <Container>
                    <Row className="mt-3 mb-5">
                        <Col xs={12} md={3} className="mb-3">
                            <div className="sticky-top" style={{ top: '1rem', zIndex: 999 }}>
                                <Card body>
                                    <Nav vertical pills>
                                        <CardTitle>User Settings</CardTitle>
                                        {menu}
                                    </Nav>
                                </Card>
                            </div>
                        </Col>
                        <Col xs={12} md={9}>
                            <Switch>
                                <CrumbRoute title="Profile" path={Routes.SETTINGS_PROFILE} component={UserProfilePage} />
                                <CrumbRoute title="Account" path={Routes.SETTINGS_ACCOUNT} component={AccountPage} />
                                <Redirect to={Routes.SETTINGS_PROFILE} />
                            </Switch>
                        </Col>
                    </Row>
                </Container>
            </>
        )
    }
}
