import React from 'react'
import { Field, FieldProps, FieldRenderProps } from 'react-final-form'

export const FieldPrefixContext = React.createContext('')

interface IFieldPrefixProps {
    prefix: string
}

export const FieldPrefix: React.FC<IFieldPrefixProps> = ({ prefix, children }) => (
    <FieldPrefixContext.Consumer>
        {outerPrefix => <FieldPrefixContext.Provider value={outerPrefix ? `${outerPrefix}.${prefix}` : prefix}>{children}</FieldPrefixContext.Provider>}
    </FieldPrefixContext.Consumer>
)

export function PrefixedField<FieldValue = any, RP extends FieldRenderProps<FieldValue, T> = FieldRenderProps<FieldValue, HTMLElement>, T extends HTMLElement = HTMLElement>(props: FieldProps<FieldValue, RP, T>) {
    const { name, ...rest } = props
    return (
        <FieldPrefixContext.Consumer>
            {prefix => <Field name={`${prefix}.${name}`} {...rest} />}
        </FieldPrefixContext.Consumer>
    )
}
