import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Container, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import { loadProjectCostsOverview } from '@src/actions/project'
import ActionBar from '@src/components/common/ActionBar'
import CheckboxRadio from '@src/components/common/CheckboxRadio'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import PhaseForm, { IPhaseFormData } from '@src/components/costs/settings/PhaseForm'
import GenericContentTable from '@src/components/table/GenericContentTable'
import { CostsEditPhase, CostsNewPhase } from '@src/logic/forms/Forms'
import { PhaseCreate, PhaseDelete, PhaseUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { formatCurrency } from '@src/logic/utils/Currency'
import { Phase } from '@src/types/costs'
import { RootState } from '@src/types/models'

interface IConnectedState {
    projectId: string
    phases: Phase[]
}

interface IConnectedDispatch {
    submitForm: (form: string) => void
    reloadCostsOverview: () => void
}

interface IState {
    phaseToEdit: Phase
    isCreating: boolean
}

class PhaseSettingSection extends React.PureComponent<IConnectedState & IConnectedDispatch, IState> {

    constructor(props) {
        super(props)

        this.state = {
            phaseToEdit: null,
            isCreating: false
        }
    }

    private editPhase = (phase: Phase) => {
        this.setState({ phaseToEdit: phase, isCreating: false })
    }

    private deletePhase = async (phase: Phase) => {
        try {
            await PhaseDelete(this.props.projectId, phase.code)
        } catch (error) {
            if (isAxiosError(error)) {
                if (error.response.status === 400) {
                    NotificationService.error('Cannot delete phase. It is locked or being used in budget or commitments.')
                }
            }
        }
        this.props.reloadCostsOverview()
    }

    private markCreating = () => {
        this.setState({ isCreating: true, phaseToEdit: null })
    }

    private clearCreateAndEdit = () => {
        this.setState({ isCreating: false, phaseToEdit: null })
    }

    private doSubmit = () => {
        this.props.submitForm(this.state.phaseToEdit ? CostsEditPhase : CostsNewPhase)
    }

    private handleSubmit = async (values: IPhaseFormData) => {
        return this.state.phaseToEdit ? PhaseUpdate(this.props.projectId, this.state.phaseToEdit.code, {
            name: values.name,
            description: values.description,
            approvedValue: values.approvedValue,
            locked: values.locked
        })
        : PhaseCreate(this.props.projectId, {
            ...values,
            approvedValue: values.approvedValue
        })
    }

    private onSubmitSuccess = () => {
        this.props.reloadCostsOverview()
        this.clearCreateAndEdit()
    }

    public render() {
        const { phases } = this.props
        const { phaseToEdit, isCreating } = this.state

        const phasesExistBody = (
                <GenericContentTable
                    data={phases}
                    headers={[
                        {
                            name: 'Phase',
                            accessor: 'code'
                        },
                        {
                            name: 'Name',
                            accessor: 'name'
                        },
                        {
                            name: 'Description',
                            accessor: 'description'
                        },
                        {
                            name: 'Approved Value',
                            overrideRenderer: phase => formatCurrency(phase.approvedValue)
                        },
                        {
                            name: 'Locked',
                            overrideRenderer: phase => <CheckboxRadio disabled checked={phase.locked} />
                        },
                        {
                            name: 'Actions',
                            headerWrapperClass: 'text-right',
                            noSmallHeader: true,
                            overrideRenderer: (phase, rowIdx) => (
                                <div className="justify-content-end text-right selectable-content__actions">
                                    <TooltipLinkAction id={`edit-phase-${rowIdx}`} tooltip="Edit" data={phase} onClick={this.editPhase}><FA icon="pencil" /></TooltipLinkAction>
                                    <TooltipLinkAction id={`delete-phase-${rowIdx}`} tooltip="Remove" data={phase} onClick={this.deletePhase}><FA icon="trash" /></TooltipLinkAction>
                                </div>
                            )
                        }
                    ]}
                />
        )

        const noPhasesBody = (
            <Card body className="text-center mt-3">
                <div className="my-3"><FA size="3x" icon="stream" /></div>
                <p className="lead"><Button onClick={this.markCreating}>Create Phase</Button></p>
                <p>There are no phases for this project.</p>
            </Card>
        )

        return (
            <>
                {phases.length > 0 &&
                <ActionBar className="mb-3">
                    <Button onClick={this.markCreating}><FA icon="plus-circle" /> Create Phase</Button>
                </ActionBar>
                }
                <Container fluid>
                    {phases.length > 0 ? phasesExistBody : noPhasesBody}
                    <Modal isOpen={!!phaseToEdit || isCreating} toggle={this.clearCreateAndEdit}>
                        <ModalHeader toggle={this.clearCreateAndEdit}>{phaseToEdit ? 'Edit Phase' : 'New Phase'}</ModalHeader>
                        <ModalBody>
                            <PhaseForm
                                existingPhases={phases}
                                form={phaseToEdit ? CostsEditPhase : CostsNewPhase}
                                enableReinitialize={true}
                                keepDirtyOnReinitialize={false}
                                initialValues={phaseToEdit ? {
                                    ...phaseToEdit,
                                    approvedValue: phaseToEdit.approvedValue == null ? 0 : phaseToEdit.approvedValue
                                } : undefined}
                                existing={phaseToEdit != null}
                                onSubmit={this.handleSubmit}
                                onSubmitSuccess={this.onSubmitSuccess}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button onClick={this.doSubmit}>{phaseToEdit ? 'Save' : 'Create'}</Button>
                        </ModalFooter>
                    </Modal>
                </Container>
            </>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: {}): IConnectedDispatch {
    return {
        ...ownProps,
        submitForm: (form: string) => dispatch(submit(form)),
        reloadCostsOverview: () => dispatch<any>(loadProjectCostsOverview())
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        projectId: state.projects.active.id,
        phases: state.projects.activeCostsOverview.phases
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(PhaseSettingSection)
