import React from 'react'

export interface IBooleanHook {
    value: boolean
    setTrue: () => void
    setFalse: () => void
    toggle: () => void
}

export default function useBoolean(initialValue?: boolean): IBooleanHook {
    const [value, setValue] = React.useState<boolean>(initialValue)

    function setTrue() {
        setValue(true)
    }

    function setFalse() {
        setValue(false)
    }

    function toggle() {
        setValue(!value)
    }

    return { value, setTrue, setFalse, toggle }
}
