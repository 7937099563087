import React from 'react'
import { WrappedFieldProps } from 'redux-form'

import CheckboxRadio, { IProps as CheckboxRadioProps } from '@src/components/common/CheckboxRadio'

interface IProps extends Omit<CheckboxRadioProps, keyof { onClick, checked }> {
}

export default class ValidatedCheckboxRadio extends React.PureComponent<IProps & WrappedFieldProps> {
    private readonly handleToggle = () => {
        this.props.input.onChange(!this.props.input.value)
    }

    public render() {
        const { input, meta, ...checkboxProps } = this.props
        return (
            <CheckboxRadio {...checkboxProps} onClick={this.handleToggle} checked={input.value} />
        )
    }
}
