import React from 'react'

import FA from '@src/components/common/FontAwesomeIcon'

import * as InfopointLogo from 'public/img/infopoint_colour.svg'

export default class LoadingApp extends React.PureComponent {

    public render() {
        return (
            <section className="h-100 d-flex flex-column align-items-center justify-content-center">
                <div className="mb-3">
                    <img width="200" src={InfopointLogo} />
                </div>
                <div className="mb-2">
                    <span className="lead">Preparing your workspace...</span>
                </div>
                <div>
                    <FA icon="spinner-third" spin size="2x" />
                </div>
            </section>
        )
    }
}
