import React from 'react'
import { Field, Form } from 'react-final-form'
import { useSelector } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'

import { calculateDiff } from '@src/components/access/AccessControlTable'
import AccessControlTableInput from '@src/components/access/AccessControlTableInput'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { RevisionOperationsArray } from '@src/logic/auth/operations'
import { CompanyUsersList, GroupsList, RevisionAccessBulkUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { AclEntry } from '@src/types/access'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

interface IBulkPermissionUpdateFormData {
    entries: AclEntry[]
}

const BulkPermissionUpdateModal: React.FC<IModalPropsManager> = ({ toggle, open, onClosed }) => {

    const revisions = useSelector<RootState, Revision[]>(s => getActiveProjectWidgetState(s).revisions)
    const company = useSelector<RootState, Company>(s => s.session.company)

    async function loadPrincipals() {
        const principalEntries: AclEntry[] = []
        const usersPromise = CompanyUsersList(undefined, undefined, 1, 200)
        const groupsPromise = GroupsList(undefined, 1, 200)

        const results = await Promise.all([usersPromise, groupsPromise])

        principalEntries.push(...results[0].data.filter(x => x.status === 'Enabled').map<AclEntry>(u => ({
            id: u.id,
            name: `${u.firstName} ${u.lastName}`.trim(),
            email: u.email,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'user'
        })))

        principalEntries.push(...results[1].data.map<AclEntry>(g => ({
            id: g.id,
            name: g.name,
            email: undefined,
            company: company.id,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'group'
        })))

        principalEntries.push({
            id: company.id,
            company: company.id,
            name: company.name,
            email: undefined,
            isAdministrator: undefined,
            denials: [],
            grants: [],
            authorised: [],
            type: 'company'
        })

        return principalEntries
    }

    async function bulkUpdateRevisionPermissions(values: IBulkPermissionUpdateFormData) {
        const aclUpdate = calculateDiff([], values.entries)

        try {
            await RevisionAccessBulkUpdate(revisions.map(r => r.id), aclUpdate)
            toggle()
        } catch (error) {
            NotificationService.error('There was an issue applying bulk permissions. Make sure you are admin on selected revisions.')
        }
    }

    return (
        <Modal size="lg" isOpen={open} onClosed={onClosed}>
            <Form
                onSubmit={bulkUpdateRevisionPermissions}
            >
                {({ handleSubmit, submitting }) =>
                    <>
                        <ModalHeader toggle={toggle}>Bulk Access Update</ModalHeader>
                        <ModalBody>
                            <p>Bulk update the access for {revisions.length} {revisions.length > 1 ? 'revisions' : 'revision'}. This will override the permission for each company, group, and user selected on each revision.</p>
                            <Field
                                name="entries"
                                component={AccessControlTableInput}
                                loadPrincipals={loadPrincipals}
                                validOperations={RevisionOperationsArray}
                            />
                        </ModalBody>
                        <ModalFooter>
                            <Button color="default" onClick={toggle}>Cancel</Button>
                            <Button color="primary" onClick={handleSubmit} disabled={submitting}>Apply</Button>
                        </ModalFooter>
                    </>
                }
            </Form>
        </Modal>
    )
}

export default React.memo(BulkPermissionUpdateModal)
