// tslint:disable:max-classes-per-file

import React from 'react'
import { connect } from 'react-redux'
import { Col, Form, FormGroup, InputGroup, InputGroupAddon, InputGroupText, Label, Table, UncontrolledTooltip } from 'reactstrap'
import { ConfigProps, Field, FieldArray, InjectedFormProps, WrappedFieldArrayProps, formValueSelector, reduxForm } from 'redux-form'

import { v4 } from 'uuid'

import FA from '@src/components/common/FontAwesomeIcon'
import { IGroupedOptions } from '@src/components/common/Select'
import ValidatedAsyncSelect from '@src/components/common/ValidatedAsyncSelect'
import ValidatedCurrencyInput from '@src/components/common/ValidatedCurrencyInput'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { commitmentStatusLabel, commitmentStatusValue, costCodeLabel, costCodeValue, isPhaseLocked, phaseLabel, phaseValue } from '@src/logic/forms/SelectHelpers'
import { required } from '@src/logic/forms/validation'
import { CostCodesList } from '@src/logic/http/Api'
import { groupBy } from '@src/logic/utils/Collection'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { CommitmentDefinition, CommitmentItem, CommitmentReportColumn, CommitmentReportColumnName, CommitmentStatusDefinition, CostCodeBrief, CostValue, CostsOverview, Phase } from '@src/types/costs'
import { DocumentLink } from '@src/types/document'
import { RootState } from '@src/types/models'

interface IProps {
    projectId: string
    costsOverview: CostsOverview
    commitmentDefinition: CommitmentDefinition
    commitmentItemToEdit: CommitmentItem
}

interface IConnectedState {
    availableDocumentLinks: DocumentLink[]
    selectedStatus: CommitmentStatusDefinition
}

export interface ICommitmentItemFormData {
    name: string
    description: string
    otherPartyRef: string
    costCode: CostCodeBrief
    status: CommitmentStatusDefinition
    phase: Phase
    statusColumnValues: {
        status: string
        columns: {
            [Column in CommitmentReportColumn]?: CostValue
        }
    }[]
}

class CommitmentItemForm extends React.PureComponent<IProps & IConnectedState & InjectedFormProps<ICommitmentItemFormData, IProps & IConnectedState>> {
    private selector: (state: RootState, ...fields: string[]) => any

    constructor(props: IProps & IConnectedState & InjectedFormProps<ICommitmentItemFormData, IProps & IConnectedState>) {
        super(props)
        this.selector = formValueSelector<RootState>(props.form)
    }

    public componentDidUpdate(prevProps: IProps & IConnectedState & InjectedFormProps<ICommitmentItemFormData, IProps & IConnectedState>) {
        if (prevProps.form !== this.props.form) {
            this.selector = formValueSelector<RootState>(this.props.form)
        }
    }

    private readonly loadCostCodes = async (query: string): Promise<IGroupedOptions<CostCodeBrief>> => {
        const response = await CostCodesList(this.props.projectId, 1, 200)
        const grouped = groupBy(response.data, 'group')
        return Object.keys(grouped).reduce((prev, curr) => [...prev, { label: curr, options: grouped[curr] }], [])
    }

    private readonly updateValue = (field: string, column: string, value: number) => {
        this.props.change(`${field}.columns.${column}.value`, value)
    }

    private readonly renderStatusColumnValue = (member: string, index: number, statuses: CommitmentStatusDefinition[]) => statuses[index].columns.map((col, cidx) => (<ConnectedStatusColumnValueRow key={`${index}-${cidx}`} selector={this.selector} updateValue={this.updateValue} status={statuses[index]} columnIndex={cidx} field={member} active={this.props.selectedStatus && statuses[index].code === this.props.selectedStatus.code} />))

    private readonly renderStatusMembers = ({ fields }: WrappedFieldArrayProps<string>) => {
        return (
            <tbody>
                {fields.map((f, idx) => this.renderStatusColumnValue(f, idx, this.props.commitmentDefinition.statusDefinitions))}
            </tbody>
        )
    }

    private readonly isStatusDisabled = (option: CommitmentStatusDefinition) => {
        return !this.props.costsOverview.budget.locked ? !option.allowInUnlockedBudget : false
    }

    private readonly formatStatusLabel = (option: CommitmentStatusDefinition) => {
        const id = v4()
        if (this.isStatusDisabled(option)) {
            return (
                <>
                    <UncontrolledTooltip target={`commitment-item-select-status-${id}`}>Requires locked budget</UncontrolledTooltip>
                    <div>
                        <FA id={`commitment-item-select-status-${id}`} className="text-warning" icon="exclamation-triangle"/>&nbsp;
                        {commitmentStatusLabel(option)}
                    </div>
                </>
            )
        }

        return commitmentStatusLabel(option)
    }

    public render() {
        return (
            <Form>
                <FormGroup row>
                    <Col md={6}>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Field id="name" name="name" component={ValidatedInput} placeholder="Name" validate={required} />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Current Status</Label>
                            <Field
                                name="status"
                                component={ValidatedSelect}
                                options={this.props.commitmentDefinition.statusDefinitions}
                                validate={required}
                                getOptionLabel={commitmentStatusLabel}
                                formatOptionLabel={this.formatStatusLabel}
                                getOptionValue={commitmentStatusValue}
                                isOptionDisabled={this.isStatusDisabled}
                                isClearable
                            />
                        </FormGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Cost Code</Label>
                            <Field name="costCode" component={ValidatedAsyncSelect} validate={required} defaultOptions={true} loadOptions={this.loadCostCodes} getOptionValue={costCodeValue} getOptionLabel={costCodeLabel} cacheOptions />
                        </FormGroup>
                    </Col>
                    <Col md={6}>
                        <FormGroup>
                            <Label>Phase</Label>
                            <Field name="phase" component={ValidatedSelect} options={this.props.costsOverview.phases} getOptionLabel={phaseLabel} getOptionValue={phaseValue} isClearable isOptionDisabled={isPhaseLocked} />
                        </FormGroup>
                    </Col>
                </FormGroup>
                <FormGroup row>
                    <Col md={6}>
                        <Label for="otherPartyRef">Supplier Reference</Label>
                        <Field id="otherPartyRef" name="otherPartyRef" component={ValidatedInput} />
                    </Col>
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} type="textarea" />
                </FormGroup>
                <Table responsive>
                    <thead>
                        <tr>
                            <th style={{ minWidth: 120 }}>Status</th>
                            <th style={{ minWidth: 120 }}>Column</th>
                            <th style={{ minWidth: 120 }}>Unit</th>
                            <th style={{ minWidth: 120 }}>Qty</th>
                            <th style={{ minWidth: 120 }}>Rate</th>
                            <th style={{ minWidth: 120 }}>Value</th>
                            <th style={{ minWidth: 120 }}>Notes</th>
                        </tr>
                    </thead>
                    <FieldArray<any> name="statusColumnValues" component={this.renderStatusMembers} />
                </Table>
            </Form>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & Omit<Partial<ConfigProps<ICommitmentItemFormData, IProps>>, 'initialValues'>): Partial<ConfigProps<ICommitmentItemFormData, IProps>> & IProps & IConnectedState {
    const { commitmentItemToEdit, commitmentDefinition, costsOverview } = ownProps

    let initialValues: Partial<ICommitmentItemFormData>
    if (commitmentItemToEdit == null) {
        initialValues = {
            statusColumnValues: commitmentDefinition.statusDefinitions.map(sd => ({
                status: sd.code,
                columns: sd.columns.reduce(
                    (prev, curr) => (
                        {
                            ...prev,
                            [curr]: {
                                value: 0,
                                unit: '',
                                quantity: 0,
                                rate: 0,
                                notes: ''
                            }
                        }),
                    {}
                )
            }))
        }
    } else {
        const groupedStatusColumnValues = groupBy(commitmentItemToEdit.values, 'status')
        initialValues = {
            costCode: commitmentItemToEdit.costCode,
            otherPartyRef: commitmentItemToEdit.otherPartyReference,
            description: commitmentItemToEdit.description,
            name: commitmentItemToEdit.name,
            phase: costsOverview.phases.find(p => p.code === commitmentItemToEdit.phase),
            status: commitmentDefinition.statusDefinitions.find(s => s.code === commitmentItemToEdit.status),
            statusColumnValues: Object.keys(groupedStatusColumnValues).map(status => ({
                status,
                columns: groupedStatusColumnValues[status].reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.column]: {
                            ...curr.value
                        }
                    }),
                    {}
                )
            }))
        }
    }

    return {
        ...ownProps,
        initialValues,
        availableDocumentLinks: getActiveProjectWidgetState(state).revisions.map<DocumentLink>(r => ({
            documentId: r.documentId,
            name: r.name,
            revisionId: r.id
        })),
        selectedStatus: formValueSelector(ownProps.form)(state, 'status')
    }
}

export default connect(mapStateToProps)(reduxForm<ICommitmentItemFormData, IProps>({})(CommitmentItemForm))

interface IStatusColumnValueRowProps {
    status: CommitmentStatusDefinition
    columnIndex: number
    field: string
    updateValue: (field: string, column: string, value: number) => void
    active: boolean
}

interface IConnectedStatusColumnValueProps {
    quantity: number
    rate: number
}

class StatusColumnValueRow extends React.PureComponent<IStatusColumnValueRowProps & IConnectedStatusColumnValueProps> {
    private readonly handleQuantityChange = (e, value: number) => {
        this.props.updateValue(this.props.field, this.props.status.columns[this.props.columnIndex], value * this.props.rate)
    }

    private readonly handleRateChange = (e, value: number) => {
        this.props.updateValue(this.props.field, this.props.status.columns[this.props.columnIndex], value * this.props.quantity)
    }

    public render() {
        const { active, status, columnIndex, field } = this.props
        const fieldPrefix = `${field}.columns.${status.columns[columnIndex]}`
        return (
            <tr style={{ background: active ? '#ecf4f5' : 'initial' }}>
                <td>{columnIndex === 0 ? status.code : ''}</td>
                <td>{CommitmentReportColumnName[status.columns[columnIndex]]}</td>
                <td>
                    <Field name={`${fieldPrefix}.unit`} component={ValidatedInput} bsSize="sm" />
                </td>
                <td>
                    <Field name={`${fieldPrefix}.quantity`} component={ValidatedInput as any} bsSize="sm" type="number" onBlur={this.handleQuantityChange} />
                </td>
                <td>
                    <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend" size="sm"><InputGroupText className="bg-white">$</InputGroupText></InputGroupAddon>
                        <Field name={`${fieldPrefix}.rate`} component={ValidatedCurrencyInput} bsSize="sm" className="text-right" onBlur={this.handleRateChange} />
                    </InputGroup>
                </td>
                <td>
                    <InputGroup size="sm">
                        <InputGroupAddon addonType="prepend" size="sm"><InputGroupText className="bg-white">$</InputGroupText></InputGroupAddon>
                        <Field name={`${fieldPrefix}.value`} component={ValidatedCurrencyInput} bsSize="sm" className="text-right" />
                    </InputGroup>
                </td>
                <td>
                    <Field name={`${fieldPrefix}.notes`} component={ValidatedInput} bsSize="sm" />
                </td>
            </tr>
        )
    }
}

function mapFormStateToProps(state: RootState, ownProps: IStatusColumnValueRowProps & { selector: (state: RootState, ...field: string[]) => any }): IStatusColumnValueRowProps & IConnectedStatusColumnValueProps {
    const { selector, ...rest } = ownProps

    return {
        ...rest,
        quantity: selector(state, `${rest.field}.columns.${rest.status.columns[rest.columnIndex]}.quantity`),
        rate: selector(state, `${rest.field}.columns.${rest.status.columns[rest.columnIndex]}.rate`)
    }
}

const ConnectedStatusColumnValueRow = connect(mapFormStateToProps)(StatusColumnValueRow)
