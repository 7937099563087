import React from 'react'
import { ToastContentProps } from 'react-toastify'
import { ToastBody, ToastHeader } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import Image from '@src/components/common/Image'
import type { Notification } from '@src/types/notification'

interface IProps {
    notification: Notification
    options: ToastContentProps
}

export const NotificationToast: React.FC<IProps> = ({ notification, options }) => {
    return (
        <>
            <ToastHeader
                icon={
                    notification.author?.profilePictureLink ? <Image rounded alt="" src={notification.author.profilePictureLink} width="20" height="20" /> : 'primary'
                }
                close={<div role="button" tabIndex={0} onClick={options.closeToast} onKeyDown={options.closeToast}><FA icon="times" /></div>}
            >
                {notification.title}
            </ToastHeader>
            <ToastBody>
                {notification.description}
            </ToastBody>
        </>
    )
}
