import React from 'react'
import { CardBody, CardText } from 'reactstrap'

import FA from '@src/components/common/FontAwesomeIcon'
import { auth } from '@src/logic/auth/AuthService'
import { RequestBuilder } from '@src/logic/http/RequestBuilder'

interface IProps {
    revisionId: string
}

const DocumentPreview: React.FC<IProps> = ({ revisionId }) => {
    const [canPreview, setCanPreview] = React.useState<boolean | null>(null)

    React.useEffect(
        () => {
            if (process.env.DOCUMENT_VIEWER_SERVER) checkCanPreview()
        },
        [revisionId]
    )

    async function checkCanPreview() {
        const response = await new RequestBuilder('GET', `${process.env.DOCUMENT_VIEWER_SERVER}/view/${revisionId}/can_preview`)
            .authenticated()
            .build<{ result: boolean }>()
        setCanPreview(response.data.result)
    }

    if (canPreview == null) {
        return <div></div>
    } else if (canPreview) {
        return (
            <div style={{ width: '100%', height: 1000, position: 'relative' }}>
                <iframe
                    allowFullScreen
                    title="document preview"
                    className="border-0"
                    width="100%"
                    height="100%"
                    style={{ left: 0, top: 0, position: 'absolute' }}
                    src={`${process.env.DOCUMENT_VIEWER_SERVER}/view/${revisionId}?access_token=${encodeURI(auth.getSessionToken())}`}
                />
            </div>
        )
    } else {
        return (
            <CardBody>
                <div>
                    <FA icon="eye-slash" />
                </div>
                <CardText className="lead">Unable to preview this type of document.</CardText>
            </CardBody>
        )
    }
}

export default DocumentPreview
