import React from 'react'
import { ToastOptions, toast } from 'react-toastify'

import { HttpTransportType, HubConnectionBuilder, TypedHubConnection } from '@microsoft/signalr'
import { Howl } from 'howler'

import notificationMp3 from 'public/audio/notification.mp3'
import notificationWebm from 'public/audio/notification.webm'

import FA from '@src/components/common/FontAwesomeIcon'
import { NotificationToast } from '@src/components/notifications/NotificationToast'
import { auth } from '@src/logic/auth/AuthService'
import { ListNotificationsResponse, Notification } from '@src/types/notification'

interface INotificationOptions {
    autoClose?: number | false
    type?: 'info' | 'success' | 'warning' | 'error'
}

interface INotificationClient {
    receiveNotification: (notification: Notification) => void
}

interface INotificationServer {
    countUnreadNotifications: () => number
    listNotifications: (before?: Date) => ListNotificationsResponse
    markAllAsRead: () => void
    markNotificationAsRead: (notificationId: string) => void
}

class NotificationService {
    private readonly connection: TypedHubConnection<INotificationServer, INotificationClient>
    private readonly notificationPop = new Howl({ src: [notificationWebm, notificationMp3], format: ['webm', 'mp3'] })
    private initialization: Promise<void>

    public readonly DEFAULT_AUTO_CLOSE = 5000

    private readonly options: ToastOptions = {
    }

    constructor() {
        this.connection = new HubConnectionBuilder()
            .withUrl(`${process.env.INFOPOINT_SERVER}notifications`, { skipNegotiation: true, transport: HttpTransportType.WebSockets, accessTokenFactory: auth.getSessionToken })
            .withAutomaticReconnect()
            .build<INotificationServer, INotificationClient>()

        this.connection.on('receiveNotification', this.handleReceiveNotification)
    }

    public readonly initializeNotificationConnection = async () => {
        this.initialization = this.connection.start()
        return await this.initialization
    }

    public readonly closeNotificationConnection = async (): Promise<void> => {
        if (this.initialization == null) {
            return
        }

        await this.connection.stop()
    }

    public readonly listNotifications = async (before?: Date): Promise<ListNotificationsResponse> => {
        if (this.initialization == null) {
            throw new Error('Notification service is not initialized')
        }

        await this.initialization
        return await this.connection.invoke('listNotifications', before)
    }

    public readonly countUnreadNotifications = async (): Promise<number> => {
        if (this.initialization == null) {
            throw new Error('Notification service is not initialized')
        }

        await this.initialization
        return await this.connection.invoke('countUnreadNotifications')
    }

    public readonly markAllNotificationsAsRead = async (): Promise<void> => {
        if (this.initialization == null) {
            throw new Error('Notification service is not initialized')
        }

        await this.initialization
        return await this.connection.send('markAllAsRead')
    }

    public readonly markNotificationAsRead = async (notificationId: string): Promise<void> => {
        if (this.initialization == null) {
            throw new Error('Notification service is not initialized')
        }

        await this.initialization
        return await this.connection.send('markNotificationAsRead', notificationId)
    }

    public readonly onReceiveNotification = (handler: INotificationClient['receiveNotification']): () => void => {
        this.connection.on('receiveNotification', handler)
        return () => this.connection.off('receiveNotification', handler)
    }

    public error(message: React.ReactNode, options?: INotificationOptions): React.ReactText {
        return toast.error(message, { ...this.options, ...options, containerId: 'toasts' })
    }

    public success(message: React.ReactNode, options?: INotificationOptions): React.ReactText {
        return toast.success(message, { ...this.options, ...options, containerId: 'toasts' })
    }

    public info(message: React.ReactNode, options?: INotificationOptions): React.ReactText {
        return toast.info(message, { ...this.options, ...options, containerId: 'toasts' })
    }

    public pendingActivityToast(message: React.ReactNode): React.ReactText {
        return toast.info(<div><FA className="mr-2" icon="spinner-third" spin />{message}</div>, { ...this.options, autoClose: false, containerId: 'toasts' })
    }

    public updateToast(id: React.ReactText, message: React.ReactNode, newOptions?: INotificationOptions) {
        return toast.update(id, { ...this.options, ...newOptions, render: message, containerId: 'toasts' })
    }

    public removeToast(id: React.ReactText) {
        toast.dismiss(id)
    }

    private readonly handleReceiveNotification = (notification: Notification) => {
        if (!this.notificationPop.playing()) {
            this.notificationPop.play()
        }
        toast.info((options) =>
            <NotificationToast notification={notification} options={options} />,
            { containerId: 'notifications', autoClose: 5000 })
    }
}

export default new NotificationService()
