import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import { compare, Operation as PatchOperation } from 'fast-json-patch'

import { loadProjectCostsOverview } from '@src/actions/project'
import CommitmentStatusForm, { ICommitmentStatusFormData, IStatusMapping } from '@src/components/costs/settings/CommitmentStatusForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { CommitmentDefinitionPatch } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { CommitmentDefinition, CommitmentReportColumnName, CommitmentStatusDefinition } from '@src/types/costs'

interface IProps extends IModalPropsManager {
    projectId: string
    commitmentDefinition: CommitmentDefinition
    statusToEdit?: CommitmentStatusDefinition
    existingCommitmentDefinitions: CommitmentDefinition[]
}

interface IConnectedDispatch {
    submitForm: () => void
    reloadCostsOverview: () => void
}

class CommitmentStatusModal extends React.PureComponent<IProps & IConnectedDispatch> {

    private handleSubmit = async (values: ICommitmentStatusFormData, dispatch: Dispatch<any>) => {
        const { statusToEdit, commitmentDefinition } = this.props
        if (statusToEdit) {
            const index = commitmentDefinition.statusDefinitions.findIndex(s => s.code === statusToEdit.code)
            if (index < 0) throw Error('Status to edit not found in commitment')
            const sd = statusToEdit
            const original: CommitmentStatusDefinition = {
                code: sd.code,
                columns: sd.columns,
                allowClaim: sd.allowClaim,
                allowInUnlockedBudget: sd.allowInUnlockedBudget,
                requireOtherParty: sd.requireOtherParty,
                mapping: sd.mapping
            }
            const update: CommitmentStatusDefinition = {
                code: statusToEdit.code,
                columns: values.columns.map(c => c.value),
                allowClaim: values.allowClaim,
                allowInUnlockedBudget: values.unlockedBudget,
                requireOtherParty: values.requireOtherParty,
                mapping: (values.mapping || []).reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.commitmentType.code]: curr.status.code
                    }),
                    {}
                )
            }

            const patch = compare(original, update).map(op => ({ ...op, path: '/statusDefinitions/' + index + op.path }))
            await CommitmentDefinitionPatch(this.props.projectId, this.props.commitmentDefinition.code, patch)
        } else {
            const newStatusDefinition: CommitmentStatusDefinition = {
                code: values.code,
                columns: values.columns.map(c => c.value),
                allowClaim: values.allowClaim,
                allowInUnlockedBudget: values.unlockedBudget,
                requireOtherParty: values.requireOtherParty,
                mapping: (values.mapping || []).reduce(
                    (prev, curr) => ({
                        ...prev,
                        [curr.commitmentType.code]: curr.status.code
                    }),
                    {}
                )
            }
            await CommitmentDefinitionPatch(this.props.projectId, this.props.commitmentDefinition.code, [
                { op: 'add', path: '/statusDefinitions/-', value: newStatusDefinition }
            ])
        }
    }

    private onSubmitSuccess = (result, dispatch, props) => {
        if (this.props.statusToEdit) {
            NotificationService.info('Updated status')
        } else {
            NotificationService.info('Created status')
        }
        this.props.reloadCostsOverview()
        this.props.toggle()
    }

    public render() {
        const { existingCommitmentDefinitions, statusToEdit } = this.props
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>{statusToEdit ? `Edit - ${statusToEdit.code}` : 'New Status'}</ModalHeader>
                <ModalBody>
                    <CommitmentStatusForm
                        form={statusToEdit ? Forms.CostsEditCommitmentStatus : Forms.CostsNewCommitmentStatus}
                        destroyOnUnmount
                        editingExisting={this.props.statusToEdit != null}
                        existingCommitmentDefinitions={existingCommitmentDefinitions}
                        initialValues={this.props.statusToEdit == null ? {
                            columns: []
                        } : {
                            code: statusToEdit.code,
                            columns: statusToEdit.columns.map(c => ({ label: CommitmentReportColumnName[c], value: c })),
                            allowClaim: statusToEdit.allowClaim,
                            unlockedBudget: statusToEdit.allowInUnlockedBudget,
                            requireOtherParty: statusToEdit.requireOtherParty,
                            mapping: Object.keys(statusToEdit.mapping).map<IStatusMapping>((code) => {
                                const definition = existingCommitmentDefinitions.find(cd => cd.code === code)
                                if (definition == null) return null
                                const status = definition.statusDefinitions.find(sd => sd.code === statusToEdit.mapping[code])
                                return {
                                    status,
                                    commitmentType: definition
                                }
                            }).filter(x => x != null)
                        }}
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.onSubmitSuccess}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.submitForm}>{statusToEdit ? 'Save' : 'Create'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        submitForm: () => dispatch(submit(ownProps.statusToEdit ? Forms.CostsEditCommitmentStatus : Forms.CostsNewCommitmentStatus)),
        reloadCostsOverview: () => dispatch(loadProjectCostsOverview())
    }
}

export default connect(null, mapDispatchToProps)(CommitmentStatusModal)
