import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledButtonDropdown, UncontrolledTooltip } from 'reactstrap'

import { AxiosResponse } from 'axios'

import { setDocumentToRevise, toggleSandbox } from '@src/actions/sandbox'
import * as WidgetActions from '@src/actions/widget'
import CheckboxRadio from '@src/components/common/CheckboxRadio'
import FA from '@src/components/common/FontAwesomeIcon'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import Toolbar from '@src/components/toolbar/Toolbar'
import ToolbarLink from '@src/components/toolbar/ToolbarLink'
import useBoolean from '@src/hooks/useBoolean'
import { isAuthorised } from '@src/logic/auth/access'
import { auth } from '@src/logic/auth/AuthService'
import { Revise, RevisionOperations } from '@src/logic/auth/operations'
import { DocumentDelete, RevisionDelete, RevisionPatch, RevisionsDownloadLink } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import NotificationService from '@src/logic/notification/NotificationService'
import { pathMatchesCurrentRoute } from '@src/logic/routing/RouteHelpers'
import * as Routes from '@src/logic/routing/routes'
import * as TourTags from '@src/logic/support/TourTags'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { IPDocument, Revision } from '@src/types/document'
import { RootState } from '@src/types/models'
import { Project } from '@src/types/project'

interface IProps {
    revision: Revision
    document: IPDocument
    reloadDocument: () => void
}

const DocumentToolbar: React.FC<RouteComponentProps & IProps> = ({ document, history, reloadDocument, revision }) => {
    const deleting = useBoolean(false)
    const confirmDownloadCheckout = useBoolean(false)
    const deleteAllRevisions = useBoolean(false)

    const revisionInWidget = useSelector<RootState, boolean>(s => getActiveProjectWidgetState(s).revisions.findIndex(x => x.id === revision.id) > -1)
    const project = useSelector<RootState, Project>(s => s.projects.active)

    const dispatch = useDispatch()

    function reviseDocument() {
        dispatch(setDocumentToRevise({ project, revisions: [revision] }))
        dispatch(toggleSandbox(true))
    }

    function toggleRevisionInWidget() {
        if (revisionInWidget) {
            dispatch(WidgetActions.removeRevisions({ projectId: revision.projectId, entityIds: [revision.id] }))
        } else {
            dispatch(WidgetActions.addRevisions({ projectId: revision.projectId, entities: [revision] }))
        }
    }

    function downloadRevision() {
        if (document.checkedOutBy != null && !confirmDownloadCheckout.value) {
            confirmDownloadCheckout.setTrue()
        } else {
            confirmDownloadCheckout.setFalse()
            downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
        }
    }

    async function toggleArchiveRevision() {
        try {
            await RevisionPatch(revision.id, [{ op: 'replace', path: 'archived', value: !revision.archived }])
            NotificationService.info(`Revision ${revision.archived ? 'restored' : 'archived'}`)
        } catch {
            NotificationService.error('Unable to archive document')
        }

        reloadDocument()
    }

    async function deleteDocument() {
        const pluralOrSingular = deleteAllRevisions.value ? 'revisions' : 'revision'
        const deletingNotification = NotificationService.info(<span>Deleting {pluralOrSingular}... <FA icon="spinner-third" spin /></span>, { autoClose: false })
        try {
            if (deleteAllRevisions.value) {
                await DocumentDelete(revision.documentId)
            } else {
                await RevisionDelete(revision.id)
            }

            NotificationService.updateToast(deletingNotification, 'Deleted ' + pluralOrSingular, { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
            WidgetActions.removeRevisions({ projectId: revision.projectId, entityIds: [revision.id] })
            history.push(Routes.projectDocuments(revision.projectId))
        } catch (e) {
            const response = e.response as AxiosResponse
            if (response) {
                if (response.status === 403) {
                    NotificationService.updateToast(deletingNotification, 'You do not have permission to delete' + (deleteAllRevisions.value ? 'all revisions' : 'this revision'), { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
                }
            }
            NotificationService.updateToast(deletingNotification, 'An error occurred while deleting the ' + pluralOrSingular, { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
        }
    }

    const authorisedDelete = isAuthorised(revision.myAccess, RevisionOperations.Delete)
    return (
        <section className="banner">
            <Toolbar>
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_DOCUMENT)} to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)} name="Preview" nameBrief="View" iconBrief="eye" data-tour={TourTags.DocumentToolbarPreview} />
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_DOCUMENT_EDIT)} to={Routes.projectDocumentEdit(revision.projectId, revision.documentId, revision.id)} name="Edit Metadata" nameBrief="Edit" iconBrief="pencil" data-tour={TourTags.DocumentToolbarEdit} />
                <ToolbarLink active={pathMatchesCurrentRoute(Routes.PROJECT_DOCUMENT_ACCESS)} to={Routes.projectDocumentAccess(revision.projectId, revision.documentId, revision.id)} name="Access" nameBrief="Access" iconBrief="lock" data-tour={TourTags.DocumentToolbarAccess} />
                <div className="ml-auto d-none d-md-flex align-items-center">
                    <ButtonGroup>
                        <Button id="document-toolbar-download" color="primary" onClick={downloadRevision} data-tour={TourTags.DocumentToolbarDownload}>
                            <FA icon="download" />
                            <UncontrolledTooltip target="document-toolbar-download">Download</UncontrolledTooltip>
                        </Button>
                        <Button id="document-toolbar-revise" color="primary" onClick={reviseDocument} disabled={!isAuthorised(document.myAccess, Revise) || document.checkedOutBy != null} data-tour={TourTags.DocumentToolbarRevise}>
                            <FA icon="edit" />
                            <UncontrolledTooltip target="document-toolbar-revise">Revise</UncontrolledTooltip>
                        </Button>
                        <UncontrolledButtonDropdown>
                            <DropdownToggle
                                caret
                                color="primary"
                            >
                                <FA icon="ellipsis-h" />
                            </DropdownToggle>
                            <DropdownMenu right>
                                <DropdownItem header>Actions</DropdownItem>
                                <DropdownItem onClick={toggleRevisionInWidget} data-tour={TourTags.DocumentToolbarWidget}>
                                    <FA className="mr-2" icon={revisionInWidget ? 'minus-circle' : 'plus-circle'} />
                                    {revisionInWidget ? 'Remove from widget' : 'Add to widget'}
                                </DropdownItem>
                                <DropdownItem onClick={toggleArchiveRevision}>
                                    <FA className="mr-2" icon={revision.archived ? 'undo' : 'archive'} />
                                    {revision.archived ? 'Restore' : 'Archive'}
                                </DropdownItem>
                                <DropdownItem
                                    id="toolbar-delete-revision-button"
                                    disabled={!authorisedDelete}
                                    onClick={deleting.setTrue}
                                    data-tour={TourTags.DocumentToolbarDelete}
                                >
                                    <FA className="mr-2" icon="trash" />
                                    Delete
                                        {!authorisedDelete && <UncontrolledTooltip target="toolbar-delete-revision-button">No delete permission</UncontrolledTooltip>}
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledButtonDropdown>
                    </ButtonGroup>
                </div>
            </Toolbar>
            <ConfirmationModal
                open={confirmDownloadCheckout.value}
                header="Confirm Download"
                message={`This document has been checked out by ${document.checkedOutBy && document.checkedOutBy.name}, and they may be making changes to it. Are you sure you want to download?`}
                confirmAction="Download"
                onConfirm={downloadRevision}
                onReject={confirmDownloadCheckout.setFalse}
                toggle={confirmDownloadCheckout.setFalse}
            />
            <ConfirmationModal
                danger
                header="Delete revision"
                open={deleting.value}
                message="Are you sure you want to delete this this revision? If this revision has been transmitted to other companies, this will not delete the revision for them."
                confirmAction="Delete"
                onReject={deleting.setFalse}
                rejectAction="Cancel"
                onConfirm={deleteDocument}
                toggle={deleting.setFalse}
            >
                <CheckboxRadio labelClass="mt-2" checked={deleteAllRevisions.value} onClick={deleteAllRevisions.toggle} label={<strong>All revisions of this document</strong>} />
            </ConfirmationModal>
        </section>
    )
}

export default DocumentToolbar
