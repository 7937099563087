import React from 'react'
import { connect } from 'react-redux'
import { Card, CardBody, CardHeader, Col, Container, FormGroup, FormText, Input, Label, Row } from 'reactstrap'
import { Dispatch } from 'redux'
import { ConfigProps, Field, FormSection, InjectedFormProps, change, formValueSelector, reduxForm } from 'redux-form'

import { DebouncedFunc, debounce } from 'lodash'

import FA from '@src/components/common/FontAwesomeIcon'
import FroalaEditorInput from '@src/components/common/FroalaEditorInput'
import FroalaViewer from '@src/components/common/FroalaViewer'
import ToggleButton from '@src/components/common/ToggleButton'
import ValidatedAsyncCreatableSelect from '@src/components/common/ValidatedAsyncCreatableSelect'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import { RFMetadataInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { transmittalAddressLabel, transmittalAddressValue } from '@src/logic/forms/SelectHelpers'
import { CommunicationPreview } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import { validateEmail } from '@src/logic/utils/Strings'
import { Api } from '@src/types/api'
import { Communication } from '@src/types/communication'
import { IMetadataDefinition, IMetadataForm } from '@src/types/metadata'
import { RootState } from '@src/types/models'
import { UserBasic } from '@src/types/principal'
import { TransmittalAddress } from '@src/types/transmittal'

interface IProps extends IMetadataForm {
    communication: Communication
    getUsers: (name: string, limit: number) => Promise<UserBasic[]>
}

interface IConnectedState {
    getCurrentOverrides: () => Api.Request.CommunicationPreviewOverride
}

interface IConnectedDispatch {
    updateBody: (body: string) => void
}

interface IState {
    preview: string
    showEditor: boolean
}

export interface ICommunicationFormData {
    name: string,
    description: string
    to: TransmittalAddress[]
    cc: TransmittalAddress[]
    body: string
    metadata: {
        [key: string]: any
    }
}

class CommunicationForm extends React.PureComponent<IProps & IConnectedState & IConnectedDispatch & InjectedFormProps<ICommunicationFormData, IProps & IConnectedState & IConnectedDispatch>, IState> {
    private readonly debouncedLoadPreview: DebouncedFunc<null>
    private editor

    constructor(props) {
        super(props)

        this.debouncedLoadPreview = debounce(this.loadPreview, 2000)

        this.state = {
            preview: '',
            showEditor: false
        }
    }

    public componentDidMount() {
        this.loadPreview()
    }

    public componentWillUnmount() {
        this.debouncedLoadPreview.cancel()
    }

    private loadPreview = async () => {
        try {
            const response = await CommunicationPreview(this.props.communication.id, this.props.dirty ? this.props.getCurrentOverrides() : {})
            this.setState({ preview: response.data })
        } catch (e) {
            if (isAxiosError(e) && e.response && e.response.status === 400) {
                this.setState({ preview: `<pre>${e.response.data.message}</pre>` })
            } else {
                this.setState({ preview: 'There was an error rendering the communication. Check that you have closed all curly braces.' })
            }
        }
    }

    private handleBodyChange = (event?: React.ChangeEvent, newValue?: any, previousValue?: any, name?: string) => {
        this.debouncedLoadPreview()
    }

    private renderMetadataDefinitionField = (definition: IMetadataDefinition) => {
        return (
            <Col md={6} key={definition.key}>
                <FormGroup>
                    <Label>{definition.name} <MetadataTooltip id={`comms-meta-tooltip-${definition.key}`} definition={definition} placement="right" /></Label>
                    <RFMetadataInput definition={definition} onChange={this.handleBodyChange} />
                    {definition.description && <FormText>{definition.description}</FormText>}
                </FormGroup>
            </Col>
        )
    }

    private loadUserOptions = async (input: string): Promise<TransmittalAddress[]> => {
        const users =  await this.props.getUsers(input, 100)
        return users.map<TransmittalAddress>(u => ({
            email: u.email,
            id: u.id,
            name: `${u.firstName} ${u.lastName}`
        }))
    }

    private isValidEmailOption = (option: any, selectValue, options) => {
        return validateEmail(option.label)
    }

    private toggleShowPreview = () => {
        if (this.state.showEditor) {
            if (this.editor.codeView.isActive()) {
                this.props.updateBody(this.editor.codeView.get())
            }
        }
        this.setState({ showEditor: !this.state.showEditor })
    }

    public render() {
        const { definitions } = this.props
        return (
            <Container fluid>
                <Row>
                    <Col>
                        <Card>
                            <CardHeader><FA icon="info-circle" /> Basic Info</CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label>Name</Label>
                                            <Field name="name" component={ValidatedInput} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Communication Number</Label>
                                            <Input disabled readonly value={this.props.communication.communicationNo} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Description</Label>
                                            <Field name="description" component={ValidatedInput} type="textarea" />
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label>To</Label>
                                            <Field name="to" component={ValidatedAsyncCreatableSelect} async defaultOptions cacheOptions loadOptions={this.loadUserOptions} isMulti creatable isValidNewOption={this.isValidEmailOption} getOptionLabel={transmittalAddressLabel} getOptionValue={transmittalAddressValue} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label>Cc</Label>
                                            <Field name="cc" component={ValidatedSelect} async defaultOptions cacheOptions loadOptions={this.loadUserOptions} isMulti creatable isValidNewOption={this.isValidEmailOption} getOptionLabel={transmittalAddressLabel} getOptionValue={transmittalAddressValue} />
                                        </FormGroup>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        <Card className="mt-3">
                            <CardHeader>Communication Data</CardHeader>
                            <CardBody>
                                <FormSection name="metadata" component={Row as any}>
                                    {definitions.map(this.renderMetadataDefinitionField)}
                                </FormSection>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col>
                        <Card>
                            <CardHeader className="d-flex align-items-center">
                                <div><FA icon="file-alt" /> Body</div>
                                <span className="d-inline-flex align-items-center ml-auto">
                                    <FA icon="pencil" size="xs" />
                                    <ToggleButton className="ml-2" on={this.state.showEditor} onToggle={this.toggleShowPreview} />
                                </span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <Col>
                                        {this.state.showEditor && <FormGroup>
                                            <Field
                                                name="body"
                                                component={FroalaEditorInput}
                                                onChange={this.handleBodyChange}
                                                config={{
                                                    height: 900,
                                                    htmlUntouched: true,
                                                    entities: '&lsquo;&rsquo;',
                                                    htmlRemoveTags: ['script'],
                                                    iframe: true,
                                                    iframeStyle: 'body{overflow: auto; height: 800px;}',
                                                    toolbarSticky: false,
                                                    events: {
                                                        'froalaEditor.initialized': (e, editor) => this.editor = editor
                                                    }
                                                }}
                                            />
                                        </FormGroup>}
                                        {!this.state.showEditor &&
                                            <FroalaViewer content={this.state.preview} config={{ height: 900, iframe: true, iframeStyle: 'body{overflow: auto; height: 800px;}', htmlUntouched: true, htmlRemoveTags: ['script'], }} />
                                        }
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & ConfigProps<ICommunicationFormData, IProps>): IProps & IConnectedState & ConfigProps {
    return {
        ...ownProps,
        getCurrentOverrides: () => {
            const values = formValueSelector(ownProps.form)(state, 'body', 'metadata')
            return { overrideBody: values.body, overrideMetadata: values.metadata }
        }
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps & ConfigProps<ICommunicationFormData, IProps>) {
    return {
        updateBody: (body: string) => dispatch(change(ownProps.form, 'body', body))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm<ICommunicationFormData, IProps & IConnectedState>({})(CommunicationForm))
