import React from 'react'
import { Badge } from 'reactstrap'

interface IProps {
    tags: string[]
}

export default class Tags extends React.Component<IProps> {
    public render() {
        const badges = this.props.tags.map((t, idx) => <Badge className="mr-1" key={idx} color="primary">{t}</Badge>)

        return <span className="small">{badges}</span>
    }
}
