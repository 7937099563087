import React from 'react'

import cx from 'classnames'

interface IProps {
    empty: boolean
    visibleSize: 'sm' | 'md' | 'lg' | 'xl'
    column: boolean
}

export default class BannerPanel extends React.PureComponent<IProps> {

    public static defaultProps: IProps = {
        empty: false,
        visibleSize: 'sm',
        column: false
    }

    public render() {
        const { empty, visibleSize, column, ...props } = this.props

        return (
            <div {...props} className={cx('banner__panel flex-direction-column', { 'banner__panel--empty': empty }, 'd-none', `d-${visibleSize}-flex`)}>
                <div className={cx('banner__panel-inner', { 'flex-direction-column': column })}>
                    {this.props.children}
                </div>
            </div>
        )
    }
}
