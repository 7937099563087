import React from 'react'

import Link from '@src/components/common/Link'
import DocumentStatusIndicator from '@src/components/document/DocumentStatusIndicator'
import * as Routes from '@src/logic/routing/routes'
import { Revision } from '@src/types/document'

interface IProps {
    revision: Revision
}

export default class  extends React.PureComponent<IProps> {
    public render() {
        const { revision } = this.props
        return (
            <div>
                <DocumentStatusIndicator revision={revision} />
                <Link to={Routes.projectDocument(revision.projectId, revision.documentId, revision.id)}>{revision.name}</Link>
            </div>
        )
    }
}
