import auth0 from 'auth0-js'

import { clear, login } from '@src/actions/session'
import { decodeToken, isTokenExpired } from '@src/logic/auth/jwtHelper'
import NotificationService from '@src/logic/notification/NotificationService'
import history from '@src/logic/routing/history'
import * as Keys from '@src/logic/storage/keys'
import { appStore } from '@src/store'
import { Session } from '@src/types/session'

const SESSION_STORAGE_KEYS = [
    Keys.WidgetState
]

export class AuthService {
    private readonly audience: string
    private readonly webAuth: auth0.WebAuth
    private authToken: string

    constructor(clientId: string, domain: string, audience: string) {
        this.audience = audience
        this.webAuth = new auth0.WebAuth(
            {
                domain,
                audience,
                clientID: clientId,
                responseType: 'token',
                scope: 'openid profile',
                redirectUri: `${window.location.origin}/authcallback`
            })
        this.authToken = null
    }

    public loadSession = async (): Promise<boolean> => {
        try {
            await this.renewSession()
            return true
        } catch {
            this.clearSession()
            return false
        }
    }

    public renewSession = async (): Promise<void> => {
        // tslint:disable-next-line: no-string-literal
        if (window['Cypress']) return
        return new Promise((resolve, reject) => {
            this.webAuth.checkSession({}, (error, result) => {
                if (error) {
                    reject()
                } else {
                    this.doAuthentication(result)
                    resolve()
                }
            })
        })
    }

    public logout = () => {
        this.clearStoredAuthData()
        this.clearSessionStorage()
        this.clearOldAuthKeys()
        this.webAuth.logout({ returnTo: window.location.origin })
    }

    public clearSession = () => {
        this.clearStoredAuthData()
        appStore?.dispatch(clear())
        this.clearSessionStorage()
    }

    public resetPassword = (email: string) => {
        return new Promise((resolve, reject) => {
            this.webAuth.changePassword({ email, connection: 'Username-Password-Authentication' }, (error: null | auth0.Auth0Error, result: any) => {
                return error != null ? reject(error) : resolve()
            })
        })
    }

    public login = async (email: string, password: string) => {
        localStorage.setItem(Keys.RedirectUrlAfterLogin, window.location.pathname)
        await new Promise((resolve, reject) => {
            this.webAuth.login({  password, username: email, realm: 'Username-Password-Authentication' }, (err, res) => {
                if (err) {
                    localStorage.removeItem(Keys.RedirectUrlAfterLogin)
                    return reject(err)
                }
                return resolve(res)
            })
        })
    }

    public isAuth0Error(e): e is auth0.Auth0Error {
        return e.error && (e.error_description || e.errorDescription)
    }


    public handleAuthentication = async () => {
        try {
            const result = await new Promise<auth0.Auth0DecodedHash>((res, rej) => this.webAuth.parseHash(
                // tslint:disable-next-line: no-string-literal
                window['Cypress'] ? { state: localStorage.getItem('testState') } : {},
                (err, response) => err ? rej(err) : res(response))
            )
            const redirectUrl = localStorage.getItem(Keys.RedirectUrlAfterLogin)
            this.doAuthentication(result, redirectUrl || '/')
            localStorage.removeItem(Keys.RedirectUrlAfterLogin)
        } catch (e) {
            history.push('/')
        }
    }

    public getSessionToken = () => {
        return this.authToken
    }


    public getUserProfile(accessToken: string): Session.AuthAccount {
        const rawProfile = decodeToken(accessToken)
        const appMetaClaim = `${this.audience}app_metadata`
        return {
            userId: rawProfile[appMetaClaim].userId,
            companyId: rawProfile[appMetaClaim].companyId,
            appSettings: {
                debug: rawProfile[appMetaClaim].debug
            }
        }
    }

    private clearStoredAuthData = () => {
        this.authToken = null
        localStorage.removeItem(Keys.AuthAccount)
    }

    private clearSessionStorage = () => {
        SESSION_STORAGE_KEYS.forEach(key => localStorage.removeItem(key))
    }

    private clearOldAuthKeys = () => {
        localStorage.removeItem(Keys.AccessToken)
        localStorage.removeItem(Keys.AccessTokenExpiry)
    }

    private doAuthentication(authResult: auth0.Auth0DecodedHash, redirectUrl: string = null) {
        const account = this.getUserProfile(authResult.accessToken)
        this.authToken = authResult.accessToken
        appStore.dispatch<any>(login(account))

        if (redirectUrl) {
            history.push(redirectUrl)
        }
    }
}

export const auth = new AuthService(process.env.AUTH0_CLIENT_ID, process.env.AUTH0_DOMAIN, process.env.AUTH0_API_IDENTIFIER)

export default AuthService
