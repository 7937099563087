import React from 'react'
import { Form, FormGroup, Label } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm } from 'redux-form'


import ValidatedInput from '@src/components/common/ValidatedInput'
import { required } from '@src/logic/forms/validation'

export interface ITemplateEditFormData {
    name: string
    description: string
}

class TemplateEditForm extends React.PureComponent<InjectedFormProps<ITemplateEditFormData>> {
    public render() {
        return (
            <Form>
                <FormGroup>
                    <Label>Name</Label>
                    <Field name="name" component={ValidatedInput} validate={required} />
                </FormGroup>
                <FormGroup>
                    <Label>Description</Label>
                    <Field name="description" component={ValidatedInput} validate={required} type="textarea" />
                </FormGroup>
            </Form>
        )
    }
}

export default reduxForm<ITemplateEditFormData>({})(TemplateEditForm)
