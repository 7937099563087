import React from 'react'
import { Card, CardBody, CardHeader } from 'reactstrap'

import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import { loadPrincipals } from '@src/logic/auth/access'
import { CompanyAccessGet, CompanyAccessUpdate } from '@src/logic/http/Api'
import { Api } from '@src/types/api'
import { Company } from '@src/types/principal'

interface IProps {
    company: Company
}

const CompanyAccessSection: React.FC<IProps> = ({ company }) => {

    async function getAcl() {
        const response = await CompanyAccessGet(false)
        return response.data
    }

    function handleLoadPrincipals() {
        return loadPrincipals(company)
    }

    async function saveAccessUpdates(accessUpdate: Api.Request.AccessControlListUpdate) {
        try {
            await CompanyAccessUpdate(accessUpdate)
            return true
        } catch {
            return false
        }
    }

    return (
        <Card>
            <CardHeader><FA icon="lock"/> Company Access Control</CardHeader>
            <CardBody>
                <AccessControlTable getAcl={getAcl} commitUpdates={saveAccessUpdates} loadPrincipals={handleLoadPrincipals} />
            </CardBody>
        </Card>
    )
}

export default CompanyAccessSection
