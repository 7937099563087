import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, CardBody, CardHeader, Container } from 'reactstrap'

import BigNumber from 'bignumber.js'

import { loadProjectCostsOverview } from '@src/actions/project'
import AccessControlTable from '@src/components/access/AccessControlTable'
import FA from '@src/components/common/FontAwesomeIcon'
import GeneralSettingsForm, { IGeneralSettingsFormData } from '@src/components/costs/settings/GeneralSettingsForm'
import { loadPrincipals } from '@src/logic/auth/access'
import { buildFormErrorsFromModelState } from '@src/logic/forms/errors'
import { CostsAccessGet, CostsAccessUpdate, CostsSettingsUpdate } from '@src/logic/http/Api'
import { isAxiosError } from '@src/logic/http/helpers'
import NotificationService from '@src/logic/notification/NotificationService'
import { AccessControlList } from '@src/types/access'
import { Api } from '@src/types/api'
import { CostsOverview } from '@src/types/costs'
import { RootState } from '@src/types/models'
import { Company } from '@src/types/principal'

const GeneralSettingsSection: React.FC = ({ }) => {

    const projectId = useSelector<RootState, string>(s => s.projects.active.id)
    const company = useSelector<RootState, Company>(s => s.session.company)
    const costsOverview = useSelector<RootState, CostsOverview>(s => s.projects.activeCostsOverview)
    const dispatch = useDispatch()

    async function saveSettings(values: IGeneralSettingsFormData) {
        try {
            await CostsSettingsUpdate(projectId, {
                gstRate: new BigNumber(values.gstRate).dividedBy(100).toNumber()
            })
            NotificationService.info('Cost settings saved')
        } catch (err) {
            if (isAxiosError(err) && err.response && err.response.status === 400) {
                return buildFormErrorsFromModelState(values, err.response.data)
            }

            NotificationService.error('There was an error while saving cost settings')
        }

        dispatch(loadProjectCostsOverview())
    }

    async function getCostsAcl(): Promise<AccessControlList> {
        return (await CostsAccessGet(projectId)).data
    }

    async function updateCostsAcl(accessUpdate: Api.Request.AccessControlListUpdate): Promise<boolean> {
        try {
            await CostsAccessUpdate(projectId, accessUpdate)
            return true
        } catch {
            return false
        }
    }

    function loadCompanyPrincipals() {
        return loadPrincipals(company)
    }

    return (
        <Container fluid>
            <GeneralSettingsForm
                initialValues={{
                    gstRate: new BigNumber(costsOverview.settings.gstRate).times(100).toNumber()
                }}
                onSubmit={saveSettings}
            />
            <Card className="mb-5">
                <CardHeader><FA icon="lock" /> Costs Access</CardHeader>
                <CardBody>
                    <AccessControlTable
                        getAcl={getCostsAcl}
                        commitUpdates={updateCostsAcl}
                        loadPrincipals={loadCompanyPrincipals}
                        disabled={!costsOverview.myAccess.isAdministrator}
                    />
                </CardBody>
            </Card>
        </Container>
    )
}

export default GeneralSettingsSection
