import React from 'react'
import { useSelector } from 'react-redux'

import Select, { IOption } from '@src/components/common/Select'
import { revisionLabel, revisionValue } from '@src/logic/forms/SelectHelpers'
import { getActiveProjectWidgetState } from '@src/reducers/widget'
import { Revision } from '@src/types/document'
import { RootState } from '@src/types/models'

interface ICommonProps {
    placeholder?: string
    filterOption?: (revision: IOption<string>, input: string) => boolean
}

interface ISingleValueProps extends ICommonProps {
    value?: Revision
    onChange: (value: Revision) => void
    isMulti: false
}

interface IMultiValueProps extends ICommonProps {
    value: Revision[]
    onChange: (value: Revision[]) => void
    isMulti: true
}

const WidgetRevisionsInput: React.FC<ISingleValueProps | IMultiValueProps> = ({ value, isMulti, onChange, ...commonProps }) => {
    const revisions = useSelector<RootState, Revision[]>(s => getActiveProjectWidgetState(s).revisions)

    return (
        <Select
            isMulti={isMulti}
            options={revisions}
            value={value}
            onChange={onChange}
            getOptionLabel={revisionLabel}
            getOptionValue={revisionValue}
            {...commonProps}
        />
    )
}

export default WidgetRevisionsInput
