import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import CommitmentItemForm, { ICommitmentItemFormData } from '@src/components/costs/commitments/CommitmentItemForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { CommitmentItemCreate, CommitmentItemUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { Commitment, CommitmentDefinition, CommitmentItem, CommitmentReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps extends IModalPropsManager {
    projectId: string
    costsOverview: CostsOverview
    commitmentDefinition: CommitmentDefinition
    commitment: Commitment
    commitmentItemToEdit?: CommitmentItem
    onItemCreatedOrUpdated?: () => void
}

interface IConnectedDispatch {
    submitForm: () => void
}

class CommitmentItemModal extends React.PureComponent<IProps & IConnectedDispatch> {
    private readonly handleSubmit = async (values: ICommitmentItemFormData, dispatch: Dispatch<any>) => {
        const { commitmentItemToEdit, commitmentDefinition, commitment } = this.props

        const statuses = commitmentDefinition.statusDefinitions

        if (commitmentItemToEdit) {
            const update: Api.Request.CommitmentItemUpdate = {
                costCode: values.costCode.code,
                name: values.name,
                description: values.description,
                status: values.status.code,
                phase: values.phase?.code,
                otherPartyReference: values.otherPartyRef,
                values: statuses.map((sd) => {
                    const status = values.statusColumnValues.find(x => x.status === sd.code)
                    return sd.columns.map<StatusColumnValueMap<CommitmentReportColumn>>(col => ({
                        column: col,
                        status: sd.code,
                        value: { ...status.columns[col] }
                    }))
                }).reduce((a, b) => a.concat(b), []),
                tags: []
            }

            return CommitmentItemUpdate(this.props.projectId, commitment.id, commitmentItemToEdit.id, update)
        }

        const newCommitmentItem: Api.Request.CommitmentItemNew = {
            name: values.name,
            costCode: values.costCode.code,
            otherPartyReference: values.otherPartyRef,
            description: '',
            phase: values.phase ? values.phase.code : null,
            status: values.status.code,
            tags: [],
            values: statuses.map((sd) => {
                const scvValue = values.statusColumnValues.find(x => x.status === sd.code)
                return sd.columns.map<StatusColumnValueMap<CommitmentReportColumn>>(col => ({
                    status: sd.code,
                    column: col,
                    value: { ...scvValue.columns[col] }
                }))
            }).reduce((a, b) => a.concat(b), [])
        }
        return CommitmentItemCreate(this.props.projectId, commitment.id, newCommitmentItem)
    }

    private readonly onSubmitSuccess = (result, dispatch, props) => {
        if (this.props.commitmentItemToEdit) {
            NotificationService.info('Updated item')
        } else {
            NotificationService.info('Created item')
        }
        this.props.onItemCreatedOrUpdated()
        this.props.toggle()
    }

    public render() {
        const { commitmentItemToEdit, commitmentDefinition, costsOverview, projectId } = this.props

        return (
            <Modal size="xl" isOpen={this.props.open} toggle={this.props.toggle} onClosed={this.props.onClosed}>
                <ModalHeader toggle={this.props.toggle}>{commitmentItemToEdit ? `Edit - ${commitmentItemToEdit.name}` : 'New item'}</ModalHeader>
                <ModalBody>
                    <CommitmentItemForm
                        form={commitmentItemToEdit != null ? Forms.CostsEditCommitmentItem : Forms.CostsNewCommitmentItem}
                        destroyOnUnmount
                        projectId={projectId}
                        costsOverview={costsOverview}
                        commitmentDefinition={commitmentDefinition}
                        commitmentItemToEdit={commitmentItemToEdit}
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.onSubmitSuccess}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.submitForm}>{commitmentItemToEdit != null ? 'Save' : 'Create'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        submitForm: () => dispatch(submit(ownProps.commitmentItemToEdit ? Forms.CostsEditCommitmentItem : Forms.CostsNewCommitmentItem))
    }
}

export default connect(null, mapDispatchToProps)(CommitmentItemModal)
