import React from 'react'
import { Button, Col, Collapse, Row, UncontrolledTooltip } from 'reactstrap'

import FA, { IPFontawesomeIcon } from '@src/components/common/FontAwesomeIcon'
import useBoolean from '@src/hooks/useBoolean'

interface IProps {
    icon: IPFontawesomeIcon
    name: string
    tooltip: string
    metadataKey: string
    onRetire?: (metadataKey: string) => void
    onUpdate?: (e: React.MouseEvent) => void
    disabled?: boolean
}

const DefinitionTemplate: React.FC<IProps> = ({ icon, name, tooltip, metadataKey, onRetire, onUpdate, disabled, children }) => {

    const open = useBoolean(false)

    function handleRetire() {
        onRetire(metadataKey)
    }

    function handleUpdate(e: React.MouseEvent) {
        onUpdate(e)
    }

    return (
        <div>
            <h4 className="my-2">
                <UncontrolledTooltip target={`metadata-icon-${metadataKey}`}>{tooltip}</UncontrolledTooltip>
                <FA id={`metadata-icon-${metadataKey}`} className="align-baseline" icon={icon} size="xs" />
                <span className="pointer" onClick={open.toggle}> {name} </span>
                <span className="float-right">
                    <FA className="pointer" icon="times" onClick={handleRetire} />
                </span>
            </h4>
            <Collapse isOpen={!open.value}>
                <Row className="mb-3">
                    {children}
                </Row>
                {!disabled &&
                <Row className="mb-3">
                    <Col>
                        <Button color="primary" onClick={handleUpdate}>Update</Button>
                    </Col>
                </Row>}
            </Collapse>
        </div>
    )
}

export default DefinitionTemplate
