import React from 'react'
import { DropdownItem } from 'reactstrap'

import { IconAvatar } from '@src/components/common/Avatar'
import Link from '@src/components/common/Link'
import * as Routes from '@src/logic/routing/routes'
import { Project } from '@src/types/project'

interface IProps {
    project: Project
    active?: boolean
}

export default class ProjectDropdownItem extends React.PureComponent<IProps> {

    public render() {
        const { project, active } = this.props

        return (
            <DropdownItem className="project__menu-item pointer" tag={Link} to={Routes.project(this.props.project.id)} role="button" active={active}>
                <IconAvatar size="sm" icon="project-diagram">{project.settings.code || project.code}</IconAvatar>
                <span className="project__text">
                    <span className="project__title">{project.name}</span>
                    <span className="project__desc">{project.description}</span>
                </span>
            </DropdownItem>
        )
    }
}
