import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import NewTemplateForm, { INewTemplateFormData } from '@src/components/communication/NewTemplateForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { TemplateCreate } from '@src/logic/http/Api'
import { Template } from '@src/types/communication'
import { Project } from '@src/types/project'

interface IProps extends IModalPropsManager {
    project?: Project
    onTemplateCreated?: (template: Template) => void
}

interface IConnectedDispatch {
    submitNewTemplate: () => void
}

class NewTemplateModal extends React.PureComponent<IProps & IConnectedDispatch> {

    public handleCreateTemplate = async (values: INewTemplateFormData): Promise<Template> => {
        const templateResponse = await TemplateCreate({
            key: values.key,
            projectId: this.props.project ? this.props.project.id : null,
            name: values.name,
            description: '',
            layout: '',
            metadataDefinitions: [],
            tags: []
        })

        return templateResponse.data
    }

    public handleTemplateCreated = (template: Template) => {
        this.props.onTemplateCreated(template)
    }

    public render() {
        const { open, toggle, onClosed, submitNewTemplate } = this.props
        return (
            <Modal isOpen={open} toggle={toggle} onClosed={onClosed}>
                <ModalHeader toggle={toggle}>New Template</ModalHeader>
                <ModalBody>
                    <NewTemplateForm
                        destroyOnUnmount
                        form={Forms.NewCommunicationTemplate}
                        onSubmit={this.handleCreateTemplate}
                        onSubmitSuccess={this.handleTemplateCreated}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={submitNewTemplate}>Create</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: IProps) {
    return {
        submitNewTemplate: () => dispatch(submit(Forms.NewCommunicationTemplate)),
    }
}

export default connect(undefined, mapDispatchToProps)(NewTemplateModal)
