import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Card, Container } from 'reactstrap'

import { CancelToken } from 'axios'

import ButtonLink from '@src/components/common/ButtonLink'
import DropdownCheckOptions, { IDropdownCheckOption } from '@src/components/common/DropdownCheckOptions'
import FA from '@src/components/common/FontAwesomeIcon'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import ModalToggle from '@src/components/modal/ModalToggle'
import RegisterRowModal from '@src/components/register/RegisterRowModal'
import { metadataSearchProperty } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import { ITableHeader } from '@src/components/table/GenericTable'
import { isAuthorised } from '@src/logic/auth/access'
import { RegisterOperations } from '@src/logic/auth/operations'
import { RegisterGetById, RegisterRowUpdate, RegisterRowsAdd, RegisterRowsDelete } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { IMetadataDefinition, MetadataTypes } from '@src/types/metadata'
import { RegisterOverview, RegisterRow } from '@src/types/register'

interface IProps extends RouteComponentProps {
    registerOverview: RegisterOverview
}

interface IState {
    rowToDelete: RegisterRow
    rowToEdit: RegisterRow
    hiddenHeaders: string[]
}

const sortableTypes: MetadataTypes[] = [MetadataTypes.Bool, MetadataTypes.CompanyProperty, MetadataTypes.Date, MetadataTypes.DocumentProperty, MetadataTypes.EmailProperty, MetadataTypes.Numeric, MetadataTypes.Text, MetadataTypes.TransmittalProperty, MetadataTypes.UserProperty]

export default class RegisterPage extends React.PureComponent<IProps & RouteComponentProps, IState> {
    private readonly searchSectionRef: React.RefObject<SearchSectionType<RegisterRow, never>>

    constructor(props: IProps) {
        super(props)

        this.searchSectionRef = React.createRef<SearchSectionType<RegisterRow, never>>()

        this.state = {
            rowToDelete: null,
            rowToEdit: null,
            hiddenHeaders: []
        }
    }

    private readonly handleSearch = async (filter: string, sort: string, page: number, perPage: number, cancelToken: CancelToken) => {
        const response = await RegisterGetById(this.props.registerOverview.id, filter, sort, page, perPage, { cancelToken })
        return { items: response.data.data.rows, totalItems: response.data.data.filteredCount }
    }

    private readonly handleAddRow = async (values, triggerSearch: () => void) => {
        const row = {
            cells: this.props.registerOverview.columnDefinitions.reduce(
                (prev, current) => ({
                    ...prev,
                    [current.key]: values[current.key]
                }),
                {}
            )
        }

        try {
            await RegisterRowsAdd(this.props.registerOverview.id, [row])
            triggerSearch()
        } catch (e) {
            NotificationService.error('An error occured while creating a new register row')
            throw e
        }
    }

    private readonly columnDefinitionsToTableHeaders = (columnDefinitions: IMetadataDefinition[]): ITableHeader<RegisterRow>[] => {
        return columnDefinitions.map<ITableHeader<RegisterRow>>(def => ({
            name: def.name,
            key: def.key as any,
            sortKey: def.key,
            sortable: sortableTypes.includes(def.type),
            overrideRenderer: row => renderMetadata(row.cells[def.key], def, this.props.registerOverview.projectId)
        })).filter(header => !this.state.hiddenHeaders.includes(header.sortKey))
    }

    private readonly handleHeaderChecked = (e: React.ChangeEvent<HTMLInputElement>, option: IDropdownCheckOption) => {
        if (this.state.hiddenHeaders.includes(option.key)) {
            this.setState({ hiddenHeaders: this.state.hiddenHeaders.filter(h => h !== option.key) })
        } else {
            this.setState({ hiddenHeaders: [...this.state.hiddenHeaders, option.key] })
        }
    }

    private readonly setRowToEdit = (row: RegisterRow) => {
        this.setState({ rowToEdit: row })
    }

    private readonly clearRowToEdit = () => {
        this.setState({ rowToEdit: null })
    }

    private readonly updateRegisterRow = async (values) => {
        try {
            await RegisterRowUpdate(this.props.registerOverview.id, this.state.rowToEdit.id, { cells: values })
            NotificationService.info('Updated row')
            this.searchSectionRef.current.doSearch()
        } catch {
            NotificationService.error('An error occured while updating the row')
        }
    }

    private readonly setRowToDelete = (row: RegisterRow) => {
        this.setState({ rowToDelete: row })
    }

    private readonly clearRowToDelete = () => {
        this.setState({ rowToDelete: null })
    }

    private readonly deleteRegisterRow = async () => {
        const toDelete = this.state.rowToDelete
        this.clearRowToDelete()

        try {
            await RegisterRowsDelete(this.props.registerOverview.id, [toDelete.id])
            NotificationService.info('Deleted row')
            this.searchSectionRef.current.doSearch()
        } catch (e) {
            NotificationService.error('An error occured while deleting the row')
        }
    }

    public render() {
        if (this.props.registerOverview.columnDefinitions.length === 0) {
            return (
                <Container fluid>
                    <Card body className="text-center mt-3">
                        <div className="my-3"><FA size="3x" icon="columns" /></div>
                        <p className="lead"><ButtonLink to={Routes.projectRegisterColumns(this.props.registerOverview.projectId, this.props.registerOverview.id)}>Add metadata columns</ButtonLink></p>
                        <p>This register doesn't have any metadata columns configured. You will need</p>
                    </Card>
                </Container>
            )
        }

        const headers: ITableHeader<RegisterRow>[] = [
            {
                name: 'Actions',
                sortable: false,
                overrideRenderer: row => (
                    <div className="selectable-content__actions">
                        <TooltipLinkAction id={`remove-${row.id}`} tooltip="Remove" disabled={false} data={row} onClick={this.setRowToDelete}><FA icon="trash" /></TooltipLinkAction>
                        <TooltipLinkAction id={`edit-${row.id}`} tooltip="Edit" data={row} onClick={this.setRowToEdit}><FA icon="pencil" /></TooltipLinkAction>
                    </div>
                )
            },
            ...this.columnDefinitionsToTableHeaders(this.props.registerOverview.columnDefinitions)
        ]
        const headerFilterOptions = this.props.registerOverview.columnDefinitions.map(cd => ({ label: cd.name, key: cd.key, checked: !this.state.hiddenHeaders.includes(cd.key) }))

        const { registerOverview } = this.props
        const { rowToEdit, rowToDelete } = this.state

        return (
            <>
                <SearchSection<RegisterRow, never>
                    ref={this.searchSectionRef}
                    regularTable
                    onSearch={this.handleSearch}
                    headers={headers}
                    searchAssistantProperties={this.props.registerOverview.columnDefinitions.map(cd => metadataSearchProperty(cd))}
                    extraSearchBarElements={[
                        {
                            element: onSearch => (
                                <ModalToggle
                                    modal={RegisterRowModal}
                                    modalProps={{
                                        onSubmit: values => this.handleAddRow(values, onSearch),
                                        definitions: this.props.registerOverview.columnDefinitions,
                                        form: 'register-add-row',
                                        title: `Add row to ${this.props.registerOverview.name}`,
                                        submitText: 'Create'
                                    }}
                                    wrapperProps={{
                                        disabled: !isAuthorised(registerOverview.myAccess, RegisterOperations.Update)
                                    }}
                                >
                                    <FA icon="plus-circle" size="lg" />
                                    <span> Add Row</span>
                                </ModalToggle>
                            ),
                            position: 'before'
                        },
                        {
                            element: _ => (
                                <DropdownCheckOptions
                                    caret
                                    isSearchable
                                    title={<FA icon="filter" />}
                                    options={headerFilterOptions}
                                    onCheck={this.handleHeaderChecked}
                                />
                            ),
                            position: 'before'
                        }
                    ]}
                    noItemsFoundMessage={<span className="lead text-center">No results</span>}
                />
                {rowToEdit &&
                    <RegisterRowModal
                        open
                        form="register-edit-row"
                        title="Update row"
                        submitText="Update"
                        definitions={registerOverview.columnDefinitions}
                        initialValues={
                            registerOverview.columnDefinitions.filter(cd => !cd.hasOwnProperty('parentKey'))
                            .reduce((obj, cd) => ({ ...obj, [cd.key]: rowToEdit.cells[cd.key] }), {})
                        }
                        toggle={this.clearRowToEdit}
                        onSubmit={this.updateRegisterRow}
                        destroyOnUnmount
                    />
                }
                <ConfirmationModal
                    open={rowToDelete != null}
                    toggle={this.clearRowToDelete}
                    message={<span>Are you sure you want to delete this register row? This action <strong>cannot</strong> be undone.</span>}
                    header="Delete row"
                    confirmAction="Yes, delete row"
                    onConfirm={this.deleteRegisterRow}
                    danger
                />
            </>
        )
    }
}
