import React, { useEffect, useRef, useState } from 'react'
import { Field, FieldRenderProps, FormSpy, useField, useForm } from 'react-final-form'
import { FormatOptionLabelMeta } from 'react-select/base'
import { Button, Card, CardBody, CardHeader, Col, Container, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row, UncontrolledTooltip } from 'reactstrap'

import { has, isEqual } from 'lodash'

import moment from 'moment'

import CollapsibleCard from '@src/components/card/CollapsibleCard'
import { UserAvatar } from '@src/components/common/Avatar'
import ClampLines from '@src/components/common/ClampLines'
import FA from '@src/components/common/FontAwesomeIcon'
import FroalaEditorInput from '@src/components/common/FroalaEditorInput'
import { IOption, InputActionChange } from '@src/components/common/Select'
import TooltipLink from '@src/components/common/TooltipLink'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import ValidatedDatePicker from '@src/components/common/ValidatedDatePicker'
import ValidatedInput from '@src/components/common/ValidatedInput'
import ValidatedSelect from '@src/components/common/ValidatedSelect'
import DocumentTableTitle from '@src/components/document/DocumentTableTitle'
import AutoSave from '@src/components/forms/AutoSave'
import { renderMetadata } from '@src/components/metadata/MetadataValues'
import NavigationPrompt from '@src/components/navigation/NavigationPrompt'
import { PropertyType, metadataSearchProperty } from '@src/components/search/SearchAssistant'
import SearchSection, { ISearchResult, SearchSectionType } from '@src/components/search/SearchSection'
import { ITableHeader } from '@src/components/table/GenericContentTable'
import AddRevisionsFromWidgetButton from '@src/components/widget/AddRevisionsFromWidgetButton'
import useAsyncCancellable from '@src/hooks/useAsyncCancellable'
import useConstant from '@src/hooks/useConstant'
import { useDeepCompareMemoize } from '@src/hooks/useDeepCompareMemoize'
import useProjectWidget from '@src/hooks/useWidget'
import { auth } from '@src/logic/auth/AuthService'
import { formatSimpleValue, parseOptionToSimpleValue, transmittalAddressLabel, transmittalAddressValue, trueFilter } from '@src/logic/forms/SelectHelpers'
import { ProjectUsersList, RevisionsDownloadLink, RevisionsList, TransmittalRevisionsList } from '@src/logic/http/Api'
import { downloadURL } from '@src/logic/http/Download'
import * as Headers from '@src/logic/http/headers'
import { FilterBuilder } from '@src/logic/iql/FilterBuilder'
import * as Routes from '@src/logic/routing/routes'
import { fileTypeIcon } from '@src/logic/utils/FileFormats'
import { throttlePromise } from '@src/logic/utils/Promise'
import { isNullOrEmpty, safeHtmlId, validateEmail } from '@src/logic/utils/Strings'
import { mutedNotSet, mutedValue } from '@src/logic/utils/ValueHelper'
import { DocumentLink, Revision } from '@src/types/document'
import { IMetadataDefinition } from '@src/types/metadata'
import { UserBasic } from '@src/types/principal'
import { TransmittalAddress } from '@src/types/transmittal'

interface IProps {
    projectId: string
    transmittalId?: string
}

export interface ITransmittalFormData {
    subject: string
    reason: string
    responseDate: Date
    responseType: string
    to: TransmittalAddress[]
    cc: TransmittalAddress[]
    bcc: TransmittalAddress[]
    revisions: DocumentLink[]
    body: string
}

const TransmittalInnerForm: React.FC<IProps> = ({ projectId, transmittalId }) => {
    const form = useForm()
    const revisionsSectionRef = useRef<SearchSectionType<Revision, 'id'>>()
    const widget = useProjectWidget()
    const revisionsField = useField<DocumentLink[]>('revisions', { isEqual, subscription: { value: true } })

    const revisionsValue = useDeepCompareMemoize(revisionsField.input.value)
    const [metadataDefinitions, setMetadataDefinitions] = useState<IMetadataDefinition[]>([])

    useEffect(
        () => {
            revisionsSectionRef.current.doSearch()
        },
        [revisionsValue, transmittalId]
    )

    function removeRevision(revision: Revision) {
        form.change('revisions', [...revisionsField.input.value.filter(x => x.revisionId !== revision.id)])
    }

    function addRevision(toAdd: Revision[]) {
        return form.change('revisions', [...revisionsField.input.value, ...toAdd.filter(r => revisionsField.input.value.find(op => op.revisionId === r.id) == null).map<DocumentLink>(r => ({ revisionId: r.id, documentId: r.documentId, name: r.name }))])
    }

    function handleSaveIfDirty() {
        if (form.getState().dirty) {
            form.submit()
        }
    }

    async function loadTransmittalRevisions(filter: string, sort: string, page: number, perPage: number): Promise<ISearchResult<Revision>> {
        if (transmittalId == null) {
            const skip = (page - 1) * perPage
            const response = await RevisionsList(projectId, FilterBuilder.for<Revision>().eq('id', revisionsField.input.value.map(x => x.revisionId).slice(skip, skip + perPage)).build())
            return {
                items: response.data.revisions,
                totalItems: revisionsField.input.value.length
            }
        }

        const response = await TransmittalRevisionsList(transmittalId, filter, sort, page, perPage)
        setMetadataDefinitions(response.data.metadataDefinitions)
        return {
            items: response.data.revisions,
            totalItems: response.headers[Headers.PaginationTotalCount]
        }
    }

    function handleRevisionSelected(...revisions: Revision[]) {
        widget.actions.toggleRevisions({ entities: revisions, projectId, snapTogether: true })
    }

    function downloadRevision(revision: Revision) {
        downloadURL(RevisionsDownloadLink(auth.getSessionToken(), revision.projectId, revision.id))
    }

    return (
        <Container fluid>
            <AutoSave wait={3000} save={handleSaveIfDirty} />
            <Row>
                <Col md={5} className="mb-3">
                    <Card className="h-100">
                        <CardHeader><FA icon="info-circle" /> Details</CardHeader>
                        <CardBody>
                            <FormGroup>
                                <Label for="reason">Reason</Label>
                                <Field name="reason" component={ValidatedSelect} options={[]} isClearable creatable format={formatSimpleValue} parse={parseOptionToSimpleValue} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="responseDate">Response Date</Label>
                                <Field name="responseDate" component={ValidatedDatePicker} isClearable placeholder="Noresponse deadline" isEqual={isEqual} />
                            </FormGroup>
                            <FormGroup>
                                <Label for="responseType">Response Type</Label>
                                <Field name="responseType" component={ValidatedSelect} options={[]} isClearable creatable format={formatSimpleValue} parse={parseOptionToSimpleValue} />
                            </FormGroup>
                        </CardBody>
                    </Card>
                </Col>
                <Col md={7} className="mb-3">
                    <RecipientSection projectId={projectId} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col>
                    <CollapsibleCard
                        noCardBody
                        defaultOpen={true}
                        header={(
                            <span className="d-inline-flex align-items-center w-100">
                                <span className="flex-grow-1"><FA icon="file-alt" /> Documents <span className="text-muted">({revisionsValue.length})</span></span>
                                <span><AddRevisionsFromWidgetButton addRevisions={addRevision} /></span>
                            </span>
                        )}
                    >
                        <SearchSection
                            itemIdKey="id"
                            noContainerForTable
                            ref={revisionsSectionRef}
                            onSearch={loadTransmittalRevisions}
                            onItemsSelected={handleRevisionSelected}
                            defaultPerPage={20}
                            selectedItems={widget.revisions.map(x => x.id)}
                            headers={[
                                {
                                    name: 'Type',
                                    overrideRenderer: r => <FA icon={fileTypeIcon(r.fileName || '')} />
                                },
                                {
                                    name: 'Name',
                                    overrideRenderer: r => <DocumentTableTitle revision={r} />
                                },
                                {
                                    name: 'Rev #',
                                    accessor: 'revNumber'
                                },
                                {
                                    name: 'Tags',
                                    accessor: 'tags'
                                },
                                {
                                    name: 'Author',
                                    accessor: 'author'
                                },
                                {
                                    name: 'Revision Date',
                                    accessor: 'revDate',
                                    overrideRenderer: r => r.revDate ? moment(r.revDate).format('lll') : mutedNotSet
                                },
                                {
                                    name: 'Uploaded',
                                    overrideRenderer: r => r.revDate ? moment(r.revDate).format('L') : mutedNotSet
                                },
                                ...(metadataDefinitions.map<ITableHeader<Revision>>(md => ({
                                    name: md.name,
                                    overrideRenderer: (r) => {
                                        const value = renderMetadata(r.metadata[md.key], md, r.projectId)
                                        return md.type === 'text' ? <ClampLines lines={2} text={typeof value === 'string' ? value : ''} /> : value
                                    }
                                }))),
                                {
                                    name: 'Actions',
                                    headerWrapperClass: 'text-right',
                                    noSmallHeader: true,
                                    overrideRenderer: r => (
                                        <div className="text-right">
                                            <TooltipLink id={`view-${r.id}`} tooltip="View" to={Routes.projectDocument(r.projectId, r.documentId, r.id)} className="selectable-content__icon order-lg-1"><FA icon="eye" /></TooltipLink>
                                            <TooltipLinkAction id={`download-${r.id}`} tooltip="Download" data={r} onClick={downloadRevision}><FA icon="download" /></TooltipLinkAction>
                                            <TooltipLinkAction id={`delete-${r.id}`} tooltip="Remove from transmittal" data={r} onClick={removeRevision}><FA icon="times" /></TooltipLinkAction>
                                        </div>
                                    )
                                }
                            ]}
                            searchAssistantProperties={[
                                {
                                    name: 'Name',
                                    searchKey: 'name',
                                    type: PropertyType.Text
                                },
                                {
                                    name: 'Rev #',
                                    searchKey: 'revision_number',
                                    type: PropertyType.Text
                                },
                                {
                                    name: 'Tags',
                                    searchKey: 'tags',
                                    type: PropertyType.Text
                                },
                                {
                                    name: 'Created At',
                                    searchKey: 'created',
                                    type: PropertyType.Date
                                },
                                {
                                    name: 'Revision Date',
                                    searchKey: 'revision_date',
                                    type: PropertyType.Date
                                },
                                {
                                    name: 'Author',
                                    searchKey: 'author',
                                    type: PropertyType.Text
                                },
                                ...metadataDefinitions.map(d => metadataSearchProperty(d))
                            ]}
                        />
                    </CollapsibleCard>
                </Col>
            </Row>
            <FormSpy subscription={{ submitting: true, dirty: true }}>
                {spy => (
                    <>
                        <Row className="mb-3">
                            <Col>
                                <Card className="mb-3">
                                    <CardHeader className="d-flex">
                                        <span><FA icon="envelope" /> Content</span>
                                        <small className="text-muted flex-shrink-1 align-self-center ml-auto">
                                            <FA icon={spy.submitting ? 'spinner-third' : spy.dirty || transmittalId ? 'exclamation-triangle' : 'check-circle'} spin={spy.submitting} />
                                            &nbsp;{spy.submitting ? 'Saving...' : spy.dirty ? 'Unsaved Changes' : 'Saved'}
                                        </small>
                                    </CardHeader>
                                    <CardBody>
                                        <FormGroup>
                                            <Label for="subject">Subject</Label>
                                            <Field id="subject" name="subject" component={ValidatedInput} />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label for="body">Body</Label>
                                            <Field name="body" component={FroalaEditorInput} />
                                        </FormGroup>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                        <NavigationPrompt when={(spy.dirty && !spy.submitting)}>
                            {(onConfirm, onCancel) => (
                                <Modal isOpen={true} toggle={onCancel}>
                                    <ModalHeader toggle={onCancel}>Unsaved Changes</ModalHeader>
                                    <ModalBody>You have unsaved changes on this transmittal. Are you sure you want to leave?</ModalBody>
                                    <ModalFooter>
                                        <Button color="link" onClick={onCancel}>Cancel</Button>
                                        <Button color="danger" onClick={onConfirm}>Discard</Button>
                                    </ModalFooter>
                                </Modal>
                            )}
                        </NavigationPrompt>
                    </>
                )}
            </FormSpy>
        </Container>
    )
}

function isTransmittalAddress(option: TransmittalAddress | UserBasic | IOption<string>): option is TransmittalAddress {
    return has(option, 'name')
}

const RecipientSection: React.FC<{ projectId: string }> = React.memo(({ projectId }) => {
    const throttledUserSearch = useConstant(() => throttlePromise(ProjectUsersList, 280))

    const defaultUsers = useAsyncCancellable(
        (ct, pid) => ProjectUsersList(pid, '', '', 1, 100, { cancelToken: ct }).then(res => res.data),
        [projectId],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    const filteredUsers = useAsyncCancellable(
        async (ct, input, defaultUsersPromise) => {
            if (input === '') return await defaultUsersPromise
            const res = await throttledUserSearch(projectId, `first_name: "${input}" email: "${input}"`, '', 1, 100, { cancelToken: ct })
            return res.data
        },
        ['', defaultUsers.currentPromise],
        { setLoading: s => ({ ...s, loading: true }) }
    )

    function handleRecipientInputChange(input: string, action: InputActionChange) {
        if (action === 'input-change') {
            filteredUsers.execute(input, defaultUsers.currentPromise)
        } else if (action === 'input-blur') {
            filteredUsers.execute('', defaultUsers.currentPromise)
        }
    }

    return (
        <Card>
            <CardHeader><FA icon="users" /> Recipients</CardHeader>
            <CardBody>
                <FormGroup>
                    <Label for="to">To</Label>
                    <Field name="to" id="to" isEqual={isEqual} component={RecipientField} loading={filteredUsers.loading} options={filteredUsers.result} onInputChange={handleRecipientInputChange} />
                </FormGroup>
                <FormGroup>
                    <Label for="cc">Cc</Label>
                    <Field name="cc" id="cc" isEqual={isEqual} component={RecipientField} loading={filteredUsers.loading} options={filteredUsers.result} onInputChange={handleRecipientInputChange} />
                </FormGroup>
                <FormGroup>
                    <Label for="bcc">Bcc</Label>
                    <Field name="bcc" id="bcc" isEqual={isEqual} component={RecipientField} loading={filteredUsers.loading} options={filteredUsers.result} onInputChange={handleRecipientInputChange} />
                </FormGroup>
            </CardBody>
        </Card>
    )
})

interface IRecipientFieldProps extends FieldRenderProps<TransmittalAddress[]> {
    id: string
    loading: boolean
    options: UserBasic[]
    onInputChange: (input: string, action: InputActionChange) => void
}

const RecipientField: React.FC<IRecipientFieldProps> = ({ id, loading, options, onInputChange, ...field }) => {
    function handleChange(value: (TransmittalAddress | UserBasic)[] | null) {
        if (value == null) return field.input.onChange([])

        const newValue = value.map(x => isTransmittalAddress(x) ? x : ({ id: x.id, email: x.email, name: `${x.firstName ?? ''} ${x.lastName ?? ''}`.trim() }))
        field.input.onChange(newValue)
    }

    function formatRecipientLabel(option: TransmittalAddress | UserBasic, labelMeta: FormatOptionLabelMeta<UserBasic>) {
        if (labelMeta.context === 'menu') {
            if (isTransmittalAddress(option)) {
                return option.email
            }

            return <span><UserAvatar firstName={option.firstName} lastName={option.lastName} imageUrl={option.profilePictureLink} size="xs" className="mr-2" /> {!isNullOrEmpty(option.firstName) ? `${option.firstName} ${option.lastName}`.trim() : option.email} {mutedValue(option.company?.name)}</span>
        }

        if (labelMeta.context === 'value') {
            return (
                <div>
                    <UncontrolledTooltip target={`recipient-${safeHtmlId(option.id)}`}>{option.email}</UncontrolledTooltip>
                    <span id={`recipient-${safeHtmlId(option.id)}`}>{`${(option as TransmittalAddress).name || option.email}`}</span>
                </div>
            )
        }

        return option.email
    }

    function isValidEmailOption(option: any) {
        return validateEmail(option.label)
    }

    function normalizeArray(value: any[] | null) {
        return value ?? []
    }

    return (
        <ValidatedSelect
            {...field}
            id={id}
            meta={field.meta as any}
            input={{
                ...field.input,
                onChange: handleChange
            } as any}
            isMulti
            creatable
            options={options}
            isLoading={loading}
            onInputChange={onInputChange}
            isValidNewOption={isValidEmailOption}
            filterOption={trueFilter}
            parse={normalizeArray}
            formatOptionLabel={formatRecipientLabel}
            getOptionLabel={transmittalAddressLabel}
            getOptionValue={transmittalAddressValue}
        />
    )
}

export default React.memo(TransmittalInnerForm)
