import React from 'react'
import { Route, Switch } from 'react-router'

import LoginPage from '@src/pages/auth/LoginPage'
import UserActivationPage from '@src/pages/UserActivationPage'

export class UnauthenticatedArea extends React.PureComponent {

    public render() {
        return (
            <Switch>
                <Route path="/activate" component={UserActivationPage} />
                <Route component={LoginPage} />
            </Switch>
        )
    }
}
