import React from 'react'
import { connect } from 'react-redux'
import { Button, Col, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from 'reactstrap'
import { ConfigProps, InjectedFormProps, reduxForm } from 'redux-form'

import { RFMetadataInput } from '@src/components/metadata/MetadataInputs'
import MetadataTooltip from '@src/components/metadata/MetadataTooltip'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { IMetadataDefinition, IMetadataForm } from '@src/types/metadata'
import { RootState } from '@src/types/models'

interface IProps extends IMetadataForm {
    definitions: IMetadataDefinition[]
    title: string
    submitText: string
}

class RegisterRowModal extends React.PureComponent<IProps & IModalPropsManager & InjectedFormProps<{}, IProps>> {

    public render() {
        return (
            <Modal size="lg" wrapClassName="d-block" isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>{this.props.title}</ModalHeader>
                <ModalBody>
                    <Row>
                        {this.props.definitions.filter(cd => !cd.hasOwnProperty('parentKey')).map((md, idx) => (
                            <Col xs={12} md={6} key={md.key} className="mb-3">
                                <Label>{md.name} <MetadataTooltip id={`meta-tooltip-${md.key}`} definition={md} placement="right" /></Label>
                                <RFMetadataInput definition={md} />
                            </Col>
                        ))}
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={this.props.handleSubmit}>{this.props.submitText}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: IProps & IModalPropsManager & Partial<Omit<ConfigProps, keyof { onSubmitSuccess }>>): IProps & Partial<ConfigProps> {
    return {
        ...ownProps,
        onSubmitSuccess: ownProps.toggle
    }
}

export default connect(mapStateToProps)(reduxForm<{}, IProps>({})(RegisterRowModal))
