import React from 'react'
import { Button, Form, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Field, InjectedFormProps, reduxForm,  } from 'redux-form'

import ValidatedInput from '@src/components/common/ValidatedInput'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import { required } from '@src/logic/forms/validation'
import { RegisterCreate } from '@src/logic/http/Api'
import { RegisterOverview } from '@src/types/register'

interface IProps {
    projectId: string
}

class NewRegisterModal extends React.PureComponent<IProps & IModalPropsManager & InjectedFormProps<{}, IProps & IModalPropsManager>> {

    public render() {
        return (
            <Modal isOpen={this.props.open} toggle={this.props.toggle}>
                <ModalHeader toggle={this.props.toggle}>Create Register</ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Label for="name">Name</Label>
                            <Field id="name" name="name" component={ValidatedInput} validate={[required]} />
                        </FormGroup>
                        <FormGroup>
                            <Label>Description</Label>
                            <Field name="description" component={ValidatedInput} type="textarea" />
                        </FormGroup>
                    </Form>
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.handleSubmit}>Create</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

async function handleCreateRegister(values, dispatch, props: IProps & IModalPropsManager): Promise<RegisterOverview> {
    return (await RegisterCreate(props.projectId, { ...values })).data
}

export default reduxForm<{}, IProps & IModalPropsManager>({ form: 'newRegister', onSubmit: handleCreateRegister })(NewRegisterModal)
