import React from 'react'
import { FormFeedback } from 'reactstrap'
import { WrappedFieldProps } from 'redux-form'

import Select, { IAsyncProps, ICreatableProps } from '@src/components/common/Select'

export default class ValidatedAsyncCreatableSelect<TValue> extends React.PureComponent<Omit<IAsyncProps<TValue> & ICreatableProps<TValue>, keyof { onChange, value }> & WrappedFieldProps> {

    public render() {
        const { input, meta: { touched, error }, creatable, ...selectProps } = this.props

        const showError = touched && !!error
        return (
            <>
                <Select<TValue>
                    {...selectProps}
                    async
                    creatable
                    value={input.value}
                    onChange={input.onChange}
                    invalid={showError}
                />
                <FormFeedback>{showError && error}</FormFeedback>
            </>
        )
    }
}
