import React from 'react'
import { connect } from 'react-redux'
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import { Dispatch } from 'redux'
import { submit } from 'redux-form'

import BudgetItemForm, { IBudgetItemFormData } from '@src/components/costs/budget/BudgetItemForm'
import { IModalPropsManager } from '@src/components/modal/ModalProps'
import * as Forms from '@src/logic/forms/Forms'
import { BudgetItemCreate, BudgetItemUpdate } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { Api } from '@src/types/api'
import { BudgetAdjustment, BudgetItem, BudgetReportColumn, CostsOverview, StatusColumnValueMap } from '@src/types/costs'

interface IProps extends IModalPropsManager {
    projectId: string
    costsOverview: CostsOverview
    budgetItemToEdit?: BudgetItem | BudgetAdjustment
    onItemCreatedOrUpdated?: () => void
}

interface IConnectedDispatch {
    submitForm: () => void
}

class BudgetItemModal extends React.PureComponent<IProps & IConnectedDispatch> {

    private handleSubmit = async (values: IBudgetItemFormData, dispatch: Dispatch<any>) => {
        const { budgetItemToEdit, costsOverview } = this.props

        const isAdjustment = this.isAdjustment()
        const statuses = isAdjustment ? costsOverview.budget.adjustmentStatusDefinitions : costsOverview.budget.itemStatusDefinitions

        if (budgetItemToEdit) {
            const update: Api.Request.BudgetItemUpdate | Api.Request.BudgetAdjustmentUpdate = {
                costCode: values.costCode.code,
                name: values.name,
                description: values.description,
                status: values.status.code,
                date: values.date,
                phase: values.phase?.code,
                values: statuses.map(sd => sd.columns.map<StatusColumnValueMap<BudgetReportColumn>>(col => ({
                    column: col,
                    status: sd.code,
                    value: {
                        quantity: 0,
                        unit: '',
                        rate: 0,
                        notes: '',
                        value: values.statusColumnValues.find(x => x.status === sd.code)[col]
                    }
                }))).reduce((a, b) => a.concat(b), []),
                tags: [],
                ...(budgetItemToEdit.isAdjustment ? {
                    clientApprovalNumber: values.clientApprovalNumber,
                    clientApprovalDocuments: values.clientApprovalDocuments
                } : {})
            }

            return BudgetItemUpdate(this.props.projectId, budgetItemToEdit.id, update)
        }

        const newBudgetItem: Api.Request.BudgetItemNew = {
            ...(isAdjustment ? {
                isAdjustment: true,
                clientApprovalNumber: values.clientApprovalNumber,
                clientApprovalDocuments: values.clientApprovalDocuments
            } : {
                isAdjustment: false
            }),
            name: values.name,
            costCode: values.costCode.code,
            description: '',
            phaseId: values.phase ? values.phase.code : null,
            status: values.status.code,
            tags: [],
            date: values.date,
            values: statuses.map(sd => sd.columns.map<StatusColumnValueMap<BudgetReportColumn>>(col => ({
                column: col,
                status: sd.code,
                value: {
                    quantity: 0,
                    unit: '',
                    rate: 0,
                    notes: '',
                    value: values.statusColumnValues.find(x => x.status === sd.code)[col]
                }
            }))).reduce((a, b) => a.concat(b), [])
        }
        return BudgetItemCreate(this.props.projectId, newBudgetItem)
    }

    private onSubmitSuccess = (result, dispatch, props) => {
        if (this.props.budgetItemToEdit) {
            NotificationService.info('Updated item')
        } else {
            NotificationService.info('Created item')
        }
        this.props.onItemCreatedOrUpdated()
        this.props.toggle()
    }

    private isAdjustment = () => {
        return this.props.budgetItemToEdit != null ? this.props.budgetItemToEdit.isAdjustment : this.props.costsOverview.budget.locked
    }

    public render() {
        const { budgetItemToEdit, costsOverview, projectId } = this.props

        return (
            <Modal size="lg" isOpen={this.props.open} toggle={this.props.toggle} onClosed={this.props.onClosed}>
                <ModalHeader toggle={this.props.toggle}>{budgetItemToEdit ? `Edit - ${budgetItemToEdit.name}` : 'New ' + (this.isAdjustment() ? 'adjustment' : 'item')}</ModalHeader>
                <ModalBody>
                    <BudgetItemForm
                        form={budgetItemToEdit != null ? Forms.CostsEditBudgetItem : Forms.CostsNewBudgetItem}
                        destroyOnUnmount
                        costsOverview={costsOverview}
                        projectId={projectId}
                        isAdjustment={budgetItemToEdit != null ? budgetItemToEdit.isAdjustment : costsOverview.budget.locked}
                        budgetItemToEdit={budgetItemToEdit}
                        onSubmit={this.handleSubmit}
                        onSubmitSuccess={this.onSubmitSuccess}
                    />
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.submitForm}>{budgetItemToEdit != null ? 'Save' : 'Create'}</Button>
                </ModalFooter>
            </Modal>
        )
    }
}

function mapDispatchToProps(dispatch, ownProps: IProps): IConnectedDispatch {
    return {
        submitForm: () => dispatch(submit(ownProps.budgetItemToEdit ? Forms.CostsEditBudgetItem : Forms.CostsNewBudgetItem)),
    }
}

export default connect(null, mapDispatchToProps)(BudgetItemModal)
