export const CostsGeneralSettings = 'costs-general-settings'
export const CostsBudgetSettings = 'costs-budget-settings'
export const CostsNewBudgetItem = 'costs-new-budget-item'
export const CostsNewBudgetItemQuick = 'costs-new-budget-item-quick'
export const CostsNewBudgetStatus = 'costs-new-budget-status'
export const CostsNewCode = 'costs-new-code'
export const CostsNewCommitment = 'costs-new-commitment'
export const CostsNewCommitmentDefinition = 'costs-new-commitment-definition'
export const CostsNewCommitmentItem = 'costs-new-commitment-item'
export const CostsNewCommitmentStatus = 'costs-new-commitment-status'
export const CostsNewPaymentClaim = 'costs-new-payment-claim'
export const CostsNewPhase = 'costs-new-phase'
export const CostsNewSnapshot = 'costs-new-snapshot'
export const CostsEditBudgetItem = 'costs-edit-budget-item'
export const CostsEditBudgetStatus = 'costs-edit-budget-status'
export const CostsEditCode = 'costs-edit-code'
export const CostsEditCommitment = 'costs-edit-commitment'
export const CostsEditCommitmentDefintion = 'costs-edit-commitment-definition'
export const CostsEditCommitmentItem = 'costs-edit-commitment-item'
export const CostsEditCommitmentStatus = 'costs-edit-commitment-status'
export const CostsEditPaymentClaim = 'costs-edit-payment-claim'
export const CostsEditPaymentItem = 'costs-edit-payment-item'
export const CostsEditPhase = 'costs-edit-phase'
export const CollaboratorSettings = 'collaborator-settings'
export const EditRevision = 'edit-revision'
export const EditCommunication = 'edit-communication'
export const EditTransmittal = 'edit-transmittal'
export const NewCommunicationTemplate = 'new-communication-template'
export const CopyProject = 'copy-project'
export const NewProject = 'new-project'
export const InviteUser = 'invite-user'
export const Group = 'group'
export const Sandbox = 'sandbox'
export const TransmittalApproval = 'transmittal-approval'
export const WidgetBulkRevisionAccess = 'widget-bulk-revision-access'
