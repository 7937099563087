import React from 'react'
import { connect } from 'react-redux'
import { Button, Card, Col, FormGroup, FormText, Label, Row } from 'reactstrap'
import { Dispatch } from 'redux'

import { reloadUserProfile } from '@src/actions/session'
import FA from '@src/components/common/FontAwesomeIcon'
import Image from '@src/components/common/Image'
import PictureUploadManager from '@src/components/common/PictureUploadManager'
import EditProfileForm from '@src/components/usersettings/EditProfileForm'
import ProfilePicture from '@src/components/usersettings/ProfilePicture'
import { UserProfilePictureUpload, UserProfileSignatureUpload } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import { RootState } from '@src/types/models'
import { Session } from '@src/types/session'

interface IConnectedState {
    user: Session.User
}

interface IConnectedDispatch {
    reloadUserProfile: () => void
}

interface IState {
    profilePictureError?: string
    signatureError?: string
}

class UserProfilePage extends React.PureComponent<IConnectedState & IConnectedDispatch, IState> {
    private readonly profilePictureUploadRef: React.RefObject<PictureUploadManager>
    private readonly signatureUploadRef: React.RefObject<PictureUploadManager>

    constructor(props) {
        super(props)

        this.state = {
            profilePictureError: undefined,
            signatureError: undefined
        }

        this.profilePictureUploadRef = React.createRef()
        this.signatureUploadRef = React.createRef()
    }

    private readonly uploadProfilePicture = async (blob: Blob) => {
        const pictureUpload = UserProfilePictureUpload(blob)
        const uploadingNotification = NotificationService.info(<div><span>Uploading profile picture...  </span><FA icon="spinner-third" spin /></div>, { autoClose: false })

        try {
            await pictureUpload
            this.setState({ profilePictureError: undefined })
            NotificationService.updateToast(uploadingNotification, 'Updated profile picture', { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
        } catch {
            NotificationService.updateToast(uploadingNotification, 'Failed to upload picture', { type: 'error' })
            return
        }

        this.props.reloadUserProfile()
    }

    private readonly uploadSignature = async (blob: Blob) => {
        const pictureUpload = UserProfileSignatureUpload(blob)
        const uploadingNotification = NotificationService.info(<div><span>Uploading signature...  </span><FA icon="spinner-third" spin /></div>, { autoClose: false })

        try {
            await pictureUpload
            this.setState({ profilePictureError: undefined })
            NotificationService.updateToast(uploadingNotification, 'Updated signature', { autoClose: NotificationService.DEFAULT_AUTO_CLOSE })
        } catch {
            NotificationService.updateToast(uploadingNotification, 'Failed to upload signature', { type: 'error' })
            return
        }

        this.props.reloadUserProfile()
    }

    private readonly openProfilePictureUpload = () => {
        this.profilePictureUploadRef.current.open()
    }

    private readonly updateProfilePictureError = (error: string) => {
        this.setState({ profilePictureError: error })
    }

    private readonly openSignatureUpload = () => {
        this.signatureUploadRef.current.open()
    }

    private readonly updateSignatureError = (error: string) => {
        this.setState({ signatureError: error })
    }

    public render() {
        return (
            <Card body>
                <h2 className="border-bottom pb-2 mb-3">Your Profile</h2>
                <Row>
                    <Col sm={7} md={8} className="mb-3">
                        <EditProfileForm />
                    </Col>
                    <Col sm={5} md={4}>
                        <FormGroup tag="dl" className="mr-4" style={{ width: 200 }}>
                            <dt><Label>Profile picture</Label></dt>
                            <dd className="clearfix">
                            <PictureUploadManager
                                ref={this.profilePictureUploadRef}
                                aspectRatio={1}
                                onUploadPicture={this.uploadProfilePicture}
                                onError={this.updateProfilePictureError}
                            >
                                <ProfilePicture url={this.props.user.profilePictureLink} />
                            </PictureUploadManager>
                            <Button color="default mt-3" className="w-100" onClick={this.openProfilePictureUpload}>Upload new picture</Button>
                            <FormText color="danger">{this.state.profilePictureError}</FormText>
                            </dd>
                        </FormGroup>
                        <FormGroup tag="dl" className="mr-4 mt-3" style={{ width: 200 }}>
                            <dt><Label>Signature</Label></dt>
                            <dd className="clearfix">
                            <PictureUploadManager
                                ref={this.signatureUploadRef}
                                onUploadPicture={this.uploadSignature}
                                onError={this.updateSignatureError}
                            >
                                <ProfilePicture url={this.props.user.signatureLink} fallbackIcon="signature" />
                            </PictureUploadManager>
                            <Button color="default mt-3" className="w-100" onClick={this.openSignatureUpload}>Upload new signature</Button>
                            <FormText color="danger">{this.state.signatureError}</FormText>
                            </dd>
                        </FormGroup>
                    </Col>
                </Row>
            </Card>
        )
    }
}

function mapStateToProps(state: RootState, ownProps: {}): IConnectedState {
    return {
        ...ownProps,
        user: state.session.user
    }
}

function mapDispatchToProps(dispatch: Dispatch, ownProps: {}): IConnectedDispatch {
    return {
        reloadUserProfile: () => dispatch<any>(reloadUserProfile())
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(UserProfilePage)
