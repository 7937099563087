import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router'

import { UnregisterCallback } from 'history'

import history from '@src/logic/routing/history'

interface IProps {
    when: boolean
    children: (onConfirm: () => void, onCancel: () => void) => React.ReactChild
}

interface IState {
    openModal: boolean
    nextLocation: any
}

class NavigationPrompt extends React.PureComponent<IProps & RouteComponentProps, IState> {

    private unblock: UnregisterCallback = null

    constructor(props) {
        super(props)
        this.state = { nextLocation: null, openModal: false }
    }

    public componentDidMount() {
        if (this.props.when) {
            this.unblock = history.block((nextLocation, action) => {
                if (this.props.when) {
                    this.setState({
                        nextLocation,
                        openModal: true
                    })
                }
                return false
            })
        }
    }

    public componentDidUpdate(prevProps: IProps & RouteComponentProps, prevState) {
        if (this.props.when && !prevProps.when) {
            this.unblock = history.block((nextLocation, action) => {
                if (this.props.when) {
                    this.setState({
                        nextLocation,
                        openModal: true
                    })
                }
                return false
            })
        } else if (!this.props.when && prevProps.when && this.unblock) {
            this.unblock()
            this.unblock = null
        }
    }

    public componentWillUnmount() {
        if (this.unblock) {
            this.unblock()
        }
    }

    private onCancel = () => {
        this.setState({ nextLocation: null, openModal: false })
    }

    private onConfirm = () => {
        this.navigateToNextLocation()
    }

    private navigateToNextLocation = () => {
        this.unblock()
        this.props.history.push(this.state.nextLocation.pathname)
    }

    public render() {
        return this.state.openModal ? this.props.children(this.onConfirm, this.onCancel) : null
    }
}

export default withRouter(NavigationPrompt)
