import React from 'react'
import { Card, CardBody, CardHeader, Collapse } from 'reactstrap'

import cx from 'classnames'

import FA from '@src/components/common/FontAwesomeIcon'

interface IProps {
    header: React.ReactChild
    defaultOpen: boolean
    noCardBody: boolean
    disabled: boolean
}

interface IState {
    open: boolean
}

export default class CollapsibleCard extends React.PureComponent<IProps, IState> {

    public static defaultProps = {
        header: '',
        defaultOpen: false,
        noCardBody: false,
        disabled: false
    }

    constructor(props: IProps) {
        super(props)

        this.state = {
            open: props.defaultOpen
        }
    }

    public componentDidUpdate(prevProps: IProps) {
        if (prevProps.defaultOpen !== this.props.defaultOpen && this.props.defaultOpen !== this.state.open) {
            this.setState({ open: this.props.defaultOpen })
        }
    }

    private toggle = () => this.setState({ open: !this.state.open })

    public render() {
        const collapseBody = (
            <Collapse isOpen={this.state.open}>
                {this.props.children}
            </Collapse>
        )

        return (
            <>
                <Card>
                    <CardHeader className="d-inline-flex align-items-center border-bottom-0">
                        <span className="flex-grow-1 mr-3">{this.props.header}</span>
                        <span className={cx('flex-shrink-1 pointer', { disabled: this.props.disabled })}>
                            <FA onClick={this.toggle} icon={this.state.open ? 'minus-square' : 'plus-square'} />
                        </span>
                    </CardHeader>
                    {!this.props.noCardBody ? <CardBody>{collapseBody}</CardBody> : collapseBody}
                </Card>
            </>
        )
    }
}
