import React from 'react'
import { RouteComponentProps } from 'react-router'
import { Button, UncontrolledTooltip } from 'reactstrap'

import moment from 'moment'

import FA from '@src/components/common/FontAwesomeIcon'
import Link from '@src/components/common/Link'
import TooltipLinkAction from '@src/components/common/TooltipLinkAction'
import CostValue from '@src/components/costs/common/CostValue'
import PaymentClaimModal from '@src/components/costs/payments/PaymentClaimModal'
import ConfirmationModal from '@src/components/modal/ConfirmationModal'
import { IMappedSearchProperty, PropertyType } from '@src/components/search/SearchAssistant'
import SearchSection, { SearchSectionType } from '@src/components/search/SearchSection'
import useBoolean from '@src/hooks/useBoolean'
import useLocalSearch from '@src/hooks/useLocalSearch'
import { isAuthorised } from '@src/logic/auth/access'
import * as Operations from '@src/logic/auth/operations'
import { PaymentClaimDelete } from '@src/logic/http/Api'
import NotificationService from '@src/logic/notification/NotificationService'
import * as Routes from '@src/logic/routing/routes'
import { mutedNotSet } from '@src/logic/utils/ValueHelper'
import { Commitment, CostsOverview, PaymentClaimBrief } from '@src/types/costs'

const PaymentClaimSearchProperties: IMappedSearchProperty<PaymentClaimBrief>[] = [
    {
        name: 'Certificate Number',
        path: claim => claim.certificateNumber,
        searchKey: 'certificate_number',
        type: PropertyType.Text
    },
    {
        name: 'Created',
        path: claim => new Date(claim.createdDate),
        searchKey: 'created',
        type: PropertyType.Date
    },
    {
        name: 'Claim Date',
        path: claim => new Date(claim.claimDate),
        searchKey: 'claim_date',
        type: PropertyType.Date
    },
    {
        name: 'Invoice Date',
        path: claim => new Date(claim.invoiceDate),
        searchKey: 'invoice_date',
        type: PropertyType.Date
    },
    {
        name: 'Claim Reference',
        path: claim => claim.claimReference,
        searchKey: 'claim_reference',
        type: PropertyType.Text
    },
    {
        name: 'Invoice Reference',
        path: claim => claim.invoiceReference,
        searchKey: 'invoice_reference',
        type: PropertyType.Text
    },
    {
        name: 'Claimed',
        path: claim => claim.currentClaimed,
        searchKey: 'claimed',
        type: PropertyType.Number
    },
    {
        name: 'Certified',
        path: claim => claim.currentCertified,
        searchKey: 'certified',
        type: PropertyType.Number
    },
    {
        name: 'Paid',
        path: claim => claim.currentPaid,
        searchKey: 'paid',
        type: PropertyType.Number
    }
]

interface IProps {
    projectId: string
    commitment: Commitment
    costsOverview: CostsOverview
    reloadCommitment: () => Promise<any>
    search: string
}

const CommitmentPaymentsSection: React.FunctionComponent<IProps & RouteComponentProps> = ({ projectId, commitment, costsOverview, reloadCommitment, search, history }) => {
    const creating = useBoolean(false)
    const editing = useBoolean(false)
    const deleting = useBoolean(false)
    const [selected, setSelected] = React.useState<PaymentClaimBrief>(undefined)
    const claimSearchSectionRef = React.useRef<SearchSectionType<PaymentClaimBrief, 'id'>>()
    const handleSearch = useLocalSearch(commitment.paymentClaims, PaymentClaimSearchProperties)

    function setClaimToEdit(claim: PaymentClaimBrief) {
        editing.setTrue()
        setSelected(claim)
    }

    function setClaimToDelete(claim: PaymentClaimBrief) {
        deleting.setTrue()
        setSelected(claim)
    }

    function clearFlags() {
        editing.setFalse()
        deleting.setFalse()
        creating.setFalse()
    }

    function clearSelectedItemAndFlags() {
        clearFlags()
        setSelected(undefined)
    }

    async function deletePaymentClaim() {
        const paymentClaimToDelete = { ...selected }
        clearSelectedItemAndFlags()

        try {
            await PaymentClaimDelete(projectId, commitment.id, paymentClaimToDelete.id)
        } catch {
            NotificationService.error(<span>Failed to remove {paymentClaimToDelete.certificateNumber}</span>)
        }

        await reloadCommitment()
    }

    async function handlePaymentCreatedOrUpdated() {
        if (selected == null) {
            history.push(Routes.projectCostsPaymentDetail(projectId, selected.commitment.type, selected.commitment.id, selected.id))
        } else {
            NotificationService.info('Payment claim updated')
            await reloadCommitment()
            if (claimSearchSectionRef.current) claimSearchSectionRef.current.doSearch()
        }
    }

    return (
        <SearchSection<PaymentClaimBrief, 'id'>
            ref={claimSearchSectionRef}
            defaultPerPage={50}
            onSearch={handleSearch}
            searchAssistantProperties={PaymentClaimSearchProperties}
            extraSearchBarElements={[
                {
                    element: _ => <Button color="secondary" onClick={creating.setTrue}><FA icon="plus" /> New Payment</Button>,
                    position: 'before'
                }
            ]}
            noItemsFoundMessage={(
                <div className="text-center">
                    <div className="my-3"><FA size="3x" icon="envelope-open-dollar" /></div>
                    <p className="lead">There are no payment claims.</p>
                    <div className="lead d-block">
                        {commitment.commitmentItems.length === 0 &&
                            <UncontrolledTooltip placement="top" target="no-claims-create-payment-claim">
                                Cannot create when there are no items
                                    </UncontrolledTooltip>
                        }
                        <div className="d-inline-block" id="no-claims-create-payment-claim">
                            <Button disabled={commitment.commitmentItems.length === 0} onClick={creating.setTrue} style={{ pointerEvents: commitment.commitmentItems.length === 0 ? 'none' : 'initial' }}>Create Payment Claim</Button>
                        </div>
                    </div>
                </div>
            )}
            headers={[
                {
                    name: 'Claim #',
                    sortKey: 'certificate_number',
                    sortable: true,
                    overrideRenderer: payment => <Link to={Routes.projectCostsPaymentDetail(projectId, payment.commitment.type, payment.commitment.id, payment.id)}>{payment.certificateNumber}</Link>
                },
                {
                    name: 'Date',
                    overrideRenderer: payment => moment(payment.createdDate).format('L'),
                    sortKey: 'created',
                    sortable: true
                },
                {
                    name: 'Claim Date',
                    overrideRenderer: payment => payment.claimDate ? moment(payment.claimDate).format('L') : mutedNotSet,
                    sortKey: 'claim_date',
                    sortable: true
                },
                {
                    name: 'Invoice Date',
                    overrideRenderer: payment => payment.invoiceDate ? moment(payment.invoiceDate).format('L') : mutedNotSet,
                    sortKey: 'invoice_date',
                    sortable: true
                },
                {
                    name: 'Claim Ref',
                    accessor: 'claimReference',
                    sortKey: 'claim_reference',
                    defaultFallback: mutedNotSet,
                    sortable: true
                },
                {
                    name: 'Invoice Ref',
                    accessor: 'invoiceReference',
                    sortKey: 'invoice_reference',
                    sortable: true
                },
                {
                    name: 'Claimed',
                    overrideRenderer: payment => <CostValue value={payment.currentClaimed} />,
                    sortKey: 'claimed',
                    sortable: true
                },
                {
                    name: 'Certified',
                    overrideRenderer: payment => <CostValue value={payment.currentCertified} />,
                    sortKey: 'certified',
                    sortable: true
                },
                {
                    name: 'Paid',
                    overrideRenderer: payment => <CostValue value={payment.currentPaid} />,
                    sortKey: 'paid',
                    sortable: true
                },
                {
                    name: 'Actions',
                    headerWrapperClass: 'text-right',
                    overrideRenderer: payment => (
                        <div className="text-right">
                            <TooltipLinkAction id={`edit-payment-${payment.id}`} tooltip="Edit" data={payment} className="order-lg-1" onClick={setClaimToEdit}><FA icon="pencil" /></TooltipLinkAction>
                            <TooltipLinkAction id={`delete-payment-${payment.id}`} tooltip="Remove" data={payment} className="order-lg-1" onClick={setClaimToDelete} disabled={!isAuthorised(costsOverview.myAccess, Operations.Delete)}><FA icon="trash" /></TooltipLinkAction>
                        </div>
                    )
                }
            ]}
        >
            <PaymentClaimModal
                open={creating.value || editing.value}
                toggle={clearFlags}
                projectId={projectId}
                costsOverview={costsOverview}
                commitment={commitment}
                paymentClaimToEdit={selected}
                onClosed={clearSelectedItemAndFlags}
                onItemCreatedOrUpdated={handlePaymentCreatedOrUpdated}
            />
            <ConfirmationModal
                danger
                open={deleting.value}
                toggle={deleting.setFalse}
                header={'Remove payment claim'}
                message={<span>Are you sure you want to remove <strong>{selected?.certificateNumber}</strong>?</span>}
                confirmAction="Remove"
                rejectAction="Cancel"
                onClosed={clearSelectedItemAndFlags}
                onReject={deleting.setFalse}
                onConfirm={deletePaymentClaim}
            />
        </SearchSection>
    )
}

export default CommitmentPaymentsSection
