export enum BeaconCommands {
    init = 'init',
    destroy = 'destroy',
    open = 'open',
    close = 'close',
    toggle = 'toggle',
    search = 'search',
    suggest = 'suggest',
    article = 'article',
    identify = 'identify'
}

declare global {
    interface Window {
        Beacon(command: BeaconCommands.init, beaconId: string)
        Beacon(command: BeaconCommands.destroy)
        // tslint:disable-next-line:unified-signatures
        Beacon(command: BeaconCommands.open)
        // tslint:disable-next-line:unified-signatures
        Beacon(command: BeaconCommands.close)
        // tslint:disable-next-line:unified-signatures
        Beacon(command: BeaconCommands.toggle)
        // tslint:disable-next-line:unified-signatures
        Beacon(command: BeaconCommands.search, query: string)
        Beacon(command: BeaconCommands.suggest, docIds: string[])
        // tslint:disable-next-line:unified-signatures
        Beacon(command: BeaconCommands.article, docId: string)
        Beacon(command: BeaconCommands.identify, identity: {
            name?: string
            email?: string
            company?: string
            avatar?: string
            signature?: string
            [key: string]: string
        })
        Beacon(event: 'on' | 'off' | 'once', command: BeaconCommands, callback: () => void)
    }
}
